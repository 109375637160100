import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Page from "../../../components/layout/Page";
import { Loading } from "../../../components/widgets";
import * as invoicesActions from "../../../redux/actions/invoices";
import InvoicesListTable from "../../../components/myreposit/invoices/InvoicesListTable";
import Permissions from "../../../permissions";
import { accessTokenItemName } from "../../../redux/actions/user";

const mapStateToProps = state => ({
  invoices: state.invoices.invoices,
});

const mapDispatchToProps = dispatch => {
  return {
    invoicesActions: bindActionCreators(invoicesActions, dispatch),
  };
};

const INVOICES_PATH = process.env.REACT_APP_MARKETPLACE_API_URL + "/billing/invoices/";

class InvoicesList extends Component {
  componentDidMount = () => {
    this.props.invoicesActions.fetchInvoices();
  };

  render() {
    if (!this.props.invoices) {
      return <Loading />;
    }

    const token = localStorage.getItem(accessTokenItemName);

    const invoices = this.props.invoices
      .map(inv => {
        return {
          ...inv,
          downloadURL:
            INVOICES_PATH + inv.id + "/reposit-invoice-" + inv.date + ".pdf?auth=Bearer " + token,
        };
      })
      .sort((a, b) => {
        if (!a["paid"] && b["paid"]) {
          return -1;
        }
        if (a["paid"] && !b["paid"]) {
          return 1;
        }
        if (a["date"] > b["date"]) {
          return -1;
        }
        if (a["date"] < b["date"]) {
          return 1;
        }
        if (a["id"] > b["id"]) {
          return -1;
        }
        if (a["id"] < b["id"]) {
          return 1;
        }
        return 0;
      });

    return (
      <>
        <Page key="invoices-list" permission={Permissions.fleet.myReposit.billing}>
          <Page.Header title="Billing" />
          <Page.Content>
            <InvoicesListTable invoices={invoices} />
          </Page.Content>
        </Page>
      </>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(InvoicesList);

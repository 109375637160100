import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Modal } from "../../components/layout/Modal";
import { Button, Loading } from "../../components/widgets";
import NewDispatch from "../../components/trading/NewDispatch";
import NewDispatchGraph from "../../components/trading/NewDispatchGraph";
import NewDispatchPricing from "../../components/trading/NewDispatchPricing";
import CustomerAppMessageEdit from "../../components/trading/CustomerAppMessageEdit";
import * as tradingActions from "../../redux/actions/trading";
import moment from "moment-timezone";
import Permissions, { hasPermission } from "../../permissions";
import NewDispatchExecuteConfirmation from "../../components/trading/NewDispatchExecuteConfirmation";

const DEFAULT_DISPATCH_START_OFFSET = 14400; // Use 4 hours from now
const DEFAULT_DISPATCH_DURATION = 14400; // 4 hours

const mapStateToProps = state => ({
  dispatches: state.trading.dispatches,
  powerstations: state.trading.powerstations,
  user: state.user,
  newDispatch: state.trading.newDispatch,
  constraintsLoading: state.trading.constraintsLoading,
  predictionsLoading: state.trading.predictionsLoading,
  dispatchPriceLoading: state.trading.dispatchPriceLoading,
  tradingPriceLoading: state.trading.tradingPriceLoading,
  executeDispatchLoading: state.trading.executeDispatchLoading,
  previousDispatchMessages: state.trading.previousDispatchMessages,
});

const mapDispatchToProps = dispatch => {
  return {
    tradingActions: bindActionCreators(tradingActions, dispatch),
  };
};

class NewDispatchModal extends Component {
  constructor(props) {
    super(props);

    const now = new Date();
    const nowTimestamp = Math.round(now.getTime() / 1000); // Get now as seconds (JS uses ms).
    let startTime = nowTimestamp + DEFAULT_DISPATCH_START_OFFSET;
    startTime = startTime - (startTime % 3600); // Round down to nearest hour.
    const timezone = this.props.user.account.timezone;
    let startDate = moment(new Date(startTime * 1000));

    const momentNow = moment().tz(this.props.user.account.timezone);
    momentNow.minutes(0).seconds(0);

    const { realPowerP, powerFactor, powerFactorLeadLag } = this.getDefaultPowerSettings();

    this.state = {
      powerstationId: props.powerstationId ? props.powerstationId : null,
      allowPowerstationEdit: !props.powerstationId,
      startDateTime: startDate.tz(timezone),
      graphStartDateTime: momentNow,
      duration: DEFAULT_DISPATCH_DURATION,
      realPowerP: realPowerP,
      powerFactor: powerFactor,
      powerFactorLeadLag: powerFactorLeadLag,
      brushSelection: null,
      customerAppMessage: props.previousDispatchMessages
        ? this.getLastDispatchMessage(props)
        : null,
      strategy: "greedy",
    };
  }

  componentDidMount = () => {
    if (this.state.powerstationId) {
      const { powerstationId, startDateTime, duration, realPowerP, powerFactor } = this.state;
      this.fetchDispatchData(powerstationId, startDateTime, duration, realPowerP, powerFactor);
    }
    this.props.tradingActions.fetchPreviousDispatchMessges();
  };

  componentWillUnmount = () => {
    this.props.tradingActions.clearNewDispatchGraphData();
    this.props.tradingActions.clearPreviousDispatchMessages();
  };

  componentWillReceiveProps = nextProps => {
    if (this.props.previousDispatchMessages !== nextProps.previousDispatchMessages) {
      const message = this.getLastDispatchMessage(nextProps);
      this.setState({
        customerAppMessage: message,
      });
    }
  };

  getDefaultPowerSettings = () => {
    return {
      realPowerP: 0,
      powerFactor: hasPermission(
        this.props.user.permissions,
        Permissions.fleet.dispatches.powerFactor
      )
        ? 1.0
        : null,
      powerFactorLeadLag: null,
    };
  };

  fetchDispatchData = (powerstationId, startDateTime, duration, realPowerP, powerFactor) => {
    const graphStartTime = this.state.graphStartDateTime.unix();
    const dispatchStartTime = this.state.startDateTime.unix();

    this.props.tradingActions.fetchNewDispatchConstraints(
      powerstationId,
      duration,
      realPowerP,
      powerFactor
    );
    this.props.tradingActions.fetchNewDispatchPrediction(
      powerstationId,
      graphStartTime,
      dispatchStartTime,
      duration,
      realPowerP
    );
    this.props.tradingActions.fetchNewDispatchPrice(
      powerstationId,
      dispatchStartTime,
      duration,
      realPowerP
    );
    this.props.tradingActions.fetchTradingPrice(powerstationId);
  };

  handlePowerstationChange = value => {
    this.props.tradingActions.clearNewDispatchGraphData();

    const { startDateTime, duration } = this.state;
    const { realPowerP, powerFactor, powerFactorLeadLag } = this.getDefaultPowerSettings();

    this.setState(
      {
        powerstationId: value,
        realPowerP: realPowerP,
        powerFactor: powerFactor,
        powerFactorLeadLag: powerFactorLeadLag,
      },
      () => {
        this.fetchDispatchData(value, startDateTime, duration, realPowerP, powerFactor);
      }
    );
  };

  handleStartDateTimeChange = (startDateTime, isBrushChange) => {
    let callback = () => {};
    let graphStartDateTime;
    if (!this.state.startDateTime.isSame(startDateTime, "day") && !isBrushChange) {
      this.props.tradingActions.clearNewDispatchGraphData();
      const { powerstationId, duration, realPowerP, powerFactor } = this.state;
      callback = () => {
        this.fetchDispatchData(powerstationId, startDateTime, duration, realPowerP, powerFactor);
      };
      graphStartDateTime = this.getGraphStartTime(startDateTime);
    } else {
      callback = this.updateConstaintsAndPredictions;
      graphStartDateTime = this.state.graphStartDateTime;
    }

    if (startDateTime.diff(graphStartDateTime) < 0) {
      startDateTime = graphStartDateTime.clone();
    }

    this.setState(
      {
        startDateTime: startDateTime,
        graphStartDateTime: graphStartDateTime,
      },
      callback
    );
  };

  handleDurationChange = duration => {
    this.setState(
      {
        duration: duration,
      },
      this.updateConstaintsAndPredictions
    );
  };

  handleRealPowerPOnChange = realPowerP => {
    const constraints = this.props.newDispatch.constraints;
    const range = constraints.max - constraints.min;
    const snapPercent = 0.04;

    let nextRealPowerP = realPowerP;
    if (range <= 0) {
      nextRealPowerP = realPowerP;
    } else if (realPowerP + range * snapPercent > constraints.max) {
      nextRealPowerP = constraints.max;
    } else if (realPowerP - range * snapPercent < constraints.min) {
      nextRealPowerP = constraints.min;
    } else if (Math.abs(realPowerP) < range * snapPercent) {
      nextRealPowerP = 0;
    }

    this.setState(
      {
        realPowerP: nextRealPowerP,
      },
      this.updateConstaintsAndPredictions
    );
  };

  handlePowerFactorOnChange = powerFactor => {
    this.setState(
      {
        powerFactor: powerFactor,
      },
      this.updateConstaintsAndPredictions
    );
  };

  handlePowerFactorLeadLagOnChange = powerFactorLeadLag => {
    this.setState(
      {
        powerFactorLeadLag: powerFactorLeadLag,
      },
      this.updateConstaintsAndPredictions
    );
  };

  updateConstraints = () => {
    // This fetches new constraints every time the time selection brush is changed.
    // Unfortunately it is impossible to distinguish between the user actively changing the brush
    // and finishing interacting all together so we just debounce here.
    // Its also better UX this way if the user just pauses whilst dragging we get refreshed constraints.
    if (this.updateConstraintsTimeout) {
      clearTimeout(this.updateConstraintsTimeout);
    }

    this.updateConstraintsTimeout = setTimeout(() => {
      this.props.tradingActions.fetchNewDispatchConstraints(
        this.state.powerstationId,
        this.state.duration,
        this.state.realPowerP,
        this.state.powerFactor
      );
    }, 300);
  };

  updatePredictions = () => {
    // Debounced as above.
    if (this.updatePredictionsTimeout) {
      clearTimeout(this.updatePredictionsTimeout);
    }

    const graphStartTime = this.state.graphStartDateTime.unix();
    const dispatchStartTime = this.state.startDateTime.unix();
    this.updatePredictionsTimeout = setTimeout(() => {
      this.props.tradingActions.fetchNewDispatchPrediction(
        this.state.powerstationId,
        graphStartTime,
        dispatchStartTime,
        this.state.duration,
        this.state.realPowerP
      );
    }, 300);
  };

  updatePrices = () => {
    // Debounced as above.
    if (this.updatePricesTimeout) {
      clearTimeout(this.updatePricesTimeout);
    }

    const startTime = this.state.startDateTime.unix();
    this.updatePricesTimeout = setTimeout(() => {
      this.props.tradingActions.fetchNewDispatchPrice(
        this.state.powerstationId,
        startTime,
        this.state.duration,
        this.state.realPowerP
      );
    }, 300);
  };

  updateConstaintsAndPredictions = () => {
    this.updateConstraints();
    this.updatePredictions();
    this.updatePrices();
  };

  getLastDispatchMessage = props => {
    let latestCreatedAt = 0;
    let latestMessage;
    for (let i in props.previousDispatchMessages) {
      const message = props.previousDispatchMessages[i];
      if (message.createdAt > latestCreatedAt) {
        latestCreatedAt = message.createdAt;
        latestMessage = message.customerMessage;
      }
    }

    return latestMessage;
  };

  getGraphStartTime = startDateTime => {
    const now = moment().tz(this.props.user.account.timezone);
    if (startDateTime.isSame(now, "day")) {
      return now.minutes(0).seconds(0);
    } else {
      return startDateTime.clone().hours(0).minutes(0).seconds(0);
    }
  };

  executeDispatch = () => {
    // power factor cannot be set on a charge
    const powerFactor = this.powerFactorAllowed() ? this.state.powerFactor : null;
    // lead/lag cannot be set on a charge
    const powerFactorLeadLag = this.leadLagAllowed() ? this.state.powerFactorLeadLag : null;
    // no customer app message for lockouts
    const customerAppMessage = this.state.realPowerP === 0 ? null : this.state.customerAppMessage;
    // only set strategy if charging or discharging
    const strategy = this.strategyAllowed() ? this.state.strategy : null;

    this.props.tradingActions.executeDispatch(
      this.state.powerstationId,
      this.state.startDateTime.unix(),
      this.state.duration,
      this.state.realPowerP,
      powerFactor,
      powerFactorLeadLag,
      customerAppMessage,
      strategy
    );
  };

  strategyAllowed = () => {
    return this.state.realPowerP !== 1;
  };

  powerFactorAllowed = () => {
    // power factor can only be set on a discharge
    return (
      this.state.realPowerP < 0 ||
      (this.props.newDispatch &&
        this.props.newDispatch.constraints &&
        !this.props.newDispatch.constraints.realPowerAvailable)
    );
  };

  leadLagAllowed = () => {
    return this.powerFactorAllowed() && this.state.powerFactor < 1;
  };

  showCustomerAppMessageEdit = () => {
    this.setState({
      editCustomerAppMessage: true,
      currentCustomerAppMessage: this.state.customerAppMessage,
    });
  };

  hideCustomerAppMessageEdit = () => {
    this.setState({
      editCustomerAppMessage: false,
    });
  };

  showExecuteConfirmation = () => {
    this.setState({
      showExecuteConfirmation: true,
    });
  };

  hideExecuteConfirmation = () => {
    this.setState({
      showExecuteConfirmation: false,
    });
  };

  handleOnCustomerAppMessageChange = value => {
    this.setState({
      customerAppMessage: value,
    });
  };

  cancelCustomerAppMessageChange = () => {
    this.setState({
      editCustomerAppMessage: false,
      customerAppMessage: this.state.currentCustomerAppMessage,
      currentCustomerAppMessage: null,
    });
  };

  renderFooter(isLoading) {
    if (this.state.editCustomerAppMessage) {
      return (
        <>
          <Button
            id="back-from-capp-msg-edit-to-dispatch-cancel"
            type="secondary"
            onClick={this.cancelCustomerAppMessageChange}
            icon="chevron-left"
          >
            Cancel
          </Button>
          <Button
            id="back-from-capp-msg-edit-to-dispatch-save"
            type="primary"
            onClick={this.hideCustomerAppMessageEdit}
            icon="check"
          >
            Save Message
          </Button>
        </>
      );
    }
    if (this.state.showExecuteConfirmation) {
      return (
        <>
          <Button
            id="back-to-dispatch-edit-modal"
            type="secondary"
            onClick={this.hideExecuteConfirmation}
            icon="chevron-left"
          >
            Back
          </Button>
          {(this.props.powerstations || !this.state.allowPowerstationEdit) &&
            this.state.powerstationId && (
              <>
                <div>
                  All times are in{" "}
                  <Link to="/user/settings">{this.props.user.account.timezone}</Link>
                </div>
                <Button
                  id="add-new-dispatch-modal"
                  type="primary"
                  onClick={this.executeDispatch}
                  icon="bolt"
                  loading={isLoading}
                >
                  Execute
                </Button>
              </>
            )}
        </>
      );
    }
    return (
      <>
        <Button
          id="cancel-new-dispatch-modal"
          type="secondary"
          onClick={this.props.handleOnCancel}
          icon="times"
        >
          Cancel
        </Button>
        {(this.props.powerstations || !this.state.allowPowerstationEdit) &&
          this.state.powerstationId && (
            <>
              <div>
                All times are in <Link to="/user/settings">{this.props.user.account.timezone}</Link>
              </div>
              <Button
                id="confirm-dispatch-modal"
                type="primary"
                onClick={this.showExecuteConfirmation}
                icon="chevron-right"
                loading={isLoading}
                disabled={
                  this.state.powerFactor !== 1.0 &&
                  this.state.powerFactorLeadLag === null &&
                  this.state.powerFactor !== null
                }
              >
                Confirm
              </Button>
            </>
          )}
      </>
    );
  }

  handleStrategyChange = newStrategy => {
    this.setState({
      strategy: newStrategy,
    });
  };

  render() {
    const newDispatch = this.props.newDispatch;

    const isLoading =
      this.props.constraintsLoading ||
      this.props.predictionsLoading ||
      this.props.executeDispatchLoading;

    return (
      <Modal>
        <Modal.Header title="New Dispatch" />
        <Modal.Content>
          <div
            style={{
              opacity: this.state.editCustomerAppMessage ? 1 : 0,
              position: this.state.editCustomerAppMessage ? "relative" : "absolute",
              top: 0,
              left: 0,
              width: "100%",
              transition: "all 0.5s ease-in-out",
              zIndex: this.state.editCustomerAppMessage ? 999999 : -999999,
            }}
          >
            <CustomerAppMessageEdit
              hideCustomerAppMessageEdit={this.hideCustomerAppMessageEdit}
              customerAppMessage={this.state.customerAppMessage}
              onChange={this.handleOnCustomerAppMessageChange}
              previousCustomerAppMessages={this.props.previousDispatchMessages}
            />
          </div>
          <div
            style={{
              opacity: this.state.showExecuteConfirmation ? 1 : 0,
              position: this.state.showExecuteConfirmation ? "relative" : "absolute",
              top: 0,
              left: 0,
              width: "100%",
              transition: "all 0.5s ease-in-out",
              zIndex: this.state.showExecuteConfirmation ? 999999 : -999999,
            }}
          >
            <NewDispatchExecuteConfirmation
              powerstations={this.props.powerstations}
              powerstation={this.state.powerstationId}
              startDateTime={this.state.startDateTime}
              duration={this.state.duration}
              timezone={this.props.user.account.timezone}
              constraints={newDispatch ? newDispatch.constraints : null}
              realPowerP={this.state.realPowerP}
              powerFactor={this.state.powerFactor}
              powerFactorLeadLag={this.state.powerFactorLeadLag}
              customerAppMessage={this.state.customerAppMessage}
              strategy={this.state.strategy}
              buyPrice={
                newDispatch && newDispatch.prices ? newDispatch.prices.dispatchPrice : undefined
              }
              sellPrice={
                newDispatch && newDispatch.prices ? newDispatch.prices.tradingPrice : undefined
              }
              showMarketData={
                this.props.user.account.suborg_type === "RETAILER" ||
                hasPermission(
                  this.props.user.permissions,
                  Permissions.fleet.dispatches.marketTrader
                )
              }
            />
          </div>
          <div
            style={{
              opacity:
                this.state.editCustomerAppMessage || this.state.showExecuteConfirmation ? 0 : 1,
              position:
                this.state.editCustomerAppMessage || this.state.showExecuteConfirmation
                  ? "absolute"
                  : "relative",
              top: 0,
              left: 0,
              width: "100%",
              transition: "all 0.5s ease-in-out",
              zIndex:
                this.state.editCustomerAppMessage || this.state.showExecuteConfirmation
                  ? -999999
                  : 999999,
            }}
          >
            <div style={{ position: "relative" }}>
              <NewDispatch
                powerstations={this.props.powerstations}
                powerstation={this.state.powerstationId}
                allowPowerstationEdit={this.state.allowPowerstationEdit}
                startDateTime={this.state.startDateTime}
                duration={this.state.duration}
                onPowerstationChange={this.handlePowerstationChange}
                handleStartDateTimeChange={this.handleStartDateTimeChange}
                handleDurationChange={this.handleDurationChange}
                timezone={this.props.user.account.timezone}
                constraints={newDispatch ? newDispatch.constraints : null}
                onChangeRealPowerP={this.handleRealPowerPOnChange}
                onChangePowerFactor={this.handlePowerFactorOnChange}
                onChangePowerFactorLeadLag={this.handlePowerFactorLeadLagOnChange}
                realPowerP={this.state.realPowerP}
                powerFactor={this.state.powerFactor}
                powerFactorLeadLag={this.state.powerFactorLeadLag}
                multipleRegions={
                  newDispatch &&
                  newDispatch.trading &&
                  (this.props.user.account.suborg_type === "RETAILER" ||
                    hasPermission(
                      this.props.user.permissions,
                      Permissions.fleet.dispatches.marketTrader
                    ))
                    ? newDispatch.trading.market.multipleRegions
                    : false
                }
                showMarketData={
                  this.props.user.account.suborg_type === "RETAILER" ||
                  hasPermission(
                    this.props.user.permissions,
                    Permissions.fleet.dispatches.marketTrader
                  )
                }
                showCustomerAppMessageEdit={this.showCustomerAppMessageEdit}
                customerAppMessage={this.state.customerAppMessage}
                previousCustomerAppMessages={this.props.previousDispatchMessages}
                powerFactorAllowed={this.powerFactorAllowed()}
                leadLagAllowed={this.leadLagAllowed()}
                strategy={this.state.strategy}
                onStrategyChange={this.handleStrategyChange}
                userPermissions={this.props.user.permissions}
              />

              {this.state.powerstationId ? (
                newDispatch && newDispatch.prediction && newDispatch.adjustedPrediction ? (
                  <div
                    style={{
                      height: "250px",
                      width: "calc(100% - 2px)",
                      marginBottom: "4rem",
                      paddingLeft: "1px",
                    }}
                  >
                    {isLoading ? <Loading horizontal /> : <div style={{ height: "4px" }} />}
                    <NewDispatchGraph
                      dispatchStartTimestamp={this.state.startDateTime.unix()}
                      startTimestamp={this.state.graphStartDateTime.unix()}
                      duration={this.state.duration}
                      prediction={newDispatch.prediction}
                      adjustedPrediction={newDispatch.adjustedPrediction}
                      marketPrediction={newDispatch.trading ? newDispatch.trading.market.rrp : null}
                      timezone={this.props.user.account.timezone}
                      handleStartDateTimeChange={this.handleStartDateTimeChange}
                      handleDurationChange={this.handleDurationChange}
                      showMarketData={
                        this.props.user.account.suborg_type === "RETAILER" ||
                        hasPermission(
                          this.props.user.permissions,
                          Permissions.fleet.dispatches.marketTrader
                        )
                      }
                    />
                  </div>
                ) : (
                  <div style={{ position: "relative", height: "8em" }}>
                    <Loading />
                  </div>
                )
              ) : null}
              {newDispatch && newDispatch.prices ? (
                <NewDispatchPricing
                  buyPrice={newDispatch.prices.dispatchPrice}
                  sellPrice={newDispatch.prices.tradingPrice}
                  showMarketData={
                    this.props.user.account.suborg_type === "RETAILER" ||
                    hasPermission(
                      this.props.user.permissions,
                      Permissions.fleet.dispatches.marketTrader
                    )
                  }
                />
              ) : null}
            </div>
          </div>
        </Modal.Content>
        <Modal.Footer>{this.renderFooter(isLoading)}</Modal.Footer>
      </Modal>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(NewDispatchModal);

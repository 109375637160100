import React, { Component } from "react";
import { Button, Loading } from "../widgets";
import SolarPanel from "../ordering/icons/solar_panel.svg";
import "./FleetDeploymentSystemComponents.css";
import AuthRequired from "../../containers/AuthRequired";
import Permissions from "../../permissions";

const EnergySystemHeader = ({ name, energySystem }) => {
  return (
    <div
      style={{
        display: "inline-flex",
        flexDirection: "column",
        marginTop: "1.4em",
      }}
    >
      <div className="energy-system-image-container">
        {energySystem.inverter && energySystem.inverter.imageUrl ? (
          <img
            className="energy-system-image"
            src={energySystem.inverter.imageUrl}
            alt={energySystem.inverter.name}
            title={energySystem.inverter.name}
          />
        ) : null}
        {energySystem.hasSolar ? (
          <img
            className="solar-panel-image"
            src={SolarPanel}
            alt="Solar panel"
            title="Solar panel"
          />
        ) : null}
        {energySystem.battery && energySystem.battery.imageUrl ? (
          <img
            className="energy-system-image"
            src={energySystem.battery.imageUrl}
            alt={energySystem.battery.name}
            title={energySystem.battery.name}
          />
        ) : null}
      </div>
      <h2>{name}</h2>
    </div>
  );
};

const ComponentRow = ({ title, content, className }) => {
  return (
    <div className={`row ${className ? className : ""}`}>
      <div className="cell cell-header">{title}</div>
      {content}
    </div>
  );
};

const InverterSummary = ({
  inverter,
  onWebPortalButtonClick,
  fetchingPortalCredentials,
  inverterPortalKey,
}) => {
  if (!inverter) {
    return <span>-</span>;
  }

  return (
    <div>
      <h3>{inverter.name}</h3>
      <div className="data-list">
        <div className="data-title">Max Input</div>
        <div>{inverter.minPower ? `${inverter.minPower} kW` : "-"}</div>
        <div className="data-title">Max Output</div>
        <div>{inverter.maxPower ? `${inverter.maxPower} kW` : "-"}</div>
        <div className="data-title">Phases</div>
        <div>{inverter.phases ? inverter.phases : "-"}</div>

        <AuthRequired permission={Permissions.fleet.node.inverter.portal}>
          {inverter.hasWebPortal && (
            <div className="data-title web-portal">
              <Button
                type="link"
                onClick={() => onWebPortalButtonClick(inverter)}
                disabled={fetchingPortalCredentials}
              >
                Web Portal
              </Button>
            </div>
          )}
        </AuthRequired>
      </div>
      {fetchingPortalCredentials && inverterPortalKey === inverter.id && <Loading horizontal />}
    </div>
  );
};

const BatterySummary = ({ battery }) => {
  if (!battery) {
    return <span>-</span>;
  }

  return (
    <div>
      <h3>{battery.name}</h3>
      <div className="data-list">
        <div className="data-title">Capacity</div>
        <div>{battery.capacity} kWh</div>
      </div>
      <div className="data-list">
        <div className="data-title">Usable Capacity</div>
        <div>{battery.usableCapacity} kWh</div>
      </div>
    </div>
  );
};

const FullWidthRow = ({ title, manufacturer, model, meters }) => {
  return (
    <div className="full-width-row thin-top-border">
      <div className="full-width-heading">
        <h3>{title}</h3>
      </div>
      <div className="full-width-content">
        <div className="data-summary">
          <div className="data-item">
            <div className="heading">Manufacturer</div>
            <div className="content">{manufacturer}</div>
          </div>
          <div className="data-item">
            <div className="heading">Model</div>
            <div className="content">{model}</div>
          </div>
          <div className="data-item">
            <div className="heading">Meters</div>
            <div className="content">{meters}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

const MeterSummary = ({ title, manufacturer, model, meters }) => {
  const metersSorted = meters.sort((a, b) => {
    return a.hwid - b.hwid;
  });
  return (
    <FullWidthRow
      title={title}
      manufacturer={manufacturer}
      model={model}
      meters={metersSorted.map(meter => (
        <p>{meter.hwid}</p>
      ))}
    />
  );
};

const Metering = ({ meterGroups, groupTitle }) => {
  return (
    <div className="full-width-data-summary">
      {meterGroups.map((meterGroup, idx) => (
        <span key={meterGroup.id ? meterGroup.id : idx}>
          <MeterSummary
            title={meterGroup.name ? meterGroup.name : `${groupTitle} ${idx + 1}`}
            manufacturer={meterGroup.manufacturer}
            model={meterGroup.model}
            meters={meterGroup.meters}
          />
        </span>
      ))}
    </div>
  );
};

const DeploymentSummary = ({ deployment }) => {
  return (
    <div className="data-list">
      <div className="data-title">Serial Number</div>
      <div>{deployment.reposit.serialNumber}</div>
      <div className="data-title">Product</div>
      <div>{deployment.reposit.product}</div>
      <div className="data-title">State</div>
      <div>{deployment.reposit.state === "COMMISSIONED" ? "Commissioned" : "Not Commissioned"}</div>
    </div>
  );
};

class FleetDeploymentSystemComponents extends Component {
  render() {
    const deployment = this.props.deployment;
    if (!deployment) {
      return null;
    }

    const systemCount = deployment.energySystems.length;

    return (
      <div className="system-components">
        <ComponentRow
          className="energy-system-header"
          content={deployment.energySystems.map((sys, idx) => (
            <div key={sys.id} className="cell center">
              <EnergySystemHeader name={`Inverter Set ${idx + 1}`} energySystem={sys} />
            </div>
          ))}
        />

        {deployment.hasBattery ? (
          <ComponentRow
            title={"Battery"}
            className="thick-top-border"
            content={deployment.energySystems.map(sys => (
              <div key={sys.id} className="cell">
                <BatterySummary battery={sys.battery} />
              </div>
            ))}
          />
        ) : null}

        {deployment.hasInverter ? (
          <ComponentRow
            title={"Inverter"}
            content={deployment.energySystems.map(sys => (
              <div key={sys.id} className="cell">
                <InverterSummary
                  inverter={sys.inverter}
                  deployment={deployment}
                  onWebPortalButtonClick={this.props.onWebPortalButtonClick}
                  fetchingPortalCredentials={this.props.fetchingPortalCredentials}
                  inverterPortalKey={this.props.inverterPortalKey}
                />
              </div>
            ))}
          />
        ) : null}

        {deployment.gridMeters.length > 0 ? (
          <ComponentRow
            title={"Grid Metering"}
            className="thick-top-border"
            content={
              <div className="cell no-width" style={{ flex: systemCount }}>
                <Metering meterGroups={deployment.gridMeters} groupTitle="Grid Meter" />
              </div>
            }
          />
        ) : null}

        {deployment.solarMeters.length > 0 ? (
          <ComponentRow
            title={"Solar Metering"}
            className="thick-top-border"
            content={
              <div className="cell no-width" style={{ flex: systemCount }}>
                <Metering meterGroups={deployment.solarMeters} groupTitle="Solar Meter" />
              </div>
            }
          />
        ) : null}

        {deployment.subMeters.length > 0 ? (
          <ComponentRow
            title={"Sub Metering"}
            className="thick-top-border"
            content={
              <div className="cell no-width" style={{ flex: systemCount }}>
                <Metering meterGroups={deployment.subMeters} />
              </div>
            }
          />
        ) : null}

        <ComponentRow
          title={"Reposit"}
          className="thick-top-border"
          content={
            <div className="cell" style={{ flex: systemCount }}>
              <DeploymentSummary deployment={deployment} />
            </div>
          }
        />
      </div>
    );
  }
}

export default FleetDeploymentSystemComponents;

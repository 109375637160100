import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Loading } from "../../components/widgets";
import MFASettingsForm from "../../components/user/MFASettingsForm";
import * as userActions from "../../redux/actions/user";
import MFAConfirmPhoneNumberModal from "../../components/user/MFAConfirmPhoneNumberModal";

const mapStateToProps = state => ({
  mfaSettings: state.user.mfaSettings,
  verifySMS: state.user.verifySMS,
  codePreference: state.user.codePreference,
});

const mapDispatchToProps = dispatch => {
  return {
    userActions: bindActionCreators(userActions, dispatch),
  };
};

class MFASettings extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showConfirmPhoneNumberModal: false,
      phoneNumber: "",
      password: "",
      verificationCode: "",
    };
  }

  componentDidMount = () => {
    this.props.userActions.fetchMFASettings();

    if (this.props.mfaSettings) {
      this.setState({
        codePreference: this.props.mfaSettings.codePreference,
      });
    }
  };

  componentWillReceiveProps = nextProps => {
    if (this.props.mfaSettings !== nextProps.mfaSettings) {
      this.setState({
        codePreference: nextProps.mfaSettings.codePreference,
      });
    }

    if (
      nextProps.verifySMS &&
      nextProps.verifySMS.messageSent &&
      this.props.verifySMS.messageSent !== nextProps.verifySMS.messageSent
    ) {
      this.setState({
        showConfirmPhoneNumberModal: true,
      });
    }

    if (!nextProps.verifySMS && this.props.verifySMS) {
      this.setState({
        showConfirmPhoneNumberModal: false,
      });
    }
  };

  handleCodePreferenceChange = preference => {
    this.setState({
      codePreference: preference.value,
    });
  };

  handlePhoneNumberChange = event => {
    const phoneNumber = event.target.value;
    this.setState({
      phoneNumber: phoneNumber,
      error: phoneNumber ? null : this.state.error,
    });
  };

  handleConfirmPasswordChange = event => {
    this.setState({
      password: event.target.value,
    });
  };

  handleVerificationCodeChange = event => {
    this.setState({
      verificationCode: event.target.value,
    });
  };

  handleOnSave = () => {
    if (this.state.codePreference === "SMS" && !this.props.mfaSettings.phoneNumberConfirmed) {
      let foundError = false;
      if (!this.state.phoneNumber || this.state.phoneNumber.trim() === "") {
        foundError = true;
        this.setState({
          phoneNumberError: "Please enter a phone number",
        });
      }

      if (!this.state.password || this.state.password.trim() === "") {
        foundError = true;
        this.setState({
          passwordError: "Please enter a password",
        });
      }

      if (!foundError) {
        this.props.userActions.fetchMFASMSVerificationRequest(
          this.state.phoneNumber,
          this.state.password
        );
      }
    } else if (this.props.mfaSettings.codePreference !== this.state.codePreference) {
      this.props.userActions.fetchSaveCodePreference(this.state.codePreference);
    }
  };

  hideConfirmPhoneNumberModal = () => {
    this.setState({
      showConfirmPhoneNumberModal: false,
    });
  };

  verifyCode = () => {
    this.props.userActions.fetchMFASMSVerificationConfirm(this.state.verificationCode);
  };

  render() {
    return (
      <div className="user-setting mfa">
        <h4>Multi-Factor Authentication (MFA)</h4>
        {!this.props.mfaSettings ? (
          <div style={{ margin: "2em", position: "relative" }}>
            <Loading />
          </div>
        ) : (
          <MFASettingsForm
            codePreference={this.state.codePreference}
            onCodePreferenceChange={this.handleCodePreferenceChange}
            saveEnabled={this.props.mfaSettings.codePreference !== this.state.codePreference}
            onPhoneNumberChange={this.handlePhoneNumberChange}
            phoneNumber={this.state.phoneNumber}
            phoneNumberConfirmed={this.props.mfaSettings.phoneNumberConfirmed}
            phoneNumberMasked={this.props.mfaSettings.phoneNumberMasked}
            onConfirmPasswordChange={this.handleConfirmPasswordChange}
            password={this.state.password}
            onSave={this.handleOnSave}
            loading={
              (this.props.verifySMS ? this.props.verifySMS.fetching : false) ||
              (this.props.codePreference ? this.props.codePreference.fetching : false)
            }
            phoneNumberError={
              this.state.phoneNumberError ||
              (this.props.verifySMS ? this.props.verifySMS.phoneNumberError : null)
            }
            passwordError={
              this.state.passwordError ||
              (this.props.verifySMS ? this.props.verifySMS.passwordError : null)
            }
          />
        )}

        {this.state.showConfirmPhoneNumberModal ? (
          <MFAConfirmPhoneNumberModal
            phoneNumber={this.props.verifySMS.formattedPhoneNumber}
            verificationCode={this.state.verificationCode}
            onVerificationCodeChange={this.handleVerificationCodeChange}
            hideConfirmPhoneNumberModal={this.hideConfirmPhoneNumberModal}
            verifyCode={this.verifyCode}
            loading={this.props.verifySMS.fetching}
          />
        ) : null}
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MFASettings);

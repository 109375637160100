import React, { Component } from "react";
import Button from "../widgets/Button";
import { Input } from "../widgets/index";
import { Tooltip } from "../widgets";
import { Modal } from "../layout/Modal";
import "./OrderSummaryModalContent.css";
import { GRID_PHASE_OPTIONS } from "../../containers/ordering/KitConstants";

import L_meter from "./icons/kit/L_meter.png";
import R_meter from "./icons/kit/R_meter.png";
import RepositControllerAndPSU from "./icons/kit/RepositControllerAndPSU.png";

const KITS_CODES = [
  "1L",
  "1L1S",
  "1L2S",
  "1L3S",
  "2L",
  "2L1S",
  "2L2S",
  "2L3S",
  "3L",
  "3L1S",
  "3L2S",
  "3L3S",
];

class OrderSummaryModalContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      referenceComment: "",
    };
  }

  handleEmailKit = () => {
    this.props.onEmailKit(this.state.referenceComment);
  };

  handleBack = () => {
    this.props.onBack();
  };

  handleUpdateReferenceComment = event => {
    this.setState({ referenceComment: event.target.value });
  };

  render() {
    const { loading, emailed, order, gridPhases } = this.props;

    const meter_kit = order.find(o => KITS_CODES.includes(o.code));
    const numSolarPhases = Array(parseInt(meter_kit.solarPhases, 10)).fill(0);
    const numGridPhases = Array(parseInt(meter_kit.gridPhases, 10)).fill(0);

    const gridMeters = numGridPhases
      ? numGridPhases.map(() => <img src={L_meter} alt="grid-meters" />)
      : null;
    const solarMeters = numSolarPhases
      ? numSolarPhases.map(() => <img src={R_meter} alt="solar-meters" />)
      : null;
    const repositController = <img src={RepositControllerAndPSU} alt="reposit-controller" />;

    const emailButton = emailed ? (
      <span className="email-success">
        <i className="fa fa-envelope" />
        Email sent
      </span>
    ) : (
      <Button
        type="primary"
        onClick={this.handleEmailKit}
        icon="envelope-open-o"
        loading={loading}
        disabled={emailed}
      >
        Email kit
      </Button>
    );

    return (
      <>
        <Modal.Header
          className="kit-design-header no-border"
          title="Configure a Reposit Kit &mdash; Summary"
        />
        <Modal.Content>
          <div className="kit-design-form kit-summary">
            <div className="illustration-bar">
              <div className="illustrations">
                {gridMeters}
                {repositController}
                {solarMeters}
              </div>
            </div>
            <div className="kit-design-form-section">
              <p>
                Reposit Kit for a <b>{GRID_PHASE_OPTIONS[gridPhases.value - 1].label}</b> phase
                installation
              </p>
              <p>
                <b>{order.map(item => item.poles).reduce((acc, poles) => acc + poles)}</b> DIN rail
                poles required
              </p>
              <table className="table-light order-table">
                <thead>
                  <tr>
                    <th scope="col">Code</th>
                    <th scope="col">Description</th>
                    <th scope="col">Qty</th>
                  </tr>
                </thead>
                <tbody>
                  {order.map(item => (
                    <tr>
                      <td>{item.code}</td>
                      <td>{item.description}</td>
                      <td>{item.qty}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div style={{ padding: "30px 0 30px 0" }}>
                <span className="label-text">
                  Reference Notes
                  <Tooltip id="ordering.referenceNotes" />
                </span>
                <br />
                <Input
                  style={{ width: "400px" }}
                  autoFocus
                  type="text"
                  spellCheck="false"
                  autoComplete="off"
                  placeholder="Enter a reference number or note here..."
                  value={this.state.referenceComment}
                  onChange={this.handleUpdateReferenceComment}
                ></Input>
              </div>
            </div>
          </div>
        </Modal.Content>
        <Modal.Footer>
          <Button type="secondary" onClick={this.handleBack} icon="chevron-left" disabled={loading}>
            Back
          </Button>
          <span className="kit-summary-email-button">{emailButton}</span>
        </Modal.Footer>
      </>
    );
  }
}

export default OrderSummaryModalContent;

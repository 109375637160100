import React from "react";
import "./MonitoringGraphLine.css";
class MonitoringGraphLine extends React.Component {
  render() {
    const { x, width, height, padding } = this.props;

    return (
      <div className="monitoring-graph-line">
        <svg width={width} height={height} role="img" viewBox={`0 0 ${width} ${height}`}>
          <line
            x1={x}
            x2={x}
            y1={padding.top}
            y2={height - padding.bottom}
            stroke="rgb(83, 86, 90)"
            strokeWidth="1"
          />
        </svg>
      </div>
    );
  }
}

export default MonitoringGraphLine;

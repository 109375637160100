import React from "react";
import Tooltip from "./Tooltip";
import "./LabelledContent.css";

const LabelledContent = ({ label, children, vertical, horizontal, tooltipId }) => (
  <div className={"labelled-content" + (vertical ? " north" : horizontal ? " west" : "")}>
    <span className="label">
      {label}
      {tooltipId ? <Tooltip id={tooltipId} /> : null}
    </span>
    <span className="value">{children}</span>
  </div>
);

export default LabelledContent;

import * as actions from "../actions/trading";

const tradingInitialState = {
  activeDispatch: null,
  newDispatch: null,
};

const tradingReducer = (state = tradingInitialState, action) => {
  switch (action.type) {
    case "RESET_STORE":
      return {
        ...tradingInitialState,
      };
    case actions.FETCH_DISPATCHES:
      return {
        ...state,
        dispatchesLoading: true,
      };
    case actions.UPDATE_DISPATCHES:
      return {
        ...state,
        dispatches: action.payload.dispatches,
        dispatchesPageMeta: action.payload.dispatchesPageMeta,
        dispatchesLoading: false,
      };
    case actions.UPDATE_TRADING_POWERSTATIONS:
      return {
        ...state,
        powerstations: action.payload.powerstations,
      };
    case actions.UPDATE_ACTIVE_DISPATCH:
      return {
        ...state,
        activeDispatch: action.payload.dispatch,
      };
    case actions.CLEAR_ACTIVE_DISPATCH:
      return {
        ...state,
        activeDispatch: null,
        activeDispatchPrediction: null,
        activeDispatchResponse: null,
        activeDispatchRemainingCharge: null,
        activeDispatchCost: null,
      };
    case actions.UPDATE_DISPATCH_PREDICTION:
      return {
        ...state,
        activeDispatchPrediction: action.payload.prediction,
      };
    case actions.UPDATE_DISPATCH_REMAINING_CHARGE:
      return {
        ...state,
        activeDispatchRemainingCharge: action.payload.remainingCharge,
      };
    case actions.UPDATE_DISPATCH_COST:
      return {
        ...state,
        activeDispatchCost: action.payload.cost,
      };
    case actions.UPDATE_DISPATCH_RESPONSE:
      return {
        ...state,
        activeDispatchResponse: action.payload.response,
      };
    case actions.FETCH_NEW_DISPATCH_CONSTRAINTS:
      return {
        ...state,
        constraintsLoading: true,
      };
    case actions.UPDATE_NEW_DISPATCH_CONSTRAINTS:
      return {
        ...state,
        newDispatch: {
          ...state.newDispatch,
          constraints: action.payload.constraints,
        },
        constraintsLoading: false,
      };
    case actions.FETCH_NEW_DISPATCH_PREDICTIONS:
      return {
        ...state,
        predictionsLoading: true,
      };
    case actions.UPDATE_NEW_DISPATCH_PREDICTIONS:
      return {
        ...state,
        newDispatch: {
          ...state.newDispatch,
          prediction: action.payload.predictions.meterPower,
          adjustedPrediction: action.payload.predictions.dispatchMeterPower,
        },
        predictionsLoading: false,
      };
    case actions.FETCH_NEW_DISPATCH_PRICE:
      return {
        ...state,
        dispatchPriceLoading: true,
      };
    case actions.UPDATE_DISPATCH_PRICE:
      return {
        ...state,
        newDispatch: {
          ...state.newDispatch,
          prices: action.payload.prices,
        },
        dispatchPriceLoading: false,
      };
    case actions.ERROR_DISPATCH_PRICE:
      return {
        ...state,
        dispatchPriceLoading: false,
      };
    case actions.FETCH_TRADING_PRICES:
      return {
        ...state,
        tradingPriceLoading: true,
      };
    case actions.UPDATE_TRADING_PRICES:
      let trading = action.payload.prices;

      if (trading.market) {
        const marketPredictionStart = trading.market.predictionStart;
        const rrp = trading.market.rrp;
        let newRrp = {};
        for (let i in rrp) {
          newRrp[marketPredictionStart + 1800 * i] = rrp[i];
        }

        trading.market.rrp = newRrp;
      } else {
        trading = null;
      }

      return {
        ...state,
        newDispatch: {
          ...state.newDispatch,
          trading: trading,
        },
        tradingPriceLoading: false,
      };
    case actions.ERROR_TRADING_PRICES:
      return {
        ...state,
        tradingPriceLoading: false,
      };
    case actions.CLEAR_NEW_DISPATCH_GRAPH_DATA:
      return {
        ...state,
        newDispatch: null,
      };
    case actions.EXECUTE_DISPATCH:
      return {
        ...state,
        executeDispatchLoading: true,
      };
    case actions.UPDATE_EXECUTE_DISPATCH:
      return {
        ...state,
        executeDispatchLoading: false,
      };
    case actions.UPDATE_PREVIOUS_DISPATCH_MESSAGES:
      return {
        ...state,
        previousDispatchMessages: action.payload.messages,
      };
    case actions.CLEAR_PREVIOUS_DISPATCH_MESSAGES:
      return {
        ...state,
        previousDispatchMessages: null,
      };
    default:
      return state;
  }
};

export default tradingReducer;

import React, { Component } from "react";
import * as ReactDOM from "react-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import WidgetContainer from "../../components/dashboard/WidgetContainer";
import { Responsive, WidthProvider } from "react-grid-layout";
import { Modal } from "../../components/layout/Modal";
import "react-grid-layout/css/styles.css";
import { WIDGET_CONFIG, getComponentClass, getCoreComponentClass } from "./widgets";
import WidgetSettings from "./WidgetSettings";
import AddWidget from "./AddWidget";
import * as dashboardActions from "../../redux/actions/dashboard";
import { Loading, Button } from "../../components/widgets";
import Page from "../../components/layout/Page";
import DashboardFullScreen from "../../components/dashboard/DashboardFullScreen";
import { anyPermissions } from "../../permissions";

const ResponsiveReactGridLayout = WidthProvider(Responsive);

const DEFAULT_DASHBOARD = {
  widgets: [{ layout: { x: 0, y: 0, w: 1, h: 2 }, widgetId: "reposit.NoWidgets" }],
};

// RFC4122 version 4 compliant solution for generating UUID4
// https://stackoverflow.com/a/2117523
const uuid4 = () => {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    // eslint-disable-next-line
    let r = (Math.random() * 16) | 0,
      v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

const mapStateToProps = state => ({
  dashboard: state.dashboard.dashboard,
  tickerEvents: state.dashboard.tickerEvents,
  isSavingDashboard: state.dashboard.saving,
  user: state.user,
});

const mapDispatchToProps = dispatch => {
  return {
    dashboardActions: bindActionCreators(dashboardActions, dispatch),
  };
};

export class Dashboard extends Component {
  constructor(props) {
    super(props);

    if (this.props.dashboard === null) {
      this.useDefaultDashboard();
    }

    this.state = {
      viewMode: "DASHBOARD",
    };
  }

  componentDidMount = () => {
    if (this.props.dashboard) {
      this.loadDashboard(this.props.dashboard);
    }

    this.props.dashboardActions.fetchDashboard();
    this.props.dashboardActions.fetchDeploymentCounts();

    window.addEventListener("resize", this.handleWindowResize);
    this.windowInnerWidth = window.innerWidth;
  };

  componentWillReceiveProps = nextProps => {
    if (nextProps.dashboard && !this.props.dashboard) {
      this.loadDashboard(nextProps.dashboard);
    }

    if (this.props.dashboard && !nextProps.dashboard) {
      this.unloadDashboard();
    }

    if (nextProps.dashboard === null) {
      this.useDefaultDashboard();
    }
  };

  componentWillUnmount = () => {
    window.removeEventListener("resize", this.handleWindowResize);
  };

  loadDashboard = dashboard => {
    const userPermissions = this.props.user.permissions;
    let widgets = [];
    for (let i in dashboard.widgets) {
      const widget = dashboard.widgets[i];
      const loadedWidget = this.loadWidget(widget);
      if (loadedWidget) {
        const widgetPermissions = loadedWidget.component.validPermissions;
        if (anyPermissions(userPermissions, widgetPermissions)) {
          widgets.push(loadedWidget);
        }
      }
    }

    this.setState({
      resizeCallbacks: {},
      dashboard: {
        widgets: widgets,
      },
    });
  };

  unloadDashboard = () => {
    this.setState({
      resizeCallbacks: null,
      dashboard: null,
    });
  };

  loadWidget = widget => {
    const componentClass = getComponentClass(widget.widgetId);
    if (componentClass) {
      return {
        ...widget,
        id: uuid4(),
        component: componentClass,
      };
    }
  };

  handleWindowResize = () => {
    if (this.windowInnerWidth !== window.innerWidth) {
      this.unloadDashboard();
      this.loadDashboard(this.props.dashboard);
      this.windowInnerWidth = window.innerWidth;
    }
  };

  saveDashboard = () => {
    const dashboard = {
      widgets: this.state.dashboard.widgets.map(w => {
        return {
          widgetId: w.widgetId,
          layout: w.layout,
          settings: w.settings,
        };
      }),
    };

    // Debounce dashboard saving as user might edit a bunch of times in quick succession.
    if (this.saveDashboardTimeout) {
      clearTimeout(this.saveDashboardTimeout);
    }
    this.saveDashboardTimeout = setTimeout(
      () => this.props.dashboardActions.saveDashboard(dashboard),
      2000
    );
  };

  useDefaultDashboard = () => {
    this.props.dashboardActions.updateDashboard({ data: DEFAULT_DASHBOARD });
  };

  registerResizeCallback = (widgetId, resizeCallback) => {
    let nextCallbacks = this.state.resizeCallbacks;
    nextCallbacks[widgetId] = resizeCallback;
    this.setState({
      resizeCallbacks: nextCallbacks,
    });
  };

  handleOnLayoutChange = layout => {
    let nextDashboard = { widgets: [] };
    for (let i in this.state.dashboard.widgets) {
      let widget = this.state.dashboard.widgets[i];
      const widgetLayout = layout[i];
      widget.layout = {
        x: widgetLayout.x,
        y: widgetLayout.y,
        w: widgetLayout.w,
        h: widgetLayout.h,
      };
      nextDashboard.widgets.push(widget);
    }

    this.setState({
      dashboard: nextDashboard,
    });

    if (this.hasUserModified) {
      this.saveDashboard();
      this.hasUserModified = false;
    }
  };

  handleOnResizeStop = (layout, oldItem, newItem, placeholder, e, element) => {
    this.state.resizeCallbacks[newItem.i]();
  };

  openWidgetSettings = widgetId => {
    const widget = this.state.dashboard.widgets.find(w => w.id === widgetId);
    const currentSettings = widget.settings;
    const widgetComponent = getCoreComponentClass(widget.component);

    this.setState({
      settings: {
        widgetId: widgetId,
        config: widgetComponent.settingsConfig,
        current: currentSettings,
      },
    });
  };

  closeWidgetSettings = () => {
    this.setState({
      settings: null,
    });
  };

  removeWidget = widgetId => {
    for (let i in this.state.dashboard.widgets) {
      if (this.state.dashboard.widgets[i].id === widgetId) {
        let nextDashboard = { ...this.state.dashboard };
        nextDashboard.widgets.splice(i, 1);
        this.setState({
          dashboard: nextDashboard,
        });
        break;
      }
    }
  };

  saveWidgetSettings = (widgetId, settings) => {
    let nextWidgets = [];

    for (let i in this.state.dashboard.widgets) {
      let widget = {
        ...this.state.dashboard.widgets[i],
      };
      if (widget.id === widgetId) {
        // Widget id is used at the react element key, so forcing it to change here causes the
        // widget to entirely re-render and re-mount ensuring that it is fully up to date.
        widget.id = uuid4();
        widget.settings = settings;
      }
      nextWidgets.push(widget);
    }

    this.setState({
      dashboard: {
        widgets: nextWidgets,
      },
    });

    this.closeWidgetSettings();
    this.saveDashboard();
  };

  openAddWidget = () => {
    this.setState({
      showAddWidget: true,
    });
  };

  closeAddWidget = () => {
    this.setState({
      showAddWidget: false,
    });
  };

  getNewWidgetY = () => {
    let y = 0;
    for (let i in this.state.dashboard.widgets) {
      const w = this.state.dashboard.widgets[i];
      if (w.layout.x === 0) {
        const thisYMax = w.layout.y + w.layout.h;
        if (thisYMax > y) {
          y = thisYMax;
        }
      }
    }
    return y;
  };

  addWidget = widgetId => {
    const noWidgetsExist = this.state.dashboard.widgets.find(
      w => w.widgetId === "reposit.NoWidgets"
    );

    let layout, currentWidgets;
    if (noWidgetsExist) {
      layout = { x: 0, y: 0, w: 1, h: 2 };
      currentWidgets = [];
    } else {
      layout = { x: 0, y: this.getNewWidgetY(), w: 1, h: 2 };
      currentWidgets = this.state.dashboard.widgets;
    }

    const widgetConfig = {
      layout: layout,
      widgetId: widgetId,
      settings: {},
    };

    const newWidget = this.loadWidget(widgetConfig);

    let nextWidgets = currentWidgets.map(w => {
      return { ...w };
    });
    nextWidgets.push(newWidget);

    this.setState(
      {
        dashboard: {
          widgets: nextWidgets,
        },
      },
      () => {
        this.closeAddWidget();
        this.openWidgetSettings(newWidget.id);
      }
    );
  };

  registerDashboard = dashboard => {
    this.dashboardElem = dashboard;
  };

  enterFullScreen = () => {
    this.setState({
      viewMode: "FULLSCREEN",
      fullScreenRunning: true,
      fullScreenWidget: 0,
    });

    const dashboard = document.getElementById("fullscreen-dashboard");

    // Supports most browsers and their versions.
    const requestMethod =
      dashboard.requestFullScreen ||
      dashboard.webkitRequestFullScreen ||
      dashboard.mozRequestFullScreen ||
      dashboard.msRequestFullScreen;

    if (requestMethod) {
      // Native full screen.
      requestMethod.call(dashboard);
    }

    this.fullScreenInterval = setInterval(this.nextFullScreenWidget, 15000);

    this.props.dashboardActions.fetchTickerEvents();
  };

  nextFullScreenWidget = () => {
    this.setState({
      fullScreenWidget: (this.state.fullScreenWidget + 1) % this.state.dashboard.widgets.length,
    });
  };

  gotoFullScreenWidget = idx => {
    // If we're currently playing then reset the timer.
    if (this.fullScreenInterval) {
      this.fullScreenInterval = clearInterval(this.fullScreenInterval);
      this.fullScreenInterval = setInterval(this.nextFullScreenWidget, 15000);
    }

    this.setState({
      fullScreenWidget: idx,
    });
  };

  exitFullScreen = () => {
    this.setState({
      viewMode: "DASHBOARD",
    });

    this.fullScreenInterval = clearInterval(this.fullScreenInterval);

    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen();
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
    } else if (document.msExitFullscreen) {
      document.msExitFullscreen();
    }
  };

  pauseFullScreen = () => {
    if (this.fullScreenInterval) {
      this.fullScreenInterval = clearInterval(this.fullScreenInterval);
    }
    this.setState({
      fullScreenRunning: false,
    });
  };

  resumeFullScreen = () => {
    this.fullScreenInterval = setInterval(this.nextFullScreenWidget, 15000);
    this.setState({
      fullScreenRunning: true,
    });
  };

  setHasUserModified = () => {
    this.hasUserModified = true;
  };

  render() {
    const fullscreenDashboardElem = document.getElementById("fullscreen-dashboard");

    if (!this.state || !this.state.dashboard) {
      return <Loading />;
    }
    if (this.state.viewMode === "FULLSCREEN") {
      return ReactDOM.createPortal(
        <DashboardFullScreen
          widget={this.state.dashboard.widgets[this.state.fullScreenWidget]}
          registerResizeCallback={this.registerResizeCallback}
          exitFullScreen={this.exitFullScreen}
          widgetCount={this.state.dashboard.widgets.length}
          fullScreenWidgetIndex={this.state.fullScreenWidget}
          pauseFullScreen={this.pauseFullScreen}
          resumeFullScreen={this.resumeFullScreen}
          isFullScreenRunning={this.state.fullScreenRunning}
          gotoFullScreenWidget={this.gotoFullScreenWidget}
          tickerEvents={this.props.tickerEvents}
          fetchTickerEvents={this.props.dashboardActions.fetchTickerEvents}
        />,
        fullscreenDashboardElem
      );
    }

    const layout = this.state.dashboard.widgets.map((w, i) => {
      return { i: w.id, ...w.layout };
    });

    const layouts = { lg: layout, md: layout, sm: layout, xs: layout, xxs: layout };

    return (
      <>
        <Page>
          <Page.Header title="Dashboard">
            <Page.Header.Actions>
              {this.props.isSavingDashboard ? (
                <div
                  style={{
                    display: "inline-block",
                    fontStyle: "italic",
                    color: "#aaa",
                    fontWeight: 100,
                    letterSpacing: "0.1em",
                  }}
                >
                  Saving...
                </div>
              ) : null}
              {this.state.dashboard.widgets.length > 0 ? (
                <Button
                  id="dashboard-go-fullscreen"
                  type="secondary"
                  icon="expand"
                  onClick={this.enterFullScreen}
                >
                  Full Screen
                </Button>
              ) : null}
              <Button
                id="dashboard-add-widget"
                type="primary"
                icon="plus"
                onClick={this.openAddWidget}
              >
                Add Widget
              </Button>
            </Page.Header.Actions>
          </Page.Header>
          <Page.Content>
            <div
              style={
                this.state.viewMode === "FULLSCREEN"
                  ? {
                      width: "100%",
                      height: "100%",
                    }
                  : {
                      marginBottom: "3em",
                    }
              }
              ref={this.registerDashboard}
            >
              <ResponsiveReactGridLayout
                rowHeight={150}
                breakpoints={{ lg: 2000, md: 1500, sm: 1000, xs: 480, xxs: 0 }}
                cols={{ lg: 5, md: 4, sm: 4, xs: 2, xxs: 1 }}
                onResizeStart={this.setHasUserModified}
                onResizeStop={this.handleOnResizeStop}
                onLayoutChange={this.handleOnLayoutChange}
                draggableHandle=".widget-drag-handle"
                verticalCompact={false}
                layouts={layouts}
                onDragStop={this.setHasUserModified}
                measureBeforeMount={true}
              >
                {this.state.dashboard.widgets.map((w, i) => {
                  return (
                    <WidgetContainer
                      key={w.id}
                      widgetId={w.id}
                      data-grid={w.layout}
                      widgetComponent={w.component}
                      settings={w.settings}
                      registerResizeCallback={this.registerResizeCallback}
                      openSettings={() => this.openWidgetSettings(w.id)}
                      removeWidget={() => this.removeWidget(w.id)}
                      settingsConfig={w.component.settingsConfig}
                    />
                  );
                })}
              </ResponsiveReactGridLayout>
            </div>
          </Page.Content>
        </Page>
        <div>
          {this.state.showAddWidget ? (
            <Modal>
              <Modal.Header title="Add Widget" />
              <Modal.Content>
                <AddWidget widgetConfig={WIDGET_CONFIG} addWidget={this.addWidget} />
              </Modal.Content>
              <Modal.Footer>
                <Button
                  id="close-add-widget-modal"
                  type="secondary"
                  icon="times"
                  onClick={this.closeAddWidget}
                >
                  Cancel
                </Button>
              </Modal.Footer>
            </Modal>
          ) : null}

          {this.state.settings ? (
            <Modal>
              <Modal.Header title="Widget Settings" />
              <WidgetSettings
                currentSettings={this.state.settings.current}
                settingsConfig={this.state.settings.config}
                onCancel={this.closeWidgetSettings}
                onSave={settings => this.saveWidgetSettings(this.state.settings.widgetId, settings)}
              />
            </Modal>
          ) : null}
        </div>
      </>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);

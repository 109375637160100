import React, { Component } from "react";
import { InfoBox, DatePicker, TimePicker, Tooltip } from "../widgets";
import "./RescheduleRertDispatch.css";
import moment from "moment-timezone";
import DispatchControlDropDown from "../trading/DispatchControlDropDown";
import StrategyDropDown from "../trading/StrategyDropDown";
import AuthRequired from "../../containers/AuthRequired";
import Permissions, { allPermissions } from "../../permissions";

class RescheduleRertDispatch extends Component {
  handleStartDateChange = startDate => {
    let nextDate = this.props.startDateTime.clone();
    nextDate.date(startDate.date());
    nextDate.month(startDate.month());
    nextDate.year(startDate.year());
    nextDate.tz(this.props.timezone);

    this.props.handleStartDateTimeChange(nextDate);
  };

  handleStartTimeChange = nextStartDateTime => {
    this.props.handleStartDateTimeChange(nextStartDateTime);
  };

  handleEndTimeChange = nextEndDateTime => {
    const duration = moment.duration(nextEndDateTime.diff(this.props.startDateTime));

    if (duration < 0) {
      return;
    }

    this.props.handleDurationChange(duration.asSeconds());
  };

  render() {
    const endDateTime = this.props.startDateTime.clone();
    endDateTime.add(this.props.duration, "seconds");

    return (
      <>
        {!this.props.startDateTime.isSame(moment().tz(this.props.timezone), "day") &&
        this.props.showMarketData &&
        !this.props.readOnly ? (
          <InfoBox
            type="warning"
            message="Predictions for future dates are likely to be inaccurate and market price predictions are unavailable."
          />
        ) : null}
        {!this.props.startDateTime.isSame(moment().tz(this.props.timezone), "day") &&
        !this.props.showMarketData &&
        !this.props.readOnly ? (
          <InfoBox
            type="warning"
            message="Predictions for future dates are likely to be inaccurate."
          />
        ) : null}
        {this.props.multipleRegions && this.props.showMarketData && !this.props.readOnly ? (
          <InfoBox type="warning" message="This Virtual Power Plant spans multiple NEM regions." />
        ) : null}
        <div className="reschedule-dispatch-info">
          {this.props.powerstation ? (
            <>
              <div>
                from
                <DatePicker
                  readOnly={this.props.readOnly}
                  placeholder="start date"
                  value={this.props.startDateTime.clone()}
                  onChange={this.handleStartDateChange}
                  minDate={moment()}
                  dateFormat="d MMMM"
                  asInline
                />
                at
                <TimePicker
                  readOnly={this.props.readOnly}
                  placeholder="start time"
                  dateTime={this.props.startDateTime}
                  onChange={this.handleStartTimeChange}
                  timezone={this.props.timezone}
                  minuteStep={5}
                  asInline
                  showNow
                />
                until
                <TimePicker
                  readOnly={this.props.readOnly}
                  placeholder="end time"
                  dateTime={endDateTime}
                  offsetFromDateTime={this.props.startDateTime}
                  onChange={this.handleEndTimeChange}
                  timezone={this.props.timezone}
                  minuteStep={5}
                  asInline
                />
                {allPermissions(this.props.userPermissions, [
                  Permissions.fleet.dispatches.strategy,
                ]) ? (
                  <br />
                ) : null}
                to
                {this.props.constraints ? (
                  <>
                    <DispatchControlDropDown
                      readOnly={true}
                      onChangeRealPowerP={this.props.onChangeRealPowerP}
                      onChangePowerFactor={this.props.onChangePowerFactor}
                      onChangePowerFactorLeadLag={this.props.onChangePowerFactorLeadLag}
                      realPowerP={this.props.realPowerP}
                      powerFactor={null}
                      powerFactorLeadLag={null}
                      realPowerPMin={this.props.constraints.realPowerPMin}
                      realPowerPMax={this.props.constraints.realPowerPMax}
                      powerFactorMin={null}
                      powerFactorMax={null}
                      powerFactorLeadLagConstraint={null}
                      powerFactorConstraintMessage={null}
                      powerFactorAllowed={false}
                      leadLagAllowed={false}
                    />
                    <AuthRequired permission={Permissions.fleet.dispatches.strategy}>
                      {this.props.realPowerP === 0 ? null : (
                        <>
                          {","}
                          <StrategyDropDown
                            readOnly={true}
                            strategy={this.props.strategy}
                            onStrategyChange={this.props.onStrategyChange}
                          />
                        </>
                      )}
                    </AuthRequired>
                  </>
                ) : (
                  " lockout"
                )}
                <Tooltip large id="dispatches.new.action" />
              </div>
            </>
          ) : null}
          {this.props.powerstation ? (
            <div className="customer-msg">
              <div className="title">Customer message:</div>
              {this.props.realPowerP !== 0 ? (
                this.props.readOnly ? (
                  <div>
                    {this.props.customerAppMessage ? (
                      <span className="ro">{this.props.customerAppMessage}</span>
                    ) : (
                      <span className="lockout-message">Default message only</span>
                    )}
                  </div>
                ) : (
                  <div
                    className="edit-container link"
                    onClick={this.props.showCustomerAppMessageEdit}
                  >
                    <span className="customer-msg-summary">
                      {this.props.customerAppMessage ? (
                        this.props.customerAppMessage
                      ) : (
                        <span className="lockout-message">Default message only</span>
                      )}
                    </span>
                    <span className="fa fa-chevron-right edit-icon" />
                  </div>
                )
              ) : (
                <div className="lockout-message">Messages are not sent for lockouts.</div>
              )}
              <Tooltip id="dispatches.new.customerMessage" />
            </div>
          ) : null}
        </div>
      </>
    );
  }
}

export default RescheduleRertDispatch;

import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Page from "../../../components/layout/Page";
import { Loading, Button } from "../../../components/widgets";
import UserForm from "../../../components/myreposit/users/UserForm";
import * as userManagementActions from "../../../redux/actions/usermanagement";
import Permissions from "../../../permissions";

const mapStateToProps = state => ({
  user: state.userManagement.activeUser,
  availablePermissions: state.userManagement.availablePermissions,
});

const mapDispatchToProps = dispatch => {
  return {
    userManagementActions: bindActionCreators(userManagementActions, dispatch),
  };
};

class UserView extends Component {
  componentDidMount = () => {
    const userId = this.props.match.params.id;
    this.props.userManagementActions.fetchUser(userId);
    this.props.userManagementActions.fetchAvailablePermissionsForOrg();
  };

  componentWillUnmount = () => {
    this.props.userManagementActions.clearActiveUser();
  };

  render() {
    if (!this.props.user || !this.props.availablePermissions) {
      return <Loading />;
    }

    const user = this.props.user;

    return (
      <Page permission={Permissions.fleet.myReposit.users}>
        <Page.Header title={`${user.givenName} ${user.surname}`}>
          <Page.Header.Actions>
            <Button
              id="my-reposit-edit-user"
              type="primary"
              icon="pencil"
              to={`/my-reposit/users/${this.props.match.params.id}/edit`}
            >
              Edit
            </Button>
          </Page.Header.Actions>
        </Page.Header>
        <Page.Content>
          <UserForm
            readOnly
            givenName={user.givenName}
            surname={user.surname}
            email={user.email}
            availablePermissions={this.props.availablePermissions.sort((a, b) => {
              const aName = a.displayName.toLowerCase();
              const bName = b.displayName.toLowerCase();
              if (aName < bName) return -1;
              if (aName > bName) return 1;
              return 0;
            })}
            userPermissions={user.permissions}
          />
        </Page.Content>
      </Page>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserView);

import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Loading, Button } from "../../../components/widgets";
import * as dashboardActions from "../../../redux/actions/dashboard";
import Permissions from "../../../permissions";
import DashboardWidgetComponent from "./DashboardWidgetComponent";
import "./SingleStat.css";

const mapStateToProps = state => ({
  deploymentCounts: state.dashboard.deploymentCounts,
});

const mapDispatchToProps = dispatch => {
  return {
    dashboardActions: bindActionCreators(dashboardActions, dispatch),
  };
};

class SingleStat extends DashboardWidgetComponent {
  static widgetId = "reposit.SingleStat";
  static className = "single-stat-widget";
  static widgetName = "Single Stat";
  static description = "Show a single figure for a variety of metrics.";
  static fullScreenLogo = "white";
  static settingsConfig = [
    {
      name: "metric",
      displayName: "What nodes do you want to display?",
      tooltipId: "dashboard.singleStat.metrics",
      type: "select",
      options: [
        { value: "TOTAL_NODES", label: "Commissioned Nodes" },
        { value: "NOT_COMMISSIONED_NODES", label: "Not Commissioned Nodes" },
        { value: "OFFLINE_NODES", label: "Offline Nodes" },
      ],
    },
  ];
  static validPermissions = [Permissions.fleet.node.any];

  componentDidMount = () => {
    const { title } = this.getTitleAndClass();
    this.setTitle(title);
  };

  componentWillReceiveProps = nextProps => {
    if (this.props.deploymentCounts !== nextProps.deploymentCounts && nextProps.deploymentCounts) {
      const count = this.getCount(nextProps);
      const { title } = this.getTitleAndClass(count);
      this.setTitle(title);
    }
  };

  getTitleAndClass = count => {
    let { metric } = this.props.settings;

    let title, className;
    switch (true) {
      case metric === "TOTAL_NODES":
        title = count === 1 ? "Commissioned Node" : "Commissioned Nodes";
        className = "commissioned";
        break;
      case metric === "NOT_COMMISSIONED_NODES":
        title = count === 1 ? "Not Commissioned Node" : "Not Commissioned Nodes";
        className = "not-commissioned";
        break;
      case metric === "OFFLINE_NODES":
        title = count === 1 ? "Offline Node" : "Offline Nodes";
        title = "Offline Nodes";
        className = "offline";
        break;
      default:
        title = "Not configured";
        className = "single-stat-unconfigured";
    }

    return {
      title,
      className,
    };
  };

  getCount = props => {
    if (!this.props.deploymentCounts) {
      return undefined;
    }

    let { metric } = props.settings;

    let count;
    switch (true) {
      case metric === "TOTAL_NODES":
        return props.deploymentCounts.commissioned;
      case metric === "NOT_COMMISSIONED_NODES":
        return props.deploymentCounts.non_commissioned;
      case metric === "OFFLINE_NODES":
        return props.deploymentCounts.offline;
      default:
        count = "";
    }

    return count;
  };

  render() {
    const count = this.getCount(this.props);
    const { title, className } = this.getTitleAndClass(count);
    const metric = this.props.settings.metric;

    return (
      <div className={`single-stat ${className}`}>
        {this.props.deploymentCounts ? (
          <>
            <div className="counter">{count}</div>
            <div className="description">{title}</div>

            {metric !== "TOTAL_NODES" && !this.props.fullscreen && count > 0 ? (
              <div className="explore-link">
                <Button
                  type="link"
                  style={{
                    color: "white",
                    fontWeight: 300,
                  }}
                  to={`/fleet-monitoring/nodes?status=${
                    metric === "NOT_COMMISSIONED_NODES" ? "Not Commissioned" : "Offline"
                  }`}
                >
                  View in Fleet Monitoring
                </Button>
              </div>
            ) : null}
          </>
        ) : (
          <Loading inverse />
        )}
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SingleStat);

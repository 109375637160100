import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import "./UserMenu.css";

class UserMenu extends Component {
  render() {
    return (
      <div className="user-menu">
        <div className="tip" />
        <div className="full-name-container">
          <p className="logged-in-as">Logged in as</p>
          <p className="full-name">{this.props.userFullName}</p>
          <p className="org-name">{this.props.orgName}</p>
        </div>
        <ul className="user-menu-options">
          <li className="settings" onClick={() => this.props.history.push("/user/settings")}>
            <span className="link">User Settings</span>
          </li>
          <li className="logout" onClick={this.props.handleLogout}>
            <span className="link">Log Out</span>
          </li>
        </ul>
      </div>
    );
  }
}

export default withRouter(UserMenu);

import React, { Component } from "react";
import "./ExportDeploymentsTable.css";
import { getAddressLine1, getAddressLine2 } from "../../utils/formatting";
import Button from "../widgets/Button";

export default class ExportDeploymentsTable extends Component {
  render() {
    const deployments = this.props.deployments;

    if (!deployments || deployments.length === 0) {
      return <div className="fleet-table-no-results">No nodes selected</div>;
    }

    return (
      <table className="fleet-table">
        <thead>
          <tr>
            <th>Serial Number / Label</th>
            <th>Install Address</th>
            <th>NMI</th>
            <th />
          </tr>
        </thead>
        <tbody>
          {deployments.map(deployment => {
            return (
              <tr key={deployment.id} className="fleet-table-row">
                <td>
                  <div>{deployment.system.reposit.serialNumber}</div>
                  <div className="site-label-cell">{deployment.siteLabel}</div>
                </td>
                <td>
                  {getAddressLine1(deployment.address)}
                  <br />
                  {getAddressLine2(deployment.address)}
                </td>
                <td>{deployment.network.nmi}</td>
                <td>
                  <Button
                    type="link"
                    icon="trash"
                    onClick={() => this.props.onRemoveDeployment(deployment)}
                  >
                    Remove
                  </Button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  }
}

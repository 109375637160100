import React, { Component } from "react";
import DeckGL, { IconLayer } from "deck.gl";

const ICON_SIZE = 150;

export default class DeploymentMapSingleLayer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      x: 0,
      y: 0,
      hoveredItems: null,
      expanded: false,
    };
  }

  render() {
    const { viewport, latitude, longitude, iconAtlas, iconMapping } = this.props;

    if (!latitude || !longitude || !iconMapping) {
      return null;
    }

    const deploymentLayer = new IconLayer({
      id: "deployment",
      data: [{ coordinates: [longitude, latitude] }],
      iconAtlas,
      iconMapping,
      getPosition: d => d.coordinates,
      getIcon: d => `dark${this.props.iconNameSuffix}`,
      getSize: d => (this.props.iconSize ? this.props.iconSize : ICON_SIZE),
    });

    return <DeckGL {...viewport} layers={[deploymentLayer]} />;
  }
}

import React, { Component } from "react";
import { FormattedNumber } from "react-intl";
import Slider from "rc-slider";
import Tooltip from "rc-tooltip";
import "./CurtailmentControlDropDown.css";

const Handle = Slider.Handle;

const formatTooltip = (val, positiveLabel, negativeLabel, zeroLabel) => {
  const roundedVal = Number(val.toFixed(2));
  if (roundedVal > 0) {
    return roundedVal + (positiveLabel ? positiveLabel : "");
  } else if (roundedVal < 0) {
    return -roundedVal + (negativeLabel ? negativeLabel : "");
  } else {
    return zeroLabel ? zeroLabel : 0;
  }
};

const handle = (hover, value, positiveLabel, negativeLabel, zeroLabel) => {
  return props => {
    const { index, dragging, ...restProps } = props;
    return (
      <Tooltip
        overlay={formatTooltip(value, positiveLabel, negativeLabel, zeroLabel)}
        visible={hover || dragging}
        placement="right"
        key={index}
        style={{ zIndex: 999999 }}
      >
        <Handle {...restProps} />
      </Tooltip>
    );
  };
};

class CurtailmentControl extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hoverRealPowerP: false,
    };
  }

  isHovering = (key, state) => this.setState({ [key]: state });

  render() {
    const { realPowerPMin, realPowerP, onChangeRealPowerP } = this.props;
    return (
      <div className="curtailment-controls-wrapper">
        <div className="curtailment-controls">
          <div className="content">
            <>
              <div className="headers" />
              <div
                style={{ margin: "0 23px" }}
                onMouseEnter={() => this.isHovering("hoverRealPowerP", true)}
                onMouseLeave={() => this.isHovering("hoverRealPowerP", false)}
              >
                <Slider
                  key="real-power-slider"
                  vertical={false}
                  onChange={onChangeRealPowerP}
                  min={realPowerPMin}
                  max={0}
                  handle={handle(this.state.hoverRealPowerP, realPowerP, "", "kW curtailment")}
                  value={realPowerP}
                  step={0.1}
                  included={true}
                  style={{ width: "100%" }}
                  handleStyle={{
                    border: "1px solid #E5E5E5",
                    width: "39px",
                    height: "39px",
                    borderRadius: "20px",
                  }}
                  railStyle={{
                    width: "100%",
                    height: "8px",
                    borderRadius: "2px",
                  }}
                  maximumTrackStyle={{ backgroundColor: "rgb(230,236,247)" }}
                />
              </div>

              <div className="footers">
                <div>
                  <FormattedNumber value={realPowerPMin} maximumFractionDigits={2} /> kW
                </div>
                <div>0 kW</div>
              </div>
            </>
          </div>
        </div>
      </div>
    );
  }
}

class CurtailmentControlDropDown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showCurtailmentControls: false,
    };
  }

  handleOnSelectedClick = () => {
    this.setState(prevState => ({
      showCurtailmentControls: !prevState.showCurtailmentControls,
    }));
  };

  hideCurtailmentControls = e => {
    // only change state if focus is moving outside dropdown
    if (!this.dropdownNode.contains(e.relatedTarget)) {
      this.setState({
        showCurtailmentControls: false,
      });
    }
  };

  registerCurrentValue = currentValueElement => {
    this.currentValueElement = currentValueElement;
  };

  getCurrentPowerDisplay = () => {
    const { realPowerP } = this.props;

    let display;

    if (realPowerP <= 0) {
      display = `curtail at ${realPowerP} kW`;
    } else {
      display = ``;
    }

    return display;
  };

  render() {
    if (this.props.readOnly) {
      return (
        <div className="curtailment-controls-drop-down ro">
          <span className="current-value" ref={this.registerCurrentValue}>
            {this.getCurrentPowerDisplay()}
          </span>
        </div>
      );
    } else {
      return (
        <div
          className={"curtailment-controls-drop-down"}
          tabIndex="0"
          onBlur={this.hideCurtailmentControls}
          ref={ref => (this.dropdownNode = ref)}
        >
          <span
            className="current-value"
            ref={this.registerCurrentValue}
            onClick={this.props.readOnly ? null : this.handleOnSelectedClick}
          >
            {this.getCurrentPowerDisplay()}
            <i className="fa fa-angle-down" />
          </span>
          {this.state.showCurtailmentControls ? (
            <div className="curtailment-controls-container">
              <div className="tip" />
              <CurtailmentControl {...this.props} />
            </div>
          ) : null}
        </div>
      );
    }
  }
}

export default CurtailmentControlDropDown;

import React, { Component } from "react";
import Page from "../../components/layout/Page";
import Permissions from "../../permissions";
import HubspotContactForm from "../../components/hubspot/HubspotContactForm";

class SupportIssues extends Component {

  render() {
    return (
      <Page permission={Permissions.fleet.node.any}>
        <Page.Header title="Support Issues">
          <Page.Header.Actions></Page.Header.Actions>
        </Page.Header>

        <Page.Content>
          <div style={{ width: "100%", height: "100%" }}>
            <HubspotContactForm
              region={process.env.REACT_APP_HUBSPOT_REGION}
              portalId={process.env.REACT_APP_HUBSPOT_PORTALID}
              formId={process.env.REACT_APP_HUBSPOT_FORMID}
            />
          </div>
        </Page.Content>
      </Page>
    );
  }
}

export default SupportIssues;

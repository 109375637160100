import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import * as userActions from "../../redux/actions/user";
import { Button, Loading } from "../../components/widgets";

const mapStateToProps = state => ({
  user: state.user,
});

const mapDispatchToProps = dispatch => {
  return {
    userActions: bindActionCreators(userActions, dispatch),
  };
};

class ForgotPasswordForm extends Component {
  constructor() {
    super();
    this.state = {
      email: "",
    };
  }

  componentWillUnmount = () => {
    this.props.userActions.clearPasswordReset();
  };

  handleEmailChange = event => {
    this.setState({ email: event.target.value });
  };

  requestPasswordReset = e => {
    e.preventDefault();
    this.props.userActions.requestPasswordReset(this.state.email);
  };

  render() {
    if (this.props.user.passwordReset && this.props.user.passwordReset.status === "FETCHING") {
      return (
        <div style={{ height: "10em" }}>
          <Loading />
        </div>
      );
    } else if (this.props.user.passwordReset && this.props.user.passwordReset.status === "OK") {
      return (
        <span>
          <div className="message-container">
            <div className="message">{this.props.user.passwordReset.message}</div>
          </div>
        </span>
      );
    } else {
      return (
        <span>
          <div className="message-container">
            {this.props.user.passwordReset && this.props.user.passwordReset.status === "ERROR" ? (
              <div className="message">Well that didn't work. Please try again.</div>
            ) : null}
          </div>
          <div className="message-container">
            <div className="message">
              Enter your email address below to reset your password. You will be sent an email which
              you will need to open to continue. You may need to check your spam folder.
            </div>
          </div>
          <div className="login-container forgot-password">
            <form className="login-form" onSubmit={this.requestPasswordReset}>
              <input
                type="text"
                onChange={this.handleEmailChange}
                autoComplete="off"
                value={this.state.email}
                placeholder="Email Address"
              />
              <Button id="send-password-reset" type="primary" onClick={this.requestPasswordReset}>
                Send Password Reset
              </Button>
            </form>
            <div className="login-links">
              <Link to="/" className="link">
                Back to Login
              </Link>
            </div>
          </div>
        </span>
      );
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPasswordForm);

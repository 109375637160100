import React, { Component } from "react";
import { connect } from "react-redux";
import { getCoreComponentClass } from "./widgets";
import { anyPermissions } from "../../permissions";
import "./AddWidget.css";

const mapStateToProps = state => ({
  user: state.user,
});

class AddWidget extends Component {
  render() {
    const userPermissions = this.props.user.permissions;

    const widgetsForUser = this.props.widgetConfig.widgets.filter(w => {
      const widgetComponent = getCoreComponentClass(w.component);
      const widgetPermissions = widgetComponent.validPermissions;

      if (anyPermissions(userPermissions, widgetPermissions)) {
        return w;
      }

      return null;
    });

    return (
      <div className="dashboard-add-widget-container">
        {widgetsForUser.map(w => {
          const widgetComponent = getCoreComponentClass(w.component);
          return (
            <div key={w.id} className="add-widget-tile" onClick={() => this.props.addWidget(w.id)}>
              <h3>{widgetComponent.widgetName}</h3>
              <div className="widget-description">{widgetComponent.description}</div>
            </div>
          );
        })}
      </div>
    );
  }
}

export default connect(mapStateToProps)(AddWidget);

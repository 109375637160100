import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Button } from "../../../components/widgets/index";
import * as userActions from "../../../redux/actions/user";
import QRCode from "qrcode.react";

const mapStateToProps = state => ({
  user: state.user,
});

const mapDispatchToProps = dispatch => {
  return {
    userActions: bindActionCreators(userActions, dispatch),
  };
};

class TOTPForm extends Component {
  constructor() {
    super();
    this.state = {
      totp: "",
      error: null,
      rememberDevice: false,
    };
  }

  componentWillReceiveProps = nextProps => {
    if (nextProps.user.totp && this.props.user.totp !== nextProps.user.totp) {
      this.setState({
        error: nextProps.user.totp.errorMessage,
      });
    }
  };

  verifyTotp = event => {
    event.preventDefault();
    const totp = this.state.totp.replace(/ /g, "");

    if (/^\d{6}$/.test(totp)) {
      this.props.userActions.submitTotpCode(totp, this.state.rememberDevice);
    } else {
      this.setState({ error: "Your code should be 6 digits." });
    }
  };

  handleTotpChange = event => {
    const newTotp = event.target.value;
    let newStateValues = { totp: newTotp };
    if (/^\d{6}$/.test(newTotp.replace(/ /g, ""))) {
      newStateValues.error = null;
    }
    this.setState(newStateValues);
  };

  handleRememberDeviceChange = event => {
    this.setState({
      rememberDevice: event.target.checked,
    });
  };

  requestSMSCode = () => {
    this.props.userActions.requestSMSCode();
  };

  logout = () => {
    this.props.userActions.logout();
  };

  render() {
    const totpConfig = this.props.user.totpConfig;

    return (
      <span>
        <h1>Multi-Factor Authentication</h1>

        {totpConfig.confirmed ? (
          totpConfig.codePreference === "APP" ? (
            <div style={{ padding: 0, marginRight: "-3em", marginLeft: "-3em" }}>
              <p>Please enter your 6-digit code from your authenticator app.</p>
              {totpConfig.phoneNumberConfirmed ? (
                <p>
                  Alternatively, you can request a code to be sent to{" "}
                  <span style={{ whiteSpace: "nowrap" }}>{totpConfig.phoneNumberMasked}</span> by
                  clicking here:{" "}
                  <Button
                    id="mfa-send-sms-code-for-login"
                    type="link"
                    onClick={this.requestSMSCode}
                  >
                    Send SMS Code
                  </Button>
                </p>
              ) : null}
            </div>
          ) : (
            <p>
              An SMS have been sent to {totpConfig.phoneNumberMasked}. Please enter the 6-digit code
              from this message below.
            </p>
          )
        ) : (
          <>
            <p style={{ padding: 0, marginRight: "-3em", marginLeft: "-3em" }}>
              Your organisation has been configured to use multi-factor authentication (MFA). To
              complete your login and use Reposit Fleet you must set up an authenticator app by
              scanning the QR code below. Google Authenticator is available as a free download via
              the{" "}
              <a
                href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en"
                target="_blank"
                rel="noopener noreferrer"
              >
                Google Play
              </a>{" "}
              and{" "}
              <a
                href="https://itunes.apple.com/au/app/google-authenticator/id388497605?mt=8"
                target="_blank"
                rel="noopener noreferrer"
              >
                App Store
              </a>
              .
            </p>
            <p style={{ padding: "2em 0", marginRight: "-3em", marginLeft: "-3em" }}>
              Follow the instructions in your Authenticator app and then enter the 6-digit code to
              proceed. Please note that the authenticator app will required for each login and once
              you have configured it this first time you will be unable to view the QR code again.
              MFA settings can be adjusted via your user settings once logged in.
            </p>
            <p style={{ marginTop: "1em" }}>
              <QRCode value={totpConfig.totpUri} />
            </p>
          </>
        )}

        <div className="totp-container totp-form-container">
          <form className="totp-form" onSubmit={this.verifyTotp}>
            <div className="message-container">
              {this.state.error ? <div className="login-message">{this.state.error}</div> : null}
            </div>
            <input
              autoFocus
              type="text"
              placeholder="Enter the 6-digit code"
              autoComplete="off"
              onChange={this.handleTotpChange}
              value={this.state.totp}
            />
            <div style={{ display: "flex", marginTop: "1.5em" }}>
              <div style={{ paddingTop: "2px" }}>
                <input
                  id="remember-me"
                  type="checkbox"
                  style={{ width: "initial", paddingTop: "5px" }}
                  checked={this.state.rememberDevice}
                  onChange={this.handleRememberDeviceChange}
                />
              </div>
              <div style={{ textAlign: "left" }}>
                <label htmlFor="remember-me" style={{ fontSize: "1em" }}>
                  Remember this browser
                  <p style={{ fontSize: "0.9em", padding: 0, color: "#9ea1a5" }}>
                    Checking this box will not require a 2FA code for 2 weeks. You will still need
                    your username/password if your sessions expires.
                  </p>
                </label>
              </div>
            </div>
            <Button id="log-in" onClick={this.verifyTotp} type="primary" style={{ marginLeft: 0 }}>
              Log In
            </Button>
            <div style={{ flex: 1 }}>
              <Button id="cancel-totp" onClick={this.logout} type="link">
                {"< Back to Login"}
              </Button>
            </div>
          </form>
        </div>
      </span>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TOTPForm);

import React, { Component } from "react";
import "./Unconfigured.css";

class Unconfigured extends Component {
  render() {
    return <div className="unconfigured">Not configured</div>;
  }
}

export default Unconfigured;

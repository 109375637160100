import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { Button, Loading } from "../../components/widgets";
import PasswordInstructions from "../../components/user/PasswordInstructions";
import * as userActions from "../../redux/actions/user";
import queryString from "query-string";
import "./ResetPasswordForm.css";

const mapStateToProps = state => ({
  user: state.user,
});

const mapDispatchToProps = dispatch => {
  return {
    userActions: bindActionCreators(userActions, dispatch),
  };
};

class ResetPasswordForm extends Component {
  constructor() {
    super();
    this.state = {
      password: "",
      confirmPassword: "",
    };
  }

  componentDidMount = () => {
    const token = this.getToken();
    this.props.userActions.verifyPasswordResetToken(token);
  };

  componentWillUnmount = () => {
    this.props.userActions.clearPasswordReset();
  };

  getToken = () => {
    const { token } = queryString.parse(this.props.location.search);
    return token;
  };

  handlePasswordChange = event => {
    this.setState({ password: event.target.value });
  };

  handleConfirmPasswordChange = event => {
    this.setState({ confirmPassword: event.target.value });
  };

  resetPasswordChange = () => {
    this.props.userActions.clearChangePassword();
    this.setState({
      validationMessage: "",
    });
  };

  changePassword = e => {
    e.preventDefault();
    this.resetPasswordChange();
    if (this.state.password !== this.state.confirmPassword) {
      this.setState({ validationMessage: "Passwords do not match." });
      return;
    }

    this.props.userActions.changePassword(
      this.state.password,
      this.state.confirmPassword,
      this.getToken()
    );
  };

  render() {
    if (
      this.props.user.passwordReset &&
      (this.props.user.passwordReset.status === "FETCHING" ||
        this.props.user.passwordReset.status === undefined)
    ) {
      return (
        <div style={{ height: "5em" }}>
          <Loading />
        </div>
      );
    } else if (
      this.props.user.passwordReset &&
      this.props.user.passwordReset.tokenValid === false
    ) {
      return (
        <span>
          <div className="message-container">
            <div className="message">
              Your reset password link is no longer valid. You may request a new password reset by
              visiting the <Link to="/password/forgot">Forgot Password</Link> form.
            </div>
          </div>
        </span>
      );
    } else if (
      this.props.user.passwordReset &&
      this.props.user.passwordReset.status === "PASSWORD_RESET"
    ) {
      return (
        <span>
          <div className="message-container">
            <div className="message">
              Your password has been reset. You may now <Link to="/">login</Link> to Reposit Fleet.
            </div>
          </div>
        </span>
      );
    } else {
      return (
        <span>
          <div className="message-container reset-password">
            {(this.props.user.passwordReset && this.props.user.passwordReset.status === "ERROR") ||
            this.state.validationMessage ? (
              <div className="message validation">
                {this.props.user.passwordReset.status === "ERROR"
                  ? this.props.user.passwordReset.message
                    ? this.props.user.passwordReset.message
                    : "Well that didn't work. Please try again."
                  : null}
                {this.props.user.passwordReset &&
                this.props.user.passwordReset.status === "ERROR" &&
                this.state.validationMessage ? (
                  <br />
                ) : null}
                {this.state.validationMessage}
              </div>
            ) : null}
          </div>
          <div className="login-container password-reset">
            <form className="login-form" onSubmit={this.changePassword}>
              <input
                autoComplete="off"
                type="password"
                onChange={this.handlePasswordChange}
                value={this.state.password}
                placeholder="New Password"
              />
              <input
                autoComplete="off"
                type="password"
                onChange={this.handleConfirmPasswordChange}
                value={this.state.confirmPassword}
                placeholder="Confirm New Password"
              />
              <Button id="reset-password" type="primary" onClick={this.changePassword}>
                Reset Password
              </Button>
            </form>
            <PasswordInstructions />
          </div>
        </span>
      );
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ResetPasswordForm));

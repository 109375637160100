import { Component } from "react";

class DashboardWidgetComponent extends Component {
  setTitle = (title, description) => {
    this.props.setTitle(title, description);
  };

  setWidgetClassName = className => {
    this.props.setWidgetClassName(className);
  };

  error = () => {
    this.props.onError();
  };
}

export default DashboardWidgetComponent;

export const METRICS = [
  {
    metric: "meter power",
    label: "Meter Power (P)",
    retailer: true,
    network: true,
  },
  {
    metric: "meter voltage",
    label: "Meter Voltage",
    retailer: false,
    network: true,
  },
  {
    metric: "meter power reactive",
    label: "Reactive Meter Power (Q)",
    retailer: false,
    network: true,
  },
  {
    metric: "meter frequency",
    label: "Meter Frequency",
    retailer: true,
    network: true,
  },
  {
    metric: "solar power",
    label: "Solar Power (P)",
    retailer: true,
    network: true,
  },
  {
    metric: "meter sga power",
    label: "Meter SGA Power (P)",
  },
  {
    metric: "battery power",
    label: "Battery Power (P)",
    retailer: true,
    network: true,
  },
  {
    metric: "inverter power reactive",
    label: "Reactive Inverter Power (Q)",
    retailer: false,
    network: true,
  },
  {
    metric: "remaining charge",
    label: "Battery Stored Energy",
    retailer: true,
    network: true,
  },
  {
    metric: "meter current",
    label: "Meter Current",
    retailer: false,
    network: true,
  },
];

export const SUBMETER_METRICS = {
  "meter power": {
    metric: "submeter power",
    label: "Submeter Power (P)",
    retailer: true,
    network: true,
  },
  "meter power reactive": {
    metric: "submeter power reactive",
    label: "Reactive Submeter Power (Q)",
    retailer: false,
    network: true,
  },
};

export const CUSTOMER_TASK = "customer";
export const OPERATIONAL_TASK = "operational";

export function getMetricDisplayName(metric) {
  const allMetrics = METRICS.concat(Object.values(SUBMETER_METRICS));
  const metricConf = allMetrics.find(m => m.metric === metric);
  if (metricConf) {
    return metricConf.label;
  } else {
    return metric;
  }
}

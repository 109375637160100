import React, { Component } from "react";
import { VictoryArea, VictoryAxis, VictoryLabel, VictoryBrushContainer } from "victory";
import { getDateAsString, getTimeAsString } from "../widgets/Moment";
import { durationToString } from "../../utils/formatting";
import moment from "moment-timezone";
import "./NewDispatchGraph.css";

const PADDING_PCT = 0.22;
const INTERVAL = 1800;

class NewNewDispatchGraph extends Component {
  constructor(props) {
    super(props);

    this.state = {
      graphWidth: null,
      graphHeight: null,
    };
  }

  getGraphData = (
    prediction,
    adjustedPrediction,
    marketPrediction,
    startTime,
    dispatchStartTime,
    duration,
    timezone
  ) => {
    let timestampTicks = {};
    let zebraTicks = {};
    let counter = 0;
    for (let i = 0; i < 48; i++) {
      const timestamp = startTime + INTERVAL * i;
      timestampTicks[timestamp * 1000] = { timestamp: timestamp * 1000, display: "" };

      // Zebra striped lines occur every 4th line i.e. every second hour.
      if (counter % 4 === 1) {
        zebraTicks[timestamp * 1000] = { timestamp: timestamp * 1000, display: "" };
      }

      counter++;
    }

    let predictionData;
    if (prediction) {
      predictionData = [];
      Object.keys(prediction)
        .sort()
        .forEach(function (k) {
          const timestamp = parseInt(k, 10);
          predictionData.push({
            x: timestamp * 1000,
            y: prediction[k],
            timestamp: timestamp * 1000,
          });
        });
    }

    let adjustedPredictionData;
    if (adjustedPrediction) {
      adjustedPredictionData = [];
      Object.keys(adjustedPrediction)
        .sort()
        .forEach(function (k) {
          const timestamp = parseInt(k, 10);
          adjustedPredictionData.push({
            x: timestamp * 1000,
            y: adjustedPrediction[k],
            timestamp: timestamp * 1000,
          });
        });
    }

    let marketPredictionData;
    if (marketPrediction) {
      marketPredictionData = [];
      Object.keys(marketPrediction)
        .sort()
        .forEach(function (k) {
          const timestamp = parseInt(k, 10);
          marketPredictionData.push({
            x: timestamp * 1000,
            y: marketPrediction[k],
            timestamp: timestamp * 1000,
          });
        });
    }

    let highlightedTimestampTicks = {};
    // Set up a highlighted vertical line for the start time of the dispatch.
    const jsStartDate = new Date(dispatchStartTime * 1000);
    const startDateStr = getDateAsString(jsStartDate, timezone);
    const startTimeStr = getTimeAsString(jsStartDate, timezone, "HH:mm");
    highlightedTimestampTicks[dispatchStartTime * 1000] = {
      timestamp: dispatchStartTime * 1000,
      display: startDateStr + "\n" + startTimeStr,
    };

    // Set up a highlighted vertical line for the end time of the dispatch.
    const dispatchEndTime = dispatchStartTime + duration;
    const jsEndDate = new Date(dispatchEndTime * 1000);
    const endTimeStr = getTimeAsString(jsEndDate, timezone, "HH:mm");
    highlightedTimestampTicks[dispatchEndTime * 1000] = {
      timestamp: dispatchEndTime * 1000,
      display: durationToString(duration) + "\n" + endTimeStr,
    };

    return {
      predictionData: predictionData,
      adjustedPredictionData: adjustedPredictionData,
      tickDisplay: timestampTicks,
      zebraTickDisplay: zebraTicks,
      highlightedTickDisplay: highlightedTimestampTicks,
      marketPredictionData: marketPredictionData,
    };
  };

  registerGraphContainer = graphContainer => {
    if (graphContainer) {
      this.setState({
        graphWidth: graphContainer.clientWidth,
        graphHeight: graphContainer.clientHeight,
      });
    }
  };

  getPredictionDomain = (prediction, adjustedPrediction, startTimestamp, endTimestamp) => {
    let min = null;
    let max = null;

    if (prediction) {
      Object.keys(prediction)
        .sort()
        .forEach(function (k) {
          min = prediction[k] < min || min === null ? prediction[k] : min;
          max = prediction[k] > max || max === null ? prediction[k] : max;
        });
    }

    if (adjustedPrediction) {
      Object.keys(adjustedPrediction)
        .sort()
        .forEach(function (k) {
          min = adjustedPrediction[k] < min || min === null ? adjustedPrediction[k] : min;
          max = adjustedPrediction[k] > max || max === null ? adjustedPrediction[k] : max;
        });
    }

    min = min === 0 ? -3 : min - Math.abs(min * PADDING_PCT);
    max = max === 0 ? 3 : max + Math.abs(max * PADDING_PCT);

    return {
      x: [startTimestamp * 1000, endTimestamp * 1000],
      y: [min, max],
    };
  };

  getMarketDomain = (marketPrediction, startTimestamp, endTimestamp) => {
    let min = null;
    let max = null;

    if (marketPrediction) {
      Object.keys(marketPrediction)
        .sort()
        .forEach(function (k) {
          min = marketPrediction[k] < min || min === null ? marketPrediction[k] : min;
          max = marketPrediction[k] > max || max === null ? marketPrediction[k] : max;
        });
    }

    min = min - 5;
    max = max + 5;

    return {
      x: [startTimestamp * 1000, endTimestamp * 1000],
      y: [min, max],
    };
  };

  handleBrushOnChange = domain => {
    const brushStart = Math.round(domain.x[0] / 1000);
    const brushEnd = Math.round(domain.x[1] / 1000);

    const brushStartSnapped = brushStart - (brushStart % 900);
    const brushEndSnapped = brushEnd - (brushEnd % 900);
    const duration = brushEndSnapped - brushStartSnapped;

    if (this.props.dispatchStartTimestamp !== brushStartSnapped) {
      const startDateTime = moment(new Date(brushStartSnapped * 1000)).tz(this.props.timezone);
      this.props.handleStartDateTimeChange(startDateTime, true);
    }

    if (this.props.duration !== duration) {
      this.props.handleDurationChange(duration);
    }
  };

  render() {
    const {
      prediction,
      adjustedPrediction,
      marketPrediction,
      startTimestamp,
      dispatchStartTimestamp,
      duration,
      timezone,
    } = this.props;
    const {
      predictionData,
      adjustedPredictionData,
      marketPredictionData,
      tickDisplay,
      zebraTickDisplay,
      highlightedTickDisplay,
    } = this.getGraphData(
      prediction,
      adjustedPrediction,
      marketPrediction,
      startTimestamp,
      dispatchStartTimestamp,
      duration,
      timezone
    );

    const endTimestamp = startTimestamp + INTERVAL * 48;

    const width = this.state.graphWidth;
    const height = this.state.graphHeight;
    const bottomPadding = 18;
    const padding = { top: 0, right: 0, bottom: 0, left: 0 };

    const predictionDomain = this.getPredictionDomain(
      prediction,
      adjustedPrediction,
      startTimestamp,
      endTimestamp
    );
    const marketDomain = this.getMarketDomain(marketPrediction, startTimestamp, endTimestamp);

    return (
      <div className="new-dispatch-response-graph">
        <div className="legend-container">
          <div className="legend">
            <span className="key-style prediction" />
            <span className="key-label">Grid Prediction</span>
          </div>
          <div className="legend">
            <span className="key-style adjusted-prediction" />
            <span className="key-label">Adjusted Grid Prediction</span>
          </div>
          {marketPrediction && (
            <div className="legend">
              <span className="key-style market-prediction" />
              <span className="key-label">Market Price Prediction</span>
            </div>
          )}
        </div>
        <div className="graph-container" ref={this.registerGraphContainer}>
          {this.state.graphWidth && (
            <svg
              width={width}
              height={height + bottomPadding}
              role="img"
              aria-labelledby="undefined-title undefined-desc"
              viewBox={`0 0 ${width} ${height + bottomPadding}`}
              overflow="visible"
              style={{
                width: `${width}px`,
                height: `${height + bottomPadding}px`,
                userSelect: "none",
                touchAction: "none",
                fill: "#ffffff",
              }}
            >
              <rect
                x="0"
                y="0"
                fill="#fff"
                style={{
                  width: "calc(100%)",
                  height: `calc(100% - ${bottomPadding}px)`,
                  stroke: "#E8E8E9",
                  strokeWidth: 1,
                }}
              />

              <g transform="translate(0, 0)">
                {/* x-axis thick grid lines/zebra striping */}
                <VictoryAxis
                  width={width}
                  height={height}
                  padding={padding}
                  domain={predictionDomain}
                  style={{
                    axis: { stroke: "none" },
                    grid: {
                      stroke: "rgba(219, 226, 233, 0.3)",
                      strokeWidth: width / ((Object.keys(tickDisplay).length - 1) / 2),
                    },
                  }}
                  orientation="bottom"
                  tickValues={Object.keys(zebraTickDisplay).map(Number)}
                  tickFormat={() => ""}
                  standalone={false}
                />

                {/* x-axis graph start label */}
                <VictoryAxis
                  width={width}
                  height={height + bottomPadding * 2}
                  padding={padding}
                  domain={predictionDomain}
                  style={{
                    axis: { stroke: "none" },
                    grid: { stroke: "none" },
                    tickLabels: {
                      fontFamily: '"Work Sans", "Helvetica Neue", Helvetica, Arial, sans-serif',
                      fill: "#DBE2E9",
                      fontSize: "12",
                    },
                  }}
                  offsetY={42}
                  orientation="bottom"
                  tickValues={[Number(Object.keys(tickDisplay)[0])]}
                  tickFormat={tick => {
                    const jsDate = new Date(tick);
                    const dateStr = getDateAsString(jsDate, timezone);
                    const timeStr = getTimeAsString(jsDate, timezone, "HH:mm");
                    return dateStr + "\n" + timeStr;
                  }}
                  tickLabelComponent={<VictoryLabel textAnchor="start" />}
                  standalone={false}
                />

                {/* x-axis graph end label */}
                <VictoryAxis
                  width={width}
                  height={height + bottomPadding * 2}
                  padding={padding}
                  domain={predictionDomain}
                  style={{
                    axis: { stroke: "none" },
                    grid: { stroke: "none" },
                    tickLabels: {
                      fontFamily: '"Work Sans", "Helvetica Neue", Helvetica, Arial, sans-serif',
                      fill: "#DBE2E9",
                      fontSize: "12",
                    },
                  }}
                  offsetY={42}
                  orientation="bottom"
                  tickValues={[
                    Number(Object.keys(tickDisplay)[Object.keys(tickDisplay).length - 1]) + 1800000,
                  ]}
                  tickFormat={tick => {
                    const jsDate = new Date(tick);
                    const dateStr = getDateAsString(jsDate, timezone);
                    const timeStr = getTimeAsString(jsDate, timezone, "HH:mm");
                    return dateStr + "\n" + timeStr;
                  }}
                  tickLabelComponent={<VictoryLabel textAnchor="end" />}
                  standalone={false}
                />

                {/* x-axis dispatch start/end labels */}
                <VictoryAxis
                  width={width}
                  height={height + bottomPadding * 2}
                  padding={padding}
                  domain={predictionDomain}
                  style={{
                    axis: { stroke: "none" },
                    grid: { stroke: "none" },
                    tickLabels: {
                      fontFamily: '"Work Sans", "Helvetica Neue", Helvetica, Arial, sans-serif',
                      fill: "#4698cb",
                      fontSize: "12",
                    },
                  }}
                  offsetY={42}
                  orientation="bottom"
                  tickValues={Object.keys(highlightedTickDisplay).map(Number)}
                  tickFormat={tick => highlightedTickDisplay[tick].display}
                  standalone={false}
                />

                {/* y-axis grid */}
                <VictoryAxis
                  width={width}
                  height={height}
                  padding={0}
                  dependentAxis
                  domain={predictionDomain}
                  crossAxis={false}
                  style={{
                    axis: { stroke: "#E8E8E9" },
                    grid: { stroke: "#E8E8E9" },
                  }}
                  orientation="left"
                  tickLabelComponent={<g />}
                  standalone={false}
                />

                {/* y-axis grid 0 line */}
                <VictoryAxis
                  width={width}
                  height={height}
                  padding={0}
                  dependentAxis
                  domain={predictionDomain}
                  crossAxis={false}
                  style={{
                    axis: { stroke: "#E8E8E9", strokeWidth: 2 },
                    grid: { stroke: "#E8E8E9", strokeWidth: 2 },
                  }}
                  orientation="left"
                  tickValues={[0]}
                  tickFormat={() => ""}
                  standalone={false}
                />

                {/* x-axis blue lines */}
                <VictoryAxis
                  width={width}
                  height={height}
                  padding={padding}
                  domain={predictionDomain}
                  style={{
                    axis: { stroke: "none" },
                    grid: { stroke: "#4698cb" },
                  }}
                  orientation="bottom"
                  tickValues={Object.keys(highlightedTickDisplay).map(Number)}
                  tickFormat={() => ""}
                  standalone={false}
                />

                {adjustedPredictionData && (
                  <VictoryArea
                    width={width}
                    height={height}
                    padding={padding}
                    data={adjustedPredictionData}
                    domain={predictionDomain}
                    interpolation="stepAfter"
                    animate={{
                      duration: 200,
                      easing: "linear",
                      onLoad: { duration: 500 },
                    }}
                    style={{
                      data: {
                        stroke: "#4698cb",
                        strokeWidth: 2,
                        strokeOpacity: 0.6,
                        strokeDasharray: "3, 2",
                        fill: "#4698cb",
                        fillOpacity: 0.1,
                      },
                    }}
                    standalone={false}
                  />
                )}

                {this.props.showMarketData && marketPredictionData && (
                  <VictoryArea
                    width={width}
                    height={height}
                    padding={padding}
                    data={marketPredictionData}
                    domain={marketDomain}
                    interpolation="stepAfter"
                    style={{
                      data: {
                        stroke: "#444444",
                        strokeWidth: 2,
                        strokeOpacity: 0.6,
                        strokeDasharray: "3, 2",
                        fill: "none",
                      },
                    }}
                    standalone={false}
                  />
                )}

                {/* y-axis kW labels */}
                <VictoryAxis
                  width={width}
                  height={height}
                  padding={padding}
                  dependentAxis
                  domain={predictionDomain}
                  crossAxis={false}
                  style={{
                    axis: { stroke: "none" },
                  }}
                  orientation="right"
                  offsetX={18}
                  tickLabelComponent={
                    <VictoryLabel
                      style={{
                        fontFamily: '"Work Sans", "Helvetica Neue", Helvetica, Arial, sans-serif',
                        fontSize: "14",
                        fill: "#616161",
                        fontWeight: 500,
                      }}
                      textAnchor="end"
                    />
                  }
                  tickFormat={datum => (datum ? datum : 0) + " kW"}
                  standalone={false}
                />

                {/* y-axis $ labels */}
                {this.props.showMarketData && (
                  <VictoryAxis
                    width={width}
                    height={height}
                    padding={padding}
                    dependentAxis
                    domain={marketDomain}
                    crossAxis={false}
                    style={{
                      axis: { stroke: "none" },
                    }}
                    orientation="left"
                    offsetX={18}
                    tickLabelComponent={
                      <VictoryLabel
                        style={{
                          fontFamily: '"Work Sans", "Helvetica Neue", Helvetica, Arial, sans-serif',
                          fontSize: "14",
                          fill: "#616161",
                          fontWeight: 500,
                        }}
                        textAnchor="start"
                      />
                    }
                    tickFormat={datum => {
                      return datum ? "$" + datum : "$0";
                    }}
                    standalone={false}
                  />
                )}

                {predictionData && (
                  <VictoryArea
                    width={width}
                    height={height}
                    padding={padding}
                    data={predictionData}
                    domain={predictionDomain}
                    interpolation="stepAfter"
                    animate={{
                      duration: 200,
                      easing: "linear",
                      onLoad: { duration: 500 },
                    }}
                    style={{
                      data: {
                        stroke: "#26D07C",
                        strokeWidth: 2,
                        strokeOpacity: 0.6,
                        strokeDasharray: "3, 2",
                        fill: "#26D07C",
                        fillOpacity: 0.1,
                      },
                    }}
                    containerComponent={
                      <VictoryBrushContainer
                        responsive={true}
                        dimension="x"
                        selectedDomain={{
                          x: [
                            dispatchStartTimestamp * 1000,
                            (dispatchStartTimestamp + duration) * 1000,
                          ],
                        }}
                        onDomainChange={this.handleBrushOnChange}
                        selectionStyle={{
                          stroke: "transparent",
                          fill: "rgba(70, 152, 203, 0.55)",
                          fillOpacity: 0.2,
                          userSelect: "none",
                          pointerEvents: "none",
                        }}
                        style={{
                          height: "auto",
                          width: "100%",
                          userSelect: "none",
                          pointerEvents: "none",
                        }}
                      />
                    }
                    standalone={false}
                  />
                )}
              </g>
            </svg>
          )}
        </div>
      </div>
    );
  }
}
export default NewNewDispatchGraph;

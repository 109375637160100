import React, { Component, useEffect, useRef, useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Button, Loading } from "../../components/widgets";
import * as userActions from "../../redux/actions/user";
import "./LoginForm.css";
import TOTPForm from "./totp/TOTPForm";

const mapStateToProps = state => ({
  user: state.user,
});

const mapDispatchToProps = dispatch => {
  return {
    userActions: bindActionCreators(userActions, dispatch),
  };
};

/**
 * An observer to check when the Turnstile javascript file is loaded.
 * The Turnstil javascript can be found in the header in public/index.html
 * This is pulled directly from @marsidev/react-turnstile which
 * we can't use (see details Turnstile component below)
 * @returns {boolean} True if the Turnstile script is loaded, otherwise false
 */
const useObserveScript = () => {
  const [scriptLoaded, setScriptLoaded] = useState(false);

  useEffect(() => {
    const checkScriptExists = () => {
      const script = !!window.turnstile;
      if (script) {
        setScriptLoaded(true);
      }
    };

    const observer = new MutationObserver(checkScriptExists);
    observer.observe(document, { childList: true, subtree: true });

    checkScriptExists();

    return () => {
      observer.disconnect();
    };
  }, []);

  return scriptLoaded;
};

/**
 * A widget for a Cloudflare Turnstile
 * This is a bastardised version of @marsidev/react-turnstile which doesn't
 * seem to be supported by this version of react-scripts due to it using the ?? operator.
 *
 * This triggers a forced render of the Turnstile widget when it first appears on screen.
 * @param props React function component properties
 * @returns {JSX.Element}
 * @constructor
 */
const Turnstile = props => {
  const { sitekey, setToken } = props;
  const scriptLoaded = useObserveScript();
  const containerRef = useRef(null);
  const [turnstileIsRendered, setTurnstileIsRendered] = useState(false);

  useEffect(() => {
    if (containerRef?.current && scriptLoaded && !turnstileIsRendered) {
      window.turnstile.render(containerRef.current, {
        sitekey: sitekey,
        theme: "light",
        callback: function (token) {
          setToken(token);
        },
      });
      setTurnstileIsRendered(true);
    }
  }, [scriptLoaded, sitekey, setToken, turnstileIsRendered]);
  return <div ref={containerRef}></div>;
};

class LoginForm extends Component {
  constructor() {
    super();
    this.state = {
      username: "",
      password: "",
      captchaToken: null,
    };
  }

  login = event => {
    event.preventDefault();
    this.props.userActions.login(this.state.username, this.state.password, this.state.captchaToken);
  };

  handleUsernameChange = event => {
    this.setState({ username: event.target.value.trim() }); // disallow leading/trailing whitespace
  };

  handlePasswordChange = event => {
    this.setState({ password: event.target.value });
  };

  componentWillReceiveProps = nextProps => {
    // If we are transitioning to TOTP then we should clear the username and password from current state.
    if (
      (this.props.user.loginStatus !== "TOTP_REQUIRED" &&
        nextProps.user.loginStatus === "TOTP_REQUIRED") ||
      (this.props.user.loginStatus !== "TOTP_UNCONFIGURED" &&
        nextProps.user.loginStatus === "TOTP_UNCONFIGURED")
    ) {
      this.setState({
        username: "",
        password: "",
        token: null,
      });
    }
  };

  render() {
    const loginStatus = this.props.user.loginStatus;

    switch (loginStatus) {
      case "FETCHING":
        return (
          <div className="loading-container">
            <Loading />
          </div>
        );
      case "TOTP_REQUIRED":
      case "TOTP_UNCONFIGURED":
        return <TOTPForm />;
      default:
        return (
          <span>
            <h1>Welcome to Reposit Fleet</h1>
            <p>To log in enter your Reposit Fleet email/username and password.</p>

            <div className="message-container">
              {loginStatus === "ERROR" && (
                <div className="login-message">
                  Incorrect username or password. Please try again.
                </div>
              )}
              {this.props.user.sessionExpired && (
                <div className="login-message">Your session has expired. Please log in again.</div>
              )}
            </div>
            <div className="login-container login-form-container">
              <form className="login-form" onSubmit={this.login}>
                {!!process.env.REACT_APP_TURNSTILE_SITE_KEY && !this.state.captchaToken && (
                  <Turnstile
                    sitekey={process.env.REACT_APP_TURNSTILE_SITE_KEY}
                    setToken={token => this.setState({ captchaToken: token })}
                  />
                )}
                <input
                  autoFocus
                  type="text"
                  placeholder="Email or Username"
                  autoComplete="off"
                  onChange={this.handleUsernameChange}
                  value={this.state.username}
                />
                <input
                  type="password"
                  placeholder="Password"
                  autoComplete="off"
                  onChange={this.handlePasswordChange}
                  value={this.state.password}
                />
                <Button
                  id="login"
                  onClick={this.login}
                  type="primary"
                  disabled={!!process.env.REACT_APP_TURNSTILE_SITE_KEY && !this.state.captchaToken}
                >
                  Log In
                </Button>
              </form>
            </div>
            <div className="login-links">
              <Link className="link" to="/password/forgot">
                I&apos;ve forgotten my password
              </Link>
            </div>
          </span>
        );
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginForm);

import React, { Component } from "react";
import { DatePicker, Button } from "../widgets";
import moment from "moment-timezone";
import "./BillingGridCreditsSearchFields.css";

class BillingGridCreditsSearchFields extends Component {
  render() {
    return (
      <>
        <DatePicker
          className="billing-gridcredits-date-picker"
          placeholder="From"
          maxDate={moment()}
          onChange={e => this.props.handleDateOnChange("from", e)}
          withInput
          value={this.props.fromDate}
        />
        <DatePicker
          className="billing-gridcredits-date-picker"
          placeholder="To"
          maxDate={moment()}
          onChange={e => this.props.handleDateOnChange("to", e)}
          value={this.props.toDate}
          withInput
        />
        <div className="form-actions">
          <Button
            id="search-billing-gridcredits"
            type="primary"
            onClick={this.props.onSearch}
            icon="search"
          >
            Search
          </Button>
        </div>
      </>
    );
  }
}

export default BillingGridCreditsSearchFields;

import React from "react";
import { Button } from "../widgets";
import "./NotFound.css";

const NotFound = () => (
  <div className="not-found">
    <h1>404</h1>
    <h2>This page could not be found.</h2>
    <p>
      The page you are looking for does not exist; it may have been moved, or removed altogether. If
      you entered a hyperlink, please check it’s entered correctly.
    </p>
    <Button id="back-to-dashboard-from-404" type="primary" to="/" icon="arrow-left">
      Back to Dashboard
    </Button>
  </div>
);

export default NotFound;

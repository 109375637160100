import React, { Component } from "react";
import { MomentDate } from "../widgets";
import { durationToStringReadable } from "../../utils/formatting";
import { Link } from "react-router-dom";

function eventLink(eventType, id) {
  if (eventType === "DISPATCH") {
    return `/virtual-power-plants/dispatches/${id}`;
  }

  if (eventType === "GRID_OUTAGE") {
    return `/virtual-power-plants/grid-outages/${id}`;
  }

  if (eventType === "CURTAILMENT") {
    return `/virtual-power-plants/feed-in-management/${id}`;
  }
}

class FleetDeploymentEventLog extends Component {
  render() {
    let events = [];
    if (this.props.deploymentHistory) {
      events = this.props.deploymentHistory.sort((a, b) => {
        if (a.ts > b.ts) return -1;
        if (a.ts < b.ts) return 1;
        return 0;
      });
    }

    if (!events || events.length === 0) {
      return <div className="fleet-table-no-results">There are no events for this node.</div>;
    }

    return (
      <table>
        <thead>
          <tr>
            <th>Date/Time</th>
            <th>Event</th>
            <th>Description</th>
            <th>Duration</th>
          </tr>
        </thead>
        <tbody>
          {events.map(event => {
            return (
              <tr>
                <td>
                  <MomentDate
                    date={new Date(event.ts * 1000)}
                    timezone={this.props.timezone}
                    format="D MMM YYYY, h:mm a"
                  />
                </td>
                <td>
                  {event.eventType === "GRID_OUTAGE" ||
                  event.eventType === "DISPATCH" ||
                  event.eventType === "CURTAILMENT" ? (
                    <Link to={eventLink(event.eventType, event.id)}>
                      {event.eventType.charAt(0).toUpperCase() +
                        event.eventType.slice(1).toLowerCase().replace("_", " ")}
                    </Link>
                  ) : (
                    event.eventType.charAt(0).toUpperCase() +
                    event.eventType.slice(1).toLowerCase().replace("_", " ")
                  )}
                </td>
                <td>{event.description}</td>
                <td>{durationToStringReadable(event.duration)}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  }
}

export default FleetDeploymentEventLog;

import React, { Component } from "react";
import { Link } from "react-router-dom";
import { MomentDate, Duration } from "../widgets";
import { getDateAsString } from "../widgets/Moment";

class GridOutageListTable extends Component {
  handleOnRowClick = outage => {
    this.props.history.push(`/virtual-power-plants/grid-outages/${outage.id}`);
  };

  getStatus = state => {
    switch (state) {
      case "COMPLETED":
        return "Completed";
      case "UPCOMING":
        return "Upcoming";
      case "INPROGRESS":
        return "In Progress";
      default:
        return "";
    }
  };

  isThisYear = date => {
    const dateYear = getDateAsString(date, this.props.timezone, "YYYY");
    const nowYear = getDateAsString(new Date(), this.props.timezone, "YYYY");
    return dateYear === nowYear;
  };

  render() {
    const outages = this.props.outages;

    if (!outages || outages.length === 0) {
      return <div className="fleet-table-no-results">No grid outages have been found.</div>;
    }

    return (
      <table>
        <thead>
          <tr>
            <th>Event</th>
            <th>Start</th>
            <th>Duration</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {outages.map(outage => {
            return (
              <tr role="button" onClick={() => this.handleOnRowClick(outage)}>
                <td>
                  <Link to={`/virtual-power-plants/grid-outages/${outage.id}`}>
                    {outage.reason}
                    <br />
                  </Link>
                  {outage.powerstation}
                </td>
                <td>
                  {this.isThisYear(new Date(outage.request.startTime * 1000)) ? (
                    <MomentDate
                      date={new Date(outage.request.startTime * 1000)}
                      timezone={this.props.timezone}
                      format="D MMMM, h:mm a"
                    />
                  ) : (
                    <MomentDate
                      date={new Date(outage.request.startTime * 1000)}
                      timezone={this.props.timezone}
                      format="D MMM YY, h:mm a"
                    />
                  )}
                </td>
                <td>
                  <Duration seconds={outage.request.duration} />
                </td>
                <td>{this.getStatus(outage.state)}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  }
}

export default GridOutageListTable;

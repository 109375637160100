import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router";
import * as analysisActions from "../../redux/actions/analysis";
import { Button, Loading } from "../../components/widgets";
import Page from "../../components/layout/Page";
import Permissions from "../../permissions";
import AnalysisDataExportsList from "./AnalysisDataExportsList";
import { accessTokenItemName } from "../../redux/actions/user";
import OperationalExport from "./AnalysisDataOperationalExport";
import CustomerExport from "./AnalysisDataCustomerExport";
import AnalysisDataChooseExport from "../../components/analysis/ChooseExportModal";
import queryString from "query-string";

export const OPERATIONAL_EXPORT_TYPE_DEPLOYMENT = "deployment";
export const OPERATIONAL_EXPORT_TYPE_POWERSTATION = "powerstation";

const mapStateToProps = state => ({
  analysis: state.analysis,
  user: state.user,
});

const mapDispatchToProps = dispatch => {
  return {
    analysisActions: bindActionCreators(analysisActions, dispatch),
  };
};

// Update interval in milliseconds
const SLOW_INTERVAL = 30000;
// Update interval if any export is in progress
const FAST_INTERVAL = 5000;
const EXPORTS_PER_PAGE = 10;

class AnalysisExportCSV extends Component {
  constructor(props) {
    super(props);

    const search = queryString.parse(props.location.search);

    let limit = EXPORTS_PER_PAGE;
    if (search.limit !== undefined) {
      limit = parseInt(search.limit, 10);
    }

    let offset = 0;
    if (search.offset !== undefined) {
      offset = parseInt(search.offset, 10);
    }

    this.state = {
      openChooseExportTypeModal: false,
      operationalExportType: null,
      limit: limit,
      offset: offset,
    };
  }

  componentDidMount = () => {
    this.fetchExports(true);
    this.refreshExports = setInterval(() => this.fetchExports(false), SLOW_INTERVAL);
    // If a deploymentId is in the URL params, then open a deployment export modal
    const queryParams = new URLSearchParams(window.location.search);
    const deploymentId = queryParams.get("deploymentId");
    if (deploymentId) {
      this.setState({
        ...this.state,
        operationalExportType: OPERATIONAL_EXPORT_TYPE_DEPLOYMENT,
        openOperationalModal: true,
      });
    }
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.location.search &&
      (!prevProps.location.search || prevProps.location.search !== this.props.location.search)
    ) {
      const query = queryString.parse(this.props.location.search);
      this.setState({
        limit: parseInt(query.limit, 10),
        offset: parseInt(query.offset, 10),
      });
    }

    if (this.state.limit !== prevState.limit || this.state.offset !== prevState.offset) {
      this.fetchExports(true);
    }
    if (this.props.analysis.exports) {
      let interval =
        this.props.analysis.exports.data[0].status === "PROGRESS" ||
        this.props.analysis.exports.data[0].status === "PENDING"
          ? FAST_INTERVAL
          : SLOW_INTERVAL;
      clearInterval(this.refreshExports);
      this.refreshExports = setInterval(() => this.fetchExports(false), interval);
    }
  }

  componentWillUnmount = () => {
    this.props.analysis.exports = null;
    clearInterval(this.refreshExports);
  };

  fetchExports = showLoading => {
    this.props.analysisActions.fetchCSVDataExports(
      this.state.limit,
      this.state.offset,
      showLoading
    );
  };

  openChooseExportTypeModal = () => {
    this.setState({
      openChooseExportTypeModal: true,
    });
  };

  openDeploymentOperationalModal = () => {
    this.setState({
      openOperationalModal: true,
      operationalExportType: OPERATIONAL_EXPORT_TYPE_DEPLOYMENT,
      openChooseExportTypeModal: false,
    });
  };

  openPowerstationOperationalModal = () => {
    this.setState({
      openOperationalModal: true,
      operationalExportType: OPERATIONAL_EXPORT_TYPE_POWERSTATION,
      openChooseExportTypeModal: false,
    });
  };

  closeOperationalModal = () => {
    this.setState({
      openOperationalModal: false,
    });
  };

  openCustomerModal = () => {
    this.setState({
      openCustomerModal: true,
      openChooseExportTypeModal: false,
    });
  };

  closeCustomerModal = () => {
    this.setState({
      openCustomerModal: false,
    });
  };

  closeChooseExportTypeModal = () => {
    this.setState({
      openChooseExportTypeModal: false,
    });
  };

  render() {
    return (
      <Page permission={Permissions.fleet.analysis.export.view}>
        <Page.Header title="Data Export (csv)">
          <Page.Header.Actions>
            <Button
              id="new-export"
              type="primary"
              icon="plus"
              onClick={this.openChooseExportTypeModal}
              timezone={this.props.user.account.timezone}
            >
              New Export
            </Button>
          </Page.Header.Actions>
        </Page.Header>
        <Page.Content>
          {this.props.analysis.loadingExports ? (
            <Loading />
          ) : (
            <AnalysisDataExportsList
              exports={this.props.analysis.exports}
              timezone={this.props.user.account.timezone}
              accessToken={localStorage.getItem(accessTokenItemName)}
              noExports={this.props.analysis.noExports}
              limit={this.state.limit}
              offset={this.state.offset}
            />
          )}
          {this.state.openChooseExportTypeModal &&
            !(this.state.openOperationalModal || this.state.openCustomerModal) && (
              <AnalysisDataChooseExport
                openPowerstationOperationalModal={this.openPowerstationOperationalModal}
                openDeploymentOperationalModal={this.openDeploymentOperationalModal}
                openCustomerModal={this.openCustomerModal}
                closeModal={this.closeChooseExportTypeModal}
                suborgType={this.props.user.account.suborg_type}
              />
            )}
          {this.state.openOperationalModal && (
            <OperationalExport
              closeModal={this.closeOperationalModal}
              suborgType={this.props.user.account.suborg_type}
              operationalExportType={this.state.operationalExportType}
              history={this.props.history}
              location={this.props.location}
            />
          )}
          {this.state.openCustomerModal && (
            <CustomerExport
              closeModal={this.closeCustomerModal}
              suborgType={this.props.user.account.suborg_type}
            />
          )}
        </Page.Content>
      </Page>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AnalysisExportCSV));

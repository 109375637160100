import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as userActions from "../../redux/actions/user";
import { Button, SelectDropdown } from "../../components/widgets";
import Page from "../../components/layout/Page";
import "./UserSettings.css";
import InlinePasswordChanger from "./InlinePasswordChanger";
import MFASettings from "./MFASettings";
import APIKeysList from "./APIKeysList";

const AVAILABLE_TIMEZONES = [
  { value: "Australia/Perth", label: "(GMT+08:00) Western Time - Perth" },
  { value: "Australia/Darwin", label: "(GMT+09:30) Central Time - Darwin" },
  { value: "Australia/Brisbane", label: "(GMT+10:00) Eastern Time - Brisbane" },
  { value: "Australia/Adelaide", label: "(GMT+10:30) Central Time - Adelaide" },
  { value: "Australia/Hobart", label: "(GMT+11:00) Eastern Time - Hobart" },
  { value: "Australia/Sydney", label: "(GMT+11:00) Eastern Time - Melbourne, Sydney" },
];

const AVAILABLE_FLEET_ALERT_EMAIL_PERIODS = [
  { value: "NEVER", label: "Never" },
  { value: "NOW", label: "Immediately" },
  { value: "HOURLY", label: "Hourly" },
  { value: "DAILY", label: "Daily" },
  { value: "WEEKLY", label: "Weekly" },
];

const mapStateToProps = state => ({
  user: state.user,
});

const mapDispatchToProps = dispatch => {
  return {
    userActions: bindActionCreators(userActions, dispatch),
  };
};

class UserSettings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      timezone: props.user.account.timezone,
      alertEmailFrequency: props.user.account.alertEmailFrequency,
    };
  }

  saveTimezone = () => {
    this.props.userActions.setTimezone(this.state.timezone);
  };

  saveAlertEmailFrequency = () => {
    this.props.userActions.setAlertEmailFrequency(this.state.alertEmailFrequency);
  };

  handleTimezoneOnChange = timezone => {
    this.setState({ timezone: timezone });
  };

  handleAlertEmailFrequencyOnChange = frequency => {
    this.setState({ alertEmailFrequency: frequency });
  };

  render() {
    return (
      <Page>
        <Page.Header title="User Settings">
          <Page.Header.Actions>
            <Button
              id="user-settings-cancel"
              type="secondary"
              onClick={this.props.history.goBack}
              icon="times"
            >
              Cancel
            </Button>
          </Page.Header.Actions>
        </Page.Header>
        <Page.Content>
          <div className="user-setting">
            <h4>Timezone</h4>
            <div>
              <p>Select your timezone from the list below.</p>
              <p>
                Your current timezone is
                <SelectDropdown
                  value={this.state.timezone}
                  options={AVAILABLE_TIMEZONES}
                  onChange={this.handleTimezoneOnChange}
                  placeholder="Select timezone..."
                />
              </p>
              <div style={{ display: "block", marginTop: "1em" }}>
                <Button
                  id="user-settings-save"
                  type="primary"
                  onClick={this.saveTimezone}
                  icon="check"
                  loading={this.props.user.savingTimezone}
                >
                  Save
                </Button>
              </div>
            </div>
          </div>

          <div className="user-setting">
            <h4>Notifications</h4>
            <div>
              <p>How often do you want to receive emails about Node alerts?</p>
              <p>
                <SelectDropdown
                  value={this.state.alertEmailFrequency}
                  options={AVAILABLE_FLEET_ALERT_EMAIL_PERIODS}
                  onChange={this.handleAlertEmailFrequencyOnChange}
                  placeholder="Select frequency..."
                />
                {this.state.alertEmailFrequency === "NEVER" ? (
                  <span className="alert-email-warning">
                    You will not receive any emails about Node alerts.
                  </span>
                ) : null}
              </p>

              <div style={{ display: "block", marginTop: "1em" }}>
                <Button
                  id="user-settings-save"
                  type="primary"
                  onClick={this.saveAlertEmailFrequency}
                  icon="check"
                  loading={this.props.user.savingAlertEmailFrequency}
                >
                  Save
                </Button>
              </div>
            </div>
          </div>

          <InlinePasswordChanger />

          {this.props.user.account.mfaEnabled ? <MFASettings /> : null}

          <APIKeysList />

          <div className="application-version">
            application version: {global.appVersion}
            {process.env.REACT_APP_BUILD_COMMIT_HASH
              ? `.${process.env.REACT_APP_BUILD_COMMIT_HASH}`
              : ""}
          </div>
        </Page.Content>
      </Page>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserSettings);

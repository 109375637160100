import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Page from "../../components/layout/Page";
import DispatchOverview from "../../components/trading/DispatchOverview";
import { Loading } from "../../components/widgets";
import * as tradingActions from "../../redux/actions/trading";
import Permissions, { hasPermission } from "../../permissions";
import { User } from "../smartWidgets";

const mapStateToProps = state => ({
  dispatch: state.trading.activeDispatch,
  prediction: state.trading.activeDispatchPrediction,
  response: state.trading.activeDispatchResponse,
  remainingCharge: state.trading.activeDispatchRemainingCharge,
  dispatchCost: state.trading.activeDispatchCost,
  powerstations: state.trading.powerstations,
  user: state.user,
});

const mapDispatchToProps = dispatch => {
  return {
    tradingActions: bindActionCreators(tradingActions, dispatch),
  };
};

class Dispatch extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dispatchId: this.props.match.params.id,
      dispatchCreatedAt: 0,
      dispatchStartTime: 0,
      dispatchEndTime: 0,
    };
  }

  refreshTimeouts = {};

  componentDidMount = () => {
    this.fetchDispatch();
    if (!this.props.powerstations) {
      this.props.tradingActions.fetchPowerstations();
    }

    if (this.props.dispatch) {
      this.fetchPrediction();
      this.fetchResponse();
      this.fetchRemainingCharge();
      this.fetchCost();
    }
  };

  componentWillReceiveProps = nextProps => {
    if (!this.props.dispatch && nextProps.dispatch) {
      this.setState(
        {
          dispatchCreatedAt: nextProps.dispatch.createdAt,
          dispatchStartTime: nextProps.dispatch.request.startTime,
          dispatchEndTime:
            nextProps.dispatch.request.startTime + nextProps.dispatch.request.duration,
        },
        () => {
          this.fetchPrediction();
          this.fetchResponse();
          this.fetchRemainingCharge();
          this.fetchCost();
          this.fetchDispatch();
        }
      );
    } else if (
      this.props.dispatch &&
      nextProps.dispatch &&
      this.props.dispatch !== nextProps.dispatch
    ) {
      this.setState({
        dispatchCreatedAt: nextProps.dispatch.createdAt,
        dispatchStartTime: nextProps.dispatch.request.startTime,
        dispatchEndTime: nextProps.dispatch.request.startTime + nextProps.dispatch.request.duration,
      });
      this.setupRefreshTimeout("cost", this.fetchCost);
    } else if (nextProps.dispatch) {
      if (this.props.prediction !== nextProps.prediction) {
        this.setupRefreshTimeout("prediction", this.fetchPrediction);
      }

      if (this.props.response !== nextProps.response) {
        this.setupRefreshTimeout("response", this.fetchResponse);
      }

      if (this.props.remainingCharge !== nextProps.remainingCharge) {
        this.setupRefreshTimeout("remainingCharge", this.fetchRemainingCharge);
      }
    }

    if (this.props.dispatch !== nextProps.dispatch) {
      this.setupRefreshTimeout("dispatch", this.fetchDispatch);
    }
  };

  fetchDispatch = () => {
    this.props.tradingActions.fetchActiveDispatch(this.state.dispatchId);
  };

  fetchPrediction = () => {
    this.props.tradingActions.fetchDispatchPrediction(this.state.dispatchId);
  };

  fetchRemainingCharge = () => {
    this.props.tradingActions.fetchDispatchRemainingCharge(this.state.dispatchId);
  };

  fetchCost = () => {
    this.props.tradingActions.fetchDispatchCost(this.state.dispatchId);
  };

  fetchResponse = () => {
    this.props.tradingActions.fetchDispatchResponse(this.state.dispatchId);
  };

  componentWillUnmount = () => {
    for (let id in this.refreshTimeouts) {
      clearTimeout(this.refreshTimeouts[id]);
    }
    this.props.tradingActions.clearActiveDispatch();
  };

  setupRefreshTimeout = (timeoutId, callback) => {
    if (this.refreshTimeouts[timeoutId]) {
      clearTimeout(this.refreshTimeouts[timeoutId]);
    }

    const nowTs = Math.round(new Date().valueOf() / 1000);
    const secondsSinceCreated = nowTs - this.state.dispatchCreatedAt;

    let dispatchState = "NOT INPROGRESS";
    if (this.state.dispatchStartTime <= nowTs && nowTs <= this.state.dispatchEndTime) {
      dispatchState = "INPROGRESS";
    }

    // If we are still on this page once a dispatch is complete, default to every 5 minutes
    let refreshInterval = 300000;
    if (secondsSinceCreated < 60) {
      // If we are within 1 minute of creating the dispatch then poll every 2 seconds to see the
      // nodes responded number clock up.
      // Most systems respond well within 60 seconds.
      refreshInterval = 2000; // 2 seconds
    } else if (dispatchState === "INPROGRESS") {
      // 8 seconds - gives a bit of time for requests and should ensure that we get an update for
      // each value at least every 10 seconds during a dispatch. The timer starts once a network
      // request has finished.
      refreshInterval = 8000;
    }

    this.refreshTimeouts[timeoutId] = setTimeout(callback, refreshInterval);
  };

  getPowerstationName = powerstationId => {
    const powerstation = this.props.powerstations.find(p => p.id === powerstationId);
    if (powerstation) {
      return powerstation.name;
    } else {
      return "Unknown";
    }
  };

  render() {
    if (!this.props.dispatch || !this.props.powerstations) {
      return <Loading />;
    }

    const dispatch = this.props.dispatch;

    return (
      <Page key="dispatch" permission={Permissions.fleet.dispatches.any}>
        <Page.Header
          title={
            dispatch.triggered ? dispatch.reason : this.getPowerstationName(dispatch.powerstation)
          }
        >
          <Page.SubHeading>
            Created by:
            {dispatch.triggered ? (
              <span className="mp-user unknown">system (auto)</span>
            ) : (
              <User userId={dispatch.createdBy} />
            )}
          </Page.SubHeading>
        </Page.Header>
        <Page.Content>
          <DispatchOverview
            dispatch={this.props.dispatch}
            prediction={this.props.prediction}
            response={this.props.response}
            remainingCharge={this.props.remainingCharge}
            dispatchCost={this.props.dispatchCost}
            timezone={this.props.user.account.timezone}
            showMarketData={
              this.props.user.account.suborg_type === "RETAILER" ||
              hasPermission(this.props.user.permissions, Permissions.fleet.dispatches.marketTrader)
            }
          />
        </Page.Content>
      </Page>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Dispatch);

import { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { hasPermission, anyPermissions, allPermissions } from "../permissions";

/*
  Usage:

  This component support 3 main props:

  permission="fleet.powerstation.view"
      The user must have this permission.

  any={['fleet.powerstation.view', 'fleet.powerstation.edit']}
      The user must have at least one of the permissions in this prop.

  all={['fleet.powerstation.view', 'fleet.powerstation.edit']}
      The user must have ALL of the permissions in this prop.


  The various pops can be composed. If they are composed, all conditions must
  be matched.
    <AuthRequired
      permission="base.access"
      any={['fleet.powerstation.view', 'fleet.deployments.view']}
      all={['fleet.powerstation.edit', 'fleet.deployments.edit']}
      >
      ...
    </AuthRequired>

  All of the props can also accept permissions ending with an asterix. This changes the
  matching function from an === to a startsWith. This allows 'fleet.*' to match both
  'fleet.deployments.view' and 'fleet.customerData.view'.
  The wildcard type of permission can be missed with other permissions, for example:
      any={['fleet.*', 'fleet.powerstation.view']}

*/

const mapStateToProps = state => ({
  permissions: state.user.permissions,
});

class AuthRequired extends Component {
  render() {
    const userPermissions = this.props.permissions;

    const permissionAuthorized = hasPermission(userPermissions, this.props.permission);
    const anyAuthorized = anyPermissions(userPermissions, this.props.any);
    const allAuthorized = allPermissions(userPermissions, this.props.all);

    if (!this.props.any && !this.props.all && !this.props.permission) {
      // Better to be safe than sorry so if none of the props are supplied render nothing.
      return null;
    }
    if (anyAuthorized && allAuthorized && permissionAuthorized) {
      return this.props.children;
    } else if (this.props.message) {
      return this.props.message;
    } else {
      return null;
    }
  }
}

AuthRequired.propTypes = {
  permission: PropTypes.string,
  any: PropTypes.arrayOf(PropTypes.string),
  all: PropTypes.arrayOf(PropTypes.string),
};

export default connect(mapStateToProps)(AuthRequired);

import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Page from "../../components/layout/Page";
import { Loading, Button } from "../../components/widgets";
import GridOutageListTable from "../../components/gridoutage/GridOutageListTable";
import NewGridOutageModal from "./NewGridOutageModal";
import * as gridOutageActions from "../../redux/actions/gridoutage";
import AuthRequired from "../AuthRequired";
import Permissions from "../../permissions";

const mapStateToProps = state => ({
  outages: state.gridOutages.outages,
  powerstations: state.gridOutages.powerstations,
  user: state.user,
});

const mapDispatchToProps = dispatch => {
  return {
    gridOutageActions: bindActionCreators(gridOutageActions, dispatch),
  };
};

class GridOutageList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showNewGridOutage: false,
    };
  }

  componentDidMount = () => {
    this.fetchGridOutageData();
    this.refreshInterval = setInterval(this.fetchGridOutageData, 300000); // 5 minutes;
  };

  componentWillUnmount = () => {
    if (this.refreshInterval) {
      clearInterval(this.refreshInterval);
    }
  };

  fetchGridOutageData = () => {
    this.props.gridOutageActions.fetchGridOutages();
    this.props.gridOutageActions.fetchOutagePowerstations();
  };

  showNewGridOutage = () => {
    this.setState({
      showNewGridOutage: true,
    });
  };

  hideNewGridOutage = () => {
    this.setState({
      showNewGridOutage: false,
    });
  };

  render() {
    if (!this.props.outages || !this.props.powerstations) {
      return <Loading />;
    }

    let outages = this.props.outages;
    outages.sort((a, b) => {
      const aRequest = a.request;
      const bRequest = b.request;
      if (aRequest.startTime > bRequest.startTime) return -1;
      if (aRequest.startTime < bRequest.startTime) return 1;
      if (aRequest.startTime === bRequest.startTime) {
        if (aRequest.duration > bRequest.duration) return -1;
        if (aRequest.duration < bRequest.duration) return 1;
        return 0;
      }
      return 0;
    });

    return (
      <Page key="grid-outage-list" permission={Permissions.fleet.powerstations.gridOutages.any}>
        <Page.Header title="Grid Outages">
          <AuthRequired permission={Permissions.fleet.powerstations.gridOutages.edit}>
            <Page.Header.Actions>
              <Button
                id="new-outage-from-outage-list"
                type="primary"
                icon="plus"
                onClick={this.showNewGridOutage}
              >
                New Grid Outage
              </Button>
            </Page.Header.Actions>
          </AuthRequired>
        </Page.Header>
        <Page.Content>
          <GridOutageListTable
            outages={outages}
            powerstations={this.props.powerstations}
            timezone={this.props.user.account.timezone}
            history={this.props.history}
          />
          {this.state.showNewGridOutage ? (
            <NewGridOutageModal handleOnCancel={this.hideNewGridOutage} />
          ) : null}
        </Page.Content>
      </Page>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(GridOutageList);

import _ from "lodash";
import * as actions from "../actions/fleet";
import { processMonitoringState } from "./utils";

const fleetInitialState = {
  deployments: null,
  activeDeployment: null,
  customerData: {
    dailyValues: null,
    meterP: null,
    houseP: null,
    solarP: null,
    inverterP: null,
    batterySOC: null,
    batteryCapacity: null,
    submeters: null,
  },
  deploymentHistory: null,
  eventLogLoading: true,
};

const fleetReducer = (state = fleetInitialState, action) => {
  switch (action.type) {
    case "RESET_STORE":
      return {
        ...fleetInitialState,
      };
    case actions.SET_LOADING_FLEET:
      return {
        ...state,
        loadingDeployments: true,
        loadingMap: action.payload.loadingMap,
      };
    case actions.FETCH_FLEET_DEPLOYMENTS:
      return {
        ...state,
        loadingDeployments: true,
      };
    case actions.UPDATE_FLEET_DEPLOYMENTS:
      return {
        ...state,
        deployments: action.payload.deployments.map(depl => processMonitoringState(depl)),
        deploymentCount: action.payload.count,
        loadingDeployments: false,
      };
    case actions.UPDATE_MAP_DEPLOYMENTS:
      return {
        ...state,
        deploymentMap: action.payload.deployments,
        loadingMap: false,
      };
    case actions.FETCH_ACTIVE_FLEET_DEPLOYMENT:
      return {
        ...state,
        activeDeploymentUid: action.payload.deploymentUid,
      };
    case actions.UPDATE_ACTIVE_FLEET_DEPLOYMENT:
      if (action.payload.deploymentUid === state.activeDeploymentUid) {
        return {
          ...state,
          activeDeployment: processMonitoringState(action.payload.deployment),
        };
      }
      return { ...state };
    case actions.SAVE_DEPLOYMENT_SITE_LABEL:
      return {
        ...state,
        activeDeployment: {
          ...state.activeDeployment,
          loadingSiteLabel: true,
        },
      };
    case actions.UPDATE_DEPLOYMENT_SITE_LABEL:
      const updatedDeployments = _.map(state.deployments, depl => {
        if (depl.siteId === action.payload.siteUid) {
          return { ...depl, siteLabel: action.payload.siteLabel };
        }
        return depl;
      });
      return {
        ...state,
        activeDeployment: {
          ...state.activeDeployment,
          siteLabel: action.payload.siteLabel,
          loadingSiteLabel: false,
        },
        deployments: updatedDeployments,
      };
    case actions.CLEAR_ACTIVE_FLEET_DEPLOYMENT:
      return {
        ...state,
        activeDeployment: null,
        customerData: null,
        activeDeploymentUid: null,
      };
    case actions.UPDATE_DAILY_DATA:
      return {
        ...state,
        customerData: {
          ...state.customerData,
          dailyValues: action.payload.response.data,
        },
      };
    case actions.UPDATE_CUSTOMER_DATA_SUBMETER:
      let submeters;
      if (!state.customerData || !state.customerData.submeters) {
        submeters = {};
      } else {
        submeters = state.customerData.submeters;
      }

      return {
        ...state,
        customerData: {
          ...state.customerData,
          submeters: {
            ...submeters,
            [action.payload.submeterId]: action.payload.submeterP,
          },
        },
      };
    case actions.UPDATE_PORTAL_CREDENTIALS:
      return {
        ...state,
        portalAccessToken: action.payload.portalAccessToken,
        portalInfo: action.payload.portalInfo,
        portalErrorMessage: null,
      };
    case actions.PORTAL_CREDENTIALS_ERROR:
      return {
        ...state,
        portalErrorMessage: action.payload.portalErrorMessage,
        portalAccessToken: null,
        portalInfo: null,
      };
    case actions.FETCH_ACTIVE_DEPLOYMENT_HISTORY:
      return {
        ...state,
        eventLogLoading: true,
      };
    case actions.UPDATE_ACTIVE_DEPLOYMENT_HISTORY:
      return {
        ...state,
        deploymentHistory: action.payload.deploymentHistory,
        eventLogPageMeta: action.payload.eventLogPageMeta,
        eventLogLoading: false,
      };
    default:
      return state;
  }
};

export default fleetReducer;

import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import queryString from "query-string";
import Page from "../../components/layout/Page";
import { Loading, Button } from "../../components/widgets";
import DispatchListTable from "../../components/trading/DispatchListTable";
import NewDispatchModal from "./NewDispatchModal";
import * as tradingActions from "../../redux/actions/trading";
import AuthRequired from "../AuthRequired";
import Permissions from "../../permissions";
import PagingControl from "../../components/widgets/PagingControl";

export const PAGE_SIZE = 10;

const mapStateToProps = state => ({
  dispatches: state.trading.dispatches,
  dispatchesPageMeta: state.trading.dispatchesPageMeta,
  powerstations: state.trading.powerstations,
  user: state.user,
  loading: state.trading.dispatchesLoading,
  location: state.router.location,
});

const mapDispatchToProps = dispatch => {
  return {
    tradingActions: bindActionCreators(tradingActions, dispatch),
  };
};

class DispatchList extends Component {
  constructor(props) {
    super(props);
    const search = queryString.parse(props.location.search);

    let limit = PAGE_SIZE;
    if (search.limit !== undefined) {
      limit = parseInt(search.limit, 10);
    }

    let offset = 0;
    if (search.offset !== undefined) {
      offset = parseInt(search.offset, 10);
    }

    this.state = {
      showNewDispatch: false,
      limit,
      offset,
    };
    this.updateLimitPerPage = this.updateLimitPerPage.bind(this);
  }

  componentDidMount = () => {
    this.fetchDispatchData(this.state.limit, this.state.offset);
    this.refreshInterval = setInterval(
      () => this.fetchDispatchData(this.state.limit, this.state.offset),
      300000
    ); // 5 minutes;
  };

  componentWillUnmount = () => {
    if (this.refreshInterval) {
      clearInterval(this.refreshInterval);
    }
  };

  componentWillReceiveProps(nextProps) {
    const nextSearch = queryString.parse(nextProps.location.search);
    if (
      this.props.location.search &&
      nextProps.location.search &&
      this.props.location.search !== nextProps.location.search
    ) {
      this.fetchDispatchData(nextSearch.limit, nextSearch.offset);
      this.setState({
        limit: parseInt(nextSearch.limit, 10),
        offset: parseInt(nextSearch.offset, 10),
      });
    }
  }

  fetchDispatchData = (limit, offset) => {
    this.props.tradingActions.fetchDispatches(limit, offset);
    this.props.tradingActions.fetchPowerstations();
  };

  getPage = (limit, offset) => {
    this.updateUrl(limit, offset);
  };

  showNewDispatch = () => {
    this.setState({
      showNewDispatch: true,
    });
  };

  hideNewDispatch = () => {
    this.setState({
      showNewDispatch: false,
    });
  };

  updateLimitPerPage(limit) {
    this.setState({ limit });
  }

  updateUrl = (limit, offset) => {
    let url = `${this.props.location.pathname}?limit=${limit}&offset=${offset}`;
    this.props.history.push(url);
  };

  render() {
    if (this.props.loading || !this.props.dispatches || !this.props.powerstations) {
      return <Loading />;
    }

    let dispatches = this.props.dispatches;
    dispatches.sort((a, b) => {
      const aRequest = a.request;
      const bRequest = b.request;
      if (aRequest.startTime > bRequest.startTime) return -1;
      if (aRequest.startTime < bRequest.startTime) return 1;
      if (aRequest.startTime === bRequest.startTime) {
        if (aRequest.duration > bRequest.duration) return -1;
        if (aRequest.duration < bRequest.duration) return 1;
        return 0;
      }
      return 0;
    });

    return (
      <Page key="dispatch-list" permission={Permissions.fleet.dispatches.any}>
        <Page.Header title="Dispatches">
          <AuthRequired permission={Permissions.fleet.dispatches.edit}>
            <Page.Header.Actions>
              <Button
                id="new-dispatch-from-dispatch-list"
                type="primary"
                icon="plus"
                onClick={this.showNewDispatch}
              >
                New Dispatch
              </Button>
            </Page.Header.Actions>
          </AuthRequired>
        </Page.Header>
        <Page.Content>
          <DispatchListTable
            dispatches={dispatches}
            powerstations={this.props.powerstations}
            timezone={this.props.user.account.timezone}
            history={this.props.history}
          />
          <PagingControl
            limit={this.state.limit}
            offset={this.state.offset}
            totalItems={this.props.dispatchesPageMeta.count}
            itemName="dispatch"
            onPageChange={(limit, offset) => this.getPage(limit, offset)}
            onChangeResultsPerPage={this.updateLimitPerPage}
            orientation="up"
          />
          {this.state.showNewDispatch ? (
            <NewDispatchModal handleOnCancel={this.hideNewDispatch} />
          ) : null}
        </Page.Content>
      </Page>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DispatchList);

import React, { Component } from "react";
import { Loading, ProgressBar, ProgressText, InfoBox } from "../widgets";
import "./DataExportSummaryItem.css";
import { durationToStringUpdate } from "../../utils/formatting";
import {
  CUSTOMER_TASK,
  getMetricDisplayName,
  OPERATIONAL_TASK,
  SUBMETER_METRICS,
} from "../../utils/analysis";
import MarkdownModal from "../widgets/MarkdownModal";

class DataExportSummaryItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalIsOpen: false,
    };
  }

  openModal = () => {
    this.setState({
      modalIsOpen: true,
    });
  };

  closeModal = () => {
    this.setState({
      modalIsOpen: false,
    });
  };

  getMissingFiles = () => {
    let missingFiles = [];
    if (!this.props.details[this.props.taskId].signed_urls) {
      return missingFiles;
    }
    let files = this.props.details[this.props.taskId].signed_urls;
    for (let i in this.props.metrics) {
      const metric = this.props.metrics[i];
      let submeterMetric = null;
      if (this.props.submeters && Object.keys(SUBMETER_METRICS).includes(metric)) {
        submeterMetric = SUBMETER_METRICS[metric].metric;
      }
      if (files) {
        const file = files.find(d => d.metric === metric);
        const submeterFile = files.find(d => d.metric === submeterMetric);
        if (!file) {
          missingFiles.push(getMetricDisplayName(metric));
        }
        if (submeterMetric && !submeterFile) {
          missingFiles.push(getMetricDisplayName(submeterMetric));
        }
      }
    }
    return missingFiles;
  };

  getSignedUrls = props => {
    if (props.details[props.taskId].status === "SUCCESS") {
      if (props.taskType === OPERATIONAL_TASK) {
        let urls = [];
        for (let i in props.details[props.taskId].signed_urls) {
          let item = props.details[props.taskId].signed_urls[i];
          urls.push(
            <div>
              <a href={item.signed_url}>{getMetricDisplayName(item.metric)}</a>
              <span> ({item.size})</span>
            </div>
          );
        }
        return urls;
      } else if (props.taskType === CUSTOMER_TASK) {
        if (props.details[props.taskId].signed_urls.length > 0) {
          return (
            <div>
              <a href={props.details[props.taskId].signed_urls[0].signed_url}>
                {props.details[props.taskId].signed_urls[0].filename}
              </a>
              <span> ({props.details[props.taskId].signed_urls[0].size})</span>
            </div>
          );
        } else {
          return <div className="no-data">(no data)</div>;
        }
      }
    } else {
      return <div className="no-data">(no data)</div>;
    }
  };

  render() {
    if (this.props.status === "PROGRESS" || this.props.status === "PENDING") {
      let percentage = this.props.percentageComplete
        ? Math.min(this.props.percentageComplete, 1)
        : 0;
      let timeRemaining = this.props.timeRemaining ? Math.max(this.props.timeRemaining, 0) : 0;
      return (
        <div className="data-export-summary-item">
          <ProgressBar progress={(percentage * 100).toFixed(0)} final="100" />
          <ProgressText progress={(percentage * 100).toFixed(0)} final="100" unit="%" />
          <span className="progress-help">Progress will update automatically</span>
          <div className="progress-help">
            Estimated Time Remaining: {durationToStringUpdate(timeRemaining / 1000)}
          </div>
        </div>
      );
    }
    if (
      !this.props.details ||
      this.props.details[this.props.taskId] == null ||
      this.props.details[this.props.taskId].loading
    ) {
      return (
        <div style={{ position: "relative", margin: "2em", marginBottom: "3em" }}>
          <Loading />
        </div>
      );
    }

    if (this.props.details[this.props.taskId].empty && this.props.status === "SUCCESS") {
      if (this.props.taskType === OPERATIONAL_TASK) {
        return "No data was found for this powerstation for the period specified.";
      } else {
        return "No data found.";
      }
    }
    return (
      <div className="data-export-summary-item">
        {this.props.status === "FAILURE" && "There was an error generating data."}
        {this.props.status === "SUCCESS" &&
          !this.props.details[this.props.taskId].loading &&
          this.getSignedUrls(this.props)}
        {this.props.taskType === OPERATIONAL_TASK &&
          this.getMissingFiles().map(mf => {
            return (
              <div className="no-data" key={mf}>
                <span>{mf}</span>
                <span> (no data)</span>
              </div>
            );
          })}
        {this.props.taskType === OPERATIONAL_TASK && (
          <InfoBox>
            Blank fields in a CSV indicate that there is missing data for that interval.
          </InfoBox>
        )}
        {this.props.taskType === CUSTOMER_TASK &&
          this.props.details[this.props.taskId].signed_urls &&
          this.props.details[this.props.taskId].signed_urls.length > 0 && (
            <div className="explanation-modal-link">
              <button
                id="open-explanation-modal"
                className="mp-button link"
                type="secondary"
                onClick={this.openModal}
              >
                Click here for an explanation of each field
              </button>
            </div>
          )}

        {this.state.modalIsOpen && (
          <MarkdownModal
            title="Customer CSV Fields"
            id={`customerCSVFields.${this.props.suborgType.toLowerCase()}`}
            closeModal={this.closeModal}
          />
        )}
      </div>
    );
  }
}

export default DataExportSummaryItem;

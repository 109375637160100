import React from "react";
import { Link } from "react-router-dom";
import { ProgressBar, ProgressText, Loading, Tooltip } from "../widgets";
import { asPower, asCapacity } from "../../utils/formatting";
import DispatchResponseGraph from "./DispatchResponseGraph";
import DispatchOverviewPricing from "./DispatchOverviewPricing";
import "./DispatchOverview.css";

const getLatestDataPoint = tsData => {
  let latestTs = 0;
  for (let i in tsData) {
    latestTs = i > latestTs ? i : latestTs;
  }

  return tsData[latestTs];
};

function asDirection(realPowerP) {
  if (realPowerP === 0) {
    return "lockout";
  } else if (realPowerP > 0) {
    return "charge";
  } else if (realPowerP < 0) {
    return "discharge";
  } else {
    return "";
  }
}

function asDirectionPastTense(realPowerP) {
  if (realPowerP > 0) {
    return "charged";
  } else if (realPowerP < 0) {
    return "discharged";
  } else {
    return "";
  }
}

const DispatchOverview = ({
  dispatch,
  prediction,
  response,
  timezone,
  remainingCharge,
  dispatchCost,
  showMarketData,
}) => {
  return (
    <>
      <div className="dispatch-overview">
        <div className="response-container">
          {(dispatch.state === "INPROGRESS" && response && prediction) ||
          (dispatch.state === "UPCOMING" && prediction) ||
          (dispatch.state === "COMPLETED" && response) ? (
            <div className="graph-wrapper">
              <DispatchResponseGraph
                prediction={prediction}
                response={response}
                startTime={dispatch.request.startTime}
                duration={dispatch.request.duration}
                accepted={dispatch.currentResponse.realPowerP}
                yAxisLocation="left"
                timezone={timezone}
                request={dispatch.request}
              />
            </div>
          ) : (
            <div className="graph-loading-container">
              <Loading />
            </div>
          )}
          <div className="node-count-container">
            <ProgressText
              progress={dispatch.currentResponse.deploymentsResponded}
              final={dispatch.request.deploymentsRequested}
            />
            <ProgressBar
              progress={dispatch.currentResponse.deploymentsResponded}
              final={dispatch.request.deploymentsRequested}
            />
            <div style={{ padding: "5px" }}>
              <strong>Nodes responded</strong>
              <Tooltip medium id="dispatches.detail.nodesResponded" />
            </div>
          </div>
        </div>

        <div className="details-container">
          <div className="capacity accepted">
            <div className="value">
              {asPower(dispatch.currentResponse.realPowerP)}{" "}
              {asDirection(dispatch.currentResponse.realPowerP)}
            </div>
            <div className="label">accepted</div>
            {dispatch.state === "COMPLETED" && dispatch.currentResponse.realPowerP !== 0 ? (
              <div>
                <div className="value">
                  {asCapacity(Math.abs(dispatch.currentResponse.deliveredKwh))}{" "}
                  {asDirectionPastTense(dispatch.currentResponse.deliveredKwh)}
                </div>
                <div className="label">delivered</div>
              </div>
            ) : null}

            {dispatch.currentResponse.rejected_real_power_p !== 0 ? (
              <div className="reasons rejected">
                <strong>{asPower(dispatch.currentResponse.rejectedRealPowerP)}</strong> rejected due
                to <strong>capacity constraints</strong>
                <Tooltip id="dispatches.detail.capacityConstraints" />
              </div>
            ) : null}
          </div>

          {response &&
          response.batteryPower &&
          (dispatch.state === "INPROGRESS" || dispatch.state === "UPCOMING") ? (
            <div className="capacity battery-power">
              <div className="value">{asPower(getLatestDataPoint(response.batteryPower))}</div>
              <div className="label">current battery power</div>
            </div>
          ) : null}

          {response &&
          response.meterPower &&
          (dispatch.state === "INPROGRESS" || dispatch.state === "UPCOMING") ? (
            <div className="capacity meter-power">
              <div className="value">{asPower(getLatestDataPoint(response.meterPower))}</div>
              <div className="label">current meter power</div>
            </div>
          ) : null}

          {remainingCharge &&
          (dispatch.state === "INPROGRESS" ||
            (dispatch.state === "UPCOMING" &&
              response &&
              (response.meterPower || response.batteryPower))) ? (
            <div className="capacity soc">
              <div className="value">{asCapacity(remainingCharge)}</div>
              <div className="label">remaining charge</div>
            </div>
          ) : null}

          <DispatchOverviewPricing
            dispatch={dispatch}
            dispatchCost={dispatchCost}
            showMarketData={showMarketData}
          />

          <div className="dispatch-overview-footer">
            {dispatch.state === "INPROGRESS" || dispatch.state === "UPCOMING" ? (
              <span className="refresh-interval">Data will update automatically</span>
            ) : null}
            <span className="">
              All times are in <Link to="/user/settings">{timezone}</Link>
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default DispatchOverview;

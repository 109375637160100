import { history } from "../../containers/Root";

export const FETCH_POWERSTATIONS = "FETCH_POWERSTATIONS";
export const UPDATE_POWERSTATIONS = "UPDATE_POWERSTATIONS";
export const FETCH_ACTIVE_POWERSTATION = "FETCH_ACTIVE_POWERSTATION";
export const UPDATE_ACTIVE_POWERSTATION = "UPDATE_ACTIVE_POWERSTATION";
export const CLEAR_ACTIVE_POWERSTATION = "CLEAR_ACTIVE_POWERSTATION";
export const FETCH_POWERSTATION_DEPLOYMENTS = "FETCH_POWERSTATION_DEPLOYMENTS";
export const UPDATE_POWERSTATION_DEPLOYMENTS = "UPDATE_POWERSTATION_DEPLOYMENTS";
export const FETCH_EDIT_POWERSTATION = "FETCH_EDIT_POWERSTATION";
export const UPDATE_EDIT_POWERSTATION = "UPDATE_EDIT_POWERSTATION";
export const FETCH_NEW_POWERSTATION = "FETCH_NEW_POWERSTATION";
export const APPEND_POWERSTATION = "APPEND_POWERSTATION";
export const DELETE_POWERSTATION = "DELETE_POWERSTATION";
export const REMOVE_POWERSTATION = "REMOVE_POWERSTATION";
export const FETCH_INCOMPLETE_POWERSTATION_EVENTS = "FETCH_INCOMPLETE_POWERSTATION_EVENTS";
export const UPDATE_INCOMPLETE_POWERSTATION_EVENTS = "UPDATE_INCOMPLETE_POWERSTATION_EVENTS";
export const FETCH_UPCOMING_EVENTS = "FETCH_UPCOMING_EVENTS";
export const UPDATE_UPCOMING_EVENTS = "UPDATE_UPCOMING_EVENTS";
export const FETCH_NEW_POWERSTATION_ERROR = "FETCH_NEW_POWERSTATION_ERROR";
export const SET_LOADING_POWERSTATION = "SET_LOADING_POWERSTATION";
export const FETCH_UNUSED_DEPLOYMENTS = "FETCH_UNUSED_DEPLOYMENTS";
export const UPDATE_UNUSED_DEPLOYMENTS = "UPDATE_UNUSED_DEPLOYMENTS";
export const FETCH_POWERSTATION_MAP = "FETCH_POWERSTATION_MAP";
export const UPDATE_POWERSTATION_MAP = "UPDATE_POWERSTATION_MAP";
export const FETCH_POWERSTATION_NAMEPLATE = "FETCH_POWERSTATION_NAMEPLATE";
export const UPDATE_POWERSTATION_NAMEPLATE = "UPDATE_POWERSTATION_NAMEPLATE";
export const PATCH_POWERSTATION = "PATCH_POWERSTATION";
export const UPDATE_PATCHED_POWERSTATION = "UPDATE_PATCHED_POWERSTATION";
export const PATCH_POWERSTATION_ERROR = "PATCH_POWERSTATION_ERROR";

export function fetchPowerstations(activeStationId, limit, offset) {
  return {
    type: FETCH_POWERSTATIONS,
    meta: {
      endpoint: `/v2/powerstations?limit=${limit}&offset=${offset}`,
      success: updatePowerstations,
      errorMessage: "Failed to load Virtual Power Plant.",
    },
  };
}

function updatePowerstations(powerstations) {
  return {
    type: UPDATE_POWERSTATIONS,
    payload: {
      powerstations: powerstations.data ? powerstations.data.result : [],
      total: powerstations.data ? powerstations.data.total : 0,
    },
  };
}

export function fetchActivePowerstation(powerstationId) {
  return {
    type: FETCH_ACTIVE_POWERSTATION,
    meta: {
      endpoint: `/v2/powerstations/${powerstationId}`,
      success: updateActivePowerstation,
      errorMessage: "Failed to load Virtual Power Plant.",
    },
  };
}

function updateActivePowerstation(powerstation) {
  return {
    type: UPDATE_ACTIVE_POWERSTATION,
    payload: {
      powerstation,
    },
  };
}

export function clearActivePowerstation() {
  return {
    type: CLEAR_ACTIVE_POWERSTATION,
  };
}

export function deletePowerstation(powerstationId, name) {
  return {
    type: DELETE_POWERSTATION,
    meta: {
      endpoint: `/api/powerstations/${powerstationId}`,
      method: "DELETE",
      errorMessage: `Failed to delete Power Station ${name}.`,
      success: () => removePowerstation(powerstationId),
      successMessage: `Power Station ${name} was deleted successfully.`,
    },
  };
}

function removePowerstation(powerstationId) {
  history.push("/virtual-power-plants/manage");
  return {
    type: REMOVE_POWERSTATION,
    payload: {
      stationId: powerstationId,
    },
  };
}

export function fetchIncompletePowerstationEvents(powerstationUid) {
  return {
    type: FETCH_INCOMPLETE_POWERSTATION_EVENTS,
    meta: {
      endpoint: `/api/powerstations/${powerstationUid}/events?status=INCOMPLETE`,
      success: updateIncompletePowerstationEvents,
      errorMessage: "Failed to load incomplete powerstation events",
    },
  };
}

function updateIncompletePowerstationEvents(events) {
  return {
    type: UPDATE_INCOMPLETE_POWERSTATION_EVENTS,
    payload: {
      events: events.data,
    },
  };
}

export function fetchUpcomingEvents(powerstationUid) {
  return {
    type: FETCH_UPCOMING_EVENTS,
    meta: {
      endpoint: `/api/events?state=INCOMPLETE`,
      success: updateUpcomingEvents,
      errorMessage: "Failed to load incomplete events",
    },
  };
}

function updateUpcomingEvents(events) {
  return {
    type: UPDATE_UPCOMING_EVENTS,
    payload: {
      events: events.data,
    },
  };
}

export function setLoading() {
  return {
    type: SET_LOADING_POWERSTATION,
  };
}

export function fetchUnusedDeployments(limit, offset) {
  return {
    type: FETCH_UNUSED_DEPLOYMENTS,
    meta: {
      endpoint: `/v2/unused-nodes?limit=${limit}&offset=${offset}`,
      success: updateUnusedDeployments,
      errorMessage: "Failed to load unused nodes.",
    },
  };
}

export function updateUnusedDeployments(response) {
  return {
    type: UPDATE_UNUSED_DEPLOYMENTS,
    payload: {
      deployments: response.deployments,
      total: response.total,
    },
  };
}

export function fetchDeployments(
  powerstationId,
  limit,
  offset,
  filter,
  includeUnusedNodes,
  deploymentState
) {
  let endpoint = `/v2/powerstations/${powerstationId}/nodes?`;
  if (filter) {
    endpoint += `f=${encodeURIComponent(filter)}&`;
  }
  if (includeUnusedNodes) {
    endpoint += `include_unused_deployments=true&`;
  }
  if (deploymentState) {
    endpoint += `deployment_state=${deploymentState}&`;
  }
  endpoint += `limit=${limit}&offset=${offset}`;

  return {
    type: FETCH_POWERSTATION_DEPLOYMENTS,
    meta: {
      endpoint,
      success: updateDeployments,
      errorMessage: "Failed to load nodes.",
    },
  };
}

function updateDeployments(deployments) {
  return {
    type: UPDATE_POWERSTATION_DEPLOYMENTS,
    payload: {
      deployments: deployments.deployments,
      total: deployments.total,
    },
  };
}

export function fetchDynamicDeployments(limit, offset, filter, state, postcodes) {
  let endpoint = `/v2/nodes?nameplate=true&`;
  if (filter) {
    endpoint += `f=${encodeURIComponent(filter)}&`;
  }
  if (state) {
    endpoint += `state=${state}&`;
  }
  if (postcodes) {
    endpoint += `postcodes=${postcodes}&`;
  }
  endpoint += `limit=${limit}&offset=${offset}`;

  return {
    type: FETCH_POWERSTATION_DEPLOYMENTS,
    meta: {
      endpoint,
      success: updateDeployments,
      errorMessage: "Failed to load nodes.",
    },
  };
}

export function fetchNameplate(state, postcodes) {
  let endpoint = `/v2/nodes?nameplate=true&`;
  if (state) {
    endpoint += `state=${state}&`;
  }
  if (postcodes) {
    endpoint += `postcodes=${postcodes}&`;
  }
  endpoint += `limit=10&offset=0`;

  return {
    type: FETCH_POWERSTATION_NAMEPLATE,
    meta: {
      endpoint,
      success: updateNameplate,
      errorMessage: "Failed to load nameplate.",
    },
  };
}

function updateNameplate(deployments) {
  return {
    type: UPDATE_POWERSTATION_NAMEPLATE,
    payload: {
      total: deployments.total,
      namePlate: deployments.nameplate,
    },
  };
}

export function fetchPowerstationMap(powerstationId) {
  return {
    type: FETCH_POWERSTATION_MAP,
    meta: {
      endpoint: `/v2/powerstations/${powerstationId}/map`,
      success: updatePowerstationMap,
      errorMessage: "Failed to load powerstation map.",
    },
  };
}

function updatePowerstationMap(mapData) {
  return {
    type: UPDATE_POWERSTATION_MAP,
    payload: {
      map: mapData.deployments,
    },
  };
}

export function patchPowerstation(powerstationUid, method, deploymentIds, filter, name) {
  const body = {};
  if (method) {
    body.nodes = { method };
  }
  if (filter) {
    body.nodes.filter = filter;
  }
  if (deploymentIds) {
    body.nodes.list = deploymentIds;
  }
  if (name) {
    body.name = name;
  }
  return {
    type: PATCH_POWERSTATION,
    meta: {
      endpoint: `/v2/powerstations/${powerstationUid}`,
      method: "PATCH",
      body,
      success: patchPowerstationSuccess,
      successMessage: "Virtual Power Plant successfully updated.",
      error: patchPowerstationFailure,
      errorMessage: "Failed to update Virtual Power Plant.",
    },
    payload: {
      powerstationUid,
      deploymentIds,
      method,
      filter,
      exitScreen: body.name && !body.nodes,
    },
  };
}

function patchPowerstationSuccess(response, payload) {
  if (payload.exitScreen) {
    history.push(
      `/virtual-power-plants/manage/${payload.powerstationUid}?tab=MAP&limit=10&offset=0`
    );
  }
  return {
    type: UPDATE_PATCHED_POWERSTATION,
    payload: {
      response,
      deploymentIds: payload.deploymentIds,
      method: payload.method,
      filter: payload.filter,
      exitScreen: payload.exitScreen,
    },
  };
}

function patchPowerstationFailure(response, payload) {
  return {
    type: PATCH_POWERSTATION_ERROR,
    payload: {
      response,
    },
  };
}

export function fetchEditPowerstation(powerstationType, name, nodes, powerstationUid, filters) {
  return {
    type: FETCH_EDIT_POWERSTATION,
    meta: {
      endpoint: "/api/powerstations/" + powerstationUid,
      method: "PUT",
      body: {
        type: powerstationType,
        name: name,
        nodes: nodes,
        filters: filters,
      },
      success: editPowerstationSuccess,
      successMessage: "Virtual Power Plant " + name + " successfully updated.",
      errorMessage: "Failed to update Virtual Power Plant.",
      // finally: editPowerstationComplete
    },
    payload: {
      powerstationUid: powerstationUid,
    },
  };
}

function editPowerstationSuccess(_, payload) {
  history.push(`/virtual-power-plants/manage/${payload.powerstationUid}?tab=MAP&limit=10&offset=0`);
  return {
    type: UPDATE_EDIT_POWERSTATION,
  };
}

export function fetchNewPowerstation(powerstationType, name, nodes, powerstationUid, filters) {
  return {
    type: FETCH_NEW_POWERSTATION,
    meta: {
      endpoint: "/api/powerstations",
      method: "POST",
      body: {
        type: powerstationType,
        name: name,
        nodes: nodes,
        filters: filters,
      },
      success: newPowerstationSuccess,
      error: newPowerstationError,
      successMessage: "Virtual Power Plant " + name + " successfully created.",
      errorMessage: "Failed to create Virtual Power Plant.",
    },
    payload: {
      powerstationUid: powerstationUid,
      powerstationType,
    },
  };
}

function newPowerstationSuccess(response) {
  if (response.data.type === "STATIC") {
    history.push(`/virtual-power-plants/manage/${response.data.id}/edit?limit=10&offset=0`);
  } else {
    history.push(`/virtual-power-plants/manage/${response.data.id}?tab=MAP&limit=10&offset=0`);
  }

  return {
    type: APPEND_POWERSTATION,
    payload: {
      powerstation: response.data,
    },
  };
}

function newPowerstationError() {
  return {
    type: FETCH_NEW_POWERSTATION_ERROR,
  };
}

import React, { Component } from "react";
import Sidebar from "../containers/layout/Sidebar";
import "./SidebarLayout.css";

export class SidebarLayout extends Component {
  render() {
    return (
      <div className="content-wrapper">
        <Sidebar />
        <div className="content-container with-sidebar">
          <div className="content">{this.props.children}</div>
        </div>
      </div>
    );
  }
}

export default SidebarLayout;

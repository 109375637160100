import React, { Component } from "react";
import Button from "../widgets/Button";
import "./KitDesignChecklist.css";

// TODO flesh this out with checkboxes or remove it
class KitDesignChecklist extends Component {
  render() {
    const onAccept = this.props.onAccept;

    return (
      <div className="kit-design-checklist">
        <p>
          This design tool helps you find the correct Reposit for your upcoming Reposit
          installations.
        </p>
        <p>
          Reposit needs to collect data from all solar and hybrid/battery inverters installed on a
          single site.
        </p>
        <p>
          A 'single site' is defined by all energy consumption and solar generation occurring behind
          a single utility meter (and with a single NMI).
        </p>
        <p>
          Before getting started please check the Reposit Design Checklist below to make sure
          Reposit is suitable for your site.
        </p>
        <ul>
          <li>Reposit does not supply switchboard enclosures or cabling.</li>
          <li>
            Reposit CT clamps for supply mains and solar generation are only 16mm<sup>2</sup>. If
            you require larger CT clamps for a project please contact&nbsp;
            <a href="mailto:support@repositpower.com">support@repositpower.com</a>.
          </li>
          <li>
            Reposit CT clamps for supply mains and solar generation require a hardwired RS485
            connection back to the Reposit controller. If there is a solar inverter installed on a
            detached building, cable trenching may be required. For advice please contact&nbsp;
            <a href="mailto:support@repositpower.com">support@repositpower.com</a>.
          </li>
          <li>Reposit equipment must be shaded from direct sunlight.</li>
          <li>
            Reposit does not currently meter or control any dedicated circuit/off-peak loads i.e.
            electric hot water or in-slab heating
          </li>
        </ul>

        <Button type="primary" onClick={onAccept} icon="check">
          Get Started
        </Button>
      </div>
    );
  }
}

export default KitDesignChecklist;

import * as actions from "../actions/notifications";

const notificationsInitialState = {
  notifications: null,
  activeNotification: null,
};

const notificationsReducer = (state = notificationsInitialState, action) => {
  switch (action.type) {
    case actions.UPDATE_NOTIFICATIONS:
      return {
        ...state,
        notifications: action.payload.notifications,
      };
    case actions.UPDATE_NOTIFICATION:
      return {
        ...state,
        activeNotification: action.payload.notification,
      };
    case actions.CLEAR_ACTIVE_NOTIFICATION:
      return {
        ...state,
        activeNotification: null,
      };
    case actions.UPDATE_MARK_READ_NOTIFICATION:
      return {
        ...state,
        notifications: state.notifications
          ? state.notifications.map(notification => {
              if (notification.id === action.payload.notificationId) {
                notification.read = true;
              }
              return notification;
            })
          : null,
        activeNotification:
          state.activeNotification && state.activeNotification.id === action.payload.notificationId
            ? { ...state.activeNotification, read: true }
            : state.activeNotification,
      };
    case actions.UPDATE_MARK_UNREAD_NOTIFICATION:
      return {
        ...state,
        notifications: state.notifications
          ? state.notifications.map(notification => {
              if (notification.id === action.payload.notificationId) {
                notification.read = false;
              }
              return notification;
            })
          : null,
        activeNotification:
          state.activeNotification && state.activeNotification.id === action.payload.notificationId
            ? { ...state.activeNotification, read: false }
            : state.activeNotification,
      };
    case actions.UPDATE_MARK_ALL_READ_NOTIFICATION:
      return {
        ...state,
        notifications: state.notifications
          ? state.notifications.map(notification => {
              notification.read = true;
              return notification;
            })
          : null,
        activeNotification: state.activeNotification
          ? { ...state.activeNotification, read: true }
          : state.activeNotification,
      };
    default:
      return state;
  }
};

export default notificationsReducer;

import React, { Component } from "react";
import KitDesignModal from "../../components/ordering/KitDesignModal";
import * as orderActions from "../../redux/actions/orders";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

const mapStateToProps = state => ({
  constraints: state.orders.constraints,
  kit: state.orders.kit,
});

const mapDispatchToProps = dispatch => {
  return {
    orderActions: bindActionCreators(orderActions, dispatch),
  };
};

const serializeDesign = design => {
  return {
    gridPhases: design.gridPhases.value,
    inverterSystems: Object.entries(design.inverterSystems).reduce((mapped, [uid, system]) => {
      mapped[uid] = {
        inverter: system.inverter.value,
        pvConnected: system.pvConnected.value,
        pvPhases: system.pvPhases.value,
        battery: system.battery.value,
      };
      return mapped;
    }, {}),
  };
};

class KitDesigner extends Component {
  componentDidMount = () => {
    this.props.orderActions.fetchKitOptions();
  };

  handleGridPhasesChanged = numPhases => {
    this.props.orderActions.updateGridPhases(numPhases);
  };

  handleNumInverterSystemsChanged = num => {
    this.props.orderActions.updateNumInverterSystems(num);
  };

  handleInverterSystemAdded = () => {
    this.props.orderActions.addInverterSystem();
  };

  handleInverterSystemRemoved = uid => {
    this.props.orderActions.removeInverterSystem(uid);
  };

  handleInverterChanged = (uid, inverter) => {
    this.props.orderActions.updateInverterSystem(uid, { inverter: inverter });
  };

  handlePvChanged = (uid, connected) => {
    this.props.orderActions.updateInverterSystem(uid, { pvConnected: connected });
  };

  handlePvPhasesChanged = (uid, pvPhases) => {
    this.props.orderActions.updateInverterSystem(uid, {
      pvPhases: pvPhases,
    });
  };

  handleBatteryChange = (uid, battery) => {
    this.props.orderActions.updateInverterSystem(uid, { battery: battery });
  };

  handleClose = () => {
    this.props.orderActions.resetKitBuilder();
    this.props.onClose();
  };

  handleSubmitDesign = () => {
    this.props.orderActions.submitKitDesign(serializeDesign(this.props.kit.design));
  };

  scrubOrder = () => {
    this.props.orderActions.updateKitOrder(null);
  };

  handleSubmitOrder = referenceNotes => {
    this.props.orderActions.emailKit({
      order: this.props.kit.order,
      design: serializeDesign(this.props.kit.design),
      referenceNotes: referenceNotes,
    });
  };

  render() {
    const {
      constraints: { inverterTypes, batteryTypes, loading: constraintsLoading },
      kit: { design, order, loading: kitLoading, emailed },
    } = this.props;

    return (
      <KitDesignModal
        inverterTypes={inverterTypes}
        batteryTypes={batteryTypes}
        design={design}
        order={order}
        constraintsLoading={constraintsLoading}
        kitLoading={kitLoading}
        emailed={emailed}
        onGridPhasesChanged={this.handleGridPhasesChanged}
        onNumInverterSystemsChanged={this.handleNumInverterSystemsChanged}
        onInverterSystemAdded={this.handleInverterSystemAdded}
        onMultipleInverterSystemsAdded={this.handleMultipleInverterSystemsAdded}
        onInverterSystemRemoved={this.handleInverterSystemRemoved}
        onInverterChanged={this.handleInverterChanged}
        onPvChanged={this.handlePvChanged}
        onPvPhasesChanged={this.handlePvPhasesChanged}
        onBatteryChange={this.handleBatteryChange}
        onClose={this.handleClose}
        onSubmitDesign={this.handleSubmitDesign}
        scrubOrder={this.scrubOrder}
        onSubmitOrder={this.handleSubmitOrder}
      />
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(KitDesigner);

import * as actions from "../actions/orders";

const uuid1 = require("uuid/v1");

const ordersInitialState = {
  constraints: {
    inverterTypes: null,
    batteryTypes: null,
    loading: false,
  },
  kit: {
    design: null,
    order: null,
    loading: false,
    emailed: false,
  },
};

const ordersReducer = (state = ordersInitialState, action) => {
  switch (action.type) {
    case actions.FETCH_KIT_OPTIONS:
      return {
        ...state,
        constraints: {
          ...state.constraints,
          loading: true,
        },
      };

    case actions.UPDATE_KIT_OPTIONS:
      return {
        ...state,
        constraints: {
          inverterTypes: action.payload.inverterTypes,
          batteryTypes: action.payload.batteryTypes,
          loading: false,
        },
      };

    case actions.SUBMIT_KIT_DESIGN:
      return {
        ...state,
        kit: {
          ...state.kit,
          loading: true,
        },
      };

    case actions.UPDATE_KIT_DESIGN_ERRORS:
      const updatedInverterSystems = action.payload.errors.inverterSystems
        ? Object.entries(action.payload.errors.inverterSystems).reduce((mapped, [uid, errors]) => {
            mapped[uid] = {
              inverter: {
                ...state.kit.design.inverterSystems[uid].inverter,
                error: errors.inverter || null,
              },
              battery: {
                ...state.kit.design.inverterSystems[uid].battery,
                error: errors.battery || null,
              },
              pvConnected: {
                ...state.kit.design.inverterSystems[uid].pvConnected,
                error: errors.pvConnected || null,
              },
              pvPhases: {
                ...state.kit.design.inverterSystems[uid].pvPhases,
                error: errors.pvPhases || null,
              },
            };
            return mapped;
          }, {})
        : {};

      return {
        ...state,
        kit: {
          ...state.kit,
          loading: false,
          design: {
            ...state.kit.design,
            gridPhases: {
              ...state.kit.design.gridPhases,
              error: action.payload.errors.gridPhases || null,
            },
            inverterSystems: {
              ...state.kit.design.inverterSystems,
              ...updatedInverterSystems,
            },
          },
        },
      };

    case actions.UPDATE_KIT_ORDER:
      return {
        ...state,
        kit: {
          ...state.kit,
          order: action.payload.order,
          loading: false,
          emailed: false,
        },
      };

    case actions.EMAIL_KIT:
      return {
        ...state,
        kit: {
          ...state.kit,
          loading: true,
        },
      };

    case actions.UPDATE_KIT_EMAILED:
      return {
        ...state,
        kit: {
          ...state.kit,
          loading: false,
          emailed: true,
        },
      };

    case actions.KIT_FINISHED_LOADING:
      return {
        ...state,
        kit: {
          ...state.kit,
          loading: false,
        },
      };

    case actions.BOOTSTRAP_KIT_DESIGN:
      return {
        ...state,
        kit: {
          ...state.kit,
          design: {
            gridPhases: {
              value: 1,
              error: null,
            },
            inverterSystems: {},
          },
        },
      };

    case actions.RESET_KIT_BUILDER:
      return {
        ...state,
        kit: ordersInitialState.kit,
      };

    case actions.UPDATE_GRID_PHASES:
      return {
        ...state,
        kit: {
          ...state.kit,
          design: {
            ...state.kit.design,
            gridPhases: {
              value: action.payload.gridPhases,
              error: null,
            },
          },
        },
      };

    case actions.UPDATE_NUM_INVERTER_SYSTEMS: {
      const existingSystems = state.kit.design.inverterSystems;
      const existingUids = Object.keys(existingSystems);

      const existingNumber = existingUids.length;
      const targetNumber = action.payload.numSystems;

      if (existingNumber === targetNumber) {
        return state;
      }

      let newSystems = { ...existingSystems };

      if (existingNumber < targetNumber) {
        for (let i = existingNumber; i < targetNumber; i++) {
          newSystems[uuid1()] = {
            inverter: {
              value: null,
              error: null,
            },
            pvConnected: {
              value: false,
              error: null,
            },
            pvPhases: {
              value: null,
              error: null,
            },
            battery: {
              value: null,
              error: null,
            },
          };
        }
      } else {
        for (let i = existingNumber; i >= targetNumber; i--) delete newSystems[existingUids[i]];
      }

      return {
        ...state,
        kit: {
          ...state.kit,
          design: {
            ...state.kit.design,
            inverterSystems: newSystems,
          },
        },
      };
    }

    case actions.ADD_INVERTER_SYSTEM:
      return {
        ...state,
        kit: {
          ...state.kit,
          design: {
            ...state.kit.design,
            inverterSystems: {
              ...state.kit.design.inverterSystems,
              [uuid1()]: {
                inverter: {
                  value: null,
                  error: null,
                },
                pvConnected: {
                  value: false,
                  error: null,
                },
                pvPhases: {
                  value: null,
                  error: null,
                },
                battery: {
                  value: null,
                  error: null,
                },
              },
            },
          },
        },
      };

    case actions.UPDATE_INVERTER_SYSTEM:
      const { uid, updates } = action.payload;

      if (updates.hasOwnProperty("inverter")) {
        const inverterRules = state.constraints.inverterTypes[updates.inverter];

        if (inverterRules.pvCompatible === false) {
          updates.pvConnected = false;
        }

        if (inverterRules.pvCompatible !== null) {
          updates.pvPhases = null;
        }

        if (inverterRules.compatibleBatteryTypes.length === 0) {
          updates.battery = null;
        }
      }

      if (updates.hasOwnProperty("pvConnected") && updates.pvConnected === false) {
        updates.pvPhases = null;
      }

      if (updates.hasOwnProperty("pvPhases") && !updates.hasOwnProperty("pvConnected")) {
        updates.pvConnected = !!updates.pvPhases;
      }

      const stateUpdates = Object.entries(updates).reduce((mapped, [key, value]) => {
        mapped[key] = {
          value: value,
          error: null,
        };
        return mapped;
      }, {});

      // clear the battery error if there's an inverter change and no battery change
      if (!stateUpdates.hasOwnProperty("battery")) {
        stateUpdates.battery = {
          value: state.kit.design.inverterSystems[uid].battery.value,
          error: null,
        };
      }

      return {
        ...state,
        kit: {
          ...state.kit,
          design: {
            ...state.kit.design,
            inverterSystems: {
              ...state.kit.design.inverterSystems,
              [uid]: {
                ...state.kit.design.inverterSystems[uid],
                ...stateUpdates,
              },
            },
          },
        },
      };

    case actions.REMOVE_INVERTER_SYSTEM: {
      let newSystems = {
        ...state.kit.design.inverterSystems,
      };
      delete newSystems[action.payload.uid];
      return {
        ...state,
        kit: {
          ...state.kit,
          design: {
            ...state.kit.design,
            inverterSystems: newSystems,
          },
        },
      };
    }

    default:
      return state;
  }
};

export default ordersReducer;

import * as actions from "../actions/rert";

const rertInitialState = {
  activeRertDispatch: null,
  newRertDispatch: null,
};

const rertReducer = (state = rertInitialState, action) => {
  switch (action.type) {
    case "RESET_STORE":
      return {
        ...rertInitialState,
      };
    case actions.UPDATE_RERT_DISPATCHES:
      return {
        ...state,
        rertDispatches: action.payload.rertDispatches,
      };
    case actions.UPDATE_RERT_POWERSTATIONS:
      return {
        ...state,
        powerstations: action.payload.powerstations,
      };
    case actions.FETCH_NEW_RERT_DISPATCH_CONSTRAINTS:
      return {
        ...state,
        constraintsLoading: true,
      };
    case actions.UPDATE_NEW_RERT_DISPATCH_CONSTRAINTS:
      return {
        ...state,
        newRertDispatch: {
          ...state.newRertDispatch,
          constraints: action.payload.constraints,
        },
        constraintsLoading: false,
      };
    case actions.FETCH_NEW_RERT_DISPATCH_PREDICTIONS:
      return {
        ...state,
        predictionsLoading: true,
      };
    case actions.UPDATE_NEW_RERT_DISPATCH_PREDICTIONS:
      return {
        ...state,
        newRertDispatch: {
          ...state.newRertDispatch,
          prediction: action.payload.predictions.meterPower,
          adjustedPrediction: action.payload.predictions.dispatchMeterPower,
        },
        predictionsLoading: false,
      };
    case actions.CLEAR_NEW_RERT_DISPATCH_GRAPH_DATA:
      return {
        ...state,
        newRertDispatch: null,
      };
    case actions.UPDATE_PREVIOUS_RERT_DISPATCH_MESSAGES:
      return {
        ...state,
        previousRertDispatchMessages: action.payload.messages,
      };
    case actions.CLEAR_PREVIOUS_RERT_DISPATCH_MESSAGES:
      return {
        ...state,
        previousRertDispatchMessages: null,
      };
    case actions.EXECUTE_RERT_DISPATCH:
      return {
        ...state,
        executeRertDispatchLoading: true,
      };
    case actions.UPDATE_EXECUTE_RERT_DISPATCH:
      return {
        ...state,
        executeRertDispatchLoading: false,
      };
    case actions.UPDATE_ACTIVE_RERT_DISPATCH:
      return {
        ...state,
        activeRertDispatch: action.payload.dispatch,
      };
    case actions.CLEAR_ACTIVE_RERT_DISPATCH:
      return {
        ...state,
        activeRertDispatch: null,
        activeRertDispatchPrediction: null,
        activeRertDispatchResponse: null,
        activeRertDispatchRemainingCharge: null,
      };
    case actions.UPDATE_RERT_DISPATCH_PREDICTION:
      return {
        ...state,
        activeRertDispatchPrediction: action.payload.prediction,
      };
    case actions.UPDATE_RERT_DISPATCH_REMAINING_CHARGE:
      return {
        ...state,
        activeRertDispatchRemainingCharge: action.payload.remainingCharge,
      };
    case actions.UPDATE_RERT_DISPATCH_RESPONSE:
      return {
        ...state,
        activeRertDispatchResponse: action.payload.response,
      };
    case actions.CANCEL_RERT_DISPATCH:
      return {
        ...state,
        cancelRertDispatchLoading: true,
      };
    case actions.UPDATE_CANCEL_RERT_DISPATCH:
      return {
        ...state,
        cancelRertDispatchLoading: false,
      };
    case actions.RESCHEDULE_RERT_DISPATCH:
      return {
        ...state,
        rescheduleRertDispatchLoading: true,
      };
    case actions.UPDATE_RESCHEDULE_RERT_DISPATCH:
      return {
        ...state,
        rescheduleRertDispatchLoading: false,
      };
    default:
      return state;
  }
};

export default rertReducer;

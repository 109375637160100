import React, { Component } from "react";
import { Button } from "../widgets";
import { getDateString } from "../../utils/userNotifications";
import "./UserNotificationsTable.css";

class UserNotificationsTable extends Component {
  markRead = (evt, notification) => {
    evt.preventDefault();
    evt.stopPropagation();
    this.props.markRead(notification.id);
  };

  handleOnRowClick = notification => {
    this.props.history.push(`/user/notifications/${notification.id}`);
  };

  render() {
    let notifications = this.props.notifications;

    if (!notifications || notifications.length === 0) {
      return <div className="fleet-table-no-results">You currently have no notifications.</div>;
    }

    return (
      <table>
        <thead>
          <tr>
            <th>Notification</th>
            {this.props.unreadCount > 0 ? <th>Mark as read</th> : null}
          </tr>
        </thead>
        <tbody>
          {notifications.map(notification => {
            return (
              <tr role="button" onClick={() => this.handleOnRowClick(notification)}>
                <td>
                  <div className="user-notification-container">
                    {notification.read ? (
                      <div className="read-spacer" />
                    ) : (
                      <div className="unread-marker" title="Unread" />
                    )}
                    <div>
                      <div className="datetime">
                        {getDateString(notification.createdAt, this.props.timezone)}
                      </div>
                      <div>{notification.summary}</div>
                    </div>
                  </div>
                </td>
                {this.props.unreadCount > 0 ? (
                  <td>
                    {notification.read ? null : (
                      <Button
                        id="notification-table-mark-read"
                        type="link"
                        onClick={e => this.markRead(e, notification)}
                        icon="check"
                      />
                    )}
                  </td>
                ) : null}
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  }
}

export default UserNotificationsTable;

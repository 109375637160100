import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as orderActions from "../../redux/actions/orders";
import Page from "../../components/layout/Page";
import Permissions from "../../permissions";
import KitDesigner from "./KitDesigner";
import KitDesignChecklist from "../../components/ordering/KitDesignChecklist";

const mapStateToProps = state => ({
  design: state.orders.kit.design,
});

const mapDispatchToProps = dispatch => {
  return {
    orderActions: bindActionCreators(orderActions, dispatch),
  };
};

class KitDesignPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      termsAccepted: false,
    };
  }

  handleTermsAccepted = () => {
    this.props.orderActions.bootstrapKitDesign();
  };

  handleClose = () => {
    this.props.orderActions.resetKitBuilder();
  };

  render() {
    const design = this.props.design;

    return (
      <Page permission={Permissions.fleet.kitDesigner}>
        <Page.Header title="Kit Builder" />
        <Page.Content>
          <KitDesignChecklist onAccept={this.handleTermsAccepted} />
          {design ? <KitDesigner onClose={this.handleClose} /> : null}
        </Page.Content>
      </Page>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(KitDesignPage);

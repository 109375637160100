import React, { Component } from "react";
import { SelectDropdown, InfoBox, DatePicker, TimePicker } from "../widgets";
import "./NewGridOutage.css";
import moment from "moment-timezone";

class NewGridOutage extends Component {
  handleStartDateChange = startDate => {
    let nextDate = this.props.startDateTime.clone();
    nextDate.date(startDate.date());
    nextDate.month(startDate.month());
    nextDate.year(startDate.year());
    nextDate.tz(this.props.timezone);

    this.props.handleStartDateTimeChange(nextDate);
  };

  handleStartTimeChange = nextStartDateTime => {
    this.props.handleStartDateTimeChange(nextStartDateTime);
  };

  handleEndTimeChange = nextEndDateTime => {
    const duration = moment.duration(nextEndDateTime.diff(this.props.startDateTime));

    if (duration < 0) {
      return;
    }

    this.props.handleDurationChange(duration.asSeconds());
  };

  render() {
    if (!this.props.powerstations && this.props.allowPowerstationEdit) {
      return (
        <InfoBox
          type="warning"
          message="You must create a Virtual Power Plant before you can dispatch."
        />
      );
    }

    const endDateTime = this.props.startDateTime.clone();
    endDateTime.add(this.props.duration, "seconds");

    const outageReasons = [
      {
        value: "STORM",
        label: "Storm",
      },
      {
        value: "PLANNED",
        label: "Planned Outage",
      },
    ];

    return (
      <>
        <InfoBox>
          This will cause batteries to charge to full from the cheapest source available prior to
          the Grid Outage start time and remain fully charged until the end of the Grid Outage.
          <br />
          You may cancel the Grid Outage event at any time and individual customers may opt-out of
          this event via the Reposit app.
        </InfoBox>
        <div className="new-dispatch-info grid-outage">
          {this.props.allowPowerstationEdit ? (
            <div>
              A
              <SelectDropdown
                placeholder="grid outage"
                value={this.props.outageReason}
                onChange={val => this.props.onReasonChange(val)}
                options={outageReasons}
              />
              will impact
              <SelectDropdown
                placeholder="Virtual Power Plant"
                value={this.props.powerstation}
                onChange={val => this.props.onPowerstationChange(val)}
                options={this.props.powerstations.map(p => {
                  return { label: p.name, value: p.id };
                })}
              />
            </div>
          ) : null}
          {this.props.powerstation ? (
            <div>
              Prepare the battery on
              <DatePicker
                placeholder="start date"
                value={this.props.startDateTime.clone()}
                onChange={this.handleStartDateChange}
                minDate={moment()}
                dateFormat="d MMMM"
                asInline
              />
              at
              <TimePicker
                placeholder="start time"
                dateTime={this.props.startDateTime}
                onChange={this.handleStartTimeChange}
                timezone={this.props.timezone}
                minuteStep={5}
                asInline
                showNow
              />
              until
              <TimePicker
                placeholder="end time"
                dateTime={endDateTime}
                offsetFromDateTime={this.props.startDateTime}
                onChange={this.handleEndTimeChange}
                timezone={this.props.timezone}
                minuteStep={5}
                asInline
              />
            </div>
          ) : null}
          {this.props.powerstation ? (
            <div className="customer-msg">
              <div className="title">Customer message:</div>
              {this.props.realPowerP !== 0 ? (
                <div
                  className="edit-container link"
                  onClick={this.props.showCustomerAppMessageEdit}
                >
                  <span className="customer-msg-summary">
                    {this.props.customerAppMessage ? (
                      this.props.customerAppMessage
                    ) : (
                      <span className="lockout-message">Default message only</span>
                    )}
                  </span>
                  <span className="fa fa-chevron-right edit-icon" />
                </div>
              ) : (
                <div className="lockout-message">Messages are not sent for lockouts.</div>
              )}
            </div>
          ) : null}
        </div>
      </>
    );
  }
}

export default NewGridOutage;

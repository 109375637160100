import { Notify } from "../../utils/notificationManager";
import { history } from "../../containers/Root";

export const FETCH_CURTAILMENTS = "FETCH_CURTAILMENTS";
export const UPDATE_CURTAILMENTS = "UPDATE_CURTAILMENTS";
export const FETCH_FIM_POWERSTATIONS = "FETCH_FIM_POWERSTATIONS";
export const UPDATE_FIM_POWERSTATIONS = "UPDATE_FIM_POWERSTATIONS";
export const FETCH_ACTIVE_CURTAILMENT = "FETCH_ACTIVE_CURTAILMENT";
export const UPDATE_ACTIVE_CURTAILMENT = "UPDATE_ACTIVE_CURTAILMENT";
export const CLEAR_ACTIVE_CURTAILMENT = "CLEAR_ACTIVE_CURTAILMENT";
export const UPDATE_CURTAILMENT_RESPONSE = "UPDATE_CURTAILMENT_RESPONSE";
export const FETCH_CURTAILMENT_RESPONSE = "FETCH_CURTAILMENT_RESPONSE";
export const UPDATE_CURTAILMENT_PREDICTION = "UPDATE_CURTAILMENT_PREDICTION";
export const FETCH_CURTAILMENT_PREDICTION = "FETCH_CURTAILMENT_PREDICTION";
export const FETCH_NEW_CURTAILMENT_CONSTRAINTS = "FETCH_NEW_CURTAILMENT_CONSTRAINTS";
export const UPDATE_NEW_CURTAILMENT_CONSTRAINTS = "UPDATE_NEW_CURTAILMENT_CONSTRAINTS";

export const FETCH_NEW_CURTAILMENT_PREDICTIONS = "FETCH_NEW_CURTAILMENT_PREDICTIONS";
export const UPDATE_NEW_CURTAILMENT_PREDICTIONS = "UPDATE_NEW_CURTAILMENT_PREDICTIONS";
export const FAILED_NEW_CURTAILMENT_PREDICTIONS = "FAILED_NEW_CURTAILMENT_PREDICTIONS";

export const CLEAR_NEW_CURTAILMENT_GRAPH_DATA = "CLEAR_NEW_CURTAILMENT_GRAPH_DATA";
export const EXECUTE_CURTAILMENT = "EXECUTE_CURTAILMENT";
export const UPDATE_EXECUTE_CURTAILMENT = "UPDATE_EXECUTE_CURTAILMENT";
export const CURTAILMENT_FINISHED_UPDATING = "CURTAILMENT_FINISHED_UPDATING";

export const FETCH_SETPOINTS = "FETCH_SETPOINTS";
export const UPDATE_SETPOINTS = "UPDATE_SETPOINTS";

export const FETCH_CURTAILMENT_DEPLOYMENTS = "FETCH_CURTAILMENT_DEPLOYMENTS";
export const UPDATE_CURTAILMENT_DEPLOYMENTS = "UPDATE_CURTAILMENT_DEPLOYMENTS";
export const FAILED_CURTAILMENT_DEPLOYMENTS = "FAILED_CURTAILMENT_DEPLOYMENTS";
export const CLEAR_CURTAILMENT_DEPLOYMENTS = "CLEAR_CURTAILMENT_DEPLOYMENTS";

export const CREATE_SETPOINT = "CREATE_SETPOINT";
export const UPDATE_CREATE_SETPOINT = "UPDATE_CREATE_SETPOINT";

export const FETCH_VPP_SETPOINTS = "FETCH_VPP_SETPOINTS";
export const UPDATE_VPP_SETPOINTS = "UPDATE_VPP_SETPOINTS";
export const CLEAR_VPP_SETPOINTS = "CLEAR_VPP_SETPOINTS";

export const CANCEL_CURTAILMENT = "CANCEL_CURTAILMENT";
export const UPDATE_CANCEL_CURTAILMENT = "UPDATE_CANCEL_CURTAILMENT";

export function fetchCurtailments(limit, offset) {
  return {
    type: FETCH_CURTAILMENTS,
    meta: {
      endpoint: `/api/curtailments?limit=${limit}&offset=${offset}`,
      success: updateCurtailments,
      errorMessage: "Failed to load curtailments.",
    },
  };
}

function updateCurtailments(response) {
  return {
    type: UPDATE_CURTAILMENTS,
    payload: {
      curtailments: response.data,
      curtailmentsPageMeta: response.meta,
    },
  };
}

export function fetchPowerstations() {
  return {
    type: FETCH_FIM_POWERSTATIONS,
    meta: {
      endpoint: "/api/powerstations",
      success: updatePowerstations,
      errorMessage: "Failed to load Virtual Power Plant.",
    },
  };
}

function updatePowerstations(powerstations) {
  return {
    type: UPDATE_FIM_POWERSTATIONS,
    payload: {
      powerstations: powerstations.data,
    },
  };
}

export function fetchActiveCurtailment(curtailmentId) {
  return {
    type: FETCH_ACTIVE_CURTAILMENT,
    meta: {
      endpoint: "/api/curtailments/" + curtailmentId,
      success: updateActiveCurtailment,
      errorMessage: "Failed to load curtailment.",
    },
  };
}

function updateActiveCurtailment(response) {
  return {
    type: UPDATE_ACTIVE_CURTAILMENT,
    payload: {
      curtailment: response.data,
    },
  };
}

export function clearActiveCurtailment() {
  return {
    type: CLEAR_ACTIVE_CURTAILMENT,
  };
}

export function fetchSetpoints(limit, offset) {
  return {
    type: FETCH_SETPOINTS,
    meta: {
      endpoint: `/api/curtailments/setpoints?limit=${limit}&offset=${offset}`,
      success: updateSetpoints,
      errorMessage: "Failed to fetch curtailment setpoint changes.",
    },
  };
}

function updateSetpoints(response) {
  return {
    type: UPDATE_SETPOINTS,
    payload: {
      setpoints: response.data,
      setpointsPageMeta: response.meta,
    },
  };
}

export function fetchVPPSetpoints(powerstationId, limit, offset) {
  return {
    type: FETCH_VPP_SETPOINTS,
    meta: {
      endpoint: `/api/curtailments/setpoints/powerstation/${powerstationId}?offset=${offset}&limit=${limit}`,
      success: updateVPPSetpoints,
      errorMessage: "Failed to fetch curtailment setpoints.",
    },
  };
}

function updateVPPSetpoints(response) {
  return {
    type: UPDATE_VPP_SETPOINTS,
    payload: {
      setpoints: response.data,
      vppSetpointsPageMeta: response.meta,
    },
  };
}

export function fetchCurtailmentResponse(curtailmentId) {
  return {
    type: FETCH_CURTAILMENT_RESPONSE,
    meta: {
      endpoint: "/api/curtailments/" + curtailmentId + "/response",
      success: updateCurtailmentResponse,
      errorMessage: "Failed to load curtailment response.",
    },
  };
}

function updateCurtailmentResponse(response) {
  return {
    type: UPDATE_CURTAILMENT_RESPONSE,
    payload: {
      response: response.data,
    },
  };
}

export function fetchCurtailmentPrediction(curtailmentId) {
  return {
    type: FETCH_CURTAILMENT_PREDICTION,
    meta: {
      endpoint: "/api/curtailments/" + curtailmentId + "/prediction",
      success: updateCurtailmentPrediction,
      errorMessage: "Failed to load curtailment prediction.",
    },
  };
}

function updateCurtailmentPrediction(response) {
  return {
    type: UPDATE_CURTAILMENT_PREDICTION,
    payload: {
      prediction: response.data,
    },
  };
}

export function clearNewCurtailmentGraphData() {
  return {
    type: CLEAR_NEW_CURTAILMENT_GRAPH_DATA,
  };
}

export function fetchNewCurtailmentConstraints(stationId) {
  return {
    type: FETCH_NEW_CURTAILMENT_CONSTRAINTS,
    meta: {
      endpoint: "/api/constraints/curtailments?powerstation=" + stationId,
      success: updateNewCurtailmentConstraints,
      errorMessage: "Failed to load curtailment constraints.",
    },
  };
}

export function fetchNewCurtailmentPredictions(stationId, duration, startTime) {
  return {
    type: FETCH_NEW_CURTAILMENT_PREDICTIONS,
    meta: {
      endpoint: `/curtailment/prediction?powerstation=${stationId}&startTime=${startTime}&duration=${duration}`,
      success: updateNewCurtailmentPredictions,
      error: failedNewCurtailmentPredictions,
      errorMessage: "Failed to load curtailment predictions.",
    },
  };
}

function updateNewCurtailmentPredictions(predictions) {
  return {
    type: UPDATE_NEW_CURTAILMENT_PREDICTIONS,
    payload: {
      predictions: predictions.data.prediction,
    },
  };
}

function failedNewCurtailmentPredictions() {
  return {
    type: FAILED_NEW_CURTAILMENT_PREDICTIONS,
  };
}

function updateNewCurtailmentConstraints(constraints) {
  return {
    type: UPDATE_NEW_CURTAILMENT_CONSTRAINTS,
    payload: {
      realPowerP: constraints.data.realPowerP,
    },
  };
}

export function executeCurtailment(stationId, startTime, duration, real_power_p, component) {
  return {
    type: EXECUTE_CURTAILMENT,
    meta: {
      endpoint: "/api/curtailments",
      method: "POST",
      body: {
        powerstation: stationId,
        startTime: startTime,
        duration: duration,
        realPowerP: real_power_p,
        component: component,
      },
      success: updateExecuteCurtailment,
      error: finishedUpdatingCurtailment,
      errorMessage: "Failed to execute Curtailment.",
    },
  };
}

function updateExecuteCurtailment(response) {
  if (response) {
    if (response.status === "ERROR") {
      // deals with 400s (treated as a success by fetchMiddleware)
      const message = (response.data && response.data.message) || "";
      Notify.error("Failed to execute curtailment.", message);
    } else {
      Notify.success("Curtailment successfully executed");

      if (response.data && response.data.id) {
        history.push(`/virtual-power-plants/feed-in-management/${response.data.id}`);
      }
    }
  }
  return {
    type: UPDATE_EXECUTE_CURTAILMENT,
  };
}

function finishedUpdatingCurtailment() {
  return {
    type: CURTAILMENT_FINISHED_UPDATING,
  };
}

export function fetchCurtailmentDeployments(curtailmentId) {
  return {
    type: FETCH_CURTAILMENT_DEPLOYMENTS,
    meta: {
      endpoint: `/curtailment/${curtailmentId}/nodes`,
      success: updateCurtailmentDeployments,
      error: failedCurtailmentDeployments,
      errorMessage: "Failed to load curtailment nodes.",
    },
  };
}

function updateCurtailmentDeployments(deployments) {
  return {
    type: UPDATE_CURTAILMENT_DEPLOYMENTS,
    payload: {
      deployments: deployments.data,
    },
  };
}

function failedCurtailmentDeployments() {
  return {
    type: FAILED_CURTAILMENT_DEPLOYMENTS,
  };
}

export function clearCurtailmentDeployments() {
  return {
    type: CLEAR_CURTAILMENT_DEPLOYMENTS,
  };
}

export function createSetpoint(stationId, rampDuration, gridLimit, solarLimit) {
  return {
    type: CREATE_SETPOINT,
    meta: {
      endpoint: "/api/curtailments/setpoint",
      method: "POST",
      body: {
        powerstation: stationId,
        rampDuration: rampDuration,
        meterRealPowerP: parseInt(gridLimit, 10),
        inverterRealPowerP: parseInt(solarLimit, 10),
      },
      success: updateCreateSetpoint,
      errorMessage: "Failed to create new default setpoint.",
    },
  };
}

function updateCreateSetpoint(response) {
  if (response) {
    if (response.status === "ERROR") {
      // deals with 400s (treated as a success by fetchMiddleware)
      const message = (response.data && response.data.message) || "";
      Notify.error("Failed to create default setpoint.", message);
    } else {
      Notify.success("Setpoint successfully created");

      if (response.data && response.data.id) {
        history.push(`/virtual-power-plants/feed-in-management`);
      }
    }
  }
  return {
    type: UPDATE_CREATE_SETPOINT,
  };
}

export function clearVPPSetpoints() {
  return {
    type: CLEAR_VPP_SETPOINTS,
  };
}

export function cancelCurtailment(curtailmentId) {
  return {
    type: CANCEL_CURTAILMENT,
    meta: {
      endpoint: `/api/curtailments/${curtailmentId}/cancel`,
      method: "POST",
      success: updateCancelCurtailment,
      successMessage: "Curtailment successfully cancelled.",
      errorMessage: "Failed to cancel curtailment.",
    },
  };
}

function updateCancelCurtailment() {
  history.push("/virtual-power-plants/feed-in-management");
  return {
    type: UPDATE_CANCEL_CURTAILMENT,
  };
}

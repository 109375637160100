import React, { Component } from "react";
import NewCurtailment from "./NewCurtailment";
import "./NewCurtailmentExecuteConfirmation.css";

class NewCurtailmentExecuteConfirmation extends Component {
  render() {
    return (
      <div className="new-curtailment-confirmation">
        <NewCurtailment
          readOnly
          powerstations={this.props.powerstations}
          powerstation={this.props.powerstation}
          startDateTime={this.props.startDateTime}
          duration={this.props.duration}
          timezone={this.props.timezone}
          constraints={this.props.constraints}
          realPowerP={this.props.realPowerP}
          component={this.props.component}
          curtailableVPP={true}
        />
        <div className="confirm-message">Are you sure you want to execute this curtailment?</div>
      </div>
    );
  }
}

export default NewCurtailmentExecuteConfirmation;

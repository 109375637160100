import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Page from "../../../components/layout/Page";
import { Loading, Button } from "../../../components/widgets";
import * as userManagementActions from "../../../redux/actions/usermanagement";
import UsersListTable from "../../../components/myreposit/users/UsersListTable";
import Permissions from "../../../permissions";

const mapStateToProps = state => ({
  users: state.userManagement.users,
});

const mapDispatchToProps = dispatch => {
  return {
    userManagementActions: bindActionCreators(userManagementActions, dispatch),
  };
};

class UsersList extends Component {
  componentDidMount = () => {
    this.props.userManagementActions.fetchAllUsers();
  };

  render() {
    if (!this.props.users) {
      return <Loading />;
    }
    return (
      <Page permission={Permissions.fleet.myReposit.users}>
        <Page.Header title="Reposit Fleet Users">
          <Page.Header.Actions>
            <Button id="users-go-to-add" type="primary" to="/my-reposit/users/new" icon="plus">
              Add User
            </Button>
          </Page.Header.Actions>
        </Page.Header>
        <Page.Content>
          <UsersListTable users={this.props.users} history={this.props.history} />
        </Page.Content>
      </Page>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UsersList);

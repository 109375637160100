import React, { Component } from "react";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as fleetActions from "../../redux/actions/fleet";
import { clearTimezoneMismatch } from "../../redux/actions/user";
import FleetDeploymentsTable from "../../components/fleet/FleetDeploymentsTable";
import { Loading, Button, DeploymentMap, InfoBox } from "../../components/widgets";
import Page from "../../components/layout/Page";
import Permissions from "../../permissions";
import FleetDeploymentsSearch from "../../components/fleet/FleetDeploymentsSearch";
import PagingControl from "../../components/widgets/PagingControl";
import queryString from "query-string";

export const PAGE_SIZE = 10;

const mapStateToProps = state => ({
  deployments: state.fleet.deployments,
  count: state.fleet.deploymentCount,
  timezone: state.user.account.timezone,
  timezoneMismatch: state.user.timezoneMismatch,
  location: state.router.location,
  loading: state.fleet.loadingDeployments,
  loadingMap: state.fleet.loadingMap,
  deploymentMapData: state.fleet.deploymentMap,
});

const mapDispatchToProps = dispatch => {
  return {
    fleetActions: bindActionCreators(fleetActions, dispatch),
    clearTimezoneMismatch: bindActionCreators(clearTimezoneMismatch, dispatch),
  };
};

class FleetDeployments extends Component {
  constructor(props) {
    super(props);

    const search = queryString.parse(props.location.search);

    let limit = PAGE_SIZE;
    if (search.limit !== undefined) {
      limit = parseInt(search.limit, 10);
    }

    let offset = 0;
    if (search.offset !== undefined) {
      offset = parseInt(search.offset, 10);
    }

    let viewMode = "LIST";
    if (search.tab) {
      viewMode = search.tab;
    }

    this.state = {
      viewMode,
      limit,
      offset,
      filter: search.f,
    };
    this.filterDeployments = this.filterDeployments.bind(this);
    this.updateLimitPerPage = this.updateLimitPerPage.bind(this);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  loadFleetData = (limit, offset, filter, needToLoadMapData) => {
    this.props.fleetActions.setLoading(needToLoadMapData);
    if (this.refreshTimeout) {
      clearTimeout(this.refreshTimeout);
    }

    // We do a half second debounce here to catch quickly paging through pages and quickly toggling filters.
    this.refreshTimeout = setTimeout(() => {
      this.props.fleetActions.fetchFleetDeployments(limit, offset, filter);
      if (needToLoadMapData) {
        this.props.fleetActions.fetchMapDeployments(filter);
      }
    }, 500);
  };

  componentDidMount() {
    this.loadFleetData(this.state.limit, this.state.offset, this.state.filter, true);

    const { status } = queryString.parse(this.props.location.search);
    if (status) {
      this.props.fleetActions.updateFleetFilter("onlineStatus", status);
    }
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  getMapHeight() {
    const calcHeight = window.innerHeight - 220;
    const minMapHeight = 500;
    return calcHeight > minMapHeight ? calcHeight : minMapHeight;
  }

  updateWindowDimensions() {
    this.setState({
      map: {
        width: window.innerWidth,
        height: this.getMapHeight(),
      },
    });
  }

  componentWillReceiveProps = nextProps => {
    const nextSearch = queryString.parse(nextProps.location.search);
    const loadMapData = this.state.filter !== nextSearch.f;
    if (
      this.props.location.search &&
      nextProps.location.search &&
      this.props.location.search !== nextProps.location.search
    ) {
      this.loadFleetData(nextSearch.limit, nextSearch.offset, nextSearch.f, loadMapData);
      this.setState({
        limit: parseInt(nextSearch.limit, 10),
        offset: parseInt(nextSearch.offset, 10),
        filter: nextSearch.f,
        viewMode: nextSearch.tab,
      });
    }
  };

  getPage = (limit, offset, filter) => {
    this.updateUrl(filter, limit, offset, this.state.viewMode);
  };

  updateUrl = (filter, limit, offset, tab) => {
    let url = `${this.props.location.pathname}?tab=${tab}`;
    if (filter) {
      url += "&" + queryString.stringify({ f: filter, limit: limit, offset: offset });
    } else {
      url += `&limit=${limit}&offset=${offset}`;
    }
    this.props.history.push(url);
  };

  updateLimitPerPage = limit => {
    this.setState({ limit });
  };

  showMapView = () => {
    this.updateUrl(this.state.filter, this.state.limit, this.state.offset, "MAP");
  };

  showListView = () => {
    this.updateUrl(this.state.filter, this.state.limit, this.state.offset, "LIST");
  };

  registerMapContainer = mapContainer => {
    if (mapContainer) {
      this.setState({
        map: {
          width: mapContainer.clientWidth,
          height: this.getMapHeight(),
        },
      });
    }
  };

  filterDeployments = filterValue => {
    this.updateUrl(filterValue, this.state.limit, 0, this.state.viewMode);
  };

  handleMapMarkerClick = deployment => {
    this.props.history.push(`/fleet-monitoring/nodes/${deployment.id}`);
  };

  getUserSettingsLink = () => {
    return <Link to={"/user/settings"}>update this</Link>;
  };

  render() {
    if (this.props.deployments === null) {
      return <Loading />;
    }

    const deployments = this.props.deployments;
    return (
      <Page permission={Permissions.fleet.node.any}>
        <Page.Header title="Fleet Monitoring">
          <Page.Header.Actions>
            {this.state.viewMode === "LIST" ? (
              <Button
                id="toggle-to-fleet-map"
                type="secondary"
                icon="map-o"
                onClick={this.showMapView}
              >
                View Map
              </Button>
            ) : null}
            {this.state.viewMode === "MAP" ? (
              <Button
                id="toggle-to-fleet-list"
                type="secondary"
                icon="list"
                onClick={this.showListView}
              >
                View List
              </Button>
            ) : null}
          </Page.Header.Actions>
        </Page.Header>
        {this.props.timezoneMismatch && (
          <InfoBox type="warning" style={{ marginTop: "1em" }}>
            {`Your user configured timezone '${this.props.timezone}' differs from your browsers current timezone. You can `}
            {this.getUserSettingsLink()}
            {` in user settings. `}
            <span
              className="link"
              style={{ fontSize: "0.9em" }}
              onClick={this.props.clearTimezoneMismatch}
            >
              Dismiss
            </span>
          </InfoBox>
        )}
        <Page.SearchFields visible={true}>
          <FleetDeploymentsSearch
            onFilterFieldChange={this.filterDeployments}
            filterValue={this.state.filter}
          />
        </Page.SearchFields>
        <Page.Content>
          {this.state.viewMode === "LIST" ? (
            this.props.loading ? (
              <Loading />
            ) : (
              <>
                <FleetDeploymentsTable deployments={deployments} history={this.props.history} />
                <PagingControl
                  key="fleetMonitoringDeployments"
                  limit={this.state.limit}
                  offset={this.state.offset}
                  totalItems={this.props.count}
                  itemName="deployment"
                  onPageChange={(limit, offset) => this.getPage(limit, offset, this.state.filter)}
                  onChangeResultsPerPage={this.updateLimitPerPage}
                  orientation="up"
                />
              </>
            )
          ) : (
            <div
              style={{
                flexGrow: 1,
                bottom: "0",
                position: "relative",
                marginTop: "1em",
                boxSizing: "border-box",
              }}
              ref={this.registerMapContainer}
            >
              {this.state.map ? (
                this.props.loadingMap ? (
                  <Loading />
                ) : (
                  <DeploymentMap
                    width={this.state.map.width}
                    height={this.state.map.height}
                    deployments={this.props.deploymentMapData}
                    showCluster={false}
                    onMarkerClick={this.handleMapMarkerClick}
                  />
                )
              ) : null}
            </div>
          )}
        </Page.Content>
      </Page>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(FleetDeployments));

export let notificationManagerInstance = undefined;
class NotificationManager {
  constructor() {
    if (!notificationManagerInstance) {
      notificationManagerInstance = this;
    }

    return notificationManagerInstance;
  }

  setNotificationSystem = notificationSystem => {
    this.notificationSystem = notificationSystem;
  };

  addNotification = notification => {
    this.notificationSystem.current.addNotification(notification);
  };

  removeNotification = notification => {
    this.notificationSystem.current.removeNotification(notification);
  };
}

notificationManagerInstance = new NotificationManager();

export const notificationStyles = {
  Containers: {
    DefaultStyle: {
      width: null,
      zIndex: 999999,
    },
  },

  NotificationItem: {
    DefaultStyle: {
      padding: "1rem 2rem",
      width: "340px",
      border: 0,
      borderRadius: "3px",
      fontWeight: 300,
      opacity: "0.8",
    },

    success: {
      background: "linear-gradient(to right, #2ad17d 0%, #1c9e5d 100%)",
      color: "#FFFFFF",
    },

    info: {
      backgroundColor: "#4698CB",
      color: "#FFFFFF",
    },

    warning: {
      background: "linear-gradient(to right, #F58D25 0%, #DB7E21 100%)",
      color: "#FFFFFF",
    },

    error: {
      background: "linear-gradient(to right, #E0004D 0%, #AD003D 100%)",
      color: "#FFFFFF",
    },
  },

  Title: {
    DefaultStyle: {
      color: "#FFFFFF",
      fontSize: "14px",
      fontWeight: 400,
      margin: 0,
      maxWidth: "90%",
    },
  },

  Dismiss: {
    DefaultStyle: {
      top: "0",
      background: "none",
      color: "transparent",
      fontSize: "2em",
      borderLeft: "1px solid #FFFFFF",
      borderRadius: "0",
      width: "14px",
      height: "calc(100% - 10px)",
      boxSizing: "border-box",
      marginTop: "5px",
      paddingLeft: "0.8em",
      paddingRight: "1.2em",
    },

    success: {
      borderLeft: "1px solid #18854E",
    },

    info: {
      borderLeft: "1px solid #347299",
    },

    warning: {
      borderLeft: "1px solid #A86119",
    },

    error: {
      borderLeft: "1px solid #730028",
    },
  },
};

export class Notify {
  static success = (title, message) => {
    notificationManagerInstance.addNotification({
      title: title,
      message: message,
      level: "success",
      position: "br",
    });
  };

  static info = (title, message) => {
    notificationManagerInstance.addNotification({
      title: title,
      message: message,
      level: "info",
      position: "br",
    });
  };

  static warning = (title, message) => {
    notificationManagerInstance.addNotification({
      title: title,
      message: message,
      level: "warning",
      position: "br",
    });
  };

  static error = (title, message) => {
    notificationManagerInstance.addNotification({
      title: title,
      message: message,
      level: "error",
      position: "br",
    });
  };
}

import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { FormattedNumber } from "react-intl";

class InvoicesListTable extends Component {
  render() {
    const invoices = this.props.invoices;

    if (!invoices || invoices.length === 0) {
      return <div className="fleet-table-no-results">No invoices.</div>;
    }

    return (
      <table>
        <thead>
          <tr>
            <th>Date</th>
            <th>Amount</th>
            <th>Status</th>
            <th>Download</th>
          </tr>
        </thead>
        <tbody>
          {invoices.map(invoice => {
            return (
              <tr>
                <td>{invoice.date}</td>
                <td>
                  <FormattedNumber
                    style="currency" // eslint-disable-line
                    currency="AUD"
                    maximumFractionDigits={2}
                    value={invoice.total}
                  />
                </td>
                <td>{invoice.paid ? "Paid" : "Pending"}</td>
                <td>
                  <a className="invoice-download-link" href={invoice.downloadURL}>
                    {invoice.paid ? "Receipt" : "Invoice"}
                  </a>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  }
}

export default withRouter(InvoicesListTable);

import React, { Component } from "react";
import ReactDatePicker from "react-datepicker";
import Input from "./Input";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment-timezone";
import enGB from "date-fns/locale/en-GB";
import "./DatePicker.css";

const DatePickerInputIconOnly = props => (
  <div className="fleet-date-picker">
    <a {...props} className="fleet-date-picker-icon">
      <i className="fa fa-calendar" />
    </a>
  </div>
);

class YearPicker extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showYearPicker: false,
    };
  }

  showYearPicker = () => {
    this.setState({
      showYearPicker: true,
    });
  };

  hideYearPicker = () => {
    this.setState({
      showYearPicker: false,
    });
  };

  onChangeYear = date => {
    this.props.onChange(date);
    this.hideYearPicker();
  };

  getYears = () => {
    const startYear = this.props.minDate.year();
    const endYear = this.props.maxDate.year();

    let years = [];
    for (let i = startYear; i <= endYear; i++) {
      years.push(i);
    }

    return years;
  };

  render() {
    return (
      <span style={{ position: "relative" }}>
        <div className="react-datepicker-wrapper">
          <div className="react-datepicker__input-container">
            <DatePickerInputIconOnly onClick={this.showYearPicker} />
          </div>
        </div>
        {this.state.showYearPicker && (
          <div
            className="react-datepicker-popper"
            data-placement="bottom-start"
            style={{
              position: "absolute",
              willChange: "transform",
              top: "0px",
              left: "0px",
              transform: "translate3d(0, 35px, 0)",
            }}
          >
            <div className="react-datepicker fleet-date-picker undefined">
              <div className="react-datepicker__triangle" />
              <div className="react-datepicker__month-container">
                <div
                  className="react-datepicker__month react-datepicker__monthPicker"
                  role="listbox"
                >
                  {this.getYears().map(year => {
                    let classes = "react-datepicker__month-text react-datepicker__month-0";
                    if (year === this.props.value.year()) {
                      classes += " react-datepicker__month--selected";
                    }
                    return (
                      <div
                        className="react-datepicker__month-wrapper"
                        onClick={() => this.onChangeYear(moment(year, "YYYY"))}
                      >
                        <div className={classes}>{year}</div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        )}
      </span>
    );
  }
}

class DatePickerInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      focused: false,
    };
  }

  onFocus = () => {
    this.setState({
      focused: true,
    });
  };

  onBlur = () => {
    this.setState({
      focused: false,
    });
  };

  render() {
    return (
      <span>
        <div className={"fleet-date-picker with-input" + (this.state.focused ? " focus" : "")}>
          <Input
            value={this.props.value}
            onFocus={this.onFocus}
            onBlur={this.onBlur}
            placeholder={this.props.placeholder}
            datepicker={true}
            error={!!this.props.error}
          />
          <a
            {...this.props}
            className={"fleet-date-picker-icon" + (this.props.error ? " error" : "")}
          >
            <i className="fa fa-calendar" />
          </a>
        </div>
        {this.props.error ? <span className="error-message">{this.props.error}</span> : null}
      </span>
    );
  }
}

class DatePickerInlineInput extends Component {
  render() {
    return (
      <div {...this.props} className="fleet-date-picker inline">
        {this.props.value ? this.props.value : this.props.placeholder}
        <i className="fa fa-angle-down" />
      </div>
    );
  }
}

class DatePickerReadOnly extends Component {
  render() {
    return <div className="fleet-date-picker ro">{this.props.value}</div>;
  }
}

class DatePicker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: props.error,
    };
  }

  componentWillReceiveProps = nextProps => {
    // Remove the existing error if we receive a new value but the error has not yet changed.
    // This probably indicates that the user is correcting the error and in most cases they will
    // do this successfully - we can wait until new validation to re-display the error if they
    // still haven't fixed it.
    if (this.props.value !== nextProps.value && this.props.error === nextProps.error) {
      this.setState({
        error: null,
      });
    }

    if (this.props.error !== nextProps.error) {
      this.setState({
        error: nextProps.error,
      });
    }
  };

  onChange = date => {
    this.props.onChange(moment(date));
  };

  render() {
    return (
      <span>
        {!this.props.showYearPicker ? (
          <ReactDatePicker
            calendarClassName={"fleet-date-picker " + this.props.className}
            customInput={
              this.props.readOnly ? (
                <DatePickerReadOnly />
              ) : this.props.asInline ? (
                <DatePickerInlineInput />
              ) : this.props.withInput ? (
                <DatePickerInput error={this.state.error} />
              ) : (
                <DatePickerInputIconOnly />
              )
            }
            selected={this.props.value ? this.props.value.toDate() : null}
            onChange={this.onChange}
            minDate={this.props.minDate ? this.props.minDate.toDate() : null}
            maxDate={this.props.maxDate ? this.props.maxDate.toDate() : null}
            dateFormat={this.props.dateFormat ? this.props.dateFormat : "dd/MM/yyyy"}
            todayButton={this.props.today === false ? null : "Today"}
            placeholderText={this.props.placeholder}
            showMonthYearPicker={this.props.showMonthYearPicker}
            filterDate={this.props.filterDate}
            locale={enGB}
          />
        ) : (
          <YearPicker
            onChange={this.onChange}
            minDate={this.props.minDate}
            maxDate={this.props.maxDate}
            value={this.props.value}
          />
        )}
      </span>
    );
  }
}

export default DatePicker;

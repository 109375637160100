import moment from "moment-timezone";

export const FETCH_DASHBOARD_DEPLOYMENTS = "FETCH_DASHBOARD_DEPLOYMENTS";
export const UPDATE_DASHBOARD_DEPLOYMENTS = "UPDATE_DASHBOARD_DEPLOYMENTS";
export const FETCH_DASHBOARD_DEPLOYMENT_COUNTS = "FETCH_DASHBOARD_DEPLOYMENT_COUNTS";
export const UPDATE_DASHBOARD_DEPLOYMENT_COUNTS = "UPDATE_DASHBOARD_DEPLOYMENT_COUNTS";
export const FETCH_DASHBOARD_POWERSTATIONS = "FETCH_DASHBOARD_POWERSTATIONS";
export const UPDATE_DASHBOARD_POWERSTATIONS = "UPDATE_DASHBOARD_POWERSTATIONS";
export const FETCH_DASHBOARD = "FETCH_DASHBOARD";
export const UPDATE_DASHBOARD = "UPDATE_DASHBOARD";
export const FETCH_TICKER_EVENTS = "FETCH_TICKER_EVENTS";
export const UPDATE_TICKER_EVENTS = "UPDATE_TICKER_EVENTS";
export const SAVE_DASHBOARD = "SAVE_DASHBOARD";
export const UPDATE_SAVE_DASHBOARD = "UPDATE_SAVE_DASHBOARD";
export const FETCH_LATEST_POWERSTATION_VOLTAGE = "FETCH_LATEST_POWERSTATION_VOLTAGE";
export const UPDATE_LATEST_POWERSTATION_VOLTAGE = "UPDATE_LATEST_POWERSTATION_VOLTAGE";
export const FETCH_POWERSTATION_SOLAR_POWER = "FETCH_POWERSTATION_SOLAR_POWER";
export const UPDATE_POWERSTATION_SOLAR_POWER = "UPDATE_POWERSTATION_SOLAR_POWER";
export const FETCH_POWERSTATION_METER_POWER = "FETCH_POWERSTATION_METER_POWER";
export const UPDATE_POWERSTATION_METER_POWER = "UPDATE_POWERSTATION_METER_POWER";
export const FETCH_POWERSTATION_REMAINING_CHARGE = "FETCH_POWERSTATION_REMAINING_CHARGE";
export const UPDATE_POWERSTATION_REMAINING_CHARGE = "UPDATE_POWERSTATION_REMAINING_CHARGE";

export function fetchDashboard() {
  return {
    type: FETCH_DASHBOARD,
    meta: {
      endpoint: "/account/dashboard",
      method: "GET",
      success: updateDashboard,
    },
  };
}

export function updateDashboard(dashboardResp) {
  return {
    type: UPDATE_DASHBOARD,
    payload: {
      dashboard: dashboardResp.data,
    },
  };
}

export function saveDashboard(dashboard) {
  return {
    type: SAVE_DASHBOARD,
    meta: {
      endpoint: "/account/dashboard",
      method: "POST",
      body: {
        ...dashboard,
      },
      errorMessage: "Failed to save Dashboard",
      success: updateSaveDashboard,
    },
    payload: {
      dashboard: dashboard,
    },
  };
}

function updateSaveDashboard() {
  return {
    type: UPDATE_SAVE_DASHBOARD,
  };
}

export function fetchDeployments() {
  return {
    type: FETCH_DASHBOARD_DEPLOYMENTS,
    meta: {
      endpoint: "/dashboard/map",
      success: updateDeployments,
      errorMessage: "Failed to load dashboard nodes.",
    },
  };
}

function updateDeployments(response) {
  return {
    type: UPDATE_DASHBOARD_DEPLOYMENTS,
    payload: {
      deployments: response.deployments,
    },
  };
}

export function fetchDeploymentCounts() {
  return {
    type: FETCH_DASHBOARD_DEPLOYMENT_COUNTS,
    meta: {
      endpoint: "/dashboard/totals",
      success: updateDeploymentCounts,
      errorMessage: "Failed to load dashboard counts.",
    },
  };
}

function updateDeploymentCounts(response) {
  return {
    type: UPDATE_DASHBOARD_DEPLOYMENT_COUNTS,
    payload: {
      deploymentCounts: response.data.deploymentCounts,
    },
  };
}

export function fetchPowerstations(activeStationId) {
  // Setting the limit arbitrarily large to ensure we always have every
  // powerstation in state. Ideally we would have the redux middleware
  // loop over each page in turn, but the route in question doesn't
  // return paging information, just a total count of powerstations.
  // We really need to have the route return a "next" URL that is requested
  // until all pages are consumed.
  return {
    type: FETCH_DASHBOARD_POWERSTATIONS,
    meta: {
      endpoint: "/v2/powerstations?limit=10000",
      success: updatePowerstations,
      errorMessage: "Failed to load Virtual Power Plant.",
    },
  };
}

function updatePowerstations(response) {
  return {
    type: UPDATE_DASHBOARD_POWERSTATIONS,
    payload: {
      powerstations: response.data.result,
    },
  };
}

export function fetchLatestPowerstationVoltage(powerstationUid) {
  return {
    type: FETCH_LATEST_POWERSTATION_VOLTAGE,
    meta: {
      endpoint: `/api/powerstations/${powerstationUid}/data/latest?metrics=meterVoltage&format=nodes&maxAge=1200`,
      method: "GET",
      success: updateLatestPowerstationVoltage,
    },
    payload: {
      powerstationUid: powerstationUid,
    },
  };
}

function updateLatestPowerstationVoltage(dataResp, payload) {
  return {
    type: UPDATE_LATEST_POWERSTATION_VOLTAGE,
    payload: {
      voltage: dataResp.data.meterVoltage,
      powerstationUid: payload.powerstationUid,
    },
  };
}

export function fetchPowerstationSolarPower(powerstationUid, timezone) {
  const now = moment().tz(timezone).hours(0).minutes(0).seconds(0).unix();

  let endpoint;
  if (powerstationUid === "__ALL_FLEET_NODES__") {
    endpoint = `/api/data`;
  } else {
    endpoint = `/api/powerstations/${powerstationUid}/data`;
  }

  return {
    type: FETCH_POWERSTATION_SOLAR_POWER,
    meta: {
      endpoint: `${endpoint}?metrics=solarPower&interval=300&start=${now}`,
      method: "GET",
      success: updatePowerstationSolarPower,
    },
    payload: {
      powerstationUid: powerstationUid,
    },
  };
}

function updatePowerstationSolarPower(dataResp, payload) {
  return {
    type: UPDATE_POWERSTATION_SOLAR_POWER,
    payload: {
      solarPower: dataResp.data.solarPower,
      powerstationUid: payload.powerstationUid,
    },
  };
}

export function fetchPowerstationMeterPower(powerstationUid, timezone) {
  const now = moment().tz(timezone).hours(0).minutes(0).seconds(0).unix();

  let endpoint;
  if (powerstationUid === "__ALL_FLEET_NODES__") {
    endpoint = `/api/data`;
  } else {
    endpoint = `/api/powerstations/${powerstationUid}/data`;
  }

  return {
    type: FETCH_POWERSTATION_METER_POWER,
    meta: {
      endpoint: `${endpoint}?metrics=meterPower&interval=300&start=${now}`,
      method: "GET",
      success: updatePowerstationMeterPower,
    },
    payload: {
      powerstationUid: powerstationUid,
    },
  };
}

function updatePowerstationMeterPower(dataResp, payload) {
  return {
    type: UPDATE_POWERSTATION_METER_POWER,
    payload: {
      meterPower: dataResp.data.meterPower,
      powerstationUid: payload.powerstationUid,
    },
  };
}

export function fetchPowerstationRemainingCharge(powerstationUid, timezone) {
  const now = moment().tz(timezone).hours(0).minutes(0).seconds(0).unix();

  let endpoint;
  if (powerstationUid === "__ALL_FLEET_NODES__") {
    endpoint = `/api/data`;
  } else {
    endpoint = `/api/powerstations/${powerstationUid}/data`;
  }

  return {
    type: FETCH_POWERSTATION_REMAINING_CHARGE,
    meta: {
      endpoint: `${endpoint}?metrics=remainingCharge&interval=300&start=${now}`,
      method: "GET",
      success: updatePowerstationRemainingCharge,
    },
    payload: {
      powerstationUid: powerstationUid,
    },
  };
}

function updatePowerstationRemainingCharge(dataResp, payload) {
  return {
    type: UPDATE_POWERSTATION_REMAINING_CHARGE,
    payload: {
      remainingCharge: dataResp.data.remainingCharge,
      powerstationUid: payload.powerstationUid,
    },
  };
}

export function fetchTickerEvents() {
  return {
    type: FETCH_TICKER_EVENTS,
    meta: {
      endpoint: "/ticker",
      success: updateTickerEvents,
      errorMessage: "Failed to load ticker events.",
    },
  };
}

function updateTickerEvents(response) {
  return {
    type: UPDATE_TICKER_EVENTS,
    payload: {
      tickerEvents: response.data,
    },
  };
}

export const FETCH_KIT_OPTIONS = "FETCH_KIT_OPTIONS";
export const UPDATE_KIT_OPTIONS = "UPDATE_KIT_OPTIONS";
export const SUBMIT_KIT_DESIGN = "SUBMIT_KIT_DESIGN";
export const UPDATE_KIT_DESIGN_ERRORS = "UPDATE_KIT_DESIGN_ERRORS";
export const UPDATE_KIT_ORDER = "UPDATE_KIT_ORDER";
export const EMAIL_KIT = "EMAIL_KIT";
export const UPDATE_KIT_EMAILED = "UPDATE_KIT_EMAILED";
export const KIT_FINISHED_LOADING = "KIT_FINISHED_LOADING";

export const BOOTSTRAP_KIT_DESIGN = "BOOTSTRAP_KIT_DESIGN";
export const RESET_KIT_BUILDER = "RESET_KIT_BUILDER";
export const UPDATE_GRID_PHASES = "UPDATE_GRID_PHASES";
export const UPDATE_NUM_INVERTER_SYSTEMS = "UPDATE_NUM_INVERTER_SYSTEMS";
export const ADD_INVERTER_SYSTEM = "ADD_INVERTER_SYSTEM";
export const UPDATE_INVERTER_SYSTEM = "UPDATE_INVERTER_SYSTEM";
export const REMOVE_INVERTER_SYSTEM = "REMOVE_INVERTER_SYSTEM";

export function fetchKitOptions(inverterType, batteryType) {
  let params = [];

  if (inverterType) {
    params.push(`inverterTypeId=${inverterType}`);
  }

  if (batteryType) {
    params.push(`batteryTypeId=${inverterType}`);
  }

  return {
    type: FETCH_KIT_OPTIONS,
    meta: {
      endpoint: `/ordering/kit-options${params ? "?" + params.join("&") : ""}`,
      success: updateKitOptions,
      error: () => fetchKitOptions(inverterType, batteryType),
      errorMessage: "Failed to load kit options.",
    },
  };
}

export function updateKitOptions(response) {
  return {
    type: UPDATE_KIT_OPTIONS,
    payload: response.data,
  };
}

export function submitKitDesign(serializedDesign) {
  return {
    type: SUBMIT_KIT_DESIGN,
    meta: {
      endpoint: "/ordering/design",
      method: "POST",
      errorMessage: "Failed to validate kit design.",
      body: serializedDesign,
      success: updateKitDesignSubmitted,
      error: kitFinishedLoading,
    },
  };
}

export function updateKitDesignSubmitted(response) {
  const { order, errors } = response.data;

  if (order) {
    return updateKitOrder(order);
  } else {
    return updateKitDesignErrors(errors);
  }
}

export function updateKitDesignErrors(errors) {
  return {
    type: UPDATE_KIT_DESIGN_ERRORS,
    payload: {
      errors: errors,
    },
  };
}

export function updateKitOrder(order) {
  return {
    type: UPDATE_KIT_ORDER,
    payload: {
      order: order,
    },
  };
}

export function emailKit(json) {
  return {
    type: EMAIL_KIT,
    meta: {
      endpoint: "/ordering/email",
      method: "POST",
      success: updateKitEmailed,
      error: kitFinishedLoading,
      errorMessage: "Failed to email kit order.", // TODO show server error to user
      body: json,
    },
  };
}

export function updateKitEmailed(response) {
  return {
    type: UPDATE_KIT_EMAILED,
  };
}

export function kitFinishedLoading() {
  return {
    type: KIT_FINISHED_LOADING,
  };
}

export function bootstrapKitDesign() {
  return {
    type: BOOTSTRAP_KIT_DESIGN,
  };
}

export function resetKitBuilder() {
  return {
    type: RESET_KIT_BUILDER,
  };
}

export function updateGridPhases(gridPhases) {
  return {
    type: UPDATE_GRID_PHASES,
    payload: {
      gridPhases: gridPhases,
    },
  };
}

export function updateNumInverterSystems(numSystems) {
  return {
    type: UPDATE_NUM_INVERTER_SYSTEMS,
    payload: {
      numSystems: numSystems,
    },
  };
}

export function addInverterSystem() {
  return {
    type: ADD_INVERTER_SYSTEM,
  };
}

export function updateInverterSystem(uid, updates) {
  return {
    type: UPDATE_INVERTER_SYSTEM,
    payload: {
      uid: uid,
      updates: updates,
    },
  };
}

export function removeInverterSystem(uid) {
  return {
    type: REMOVE_INVERTER_SYSTEM,
    payload: {
      uid: uid,
    },
  };
}

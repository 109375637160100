import React, { Component } from "react";
import "./InverterSystemForm.css";
import SelectDropdown from "../widgets/SelectDropdown";
import { SOLAR_PHASE_OPTIONS } from "../../containers/ordering/KitConstants";

const SOLAR_CONNECTION_OPTIONS = [
  {
    value: true,
    label: "Connected",
  },
  {
    value: false,
    label: "Not Connected",
  },
];

function compareOrdering(a, b) {
  const aName = a[1].displayName.toLowerCase();
  const bName = b[1].displayName.toLowerCase();
  if (aName < bName || a[0] === "00") return -1;
  if (aName > bName || b[0] === "00") return 1;
  return 0;
}

function mapTypesToDropdownOptions(types) {
  return Object.entries(types)
    .sort((a, b) => compareOrdering(a, b))
    .map(entry => {
      const [uid, { displayName }] = entry;
      return {
        value: uid,
        label: displayName,
      };
    });
}

class InverterSystemForm extends Component {
  handleInverterChange = inv => {
    this.props.onInverterChange(inv);
  };

  handlePvChanged = pv => {
    this.props.onPvChanged(pv);
  };

  handleSolarPhaseChange = phases => {
    this.props.onPvPhasesChanged(phases);
  };

  handleBatteryChange = battery => {
    this.props.onBatteryChange(battery);
  };

  render() {
    const { loading, index, inverterSystem, inverterTypes, batteryTypes } = this.props;

    const { inverter, pvConnected, battery, pvPhases } = inverterSystem;

    let pvControl = null;
    let batteryControl = null;

    if (inverter.value !== null) {
      const inverterRules = inverterTypes[inverter.value];

      if (inverterRules.compatibleBatteryTypes.length !== 0) {
        const battIncomatible =
          battery.value && !inverterRules.compatibleBatteryTypes.includes(battery.value);

        let batteryOptions = mapTypesToDropdownOptions(batteryTypes).filter(option =>
          inverterRules.compatibleBatteryTypes.includes(option.value)
        );

        if (!inverterRules.allInOne) {
          batteryOptions.push({
            value: null,
            label: "Not Connected",
          });
        }

        batteryControl = (
          <div className="battery-sentence">
            Battery is&nbsp;
            <SelectDropdown
              placeholder={battIncomatible ? batteryTypes[battery.value].displayName : null}
              value={battery.value}
              options={batteryOptions}
              onChange={this.handleBatteryChange}
              error={battery.error || battIncomatible} // TODO add tooltip error explanation
              tooltipContent={battery.error || battIncomatible}
              tooltipIcon="exclamation-circle"
              readOnly={loading}
            />
          </div>
        );
      } else {
        batteryControl = (
          <div className="battery-sentence">
            Battery is not present (batteries cannot be connected to this inverter)
          </div>
        );
      }

      if (inverterRules.pvCompatible) {
        pvControl = (
          <div className="solar-sentence">
            Solar is&nbsp;
            <SelectDropdown
              readOnly={loading}
              value={pvConnected.value}
              onChange={this.handlePvChanged}
              options={SOLAR_CONNECTION_OPTIONS}
              error={pvConnected.error}
              tooltipContent={pvConnected.error}
              tooltipIcon="exclamation-circle"
            />
          </div>
        );
      } else if (inverterRules.pvCompatible === null) {
        // in this case we need to meter the solar ourselves, so need to know the number of phases
        pvControl = (
          <div className="solar-sentence">
            Solar is&nbsp;
            <SelectDropdown
              readOnly={loading}
              placeholder="&hellip;"
              value={pvPhases.value}
              options={SOLAR_PHASE_OPTIONS}
              onChange={this.handleSolarPhaseChange}
              error={pvPhases.error}
              tooltipContent={pvPhases.error}
              tooltipIcon="exclamation-circle"
            />
          </div>
        );
      } else {
        pvControl = (
          <div className="solar-sentence">
            Solar is not present (solar cannot be connected to this inverter)
          </div>
        );
      }
    }

    return (
      <div className="inverter-system-container">
        <h4>Inverter Set {index}</h4>
        <div className="inverter-sentence">
          The inverter will be a
          {inverter.value &&
          ["a", "e", "i", "o", "u"].includes(
            inverterTypes[inverter.value].displayName[0].toLowerCase()
          )
            ? "n"
            : ""}
          &nbsp;
          <SelectDropdown
            readOnly={loading}
            className="inverter-selector"
            placeholder="&hellip;"
            value={inverter.value}
            options={mapTypesToDropdownOptions(inverterTypes)}
            onChange={this.handleInverterChange}
            error={inverter.error}
            tooltipContent={inverter.error}
            tooltipIcon="exclamation-circle"
          />
        </div>
        {pvControl}
        {batteryControl}
      </div>
    );
  }
}

export default InverterSystemForm;

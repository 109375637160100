import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as userActions from "../redux/actions/user";
import LoginBackground1 from "./login_bg_1_1920x1080.jpg";
import LoginBackground2 from "./login_bg_2_1920x1080.jpg";
import LoginBackground3 from "./login_bg_3_1920x1080.jpg";
import LoginBackground4 from "./login_bg_4_1920x1080.jpg";
import LoginBackground6 from "./login_bg_6_1920x1080.jpg";
import LoginBackground7 from "./login_bg_7_1920x1080.jpg";
import LoginBackground8 from "./login_bg_8_1920x1080.jpg";
import LoginBackground9 from "./login_bg_9_1920x1080.jpg";
import LoginRepositLogo from "./login_reposit.svg";
import "./LoginLayout.css";

const BACKGROUND_IMAGES = [
  LoginBackground1,
  LoginBackground2,
  LoginBackground3,
  LoginBackground4,
  LoginBackground6,
  LoginBackground7,
  LoginBackground8,
  LoginBackground9,
];

const mapStateToProps = state => ({
  user: state.user,
  routing: state.routing,
});

const mapDispatchToProps = dispatch => {
  return {
    userActions: bindActionCreators(userActions, dispatch),
  };
};

class LoginLayout extends Component {
  componentWillMount = () => {
    const backgroundImage = BACKGROUND_IMAGES[Math.floor(Math.random() * BACKGROUND_IMAGES.length)];

    this.setState({
      backgroundImage: backgroundImage,
    });
  };

  componentDidMount = () => {
    this.props.userActions.refreshLogin();
  };

  render() {
    return (
      <div
        className="login-view"
        style={{
          background: `url(${this.state.backgroundImage}) no-repeat center center fixed`,
        }}
      >
        <div className="login-panel">
          <img src={LoginRepositLogo} alt="Reposit Power logo" />
          <div className="login-content">{this.props.children}</div>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginLayout);

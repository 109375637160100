import React from "react";

const Duration = ({ seconds, format }) => {
  let hours = Math.floor(seconds / 3600);
  let minutes = Math.floor((seconds - hours * 3600) / 60);

  let duration = "";
  if (format === "long" || !format) {
    const hoursText = hours === 1 ? "hour" : "hours";
    const minutesText = minutes === 1 ? "minute" : "minutes";

    if (hours && minutes) {
      duration = `${hours} ${hoursText} ${minutes} ${minutesText}`;
    } else if (hours) {
      duration = `${hours} ${hoursText}`;
    } else if (minutes) {
      duration = `${minutes} ${minutesText}`;
    }
  } else if (format === "short") {
    if (hours && minutes) {
      duration = `${hours}h ${minutes}m`;
    } else if (hours) {
      duration = `${hours}h`;
    } else if (minutes) {
      duration = `${minutes}m`;
    }
  }

  return <span>{duration}</span>;
};

export default Duration;

import * as actions from "../actions/invoices";

const invoicesInitialState = {
  invoices: null,
};

const invoicesReducer = (state = invoicesInitialState, action) => {
  switch (action.type) {
    case actions.UPDATE_INVOICES:
      return {
        ...state,
        invoices: action.payload.invoices,
      };
    default:
      return state;
  }
};

export default invoicesReducer;

import React, { Component } from "react";
import _ from "lodash";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as customerActions from "../../redux/actions/customers";
import Page from "../../components/layout/Page";
import { Loading, Button, InfoBox } from "../../components/widgets";
import CustomersListTable from "../../components/customers/CustomersListTable";
import CustomersListSearchBar from "../../components/customers/CustomersListSearchBar";
import { getCombinedAddress } from "../../utils/formatting";

const mapStateToProps = state => ({
  customers: state.customers,
  user: state.user,
});

const mapDispatchToProps = dispatch => {
  return {
    customerActions: bindActionCreators(customerActions, dispatch),
  };
};

class CustomersList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showSearch:
        !!props.customers.customersListFilter.searchTerm ||
        !!props.customers.customersListFilter.tariff ||
        !!props.customers.customersListFilter.additionalContracts ||
        !!props.customers.customersListFilter.agreementVersion,
      filterAgreementVersions: [],
      filterTariffs: [],
      filterContracts: [],
      showUploadModal: false,
    };
  }

  componentDidMount = () => {
    this.props.customerActions.fetchCustomersContracts();
    this.setFilterOptions(this.props);
  };

  componentWillReceiveProps = nextProps => {
    if (this.props.customers.customers !== nextProps.customers.customers) {
      this.setFilterOptions(nextProps);
    }
    if (
      nextProps.customers.requiresRefresh &&
      nextProps.customers.requiresRefresh !== this.props.customers.requiresRefresh
    ) {
      this.props.customerActions.fetchCustomersContracts();
    }
  };

  showSearch = () => {
    this.setState({
      showSearch: true,
    });
  };

  hideSearch = () => {
    this.props.customerActions.clearCustomerListFilter();
    this.setState({
      showSearch: false,
    });
  };

  getTariffOptions = () => {
    return this.state.filterTariffs.map(tariff => {
      return { value: tariff.name, label: tariff.name };
    });
  };

  getContractOptions = () => {
    return this.state.filterContracts.map(contract => {
      return { value: contract.contractName, label: contract.contractName };
    });
  };

  getCustomerAddress = customer => {
    const addr = _.pick(customer, ["streetNumber", "streetName", "city", "state", "postcode"]);
    return { ...addr, street: addr.streetName };
  };

  setFilterOptions = props => {
    if (props.customers.customers) {
      let agreementVersions = {};
      let uniqueAgreementVersions = [];
      let tariffs = {};
      let uniqueTariffs = [];
      let contracts = {};
      let uniqueContracts = [];
      for (let i in props.customers.customers) {
        const customer = props.customers.customers[i];
        const agreementVersion = customer.agreementVersion;
        if (agreementVersion && !agreementVersions[agreementVersion]) {
          agreementVersions[agreementVersion] = true;
          uniqueAgreementVersions.push(agreementVersion);
        }

        if (customer.tariff && !tariffs[customer.tariff.name]) {
          tariffs[customer.tariff.name] = true;
          uniqueTariffs.push(customer.tariff);
        }

        for (let j in customer.contracts) {
          const contract = customer.contracts[j];
          if (!contracts[contract.contractName]) {
            contracts[contract.contractName] = true;
            uniqueContracts.push(contract);
          }
        }
      }

      this.setState({
        filterAgreementVersions: uniqueAgreementVersions,
        filterTariffs: uniqueTariffs,
        filterContracts: uniqueContracts,
      });
    } else {
      this.setState({
        filterAgreementVersions: [],
        filterTariffs: [],
        filterContracts: [],
      });
    }
  };

  filterCustomers = (filter, customers) => {
    if (filter.tariff) {
      customers = customers.filter(c => c.tariff && c.tariff.name === filter.tariff);
    }

    if (filter.agreementVersion) {
      customers = customers.filter(c => c.agreementVersion === filter.agreementVersion);
    }

    if (filter.additionalContracts) {
      customers = customers.filter(c => {
        for (let i in c.contracts) {
          const contract = c.contracts[i];
          if (contract.contractName === filter.additionalContracts) {
            return true;
          }
        }
        return false;
      });
    }

    const searchTerm = filter.searchTerm;
    if (searchTerm && searchTerm.trim() !== "") {
      let searchLower = searchTerm.toLowerCase();
      customers = customers.filter(
        c =>
          c.nmi.toLowerCase().indexOf(searchLower) >= 0 ||
          `${getCombinedAddress(this.getCustomerAddress(c))}`.toLowerCase().indexOf(searchLower) >=
            0
      );
    }

    return customers.sort((a, b) => {
      if (a.contracts.length !== b.contracts.length) {
        if (a.contracts.length === 0) return -1;
        if (b.contracts.length === 0) return 1;
      }
      if (a.nmi < b.nmi) return -1;
      if (a.nmi > b.nmi) return 1;
      return 0;
    });
  };

  showUploadModal = () => {
    this.setState({
      showUploadModal: true,
    });
  };

  hideUploadModal = () => {
    this.setState({
      showUploadModal: false,
    });
  };

  getUnconfiguredCustomers = () => {
    const unconfiguredCustomers = this.props.customers.customers.filter(
      c => c.contracts.length === 0
    );
    let unconfiguredCapacity = 0;
    let unconfiguredPower = 0;
    if (unconfiguredCustomers) {
      for (let i in unconfiguredCustomers) {
        const customer = unconfiguredCustomers[i];
        unconfiguredCapacity += customer.capacity;
        unconfiguredPower += customer.maxPower;
      }
    }

    return {
      count: unconfiguredCustomers.length,
      capacity: unconfiguredCapacity.toFixed(2),
      power: unconfiguredPower.toFixed(2),
    };
  };

  render() {
    if (this.props.customers.customers === null) {
      return <Loading />;
    }
    const customers = this.filterCustomers(
      this.props.customers.customersListFilter,
      this.props.customers.customers
    );
    const unconfiguredCustomers = this.getUnconfiguredCustomers();

    return (
      <Page>
        <Page.Header title="Customers">
          <Page.Header.Actions>
            {this.state.showSearch ? (
              <Button
                id="clear-fleet-search"
                type="secondary"
                icon="times"
                onClick={this.hideSearch}
              >
                Clear Filter
              </Button>
            ) : (
              <Button
                id="enable-fleet-search"
                type="secondary"
                icon="search"
                onClick={this.showSearch}
              >
                Filter List
              </Button>
            )}
          </Page.Header.Actions>
        </Page.Header>
        <Page.SearchFields visible={this.state.showSearch}>
          <CustomersListSearchBar
            onFilterFieldChange={this.props.customerActions.updateCustomerListFilter}
            searchTerm={this.props.customers.customersListFilter.searchTerm}
            tariff={this.props.customers.customersListFilter.tariff}
            filterTariffs={this.getTariffOptions()}
            additionalContracts={this.props.customers.customersListFilter.additionalContracts}
            filterContracts={this.getContractOptions()}
            suborgType={this.props.user.account.suborg_type}
          />
        </Page.SearchFields>
        <Page.Content>
          {unconfiguredCustomers.count > 0 ? (
            <InfoBox type="warning" style={{ marginTop: "1em" }}>
              {unconfiguredCustomers.count === 1
                ? `There is 1 customer that has no capabilities assigned and is therefore not ready to be used in Fleet. This customer has ${unconfiguredCustomers.capacity} kWh of available capacity and ${unconfiguredCustomers.power} kW of power output. You will not be billed for this customer until capabilities are assigned and they have been commissioned. This customer is identified in the table below.`
                : `There are ${unconfiguredCustomers.count} customers that have no capabilities assigned and are therefore not ready to be used in Fleet. These customers have ${unconfiguredCustomers.capacity} kWh of available capacity and ${unconfiguredCustomers.power} kW of power output. You will not be billed for these customers until capabilities are assigned and they have been commissioned. These customers are identified in the table below.`}
            </InfoBox>
          ) : null}
          <CustomersListTable
            customers={customers}
            history={this.props.history}
            timezone={this.props.user.account.timezone}
            suborgType={this.props.user.account.suborg_type}
          />
        </Page.Content>
      </Page>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomersList);

import React, { Component } from "react";
import "./PasswordInstructions.css";

export default class PasswordInstructions extends Component {
  /*
   * Unfortunately Chrome doesn't support strings in the list-style-type parameter, so rather than
   * creating my own em dash SVG, I'm including the em dash inline. Unfortunately, Firefox doesn't
   * appear to render the CSS ::first-letter pseudo-element properly in this case, hence the spans.
   *
   * There must be a better way to do this, but for now this will have to do.
   */
  render() {
    return (
      <div className="password-requirements">
        Your password must:
        <ul>
          <li>
            <span>—</span> not be too common,
          </li>
          <li>
            <span>—</span> be at least 8 characters in length,
          </li>
          <li className="character-list">
            <span>—</span> contain at least 3 of the following:
            <ul>
              <li>
                <span>—</span> lower case letters (a-z),
              </li>
              <li>
                <span>—</span> upper case letters (A-Z),
              </li>
              <li>
                <span>—</span> numbers (0-9),
              </li>
              <li>
                <span>—</span> special characters (e.g. !@#$%^&* ).
              </li>
            </ul>
          </li>
        </ul>
      </div>
    );
  }
}

import React from "react";
import { SelectDropdown, Checkbox } from "../widgets";
import "./MonitoringGraphControls.css";

const LabelToggle = ({ type, label, checked, onChange }) => (
  <Checkbox
    checkboxComponent={id => (
      <label htmlFor={id} className={`label-button-checkbox ${type}`}>
        {label}
      </label>
    )}
    checked={checked}
    onChange={onChange}
  />
);

class MonitoringGraphControls extends React.Component {
  updateComponentState = (property, nextVal) => {
    let nextComponentState = { ...this.props.componentState };
    nextComponentState[property] = nextVal;
    this.props.onChange(nextComponentState);
  };

  updateSubmeterComponentState = (submeter, nextVal) => {
    let nextComponentState = { ...this.props.componentState };
    nextComponentState["submeters"][submeter] = nextVal;
    this.props.onChange(nextComponentState);
  };

  render() {
    const { components, componentState } = this.props;
    return (
      <div className="monitoring-graph-controls">
        <div className="control">
          <label>Secondary Y-Axis</label>
          <SelectDropdown
            value={componentState.secondaryYAxis}
            onChange={val => this.updateComponentState("secondaryYAxis", val)}
            options={components.secondaryYAxes}
          />
        </div>
        <div className="control" />
        {/* TODO: Implement data lookup by inverter set
        {this.props.components.inverterSets.length > 1 &&
         <div className="control">
            <label>System</label>
            <SelectDropdown
              value={componentState.system}
              onChange={val => this.updateComponentState("system", val)}
              options={[
                { label: "Combined", value: null },
                ...this.props.components.inverterSets.map(is => { return {
                      label: is.name,
                      value: is.id
                  }}),
              ]}
            />
          </div>
        }*/}
        <div className="control">
          <label>Energy Sources</label>
          <div className="toggles-container">
            {components.grid && (
              <LabelToggle
                type="grid"
                label="Grid"
                checked={componentState.grid}
                onChange={e => this.updateComponentState("grid", e.target.checked)}
              />
            )}
            {components.solar && (
              <LabelToggle
                type="solar"
                label="Solar"
                checked={componentState.solar}
                onChange={e => this.updateComponentState("solar", e.target.checked)}
              />
            )}
            {components.sga && (
              <LabelToggle
                type="sga"
                label="SGA"
                checked={componentState.sga}
                onChange={e => this.updateComponentState("sga", e.target.checked)}
              />
            )}
            {components.battery && (
              <LabelToggle
                type="battery"
                label="Battery Power"
                checked={componentState.battery}
                onChange={e => this.updateComponentState("battery", e.target.checked)}
              />
            )}
          </div>
        </div>
        <div className="control">
          <label>Consumption</label>
          <div className="toggles-container">
            <LabelToggle
              type="house"
              label="Total Consumption"
              checked={componentState.house}
              onChange={e => this.updateComponentState("house", e.target.checked)}
            />
            {components.submeters.map(sm => (
              <LabelToggle
                type="submeter"
                label={sm.name}
                checked={componentState.submeters[sm.id]}
                onChange={e => this.updateSubmeterComponentState(sm.id, e.target.checked)}
              />
            ))}
          </div>
        </div>
        {/* TODO: Enable split-by-phase once the backend work is done
       {components.splitByPhase && (
          <div className="control">
            <label>Phases</label>
            <Checkbox
              label="Split by Phase"
              checked={componentState.splitByPhase}
              onChange={e =>
                this.updateComponentState("splitByPhase", e.target.checked)
              }
            />
          </div>
        )}*/}
      </div>
    );
  }
}

export default MonitoringGraphControls;

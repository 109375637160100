import React from "react";
import { Button, DatePicker } from "../widgets";
import "./MonitoringTimeControls.css";
import moment from "moment-timezone";
import { MONITORING_INTERNAL_DATE_FORMAT } from "../../constants";

const PERIODS = [
  { label: "Day", value: "day" },
  { label: "Week", value: "week" },
  // TODO: Re-enable month view, but change it to show totals instead of having a low sample rate
  // {label: 'Month', value: 'month'},
  { label: "Year", value: "year" },
];

function today(timezone) {
  return moment().tz(timezone).startOf("day");
}

class MonitoringTimeControls extends React.Component {
  handleOnDateChange = (dateStr, period) => {
    let alignPeriod = period;
    if (period === "week") {
      // Ensure that our weeks start on Mondays, we're not Heretics.
      alignPeriod = "isoWeek";
    }
    const momentDate = moment(dateStr);

    let nextDate = momentDate.startOf(alignPeriod);

    // Ensure we don't select earlier that the minDate, this could happen for example
    // when switching from year to day view - year dates are 1st Jan, sysmte might not
    // have been commissioned until April, etc.
    if (this.props.minDate && nextDate.isBefore(this.props.minDate)) {
      nextDate = this.props.minDate.clone().startOf(alignPeriod);
    }

    // And the same for end dates, but this is less likely as all periods are start aligned.
    if (nextDate.isAfter(this.props.maxDate)) {
      nextDate = this.props.maxDate.clone().startOf(alignPeriod);
    }

    this.props.onDateChange({
      string: nextDate.format(MONITORING_INTERNAL_DATE_FORMAT),
      period: period,
    });
  };

  hasPrevious = (momentDate, period) => {
    return (
      this.props.minDate === null ||
      momentDate.isSameOrAfter(this.props.minDate.clone().startOf(period))
    );
  };

  hasNext = (momentDate, period) => {
    return momentDate.isSameOrBefore(today(this.props.dateInfo.timezone).startOf(period));
  };

  getPreviousDateForInfo = dateInfo => {
    return moment(dateInfo.string).subtract(1, dateInfo.period);
  };

  getNextDateForInfo = dateInfo => {
    return moment(dateInfo.string).add(1, dateInfo.period);
  };

  previousDate = () => {
    const { dateInfo } = this.props;
    const previousDate = this.getPreviousDateForInfo(dateInfo);
    if (this.hasPrevious(previousDate, dateInfo.period)) {
      this.handleOnDateChange(previousDate, dateInfo.period);
    }
  };

  nextDate = () => {
    const { dateInfo } = this.props;
    const nextDate = this.getNextDateForInfo(dateInfo);
    if (this.hasNext(nextDate, dateInfo.period)) {
      this.handleOnDateChange(nextDate, dateInfo.period);
    }
  };

  isMonday = date => {
    return moment(date).day() === 1;
  };

  gotoNow = () => {
    const now = moment().tz(this.props.timezone);
    this.handleOnDateChange(now, this.props.dateInfo.period);
  };

  getDateString = () => {
    const { dateInfo } = this.props;
    switch (dateInfo.period) {
      case "day":
        return moment(dateInfo.string).format("dddd, D MMMM YYYY");
      case "week":
        return `Week Starting ${moment(dateInfo.string).format("D MMMM YYYY")}`;
      case "month":
        return moment(dateInfo.string).format("MMMM YYYY");
      case "year":
        return moment(dateInfo.string).format("YYYY");
      default:
        console.warn("Unknown period", dateInfo.period);
        return moment(dateInfo.string).format("dddd, D MMMM YYYY");
    }
  };

  render() {
    const { dateInfo, minDate } = this.props;
    return (
      <div className="monitoring-time-controls">
        <div className="period-selector">
          {PERIODS.map(
            p =>
              this.props.allowedPeriods[p.value] && (
                <Button
                  type={`link-inverted ${dateInfo.period === p.value ? "active" : ""}`}
                  onClick={() => this.handleOnDateChange(dateInfo.string, p.value)}
                >
                  {p.label}
                </Button>
              )
          )}
        </div>
        <div className="data-download-container">
          {this.props.canExportData && (
            <button
              className="mp-button link download-link"
              onClick={() => this.props.openDataExport(this.props.dateInfo)}
            >
              <i className="fa fa-download" />
              Export data
            </button>
          )}
        </div>
        <div className="date-selector-container">
          {this.hasPrevious(this.getPreviousDateForInfo(dateInfo), dateInfo.period) ? (
            <Button type="link" onClick={this.previousDate}>
              <span className="fa fa-chevron-left" />
            </Button>
          ) : null}
          <h3 className={dateInfo.period.toLowerCase()}>{this.getDateString()}</h3>
          <DatePicker
            value={moment.utc(dateInfo.string, MONITORING_INTERNAL_DATE_FORMAT)}
            onChange={nextDate => this.handleOnDateChange(nextDate, dateInfo.period)}
            minDate={minDate}
            maxDate={today(dateInfo.timezone)}
            showMonthYearPicker={dateInfo.period === "month"}
            showYearPicker={dateInfo.period === "year"}
            filterDate={dateInfo.period === "week" ? this.isMonday : null}
          />
          <Button
            type="link goto-today"
            icon="clock-o"
            onClick={this.gotoNow}
            title="Go to today"
          />
          {this.hasNext(this.getNextDateForInfo(dateInfo), dateInfo.period) ? (
            <Button type="link" onClick={this.nextDate}>
              <span className="fa fa-chevron-right" />
            </Button>
          ) : (
            <div style={{ width: "18.5px" }} />
          )}
        </div>
      </div>
    );
  }
}

export default MonitoringTimeControls;

import React, { Component } from "react";
import Page from "../../components/layout/Page";
import { Button } from "../../components/widgets";
import AuthRequired from "../AuthRequired";
import Permissions from "../../permissions";
import PowerstationList from "./PowerstationsList";

export class PowerstationListContainer extends Component {
  constructor(props) {
    super(props);
    this.state = { createNewVpp: false };
    this.hideNewVppModal = this.hideNewVppModal.bind(this);
  }

  showNewVppModal = () => {
    this.setState({ createNewVpp: true });
  };

  hideNewVppModal = () => {
    this.setState({ createNewVpp: false });
  };

  render() {
    return (
      <Page key="powerstation-list" permission={Permissions.fleet.powerstations.any}>
        <Page.Header title="Virtual Power Plants">
          <AuthRequired permission={Permissions.fleet.powerstations.edit}>
            <Page.Header.Actions>
              <Button
                id="new-virtual-power-plant"
                type="primary"
                icon="plus"
                onClick={this.showNewVppModal}
              >
                New VPP
              </Button>
            </Page.Header.Actions>
          </AuthRequired>
        </Page.Header>
        <Page.Content>
          <PowerstationList
            hideNewVppModal={this.hideNewVppModal}
            createNewVpp={this.state.createNewVpp}
            {...this.props}
          />
        </Page.Content>
      </Page>
    );
  }
}

export default PowerstationListContainer;

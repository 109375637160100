import React, { Component } from "react";
import FullScreenLogoWhite from "./fullscreen_logo_white.svg";
import FullScreenLogoBlue from "./fullscreen_logo_blue.svg";
import "./WidgetContent.css";

class WidgetContent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      contentWidth: 0,
      contentHeight: 0,
    };

    this.widgetContent = {
      clientWidth: 0,
      clientHeight: 0,
    };
  }

  renderFullScreenWidgetMarkers = () => {
    let markers = [];
    for (let i = 0; i < this.props.widgetCount; i++) {
      const className = i === this.props.fullScreenWidgetIndex ? "fa fa-circle" : "fa fa-circle-o";
      markers.push(
        <span className={className} onClick={() => this.props.gotoFullScreenWidget(i)} />
      );
    }

    return markers;
  };

  componentDidMount = () => {
    this.setState({
      contentWidth: this.widgetContent.clientWidth,
      contentHeight: this.widgetContent.clientHeight,
    });
    // Resizing is managed by the grid layout itself. As such in order to update the grid items
    // we need to hook into this. We do so via a callback that we register with Dashboard and then
    // gets run for each widget that is resized.
    this.props.registerResizeCallback(this.props.widgetId, this.handleResize);
  };

  registerWidgetContent = widgetContent => {
    if (widgetContent) {
      this.widgetContent = widgetContent;
      this.setState({
        contentWidth: this.widgetContent.clientWidth,
        contentHeight: this.widgetContent.clientHeight,
      });
    }
  };

  handleResize = () => {
    this.setState({
      contentWidth: this.widgetContent.clientWidth,
      contentHeight: this.widgetContent.clientHeight,
    });
  };

  render() {
    const WidgetComponent = this.props.widgetComponent;
    const notConfigured = this.props.title === "Not configured";

    return (
      <div
        className={"widget-content" + (this.props.fullscreen ? " fullscreen" : "")}
        style={{ height: this.props.height }}
      >
        <header
          className={
            this.props.fullscreen
              ? null
              : `${notConfigured ? "not-configured" : ""} widget-drag-handle`
          }
        >
          {this.props.fullscreen ? (
            <div className="widget-controls">
              <img
                src={
                  this.props.widgetComponent.fullScreenLogo === "white"
                    ? FullScreenLogoWhite
                    : FullScreenLogoBlue
                }
                alt="Reposit Power logo"
              />
            </div>
          ) : (
            <div className="widget-controls" />
          )}

          <div className="title-container">
            <h3 className={notConfigured ? "not-configured" : ""}>
              {notConfigured ? "Single Stat" : this.props.title}
            </h3>
            <span className="widget-description">{this.props.description}</span>
            {this.props.fullscreen ? (
              <div className="pages-container">{this.renderFullScreenWidgetMarkers()}</div>
            ) : null}
          </div>

          {this.props.fullscreen ? (
            <div className="widget-controls right">
              {this.props.isFullScreenRunning ? (
                <span className="fa fa-pause" onClick={this.props.pauseFullScreen} />
              ) : (
                <span className="fa fa-play" onClick={this.props.resumeFullScreen} />
              )}
              <span className="fa fa-compress" onClick={this.props.exitFullScreen} />
            </div>
          ) : (
            <div className="widget-controls right">
              <span className="fa fa-cog" onClick={this.props.openSettings} />
              <span className="fa fa-times" onClick={this.props.removeWidget} />
            </div>
          )}
        </header>

        {this.props.error ? (
          <div className="widget-error-message">
            <div>Error</div>
            {this.props.errorMessage ? this.props.errorMessage : "Something went wrong"}
          </div>
        ) : null}

        <div className="widget-content-wrapper" ref={this.registerWidgetContent}>
          {this.state.contentWidth && this.state.contentHeight ? (
            <WidgetComponent
              setTitle={this.props.setTitle}
              setWidgetClassName={this.props.setWidgetClassName}
              onError={this.props.onError}
              width={this.widgetContent.clientWidth}
              height={this.widgetContent.clientHeight}
              settings={this.props.settings}
              fullscreen={this.props.fullscreen}
            />
          ) : null}
        </div>

        {/* children is passed in by react-grid-layout and contains the resize handlers */}
        {this.props.reactGridLayoutChildren}
      </div>
    );
  }
}

export default WidgetContent;

import React, { Component } from "react";
import Button from "../widgets/Button";
import { Modal } from "../layout/Modal";
import SelectDropdown from "../widgets/SelectDropdown";
import "./SiteDetailsModalContent.css";
import { GRID_PHASE_OPTIONS } from "../../containers/ordering/KitConstants";

class SiteDetailsModalContent extends Component {
  handleNext = () => {
    this.props.onNext();
  };

  handleGridPhasesChanged = gridPhases => {
    this.props.onGridPhasesChanged(gridPhases);
  };

  handleNumInvertersChanged = (numExisting, numNew) => {
    this.props.onNumInvertersChanged(numExisting, numNew);
  };

  handleCancel = () => {
    this.props.onCancel();
  };

  render() {
    const inverterNumberOptions = [
      { value: 0, label: "0" },
      { value: 1, label: "1" },
      { value: 2, label: "2" },
      { value: 3, label: "3" },
      { value: 4, label: "4" },
      { value: 5, label: "5" },
    ];

    const { loading, gridPhases, existingInvertersCount, newInvertersCount } = this.props;

    return (
      <>
        <Modal.Header
          className="kit-design-header"
          title="Configure a Reposit Kit &mdash; Site Details"
        />
        <Modal.Content>
          <div className="kit-design-form site-details">
            <div className="kit-design-form-section">
              <div className="grid-phases-sentence">
                Create a new Reposit Kit for a&nbsp;
                <SelectDropdown
                  className="grid-phases-selector"
                  placeholder="&hellip;"
                  value={gridPhases.value}
                  options={GRID_PHASE_OPTIONS}
                  onChange={this.handleGridPhasesChanged}
                  error={gridPhases.error}
                  tooltipContent={gridPhases.error}
                  tooltipIcon="exclamation-circle"
                />
                &nbsp;installation
              </div>
              <div className="grid-phases-sentence">
                There {existingInvertersCount === 1 ? "is" : "are"} currently&nbsp;
                <SelectDropdown
                  className="grid-phases-selector"
                  placeholder="&hellip;"
                  value={existingInvertersCount}
                  options={inverterNumberOptions}
                  onChange={count => this.handleNumInvertersChanged(count, newInvertersCount)}
                />
                &nbsp;inverter{existingInvertersCount === 1 ? "" : "s"} at the premises&nbsp;
              </div>
              <div className="grid-phases-sentence">
                Additionally,&nbsp;
                <SelectDropdown
                  className="grid-phases-selector"
                  placeholder="&hellip;"
                  value={newInvertersCount}
                  options={inverterNumberOptions}
                  onChange={count => this.handleNumInvertersChanged(existingInvertersCount, count)}
                />
                &nbsp;inverter{newInvertersCount === 1 ? "" : "s"} will be added&nbsp;
              </div>
            </div>
          </div>
        </Modal.Content>
        <Modal.Footer>
          <Button type="secondary" onClick={this.handleCancel} icon="times" disabled={loading}>
            Cancel
          </Button>
          <div className="kit-design-next">
            <Button
              type="primary"
              onClick={this.handleNext}
              icon={"chevron-right"}
              loading={loading}
            >
              Next
            </Button>
          </div>
        </Modal.Footer>
      </>
    );
  }
}

export default SiteDetailsModalContent;

import React, { Component } from "react";
import { Input, Select } from "../widgets";
import "./CustomersListSearchBar.css";

class CustomersListSearchBar extends Component {
  render() {
    return (
      <>
        <Input
          placeholder="NMI or address..."
          onChange={e => this.props.onFilterFieldChange("searchTerm", e.target.value)}
          value={this.props.searchTerm ? this.props.searchTerm : ""}
          containerStyle={{ flex: 5 }}
          style={{ width: "100%" }}
        />
        {this.props.suborgType === "RETAILER" ? (
          <Select
            placeholder="Tariff..."
            value={this.props.tariff}
            options={this.props.filterTariffs}
            onChange={value => this.props.onFilterFieldChange("tariff", value ? value.value : null)}
            containerStyle={{ flex: 2 }}
            style={{ width: "100%" }}
          />
        ) : null}
        <Select
          placeholder="Capabilities..."
          value={this.props.additionalContracts}
          options={this.props.filterContracts}
          onChange={value =>
            this.props.onFilterFieldChange("additionalContracts", value ? value.value : null)
          }
          containerStyle={{ flex: 2 }}
          style={{ flex: "100%" }}
        />
      </>
    );
  }
}

export default CustomersListSearchBar;

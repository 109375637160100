import React, { Component } from "react";
import { Input, Toggle } from "../../widgets";
import Permissions from "../../../permissions";
import "./UserForm.css";

class UserForm extends Component {
  getPermissionsList = () => {
    let permissions = [];
    for (let i in this.props.availablePermissions) {
      const permission = this.props.availablePermissions[i];
      const userPermission = this.props.userPermissions.find(p => p === permission.name);
      const enabled = !!userPermission;
      if (!this.props.readOnly || enabled) {
        permissions.push({
          name: permission.name,
          displayName: permission.displayName,
          description: permission.description,
          enabled: enabled,
        });
      }
    }

    return permissions;
  };

  render() {
    const permissions = this.getPermissionsList();

    return (
      <div className="user-form">
        <div className="user-form-row">
          <label className="user-name">
            <span className="label-text">Given Name</span>
            <div className="widget">
              <Input
                readOnly={this.props.readOnly}
                value={this.props.givenName}
                onChange={e => this.props.handleFieldOnChange("givenName", e.target.value)}
                error={!this.props.readOnly ? this.props.errors.givenName : null}
              />
            </div>
          </label>
          <label className="user-name">
            <span className="label-text">Surname</span>
            <div className="widget">
              <Input
                readOnly={this.props.readOnly}
                value={this.props.surname}
                onChange={e => this.props.handleFieldOnChange("surname", e.target.value)}
                error={!this.props.readOnly ? this.props.errors.surname : null}
              />
            </div>
          </label>
        </div>
        <div className="user-form-row">
          <label className="email">
            <span className="label-text">Email</span>
            <div className="widget">
              <Input
                readOnly={this.props.readOnly}
                value={this.props.email}
                onChange={e => this.props.handleFieldOnChange("email", e.target.value)}
                error={!this.props.readOnly ? this.props.errors.email : null}
              />
            </div>
          </label>
        </div>

        <h2>Permissions</h2>

        {permissions.length > 0 ? (
          <table className="table-light permissions-table">
            {!this.props.readOnly ? (
              <thead>
                <tr>
                  <th />
                  <th style={{ width: "8em" }}>Enabled?</th>
                </tr>
              </thead>
            ) : null}
            <tbody>
              {permissions.map(p => {
                return (
                  <tr>
                    <td>
                      <span className="display-name">{p.displayName}</span>
                      <span className="description">{p.description}</span>
                    </td>
                    {!this.props.readOnly ? (
                      <td>
                        {/* Allowing the user to revoke the Fleet admin priv from themselves
                          might result in having that ord blocked from Fleet so disallow it.*/}
                        {p.name === Permissions.fleet.administrator &&
                        p.enabled &&
                        this.props.userId === this.props.loggedInUserId ? (
                          <span className="no-revoke">
                            You may not revoke this permission from yourself
                          </span>
                        ) : (
                          <Toggle
                            readOnly={this.props.readOnly}
                            checked={p.enabled}
                            onChange={e =>
                              this.props.handlePermissionToggle(p.name, e.target.checked)
                            }
                          />
                        )}
                      </td>
                    ) : null}
                  </tr>
                );
              })}
            </tbody>
          </table>
        ) : (
          <p>This user has no permissions.</p>
        )}
      </div>
    );
  }
}

export default UserForm;

import * as actions from "../actions/customers";

const customersInitialState = {
  customers: null,
  contracts: null,
  customersListFilter: {
    searchTerm: null,
    tariff: null,
    additionalContracts: null,
    agreementVersion: null,
  },
  tariff: {
    operationalNetworks: null,
    tariffs: null,
    activeTariff: null,
    savingTariff: false,
    serverErrors: null,
  },
  tariffListFilter: {
    tariffNameOrPlanCode: null,
    network: null,
    contract: null,
    sortField: null,
  },
  activeCustomer: null,
};

const contractsReducer = (state = customersInitialState, action) => {
  switch (action.type) {
    case "RESET_STORE":
      return {
        ...customersInitialState,
      };
    case actions.CLEAR_CUSTOMER_CONTRACTS:
      return {
        ...customersInitialState,
      };
    case actions.UPDATE_CUSTOMER_CONTRACTS:
      return {
        ...state,
        customers: action.payload.customers,
        contracts: action.payload.contracts,
        requiresRefresh: false,
      };
    case actions.UPDATE_CUSTOMER_LIST_FILTER:
      return {
        ...state,
        customersListFilter: {
          ...state.customersListFilter,
          [action.payload.filterKey]: action.payload.filterValue,
        },
      };
    case actions.CLEAR_CUSTOMER_LIST_FILTER:
      return {
        ...state,
        customersListFilter: customersInitialState.customersListFilter,
      };
    case actions.UPDATE_TARIFF_LIST_FILTER:
      return {
        ...state,
        tariffListFilter: {
          ...state.tariffListFilter,
          [action.payload.filterKey]: action.payload.filterValue,
        },
      };
    case actions.CLEAR_TARIFF_LIST_FILTER:
      return {
        ...state,
        tariffListFilter: customersInitialState.tariffListFilter,
      };
    case actions.UPDATE_OPERATIONAL_NETWORKS:
      return {
        ...state,
        tariff: {
          ...state.tariff,
          operationalNetworks: action.payload.networks,
        },
      };
    case actions.UPDATE_CUSTOMER_DETAILS:
      return {
        ...state,
        activeCustomer: {
          ...(state.activeCustomer ? state.activeCustomer : {}),
          ...action.payload.customerDetails,
        },
      };
    case actions.CLEAR_CUSTOMER_DETAILS:
      return {
        ...state,
        activeCustomer: null,
      };
    case actions.UPDATE_CURRENT_CUSTOMER_TARIFF:
      return {
        ...state,
        activeCustomer: {
          ...(state.activeCustomer ? state.activeCustomer : {}),
          currentTariff: action.payload.tariff,
        },
      };
    case actions.UPDATE_ALL_TARIFFS:
      return {
        ...state,
        tariff: {
          ...state.tariff,
          tariffs: action.payload.tariffs,
        },
      };
    case actions.UPDATE_TARIFF:
      return {
        ...state,
        tariff: {
          ...state.tariff,
          activeTariff: action.payload.tariff,
        },
      };
    case actions.CLEAR_ACTIVE_TARIFF:
      return {
        ...state,
        tariff: {
          ...state.tariff,
          activeTariff: null,
        },
      };
    case actions.FETCH_SAVE_NEW_TARIFF:
    case actions.FETCH_SAVE_EXISTING_TARIFF:
      return {
        ...state,
        tariff: {
          ...state.tariff,
          savingTariff: true,
          serverErrors: null,
        },
      };
    case actions.UPDATE_SAVE_NEW_TARIFF:
    case actions.UPDATE_SAVE_EXISTING_TARIFF:
      return {
        ...state,
        tariff: {
          ...state.tariff,
          activeTariff: action.payload.tariff,
          savingTariff: false,
          serverErrors: action.payload.tariffErrors,
        },
      };
    case actions.ERROR_SAVE_NEW_TARIFF:
    case actions.ERROR_SAVE_EXISTING_TARIFF:
      return {
        ...state,
        tariff: {
          ...state.tariff,
          savingTariff: false,
          serverErrors: null,
        },
      };
    default:
      return state;
  }
};

export default contractsReducer;

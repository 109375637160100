import NoWidgets from "./NoWidgets";
import VoltageHeatMap from "./VoltageHeatMap";
import SingleStat from "./SingleStat";
import NodeMap from "./NodeMap";
import GraphWidget from "./GraphWidget";

// Add your widgets here!
const WIDGETS = [NoWidgets, SingleStat, VoltageHeatMap, NodeMap, GraphWidget];

// If the component is wrapped in connect() then this will return the wrapped component inside of
// connect otherwise it will return componentClass.
export const getCoreComponentClass = componentClass => {
  if (componentClass.WrappedComponent) {
    return componentClass.WrappedComponent;
  } else {
    return componentClass;
  }
};

let widgetConfig = { widgets: [] };
for (let i in WIDGETS) {
  const componentClass = WIDGETS[i];
  const coreComponentClass = getCoreComponentClass(componentClass);
  widgetConfig.widgets.push({
    id: coreComponentClass.widgetId,
    component: componentClass,
  });
}

export const WIDGET_CONFIG = widgetConfig;

let WIDGET_ID_MAP = {};
for (let i in widgetConfig.widgets) {
  const widget = widgetConfig.widgets[i];
  WIDGET_ID_MAP[widget.id] = widget;
}

export const getComponentClass = widgetId => {
  const widgetConfig = WIDGET_ID_MAP[widgetId];
  if (widgetConfig) {
    return widgetConfig.component;
  }
};

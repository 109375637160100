import React, { Component } from "react";
import "./InverterSystemIllustration.css";
import Button from "../widgets/Button";

import SolarPanel from "./icons/solar_panel.svg";

class InverterSystemIllustration extends Component {
  handleSelected = () => {
    this.props.onSelected();
  };

  handleRemoved = () => {
    this.props.onRemoved();
  };

  getSolarIcon = pvConnected => {
    return pvConnected ? <img src={SolarPanel} alt="Solar" /> : null;
  };

  getIcons = () => {
    const {
      inverterTypes,
      batteryTypes,
      inverterSystem: {
        inverter: { value: inverter },
        battery: { value: battery },
        pvConnected: { value: pvConnected },
      },
    } = this.props;

    if (!inverter) {
      return (
        <>
          <div className="inverter-illustration">
            <img src={inverterTypes["00"].imageUrl} alt={inverterTypes["00"].displayName} />
          </div>
        </>
      );
    }

    if (inverter && inverterTypes[inverter].allInOne) {
      return (
        <>
          <div className="inverter-illustration">
            <img src={inverterTypes[inverter].imageUrl} alt={inverterTypes[inverter].displayName} />
          </div>
          <div className="solar-illustration">{this.getSolarIcon(pvConnected)}</div>
        </>
      );
    } else {
      return (
        <>
          <div className="inverter-illustration">
            <img src={inverterTypes[inverter].imageUrl} alt={inverterTypes[inverter].displayName} />
          </div>
          <div className="solar-illustration">{this.getSolarIcon(pvConnected)}</div>
          {battery ? (
            <div className="battery-illustration">
              <img src={batteryTypes[battery].imageUrl} alt={batteryTypes[battery].displayName} />
            </div>
          ) : null}
        </>
      );
    }
  };

  render() {
    const { loading, selected, inverterSystem } = this.props;

    const errors = Object.values(inverterSystem).filter(val => val.error).length;

    const errorIndicator = errors ? (
      <div className={"error-indicator"}>
        <span className="fa fa-exclamation-circle" /> {errors} issue{errors === 1 ? "" : "s"}
      </div>
    ) : null;

    return (
      <div className="illustration-container">
        <div className="inverter-system-remove">
          <Button type="link" onClick={this.handleRemoved} icon="minus-circle" disabled={loading} />
        </div>
        <div className="illustration" onClick={this.handleSelected}>
          {this.getIcons()}
        </div>
        {errorIndicator}
        <div className={"selected-indicator" + (selected ? " selected" : "")}> </div>
      </div>
    );
  }
}

export default InverterSystemIllustration;

import React, { Component } from "react";
import { VictoryArea, VictoryAxis, VictoryLabel } from "victory";
import { getDateAsString, getTimeAsString } from "../widgets/Moment";
import { Tooltip } from "../widgets";
import { durationToString } from "../../utils/formatting";
import "./DispatchResponseGraph.css";

const PADDING_PCT = 0.22;

class DispatchResponseGraph extends Component {
  constructor(props) {
    super(props);

    this.state = {
      graphWidth: null,
      graphHeight: null,
    };
  }

  getGraphData = (prediction, response, startTime, duration, accepted) => {
    const tickSpacing = 1800;
    const endTime = startTime + duration;

    let timestampTicks = {};
    let highlightedTimestampTicks = {};
    let zebraTicks = {};

    let startTimestamp = startTime;
    if (response && response.batteryPower) {
      startTimestamp = Number(Object.keys(response.batteryPower)[0]);
    } else if (prediction && prediction.batteryPower) {
      startTimestamp = Number(Object.keys(prediction.batteryPower)[0]);
    }

    const padding = startTime - startTimestamp;
    const endTimestamp = startTime + duration + padding;

    let timestamp = startTimestamp;
    const timezone = this.props.timezone;
    let counter = 0;
    while (timestamp <= endTimestamp) {
      // A a vertical line.
      timestampTicks[timestamp * 1000] = { timestamp: timestamp * 1000, display: "" };

      // Zebra striped lines occur every other line.
      if (counter % 2 === 1) {
        zebraTicks[(timestamp - 900) * 1000] = { timestamp: timestamp * 1000, display: "" };
      }

      timestamp += tickSpacing;
      counter++;
    }

    // Set up a highlighted vertical line for the start time of the dispatch.
    highlightedTimestampTicks[startTime * 1000] = { timestamp: startTime * 1000, display: "" };
    const jsStartDate = new Date(startTime * 1000);
    const startDateStr = getDateAsString(jsStartDate, timezone);
    const startTimeStr = getTimeAsString(jsStartDate, timezone);
    timestampTicks[startTime * 1000] = {
      timestamp: startTime * 1000,
      display: startDateStr + "\n" + startTimeStr,
      anchor: duration < 3600 ? "end" : "middle",
    };

    // Set up a highlighted vertical line for the end time of the dispatch.
    highlightedTimestampTicks[endTime * 1000] = { timestamp: endTime * 1000, display: "" };
    const jsEndDate = new Date(endTime * 1000);
    const endTimeStr = getTimeAsString(jsEndDate, timezone);
    timestampTicks[endTime * 1000] = {
      timestamp: endTime * 1000,
      display: durationToString(duration) + "\n" + endTimeStr,
      anchor: duration < 3600 ? "start" : "middle",
    };

    let batteryResponseData,
      latestBatteryResponseTimestamp = null,
      latestBatteryResponse = null;
    if (response && response.batteryPower) {
      batteryResponseData = [];
      let batteryResponse = response.batteryPower;

      Object.keys(batteryResponse)
        .sort()
        .forEach(function (k) {
          const timestamp = parseInt(k, 10);
          batteryResponseData.push({
            x: timestamp * 1000,
            y: batteryResponse[k],
            timestamp: timestamp * 1000,
          });
          latestBatteryResponseTimestamp = timestamp;
          latestBatteryResponse = batteryResponse[k];
        });
    }

    let meterResponseData,
      latestMeterResponseTimestamp = null,
      latestMeterResponse = null;
    if (response && response.meterPower) {
      meterResponseData = [];
      let meterResponse = response.meterPower;

      Object.keys(meterResponse)
        .sort()
        .forEach(function (k) {
          const timestamp = parseInt(k, 10);
          meterResponseData.push({
            x: timestamp * 1000,
            y: meterResponse[k],
            timestamp: timestamp * 1000,
          });
          latestMeterResponseTimestamp = timestamp;
          latestMeterResponse = meterResponse[k];
        });
    }

    let batteryPredictionData;
    if (prediction && prediction.batteryPower) {
      batteryPredictionData = [];
      let batteryPrediction = prediction.batteryPower;

      Object.keys(batteryPrediction)
        .sort()
        .forEach(function (k) {
          const timestamp = parseInt(k, 10);

          if (
            latestBatteryResponseTimestamp === null ||
            latestBatteryResponseTimestamp <= timestamp
          ) {
            batteryPredictionData.push({
              x: timestamp * 1000,
              y: batteryPrediction[k],
              timestamp: timestamp * 1000,
            });
          }

          if (
            latestBatteryResponseTimestamp > timestamp &&
            latestBatteryResponseTimestamp < timestamp + 1800
          ) {
            batteryPredictionData.push({
              x: latestBatteryResponseTimestamp * 1000,
              y: latestBatteryResponse,
              timestamp: latestBatteryResponseTimestamp * 1000,
            });
          }
        });
    }

    let meterPredictionData;
    if (prediction && prediction.meterPower) {
      meterPredictionData = [];
      let batteryPrediction = prediction.meterPower;

      Object.keys(batteryPrediction)
        .sort()
        .forEach(function (k) {
          const timestamp = parseInt(k, 10);

          if (latestMeterResponseTimestamp === null || latestMeterResponseTimestamp <= timestamp) {
            meterPredictionData.push({
              x: timestamp * 1000,
              y: batteryPrediction[k],
              timestamp: timestamp * 1000,
            });
          }

          if (
            latestMeterResponseTimestamp > timestamp &&
            latestMeterResponseTimestamp < timestamp + 1800
          ) {
            meterPredictionData.push({
              x: latestMeterResponseTimestamp * 1000,
              y: latestMeterResponse,
              timestamp: latestMeterResponseTimestamp * 1000,
            });
          }
        });
    }

    let acceptedData = [
      {
        x: startTimestamp * 1000,
        y: 0.0,
        timestamp: startTimestamp * 1000,
      },
      {
        x: startTime * 1000,
        y: accepted,
        timestamp: startTime * 1000,
      },
      {
        x: (startTime + duration) * 1000,
        y: 0.0,
        timestamp: (startTime + duration) * 1000,
      },
      {
        x: endTimestamp * 1000,
        y: 0.0,
        timestamp: endTimestamp * 1000,
      },
    ];

    return {
      batteryResponseData: batteryResponseData,
      meterResponseData: meterResponseData,
      acceptedData: acceptedData,
      batteryPredictionData: batteryPredictionData,
      meterPredictionData: meterPredictionData,
      tickDisplay: timestampTicks,
      highlightedTickDisplay: highlightedTimestampTicks,
      startTimestamp: startTimestamp,
      endTimestamp: endTimestamp,
      zebraTickDisplay: zebraTicks,
    };
  };

  registerGraphContainer = graphContainer => {
    if (graphContainer) {
      this.setState({
        graphWidth: graphContainer.clientWidth,
        graphHeight: graphContainer.clientHeight,
      });
    }
  };

  getDomain = (accepted, response, prediction, startTimestamp, endTimestamp) => {
    let min = accepted < 0 ? accepted : 0;
    let max = accepted > 0 ? accepted : 0;

    let batteryResponse;
    if (response && response.batteryPower) {
      batteryResponse = response.batteryPower;
      Object.keys(batteryResponse)
        .sort()
        .forEach(function (k) {
          min = batteryResponse[k] < min ? batteryResponse[k] : min;
          max = batteryResponse[k] > max ? batteryResponse[k] : max;
        });
    }

    let meterResponse;
    if (response && response.meterPower) {
      meterResponse = response.meterPower;
      Object.keys(meterResponse)
        .sort()
        .forEach(function (k) {
          min = meterResponse[k] < min ? meterResponse[k] : min;
          max = meterResponse[k] > max ? meterResponse[k] : max;
        });
    }

    let batteryPrediction;
    if (prediction && prediction.batteryPower) {
      batteryPrediction = prediction.batteryPower;
      Object.keys(batteryPrediction)
        .sort()
        .forEach(function (k) {
          min = batteryPrediction[k] < min ? batteryPrediction[k] : min;
          max = batteryPrediction[k] > max ? batteryPrediction[k] : max;
        });
    }

    let meterPrediction;
    if (prediction && prediction.meterPower) {
      meterPrediction = prediction.meterPower;
      Object.keys(meterPrediction)
        .sort()
        .forEach(function (k) {
          min = meterPrediction[k] < min ? meterPrediction[k] : min;
          max = meterPrediction[k] > max ? meterPrediction[k] : max;
        });
    }

    min = min === 0 ? -3 : min - Math.abs(min * PADDING_PCT);
    max = max === 0 ? 3 : max + Math.abs(max * PADDING_PCT);

    return {
      x: [startTimestamp * 1000, endTimestamp * 1000],
      y: [min, max],
    };
  };

  render() {
    const { response, startTime, duration, accepted, prediction } = this.props;
    const {
      acceptedData,
      meterResponseData,
      batteryResponseData,
      batteryPredictionData,
      meterPredictionData,
      zebraTickDisplay,
      tickDisplay,
      highlightedTickDisplay,
      startTimestamp,
      endTimestamp,
    } = this.getGraphData(prediction, response, startTime, duration, accepted);

    const width = this.state.graphWidth;
    const height = this.state.graphHeight; // this.state.graphHeight;
    const bottomPadding = 18;
    const padding = { top: 0, right: 0, bottom: 0, left: 0 };

    const domain = this.getDomain(accepted, response, prediction, startTimestamp, endTimestamp);

    return (
      <div className="dispatch-response-graph">
        <div className="legend-container">
          <div className="legend">
            <span className="key-style accepted" />
            <span className="key-label">Accepted</span>
          </div>
          <div className="legend">
            <span className="key-style battery-response" />
            <span className="key-label">Battery</span>
          </div>
          <div className="legend">
            <span className="key-style battery-prediction" />
            <span className="key-label">Battery Prediction</span>
          </div>
          <div className="legend">
            <span className="key-style meter-response" />
            <span className="key-label">Grid</span>
          </div>
          <div className="legend">
            <span className="key-style meter-prediction" />
            <span className="key-label">Grid Prediction</span>
          </div>
          <Tooltip medium id="dispatches.graph.legend" />
        </div>
        <div className="graph-container" ref={this.registerGraphContainer}>
          {this.state.graphWidth && (
            <svg
              width={width}
              height={height + bottomPadding}
              role="img"
              aria-labelledby="undefined-title undefined-desc"
              viewBox={`0 0 ${width} ${height + bottomPadding}`}
              overflow="visible"
              style={{
                width: `${width}px`,
                height: `${height + bottomPadding}px`,
                userSelect: "none",
                touchAction: "none",
                fill: "#ffffff",
              }}
            >
              <rect
                x="0"
                y="0"
                fill="#fff"
                style={{
                  width: "calc(100%)",
                  height: `calc(100% - ${bottomPadding}px)`,
                  stroke: "#E8E8E9",
                  strokeWidth: 1,
                }}
              />

              <g transform="translate(0, 0)">
                {/* x-axis thick grid lines/zebra striping */}
                <VictoryAxis
                  width={width}
                  height={height}
                  padding={padding}
                  domain={domain}
                  style={{
                    axis: { stroke: "none" },
                    grid: {
                      stroke: "rgba(219, 226, 233, 0.3)",
                      strokeWidth: width / (Object.keys(tickDisplay).length - 1),
                    },
                  }}
                  orientation="bottom"
                  tickValues={Object.keys(zebraTickDisplay).map(Number)}
                  tickFormat={() => ""}
                  standalone={false}
                />

                {/* x-axis labels */}
                <VictoryAxis
                  width={width}
                  height={height + bottomPadding * 2}
                  padding={padding}
                  domain={domain}
                  style={{
                    axis: { stroke: "none" },
                    grid: { stroke: "none" },
                    tickLabels: {
                      fontFamily: '"Work Sans", "Helvetica Neue", Helvetica, Arial, sans-serif',
                      fill: "#4698cb",
                      fontSize: "12",
                    },
                  }}
                  offsetY={42}
                  orientation="bottom"
                  tickValues={Object.keys(tickDisplay).map(Number)}
                  tickFormat={tick => tickDisplay[tick + ""].display}
                  standalone={false}
                />

                {/* y-axis grid */}
                <VictoryAxis
                  width={width}
                  height={height}
                  padding={0}
                  dependentAxis
                  domain={domain}
                  crossAxis={false}
                  style={{
                    axis: { stroke: "#E8E8E9" },
                    grid: { stroke: "#E8E8E9" },
                  }}
                  orientation="left"
                  tickLabelComponent={<g />}
                  standalone={false}
                />

                {/* x-axis blue lines */}
                <VictoryAxis
                  width={width}
                  height={height}
                  padding={padding}
                  domain={domain}
                  style={{
                    axis: { stroke: "none" },
                    grid: { stroke: "#4698cb" },
                  }}
                  orientation="bottom"
                  tickValues={Object.keys(highlightedTickDisplay).map(Number)}
                  tickFormat={() => ""}
                  standalone={false}
                />

                {acceptedData && (
                  <VictoryArea
                    width={width}
                    height={height}
                    padding={padding}
                    data={acceptedData}
                    domain={domain}
                    interpolation="stepAfter"
                    style={{
                      data: {
                        stroke: "#4698CB",
                        strokeWidth: 2,
                        fill: "none",
                      },
                    }}
                    standalone={false}
                  />
                )}

                {meterResponseData && (
                  <VictoryArea
                    width={width}
                    height={height}
                    padding={padding}
                    data={meterResponseData}
                    domain={domain}
                    style={{
                      data: {
                        stroke: "#aaaaaa",
                        strokeWidth: 2,
                        fill: "#aaaaaa",
                        fillOpacity: 0.2,
                      },
                    }}
                    standalone={false}
                  />
                )}

                {meterPredictionData && (
                  <VictoryArea
                    width={width}
                    height={height}
                    padding={padding}
                    data={meterPredictionData}
                    domain={domain}
                    interpolation="stepAfter"
                    style={{
                      data: {
                        stroke: "#aaaaaa",
                        strokeWidth: 2,
                        strokeOpacity: 0.6,
                        strokeDasharray: "3, 2",
                        fill: "#aaaaaa",
                        fillOpacity: 0.1,
                      },
                    }}
                    standalone={false}
                  />
                )}

                {batteryResponseData && (
                  <VictoryArea
                    width={width}
                    height={height}
                    padding={padding}
                    data={batteryResponseData}
                    domain={domain}
                    style={{
                      data: {
                        stroke: "#26D07C",
                        strokeWidth: 2,
                        fill: "#26D07C",
                        fillOpacity: 0.2,
                      },
                    }}
                    standalone={false}
                  />
                )}

                {batteryPredictionData && (
                  <VictoryArea
                    width={width}
                    height={height}
                    padding={padding}
                    data={batteryPredictionData}
                    domain={domain}
                    interpolation="stepAfter"
                    style={{
                      data: {
                        stroke: "#26D07C",
                        strokeWidth: 2,
                        strokeOpacity: 0.6,
                        strokeDasharray: "3, 2",
                        fill: "#26D07C",
                        fillOpacity: 0.1,
                      },
                    }}
                    standalone={false}
                  />
                )}

                {/* y-axis labels */}
                <VictoryAxis
                  width={width}
                  height={height}
                  padding={padding}
                  dependentAxis
                  domain={domain}
                  crossAxis={false}
                  style={{
                    axis: { stroke: "none" },
                  }}
                  orientation="right"
                  offsetX={18}
                  tickLabelComponent={
                    <VictoryLabel
                      style={{
                        fontFamily: '"Work Sans", "Helvetica Neue", Helvetica, Arial, sans-serif',
                        fontSize: "14",
                        fill: "#616161",
                        fontWeight: 500,
                      }}
                      textAnchor="end"
                    />
                  }
                  tickFormat={datum => (datum ? datum : 0) + " kW"}
                  standalone={false}
                />
              </g>
            </svg>
          )}
        </div>
      </div>
    );
  }
}
export default DispatchResponseGraph;

export const FETCH_NOTIFICATIONS = "FETCH_NOTIFICATIONS";
export const UPDATE_NOTIFICATIONS = "UPDATE_NOTIFICATIONS";
export const FETCH_NOTIFICATION = "FETCH_NOTIFICATION";
export const UPDATE_NOTIFICATION = "UPDATE_NOTIFICATION";
export const CLEAR_ACTIVE_NOTIFICATION = "CLEAR_ACTIVE_NOTIFICATION";
export const FETCH_MARK_READ_NOTIFICATION = "FETCH_MARK_READ_NOTIFICATION";
export const UPDATE_MARK_READ_NOTIFICATION = "UPDATE_MARK_READ_NOTIFICATION";
export const FETCH_MARK_UNREAD_NOTIFICATION = "FETCH_MARK_UNREAD_NOTIFICATION";
export const UPDATE_MARK_UNREAD_NOTIFICATION = "UPDATE_MARK_UNREAD_NOTIFICATION";
export const FETCH_MARK_ALL_READ_NOTIFICATION = "FETCH_MARK_ALL_READ_NOTIFICATION";
export const UPDATE_MARK_ALL_READ_NOTIFICATION = "UPDATE_MARK_ALL_READ_NOTIFICATION";

export function fetchNotifications() {
  return {
    type: FETCH_NOTIFICATIONS,
    meta: {
      endpoint: "/account/notifications",
      success: updateNotifications,
      errorMessage: "Unable to load notifications.",
    },
  };
}

function updateNotifications(notifications) {
  return {
    type: UPDATE_NOTIFICATIONS,
    payload: {
      notifications: notifications.data,
    },
  };
}

export function fetchNotification(notificationId) {
  return {
    type: FETCH_NOTIFICATION,
    meta: {
      endpoint: `/account/notifications/${notificationId}`,
      success: updateNotification,
      errorMessage: "Unable to load notification.",
    },
  };
}

function updateNotification(notification) {
  return {
    type: UPDATE_NOTIFICATION,
    payload: {
      notification: notification.data,
    },
  };
}

export function clearActiveNotification() {
  return {
    type: CLEAR_ACTIVE_NOTIFICATION,
  };
}

export function fetchMarkReadNotification(notificationId) {
  return {
    type: FETCH_MARK_READ_NOTIFICATION,
    meta: {
      method: "POST",
      endpoint: `/account/notifications/${notificationId}/mark-read`,
      success: updateMarkReadNotification,
      errorMessage: "Unable to mark notification as read.",
    },
    payload: {
      notificationId: notificationId,
    },
  };
}

function updateMarkReadNotification(response, payload) {
  return {
    type: UPDATE_MARK_READ_NOTIFICATION,
    payload: {
      notificationId: payload.notificationId,
    },
  };
}

export function fetchMarkUnreadNotification(notificationId) {
  return {
    type: FETCH_MARK_UNREAD_NOTIFICATION,
    meta: {
      method: "POST",
      endpoint: `/account/notifications/${notificationId}/mark-unread`,
      success: updateMarkUnreadNotification,
      errorMessage: "Unable to mark notification as unread.",
    },
    payload: {
      notificationId: notificationId,
    },
  };
}

function updateMarkUnreadNotification(response, payload) {
  return {
    type: UPDATE_MARK_UNREAD_NOTIFICATION,
    payload: {
      notificationId: payload.notificationId,
    },
  };
}

export function fetchMarkAllRead() {
  return {
    type: FETCH_MARK_ALL_READ_NOTIFICATION,
    meta: {
      method: "PUT",
      endpoint: "/account/notifications",
      body: {
        markRead: true,
      },
      success: updateMarkAllRead,
      errorMessage: "Unable to mark notifications as read.",
    },
  };
}

function updateMarkAllRead() {
  return {
    type: UPDATE_MARK_ALL_READ_NOTIFICATION,
  };
}

import React from "react";
import DashboardWidgetComponent from "./DashboardWidgetComponent";

export default class NoWidgets extends DashboardWidgetComponent {
  static widgetId = "reposit.NoWidgets";
  static widgetName = "Welcome";
  static description = "";
  static validPermissions = ["none"];

  componentDidMount = () => {
    this.setTitle("New Dashboard - No Widgets");
  };

  render() {
    return (
      <div
        style={{
          textAlign: "center",
          padding: "1em",
          width: "100%",
        }}
      >
        <p>Hi, it looks like you haven't added any widgets to your dashboard yet.</p>
        <p>Start by clicking the "Add Widget" button.</p>
        <p>Each dashboard can be customised per user and your changes save automatically.</p>
        <p>Once you add your first widget this message will disappear.</p>
      </div>
    );
  }
}

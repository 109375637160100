import React, { Component } from "react";
import { Link } from "react-router-dom";
import { TARIFF_TYPES } from "../../components/customers/Tariff";
import "./TariffListTable.css";

class TariffListTable extends Component {
  handleOnRowClick = tariff => {
    this.props.history.push(`/customers/plans/${encodeURIComponent(tariff.planCode)}`);
  };

  render() {
    let tariffs = this.props.tariffs;

    if (!tariffs || tariffs.length === 0) {
      return <div className="fleet-table-no-results">No tariffs have been found.</div>;
    }

    return (
      <table>
        <thead>
          <tr>
            <th>Name</th>
            <th>Network</th>
            <th>Plan Type</th>
            <th>Customers</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {tariffs.map(tariff => {
            return (
              <tr role="button" onClick={() => this.handleOnRowClick(tariff)}>
                <td>
                  <Link to={`/customers/plans/${encodeURIComponent(tariff.planCode)}`}>
                    {tariff.name}
                  </Link>
                  <p>{tariff.planCode}</p>
                </td>
                <td>{tariff.operationalNetwork}</td>
                <td>{TARIFF_TYPES[tariff.type] ? TARIFF_TYPES[tariff.type] : tariff.type}</td>
                <td>{tariff.customersCount}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  }
}

export default TariffListTable;

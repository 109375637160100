import React, { Component } from "react";
import { Textarea, Checkbox } from "../widgets";
import "./PowerstationDeploymentsSearch.css";

class PowerstationDeploymentsSearch extends Component {
  render() {
    return (
      <div className="powerstation-deployment-search">
        <div className="text-search">
          <Textarea
            placeholder="NMI(s), Serial number or address..."
            onChange={e => this.props.onDeploymentFilterChange(e.target.value)}
            value={this.props.deploymentFilter}
            containerStyle={{ flex: 5 }}
            style={{
              width: "95%",
              margin: "5px",
              height: "2.38rem",
            }}
            autoFocus={this.props.autoFocus !== undefined ? this.props.autoFocus : true}
            tooltipId="powerstations.detail.deploymentListSearch"
          />
        </div>
        {this.props.showIncludedNodesCheckbox ? (
          <div className="included-only">
            <div>Show included nodes only?</div>
            <Checkbox
              checked={this.props.showIncludedOnly}
              onChange={this.props.onIncludedOnlyChange}
            />
          </div>
        ) : null}
      </div>
    );
  }
}

export default PowerstationDeploymentsSearch;

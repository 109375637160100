export const NETWORK_CONNECTIONS = {
  ETHERNET: "Ethernet",
  WIFI: "Wifi",
  "4G": "4G Mobile",
};

export const NETWORK_CONNECTION_OPTIONS = Object.entries(NETWORK_CONNECTIONS).map(([val, lab]) => {
  return {
    value: val,
    label: lab,
  };
});

export const GRID_PHASE_OPTIONS = [
  {
    value: 1,
    label: "Single Phase",
  },
  {
    value: 2,
    label: "Two Phase",
  },
  {
    value: 3,
    label: "Three Phase",
  },
];

export const SOLAR_PHASE_OPTIONS = [
  {
    value: 1,
    label: "Single Phase",
  },
  {
    value: 3,
    label: "Three Phase",
  },
];

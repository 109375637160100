import React, { Component } from "react";
import "./KitDesignModal.css";
import { Modal } from "../layout/Modal";
import OrderSummaryModalContent from "./OrderSummaryModalContent";
import SiteDetailsModalContent from "./SiteDetailsModalContent";
import ConfigureInvertersModalContent from "./ConfigureInvertersModalContent";

const MODALS = {
  SITE_DETAILS: "SITE_DETAILS",
  CONFIGURE_INVERTERS: "CONFIGURE_INVERTERS",
  SUMMARY: "SUMMARY",
};

class KitDesignModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      context: MODALS.SITE_DETAILS,
      existingInverterCount: 0,
    };
  }

  static getDerivedStateFromProps = (props, state) => {
    let updates = {};

    const totalNumInverters = Object.keys(props.design.inverterSystems).length;
    if (totalNumInverters < state.existingInverterCount) {
      updates.existingInverterCount = totalNumInverters;
    }

    if (props.order) {
      updates.context = MODALS.SUMMARY;
    }

    return Object.keys(updates).length ? updates : null;
  };

  handleGridPhasesChanged = numPhases => {
    this.props.onGridPhasesChanged(numPhases);
  };

  handleNumInvertersChanged = (existingSystems, newSystems) => {
    this.setState({
      existingInverterCount: existingSystems,
    });
    this.props.onNumInverterSystemsChanged(existingSystems + newSystems);
  };

  handleInverterChanged = (uid, inverter) => {
    this.props.onInverterChanged(uid, inverter);
  };

  handlePvChanged = (uid, connected) => {
    this.props.onPvChanged(uid, connected);
  };

  handlePvPhasesChanged = (uid, phPhases) => {
    this.props.onPvPhasesChanged(uid, phPhases);
  };

  handleBatteryChanged = (uid, battery) => {
    this.props.onBatteryChange(uid, battery);
  };

  handleInverterSystemAdded = () => {
    this.setState({ inverterSubsystemUid: null });
    this.props.onInverterSystemAdded();
  };

  handleMultipleInverterSystemsAdded = (isSupportedInverter, numberOfInverters) => {
    this.setState({ inverterSubsystemUid: null });
    this.props.onMultipleInverterSystemsAdded(isSupportedInverter, numberOfInverters);
  };

  handleInverterSystemRemoved = uid => {
    this.props.onInverterSystemRemoved(uid);
  };

  handleSiteDetailsSubmitted = () => {
    this.setState({
      context: MODALS.CONFIGURE_INVERTERS,
    });
  };

  handleConfigureInvertersCancel = () => {
    this.setState({
      context: MODALS.SITE_DETAILS,
    });
  };

  handleClose = () => {
    this.props.onClose();
  };

  handleSubmitDesign = () => {
    this.props.onSubmitDesign();
  };

  scrubOrder = () => {
    this.setState({
      context: MODALS.CONFIGURE_INVERTERS,
    });
    this.props.scrubOrder();
  };

  handleEmailKit = referenceComment => {
    this.props.onSubmitOrder(referenceComment);
  };

  render() {
    const { context, existingInverterCount } = this.state;

    const {
      inverterTypes,
      batteryTypes,
      design: { gridPhases, inverterSystems },
      order,
      constraintsLoading,
      kitLoading,
      emailed,
    } = this.props;

    const newInvertersCount = Object.keys(inverterSystems).length - existingInverterCount;

    let content;

    switch (context) {
      case MODALS.SITE_DETAILS:
        content = (
          <SiteDetailsModalContent
            loading={kitLoading}
            gridPhases={gridPhases}
            onGridPhasesChanged={this.handleGridPhasesChanged}
            existingInvertersCount={existingInverterCount}
            newInvertersCount={newInvertersCount}
            onNumInvertersChanged={this.handleNumInvertersChanged}
            onNext={this.handleSiteDetailsSubmitted}
            onCancel={this.handleClose}
          />
        );
        break;
      case MODALS.CONFIGURE_INVERTERS:
        content = (
          <ConfigureInvertersModalContent
            kitLoading={kitLoading}
            constraintsLoading={constraintsLoading}
            inverterTypes={inverterTypes}
            batteryTypes={batteryTypes}
            inverterSystems={inverterSystems}
            onInverterSystemAdded={this.handleInverterSystemAdded}
            onInverterSystemRemoved={this.handleInverterSystemRemoved}
            onInverterChanged={this.handleInverterChanged}
            onPvChanged={this.handlePvChanged}
            onPvPhasesChanged={this.handlePvPhasesChanged}
            onBatteryChanged={this.handleBatteryChanged}
            onBack={this.handleConfigureInvertersCancel}
            onNext={this.handleSubmitDesign}
          />
        );
        break;
      case MODALS.SUMMARY:
        content = (
          <OrderSummaryModalContent
            loading={kitLoading}
            emailed={emailed}
            order={order}
            gridPhases={gridPhases}
            onBack={this.scrubOrder}
            onEmailKit={this.handleEmailKit}
            onFinished={this.handleClose}
          />
        );
        break;
      default:
        content = <p>Unable to load Kit Builder</p>;
    }

    return <Modal className="kit-design-modal">{content}</Modal>;
  }
}

export default KitDesignModal;

import React from "react";
import { Loading } from "../widgets";
import "./StatsPanel.css";

const listLayout = stats => (
  <ul>
    {stats.map(stat => (
      <li>
        <label>{stat.label}</label>
        <span className="value">{stat.value}</span>
      </li>
    ))}
  </ul>
);

const gridLayout = stats => (
  <div className="stats-grid">
    {stats.map(stat => (
      <div>
        <div className="value">{stat.value}</div>
        <label>{stat.label}</label>
      </div>
    ))}
  </div>
);

class StatsPanel extends React.Component {
  render() {
    return (
      <div className={`stats-panel ${this.props.color}`}>
        <header>
          <h3>{this.props.title}</h3>
        </header>
        {this.props.loading ? (
          <div className="stats-grid loading">
            <Loading />
          </div>
        ) : this.props.display === "grid" ? (
          gridLayout(this.props.stats)
        ) : (
          listLayout(this.props.stats)
        )}
      </div>
    );
  }
}

export default StatsPanel;

import { Notify } from "../../utils/notificationManager";
import { accessTokenItemName, mfaRequired } from "../actions/user";

const fetchMiddleware = store => next => action => {
  next(action);
  let options;
  if (action.meta && action.meta.endpoint) {
    const accessToken = action.meta.accessToken
      ? action.meta.accessToken
      : localStorage.getItem(accessTokenItemName);

    let headers;
    if (action.meta.headers !== undefined) {
      headers = action.meta.headers;
    } else {
      headers = {
        "Content-Type": "application/json",
      };
    }

    let endpoint;
    if (action.meta.endpoint.startsWith("http")) {
      endpoint = action.meta.endpoint;
    } else if (action.meta.noAuthorizationHeader) {
      endpoint = process.env.REACT_APP_MARKETPLACE_API_URL + action.meta.endpoint;
    } else {
      endpoint = process.env.REACT_APP_MARKETPLACE_API_URL + action.meta.endpoint;
      if (headers.Authorization === undefined) {
        headers.Authorization = "Bearer " + accessToken;
      }
    }

    switch (action.meta.method) {
      case "PUT":
      case "PATCH":
      case "POST":
        options = {
          method: action.meta.method ? action.meta.method : "POST",
          headers: headers,
        };

        if (action.meta.body && options.headers["Content-Type"] === "application/json") {
          options.body = JSON.stringify(action.meta.body);
        } else {
          options.body = action.meta.body;
        }

        break;
      default:
        options = {
          method: action.meta.method ? action.meta.method.toUpperCase() : "GET",
          headers: headers,
        };
    }

    // cater for the possibility of an AbortController
    if (action.meta.signal) {
      options.signal = action.meta.signal;
    }
    // Override any default headers with those pass in by the action.
    if (action.meta.headers) {
      const actionHeaders = action.meta.headers;
      for (let prop in actionHeaders) {
        if (options.headers[prop]) {
          options.headers[prop] = actionHeaders[prop];
        }
      }
    }

    if (!action.meta.endpoint.startsWith("http")) {
      // This enables cookies to be sent with fetch requests if we are making default requests
      // to the Fleet API.
      options.credentials = "include";
    }

    fetch(endpoint, options)
      .then(function (response) {
        if (response && response.status === 401) {
          let ex = new Error();
          ex.isBadAuth = true;
          if (localStorage.getItem(accessTokenItemName)) {
            ex.badAuthType = "SESSION_EXPIRED";
          } else {
            ex.badAuthType = "NOT_AUTHENTICATED";
          }
          ex.response = response;
          throw ex;
        } else if (response && response.status === 403) {
          Notify.warning(
            "Not Authorized",
            "You do not have the required permissions to perform this action."
          );
          let ex = new Error();
          ex.notAuthorized = true;
          throw ex;
          // 400 will get treated as a success as usually it will return something sensible to further action
        } else if (response.status >= 402 && response.status < 600) {
          let ex = new Error("Bad response from server");
          ex.response = response;
          throw ex;
        } else if (response.status === 204) {
          // No Content
          return null;
        }
        return response.json();
      })
      .then(function (json) {
        if (action.meta.successMessage && json.status !== "ERROR") {
          Notify.success(action.meta.successMessage);
        }
        if (action.meta.success) {
          const successResult = action.meta.success(json, action.payload);
          // Check to see if the result has a type attribute, if it does then
          // assume its a Redux action and dispatch it.
          if (successResult && successResult.type) {
            store.dispatch(successResult);
          }
        }
      })
      .catch(function (ex) {
        if (ex.isBadAuth) {
          // We can fully override the auth error handling by defining an authErrorOverride
          // method - this is useful for when bad auth is raised such as during a password reset
          // that requires the current users password.
          if (action.meta.authErrorOverride) {
            const errorResult = action.meta.authErrorOverride(action.payload, ex.response);
            // Check to see if the result has a type attribute, if it does then
            // assume its a Redux action and dispatch it.
            if (errorResult && errorResult.type) {
              store.dispatch(errorResult);
            }
            return;
          }
          ex.response.json().then(function (json) {
            // In the case of an MFA failure we don't want to remove the access token and reset
            // everything as the initial user/password part of the auth process is OK.
            if (json.error !== "mfa_failure") {
              next({ type: "RESET_STORE" });
              if (ex.badAuthType === "SESSION_EXPIRED") {
                next({ type: "SESSION_EXPIRED" });
              }
              localStorage.removeItem(accessTokenItemName);
            } else {
              // Otherwise, this is an MFA error so we need to take the relevant MFA action.
              const mfaAction = mfaRequired(json);
              next(mfaAction);
            }
            if (action.meta.authError) {
              const errorResult = action.meta.authError(action.payload, ex.response);
              // Check to see if the result has a type attribute, if it does then
              // assume it's a Redux action and dispatch it.
              if (errorResult && errorResult.type) {
                store.dispatch(errorResult);
              }
            }
          });
          if (action.meta.authErrorMessage) {
            Notify.error(action.meta.authErrorMessage);
          }
          return;
        }
        if (ex.notAuthorized) {
          return;
        }
        if (action.meta.errorMessage) {
          Notify.error(action.meta.errorMessage, "Please try again later.");
        }
        if (action.meta.error) {
          const errorResult = action.meta.error(action.payload, ex.response);
          // Check to see if the result has a type attribute, if it does then
          // assume its a Redux action and dispatch it.
          if (errorResult && errorResult.type) {
            store.dispatch(errorResult);
          }
        }
        console.error(ex);
        return ex;
      })
      .then(function (ex) {
        if (action.meta.finally) {
          const finalResult = action.meta.finally(action.payload);
          // Check to see if the result has a type attribute, if it does then
          // assume its a Redux action and dispatch it.
          if (finalResult && finalResult.type) {
            store.dispatch(finalResult);
          }
        }
      });
  }
};

export { fetchMiddleware };

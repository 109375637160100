import * as actions from "../actions/gridoutage";

const gridOutageInitialState = {
  inProgress: null,
  completed: null,
  activeGridOutage: null,
  newGridOutage: null,
  loading: false,
};

const gridOutageReducer = (state = gridOutageInitialState, action) => {
  switch (action.type) {
    case "RESET_STORE":
      return {
        ...gridOutageInitialState,
      };
    case actions.UPDATE_GRID_OUTAGES:
      return {
        ...state,
        outages: action.payload.outages,
        loading: false,
      };
    case actions.UPDATE_GRID_OUTAGE_POWERSTATIONS:
      return {
        ...state,
        powerstations: action.payload.powerstations,
      };
    case actions.FETCH_GRID_OUTAGE_RESPONSE:
      return {
        ...state,
      };
    case actions.UPDATE_GRID_OUTAGE_RESPONSE:
      return {
        ...state,
        activeGridOutage: {
          ...state.activeGridOutage,
          response: action.payload.response,
        },
      };
    case actions.UPDATE_ACTIVE_GRID_OUTAGE:
      return {
        ...state,
        activeGridOutage: {
          ...state.activeGridOutage,
          ...action.payload.outage,
        },
      };

    case actions.EXECUTE_GRID_OUTAGE:
      return {
        ...state,
        loading: true,
      };
    case actions.CLEAR_ACTIVE_GRID_OUTAGE:
      return {
        ...state,
        activeGridOutage: null,
      };
    default:
      return state;
  }
};

export default gridOutageReducer;

import React, { Component } from "react";
import { SelectDropdown, InfoBox, Input } from "../widgets";
import "./NewCurtailmentSetpoint.css";

class NewCurtailmentSetpoint extends Component {
  render() {
    if (!this.props.powerstations) {
      return (
        <InfoBox
          type="warning"
          message="You must create a Virtual Power Plant before you can set a default setpoint."
        />
      );
    }
    return (
      <div className="new-setpoint-info">
        <div>
          Add a new default setpoint for
          <SelectDropdown
            placeholder="Virtual Power Plant"
            value={this.props.powerstation}
            onChange={val => this.props.handlePowerstationChange(val)}
            options={this.props.powerstations.map(p => {
              return { label: p.name, value: p.id };
            })}
            tooltipTitle="Virtual Power Plant"
            tooltipContent="Select a Virtual Power Plant you wish to set a default setpoint for."
          />
          {this.props.powerstation ? (
            <>
              <div className="user-form">
                <div className="user-form-row">
                  <label className="user-name">
                    <span className="label-text">Ramp Duration</span>
                    <div className="widget">
                      <Input
                        value={this.props.rampDuration}
                        type="number"
                        onChange={e => this.props.onChange("rampDuration", e.target.value)}
                      />
                    </div>
                  </label>
                </div>
                <div className="user-form-row">
                  <label className="email">
                    <span className="label-text">Gross VPP Limit (kW)</span>
                    <div className="widget">
                      <Input
                        value={this.props.gridLimit}
                        type="number"
                        onChange={e => this.props.handleLimitChange("gridLimit", e.target.value)}
                      />
                    </div>
                  </label>
                  <label className="user-name">
                    <span className="label-text">Net VPP Limit (kW)</span>
                    <div className="widget">
                      <Input
                        value={this.props.solarLimit}
                        type="number"
                        onChange={e => this.props.handleLimitChange("solarLimit", e.target.value)}
                      />
                    </div>
                  </label>
                </div>
              </div>
            </>
          ) : null}
        </div>
      </div>
    );
  }
}

export default NewCurtailmentSetpoint;

import * as actions from "../actions/billing";

const gridCreditsInitialState = {
  events: null,
  billing: null,
};

const billingReducer = (state = gridCreditsInitialState, action) => {
  switch (action.type) {
    case "RESET_STORE":
      return {
        ...gridCreditsInitialState,
      };
    case actions.UPDATE_GRID_CREDITS_EVENTS:
      return {
        ...state,
        events: action.payload.events,
      };
    case actions.UPDATE_CUSTOMER_COUNT_HISTORY:
      return {
        ...state,
        customerCount: action.payload.customerCount,
      };
    case actions.UPDATE_GRID_CREDITS_SUMMARY:
      return {
        ...state,
        billingSummary: action.payload.billingSummary,
      };
    default:
      return state;
  }
};

export default billingReducer;

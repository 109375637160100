import React, { Component } from "react";
import { FormattedNumber } from "react-intl";
import { Tooltip } from "../../components/widgets";
import "./NewDispatchPricing.css";

class NewDispatchPricing extends Component {
  render() {
    return (
      <div className="new-dispatch-pricing">
        <div>
          <h4>
            cost
            <Tooltip id="dispatches.new.buyPrice" />
          </h4>
          <div className="price">
            <FormattedNumber
              style="currency" // eslint-disable-line
              currency="AUD"
              maximumFractionDigits={2}
              value={Math.abs(this.props.buyPrice)}
            />
          </div>
        </div>
        {this.props.showMarketData ? (
          <div>
            <h4>
              sell price
              <Tooltip id="dispatches.new.sellPrice" />
            </h4>
            <div className="price">
              {this.props.sellPrice ? (
                <FormattedNumber
                  style="currency" // eslint-disable-line
                  currency="AUD"
                  maximumFractionDigits={2}
                  value={Math.abs(this.props.sellPrice)}
                />
              ) : (
                "-"
              )}
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}

export default NewDispatchPricing;

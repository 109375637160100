import React, { Component } from "react";
import { VictoryArea, VictoryAxis, VictoryLabel } from "victory";
import { getDateAsString, getTimeAsString } from "../widgets/Moment";
import { Tooltip } from "../widgets";
import { durationToString } from "../../utils/formatting";
import "./CurtailmentResponseGraph.css";

const PADDING_PCT = 0.22;
const DEFAULT_GRAPH_MIN = -5;
const DEFAULT_GRAPH_MAX = 5;

class CurtailmentResponseGraph extends Component {
  constructor(props) {
    super(props);

    this.state = {
      graphWidth: null,
      graphHeight: null,
    };
  }

  getGraphData = (prediction, response, startTime, duration) => {
    const tickSpacing = 1800;
    const endTime = startTime + duration;

    let timestampTicks = {};
    let highlightedTimestampTicks = {};
    let zebraTicks = {};

    let startTimestamp = startTime;
    if (response && response.solarPower) {
      startTimestamp = Number(Object.keys(response.solarPower)[0]);
    } else if (prediction && prediction.solarPower) {
      startTimestamp = Number(Object.keys(prediction.solarPower)[0]);
    }

    const padding = startTime - startTimestamp;
    const endTimestamp = startTime + duration + padding;

    let timestamp = startTimestamp;
    const timezone = this.props.timezone;
    let counter = 0;
    while (timestamp <= endTimestamp) {
      // A a vertical line.
      timestampTicks[timestamp * 1000] = { timestamp: timestamp * 1000, display: "" };

      // Zebra striped lines occur every other line.
      if (counter % 2 === 1) {
        zebraTicks[(timestamp - 900) * 1000] = { timestamp: timestamp * 1000, display: "" };
      }

      timestamp += tickSpacing;
      counter++;
    }

    // Set up a highlighted vertical line for the start time of the curtailment.
    highlightedTimestampTicks[startTime * 1000] = { timestamp: startTime * 1000, display: "" };
    const jsStartDate = new Date(startTime * 1000);
    const startDateStr = getDateAsString(jsStartDate, timezone);
    const startTimeStr = getTimeAsString(jsStartDate, timezone);
    timestampTicks[startTime * 1000] = {
      timestamp: startTime * 1000,
      display: startDateStr + "\n" + startTimeStr,
      anchor: duration < 3600 ? "end" : "middle",
    };

    // Set up a highlighted vertical line for the end time of the curtailment.
    highlightedTimestampTicks[endTime * 1000] = { timestamp: endTime * 1000, display: "" };
    const jsEndDate = new Date(endTime * 1000);
    const endTimeStr = getTimeAsString(jsEndDate, timezone);
    timestampTicks[endTime * 1000] = {
      timestamp: endTime * 1000,
      display: durationToString(duration) + "\n" + endTimeStr,
      anchor: duration < 3600 ? "start" : "middle",
    };

    let solarResponseData,
      latestSolarResponseTimestamp = null,
      latestSolarResponse = null;
    if (response && response.solarPower) {
      solarResponseData = [];
      let solarResponse = response.solarPower;

      Object.keys(solarResponse)
        .sort()
        .forEach(function (k) {
          const timestamp = parseInt(k, 10);
          solarResponseData.push({
            x: timestamp * 1000,
            y: solarResponse[k],
            timestamp: timestamp * 1000,
          });
          latestSolarResponseTimestamp = timestamp;
          latestSolarResponse = solarResponse[k];
        });
    }

    let meterResponseData,
      latestMeterResponseTimestamp = null,
      latestMeterResponse = null;
    if (response && response.meterPower) {
      meterResponseData = [];
      let meterResponse = response.meterPower;

      Object.keys(meterResponse)
        .sort()
        .forEach(function (k) {
          const timestamp = parseInt(k, 10);
          meterResponseData.push({
            x: timestamp * 1000,
            y: meterResponse[k],
            timestamp: timestamp * 1000,
          });
          latestMeterResponseTimestamp = timestamp;
          latestMeterResponse = meterResponse[k];
        });
    }

    let solarPredictionData;
    if (prediction && prediction.solarPower) {
      solarPredictionData = [];
      let solarPrediction = prediction.solarPower;

      Object.keys(solarPrediction)
        .sort()
        .forEach(function (k) {
          const timestamp = parseInt(k, 10);

          if (latestSolarResponseTimestamp === null || latestSolarResponseTimestamp <= timestamp) {
            solarPredictionData.push({
              x: timestamp * 1000,
              y: solarPrediction[k],
              timestamp: timestamp * 1000,
            });
          }

          if (
            latestSolarResponseTimestamp > timestamp &&
            latestSolarResponseTimestamp < timestamp + 1800
          ) {
            solarPredictionData.push({
              x: latestSolarResponseTimestamp * 1000,
              y: latestSolarResponse,
              timestamp: latestSolarResponseTimestamp * 1000,
            });
          }
        });
    }

    let meterPredictionData;
    if (prediction && prediction.meterPower) {
      meterPredictionData = [];
      let batteryPrediction = prediction.meterPower;

      Object.keys(batteryPrediction)
        .sort()
        .forEach(function (k) {
          const timestamp = parseInt(k, 10);

          if (latestMeterResponseTimestamp === null || latestMeterResponseTimestamp <= timestamp) {
            meterPredictionData.push({
              x: timestamp * 1000,
              y: batteryPrediction[k],
              timestamp: timestamp * 1000,
            });
          }

          if (
            latestMeterResponseTimestamp > timestamp &&
            latestMeterResponseTimestamp < timestamp + 1800
          ) {
            meterPredictionData.push({
              x: latestMeterResponseTimestamp * 1000,
              y: latestMeterResponse,
              timestamp: latestMeterResponseTimestamp * 1000,
            });
          }
        });
    }
    return {
      solarResponseData: solarResponseData,
      meterResponseData: meterResponseData,
      solarPredictionData: solarPredictionData,
      meterPredictionData: meterPredictionData,
      tickDisplay: timestampTicks,
      highlightedTickDisplay: highlightedTimestampTicks,
      startTimestamp: startTimestamp,
      endTimestamp: endTimestamp,
      zebraTickDisplay: zebraTicks,
    };
  };

  registerGraphContainer = graphContainer => {
    if (graphContainer) {
      this.setState({
        graphWidth: graphContainer.clientWidth,
        graphHeight: graphContainer.clientHeight,
      });
    }
  };

  getDefaultMinMax = prediction => {
    let min = DEFAULT_GRAPH_MIN;
    let max = DEFAULT_GRAPH_MAX;

    let solarPrediction;
    if (prediction && prediction.solarPower) {
      solarPrediction = prediction.solarPower;
      Object.keys(solarPrediction)
        .sort()
        .forEach(function (k) {
          min = solarPrediction[k] < min ? solarPrediction[k] : min;
          max = solarPrediction[k] > max ? solarPrediction[k] : max;
        });
    }

    let meterPrediction;
    if (prediction && prediction.meterPower) {
      meterPrediction = prediction.meterPower;
      Object.keys(meterPrediction)
        .sort()
        .forEach(function (k) {
          min = meterPrediction[k] < min ? meterPrediction[k] : min;
          max = meterPrediction[k] > max ? meterPrediction[k] : max;
        });
    }
    return [min, max];
  };

  getDomain = (response, prediction, startTimestamp, endTimestamp) => {
    let [min, max] = this.getDefaultMinMax(prediction);

    let solarResponse;
    if (response && response.solarPower) {
      solarResponse = response.solarPower;
      Object.keys(solarResponse)
        .sort()
        .forEach(function (k) {
          min = solarResponse[k] < min ? solarResponse[k] : min;
          max = solarResponse[k] > max ? solarResponse[k] : max;
        });
    }

    let meterResponse;
    if (response && response.meterPower) {
      meterResponse = response.meterPower;
      Object.keys(meterResponse)
        .sort()
        .forEach(function (k) {
          min = meterResponse[k] < min ? meterResponse[k] : min;
          max = meterResponse[k] > max ? meterResponse[k] : max;
        });
    }

    min -= Math.abs(min * PADDING_PCT);
    max += Math.abs(max * PADDING_PCT);

    return {
      x: [startTimestamp * 1000, endTimestamp * 1000],
      y: [min, max],
    };
  };

  render() {
    const { response, startTime, duration, prediction } = this.props;
    const {
      meterResponseData,
      solarResponseData,
      solarPredictionData,
      meterPredictionData,
      zebraTickDisplay,
      tickDisplay,
      highlightedTickDisplay,
      startTimestamp,
      endTimestamp,
    } = this.getGraphData(prediction, response, startTime, duration);

    const width = this.state.graphWidth;
    const height = this.state.graphHeight;
    const bottomPadding = 18;
    const padding = { top: 0, right: 0, bottom: 0, left: 0 };

    const domain = this.getDomain(response, prediction, startTimestamp, endTimestamp);
    return (
      <div className="curtailment-response-graph">
        <div className="legend-container">
          <div className="legend">
            <span className="key-style grid-response" />
            <span className="key-label">Grid</span>
          </div>
          <div className="legend">
            <span className="key-style solar-response" />
            <span className="key-label">Solar</span>
          </div>
          <div className="legend">
            <span className="key-style meter-prediction" />
            <span className="key-label">Grid Prediction</span>
          </div>
          <div className="legend">
            <span className="key-style solar-prediction" />
            <span className="key-label">Solar Prediction</span>
          </div>
          <Tooltip medium id="curtailments.graph.legend" />
        </div>
        <div className="graph-container" ref={this.registerGraphContainer}>
          {this.state.graphWidth && (
            <svg
              width={width}
              height={height + bottomPadding}
              role="img"
              aria-labelledby="undefined-title undefined-desc"
              viewBox={`0 0 ${width} ${height + bottomPadding}`}
              overflow="visible"
              style={{
                width: `${width}px`,
                height: `${height + bottomPadding}px`,
                userSelect: "none",
                touchAction: "none",
                fill: "#ffffff",
              }}
            >
              <rect
                x="0"
                y="0"
                fill="#fff"
                style={{
                  width: "calc(100%)",
                  height: `calc(100% - ${bottomPadding}px)`,
                  stroke: "#E8E8E9",
                  strokeWidth: 1,
                }}
              />

              <g transform="translate(0, 0)">
                {/* x-axis thick grid lines/zebra striping */}
                <VictoryAxis
                  width={width}
                  height={height}
                  padding={padding}
                  domain={domain}
                  style={{
                    axis: { stroke: "none" },
                    grid: {
                      stroke: "rgba(219, 226, 233, 0.3)",
                      strokeWidth: width / (Object.keys(tickDisplay).length - 1),
                    },
                  }}
                  orientation="bottom"
                  tickValues={Object.keys(zebraTickDisplay).map(Number)}
                  tickFormat={() => ""}
                  standalone={false}
                />

                {/* x-axis labels */}
                <VictoryAxis
                  width={width}
                  height={height + bottomPadding * 2}
                  padding={padding}
                  domain={domain}
                  style={{
                    axis: { stroke: "none" },
                    grid: { stroke: "none" },
                    tickLabels: {
                      fontFamily: '"Work Sans", "Helvetica Neue", Helvetica, Arial, sans-serif',
                      fill: "#4698cb",
                      fontSize: "12",
                    },
                  }}
                  offsetY={42}
                  orientation="bottom"
                  tickValues={Object.keys(tickDisplay).map(Number)}
                  tickFormat={tick => tickDisplay[tick + ""].display}
                  standalone={false}
                />

                {/* y-axis grid */}
                <VictoryAxis
                  width={width}
                  height={height}
                  padding={0}
                  dependentAxis
                  domain={domain}
                  crossAxis={false}
                  style={{
                    axis: { stroke: "#E8E8E9" },
                    grid: { stroke: "#E8E8E9" },
                  }}
                  orientation="left"
                  tickLabelComponent={<g />}
                  standalone={false}
                />

                {/* x-axis blue lines */}
                <VictoryAxis
                  width={width}
                  height={height}
                  padding={padding}
                  domain={domain}
                  style={{
                    axis: { stroke: "none" },
                    grid: { stroke: "#4698cb" },
                  }}
                  orientation="bottom"
                  tickValues={Object.keys(highlightedTickDisplay).map(Number)}
                  tickFormat={() => ""}
                  standalone={false}
                />
                {meterResponseData && (
                  <VictoryArea
                    width={width}
                    height={height}
                    padding={padding}
                    data={meterResponseData}
                    domain={domain}
                    style={{
                      data: {
                        stroke: "#4698cb",
                        strokeWidth: 2,
                        fill: "#4698cb",
                        fillOpacity: 0.2,
                      },
                    }}
                    standalone={false}
                  />
                )}

                {meterPredictionData && (
                  <VictoryArea
                    width={width}
                    height={height}
                    padding={padding}
                    data={meterPredictionData}
                    x={meterPredictionData => meterPredictionData.x}
                    y={meterPredictionData => meterPredictionData.y}
                    domain={domain}
                    interpolation="stepAfter"
                    style={{
                      data: {
                        stroke: "#aaaaaa",
                        strokeWidth: 2,
                        strokeOpacity: 0.6,
                        strokeDasharray: "3, 2",
                        fill: "rgba(170, 170, 170, 0.6)",
                        fillOpacity: 0.1,
                      },
                    }}
                    standalone={false}
                  />
                )}

                {solarResponseData && (
                  <VictoryArea
                    width={width}
                    height={height}
                    padding={padding}
                    data={solarResponseData}
                    domain={domain}
                    style={{
                      data: {
                        stroke: "#26D07C",
                        strokeWidth: 2,
                        fill: "#26D07C",
                        fillOpacity: 0.2,
                      },
                    }}
                    standalone={false}
                  />
                )}

                {solarPredictionData && (
                  <VictoryArea
                    width={width}
                    height={height}
                    padding={padding}
                    data={solarPredictionData}
                    domain={domain}
                    interpolation="stepAfter"
                    style={{
                      data: {
                        stroke: "#26D07C",
                        strokeWidth: 2,
                        strokeOpacity: 0.6,
                        strokeDasharray: "3, 2",
                        fill: "#F68D26",
                        fillOpacity: 0.1,
                      },
                    }}
                    standalone={false}
                  />
                )}

                {/* y-axis labels */}
                <VictoryAxis
                  width={width}
                  height={height}
                  padding={padding}
                  dependentAxis
                  domain={domain}
                  crossAxis={false}
                  style={{
                    axis: { stroke: "none" },
                  }}
                  orientation="right"
                  offsetX={18}
                  tickLabelComponent={
                    <VictoryLabel
                      style={{
                        fontFamily: '"Work Sans", "Helvetica Neue", Helvetica, Arial, sans-serif',
                        fontSize: "14",
                        fill: "#616161",
                        fontWeight: 500,
                      }}
                      textAnchor="end"
                    />
                  }
                  tickFormat={datum => (datum ? datum : 0) + " kW"}
                  standalone={false}
                />
              </g>
            </svg>
          )}
        </div>
      </div>
    );
  }
}
export default CurtailmentResponseGraph;

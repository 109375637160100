import React from "react";
import { Loading, Tooltip, InfoBox } from "../widgets";
import { FormattedNumber } from "react-intl";

const DispatchOverviewPricing = ({ dispatch, dispatchCost, showMarketData }) => {
  if (!dispatchCost) {
    return (
      <div className="prices-loading">
        <Loading />
      </div>
    );
  }

  if (dispatchCost.type !== "DISPATCH_NOT_STARTED") {
    return (
      <>
        <div className={"capacity cost" + (dispatch.state === "INPROGRESS" ? " inprogress" : "")}>
          {dispatchCost.type === "SETTLED" && dispatchCost.cost < 1.0 ? (
            <InfoBox type="warning">
              Not all data has been received or settled for this dispatch yet -{" "}
              {Math.round((1.0 - dispatchCost.coverage) * 100)}% of nodes are not included. The
              settled price below may increase as the remaining data is collected and settled. This
              usually occurs because a node is offline or stops responding.
            </InfoBox>
          ) : null}

          {dispatchCost.buyPrice !== null ? (
            <div className="value">
              <FormattedNumber
                style="currency" // eslint-disable-line
                currency="AUD"
                maximumFractionDigits={2}
                value={dispatchCost.buyPrice}
              />
            </div>
          ) : null}

          {dispatch.state === "INPROGRESS" ? (
            <div className="label">
              current cost (estimated) <Tooltip id="dispatches.detail.currentCost" />
            </div>
          ) : dispatchCost.type === "ESTIMATE" ? (
            <div className="label">
              estimated cost <Tooltip id="dispatches.detail.estimatedCost" />
            </div>
          ) : dispatchCost.buyPrice ? (
            <div className="label">
              settled cost <Tooltip id="dispatches.detail.settledCost" />
            </div>
          ) : null}
        </div>

        {showMarketData ? (
          <div className="capacity market-price">
            <div className="value">
              <FormattedNumber
                style="currency" // eslint-disable-line
                currency="AUD"
                maximumFractionDigits={2}
                value={dispatchCost.sellPrice}
              />
            </div>
            <div className="label">
              sell price (estimated) <Tooltip id="dispatches.detail.sellPrice" />
            </div>
          </div>
        ) : null}
      </>
    );
  } else {
    return null;
  }
};

export default DispatchOverviewPricing;

export const OPEN_CONFIRM = "OPEN_CONFIRM";
export const CLOSE_CONFIRM = "CLOSE_CONFIRM";

export function openConfirm(resolve, reject, message, id) {
  window.fleetConfirm = { resolve: resolve, reject: reject };

  return {
    type: OPEN_CONFIRM,
    payload: {
      message: message,
      id: id,
    },
  };
}

export function closeConfirm() {
  window.fleetConfirm = undefined;

  return {
    type: CLOSE_CONFIRM,
  };
}

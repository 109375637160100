import React, { Component } from "react";
import { SelectDropdown, InfoBox, DatePicker, TimePicker, Tooltip } from "../widgets";
import "./NewCurtailment.css";
import moment from "moment-timezone";
import CurtailmentControlDropDown from "./CurtailmentControlDropDown";

const CURTAILMENT_COMPONENT_OPTIONS = [
  {
    value: "grid",
    label: "net",
  },
  {
    value: "solar",
    label: "gross",
  },
];

class NewCurtailment extends Component {
  handleStartDateChange = startDate => {
    let nextDate = this.props.startDateTime.clone();
    nextDate.date(startDate.date());
    nextDate.month(startDate.month());
    nextDate.year(startDate.year());
    nextDate.tz(this.props.timezone);

    this.props.handleStartDateTimeChange(nextDate);
  };

  handleStartTimeChange = nextStartDateTime => {
    this.props.handleStartDateTimeChange(nextStartDateTime);
  };

  handleEndTimeChange = nextEndDateTime => {
    const duration = moment.duration(nextEndDateTime.diff(this.props.startDateTime));

    if (duration < 0) {
      return;
    }

    this.props.handleDurationChange(duration.asSeconds());
  };

  render() {
    if (!this.props.powerstations && this.props.allowPowerstationEdit) {
      return (
        <InfoBox
          type="warning"
          message="You must create a Virtual Power Plant before you can curtail."
        />
      );
    }

    const endDateTime = this.props.startDateTime.clone();
    endDateTime.add(this.props.duration, "seconds");
    return (
      <>
        <div className="new-curtailment-info">
          {this.props.allowPowerstationEdit || this.props.readOnly ? (
            <div>
              Add a new
              <SelectDropdown
                placeholder="gross"
                readOnly={this.props.readOnly}
                value={this.props.component}
                onChange={val => this.props.onComponentChange(val)}
                options={CURTAILMENT_COMPONENT_OPTIONS}
                tooltipTitle="Component"
                tooltipContent="A net curtailment will curtail power from the grid, while gross will curtail solar power."
              />
              curtailment for
              <SelectDropdown
                readOnly={this.props.readOnly}
                placeholder="Virtual Power Plant"
                value={this.props.powerstation}
                onChange={val => this.props.onPowerstationChange(val)}
                options={this.props.powerstations.map(p => {
                  return { label: p.name, value: p.id };
                })}
                tooltipTitle="Virtual Power Plant"
                tooltipContent="The Virtual Power Plant you wish to curtail. Whilst a VPP can contain a mix of dispatchable and non-dispatchable systems only those that are dispatchable will have this event applied. All predictions and estimates here only include those systems that are dispatchable."
              />
            </div>
          ) : null}
          {this.props.powerstation && this.props.curtailableVPP ? (
            <>
              <div>
                from
                <DatePicker
                  readOnly={this.props.readOnly}
                  placeholder="start date"
                  value={this.props.startDateTime.clone()}
                  onChange={this.handleStartDateChange}
                  minDate={moment()}
                  dateFormat="d MMMM"
                  asInline
                />
                at
                <TimePicker
                  readOnly={this.props.readOnly}
                  placeholder="start time"
                  dateTime={this.props.startDateTime}
                  onChange={this.handleStartTimeChange}
                  timezone={this.props.timezone}
                  minuteStep={5}
                  asInline
                  showNow
                />
                until
                <TimePicker
                  readOnly={this.props.readOnly}
                  placeholder="end time"
                  dateTime={endDateTime}
                  offsetFromDateTime={this.props.startDateTime}
                  onChange={this.handleEndTimeChange}
                  timezone={this.props.timezone}
                  minuteStep={5}
                  asInline
                />
                to
                <CurtailmentControlDropDown
                  readOnly={this.props.readOnly}
                  onChangeRealPowerP={this.props.onChangeRealPowerP}
                  realPowerP={this.props.realPowerP}
                  realPowerPMin={this.props.constraints}
                />
                <Tooltip large id="curtailments.new.action" />
              </div>
            </>
          ) : this.props.powerstation && !this.props.curtailableVPP ? (
            <InfoBox
              type="warning"
              message="The selected Virtual Power Plant has no curtailable nodes."
            />
          ) : null}
        </div>
      </>
    );
  }
}

export default NewCurtailment;

import React, { Component } from "react";
import { ConnectedRouter } from "react-router-redux";
import { Route, Switch, Redirect } from "react-router";
import { PropsRoute } from "./utils";
import LoginLayout from "../layouts/LoginLayout";
import NavLayout from "../layouts/NavLayout";
import NoSidebarLayout from "../layouts/NoSidebarLayout";
import SidebarLayout from "../layouts/SidebarLayout";
import LoginRequired from "../containers/LoginRequired";

import Dashboard from "../containers/dashboard/Dashboard";

import PowerstationListContainer from "../containers/powerstations/PowerstationsListContainer";
import PowerstationView from "../containers/powerstations/PowerstationView";
import PowerstationEdit from "../containers/powerstations/PowerstationEdit";

import BillingGridCredits from "../containers/billing/BillingGridCredits";
import BillingGridCreditsSearch from "../containers/billing/BillingGridCreditsSearch";

import FleetDeployments from "../containers/fleet/FleetDeployments";
import FleetDeployment from "../containers/fleet/FleetDeployment";

import SupportIssues from "../containers/support/SupportIssues";

import DispatchList from "../containers/trading/DispatchList";
import Dispatch from "../containers/trading/Dispatch";

import RertDispatchList from "../containers/rert/RertDispatchList";
import RertDispatch from "../containers/rert/RertDispatch";

import GridOutageList from "../containers/gridoutage/GridOutageList";
import GridOutage from "../containers/gridoutage/GridOutage";

import CurtailmentList from "../containers/curtailment/CurtailmentList";
import Curtailment from "../containers/curtailment/Curtailment";

import ForgotPasswordForm from "../containers/login/ForgotPasswordForm";
import ResetPasswordForm from "../containers/login/ResetPasswordForm";
import DisavowPasswordReset from "../containers/login/DisavowPasswordReset";

import UserSettings from "../containers/user/UserSettings";
import UserNotifications from "../containers/user/UserNotifications";
import UserNotification from "../containers/user/UserNotification";

import CustomersList from "../containers/customers/CustomersList";
import CustomerDetails from "../containers/customers/CustomerDetails";
import TariffList from "../containers/customers/TariffList";
import TariffNew from "../containers/customers/TariffNew";
import TariffEdit from "../containers/customers/TariffEdit";

import UsersList from "../containers/myreposit/users/UsersList";
import UserView from "../containers/myreposit/users/UserView";
import UserEdit from "../containers/myreposit/users/UserEdit";

import InvoicesList from "../containers/myreposit/invoices/InvoicesList";

import AnalysisExportCSV from "../containers/analysis/AnalysisExportCSV";

import NotFound from "../components/layout/NotFound";
import KitDesignPage from "../containers/ordering/KitDesignPage";

class AuthRouter extends Component {
  render() {
    return (
      <ConnectedRouter history={this.props.history}>
        <Switch>
          {/* Unauthenticated password routes - these just use LoginRequired. */}
          <Route path="/password">
            <LoginLayout>
              <Switch>
                <Route path="/password/forgot" component={ForgotPasswordForm} />
                <Route path="/password/reset" component={ResetPasswordForm} />
                <Route path="/password/reset/disavow" component={DisavowPasswordReset} />
                <Route component={NotFound} />
              </Switch>
            </LoginLayout>
          </Route>

          <LoginRequired>
            <NavLayout>
              <Switch>
                {/* Explicitly declare the NavLayout for user settings as this is non-default. */}
                <Route exact path="/user/settings">
                  <NoSidebarLayout>
                    <Route exact path="/user/settings" component={UserSettings} />
                  </NoSidebarLayout>
                </Route>

                {/* Standard routes that use the regular SidebarWithNavLayout */}
                <Route path="/">
                  <SidebarLayout>
                    <Switch>
                      <Route exact path="/" component={Dashboard} />

                      <Route
                        exact
                        path="/virtual-power-plants/manage"
                        component={PowerstationListContainer}
                      />
                      <Route
                        exact
                        path="/virtual-power-plants/manage/new"
                        component={PowerstationEdit}
                      />
                      <Route
                        exact
                        path="/virtual-power-plants/manage/:id"
                        component={PowerstationView}
                      />
                      <Route
                        exact
                        path="/virtual-power-plants/manage/:id/edit"
                        component={PowerstationEdit}
                      />

                      <Route
                        exact
                        path="/virtual-power-plants/dispatches"
                        component={DispatchList}
                      />
                      <Route path="/virtual-power-plants/dispatches/:id" component={Dispatch} />

                      <Route
                        exact
                        path="/virtual-power-plants/rert-dispatches"
                        component={RertDispatchList}
                      />
                      <Route
                        path="/virtual-power-plants/rert-dispatches/:id"
                        component={RertDispatch}
                      />

                      <Route
                        exact
                        path="/virtual-power-plants/grid-outages"
                        component={GridOutageList}
                      />
                      <Route path="/virtual-power-plants/grid-outages/:id" component={GridOutage} />

                      <Route
                        exact
                        path="/virtual-power-plants/feed-in-management"
                        component={CurtailmentList}
                      />
                      <Route
                        path="/virtual-power-plants/feed-in-management/:id"
                        component={Curtailment}
                      />

                      <Route exact path="/gridcredits/billing" component={BillingGridCredits} />

                      <Route
                        exact
                        path="/gridcredits/billing/search"
                        component={BillingGridCreditsSearch}
                      />

                      <Route
                        exact
                        path="/fleet-status"
                        render={() => <Redirect to="/fleet-monitoring" />}
                      />
                      <Route
                        path="/fleet-status/:id"
                        render={() => <Redirect to="/fleet-monitoring/nodes/:id" />}
                      />

                      <Route exact path="/fleet-monitoring/nodes" component={FleetDeployments} />
                      <Route path="/fleet-monitoring/nodes/:id" component={FleetDeployment} />

                      <Route exact path="/ordering/kit-designer" component={KitDesignPage} />

                      <Route exact path="/support-issues" component={SupportIssues} />

                      <Route
                        exact
                        path="/trading/dispatches"
                        render={() => <Redirect to="/virtual-power-plants/dispatches" />}
                      />
                      <Route
                        path="/trading/dispatches/:id"
                        render={() => <Redirect to="/virtual-power-plants/dispatches/:id" />}
                      />

                      <Route exact path="/customers/manage" component={CustomersList} />
                      <Route path="/customers/manage/:nmi" component={CustomerDetails} />
                      <Route exact path="/customers/plans" component={TariffList} />
                      <Route exact path="/customers/plans/new" component={TariffNew} />
                      <PropsRoute
                        exact
                        path="/customers/plans/:planCode"
                        component={TariffEdit}
                        tariffEditable={false}
                      />
                      <PropsRoute
                        exact
                        path="/customers/plans/:planCode/edit"
                        component={TariffEdit}
                        tariffEditable={true}
                      />

                      <Route exact path="/my-reposit/billing" component={InvoicesList} />
                      <Route exact path="/my-reposit/users" component={UsersList} />
                      <Route exact path="/my-reposit/users/new" component={UserEdit} />
                      <Route exact path="/my-reposit/users/:id" component={UserView} />
                      <Route exact path="/my-reposit/users/:id/edit" component={UserEdit} />

                      <Route exact path="/analysis/export" component={AnalysisExportCSV} />

                      <Route exact path="/user/notifications" component={UserNotifications} />
                      <Route exact path="/user/notifications/:id" component={UserNotification} />

                      <Route exact path="/index.html" component={Dashboard} />

                      <Route component={NotFound} />
                    </Switch>
                  </SidebarLayout>
                </Route>
              </Switch>
            </NavLayout>
          </LoginRequired>
        </Switch>
      </ConnectedRouter>
    );
  }
}

export default AuthRouter;

import React, { Component } from "react";
import "./GridOutageOverview.css";
import { ProgressBar, ProgressText } from "../widgets/Progress";
import { Link } from "react-router-dom";
import { getDateAsString, MomentDate } from "../widgets/Moment";
import Loading from "../widgets/Loading";
import Tooltip from "../widgets/Tooltip";

class GridOutageOverview extends Component {
  pluraliseOptedOut = nodes => {
    if (nodes > 1) {
      return `${nodes} nodes have `;
    }
    if (nodes === 0) {
      return "No nodes have ";
    }
    return `${nodes} node has `;
  };

  formatParagraphText = (props, tz) => {
    if (!props.request || !props.reason) {
      return null;
    }

    let start = new Date(props.request.startTime);
    let end = new Date(props.request.startTime + props.request.duration);
    let startDay = getDateAsString(start * 1000, tz, "D MMMM");
    let startTime = getDateAsString(start * 1000, tz, "HH:mm");
    let endTime = getDateAsString(end * 1000, tz, "HH:mm");

    let storm = "storm";

    let text = `A ${props.reason.toLowerCase()} will impact ${
      props.powerstation
    } on ${startDay} at ${startTime} until ${endTime}.`;
    if (props.reason === "Storm Approaching") {
      text = `A ${storm} will impact ${props.powerstation} on ${startDay} at ${startTime} until ${endTime}.`;
    }

    return <p className="grid-outage-overview-text">{text}</p>;
  };

  render() {
    const gridOutage = this.props.gridOutage;
    if (!gridOutage) {
      return <Loading />;
    }
    return (
      <div className="grid-outage-container">
        <div className="grid-outage-overview">
          {this.formatParagraphText(gridOutage, this.props.timezone)}
          <div className="node-count-container">
            <ProgressText
              progress={gridOutage.response.responded}
              final={gridOutage.response.total_deployments}
            />
            <ProgressBar
              progress={gridOutage.response.responded}
              final={gridOutage.response.total_deployments}
            />
            <div className="nodes-responded-container">
              <strong>Nodes responded</strong>
              <Tooltip id="gridOutages.detail" />
            </div>
          </div>
        </div>
        <div>
          <div className="opted-out-container">
            <h2>Opted-Out</h2>
            <p>
              {this.pluraliseOptedOut(gridOutage.response.total_cancelled_deployments)}opted out of
              this request{gridOutage.response.cancelled_deployments.length > 0 ? ":" : "."}
            </p>
            {(gridOutage.response.cancelled_deployments || []).map(item => {
              return (
                <div className="cancelled-node" key={item.nmi}>
                  <Link to={`/fleet-monitoring/nodes/${item.deployment_uid}`}>{item.nmi}</Link>
                  <div style={{ fontSize: "0.85em" }}>
                    <MomentDate
                      date={new Date(item.cancelled_at * 1000)}
                      timezone={this.props.timezone}
                      format="D MMMM, h:mm a"
                    />
                  </div>
                </div>
              );
            })}
          </div>
          <div className="dispatch-overview-footer">
            {gridOutage.state === "INPROGRESS" || gridOutage.state === "UPCOMING" ? (
              <span className="refresh-interval">Data will update automatically</span>
            ) : null}
            <span className="">
              All times are in <Link to="/user/settings">{this.props.timezone}</Link>
            </span>
          </div>
        </div>
      </div>
    );
  }
}

export default GridOutageOverview;

import React, { Component } from "react";

import { bindActionCreators } from "redux";
import * as analysisActions from "../../redux/actions/analysis";
import { beginCSVDataExport } from "../../redux/actions/analysis";
import * as dashboardActions from "../../redux/actions/dashboard";
import Permissions, { hasPermission } from "../../permissions";
import { Notify } from "../../utils/notificationManager";
import { connect } from "react-redux";
import { Modal } from "../../components/layout/Modal";
import { Button, Checkbox, DatePicker, InfoBox, Select } from "../../components/widgets";
import { METRICS, OPERATIONAL_TASK } from "../../utils/analysis";
import "./AnalysisDataOperationalExport.css";
import PropTypes from "prop-types";
import {
  OPERATIONAL_EXPORT_TYPE_DEPLOYMENT,
  OPERATIONAL_EXPORT_TYPE_POWERSTATION,
} from "./AnalysisExportCSV";
import DeploymentSuggestInput from "../../components/widgets/DeploymentSuggestInput";
import ExportDeploymentsTable from "../../components/analysis/ExportDeploymentsTable";
import _ from "lodash";
import * as moment from "moment";
import Loading from "../../components/widgets/Loading";

const intervalOptions = [
  {
    value: "5s",
    label: "5 seconds",
  },
  {
    value: "30s",
    label: "30 seconds",
  },
  {
    value: "1m",
    label: "1 minute",
  },
  {
    value: "5m",
    label: "5 minutes",
  },
  {
    value: "10m",
    label: "10 minutes",
  },
  {
    value: "30m",
    label: "30 minutes",
  },
  {
    value: "1h",
    label: "1 hour",
  },
  {
    value: "6h",
    label: "6 hours",
  },
  {
    value: "12h",
    label: "12 hours",
  },
  {
    value: "24h",
    label: "1 day",
  },
  {
    value: "3d",
    label: "3 days",
  },
  {
    value: "1w",
    label: "1 week",
  },
];

const mapStateToProps = state => ({
  user: state.user,
  powerstations: state.dashboard.powerstations,
  analysis: state.analysis,
});

const mapDispatchToProps = dispatch => {
  return {
    beginCSVDataExport: bindActionCreators(beginCSVDataExport, dispatch),
    dashboardActions: bindActionCreators(dashboardActions, dispatch),
    analysisActions: bindActionCreators(analysisActions, dispatch),
  };
};

class NewOperationalExport extends Component {
  static get propTypes() {
    return {
      dashboardActions: PropTypes.objectOf(PropTypes.func),
      analysisActions: PropTypes.objectOf(PropTypes.func),

      analysis: PropTypes.object,
      powerstations: PropTypes.arrayOf(PropTypes.object),
      user: PropTypes.object,

      suborgType: PropTypes.string,
      operationalExportType: PropTypes.string,
      history: PropTypes.object,
      location: PropTypes.object,

      beginCSVDataExport: PropTypes.func,
      closeModal: PropTypes.func,
    };
  }

  constructor(props) {
    super(props);
    this.state = {
      redirected: false,
      deploymentSuggestionInput: "",
      dataExport: {
        powerstationId: "",
        start: "",
        end: "",
        metrics: [],
        splitPhases: false,
        aggregateByVPP: false,
        includeSubmeters: false,
        interval: "1h",
      },
      errors: [],
    };
  }

  componentDidMount = () => {
    if (!this.props.powerstations) {
      this.props.dashboardActions.fetchPowerstations();
    }
    // Deselect any deployments that may have been selected the last time the modal was opened
    this.props.analysisActions.deselectAllExportDeployments();
    const queryParams = new URLSearchParams(window.location.search);
    const deploymentId = queryParams.get("deploymentId");
    if (deploymentId) {
      // If a deployment ID is specified, then we need to fetch its data to put in the table
      this.props.analysisActions.fetchSingleExportDeployment(deploymentId);

      // Figure out which metrics should be selected base on query params
      const selectedMetrics = METRICS.filter(m => {
        if (!this.shouldIncludeMetric(m)) {
          return false;
        }
        // Only select solar/battery/sga metrics if they are in the params
        switch (m.metric) {
          case "solar power":
            return !!queryParams.get("solar");
          case "battery power":
            return !!queryParams.get("battery");
          case "meter sga power":
            return !!queryParams.get("sga");
          default:
            return true;
        }
      }).map(m => ({
        value: m.metric,
        label: m.label,
      }));

      // Fill in dataExport with metrics and start/end dates
      this.setState({
        redirected: true,
        dataExport: {
          ...this.state.dataExport,
          start: queryParams.get("start") ? moment(queryParams.get("start")) : null,
          end: queryParams.get("end") ? moment(queryParams.get("end")) : null,
          metrics: selectedMetrics,
        },
      });
    }
    // Replace the history
    this.props.history.replace(this.props.location.pathname);
  };

  validateExport = () => {
    let exportErrors = {};
    if (
      this.props.operationalExportType === OPERATIONAL_EXPORT_TYPE_POWERSTATION &&
      !this.state.dataExport.powerstationId
    ) {
      exportErrors.powerstationId = "Please select a powerstation";
    }

    if (
      this.props.operationalExportType === OPERATIONAL_EXPORT_TYPE_DEPLOYMENT &&
      _.isEmpty(this.props.analysis.selectedDeployments)
    ) {
      exportErrors.deployments = "Please search for and select at least one deployment";
    }

    if (!this.state.dataExport.start) {
      exportErrors.start = "Please select a start time";
    }

    if (!this.state.dataExport.end) {
      exportErrors.end = "Please select an end time";
    }

    if (this.state.dataExport.metrics.length === 0) {
      exportErrors.metrics = "Please select at least one metric";
    }

    if (this.state.dataExport.start > this.state.dataExport.end) {
      exportErrors.start = "Start date must be before end date";
    }

    if (this.state.dataExport.splitPhases && this.state.dataExport.aggregateByVPP) {
      exportErrors.noPhaseAndAgg =
        "Unable to aggregate by Virtual Power Plant if splitting by phase";
    }
    return exportErrors;
  };

  saveChanges = () => {
    const newExportErrors = this.validateExport();

    this.setState({
      errors: newExportErrors,
    });

    if (Object.keys(newExportErrors).length > 0) {
      console.warn(newExportErrors);
      Notify.warning("Data Export Errors", "Please correct your errors before submitting.");
    } else {
      // all clear
      const { start, end } = this.state.dataExport;
      const startTs = start.tz(this.props.user.account.timezone).unix();
      const endTs = end.tz(this.props.user.account.timezone).unix();

      let jsonBody = {
        type: OPERATIONAL_TASK,
        params: {
          start: startTs,
          end: endTs,
          queries: this.state.dataExport.metrics.map(function (elem) {
            return elem.value;
          }),
          splitPhases: this.state.dataExport.splitPhases,
          aggByPowerstation: this.state.dataExport.aggregateByVPP,
          includeSubmeters: this.state.dataExport.includeSubmeters,
          interval: this.state.dataExport.interval,
          user: this.props.user.account.id,
          timezone: this.props.user.account.timezone,
        },
      };

      if (this.props.operationalExportType === OPERATIONAL_EXPORT_TYPE_POWERSTATION) {
        jsonBody.params.powerstation = this.state.dataExport.powerstationId;
      } else if (this.props.operationalExportType === OPERATIONAL_EXPORT_TYPE_DEPLOYMENT) {
        jsonBody.params.deploymentUids = this.props.analysis.selectedDeployments.map(x => x.id);
      }

      this.props.beginCSVDataExport(jsonBody, this.props.user.account.id);
      this.props.closeModal(true);
    }
  };

  handleExportFieldChange = (fieldName, value) => {
    this.setState({
      dataExport: {
        ...this.state.dataExport,
        [fieldName]: value,
      },
    });
  };

  shouldIncludeMetric = metric => {
    return (
      (metric.metric === "meter sga power" &&
        hasPermission(this.props.user.permissions, Permissions.fleet.node.sga_data.view)) ||
      (metric.metric !== "meter sga power" &&
        (this.props.suborgType === "NETWORK" ? metric.network : metric.retailer))
    );
  };

  allMetrics = () => {
    this.setState({
      dataExport: {
        ...this.state.dataExport,
        metrics: METRICS.filter(m => this.shouldIncludeMetric(m)).map(m => ({
          value: m.metric,
          label: m.label,
        })),
      },
    });
  };

  componentWillUpdate(nextProps, nextState) {
    if (
      this.state.dataExport.aggregateByVPP !== nextState.dataExport.aggregateByVPP &&
      nextState.dataExport.splitPhases
    ) {
      this.setState({
        dataExport: {
          ...this.state.dataExport,
          aggregateByVPP: false,
        },
        errors: {
          ...this.state.errors,
          noPhaseAndAgg: "Unable to aggregate by Virtual Power Plant if splitting by phase",
        },
      });
    } else if (
      this.state.dataExport.splitPhases !== nextState.dataExport.splitPhases &&
      nextState.dataExport.aggregateByVPP
    ) {
      this.setState({
        dataExport: {
          ...this.state.dataExport,
          splitPhases: false,
        },
        errors: {
          ...this.state.errors,
          noPhaseAndAgg: "Unable to aggregate by Virtual Power Plant if splitting by phase",
        },
      });
    } else {
      if (
        this.state.dataExport.splitPhases !== nextState.dataExport.splitPhases ||
        this.state.dataExport.aggregateByVPP !== nextState.dataExport.aggregateByVPP
      ) {
        this.setState({
          dataExport: {
            ...this.state.dataExport,
            splitPhases: nextState.dataExport.splitPhases,
            aggregateByVPP: nextState.dataExport.aggregateByVPP,
          },
          errors: {
            ...this.state.errors,
            noPhaseAndAgg: null,
          },
        });
      }
    }
  }

  selectDeployment = deployment => {
    this.setState({
      deploymentSuggestionInput: "",
    });
    this.props.analysisActions.selectExportDeployment(deployment);
  };

  deselectDeployment = deployment => {
    this.props.analysisActions.deselectExportDeployment(deployment);
  };

  clearAllSelectedDeployments = () => {
    this.setState({ dataExport: { ...this.state.dataExport, deployments: [] } });
  };

  render() {
    const metricsOptions = METRICS.filter(m => this.shouldIncludeMetric(m)).map(m => ({
      value: m.metric,
      label: m.label,
    }));

    let powerstationOptions = [];
    for (let i in this.props.powerstations) {
      powerstationOptions.push({
        value: this.props.powerstations[i].id,
        label: this.props.powerstations[i].name,
      });
    }
    return (
      <Modal>
        <Modal.Header title="New Operational Data Export" />
        <Modal.Content>
          <InfoBox>
            Creating this data export will submit a job to download your specified data as a CSV.
            Depending on the date range selected this can may take several minutes to complete.
          </InfoBox>
          <div className="new-operational-export-modal-contents">
            {this.props.operationalExportType === OPERATIONAL_EXPORT_TYPE_POWERSTATION ? (
              <>
                <span className="label-text">Virtual Power Plant</span>
                <Select
                  options={powerstationOptions}
                  onChange={powerstation => {
                    if (powerstation) {
                      this.handleExportFieldChange("powerstationId", powerstation.value);
                    }
                  }}
                  value={this.state.dataExport.powerstationId}
                  error={this.state.errors.powerstationId}
                />
              </>
            ) : (
              <>
                <span className="label-text">Nodes</span>
                <DeploymentSuggestInput
                  value={this.state.deploymentSuggestionInput}
                  onChange={value => this.setState({ deploymentSuggestionInput: value })}
                  deploymentSuggestions={this.props.analysis.deploymentSuggestions}
                  fetchSuggestions={value =>
                    this.props.analysisActions.fetchDeploymentSuggestions(value)
                  }
                  clearSuggestions={() => analysisActions.clearDeploymentSuggestions()}
                  onSuggestionSelected={deployment => this.selectDeployment(deployment)}
                  error={this.state.errors.deployments}
                />
                {this.props.analysis.loadingSuggestions && <Loading horizontal />}
                {this.props.analysis.loadingSelectedDeployment ? (
                  <Loading horizontal />
                ) : (
                  <ExportDeploymentsTable
                    deployments={this.props.analysis.selectedDeployments}
                    onRemoveDeployment={deployment => this.deselectDeployment(deployment)}
                  />
                )}
              </>
            )}
            <div className="new-export-dropdown">
              <span className="label-text">Start</span>
              <div className="widget">
                <DatePicker
                  withInput
                  dateFormat="dd/MM/yyyy"
                  readOnly={false}
                  value={this.state.dataExport.start}
                  onChange={date => this.handleExportFieldChange("start", date)}
                  placeholderText="DD/MM/YYYY"
                  timezone={this.props.user.account.timezone}
                  error={this.state.errors.start}
                />
              </div>
            </div>
            <div className="new-export-dropdown">
              <span className="label-text">End</span>
              <div className="widget">
                <DatePicker
                  withInput
                  dateFormat="dd/MM/yyyy"
                  readOnly={false}
                  value={this.state.dataExport.end}
                  onChange={date => this.handleExportFieldChange("end", date)}
                  placeholderText="DD/MM/YYYY"
                  timezone={this.props.user.account.timezone}
                  error={this.state.errors.end}
                />
              </div>
            </div>
            <div className="new-export-dropdown">
              <span className="label-text">Metrics</span>
              <Select
                options={metricsOptions}
                onChange={metrics => this.handleExportFieldChange("metrics", metrics)}
                value={this.state.dataExport.metrics}
                multi={true}
                className={this.state.multipleSelected ? "multiple-selected" : null}
                error={this.state.errors.metrics}
              />
            </div>
            <span className="select-all">
              <button className="mp-button link" onClick={this.allMetrics}>
                Select All Metrics
              </button>
            </span>
            <div className="new-export-dropdown">
              <span className="label-text">Downsampling Interval</span>
              <Select
                options={intervalOptions}
                onChange={interval =>
                  this.handleExportFieldChange("interval", interval ? interval.value || "5m" : "5m")
                }
                value={this.state.dataExport.interval}
              />
            </div>
            <Checkbox
              checked={this.state.dataExport.splitPhases}
              onChange={event => this.handleExportFieldChange("splitPhases", event.target.checked)}
              label="Split by Phase"
              tooltipId="analysis.new.operational.splitByPhase"
            />
            {this.props.operationalExportType === OPERATIONAL_EXPORT_TYPE_POWERSTATION && (
              <Checkbox
                checked={this.state.dataExport.aggregateByVPP}
                onChange={event =>
                  this.handleExportFieldChange("aggregateByVPP", event.target.checked)
                }
                error={this.state.errors.noPhaseAndAgg}
                label="Aggregate by Virtual Power Plant"
                tooltipId="analysis.new.operational.aggregateByVPP"
              />
            )}
            <Checkbox
              checked={this.state.dataExport.includeSubmeters}
              onChange={event =>
                this.handleExportFieldChange("includeSubmeters", event.target.checked)
              }
              label="Include Submeter Data"
              tooltipId="analysis.new.operational.includeSubmeters"
            />
          </div>
        </Modal.Content>
        <Modal.Footer>
          <Button
            id="new-export-modal-cancel"
            type="secondary"
            onClick={() =>
              // If the user has been redirected here (like from the Node Details page)
              // then send them back there instead of closing the modal
              this.state.redirected ? this.props.history.goBack() : this.props.closeModal()
            }
            icon="times"
          >
            Cancel
          </Button>
          <Button
            id="new-export-modal-begin"
            type="primary"
            icon="check"
            onClick={this.saveChanges}
          >
            Begin Export
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(NewOperationalExport);

import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import UserMenu from "../../components/layout/UserMenu";
import NotificationBell from "../../components/layout/notification_bell.svg";
import NotificationUnreadAlert from "../../components/layout/notification_unread_alert.svg";
import * as notificationActions from "../../redux/actions/notifications";
import "./Header.css";

const FIVE_MINUTES = 300000;
const NOTIFICATION_REFRESH_INTERVAL = FIVE_MINUTES;

const mapStateToProps = state => ({
  notifications: state.notifications.notifications,
});

const mapDispatchToProps = dispatch => {
  return {
    notificationActions: bindActionCreators(notificationActions, dispatch),
  };
};

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showUserMenu: false,
    };
  }

  componentDidMount = () => {
    // Delay fetching the notifications a bit so that other, more important things load first and also set up an
    // interval to refresh them every 5 mins.
    this.notificationTimeout = setTimeout(this.props.notificationActions.fetchNotifications, 1000);
    this.notificationInterval = setInterval(
      this.props.notificationActions.fetchNotifications,
      NOTIFICATION_REFRESH_INTERVAL
    );

    // When the tab becomes visible immediately check for any new notifications.
    document.addEventListener("visibilitychange", this.onDocumentVisible);
  };

  onDocumentVisible = () => {
    // When the tab becomes visible immediately check for any new notifications.
    if (document.visibilityState === "visible") {
      this.props.notificationActions.fetchNotifications();
    }
  };

  componentWillUnmount = () => {
    if (this.notificationTimeout) {
      clearTimeout(this.notificationTimeout);
    }

    if (this.notificationInterval) {
      clearInterval(this.notificationInterval);
    }

    document.removeEventListener("visibilitychange", this.onDocumentVisible, false);
  };

  toggleUserMenu = event => {
    this.setState({
      showUserMenu: !this.state.showUserMenu,
    });
  };

  hideUserMenu = () => {
    this.setState({
      showUserMenu: false,
    });
  };

  render() {
    // Fix up this value when we actually implement notifications and everything should magically
    // work.
    const unreadCount = this.props.notifications
      ? this.props.notifications.filter(notification => !notification.read).length
      : 0;

    return (
      <header className="header">
        <div className="header-content">
          <Link className="notification-container" to="/user/notifications">
            <div className="notification-bell-container">
              <img
                src={NotificationBell}
                className="notification-bell"
                alt="Notification indicator"
              />
              {unreadCount > 0 ? (
                <img
                  src={NotificationUnreadAlert}
                  className="notification-unread-alert"
                  alt="Unread notification indicator"
                />
              ) : null}
            </div>
            {unreadCount > 0 ? (
              <span className="notification-count">{unreadCount} Unread</span>
            ) : null}
          </Link>
          <div
            className="user-container"
            tabIndex="0"
            onClick={this.toggleUserMenu}
            onBlur={this.hideUserMenu}
          >
            <span className="username">{this.props.userFullName}</span>
            {this.state.showUserMenu ? (
              <UserMenu
                userFullName={this.props.userFullName}
                handleLogout={this.props.handleLogout}
                orgName={this.props.orgName}
              />
            ) : null}
          </div>
        </div>
      </header>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Header);

import React, { Component } from "react";
import "./StrategyDropDown.css";

const STRATEGY_DISPLAY = {
  greedy: "greedily",
  uniform: "uniformly",
};

class StrategyDropDown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showStrategyControls: false,
    };
  }

  handleOnSelectedClick = () => {
    this.setState(prevState => ({
      showStrategyControls: !prevState.showStrategyControls,
    }));
  };

  hideStrategyControls = e => {
    // only change state if focus is moving outside dropdown
    if (!this.dropdownNode.contains(e.relatedTarget)) {
      this.setState({
        showStrategyControls: false,
      });
    }
  };

  render() {
    const { strategy, onStrategyChange, readOnly } = this.props;

    if (readOnly) {
      return (
        <div className="strategy-controls-drop-down ro">
          <span className="current-value">{STRATEGY_DISPLAY[strategy]}</span>
        </div>
      );
    }

    return (
      <div
        className="strategy-controls-drop-down"
        tabIndex="0"
        onBlur={this.hideStrategyControls}
        ref={ref => (this.dropdownNode = ref)}
      >
        <span className="current-value" onClick={this.handleOnSelectedClick}>
          {STRATEGY_DISPLAY[strategy]}
          <i className="fa fa-angle-down" />
        </span>
        {this.state.showStrategyControls ? (
          <div className="strategy-controls-container">
            <div className="tip" />
            <div className="strategy-controls-wrapper">
              <div className="strategy-controls">
                <div className="content">
                  <div className="strategy-container">
                    <label htmlFor="strategy-greedy" className="strategy-label">
                      <input
                        id="strategy-greedy"
                        name="strategy"
                        type="radio"
                        value="greedy"
                        checked={strategy === "greedy"}
                        onChange={e => onStrategyChange("greedy")}
                      />
                      Greedy
                    </label>
                    <label htmlFor="strategy-uniform" className="strategy-label">
                      <input
                        id="strategy-uniform"
                        name="strategy"
                        type="radio"
                        value="uniform"
                        checked={strategy === "uniform"}
                        onChange={e => onStrategyChange("uniform")}
                      />
                      Uniform
                    </label>
                  </div>
                  <div className="strategy-explanation">
                    <h2>Strategy</h2>
                    <p>
                      This determines how a node will behave if the requested power can't be
                      sustained for the full duration due to the state of charge.
                    </p>
                    <h2>Greedy</h2>
                    <p>The node will match the requested power for as long as it can.</p>
                    <h2>Uniform</h2>
                    <p>
                      The node will dispatch for the requested duration but its power may be less
                      than requested.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}

export default StrategyDropDown;

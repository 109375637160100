import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Page from "../../components/layout/Page";
import CurtailmentOverview from "../../components/curtailment/CurtailmentOverview";
import { Loading } from "../../components/widgets";
import * as feedInManagementActions from "../../redux/actions/feedinmanagement";
import Permissions from "../../permissions";
import { User } from "../smartWidgets";
import DeploymentList from "../smartWidgets/DeploymentList";
import AuthRequired from "../AuthRequired";
import Button from "../../components/widgets/Button";
import { confirm } from "../../utils/dialogs";

const mapStateToProps = state => ({
  curtailment: state.feedInManagement.activeCurtailment,
  response: state.feedInManagement.activeCurtailmentResponse,
  prediction: state.feedInManagement.activeCurtailmentPrediction,
  powerstations: state.feedInManagement.powerstations,
  user: state.user,
});

const mapDispatchToProps = dispatch => {
  return {
    feedInManagementActions: bindActionCreators(feedInManagementActions, dispatch),
  };
};

class Curtailment extends Component {
  constructor(props) {
    super(props);

    this.state = {
      curtailmentId: this.props.match.params.id,
      curtailmentCreatedAt: 0,
      curtailmentStartTime: 0,
      curtailmentEndTime: 0,
    };
  }

  refreshTimeouts = {};

  componentDidMount = () => {
    this.fetchCurtailment();
    if (!this.props.powerstations) {
      this.props.feedInManagementActions.fetchPowerstations();
    }

    if (this.props.curtailment) {
      this.fetchPrediction();
      this.fetchResponse();
    }
  };

  componentWillReceiveProps = nextProps => {
    if (!this.props.curtailment && nextProps.curtailment) {
      this.setState(
        {
          curtailmentCreatedAt: nextProps.curtailment.createdAt,
          curtailmentStartTime: nextProps.curtailment.request.startTime,
          curtailmentEndTime:
            nextProps.curtailment.request.startTime + nextProps.curtailment.request.duration,
        },
        () => {
          this.fetchPrediction();
          this.fetchResponse();
          this.fetchCurtailment();
        }
      );
    } else if (
      this.props.curtailment &&
      nextProps.curtailment &&
      this.props.curtailment !== nextProps.curtailment
    ) {
      this.setState({
        curtailmentCreatedAt: nextProps.curtailment.createdAt,
        curtailmentStartTime: nextProps.curtailment.request.startTime,
        curtailmentEndTime:
          nextProps.curtailment.request.startTime + nextProps.curtailment.request.duration,
      });
    } else if (nextProps.curtailment) {
      if (this.props.prediction !== nextProps.prediction) {
        this.setupRefreshTimeout("prediction", this.fetchPrediction);
      }

      if (this.props.response !== nextProps.response) {
        this.setupRefreshTimeout("response", this.fetchResponse);
      }
    }

    if (this.props.curtailment !== nextProps.curtailment) {
      this.setupRefreshTimeout("curtailment", this.fetchCurtailment);
    }
  };

  fetchCurtailment = () => {
    this.props.feedInManagementActions.fetchActiveCurtailment(this.state.curtailmentId);
  };

  fetchPrediction = () => {
    this.props.feedInManagementActions.fetchCurtailmentPrediction(this.state.curtailmentId);
  };

  fetchResponse = () => {
    this.props.feedInManagementActions.fetchCurtailmentResponse(this.state.curtailmentId);
  };

  componentWillUnmount = () => {
    for (let id in this.refreshTimeouts) {
      clearTimeout(this.refreshTimeouts[id]);
    }
    this.props.feedInManagementActions.clearActiveCurtailment();
  };

  setupRefreshTimeout = (timeoutId, callback) => {
    if (this.refreshTimeouts[timeoutId]) {
      clearTimeout(this.refreshTimeouts[timeoutId]);
    }

    const nowTs = Math.round(new Date().valueOf() / 1000);
    const secondsSinceCreated = nowTs - this.state.curtailmentCreatedAt;

    let curtailmentState = "NOT INPROGRESS";
    if (this.state.curtailmentStartTime <= nowTs && nowTs <= this.state.curtailmentEndTime) {
      curtailmentState = "INPROGRESS";
    }

    // If we are still on this page once a curtailment is complete, default to every 5 minutes
    let refreshInterval = 300000;
    if (secondsSinceCreated < 60) {
      // If we are within 1 minute of creating the curtailment then poll every 2 seconds to see the
      // nodes responded number clock up.
      // Most systems respond well within 60 seconds.
      refreshInterval = 2000; // 2 seconds
    } else if (curtailmentState === "INPROGRESS") {
      // 8 seconds - gives a bit of time for requests and should ensure that we get an update for
      // each value at least every 10 seconds during a dispatch. The timer starts once a network
      // request has finished.
      refreshInterval = 8000;
    }

    this.refreshTimeouts[timeoutId] = setTimeout(callback, refreshInterval);
  };

  getPowerstationName = powerstationId => {
    const powerstation = this.props.powerstations.find(p => p.id === powerstationId);
    if (powerstation) {
      return powerstation.name;
    } else {
      return "Unknown";
    }
  };

  cancelCurtailment = () => {
    confirm("curtailment-cancel", "Are you sure you want to cancel this curtailment event?")
      .then(() => {
        this.props.feedInManagementActions.cancelCurtailment(this.state.curtailmentId);
      })
      .catch(() => null);
  };

  render() {
    if (!this.props.curtailment || !this.props.powerstations) {
      return <Loading />;
    }

    const curtailment = this.props.curtailment;
    return (
      <Page key="curtailment" permission={Permissions.fleet.curtailments.any}>
        <Page.Header
          title={
            curtailment.triggered
              ? curtailment.reason
              : this.getPowerstationName(curtailment.powerstation)
          }
        >
          <AuthRequired permission={Permissions.fleet.curtailments.edit}>
            {curtailment.state === "INPROGRESS" || curtailment.state === "UPCOMING" ? (
              <Page.Header.Actions>
                <Button
                  id="cancel-curtailment"
                  type="danger"
                  icon="trash-o"
                  onClick={this.cancelCurtailment}
                >
                  Cancel
                </Button>
              </Page.Header.Actions>
            ) : null}
          </AuthRequired>
          <Page.SubHeading>
            Created by:
            <User userId={curtailment.createdBy} />
          </Page.SubHeading>
        </Page.Header>
        <Page.Content>
          <CurtailmentOverview
            curtailment={curtailment}
            prediction={this.props.prediction}
            response={this.props.response}
            timezone={this.props.user.account.timezone}
          />
          <DeploymentList
            eventId={curtailment.id}
            type="CURTAILMENT"
            history={this.props.history}
          />
        </Page.Content>
      </Page>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Curtailment);

import React, { Component } from "react";
import { Button } from "../../components/widgets";
import PasswordInstructions from "../../components/user/PasswordInstructions";
import Input from "../../components/widgets/Input";
import "./InlinePasswordChangeForm.css";

export default class InlinePasswordChangeForm extends Component {
  render() {
    const {
      status,
      oldPassword,
      newPassword,
      passwordConfirmation,
      oldPasswordError,
      newPasswordError,
      passwordConfirmationError,
      onOldPasswordChange,
      onNewPasswordChange,
      onPasswordConfirmationChange,
      onSubmit,
    } = this.props;

    const isChanging = status === "CHANGING";

    return (
      <div className="inline-password-change-container">
        <p className="inline-password-description">
          To change your password, please enter your current and new passwords below.
        </p>
        <div className="inline-password-change-box">
          <form className="inline-password-change-form" onSubmit={onSubmit}>
            <div className="inline-password-change-fields">
              <div className="field-box">
                <h6>Current Password</h6>
                <Input
                  type="password"
                  placeholder="Current Password"
                  autoComplete="off"
                  value={oldPassword}
                  onChange={onOldPasswordChange}
                  error={oldPasswordError}
                  disabled={isChanging}
                />
              </div>
              <div className="field-box">
                <h6>New Password</h6>
                <Input
                  type="password"
                  placeholder="New Password"
                  autoComplete="off"
                  value={newPassword}
                  onChange={onNewPasswordChange}
                  error={newPasswordError}
                  disabled={isChanging}
                />
              </div>
              <div className="field-box">
                <h6>Confirm New Password</h6>
                <Input
                  type="password"
                  placeholder="New Password"
                  autoComplete="off"
                  value={passwordConfirmation}
                  onChange={onPasswordConfirmationChange}
                  error={passwordConfirmationError}
                  disabled={isChanging}
                />
              </div>
            </div>
            <PasswordInstructions />
            <Button
              id="change-password-save"
              onClick={onSubmit}
              type="primary"
              icon="check"
              loading={isChanging}
            >
              Change Password
            </Button>
          </form>
        </div>
      </div>
    );
  }
}

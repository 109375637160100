import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Modal } from "../../components/layout/Modal";
import { Button } from "../../components/widgets";
import NewCurtailmentSetpoint from "../../components/curtailment/NewCurtailmentSetpoint";
import * as feedInManagementActions from "../../redux/actions/feedinmanagement";

const DEFAULT_RAMP_DURATION = 120;

const mapStateToProps = state => ({
  setpoints: state.feedInManagement.setpoints,
  powerstations: state.feedInManagement.powerstations,
  user: state.user,
  newCurtailmentSetpoint: state.feedInManagement.newCurtailmentSetpoint,
  executeCurtailmentSetpointLoading: state.feedInManagement.executeCurtailmentSetpointLoading,
});

const mapDispatchToProps = dispatch => {
  return {
    feedInManagementActions: bindActionCreators(feedInManagementActions, dispatch),
  };
};

class NewCurtailmenSetpointModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      powerstationId: props.powerstationId ? props.powerstationId : null,
      rampDuration: DEFAULT_RAMP_DURATION,
      gridLimit: 0,
      solarLimit: 0,
    };
  }

  handlePowerstationChange = value => {
    this.setState({
      powerstationId: value,
      realPowerP: 0,
      rampDuration: DEFAULT_RAMP_DURATION,
      gridLimit: 0,
      solarLimit: 0,
    });
  };

  onChange = (name, value) => {
    this.setState({ [name]: value });
  };

  handleLimitChange = (name, value) => {
    if (value <= 0) {
      this.setState({
        [name]: value,
      });
    }
  };

  createSetpoint = () => {
    this.props.feedInManagementActions.createSetpoint(
      this.state.powerstationId,
      this.state.rampDuration,
      this.state.gridLimit,
      this.state.solarLimit
    );
  };

  renderFooter(isLoading) {
    return (
      <>
        <Button
          id="cancel-new-setpoint-modal"
          type="secondary"
          onClick={this.props.handleOnCancel}
          icon="times"
        >
          Cancel
        </Button>
        {(this.props.powerstations || !this.state.allowPowerstationEdit) &&
        this.state.powerstationId ? (
          <>
            <div>
              All times are in <Link to="/user/settings">{this.props.user.account.timezone}</Link>
            </div>
            <Button
              id="confirm-setpoint-modal"
              type="primary"
              onClick={this.createSetpoint}
              icon="chevron-right"
              loading={isLoading}
            >
              Create Setpoint
            </Button>
          </>
        ) : null}
      </>
    );
  }

  render() {
    const isLoading = this.props.executeCurtailmentSetpointLoading;
    return (
      <Modal>
        <Modal.Header title="New Curtailment Setpoint" />
        <Modal.Content>
          <div
            style={{
              top: 0,
              left: 0,
              width: "100%",
              transition: "all 0.5s ease-in-out",
            }}
          >
            <div style={{ position: "relative" }}>
              <NewCurtailmentSetpoint
                powerstations={this.props.powerstations}
                powerstation={this.state.powerstationId}
                timezone={this.props.user.account.timezone}
                onChange={this.onChange}
                handlePowerstationChange={this.handlePowerstationChange}
                handleLimitChange={this.handleLimitChange}
                rampDuration={this.state.rampDuration}
                gridLimit={this.state.gridLimit}
                solarLimit={this.state.solarLimit}
                realPowerP={this.state.realPowerP}
                userPermissions={this.props.user.permissions}
              />
            </div>
          </div>
        </Modal.Content>
        <Modal.Footer>{this.renderFooter(isLoading)}</Modal.Footer>
      </Modal>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(NewCurtailmenSetpointModal);

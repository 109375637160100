import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import ReactGA from "react-ga";
import "./Button.css";

class Button extends Component {
  handleOnClick = event => {
    if (this.props.id) {
      ReactGA.event({
        category: "Button",
        action: "Clicked Button",
        label: this.props.id,
      });
    }

    if (this.props.to) {
      this.props.history.push(this.props.to);
    } else {
      this.props.onClick(event);
    }
  };

  render() {
    const { type, children, onClick, to, loading, disabled, id } = this.props;
    if (to && to.startsWith("http")) {
      return (
        <a className={"mp-button " + type} href={to}>
          {this.props.icon ? <span className={"fa fa-" + this.props.icon} /> : null}
          {children}
        </a>
      );
    }

    if (loading) {
      return (
        <button key={id} className={"mp-button " + type} disabled title={this.props.title}>
          <i className="fa fa-spinner fa-spin reposit-loading" ariaHidden="true" />
          <span className="loading-hidden">{children}</span>
        </button>
      );
    }

    if (disabled) {
      return (
        <button key={id} className={"mp-button " + type} disabled title={this.props.title}>
          {this.props.icon ? <span className={"fa fa-" + this.props.icon} /> : null}
          {children}
        </button>
      );
    }

    return (
      <button
        key={id}
        className={"mp-button " + type}
        onClick={onClick || to ? this.handleOnClick : null}
        style={this.props.style}
        title={this.props.title}
      >
        {this.props.icon ? <span className={"fa fa-" + this.props.icon} /> : null}
        {children}
      </button>
    );
  }
}

Button.propTypes = {
  type: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  onClick: PropTypes.func,
  to: PropTypes.string,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  style: PropTypes.object,
};

export default withRouter(Button);

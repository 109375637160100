import { history } from "../../containers/Root";
import { Notify } from "../../utils/notificationManager";
import { refetchAccount, loginError } from "./user";

export const FETCH_ALL_USERS = "FETCH_ALL_USERS";
export const UPDATE_ALL_USERS = "UPDATE_ALL_USERS";
export const FETCH_USER = "FETCH_USER";
export const UPDATE_USER = "UPDATE_USER";
export const CLEAR_ACTIVE_USER = "CLEAR_ACTIVE_USER";
export const FETCH_AVAILABLE_PERMISSIONS_FOR_ORG = "FETCH_AVAILABLE_PERMISSIONS_FOR_ORG";
export const UPDATE_AVAILABLE_PERMISSIONS_FOR_ORG = "UPDATE_AVAILABLE_PERMISSIONS_FOR_ORG";
export const FETCH_CREATE_USER = "FETCH_CREATE_USER";
export const UPDATE_CREATE_USER = "UPDATE_CREATE_USER";
export const FETCH_SAVE_USER = "FETCH_SAVE_USER";
export const UPDATE_SAVE_USER = "UPDATE_SAVE_USER";

export function fetchAllUsers() {
  return {
    type: FETCH_ALL_USERS,
    meta: {
      endpoint: "/api/users",
      success: updateAllUsers,
      errorMessage: "Failed to obtain a list of users.",
    },
  };
}

function updateAllUsers(response) {
  return {
    type: UPDATE_ALL_USERS,
    payload: {
      users: response.data,
    },
  };
}

export function fetchUser(userId) {
  return {
    type: FETCH_USER,
    meta: {
      endpoint: "/api/users/" + userId,
      success: updateUser,
      errorMessage: "Failed to obtain user information.",
    },
  };
}

function updateUser(response) {
  return {
    type: UPDATE_USER,
    payload: {
      user: response.data,
    },
  };
}

export function clearActiveUser() {
  return {
    type: CLEAR_ACTIVE_USER,
  };
}

export function fetchAvailablePermissionsForOrg(userId) {
  return {
    type: FETCH_AVAILABLE_PERMISSIONS_FOR_ORG,
    meta: {
      endpoint: "/api/users/available-permissions",
      success: updateAvailablePermissionsForOrg,
      errorMessage: "Failed to obtain a list of permissions.",
    },
  };
}

function updateAvailablePermissionsForOrg(response) {
  return {
    type: UPDATE_AVAILABLE_PERMISSIONS_FOR_ORG,
    payload: {
      permissions: response.data,
    },
  };
}

export function createUser(user) {
  return {
    type: FETCH_CREATE_USER,
    meta: {
      endpoint: "/api/users",
      method: "POST",
      body: {
        givenName: user.givenName,
        surname: user.surname,
        email: user.email,
        permissions: user.permissions,
      },
      success: updateCreateUser,
    },
  };
}

function updateCreateUser(response) {
  if (response.status === "OK") {
    history.push(`/my-reposit/users/${response.data.id}`);
    Notify.success(
      "User Created",
      `Successfully created ${response.data.givenName} ${response.data.surname}`
    );
  }

  return {
    type: UPDATE_CREATE_USER,
    payload: {
      user: response.data,
      error: response.error,
    },
  };
}

export function saveUser(userId, user, loggedInUserId) {
  return {
    type: FETCH_SAVE_USER,
    meta: {
      endpoint: `/api/users/${userId}`,
      method: "PUT",
      body: {
        givenName: user.givenName,
        surname: user.surname,
        email: user.email,
        permissions: user.permissions,
      },
      success: updateSaveUser,
    },
    payload: {
      loggedInUserId: loggedInUserId,
    },
  };
}

function updateSaveUser(response, payload) {
  if (response.status === "OK") {
    history.push(`/my-reposit/users/${response.data.id}`);
    Notify.success(
      "User Updated",
      `Successfully updated ${response.data.givenName} ${response.data.surname}`
    );
  }

  // If we successfully saved we want to refresh the users account to get their latest list of
  // permissions.
  if (response.data && response.data.id === payload.loggedInUserId) {
    return {
      type: UPDATE_SAVE_USER,
      meta: {
        endpoint: "/account",
        success: refetchAccount,
        error: loginError,
        errorMessage: "Failed to load user account details",
      },
      payload: {
        user: response.data,
        error: response.error,
      },
    };
  } else {
    return {
      type: UPDATE_SAVE_USER,
      payload: {
        user: response.data,
        error: response.error,
      },
    };
  }
}

import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Page from "../../components/layout/Page";
import { Button } from "../../components/widgets";
import UserNotificationsTable from "../../components/user/UserNotificationsTable";
import * as notificationActions from "../../redux/actions/notifications";

const mapStateToProps = state => ({
  notifications: state.notifications.notifications,
  account: state.user.account,
});

const mapDispatchToProps = dispatch => {
  return {
    notificationActions: bindActionCreators(notificationActions, dispatch),
  };
};

class UserNotifications extends Component {
  componentDidMount = () => {
    this.props.notificationActions.fetchNotifications();
  };

  markRead = notificationId => {
    this.props.notificationActions.fetchMarkReadNotification(notificationId);
  };

  sortNotifications = () => {
    let notifications = this.props.notifications;
    if (notifications) {
      notifications.sort((a, b) => {
        const aCreatedAt = a.createdAt;
        const bCreatedAt = b.createdAt;
        if (aCreatedAt > bCreatedAt) return -1;
        if (aCreatedAt < bCreatedAt) return 1;
        return 0;
      });
    }

    return notifications;
  };

  render() {
    const notifications = this.sortNotifications();
    const unreadCount = notifications
      ? notifications.filter(notification => !notification.read).length
      : 0;

    return (
      <Page>
        <Page.Header title="Notifications">
          <Page.Header.Actions>
            {unreadCount > 0 ? (
              <Button
                id="user-notifications-mark-all-as-read"
                type="secondary"
                onClick={() => this.props.notificationActions.fetchMarkAllRead()}
                icon="check"
              >
                Mark all as Read
              </Button>
            ) : null}
          </Page.Header.Actions>
        </Page.Header>
        <Page.Content>
          <UserNotificationsTable
            notifications={notifications}
            markRead={this.markRead}
            timezone={this.props.account.timezone}
            unreadCount={unreadCount}
            history={this.props.history}
          />
        </Page.Content>
      </Page>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserNotifications);

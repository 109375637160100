import React, { Component } from "react";
import { Input } from "../widgets";

class FleetDeploymentsSearch extends Component {
  render() {
    return (
      <>
        <Input
          placeholder="NMI, Serial number, Label, Battery or Address..."
          onChange={e => this.props.onFilterFieldChange(e.target.value)}
          value={this.props.filterValue ? this.props.filterValue : ""}
          containerStyle={{ flex: 5 }}
          style={{ width: "100%" }}
          autoFocus={true}
        />
      </>
    );
  }
}

export default FleetDeploymentsSearch;

import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Page from "../../components/layout/Page";
import * as customerActions from "../../redux/actions/customers";
import { applicableDaysToList } from "../../utils/tariff";
import Tariff from "../../components/customers/Tariff";
import Permissions from "../../permissions";

const mapStateToProps = state => ({
  customers: state.customers,
  user: state.user,
});

const mapDispatchToProps = dispatch => {
  return {
    customerActions: bindActionCreators(customerActions, dispatch),
  };
};

class TariffNew extends Component {
  componentDidMount = () => {
    // Refresh the tariff list for duplicate name/plan code validation.
    this.props.customerActions.fetchAllTariffs();
  };

  componentWillReceiveProps = nextProps => {
    if (
      this.props.customers.tariff.activeTariff !== nextProps.customers.tariff.activeTariff &&
      this.props.customers.tariff.activeTariff === null &&
      nextProps.customers.tariff.activeTariff
    ) {
      const planCode = nextProps.customers.tariff.activeTariff.planCode;
      this.props.history.push("/customers/plans/" + encodeURIComponent(planCode));
    }
  };

  handleSave = tariff => {
    const new_tariff = {
      name: tariff.name,
      planCode: tariff.planCode,
      description: tariff.description,
      tariffType: tariff.tariffType,
      operationalNetworkUid: tariff.operationalNetworkUid,
      startDate: tariff.startDate.format("YYYY-MM-DD"),
      resetPeriod: tariff.resetPeriod,
      dailyCharge: tariff.dailyCharge,
      feedInTariff: tariff.feedInTariff,
      rates: tariff.rates.map(r => ({
        ...r,
        periods: tariff.periods
          ? tariff.periods
              .filter(p => p.rate === r.label)
              .map(p => ({
                ...p,
                applicableDays: applicableDaysToList(p.applicableDays),
              }))
          : [],
      })),
      demandRates: tariff.demandRates
        ? tariff.demandRates.map(r => ({
            ...r,
            periods: tariff.demandPeriods
              ? tariff.demandPeriods
                  .filter(p => p.rate === r.label)
                  .map(p => ({
                    ...p,
                    applicableDays: applicableDaysToList(p.applicableDays),
                  }))
              : [],
          }))
        : [],
    };

    this.props.customerActions.fetchSaveNewTariff(new_tariff);
  };

  handleOnCancel = () => {
    this.props.history.push("/customers/plans");
  };

  render() {
    return (
      <Page permission={Permissions.fleet.customerInformation.plans.any}>
        <Tariff
          title="New Plan"
          onSave={this.handleSave}
          onCancel={this.handleOnCancel}
          isSaving={this.props.customers.tariff.savingTariff}
          fetchOperationalNetworks={this.props.customerActions.fetchOperationalNetworks}
          operationalNetworks={this.props.customers.tariff.operationalNetworks}
          tariffErrors={this.props.customers.tariff.serverErrors}
          allTariffs={this.props.customers.tariff.tariffs}
          timezone={this.props.user.account.timezone}
        />
      </Page>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TariffNew);

import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Loading } from "../../../components/widgets";
import DashboardWidgetComponent from "./DashboardWidgetComponent";
import * as dashboardActions from "../../../redux/actions/dashboard";
import { DeploymentMap } from "../../../components/widgets";
import Permissions from "../../../permissions";

const mapStateToProps = state => ({
  deployments: state.dashboard.deployments,
});

const mapDispatchToProps = dispatch => {
  return {
    dashboardActions: bindActionCreators(dashboardActions, dispatch),
  };
};

class NodeMap extends DashboardWidgetComponent {
  static widgetId = "reposit.NodeMap";
  static className = "node-map-widget";
  static widgetName = "Node Map";
  static description = "Show a map of the nodes belonging to your organisation.";
  static validPermissions = [
    Permissions.fleet.node.any,
    Permissions.fleet.customerInformation.any,
    Permissions.fleet.powerstations.any,
  ];
  static settingsConfig = [
    {
      name: "shouldCluster",
      displayName: "Do you want to cluster nodes on the map?",
      type: "checkbox",
    },
  ];

  componentDidMount = () => {
    this.setTitle("Node Map");
    if (!this.props.deployments) {
      this.props.dashboardActions.fetchDeployments();
    }
  };

  render() {
    if (!this.props.deployments) {
      return <Loading />;
    }

    return (
      <DeploymentMap
        width={this.props.width}
        height={this.props.height}
        deployments={this.props.deployments}
        showCluster={
          this.props.settings.shouldCluster === undefined
            ? false
            : this.props.settings.shouldCluster
        }
        iconSize={this.props.fullscreen ? 250 : null}
        recomputeBoundsOnUpdate
      />
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(NodeMap);

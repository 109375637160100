import React, { Component } from "react";
import { Modal } from "../layout/Modal";
import Markdown from "react-markdown";
import { getTooltipById } from "../../utils/tooltips";
import { Button } from "../widgets";

import "./MarkdownModal.css";

class MarkdownModal extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  getTitleAndContent = () => {
    let content;

    if (this.props.id) {
      const tooltip = getTooltipById(this.props.id);
      if (tooltip.content) {
        content = <Markdown source={tooltip.content} />;
      }
    }

    return content;
  };

  render() {
    return (
      <Modal style={{ width: "50em" }}>
        <Modal.Header title={this.props.title ? this.props.title : null} />
        <Modal.Content>
          <div className="markdown-modal-container">{this.getTitleAndContent()}</div>
        </Modal.Content>
        <Modal.Footer>
          <Button
            id="close-markdown-modal"
            type="secondary"
            onClick={this.props.closeModal}
            icon="times"
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default MarkdownModal;

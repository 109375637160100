import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Modal } from "../../components/layout/Modal";
import { Select, Button, Loading, Checkbox, Tooltip } from "../../components/widgets";
import * as dashboardActions from "../../redux/actions/dashboard";
import "./WidgetSettings.css";

export const ALL_POWERSTATIONS_OPTION = "__ALL_FLEET_NODES__";

const mapStateToProps = state => ({
  powerstations: state.dashboard.powerstations,
  user: state.user,
});

const mapDispatchToProps = dispatch => {
  return {
    dashboardActions: bindActionCreators(dashboardActions, dispatch),
  };
};

// Taken from https://stackoverflow.com/questions/5999998/how-can-i-check-if-a-javascript-variable-is-function-type
function isFunction(functionToCheck) {
  return functionToCheck && {}.toString.call(functionToCheck) === "[object Function]";
}

class WidgetSettings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nextSettings: {
        ...props.currentSettings,
      },
    };
  }

  componentDidMount = () => {
    const settingsConfig = this.evalSettingsConfig();
    // If this widget uses the powerstation select widget in its settings but powerstations have not
    // yet loaded then we need to load them.
    if (this.usesPowerstationSelect(settingsConfig) && !this.props.powerstations) {
      this.props.dashboardActions.fetchPowerstations();
    }
  };

  updateSetting = (name, value) => {
    const nextSettings = {
      ...this.state.nextSettings,
      [name]: value,
    };

    this.setState({
      nextSettings: nextSettings,
    });
  };

  usesPowerstationSelect = settingsConfig => {
    if (settingsConfig) {
      const powerstationSelectSettings = settingsConfig.filter(
        sc =>
          sc.type === "powerstation-select" ||
          sc.type === "powerstation-select-with-all-nodes-option"
      );
      return powerstationSelectSettings.length > 0;
    } else {
      return false;
    }
  };

  renderFormWidget = (settingsConfigItem, onChange) => {
    switch (settingsConfigItem.type) {
      case "select":
        return (
          <Select
            options={settingsConfigItem.options}
            value={this.state.nextSettings[settingsConfigItem.name]}
            onChange={option => this.updateSetting(settingsConfigItem.name, option.value)}
          />
        );

      case "powerstation-select":
      case "powerstation-select-with-all-nodes-option":
        let powerstationOptions = [];

        if (settingsConfigItem.type === "powerstation-select-with-all-nodes-option") {
          powerstationOptions.push({
            value: ALL_POWERSTATIONS_OPTION,
            label: "All Nodes in my Fleet",
          });
        }

        for (let i in this.props.powerstations) {
          powerstationOptions.push({
            value: this.props.powerstations[i].id,
            label: this.props.powerstations[i].name,
          });
        }

        return (
          <Select
            options={powerstationOptions}
            value={this.state.nextSettings[settingsConfigItem.name]}
            onChange={option => this.updateSetting(settingsConfigItem.name, option.value)}
          />
        );

      case "checkbox":
        return (
          <label className="checkbox-label" htmlFor={`ws-${settingsConfigItem.name}`}>
            <Checkbox
              id={`ws-${settingsConfigItem.name}`}
              onChange={e => this.updateSetting(settingsConfigItem.name, e.target.checked)}
              checked={
                this.state.nextSettings[settingsConfigItem.name] === undefined
                  ? false
                  : this.state.nextSettings[settingsConfigItem.name]
              }
            />{" "}
            Yes
          </label>
        );

      default:
        return null;
    }
  };

  evalSettingsConfig = () => {
    let settingsConfig = this.props.settingsConfig;
    if (settingsConfig && isFunction(settingsConfig)) {
      settingsConfig = settingsConfig(this.props.user);
    }

    return settingsConfig;
  };

  render() {
    const settingsConfig = this.evalSettingsConfig();

    // If this widget uses the powerstation select widget in its settings but powerstations have not
    // yet loaded then display the loading spinner.
    if (this.usesPowerstationSelect(settingsConfig) && this.props.powerstations === null) {
      return <Loading />;
    }

    return (
      <>
        <Modal.Content>
          {this.usesPowerstationSelect(settingsConfig) && !this.props.powerstations ? (
            <div style={{ height: "5em" }}>
              <Loading />
            </div>
          ) : (
            <div className="dashboard-widget-settings-container">
              {settingsConfig ? (
                settingsConfig.map(sc => {
                  return (
                    <div key={sc.name} className="setting">
                      <div className="display-name">
                        {sc.displayName}
                        {sc.tooltipId ? <Tooltip large id={sc.tooltipId} /> : null}
                      </div>
                      {this.renderFormWidget(sc)}
                    </div>
                  );
                })
              ) : (
                <div>No settings for this widget</div>
              )}
            </div>
          )}
        </Modal.Content>
        <Modal.Footer>
          <Button
            id="close-widget-settings-modal"
            type="secondary"
            icon="times"
            onClick={this.props.onCancel}
          >
            Cancel
          </Button>
          {settingsConfig ? (
            <Button
              id="save-widget-settings-modal"
              type="primary"
              icon="check"
              onClick={() => this.props.onSave(this.state.nextSettings)}
            >
              Save
            </Button>
          ) : null}
        </Modal.Footer>
      </>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(WidgetSettings);

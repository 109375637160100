import React, { Component } from "react";
import { Notify } from "../../utils/notificationManager";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as fleetActions from "../../redux/actions/fleet";
import * as monitoringActions from "../../redux/actions/monitoring";
import { Loading } from "../../components/widgets";
import Page from "../../components/layout/Page";
import TabBar from "../../components/layout/TabBar";
import FleetDeploymentSystemComponents from "../../components/fleet/FleetDeploymentSystemComponents";
import FleetDeploymentLocation from "../../components/fleet/FleetDeploymentLocation";
import FleetDeploymentEventLog from "../../components/fleet/FleetDeploymentEventLog";
import Permissions, { hasPermission } from "../../permissions";
import moment from "moment-timezone";
import PagingControl from "../../components/widgets/PagingControl";
import { accessTokenItemName } from "../../redux/actions/user";
import Monitoring from "../../components/monitoring/Monitoring";
import "./FleetDeployment.css";
import { getCombinedAddress } from "../../utils/formatting";
import EditableInput from "../../components/widgets/EditableInput";
import queryString from "query-string";

const mapStateToProps = state => ({
  deployment: state.fleet.activeDeployment,
  customerData: state.fleet.customerData,
  deploymentHistory: state.fleet.deploymentHistory,
  eventLogLoading: state.fleet.eventLogLoading,
  user: state.user,
  eventLogPageMeta: state.fleet.eventLogPageMeta,
  monitoring: state.monitoring,
  portalAccessToken: state.fleet.portalAccessToken,
  portalErrorMessage: state.fleet.portalErrorMessage,
  portalInfo: state.fleet.portalInfo,
});

const mapDispatchToProps = dispatch => {
  return {
    fleetActions: bindActionCreators(fleetActions, dispatch),
    monitoringActions: bindActionCreators(monitoringActions, dispatch),
  };
};

const PAGE_SIZE = 10;

const PERIOD_TO_INTERVAL_SECONDS = {
  day: 300, // 5 minutes
  week: 3600, // 1 hour
  month: 86400, // 1 day
  year: 86400, // 1 day
};

const SECONDARY_DATA_TO_TSDB_METRIC = {
  voltage: "meterVoltage",
  frequency: "meterFrequency",
  reactive_power: "meterReactivePower,solarReactivePower",
  state_of_charge: "soc",
};

class FleetDeployment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageTab: "MONITORING",
      eventsLimit: PAGE_SIZE,
      eventsOffset: 0,
      fetchingPortalCredentials: false,
      currentInverterPortalKey: null,
      portalErrorMessage: null,
    };
    this.updateEventLimitPerPage = this.updateEventLimitPerPage.bind(this);
    this.goBack = this.goBack.bind(this);
  }

  componentDidMount = () => {
    this.props.fleetActions.fetchActiveFleetDeployment(this.props.match.params.id);

    this.timer = setInterval(() => {
      this.props.fleetActions.fetchActiveFleetDeployment(this.props.match.params.id);
    }, 10000);
  };

  componentWillUnmount = () => {
    this.props.fleetActions.clearActiveFleetDeployment();
    this.props.fleetActions.clearActiveDeploymentHistory();
    clearInterval(this.timer);
    this.timer = null;
    if (this.refreshTimeout) {
      clearTimeout(this.refreshTimeout);
    }
  };

  componentWillReceiveProps = nextProps => {
    if (
      nextProps.portalErrorMessage !== this.props.portalErrorMessage &&
      nextProps.portalErrorMessage
    ) {
      Notify.error("Failed to access inverter portal", nextProps.portalErrorMessage);
    }

    if (
      nextProps.portalAccessToken !== this.props.portalAccessToken &&
      nextProps.portalAccessToken
    ) {
      const cookieName = "reposit-inverter-portal-token";
      const rpToken = localStorage.getItem(accessTokenItemName);
      const portalUrl =
        nextProps.portalInfo.externalPortalUrl +
        "?" +
        cookieName +
        "=" +
        encodeURIComponent(nextProps.portalAccessToken) +
        "&auth=Bearer " +
        rpToken;
      window.open(portalUrl);
      this.setState({ fetchingPortalCredentials: false });
    }
  };

  fetchEvents = (eventsLimit, eventsOffset) => {
    this.setState({
      eventsOffset,
    });
    this.props.fleetActions.fetchActiveDeploymentHistory(
      this.props.deployment.id,
      eventsLimit,
      eventsOffset
    );
  };

  updateEventLimitPerPage(eventsLimit) {
    this.setState({ eventsLimit });
  }

  handlePageTabOnChange = nextTab => {
    this.setState({
      pageTab: nextTab,
    });

    if (nextTab === "EVENT_LOG") {
      this.fetchEvents(this.state.eventsLimit, this.state.eventsOffset);
    }
  };

  fetchPowerData = (start, end, dateInfo) => {
    const intervalSeconds = PERIOD_TO_INTERVAL_SECONDS[dateInfo.period];
    this.props.monitoringActions.fetchNodePowerData(
      this.props.deployment.id,
      start,
      end,
      intervalSeconds,
      dateInfo.period,
      dateInfo.string,
      hasPermission(this.props.user.permissions, Permissions.fleet.node.sga_data.view)
    );
  };

  fetchSubmeterData = (start, end, dateInfo) => {
    const intervalSeconds = PERIOD_TO_INTERVAL_SECONDS[dateInfo.period];

    this.props.deployment.subMeters.forEach(submeter => {
      this.props.monitoringActions.fetchNodeSubmeterData(
        this.props.deployment.reposit.serialNumber,
        start,
        end,
        intervalSeconds,
        dateInfo.period,
        submeter.id,
        dateInfo.string
      );
    });
  };

  fetchSecondaryData = (start, end, period, dataType, dateStr) => {
    const interval = PERIOD_TO_INTERVAL_SECONDS[period];
    const metric = SECONDARY_DATA_TO_TSDB_METRIC[dataType];
    this.props.monitoringActions.fetchNodeSecondaryData(
      this.props.deployment.id,
      start,
      end,
      interval,
      period,
      metric,
      dateStr
    );
  };

  fetchSummaryData = (start, dateInfo) => {
    this.props.monitoringActions.fetchNodeSummaryData(
      this.props.deployment.id,
      start,
      dateInfo.period,
      dateInfo.string
    );
  };

  getCombinedMaxP = energySystems => {
    let maxP = 0;
    for (let idx in energySystems) {
      if (energySystems.hasOwnProperty(idx) && energySystems[idx].inverter) {
        maxP += energySystems[idx].inverter.maxPower;
      }
    }
    return `${maxP} kW`;
  };

  openDataExport = dateInfo => {
    let url = "/analysis/export";
    let params = {
      deploymentId: this.props.deployment.id,
      start: dateInfo.string,
      end: moment(dateInfo.string).add(1, dateInfo.period).format("YYYY-MM-DD"),
      solar: this.props.deployment.hasSolar,
      battery: this.props.deployment.hasBattery,
      sga: hasPermission(this.props.user.permissions, Permissions.fleet.node.sga_data.view),
    };
    this.props.history.push(`${url}?${queryString.stringify(params)}`);
  };

  clearActiveMonitoringData = () => {
    this.props.monitoringActions.clearActiveMonitoringData();
  };

  renderTabContent() {
    const deployment = this.props.deployment;
    const ping = deployment.status.ping;
    if (this.state.pageTab === "MONITORING") {
      return (
        <Monitoring
          user={this.props.user}
          statsPanels={[
            {
              title: "System Overview",
              color: "blue",
              stats: [
                {
                  label: "Status",
                  value: (
                    <div className="connectivity-container">
                      <div className={`status ${ping.connectionOffline ? "offline" : "online"}`}>
                        {ping.connectionOffline ? "Offline" : "Online"}
                      </div>
                      {ping.lastTimestamp && (
                        <div className="timestamp">
                          as of{" "}
                          {moment(new Date(ping.lastTimestamp * 1000))
                            .tz(this.props.user.account.timezone)
                            .format("D MMMM, h:mm a")}
                        </div>
                      )}
                    </div>
                  ),
                },
                {
                  label: "Reposit Serial",
                  value: deployment.reposit.serialNumber,
                },
                {
                  label: "State",
                  value:
                    deployment.reposit.state === "COMMISSIONED"
                      ? "Commissioned"
                      : "Not Commissioned",
                },
                {
                  label: "Inverter Sets",
                  value: deployment.energySystems.length,
                },
                {
                  label: "Inverter Max P",
                  value: this.getCombinedMaxP(deployment.energySystems),
                },
                { label: "NMI", value: deployment.network.nmi },
                {
                  label: "Location",
                  value: getCombinedAddress(deployment.address),
                },
              ],
            },
          ]}
          components={{
            house: true,
            grid: true,
            solar: deployment.hasSolar,
            battery: deployment.hasBattery,
            sga: hasPermission(this.props.user.permissions, Permissions.fleet.node.sga_data.view),
            splitByPhase: deployment.phases > 1,
            submeters: deployment.subMeters.map(sm => ({
              name: sm.name,
              id: sm.id,
            })),
            inverterSets: deployment.energySystems.map((es, idx) => ({
              name: `Inverter Set ${idx + 1}`,
              id: es.id,
            })),
            secondaryYAxes: [
              { label: "None", value: null },
              { label: "Grid Voltage", value: "voltage" },
              { label: "Grid Frequency", value: "frequency" },
              { label: "Grid Reactive Power", value: "reactive_power" },
              { label: "State of Charge", value: "state_of_charge" },
            ],
          }}
          allowedPeriods={{
            day: true,
            week: true,
            month: true,
            year: false, // year data takes too long to fetch at the moment.
          }}
          minDate={
            deployment.reposit.commissionedDate !== null
              ? moment(deployment.reposit.commissionedDate)
              : null
          }
          timezone={this.props.user.account.timezone}
          fetchPowerData={this.fetchPowerData}
          fetchSubmeterData={this.fetchSubmeterData}
          fetchSecondaryData={this.fetchSecondaryData}
          fetchSummaryData={this.fetchSummaryData}
          data={this.props.monitoring}
          loading={
            this.props.monitoring.loadingPowerData ||
            this.props.monitoring.loadingSecondaryData ||
            this.props.monitoring.loadingSubmeterData
          }
          loadingSummary={this.props.monitoring.loadingSummary}
          openDataExport={this.openDataExport}
          clearActiveMonitoringData={this.clearActiveMonitoringData}
          canExportData={hasPermission(
            this.props.user.permissions,
            Permissions.fleet.analysis.export.edit
          )}
        />
      );
    } else if (this.state.pageTab === "SYSTEM_COMPONENTS") {
      return (
        <>
          <FleetDeploymentSystemComponents
            deployment={this.props.deployment}
            onWebPortalButtonClick={this.onWebPortalButtonClick}
            fetchingPortalCredentials={this.state.fetchingPortalCredentials}
            inverterPortalKey={this.state.currentInverterPortalKey}
          />
        </>
      );
    } else if (this.state.pageTab === "LOCATION") {
      return (
        <>
          <FleetDeploymentLocation
            deployment={this.props.deployment}
            network={this.props.user.account === "INSTALLER" ? null : this.props.deployment.network}
          />
        </>
      );
    } else if (
      this.props.deploymentHistory === null ||
      this.props.deploymentHistory === undefined
    ) {
      return <Loading />;
    } else {
      if (this.props.eventLogLoading) {
        return <Loading />;
      }
      return (
        <>
          <FleetDeploymentEventLog
            deploymentHistory={this.props.deploymentHistory}
            timezone={this.props.user.account.timezone}
            eventLogPageMeta={this.props.eventLogPageMeta}
            fetchActiveDeploymentHistory={this.props.fleetActions.fetchActiveDeploymentHistory}
          />
          <PagingControl
            limit={this.state.eventsLimit}
            offset={this.state.eventsOffset}
            totalItems={this.props.eventLogPageMeta.count}
            itemName="event"
            onPageChange={this.fetchEvents}
            onChangeResultsPerPage={this.updateEventLimitPerPage}
            orientation="up"
          />
        </>
      );
    }
  }

  onWebPortalButtonClick = inverter => {
    this.setState({
      fetchingPortalCredentials: true,
      currentInverterPortalKey: inverter.id,
      portalErrorMessage: null,
    });
    this.props.fleetActions.getInverterPortalCredentials(this.props.deployment.id, inverter.id);
  };

  tabs = () => {
    let baseSet = [
      { label: "Monitoring", value: "MONITORING" },
      { label: "System Components", value: "SYSTEM_COMPONENTS" },
      { label: "Location", value: "LOCATION" },
      { label: "Event Log", value: "EVENT_LOG" },
    ];
    return baseSet;
  };

  goBack() {
    this.props.history.goBack();
  }

  render() {
    if (!this.props.deployment) {
      return <Loading />;
    }
    const deployment = this.props.deployment;

    return (
      <Page permission={Permissions.fleet.node.any}>
        <div className="breadcrumb">
          <button className="mp-button link" onClick={this.goBack}>
            <span className="fa fa-chevron-left back-icon" />
            <span className="breadcrumb-label">Go Back</span>
          </button>
        </div>
        <Page.Header title={`${getCombinedAddress(deployment.address)}`}>
          <div className="break" />
          <EditableInput
            value={deployment.siteLabel}
            onSaveChange={value =>
              this.props.fleetActions.saveDeploymentSiteLabel(deployment.id, value)
            }
            loading={deployment.loadingSiteLabel}
            maxLength={"100"}
          />
        </Page.Header>
        <Page.Content>
          <TabBar
            tabs={this.tabs()}
            onChange={this.handlePageTabOnChange}
            activeTab={this.state.pageTab}
            className="fleet-deployment-tabbar"
          />
          {this.renderTabContent()}
        </Page.Content>
      </Page>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FleetDeployment);

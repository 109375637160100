import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router";
import * as billingActions from "../../redux/actions/billing";
import { accessTokenItemName } from "../../redux/actions/user";
import { Button } from "../../components/widgets";
import BillingGridCreditsSummary from "../../components/billing/BillingGridCreditsSummary";
import Page from "../../components/layout/Page";
import Permissions from "../../permissions";

const mapStateToProps = state => ({
  billing: state.billing,
  user: state.user,
});

const mapDispatchToProps = dispatch => {
  return {
    billingActions: bindActionCreators(billingActions, dispatch),
  };
};

class BillingGridCredits extends Component {
  componentDidMount = () => {
    this.props.billingActions.fetchGridCreditsSummary("month");
  };

  componentWillUnmount = () => {
    this.props.billing.events = null;
  };

  constructor(props) {
    super(props);
    this.state = {
      searchTerm: "",
      searchDates: {
        from: null,
        to: null,
      },
      summary: props.billing.summary,
    };
  }

  handleFromDateChange = (fromDate, e) => {
    this.setState({
      searchDates: {
        from: fromDate,
        to: this.state.searchDates.to,
      },
    });
  };

  handleToDateChange = toDate => {
    this.setState({
      searchDates: {
        from: this.state.searchDates.from,
        to: toDate,
      },
    });
  };

  render() {
    return (
      <Page permission={Permissions.fleet.billing.gridcredits.view}>
        <Page.Header title="GridCredits® - Billing">
          <Page.Header.Actions>
            <Button
              id="goto-billing-search"
              type="primary"
              icon="search"
              to="/gridcredits/billing/search"
            >
              Search Transactions
            </Button>
          </Page.Header.Actions>
        </Page.Header>
        <Page.Content>
          <BillingGridCreditsSummary
            billingSummary={this.props.billing.billingSummary}
            timezone={this.props.user.account.timezone}
            accessToken={localStorage.getItem(accessTokenItemName)}
          />
        </Page.Content>
      </Page>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(BillingGridCredits));

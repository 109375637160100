import React, { Component } from "react";
import RescheduleRertDispatch from "./RescheduleRertDispatch";
import "./RescheduleRertDispatchConfirmation.css";

class RescheduleRertDispatchConfirmation extends Component {
  render() {
    return (
      <div className="reschedule-rert-dispatch-confirmation">
        <RescheduleRertDispatch
          readOnly
          powerstations={this.props.powerstations}
          powerstation={this.props.powerstation}
          startDateTime={this.props.startDateTime}
          duration={this.props.duration}
          timezone={this.props.timezone}
          constraints={this.props.constraints}
          realPowerP={this.props.realPowerP}
          customerAppMessage={this.props.customerAppMessage}
          strategy={this.props.strategy}
        />
        <div className="confirm-message">Are you sure you want to reschedule this dispatch?</div>
      </div>
    );
  }
}

export default RescheduleRertDispatchConfirmation;

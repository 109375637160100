import React from "react";
import Autosuggest from "react-autosuggest";
import { getAddressLine1, getAddressLine2 } from "../../utils/formatting";
import _ from "lodash";
import "./DeploymentSuggestInput.css";

function renderSuggestion(deployment, { query, isHighlighted }) {
  return (
    <div className={"deployment-suggestion-row " + (isHighlighted ? "highlighted" : "")}>
      <div className={"deployment-suggestion-column"}>
        <span>{highlightText(deployment.system.reposit.serialNumber, query)}</span>
        {deployment.siteLabel && (
          <span className="site-label-cell" title={deployment.siteLabel}>
            {highlightText(deployment.siteLabel, query)}
          </span>
        )}
      </div>
      <div className={"deployment-suggestion-column"} style={{ flex: 2 }}>
        <span>{highlightText(getAddressLine1(deployment.address), query)}</span>
        <span>{highlightText(getAddressLine2(deployment.address), query)}</span>
      </div>
      <div className={"deployment-suggestion-column"}>
        {highlightText(deployment.network.nmi, query)}
      </div>
      <div className={"deployment-suggestion-column"}>
        {!_.isEmpty(deployment.system.inverter) &&
          highlightText(deployment.system.inverter[0].brand, query)}
      </div>
      <div className={"deployment-suggestion-column"}>
        {!_.isEmpty(deployment.system.battery) &&
          highlightText(deployment.system.battery[0].brand, query)}
      </div>
    </div>
  );
}

/**
 * Puts in highlight spans within the text where the searchTerm is found
 * @param text The searched text
 * @param searchTerm The term to search for
 * @returns {JSX.Element|*} A span containing the split text if it was split, otherwise the original text
 */
const highlightText = (text, searchTerm) => {
  if (!text) {
    return text;
  }
  const splitPoint = text.toLowerCase().search(searchTerm.toLowerCase());
  if (splitPoint === -1) {
    return text;
  }

  const parts = [];
  parts.push(text.slice(0, splitPoint));
  parts.push(
    <span className="highlight">{text.slice(splitPoint, splitPoint + searchTerm.length)}</span>
  );
  parts.push(text.slice(splitPoint + searchTerm.length));
  return <span>{parts}</span>;
};

export default function DeploymentSuggestInput(props) {
  return (
    <div className="rp-widget input">
      <Autosuggest
        suggestions={props.deploymentSuggestions}
        onSuggestionsFetchRequested={({ value, reason }) => props.fetchSuggestions(value)}
        onSuggestionsClearRequested={props.clearSuggestions}
        getSuggestionValue={suggestion => suggestion.id}
        renderSuggestion={renderSuggestion}
        onSuggestionSelected={(event, { suggestion }) => props.onSuggestionSelected(suggestion)}
        inputProps={{
          value: props.value,
          placeholder: "\uF002 Search by NMI, serial number, label or address...",
          onChange: (event, { newValue }) => props.onChange(newValue),
          style: {
            fontFamily: [
              "FontAwesome",
              "Work Sans",
              "Helvetica Neue",
              "Helvetica",
              "Arial",
              "sans-serif",
            ],
            width: "100%",
          },
        }}
      />
      {props.error ? <span className="error-message">{props.error}</span> : null}
    </div>
  );
}

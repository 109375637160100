import React, { Component } from "react";
import "./Progress.css";

export class ProgressBar extends Component {
  render() {
    const progress = Math.round((this.props.progress / this.props.final) * 99) + 1;
    const divStyle = {
      width: progress + "%",
    };

    return (
      <div className="progress-bar">
        <div style={divStyle} />
      </div>
    );
  }
}

export class ProgressText extends Component {
  render() {
    return (
      <div className="progress-text progress">
        <div className="progress">{this.props.progress}</div>
        <div className="final">
          {" "}
          / {this.props.final}
          {this.props.unit ? this.props.unit : null}
        </div>
      </div>
    );
  }
}

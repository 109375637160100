import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import queryString from "query-string";
import { Loading, Button, DeploymentMap } from "../../components/widgets";
import Page from "../../components/layout/Page";
import TabbedContainer from "../../components/layout/TabbedContainer";
import { confirm } from "../../utils/dialogs";
import * as powerstationActions from "../../redux/actions/powerstations";
import * as feedInManagementActions from "../../redux/actions/feedinmanagement";
import PowerstationType from "../../components/powerstations/PowerstationType";
import PowerstationNamePlate from "../../components/powerstations/PowerstationNamePlate";
import PowerstationIncompleteEvents from "../../components/powerstations/PowerstationIncompleteEvents";
import AuthRequired from "../AuthRequired";
import Permissions, { hasPermission } from "../../permissions";
import NewDispatchModal from "../trading/NewDispatchModal";
import PowerstationDeploymentsList from "../../components/powerstations/PowerstationDeploymentsList";
import PowerstationDeploymentsSearch from "../../components/powerstations/PowerstationDeploymentsSearch";
import SetpointListTable from "../../components/curtailment/SetpointListTable";
import PagingControl from "../../components/widgets/PagingControl";

const PAGE_SIZE = 10;

const mapStateToProps = state => ({
  powerstation: state.powerstations.activePowerstation,
  incompleteEvents: state.powerstations.incompleteEventsForPowerstation,
  deployments: state.powerstations.deployments,
  mapData: state.powerstations.map,
  deploymentCount: state.powerstations.deploymentCount,
  loadingDeployments: state.powerstations.loadingDeployments,
  loadingMap: state.powerstations.loadingMap,
  userPermissions: state.user.permissions,
  user: state.user,
  savePowerstationLoading: state.powerstations.savePowerstationLoading,
  setpoints: state.feedInManagement.vppSetpoints,
  setpointsPageMeta: state.feedInManagement.vppSetpointsPageMeta,
  location: state.router.location,
  setPointsLoading: state.feedInManagement.vppSetpointsLoading,
});

const mapDispatchToProps = dispatch => {
  return {
    powerstationActions: bindActionCreators(powerstationActions, dispatch),
    feedInManagementActions: bindActionCreators(feedInManagementActions, dispatch),
  };
};

export class PowerstationView extends Component {
  constructor(props) {
    super(props);

    const search = queryString.parse(props.location.search);

    let limit = PAGE_SIZE;
    if (search.limit !== undefined) {
      limit = parseInt(search.limit, 10);
    }

    let offset = 0;
    if (search.offset !== undefined) {
      offset = parseInt(search.offset, 10);
    }

    this.state = {
      powerstationId: this.props.match.params.id,
      type: this.getPowerstationType(props),
      filters: props.powerstation ? props.powerstation.filters : null,
      showNewDispatch: false,
      showSetpointInfo: hasPermission(props.userPermissions, Permissions.fleet.curtailments.any),
      pageTab: this.getPageTab(search),
      limit,
      offset,
      filter: search.f,
    };

    this.updateUrl = this.updateUrl.bind(this);
    this.updateLimitPerPage = this.updateLimitPerPage.bind(this);
  }

  componentDidMount = () => {
    this.props.powerstationActions.fetchActivePowerstation(this.state.powerstationId);
    this.props.powerstationActions.fetchPowerstationMap(this.state.powerstationId);
    this.props.powerstationActions.fetchIncompletePowerstationEvents(this.state.powerstationId);
    if (!this.props.deployments) {
      this.props.powerstationActions.fetchDeployments(
        this.state.powerstationId,
        this.state.limit,
        this.state.offset,
        this.state.filter
      );
    }
    this.fetchData(this.state.limit, this.state.offset, this.state.pageTab);
  };

  componentWillUnmount = () => {
    this.props.powerstationActions.clearActivePowerstation();
    this.props.feedInManagementActions.clearVPPSetpoints();
  };

  loadDeploymentData = (limit, offset, filter) => {
    this.props.powerstationActions.setLoading();
    if (this.refreshTimeout) {
      clearTimeout(this.refreshTimeout);
    }

    // We do a half second debounce here to catch quickly paging through pages and quickly toggling filters.
    this.refreshTimeout = setTimeout(() => {
      this.props.powerstationActions.fetchDeployments(
        this.state.powerstationId,
        limit,
        offset,
        filter
      );
    }, 500);
  };

  componentWillReceiveProps = nextProps => {
    if (this.props.powerstation !== nextProps.powerstation && nextProps.powerstation) {
      this.setState({
        type: this.getPowerstationType(nextProps),
        filters: nextProps.powerstation.filters,
      });
    }

    const nextSearch = queryString.parse(nextProps.location.search);
    if (
      this.props.location.search &&
      nextProps.location.search &&
      this.props.location.search !== nextProps.location.search
    ) {
      this.fetchData(nextSearch.limit, nextSearch.offset, nextSearch.tab, nextSearch.f);
      const offset = parseInt(nextSearch.offset, 10);
      this.setState({
        limit: parseInt(nextSearch.limit, 10) || this.state.limit,
        offset: offset || offset === 0 ? offset : this.state.offset,
        pageTab: this.getPageTab(nextSearch),
        filter: nextSearch.f,
      });
    }
  };

  getPageTab(search) {
    let pageTab = "MAP";
    if (search.tab !== undefined) {
      pageTab = search.tab;
    }
    return pageTab;
  }

  fetchData = (limit, offset, pageTab, filter) => {
    if (pageTab === "SETPOINT_LIST") {
      this.props.feedInManagementActions.fetchVPPSetpoints(
        this.state.powerstationId,
        limit,
        offset
      );
    }
    if (pageTab === "DEPLOYMENT_LIST") {
      this.loadDeploymentData(limit, offset, filter);
    }
  };

  getPage = (limit, offset, pageTab, filter) => {
    this.updateUrl(pageTab, limit, offset, filter);
  };

  updateUrl = (tab, limit, offset, filter) => {
    let url = `${this.props.location.pathname}?tab=${tab}`;
    if (filter) {
      url += `&${queryString.stringify({ f: filter })}`;
    }
    if (limit) {
      url += `&limit=${limit}`;
    }
    if (offset || offset === 0) {
      url += `&offset=${offset}`;
    }
    this.props.history.push(url);
  };

  updateLimitPerPage = limit => {
    this.setState({ limit });
  };

  getPowerstationType = props => {
    if (!props.powerstation) {
      return;
    }

    if (props.powerstation.type === "DYNAMIC") {
      return props.powerstation.filters.state ? "STATE" : "POSTCODE";
    } else if (props.powerstation.type === "FCAS") {
      return "FCAS";
    } else {
      return "INDIVIDUAL_NODES";
    }
  };

  deletePowerstation = () => {
    confirm(
      "powerstation-delete",
      'Are you sure you want to delete Virtual Power Plant "' + this.props.powerstation.name + '"?'
    )
      .then(() => {
        this.props.powerstationActions.deletePowerstation(
          this.props.powerstation.id,
          this.props.powerstation.name
        );
      })
      .catch(() => null);
  };

  registerMapContainer = mapContainer => {
    if (mapContainer) {
      this.setState({
        map: {
          width: mapContainer.clientWidth,
          height: mapContainer.clientHeight,
        },
      });
    }
  };

  showNewDispatch = () => {
    this.setState({
      showNewDispatch: true,
    });
  };

  hideNewDispatch = () => {
    this.setState({
      showNewDispatch: false,
    });
  };

  handleDeploymentFilterChange = filter => {
    this.updateUrl(this.state.pageTab, this.state.limit, 0, filter);
  };

  getInitialTab() {
    let tabNumber;
    switch (this.state.pageTab) {
      case "DEPLOYMENT_LIST":
        tabNumber = 1;
        break;
      case "SETPOINT_LIST":
        tabNumber = 2;
        break;
      default:
        tabNumber = 0;
    }
    return tabNumber;
  }

  render() {
    if (!this.props.powerstation) {
      return <Loading />;
    }

    return (
      <Page key="powerstation-view" permission={Permissions.fleet.powerstations.any}>
        <Page.Header title={this.props.powerstation.name}>
          <AuthRequired permission={Permissions.fleet.powerstations.edit}>
            <Page.Header.Actions>
              {this.state.type !== "FCAS" ? (
                <div>
                  <Button
                    id="edit-powerstation"
                    type="primary"
                    icon="pencil"
                    to={`/virtual-power-plants/manage/${this.props.powerstation.id}/edit?${
                      this.state.filter ? `${queryString.stringify({ f: this.state.filter })}&` : ""
                    }limit=${this.state.limit}&offset=${this.state.offset}`}
                  >
                    Edit
                  </Button>
                  <Button
                    id="delete-powerstation"
                    type="danger"
                    icon="trash-o"
                    onClick={this.deletePowerstation}
                    loading={this.props.savePowerstationLoading}
                  >
                    Delete
                  </Button>
                </div>
              ) : null}
            </Page.Header.Actions>
          </AuthRequired>
        </Page.Header>
        <Page.Content>
          <PowerstationType
            type={this.state.type}
            handleTypeOnChange={this.handleTypeOnChange}
            handleFilterOnChange={this.handleFilterOnChange}
            filters={this.state.filters}
            readOnly
          />

          <PowerstationNamePlate
            capacity={this.props.powerstation.namePlate.batteryCapacity}
            inverterPower={this.props.powerstation.namePlate.inverterPower}
            batteryPower={this.props.powerstation.namePlate.batteryPower}
            deploymentCount={this.props.powerstation.namePlate.totalDeployments}
          />

          <PowerstationIncompleteEvents
            onNewDispatchClick={this.showNewDispatch}
            userPermissions={this.props.userPermissions}
            incompleteEvents={this.props.incompleteEvents}
            timezone={this.props.user.account.timezone}
          />

          <TabbedContainer id="powerstation-view" initialTabNumber={this.getInitialTab()}>
            <TabbedContainer.Tab
              label="Map"
              onActivate={() => this.updateUrl("MAP")}
              style={{ height: "40vh" }}
            >
              <div style={{ height: "100%" }} ref={this.registerMapContainer}>
                {this.props.mapData && !this.props.loadingMap && this.state.map ? (
                  <DeploymentMap
                    width={this.state.map.width}
                    height={this.state.map.height}
                    deployments={this.props.mapData}
                  />
                ) : (
                  <Loading />
                )}
              </div>
            </TabbedContainer.Tab>

            <TabbedContainer.Tab
              label="List"
              style={{ backgroundColor: "#F7F9FD" }}
              onActivate={() =>
                this.updateUrl("DEPLOYMENT_LIST", this.state.limit, this.state.offset)
              }
            >
              <PowerstationDeploymentsSearch
                key="ps-depl-search"
                deploymentFilter={this.state.filter}
                onDeploymentFilterChange={this.handleDeploymentFilterChange}
              />
              {this.props.loadingDeployments ? (
                <Loading className="loading-inline" />
              ) : (
                <>
                  <div key="ps-depl-list">
                    <PowerstationDeploymentsList
                      deployments={this.props.deployments}
                      userPermissions={this.props.userPermissions}
                      includedDeploymentCount={this.props.powerstation.namePlate.totalDeployments}
                    />
                  </div>
                  <PagingControl
                    key="deploymentPaging"
                    limit={this.state.limit}
                    offset={this.state.offset}
                    totalItems={this.props.deploymentCount}
                    itemName="deployment"
                    onPageChange={(limit, offset) =>
                      this.getPage(limit, offset, this.state.pageTab, this.state.filter)
                    }
                    onChangeResultsPerPage={this.updateLimitPerPage}
                    orientation="up"
                  />
                </>
              )}
            </TabbedContainer.Tab>
            <TabbedContainer.Tab
              label="Default Setpoints"
              permission={Permissions.fleet.curtailments.any}
              onActivate={() =>
                this.updateUrl("SETPOINT_LIST", this.state.limit, this.state.offset)
              }
            >
              <div style={{ height: "100%" }} ref={this.registerMapContainer}>
                {!this.props.setPointsLoading && this.props.setpoints ? (
                  <>
                    <SetpointListTable
                      setpoints={this.props.setpoints}
                      powerstations={[this.props.powerstation]}
                      timezone={this.props.user.account.timezone}
                      history={this.props.history}
                    />
                    <PagingControl
                      key="VPPSetpointPaging"
                      limit={this.state.limit}
                      offset={this.state.offset}
                      totalItems={this.props.setpointsPageMeta.count}
                      itemName="setpoint"
                      onPageChange={(limit, offset) =>
                        this.getPage(limit, offset, this.state.pageTab, this.state.filter)
                      }
                      onChangeResultsPerPage={this.updateLimitPerPage}
                      orientation="up"
                    />
                  </>
                ) : (
                  <Loading />
                )}
              </div>
            </TabbedContainer.Tab>
          </TabbedContainer>

          {this.state.showNewDispatch ? (
            <NewDispatchModal
              handleOnCancel={this.hideNewDispatch}
              powerstationId={this.props.powerstation.id}
              powerstationName={this.props.powerstation.name}
            />
          ) : null}
        </Page.Content>
      </Page>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PowerstationView));

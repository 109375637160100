import moment from "moment-timezone";

export function getDateString(createdAt, timezone) {
  const date = moment(new Date(createdAt * 1000)).tz(timezone);
  const now = moment().tz(timezone);

  if (date.diff(now, "days") > -1) {
    return `Today, ${date.format("h:mma")}`;
  } else if (date.diff(now, "days") > -2) {
    return `Yesterday, ${date.format("h:mma")}`;
  } else if (date.diff(now, "years") > -1) {
    return date.format("dddd D MMMM, h:mma");
  } else {
    return date.format("dddd D MMMM YYYY, h:mma");
  }
}

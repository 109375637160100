import React, { Component } from "react";
import WidgetContent from "./WidgetContent";
import "./WidgetContainer.css";

class WidgetContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      status: "LOADING",
      widgetClassName: props.widgetComponent.className,
    };
  }

  setContentStatus = status => {
    if (status !== this.state.contentStatus) {
      this.setState({
        status: status,
      });
    }
  };

  setTitle = (title, description) => {
    this.setState({
      title: title,
      description: description,
    });
  };

  setWidgetClassName = className => {
    this.setState({
      widgetClassName: className,
    });
  };

  removeWidget = e => {
    this.props.removeWidget(this.props.widgetId);
  };

  render() {
    let className = this.props.className + " dashboard-widget " + this.state.widgetClassName;

    if (this.state.status === "ERROR") {
      className += " widget-error";
    }

    return (
      <div
        key={this.props.key}
        className={className}
        data-grid={this.props["data-grid"]}
        onMouseDown={this.props.onMouseDown}
        onMouseUp={this.props.onMouseUp}
        onTouchEnd={this.props.onTouchEnd}
        onTouchStart={this.props.onTouchStart}
        style={this.props.style}
      >
        <WidgetContent
          location="DASHBOARD"
          title={this.state.title}
          description={this.state.description}
          hasSettings={!!this.props.settingsConfig}
          widgetComponent={this.props.widgetComponent}
          error={this.state.status === "ERROR"}
          errorMessage={this.state.errorMessage}
          setTitle={this.setTitle}
          onError={errorMessage => this.setContentStatus("ERROR", errorMessage)}
          removeWidget={this.props.removeWidget}
          settings={this.props.settings}
          openSettings={this.props.openSettings}
          setWidgetClassName={this.setWidgetClassName}
          fullscreen={this.props.fullscreen}
          exitFullScreen={this.props.exitFullScreen}
          widgetCount={this.props.widgetCount}
          fullScreenWidgetIndex={this.props.fullScreenWidgetIndex}
          widgetId={this.props.widgetId}
          registerResizeCallback={this.props.registerResizeCallback}
          pauseFullScreen={this.props.pauseFullScreen}
          resumeFullScreen={this.props.resumeFullScreen}
          isFullScreenRunning={this.props.isFullScreenRunning}
          gotoFullScreenWidget={this.props.gotoFullScreenWidget}
        />
        {/* children is passed in by react-grid-layout and contains the resize handlers */}
        {this.props.children}
      </div>
    );
  }
}

export default WidgetContainer;

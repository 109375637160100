import React, { Component } from "react";
import { Tooltip } from "../widgets";
import "./PowerstationNamePlate.css";

class PowerstationNamePlate extends Component {
  render() {
    let capacity = 0,
      inverterPower = 0,
      batteryPower = 0,
      capacityUnit = "",
      inverterPowerUnit = "",
      batteryPowerUnit = "";
    if (this.props.capacity) {
      capacity = this.props.capacity > 1000 ? this.props.capacity / 1000 : this.props.capacity;
      capacityUnit = this.props.capacity > 1000 ? "MWh" : "kWh";
    }

    if (this.props.inverterPower) {
      inverterPower =
        this.props.inverterPower > 1000
          ? this.props.inverterPower / 1000
          : this.props.inverterPower;
      inverterPowerUnit = this.props.inverterPower > 1000 ? "MW" : "kW";
    }

    if (this.props.batteryPower) {
      batteryPower =
        this.props.batteryPower > 1000 ? this.props.batteryPower / 1000 : this.props.batteryPower;
      batteryPowerUnit = this.props.batteryPower > 1000 ? "MW" : "kW";
    }
    return (
      <div
        className={
          "powerstation-nameplate" + (this.props.className ? " " + this.props.className : "")
        }
      >
        <div>
          <label htmlFor="ps-np-size">Size</label>
          <div id="ps-np-size" className="value size">
            {this.props.loading ? (
              <span className="fa fa-circle-o-notch fa-spin ps-np-loading" />
            ) : this.props.deploymentCount ? (
              `${this.props.deploymentCount} Nodes`
            ) : (
              "0 Nodes"
            )}
          </div>
        </div>
        <div>
          <label htmlFor="ps-np-capacity">Capacity</label>
          <div id="ps-np-capacity" className="value capacity">
            {this.props.loading ? null : `${+capacity.toFixed(2)} ${capacityUnit}`}
          </div>
        </div>
        <div>
          <label htmlFor="ps-np-inverter-power">Inverter Power</label>
          <div id="ps-np-inverter-power" className="value power">
            {this.props.loading ? null : `${+inverterPower.toFixed(2)} ${inverterPowerUnit}`}
          </div>
        </div>
        <div>
          <label htmlFor="ps-np-battery-power">Battery Power</label>
          <div id="ps-np-battery-power" className="value power">
            {this.props.loading ? null : `${+batteryPower.toFixed(2)} ${batteryPowerUnit}`}
          </div>
        </div>
        <div className="ps-np-tooltip-container">
          <Tooltip id="powerstations.detail.namePlate" />
        </div>
      </div>
    );
  }
}

export default PowerstationNamePlate;

import React, { Component } from "react";
import Page from "../layout/Page";
import { Select, Input, DatePicker, Button } from "../widgets";
import { Notify } from "../../utils/notificationManager";
import TariffRatesTable from "./TariffRatesTable";
import TariffPeriodsTable from "./TariffPeriodsTable";
import AuthRequired from "../../containers/AuthRequired";
import Permissions from "../../permissions";
import moment from "moment-timezone";
import "./Tariff.css";

export const TARIFF_TYPE_OPTIONS = [
  { value: "FLAT_RATE", label: "Flat Rate" },
  { value: "TOU", label: "Time of Use" },
  { value: "TOU_BLOCK", label: "Time of Use - Block" },
  { value: "BLOCK", label: "Block" },
  { value: "FLAT_DEMAND", label: "Flat Rate with Demand Charge" },
  { value: "TOU_DEMAND", label: "Time of Use with Demand Charge" },
];

export let TARIFF_TYPES = {};
for (let i in TARIFF_TYPE_OPTIONS) {
  TARIFF_TYPES[TARIFF_TYPE_OPTIONS[i].value] = TARIFF_TYPE_OPTIONS[i].label;
}

export const RESET_PERIODS = [
  { value: "DAILY", label: "Daily" },
  { value: "WEEKLY", label: "Weekly" },
  { value: "MONTHLY", label: "Monthly" },
  { value: "QUARTERLY", label: "Quarterly" },
];

class Tariff extends Component {
  constructor(props) {
    super(props);
    if (props.tariff) {
      const currentVersion = props.tariff.versions.find(v => v.latest);
      const startDate = moment(currentVersion.startDate, "YYYY-MM-DD");

      if (props.tariff.tariffType === "FLAT_RATE") {
        props.tariff.rates[0].blocks[0].onset = 0;
      }

      this.state = {
        tariff: {
          ...props.tariff,
          startDate: startDate,
        },
        tariffErrors: {
          rates: [],
          periods: [],
          demandPeriods: [],
          demandRates: [],
        },
      };
    } else {
      this.state = {
        tariff: {
          tariffType: "",
          planCode: "",
          name: "",
          description: "",
          operationalNetworkUid: "",
          dailyCharge: "",
          resetPeriod: "",
          feedInTariff: "",
          rates: null,
          periods: null,
          demandPeriods: null,
          demandRates: null,
        },
        tariffErrors: {
          rates: [],
          periods: [],
          demandPeriods: [],
          demandRates: [],
        },
      };
    }
  }

  static defaultRate = {
    label: "",
    isBaseRate: false,
    blocks: null,
  };

  static defaultBlock = {
    onset: "",
    price: "",
  };

  static defaultPeriod = {
    startTime: "",
    endTime: "",
    applicableDays: {
      mon: false,
      tue: false,
      wed: false,
      thu: false,
      fri: false,
      sat: false,
      sun: false,
      publicHolidays: false,
    },
    rate: "",
  };

  componentDidMount = () => {
    this.props.fetchOperationalNetworks();
  };

  componentWillReceiveProps = nextProps => {
    if (nextProps.tariffErrors) {
      this.setState({
        tariffErrors: {
          ...this.state.tariffErrors,
          ...nextProps.tariffErrors,
        },
      });
    }
  };

  generateDefaultTariff = (ratesCount, blocksCount, periodsCount, demandRatesCount) => {
    let rates = [];
    for (let i = 0; i < ratesCount; i++) {
      let newRate = Object.assign({}, Tariff.defaultRate);
      newRate.blocks = [];

      for (let j = 0; j < blocksCount[i]; j++) {
        const newBlock = Object.assign({}, Tariff.defaultBlock);
        if (j === 0) {
          newBlock.onset = "0";
        }
        newRate.blocks.push(newBlock);
      }

      rates.push(newRate);
    }

    if (rates.length > 0) {
      rates[0].label = "Base Rate";
      rates[0].isBaseRate = true;
    }

    let periods = [];
    for (let i = 0; i < periodsCount; i++) {
      const newPeriod = Object.assign({}, Tariff.defaultPeriod);
      periods.push(newPeriod);
    }

    let demandRates = [];

    let demandPeriods = [];

    for (let i = 0; i < demandRatesCount; i++) {
      let newRate = Object.assign({}, Tariff.defaultRate);
      newRate.blocks = [];
      const newBlock = Object.assign({}, Tariff.defaultBlock);
      newBlock.onset = "0";
      newRate.blocks.push(newBlock);

      demandRates.push(newRate);
      const newPeriod = Object.assign({}, Tariff.defaultPeriod);
      demandPeriods.push(newPeriod);
    }

    return {
      rates: rates.length === 0 ? null : rates,
      periods: periods.length === 0 ? null : periods,
      demandRates: demandRates.length === 0 ? null : demandRates,
      demandPeriods: demandPeriods.length === 0 ? null : demandPeriods,
    };
  };

  handleTariffTypeChange = option => {
    if (option !== null) {
      const tariffType = option.value;
      let ratesCount, blocksCount, periodsCount, demandRatesCount;
      switch (tariffType) {
        case "FLAT_RATE":
          ratesCount = 1;
          blocksCount = [1];
          periodsCount = 0;
          demandRatesCount = 0;
          break;
        case "TOU":
          ratesCount = 2;
          blocksCount = [1, 1];
          periodsCount = 2;
          demandRatesCount = 0;
          break;
        case "TOU_BLOCK":
          ratesCount = 2;
          blocksCount = [1, 2];
          periodsCount = 2;
          demandRatesCount = 0;
          break;
        case "BLOCK":
          ratesCount = 1;
          blocksCount = [2];
          periodsCount = 0;
          demandRatesCount = 0;
          break;
        case "FLAT_DEMAND":
          ratesCount = 1;
          blocksCount = [1];
          periodsCount = 0;
          demandRatesCount = 1;
          break;
        case "TOU_DEMAND":
          ratesCount = 2;
          blocksCount = [1, 1];
          periodsCount = 2;
          demandRatesCount = 1;
          break;
        default:
          break;
      }

      const { rates, periods, demandRates, demandPeriods } = this.generateDefaultTariff(
        ratesCount,
        blocksCount,
        periodsCount,
        demandRatesCount
      );

      this.setState({
        tariff: {
          ...this.state.tariff,
          tariffType: tariffType,
          rates: rates,
          periods: periods,
          demandRates: demandRates,
          demandPeriods: demandPeriods,
        },
      });
    } else {
      this.setState({
        tariff: {
          ...this.state.tariff,
          tariffType: null,
          rates: null,
          periods: null,
          demandRates: null,
          demandPeriods: null,
        },
      });
    }
  };

  handleAddRate = () => {
    let newRates = this.state.tariff.rates.slice(0);
    newRates.push({ ...Tariff.defaultRate });

    let newBlock = Object.assign({}, Tariff.defaultBlock);
    if (!newRates.blocks) {
      newBlock.onset = 0;
    }
    newRates[newRates.length - 1].blocks = [newBlock];

    this.setState({
      tariff: {
        ...this.state.tariff,
        rates: newRates,
      },
    });
  };

  handleRemoveBlock = (rateIdx, blockIdx) => {
    let newRates = this.state.tariff.rates.slice(0);
    newRates[rateIdx].blocks.splice(blockIdx, 1);

    if (newRates[rateIdx].blocks.length === 0) {
      // There are no blocks left for this rate so also remove the rate.
      newRates.splice(rateIdx, 1);
    }

    this.setState({
      tariff: {
        ...this.state.tariff,
        rates: newRates,
      },
    });
  };

  handleAddBlock = index => {
    let newBlocks = this.state.tariff.rates[index].blocks.slice(0);
    newBlocks.push({ ...Tariff.defaultBlock });

    let newRates = this.state.tariff.rates.slice(0);
    newRates[index].blocks = newBlocks;

    this.setState({
      tariff: {
        ...this.state.tariff,
        rates: newRates,
      },
    });
  };

  handleAddPeriod = () => {
    let newPeriods = this.state.tariff.periods.slice(0);
    newPeriods.push({ ...Tariff.defaultPeriod });

    this.setState({
      tariff: {
        ...this.state.tariff,
        periods: newPeriods,
      },
    });
  };

  handleRemovePeriod = index => {
    let newPeriods = this.state.tariff.periods.slice(0);
    newPeriods.splice(index, 1);

    this.setState({
      tariff: {
        ...this.state.tariff,
        periods: newPeriods,
      },
    });
  };

  handleRateChange = (index, property, value) => {
    let newRates = this.state.tariff.rates.slice(0);
    newRates[index][property] = value;

    this.setState({
      tariff: {
        ...this.state.tariff,
        rates: newRates,
      },
    });
  };

  handleDemandRateChange = (index, property, value) => {
    let newRates = this.state.tariff.demandRates.slice(0);
    newRates[index][property] = value;

    this.setState({
      tariff: {
        ...this.state.tariff,
        demandRates: newRates,
      },
    });
  };

  handlePeriodChange = (index, property, value) => {
    let newPeriods = this.state.tariff.periods.slice(0);
    if (property === "applicableDays") {
      let newDays = { ...newPeriods[index]["applicableDays"] };
      newDays[value.day] = value.checked;
      newPeriods[index]["applicableDays"] = newDays;
    } else {
      newPeriods[index][property] = value;
    }

    this.setState({
      tariff: {
        ...this.state.tariff,
        periods: newPeriods,
      },
    });
  };

  handleDemandPeriodChange = (index, property, value) => {
    let newPeriods = this.state.tariff.demandPeriods.slice(0);
    if (property === "applicableDays") {
      let newDays = { ...newPeriods[index]["applicableDays"] };
      newDays[value.day] = value.checked;
      newPeriods[index]["applicableDays"] = newDays;
    } else {
      newPeriods[index][property] = value;
    }

    this.setState({
      tariff: {
        ...this.state.tariff,
        demandPeriods: newPeriods,
      },
    });
  };

  handleBlockChange = (rateIdx, blockIdx, property, value) => {
    let newBlocks = this.state.tariff.rates[rateIdx].blocks.slice(0);
    newBlocks[blockIdx][property] = value;

    let newRates = this.state.tariff.rates.slice(0);
    newRates[rateIdx].blocks = newBlocks;

    this.setState({
      tariff: {
        ...this.state.tariff,
        rates: newRates,
      },
    });
  };

  handleDemandBlockChange = (rateIdx, blockIdx, property, value) => {
    let newBlocks = this.state.tariff.demandRates[rateIdx].blocks.slice(0);
    newBlocks[blockIdx][property] = value;

    let newRates = this.state.tariff.demandRates.slice(0);
    newRates[rateIdx].blocks = newBlocks;

    this.setState({
      tariff: {
        ...this.state.tariff,
        demandRates: newRates,
      },
    });
  };

  handleTariffFieldChange = (fieldName, value) => {
    this.setState({
      tariff: {
        ...this.state.tariff,
        [fieldName]: value,
      },
    });
  };

  validateTariffDetails = () => {
    let tariffDetailErrors = {};

    if (this.state.tariff.name.trim() === "" || !this.state.tariff.name) {
      tariffDetailErrors.name = "Please enter a plan name.";
    }

    if (!this.state.tariff.tariffType) {
      tariffDetailErrors.tariffType = "Please select a plan type.";
    }

    if (isNaN(parseFloat(this.state.tariff.dailyCharge))) {
      tariffDetailErrors.dailyCharge = "Please enter a dollar value.";
    }

    if (!this.state.tariff.dailyCharge) {
      tariffDetailErrors.dailyCharge = "Please enter a daily charge.";
    }

    if (isNaN(parseFloat(this.state.tariff.feedInTariff))) {
      tariffDetailErrors.feedInTariff = "Please enter a dollar value.";
    }

    if (!this.state.tariff.feedInTariff) {
      tariffDetailErrors.feedInTariff = "Please enter a feed in tariff.";
    }

    if (!this.state.tariff.operationalNetworkUid) {
      tariffDetailErrors.operationalNetworkUid = "Please enter a network.";
    }

    if (!this.state.tariff.startDate) {
      tariffDetailErrors.startDate = "Please enter commencement date.";
    }

    if (this.state.tariff.planCode.trim() === "" || !this.state.tariff.planCode) {
      tariffDetailErrors.planCode = "Please enter a plan code.";
    }

    if (
      (this.state.tariff.tariffType === "TOU_BLOCK" || this.state.tariff.tariffType === "BLOCK") &&
      !this.state.tariff.resetPeriod
    ) {
      tariffDetailErrors.resetPeriod = "Please enter a reset period.";
    }

    if (
      this.props.allTariffs.find(
        t => t.name === this.state.tariff.name && t.uid !== this.state.tariff.uid
      )
    ) {
      tariffDetailErrors.name = "This plan name already exists. Each plan must have a unique name.";
    }

    if (
      this.props.allTariffs.find(
        t => t.planCode === this.state.tariff.planCode && t.uid !== this.state.tariff.uid
      )
    ) {
      tariffDetailErrors.name = "This plan code already exists. Each plan must have a unique name.";
    }

    return tariffDetailErrors;
  };

  validateRates = () => {
    let tariffRateErrors = [];

    for (let i in this.state.tariff.rates) {
      const rate = this.state.tariff.rates[i];
      let rateErrors = {};

      if (rate.label.trim() === "" || !rate.label) {
        rateErrors["label"] = "Please enter a label for this rate.";
      }

      rateErrors["blocks"] = [];

      for (let j in rate.blocks) {
        rateErrors["blocks"].push(this.validateBlock(rate.blocks[j]));
      }

      tariffRateErrors.push(rateErrors);
    }

    return tariffRateErrors;
  };

  validateBlock = block => {
    let blockErrors = {};

    if (isNaN(parseInt(block.onset, 10))) {
      blockErrors.onset = "Please enter a number.";
    }

    if (block.onset === null || block.onset === undefined || block.onset === "") {
      blockErrors.onset = "Please enter an onset.";
    }

    if (isNaN(parseFloat(block.price))) {
      blockErrors.onset = "Please enter a dollar value.";
    }

    if (block.price.trim() === "" || !block.price) {
      blockErrors.price = "Please enter a price.";
    }

    return blockErrors;
  };

  validatePeriods = () => {
    let tariffPeriodErrors = [];

    for (let i in this.state.tariff.periods) {
      const period = this.state.tariff.periods[i];
      let periodErrors = {};

      if (period.startTime.trim() === "" || !period.startTime) {
        periodErrors.startTime = "Please enter a start time.";
      }

      if (period.endTime.trim() === "" || !period.endTime) {
        periodErrors.endTime = "Please enter a end time.";
      }

      if (
        !period.applicableDays.mon &&
        !period.applicableDays.tue &&
        !period.applicableDays.wed &&
        !period.applicableDays.thu &&
        !period.applicableDays.fri &&
        !period.applicableDays.sat &&
        !period.applicableDays.sun &&
        !period.applicableDays.publicHolidays
      ) {
        periodErrors.applicableDays = "Please select at least one day.";
      }

      if (period.rate.trim() === "" || !period.rate) {
        periodErrors.rate = "Please select a rate.";
      }

      if (
        period.startTime &&
        period.endTime &&
        this.timeToInt(period.startTime) >= this.timeToInt(period.endTime)
      ) {
        // Just set this error to true, we will display a message underneath both fields rather than
        // one per time field.
        periodErrors.timeAlignment = true;
      }

      tariffPeriodErrors.push(periodErrors);
    }

    const overlapErrors = this.validatePeriodsOverlap();

    for (let i in overlapErrors) {
      // Just set this error to true. This will be used give the red outline but not display an error
      // message we will display a global error relating to period overlaps at the bottom of the
      // table.
      tariffPeriodErrors[i].overlap = true;
    }

    return tariffPeriodErrors;
  };

  timeToInt = time => {
    const noColon = time.replace(":", "");
    return parseInt(noColon, 10);
  };

  validatePeriodsOverlap = () => {
    let tariffOverlapErrors = {};

    const periods = this.state.tariff.periods;

    const checkOverlapForDay = day => {
      let periodsForDay = [];
      for (let i in periods) {
        const period = periods[i];
        // We preserve the index in the periods so that we can map back to the original period row in
        // the table so that we can display the red borders on the periods with the issues.
        if (period.applicableDays[day])
          periodsForDay.push({
            index: i,
            period: {
              startTime: this.timeToInt(period.startTime),
              endTime: this.timeToInt(period.endTime),
            },
          });
      }

      // eslint-disable-next-line
      dayLoop: for (let i in periodsForDay) {
        const dayToCheck = periodsForDay[i];
        const period1 = dayToCheck.period;
        for (let j in periodsForDay) {
          const period2 = periodsForDay[j].period;
          // i !== j check so we don't compare with itself - comparing with itself will always overlap.
          if (
            i !== j &&
            period1.startTime < period2.endTime &&
            period2.startTime < period1.endTime
          ) {
            tariffOverlapErrors[dayToCheck.index] = true;
            // eslint-disable-next-line
            continue dayLoop;
          }
        }
      }
    };

    for (let day in Tariff.defaultPeriod.applicableDays) {
      checkOverlapForDay(day);
    }

    return tariffOverlapErrors;
  };

  hasErrors(tariffErrors) {
    // There will always be at least two keys as we always have `rates` and `periods`.
    if (Object.keys(tariffErrors).length > 4 && tariffErrors.constructor === Object) {
      return true;
    }

    for (let i in tariffErrors.periods) {
      const periodErrors = tariffErrors.periods[i];
      if (Object.keys(periodErrors).length > 0 && periodErrors.constructor === Object) {
        return true;
      }
    }

    for (let i in tariffErrors.rates) {
      const rateErrors = tariffErrors.rates[i];
      // We always have a `blocks` property.
      if (Object.keys(rateErrors).length > 1 && rateErrors.constructor === Object) {
        return true;
      }

      for (let j in rateErrors.blocks) {
        const blockErrors = rateErrors.blocks[j];
        if (Object.keys(blockErrors).length > 0 && blockErrors.constructor === Object) {
          return true;
        }
      }
    }

    return false;
  }

  handleSaveTariff = () => {
    const tariffDetailErrors = this.validateTariffDetails();
    const tariffRateErrors = this.validateRates();
    const tarrifPeriodErrors = this.validatePeriods();
    // TODO Check for errors in the demand periods
    const tariffDemandRateErrors = [];
    const tariffDemandPeriodErrors = [];

    const tariffErrors = {
      ...tariffDetailErrors,
      rates: tariffRateErrors,
      periods: tarrifPeriodErrors,
      demandRates: tariffDemandRateErrors,
      demandPeriods: tariffDemandPeriodErrors,
    };

    this.setState({
      tariffErrors: tariffErrors,
    });

    // Check if we've actually added any errors to the planErrors object;
    if (this.hasErrors(tariffErrors)) {
      Notify.warning("New Plan Errors", "Please correct your errors before continuing.");
    } else {
      // No errors added so we are good to save!
      this.props.onSave(this.state.tariff);
    }
  };

  render() {
    const tariff = this.state.tariff;
    const isReadOnly = this.props.readOnly;
    return (
      <>
        <Page.Header title={this.props.title}>
          <Page.Header.Actions>
            <AuthRequired permission={Permissions.fleet.customerInformation.plans.edit}>
              {!this.props.readOnly ? (
                <>
                  <Button
                    id="plan-edit-cancel"
                    type="secondary"
                    onClick={this.props.onCancel}
                    icon="times"
                  >
                    Cancel
                  </Button>
                  <Button
                    id="plan-edit-save"
                    type="primary"
                    onClick={this.handleSaveTariff}
                    icon="pencil"
                    loading={this.props.isSaving}
                  >
                    Save
                  </Button>
                </>
              ) : (
                <Button
                  id="plan-edit-go"
                  type="primary"
                  to={"/customers/plans/" + encodeURIComponent(tariff.planCode) + "/edit"}
                  icon="pencil"
                >
                  Edit Plan
                </Button>
              )}
            </AuthRequired>
          </Page.Header.Actions>
        </Page.Header>
        <Page.Content className="tariff">
          <div className="tariff-details">
            {this.state.tariffErrors.non_field_errors ? (
              <div className="tariff-details-row">
                <span className="non-field-errors">{this.state.tariffErrors.non_field_errors}</span>
              </div>
            ) : null}
            {!isReadOnly ? (
              <div className="tariff-details-row">
                <label className="plan-name">
                  <span className="label-text">Plan Name</span>
                  <div className="widget">
                    <Input
                      error={this.state.tariffErrors.name}
                      value={this.state.tariff.name}
                      onChange={e => this.handleTariffFieldChange("name", e.target.value)}
                    />
                  </div>
                </label>
              </div>
            ) : null}
            <div className="tariff-details-row">
              <label className="plan-code">
                <span className="label-text">Plan Code</span>
                <div className="widget">
                  <Input
                    readOnly={isReadOnly}
                    error={this.state.tariffErrors.planCode}
                    value={this.state.tariff.planCode}
                    onChange={e => this.handleTariffFieldChange("planCode", e.target.value)}
                  />
                </div>
              </label>
              {!isReadOnly || this.state.tariff.description ? (
                <label className="description">
                  <span className="label-text">Description (optional)</span>
                  <div className="widget">
                    <Input
                      readOnly={isReadOnly}
                      error={this.state.tariffErrors.description}
                      value={this.state.tariff.description}
                      onChange={e => this.handleTariffFieldChange("description", e.target.value)}
                    />
                  </div>
                </label>
              ) : null}
            </div>
            <div className="tariff-details-row">
              <label className="tariff-type">
                <span className="label-text">Plan type</span>
                <div className="widget">
                  <Select
                    readOnly={isReadOnly}
                    value={tariff.tariffType}
                    options={TARIFF_TYPE_OPTIONS}
                    onChange={this.handleTariffTypeChange}
                    error={this.state.tariffErrors.tariffType}
                  />
                </div>
              </label>
              <label className="operational-network">
                <span className="label-text">Network</span>
                <div className="widget">
                  {this.props.operationalNetworks ? (
                    <Select
                      readOnly={isReadOnly}
                      options={this.props.operationalNetworks.map(n => ({
                        label: n.name,
                        value: n.uid,
                      }))}
                      error={this.state.tariffErrors.operationalNetworkUid}
                      value={this.state.tariff.operationalNetworkUid}
                      onChange={e =>
                        this.handleTariffFieldChange("operationalNetworkUid", e ? e.value : null)
                      }
                    />
                  ) : (
                    <i
                      className="fa fa-spinner fa-spin reposit-loading operational-networks-loading"
                      ariaHidden="true"
                    />
                  )}
                </div>
              </label>
              <label className="commencement-date">
                <span className="label-text">Commencement Date</span>
                <div className="widget">
                  <DatePicker
                    withInput
                    readOnly={isReadOnly}
                    dateFormat="dd/MM/yyyy"
                    value={this.state.tariff.startDate}
                    onChange={d => this.handleTariffFieldChange("startDate", d)}
                    error={this.state.tariffErrors.startDate}
                    placeholderText="DD/MM/YYYY"
                    timezone={this.props.timezone}
                  />
                </div>
              </label>
            </div>
            <div className="tariff-details-row">
              <label className={"dollar-input" + (isReadOnly ? " ro" : "")}>
                <span className="label-text">Daily Charge</span>
                <div className="widget">
                  <Input
                    readOnly={isReadOnly}
                    error={this.state.tariffErrors.dailyCharge}
                    value={this.state.tariff.dailyCharge}
                    onChange={e => this.handleTariffFieldChange("dailyCharge", e.target.value)}
                  />
                </div>
              </label>
              <label className={"dollar-input" + (isReadOnly ? " ro" : "")}>
                <span className="label-text">Solar FiT</span>
                <div className="widget">
                  <Input
                    readOnly={isReadOnly}
                    error={this.state.tariffErrors.feedInTariff}
                    value={this.state.tariff.feedInTariff}
                    onChange={e => this.handleTariffFieldChange("feedInTariff", e.target.value)}
                  />
                </div>
              </label>
              {tariff.tariffType === "BLOCK" || tariff.tariffType === "TOU_BLOCK" ? (
                <label className="reset-period">
                  <span className="label-text">When do rate/block allowances reset?</span>
                  <div className="widget">
                    <Select
                      readOnly={isReadOnly}
                      options={RESET_PERIODS}
                      error={this.state.tariffErrors.resetPeriod}
                      value={this.state.tariff.resetPeriod}
                      onChange={e =>
                        this.handleTariffFieldChange("resetPeriod", e ? e.value : null)
                      }
                    />
                  </div>
                </label>
              ) : null}
            </div>
          </div>

          {tariff.rates ? (
            <div className="tariff-rates">
              <h2>Rates</h2>
              {!isReadOnly ? (
                <p className="help-text">All prices should be exclusive of GST.</p>
              ) : null}
              <TariffRatesTable
                readOnly={isReadOnly}
                rates={tariff.rates}
                mode={this.props.mode}
                addRate={
                  tariff.tariffType === "TOU_BLOCK" || tariff.tariffType === "TOU"
                    ? this.handleAddRate
                    : null
                }
                addBlock={
                  tariff.tariffType === "BLOCK" || tariff.tariffType === "TOU_BLOCK"
                    ? this.handleAddBlock
                    : null
                }
                enableMultipleBaseRateBlocks={tariff.tariffType === "BLOCK"}
                removeBlock={this.handleRemoveBlock}
                rateChange={this.handleRateChange}
                blockChange={this.handleBlockChange}
                errors={this.state.tariffErrors.rates}
              />
            </div>
          ) : null}

          {tariff.periods ? (
            <div className="tariff-periods">
              <h2>Periods</h2>
              {!isReadOnly ? (
                <p className="help-text">
                  Please specify when the rates defined above apply. The base rate will apply unless
                  otherwise specified.
                </p>
              ) : (
                <p className="help-text">The base rate will apply unless otherwise specified.</p>
              )}
              <TariffPeriodsTable
                readOnly={isReadOnly}
                periods={tariff.periods}
                rates={tariff.rates.filter(r => !r.isBaseRate)}
                mode={this.props.mode}
                addPeriod={
                  tariff.tariffType === "TOU_BLOCK" || tariff.tariffType === "TOU"
                    ? this.handleAddPeriod
                    : null
                }
                removePeriod={this.handleRemovePeriod}
                periodChange={this.handlePeriodChange}
                errors={this.state.tariffErrors.periods}
              />
            </div>
          ) : null}

          {tariff.demandRates ? (
            <>
              <div className="tariff-rates">
                <h2>Demand Rates</h2>
                {!isReadOnly ? (
                  <p className="help-text">All prices should be exclusive of GST.</p>
                ) : null}
                <TariffRatesTable
                  readOnly={isReadOnly}
                  rates={tariff.demandRates}
                  mode={this.props.mode}
                  addRate={null} // only one rate for now
                  addBlock={null}
                  enableMultipleBaseRateBlocks={false}
                  removeBlock={null}
                  rateChange={this.handleDemandRateChange}
                  blockChange={this.handleDemandBlockChange}
                  errors={this.state.tariffErrors.demandRates}
                  units={"kW/day"}
                />
              </div>
              <div className="tariff-periods">
                <h2>Demand Periods</h2>
                {!isReadOnly ? (
                  <p className="help-text">
                    Please specify when the demand rates defined above apply.
                  </p>
                ) : (
                  <p className="help-text">Demand rates apply only during the specified periods</p>
                )}
                <TariffPeriodsTable
                  readOnly={isReadOnly}
                  periods={tariff.demandPeriods}
                  rates={tariff.demandRates.filter(r => !r.isBaseRate)}
                  mode={this.props.mode}
                  addPeriod={null}
                  removePeriod={null}
                  periodChange={this.handleDemandPeriodChange}
                  errors={this.state.tariffErrors.demandPeriods}
                />
              </div>
            </>
          ) : null}
        </Page.Content>
      </>
    );
  }
}

export default Tariff;

import React, { useEffect, useState } from "react";
import "./HubspotContactForm.css";
import Loading from "../../components/widgets/Loading";
const HubspotContactForm = props => {
  const { region, portalId, formId } = props;
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://js.hsforms.net/forms/v2.js";

    document.body.appendChild(script);

    script.addEventListener("load", () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          region: region,
          portalId: portalId,
          formId: formId,
          target: "#hubspotForm",
        });
        setLoading(false);
      }
    });
  }, [region, portalId, formId]);

  if (loading) {
    return <Loading />;
  }

  return (
    <div className="HubspotContactFormCotainer">
      <div id="hubspotForm"></div>
    </div>
  );
};
export default HubspotContactForm;

import { getDateAsString } from "../components/widgets/Moment";

export const asCapacity = rawCapacity => {
  let capacityUnit = "",
    capacity = 0;
  if (rawCapacity || rawCapacity === 0) {
    capacity =
      rawCapacity > 1000 || rawCapacity < -1000
        ? +(rawCapacity / 1000).toFixed(2)
        : +rawCapacity.toFixed(2);
    capacityUnit = rawCapacity > 1000 || rawCapacity < -1000 ? "MWh" : "kWh";
  }

  return `${capacity} ${capacityUnit}`;
};

export const asPower = rawPower => {
  let powerUnit = "",
    power = 0;
  if (rawPower || rawPower === 0) {
    power =
      rawPower > 1000 || rawPower < -1000 ? +(rawPower / 1000).toFixed(2) : Math.round(rawPower);
    powerUnit = rawPower > 1000 || rawPower < -1000 ? "MW" : "kW";
  }

  return `${Math.abs(power)} ${powerUnit}`;
};

export const asPowerSigned = rawPower => {
  let powerUnit = "",
    power = 0;
  if (rawPower || rawPower === 0) {
    power =
      rawPower > 1000 || rawPower < -1000 ? +(rawPower / 1000).toFixed(2) : Math.round(rawPower);
    powerUnit = rawPower > 1000 || rawPower < -1000 ? "MW" : "kW";
  }

  return `${power} ${powerUnit}`;
};

export function durationToString(duration) {
  let hours = Math.floor(duration / 3600);
  let minutes = Math.floor((duration - hours * 3600) / 60);
  if (hours && minutes) {
    return `+${hours}h${minutes}m`;
  } else if (hours) {
    return `+${hours}h`;
  } else if (minutes) {
    return `+${minutes}m`;
  } else {
    return "";
  }
}

export function durationToStringReadable(duration) {
  let hours = Math.floor(duration / 3600);
  let minutes = Math.floor((duration - hours * 3600) / 60);

  let hourWord = "hours";
  let minuteWord = "minutes";
  if (hours === 1) {
    hourWord = "hour";
  }

  if (minutes === 1) {
    minuteWord = "minute";
  }
  if (hours && minutes) {
    return `${hours} ${hourWord} ${minutes} ${minuteWord}`;
  } else if (hours) {
    return `${hours} ${hourWord}`;
  } else if (minutes) {
    return `${minutes} ${minuteWord}`;
  } else {
    return "-";
  }
}

export function durationToStringUpdate(duration) {
  if (duration === null || duration === undefined || duration === 0) {
    return "-";
  }
  let hours = Math.floor(duration / 3600);
  let minutes = Math.floor((duration - hours * 3600) / 60);
  if (hours && minutes) {
    return `${hours}h ${minutes}m`;
  } else if (hours && !minutes) {
    return `${hours}h`;
  } else if (minutes && !hours) {
    return `${minutes}m`;
  } else {
    return `${duration.toFixed(0)}s`;
  }
}

export function relativeTimeDuration(previous, timezone, format) {
  let sPerMinute = 60;
  let sPerHour = sPerMinute * 60;
  let sPerDay = sPerHour * 24;
  let sPerMonth = sPerDay * 30;
  let current = Date.now() / 1000;

  let elapsed = current - previous;
  if (elapsed < sPerMinute) {
    return "Just now";
  } else if (elapsed < sPerHour) {
    let minutes = Math.round(elapsed / sPerMinute);
    return `${minutes} minute${minutes > 1 ? "s" : ""} ago`;
  } else if (elapsed < sPerDay) {
    let hours = Math.round(elapsed / sPerHour);
    return `${hours} hour${hours > 1 ? "s" : ""} ago`;
  } else if (elapsed < sPerMonth) {
    let days = Math.round(elapsed / sPerDay);
    return `${days} day${days > 1 ? "s" : ""} ago`;
  } else {
    return getDateAsString(previous * 1000, timezone, format);
  }
}

export const componentToType = component => {
  if (component === "solar") {
    return "gross";
  } else {
    return "net";
  }
};

export function toTitleCase(str) {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}

export const getDeployment = (deployments, idx) => {
  if (deployments) {
    return deployments[idx];
  }
  return {};
};

export const getAddressLine1 = address => {
  if (address && (address.streetNumber || address.street)) {
    const streetNumber = address.streetNumber || "";
    const streetName = streetNumber && address.street ? ` ${address.street}` : address.street || "";
    return `${streetNumber}${streetName},`;
  }
  return "";
};

export const getAddressLine2 = address => {
  if (address && (address.city || address.state || address.postcode)) {
    const city = address.city || "";
    const state = address.state && city ? ` ${address.state}` : address.state || "";
    const postcode =
      (city || state) && address.postcode ? ` ${address.postcode}` : address.postcode || "";
    return `${city}${state}${postcode}`;
  }
  return "";
};

export const getCombinedAddress = address => {
  return address ? `${getAddressLine1(address)} ${getAddressLine2(address)}`.trim() : "";
};

import * as actions from "../actions/analysis";

const analysisInitialState = {
  loadingExports: false,
  exports: null,
  exportDetails: null,
  noExports: false,
  loadingSuggestions: false,
  deploymentSuggestions: [],
  loadingSelectedDeployment: false,
  selectedDeployments: [],
};

const analysisReducer = (state = analysisInitialState, action) => {
  switch (action.type) {
    case "RESET_STORE":
      return {
        ...analysisInitialState,
      };
    case actions.FETCH_CSV_EXPORTS:
      return {
        ...state,
        loadingExports: action.payload.showLoading,
      };
    case actions.UPDATE_CSV_EXPORTS:
      return {
        ...state,
        loadingExports: false,
        exports: action.payload.exports,
        noExports: false,
      };
    case actions.UPDATE_CSV_EXPORT_DETAILS:
      return {
        ...state,
        exportDetails: {
          ...state.exportDetails,
          [action.payload.taskId]: action.payload.exportDetails,
          empty: false,
          loading: false,
        },
      };
    case actions.UPDATE_CSV_EXPORTS_EMPTY:
      return {
        ...state,
        loadingExports: false,
        noExports: true,
      };
    case actions.FETCH_CSV_EXPORT_DETAILS:
      return {
        ...state,
        exportDetails: {
          ...state.exportDetails,
          loading: true,
        },
      };
    case actions.UPDATE_CSV_EXPORT_DETAILS_EMPTY:
      return {
        ...state,
        exportDetails: {
          ...state.exportDetails,
          [action.payload.taskId]: {
            empty: true,
          },
          loading: false,
        },
      };
    case actions.FETCH_CSV_EXPORT_DEPLOYMENT_SUGGESTIONS:
      return {
        ...state,
        loadingSuggestions: true,
      };
    case actions.UPDATE_CSV_EXPORT_DETAILS_FAILURE:
      return {
        ...state,
      };
    case actions.UPDATE_CSV_EXPORT_DEPLOYMENT_SUGGESTIONS:
      return {
        ...state,
        deploymentSuggestions: action.payload.deployments,
        loadingSuggestions: false,
      };

    case actions.CLEAR_CSV_EXPORT_DEPLOYMENT_SUGGESTIONS:
      return {
        ...state,
        deploymentSuggestions: [],
      };
    case actions.FETCH_CSV_EXPORT_SINGLE_DEPLOYMENT:
      return {
        ...state,
        loadingSelectedDeployment: true,
      };
    case actions.SELECT_CSV_EXPORT_DEPLOYMENT:
      // Make no changes if the deployment is already selected
      if (state.selectedDeployments.find(x => x.id === action.payload.deployment.id)) {
        return { ...state, loadingSelectedDeployment: false };
      }
      return {
        ...state,
        loadingSelectedDeployment: false,
        selectedDeployments: [...state.selectedDeployments, action.payload.deployment],
      };
    case actions.DESELECT_CSV_EXPORT_DEPLOYMENT:
      return {
        ...state,
        selectedDeployments: state.selectedDeployments.filter(
          x => x.id !== action.payload.deployment.id
        ),
      };
    case actions.DESELECT_ALL_CSV_EXPORT_DEPLOYMENT:
      return {
        ...state,
        selectedDeployments: [],
      };
    default:
      return state;
  }
};

export default analysisReducer;

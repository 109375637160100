import React, { Component } from "react";
import _ from "lodash";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import queryString from "query-string";
import { withRouter } from "react-router-dom";
import { Notify } from "../../utils/notificationManager";
import { Loading, Button, Input, InfoBox } from "../../components/widgets";
import Page from "../../components/layout/Page";
import * as powerstationActions from "../../redux/actions/powerstations";
import PowerstationType from "../../components/powerstations/PowerstationType";
import PowerstationNamePlate from "../../components/powerstations/PowerstationNamePlate";
import Permissions from "../../permissions";
import PowerstationDeploymentsList from "../../components/powerstations/PowerstationDeploymentsList";
import PowerstationDeploymentsSearch from "../../components/powerstations/PowerstationDeploymentsSearch";
import PagingControl from "../../components/widgets/PagingControl";
import TabbedContainer from "../../components/layout/TabbedContainer";

const PAGE_SIZE = 10;

const mapStateToProps = state => ({
  powerstation: state.powerstations.activePowerstation,
  deployments: state.powerstations.deployments,
  savePowerstationLoading: state.powerstations.savePowerstationLoading,
  deploymentCount: state.powerstations.deploymentCount,
  loadingDeployments: state.powerstations.loadingDeployments,
  patchError: state.powerstations.patchError,
  bulkEditActionLoading: state.powerstations.bulkEditActionLoading,
});

const mapDispatchToProps = dispatch => {
  return {
    powerstationActions: bindActionCreators(powerstationActions, dispatch),
  };
};

export class PowerstationEdit extends Component {
  constructor(props) {
    super(props);
    const powerstationUid = this.props.match.params.id;
    const search = queryString.parse(props.location.search);

    let limit = PAGE_SIZE;
    if (search.limit !== undefined) {
      limit = parseInt(search.limit, 10);
    }

    let offset = 0;
    if (search.offset !== undefined) {
      offset = parseInt(search.offset, 10);
    }
    let addressFilter = props.powerstation ? props.powerstation.filters : null;
    if (search.address_filter !== undefined) {
      addressFilter = this.parseAddressFilter(
        search.address_filter,
        this.getPowerstationType(props)
      );
    }
    let showIncludedOnly = false;
    if (search.show_included_only !== undefined) {
      showIncludedOnly = search.show_included_only === "true";
    }
    this.state = {
      powerstationId: powerstationUid,
      name: props.powerstation ? props.powerstation.name : "",
      type: powerstationUid ? this.getPowerstationType(props) : "INDIVIDUAL_NODES",
      addressFilter,
      limit,
      offset,
      filter: search.f,
      showIncludedOnly: showIncludedOnly,
    };
    this.updateLimitPerPage = this.updateLimitPerPage.bind(this);
  }

  componentDidMount = () => {
    if (this.state.powerstationId) {
      this.props.powerstationActions.fetchActivePowerstation(this.state.powerstationId);
    }
  };

  componentWillUnmount = () => {
    this.props.powerstationActions.clearActivePowerstation();
  };

  componentWillReceiveProps = nextProps => {
    const nextSearch = queryString.parse(nextProps.location.search);
    const powerstationType = this.getPowerstationType(nextProps);
    if (
      nextProps.powerstation &&
      (!this.props.powerstation || this.props.powerstation.id !== nextProps.powerstation.id)
    ) {
      let addressFilter = nextProps.powerstation.filters;
      if (nextSearch.address_filter !== undefined) {
        addressFilter = this.parseAddressFilter(nextSearch.address_filter, powerstationType);
      }
      this.setState({
        type: powerstationType,
        addressFilter,
      });
      this.fetchDeployments(
        this.state.powerstationId,
        nextSearch.limit,
        nextSearch.offset,
        nextSearch.f,
        powerstationType,
        addressFilter,
        nextSearch.show_included_only === "true"
      );
    } else {
      if (
        this.props.location.search &&
        nextProps.location.search &&
        this.props.location.search !== nextProps.location.search
      ) {
        this.fetchDeployments(
          this.state.powerstationId,
          nextSearch.limit,
          nextSearch.offset,
          nextSearch.f,
          powerstationType,
          this.parseAddressFilter(nextSearch.address_filter) || this.state.addressFilter,
          nextSearch.show_included_only === "true"
        );
      }
    }

    if (this.props.powerstation !== nextProps.powerstation && nextProps.powerstation) {
      this.setState({
        name: nextProps.powerstation.name,
      });
    }

    if (this.props.deployments !== nextProps.deployments) {
      this.setState({
        fetchingFilteredDeployments: false,
      });
    }
  };

  fetchDeployments(
    powerstationId,
    limit,
    offset,
    filter,
    powerstationType,
    addressFilter,
    showIncludedOnly
  ) {
    this.props.powerstationActions.setLoading();
    if (this.refreshTimeout) {
      clearTimeout(this.refreshTimeout);
    }

    // We do a half second debounce here to catch quickly paging through pages and quickly toggling filters.
    this.refreshTimeout = setTimeout(() => {
      if (powerstationType === "INDIVIDUAL_NODES") {
        this.props.powerstationActions.fetchDeployments(
          powerstationId,
          limit,
          offset,
          filter,
          !showIncludedOnly
        );
      } else {
        let postcodes = addressFilter.postcodes ? _.join(addressFilter.postcodes) : null;
        // if dynamic postcode based power station is changed to have an empty postcode filter then
        // make sure it will retrieve nothing
        if (powerstationType === "POSTCODE" && _.isEmpty(_.trim(_.join(addressFilter.postcodes)))) {
          postcodes = "NULL";
        }
        this.props.powerstationActions.fetchNameplate(addressFilter.state, postcodes);
        this.props.powerstationActions.fetchDynamicDeployments(
          limit,
          offset,
          filter,
          addressFilter.state,
          postcodes
        );
      }
    }, 500);

    const parsedOffset = parseInt(offset, 10);
    this.setState({
      limit: parseInt(limit, 10) || this.state.limit,
      offset: parsedOffset || parsedOffset === 0 ? parsedOffset : this.state.offset,
      filter,
      showIncludedOnly,
    });
  }

  getPage = (limit, offset, filter) => {
    this.updateUrl(limit, offset, filter, this.getAddressFilterParam());
  };

  updateUrl = (limit, offset, filter, addressFilter, showIncludedOnly) => {
    let url = `${this.props.location.pathname}?`;
    if (filter) {
      url += queryString.stringify({ f: filter });
    }
    if (limit) {
      url += `&limit=${limit}`;
    }
    if (offset || offset === 0) {
      url += `&offset=${offset}`;
    }
    if (addressFilter) {
      url += `&${queryString.stringify({ address_filter: addressFilter })}`;
    }
    if (showIncludedOnly !== undefined) {
      url += `&show_included_only=${showIncludedOnly}`;
    }
    this.props.history.push(url);
  };

  updateLimitPerPage = limit => {
    this.setState({ limit });
  };

  getPowerstationType = props => {
    if (!props.powerstation) {
      return;
    }

    if (props.powerstation.type === "DYNAMIC") {
      return props.powerstation.filters.state ? "STATE" : "POSTCODE";
    } else {
      return "INDIVIDUAL_NODES";
    }
  };

  handleNameOnChange = event => {
    const nextName = event.target.value;
    this.setState({ name: nextName });
    if (nextName !== null && nextName.trim() !== "") {
      this.setState({ validationMessage: null });
    }
  };

  parseAddressFilter = (value, type) => {
    let filters;
    const powerstationType = type ? type : this.state ? this.state.type : null;
    if (powerstationType === "POSTCODE") {
      filters = {
        postcodes: value ? value.split(",") : [],
        state: null,
      };
    }

    if (powerstationType === "STATE") {
      filters = {
        postcodes: [],
        state: value ? value : null,
      };
    }
    return filters;
  };

  getAddressFilterParam = () => {
    if (this.state.type === "POSTCODE") {
      return _.join(this.state.addressFilter.postcodes);
    }
    if (this.state.type === "STATE") {
      return this.state.addressFilter.state;
    }
    return null;
  };

  handleFilterOnChange = value => {
    const filters = this.parseAddressFilter(value);
    this.setState({
      fetchingFilteredDeployments: true,
      powerstationTypeError: null,
      addressFilter: filters,
    });
    this.updateUrl(this.state.limit, 0, this.state.filter, value);
  };

  toggleDeploymentIncluded = (deploymentId, included) => {
    this.props.powerstationActions.patchPowerstation(
      this.state.powerstationId,
      included ? "remove" : "add",
      [deploymentId]
    );
  };

  savePowerstation = () => {
    if (this.state.name === null || this.state.name.trim() === "") {
      this.setState({
        validationMessage: "You must enter a Virtual Power Plant name.",
      });
      Notify.error(
        "Virtual Power Plant invalid",
        "Please ensure your virtual power plant has a name"
      );
      return;
    } else {
      this.setState({ validationMessage: null });
    }

    if (
      this.state.type === "POSTCODE" &&
      _.isEmpty(_.trim(_.join(this.state.addressFilter.postcodes)))
    ) {
      this.setState({ powerstationTypeError: "You must enter a value for postcode" });
      Notify.error(
        "Virtual Power Plant invalid",
        "Please ensure your virtual power plant has a postcode"
      );
      return;
    }

    const powerstation = this.props.powerstation;

    let saveFunction;
    if (this.state.powerstationId) {
      saveFunction = this.props.powerstationActions.fetchEditPowerstation;
    } else {
      saveFunction = this.props.powerstationActions.fetchNewPowerstation;
    }

    const powerstationUid = powerstation ? powerstation.id : null;
    if (this.state.type === "INDIVIDUAL_NODES") {
      this.props.powerstationActions.patchPowerstation(
        this.state.powerstationId,
        null,
        null,
        null,
        this.state.name
      );
    } else {
      saveFunction("DYNAMIC", this.state.name, [], powerstationUid, this.state.addressFilter);
    }
  };

  getNamePlateData = () => {
    let capacity = 0,
      inverterPower = 0,
      batteryPower = 0,
      deploymentCount = 0;
    if (this.props.powerstation) {
      capacity = this.props.powerstation.namePlate.batteryCapacity;
      inverterPower = this.props.powerstation.namePlate.inverterPower;
      batteryPower = this.props.powerstation.namePlate.batteryPower;
      deploymentCount = this.props.powerstation.namePlate.totalDeployments;
    }
    return {
      capacity,
      inverterPower,
      batteryPower,
      deploymentCount,
    };
  };

  handleDeploymentFilterChange = filter => {
    this.updateUrl(this.state.limit, 0, filter, this.getAddressFilterParam());
  };

  addAll = () => {
    this.props.powerstationActions.patchPowerstation(
      this.state.powerstationId,
      "add",
      null,
      this.state.filter
    );
  };

  removeAll = () => {
    this.props.powerstationActions.patchPowerstation(
      this.state.powerstationId,
      "remove",
      null,
      this.state.filter
    );
  };

  addCurrentPage = () => {
    this.props.powerstationActions.patchPowerstation(
      this.state.powerstationId,
      "add",
      _.map(this.props.deployments, d => d.id)
    );
  };

  removeCurrentPage = () => {
    this.props.powerstationActions.patchPowerstation(
      this.state.powerstationId,
      "remove",
      _.map(this.props.deployments, d => d.id)
    );
  };

  onChangeIncludedOnly = () => {
    this.updateUrl(
      this.state.limit,
      0,
      this.state.filter,
      this.getAddressFilterParam(),
      !this.state.showIncludedOnly
    );
  };

  render() {
    if (this.state.powerstationId && !this.props.powerstation) {
      return <Loading />;
    }

    const { capacity, inverterPower, batteryPower, deploymentCount } = this.getNamePlateData();

    return (
      <>
        <Page key="powerstation-edit" permission={Permissions.fleet.powerstations.edit}>
          <Page.Header>
            <Page.Header.Title>
              <Input
                style={{ fontSize: "1em", fontWeight: 300, width: "32rem" }}
                value={this.state.name}
                onChange={this.handleNameOnChange}
                error={this.state.validationMessage}
                placeholder="Virtual Power Plant name"
              />
            </Page.Header.Title>
            <Page.Header.Actions>
              <Button
                id="cancel-edit-powerstation"
                type="secondary"
                icon="times"
                to={
                  this.state.powerstationId
                    ? `/virtual-power-plants/manage/${this.state.powerstationId}?limit=10&offset=0`
                    : "/virtual-power-plants/manage?limit=10&offset=0"
                }
              >
                Cancel
              </Button>
              <Button
                id="save-powerstation"
                type="primary"
                icon="check"
                onClick={this.savePowerstation}
                loading={this.props.savePowerstationLoading}
              >
                Save
              </Button>
            </Page.Header.Actions>
          </Page.Header>
          <Page.Content>
            <PowerstationType
              type={this.state.type}
              handleFilterOnChange={this.handleFilterOnChange}
              filters={this.state.addressFilter}
              filterTypeReadOnly={true}
              error={this.state.powerstationTypeError}
            />
            <PowerstationNamePlate
              capacity={capacity}
              inverterPower={inverterPower}
              batteryPower={batteryPower}
              deploymentCount={deploymentCount}
              loading={this.state.fetchingFilteredDeployments}
              className="no-border"
            />
            <div>
              <TabbedContainer id="powerstation-node-list" initialTabNumber={0}>
                <TabbedContainer.Tab
                  label="Nodes"
                  onActivate={() => {}}
                  style={{ backgroundColor: "#F7F9FD" }}
                >
                  {this.state.type === "INDIVIDUAL_NODES" ? (
                    <InfoBox type="info" style={{ margin: "10px" }}>
                      You are editing a Virtual Power Plant made up of individual nodes. Any changes
                      you make below to add or remove a node will be saved immediately.
                    </InfoBox>
                  ) : (
                    <InfoBox type="info" style={{ margin: "10px" }}>
                      You are editing a Virtual Power Plant with automatic enrolment. You cannot add
                      or remove nodes manually.
                    </InfoBox>
                  )}
                  <PowerstationDeploymentsSearch
                    deploymentFilter={this.state.filter}
                    onDeploymentFilterChange={this.handleDeploymentFilterChange}
                    showIncludedNodesCheckbox={this.state.type === "INDIVIDUAL_NODES"}
                    showIncludedOnly={this.state.showIncludedOnly}
                    onIncludedOnlyChange={this.onChangeIncludedOnly}
                    autoFocus={this.state.type === "POSTCODE" ? false : true}
                  />
                  {!this.props.loadingDeployments ? (
                    <>
                      <div>
                        <PowerstationDeploymentsList
                          selectable={this.state.type === "INDIVIDUAL_NODES"}
                          onToggleDeploymentIncluded={
                            this.state.type === "INDIVIDUAL_NODES"
                              ? this.toggleDeploymentIncluded
                              : null
                          }
                          deployments={this.props.deployments}
                          addAll={this.addAll}
                          addCurrentPage={this.addCurrentPage}
                          removeAll={this.removeAll}
                          removeCurrentPage={this.removeCurrentPage}
                          bulkEditActionLoading={this.props.bulkEditActionLoading}
                          buldEditError={this.props.patchError}
                          includedDeploymentCount={
                            this.props.powerstation.namePlate.totalDeployments
                          }
                        />
                      </div>
                      <PagingControl
                        key="deploymentEditPaging"
                        limit={this.state.limit}
                        offset={this.state.offset}
                        totalItems={this.props.deploymentCount}
                        itemName="deployment"
                        onPageChange={(limit, offset) =>
                          this.getPage(limit, offset, this.state.filter)
                        }
                        onChangeResultsPerPage={this.updateLimitPerPage}
                        orientation="up"
                      />
                    </>
                  ) : (
                    <Loading className="loading-inline" />
                  )}
                </TabbedContainer.Tab>
              </TabbedContainer>
            </div>
          </Page.Content>
        </Page>
      </>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PowerstationEdit));

import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Page from "../../components/layout/Page";
import { Loading } from "../../components/widgets";
import * as gridOutageActions from "../../redux/actions/gridoutage";
import Permissions from "../../permissions";
import GridOutageOverview from "../../components/gridoutage/GridOutageOverview";
import AuthRequired from "../AuthRequired";
import Button from "../../components/widgets/Button";
import { confirm } from "../../utils/dialogs";
import { User } from "../smartWidgets";

const mapStateToProps = state => ({
  outage: state.gridOutages.activeGridOutage,
  powerstations: state.gridOutages.powerstations,
  user: state.user,
});

const mapDispatchToProps = dispatch => {
  return {
    gridOutageActions: bindActionCreators(gridOutageActions, dispatch),
  };
};

class GridOutage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      gridOutageId: this.props.match.params.id,
    };
  }

  refreshTimeouts = {};

  componentDidMount = () => {
    this.props.gridOutageActions.fetchActiveGridOutage(this.state.gridOutageId);
    if (!this.props.powerstations) {
      this.props.gridOutageActions.fetchOutagePowerstations();
    }
    this.fetchGridOutageData(this.props);
    if (this.props.outage) {
      this.setupRefreshGridOutageTimeout("gridOutage", this.fetchGridOutage, this.props);
    }
  };

  componentWillReceiveProps = nextProps => {
    if (!this.props.outage && nextProps.outage) {
      this.setupRefreshGridOutageTimeout("gridOutage", this.fetchGridOutage, nextProps);
    }

    if (this.props.outage && this.props.outage.response !== nextProps.outage.response) {
      this.setupRefreshGridOutageTimeout("response", this.fetchGridOutageData, nextProps);
    }
  };

  setupRefreshGridOutageTimeout = (timeoutId, callback, props) => {
    if (this.refreshTimeouts[timeoutId]) {
      clearTimeout(this.refreshTimeouts[timeoutId]);
    }
    const nowTs = Math.round(new Date().valueOf() / 1000);
    const secondsSinceCreated = nowTs - props.outage.createdAt;
    // If we are still on this page once a dispatch is complete, default to every 5 minutes
    let refreshInterval = 300000;
    if (secondsSinceCreated < 60) {
      // If we are within 1 minute of creating the dispatch then poll every 2 seconds to see the
      // nodes responded number clock up.
      // Most systems respond well within 60 seconds.
      refreshInterval = 2000; // 2 seconds
    } else {
      if (props.outage.state === "INPROGRESS" || props.outage.state === "UPCOMING") {
        refreshInterval = 10000; // 10 seconds
      }
    }

    // this.refreshTimeout = setTimeout(this.refreshGridOutage, refreshInterval);
    this.refreshTimeouts[timeoutId] = setTimeout(callback, refreshInterval);
  };

  fetchGridOutage = () => {
    this.props.gridOutageActions.fetchActiveGridOutage(this.state.gridOutageId);
  };

  fetchGridOutageData = () => {
    this.props.gridOutageActions.fetchGridOutageResponse(this.state.gridOutageId);
  };

  componentWillUnmount = () => {
    this.props.gridOutageActions.clearActiveGridOutage();
    if (this.refreshTimeout) {
      clearTimeout(this.refreshTimeout);
    }
  };

  cancelGridOutage = () => {
    confirm("grid-outage-cancel", "Are you sure you want to cancel this grid outage event?")
      .then(() => {
        this.props.gridOutageActions.cancelGridOutage(this.state.gridOutageId);
      })
      .catch(() => null);
  };

  render() {
    if (!this.props.outage || !this.props.powerstations) {
      return <Loading />;
    }

    if (!this.props.outage.createdAt) {
      return <Loading />;
    }

    const outage = this.props.outage;
    return (
      <Page key="outage" permission={Permissions.fleet.powerstations.gridOutages.any}>
        <Page.Header
          title={`${outage.reason ? outage.reason : "Unknown Grid Outage"} at ${
            outage.powerstation
          }`}
        >
          <AuthRequired permission={Permissions.fleet.powerstations.gridOutages.edit}>
            {outage.state === "INPROGRESS" || outage.state === "UPCOMING" ? (
              <Page.Header.Actions>
                <Button
                  id="cancel-grid-outage"
                  type="danger"
                  icon="trash-o"
                  onClick={this.cancelGridOutage}
                >
                  Cancel
                </Button>
              </Page.Header.Actions>
            ) : null}
          </AuthRequired>
          <Page.SubHeading>
            Created by:
            <User userId={outage.createdBy} />
          </Page.SubHeading>
        </Page.Header>
        <Page.Content>
          {this.props.outage.response ? (
            <GridOutageOverview
              gridOutage={this.props.outage}
              timezone={this.props.user.account.timezone}
            />
          ) : null}
        </Page.Content>
      </Page>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(GridOutage);

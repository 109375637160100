import React, { Component } from "react";
import { getDateAsString } from "../widgets/Moment";

import "./ExportItemHeader.css";
import { CUSTOMER_TASK, getMetricDisplayName } from "../../utils/analysis";

export class ExportItemHeader extends Component {
  getStatus = state => {
    switch (state) {
      case "SUCCESS":
        return "Completed";
      case "PENDING":
        return "Submitted";
      case "PROGRESS":
        return "In Progress";
      case "FAILURE":
        return "Failed";
      case "REMOVED":
        return "Removed";
      case "CANCELLED":
        return "Cancelled";
      default:
        return "";
    }
  };

  formatMetrics = metrics => {
    if (metrics)
      return metrics.map((metric, i) => (
        <span className="single-metric" key={i}>
          {getMetricDisplayName(metric)}
          {i < metrics.length - 1 ? ",\u00A0" : ""}
        </span>
      ));
  };

  summaryBlurb = () => {
    let base = "For customers ";
    if (
      this.props.suborgType === "NETWORK" &&
      this.props.powerstation === "" &&
      this.props.taskInfo.all_customers
    ) {
      return `${base} on my network`;
    } else if (this.props.powerstation) {
      return `For ${this.props.powerstation}`;
    } else {
      return `${base} in Fleet`;
    }
  };

  render() {
    const startDate = new Date(parseInt(this.props.start * 1000, 10));
    const endDate = new Date(parseInt(this.props.end * 1000, 10));
    const startDateString = getDateAsString(startDate, this.props.timezone, "DD/MM/YY");
    const endDateString = getDateAsString(endDate, this.props.timezone, "DD/MM/YY");
    if (this.props.taskType === CUSTOMER_TASK) {
      return (
        <div className="analysis-exports-heading" {...this.props}>
          <span className="date">{this.props.date}</span>
          <span className="export-type-customer">Customer</span>
          <span className="summary-blurb">{this.summaryBlurb()}</span>
          <span className="expand-icon">
            <i className="fa fa-angle-double-down" />
          </span>
          <span className="status">{this.getStatus(this.props.status)}</span>
        </div>
      );
    } else {
      return (
        <div className="analysis-exports-heading" {...this.props}>
          <span className="date">{this.props.date}</span>
          <span className="export-type-operational">Operational</span>
          <span className="powerstation-name">{`For ${this.props.powerstation} from ${startDateString} to ${endDateString}`}</span>
          <span className="metrics"> {this.formatMetrics(this.props.metrics)}</span>
          <span className="expand-icon">
            <i className="fa fa-angle-double-down" />
          </span>
          <span className="status">{this.getStatus(this.props.status)}</span>
        </div>
      );
    }
  }
}

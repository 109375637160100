import React from "react";
import moment from "moment-timezone";
import "./Moment.css";

export const getDateAsString = (date, tz, format) => {
  const formatToUse = format ? format : "D MMMM";
  let momentDate;
  if (moment.isMoment(date)) {
    momentDate = date;
  } else {
    momentDate = moment(date).tz(tz);
  }
  return momentDate.format(formatToUse);
};

export const getTimeAsString = (date, tz, format) => {
  const formatToUse = format ? format : "h:mm a";
  let momentDate;
  if (moment.isMoment(date)) {
    momentDate = date;
  } else {
    momentDate = moment(date).tz(tz);
  }
  return momentDate.format(formatToUse);
};

export const getRelativeString = date => {
  let momentDate;
  if (moment.isMoment(date)) {
    momentDate = date;
  } else {
    momentDate = moment(date);
  }
  return momentDate.fromNow();
};

export const MomentDate = ({ date, timezone, format }) => (
  <span className="moment-date">{getDateAsString(date, timezone, format)}</span>
);

export const MomentTime = ({ date, timezone, format }) => (
  <span className="moment-time">{getTimeAsString(date, timezone, format)}</span>
);

export const MomentRelative = ({ date }) => (
  <span className="moment-time" title={date}>
    {getRelativeString(date)}
  </span>
);

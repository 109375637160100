export const applicableDaysToList = applicableDays => {
  const days = [];
  for (let day in applicableDays) {
    if (applicableDays[day]) {
      if (day === "publicHolidays") {
        days.push("PUBLIC_HOLIDAYS");
      } else {
        days.push(day.toUpperCase());
      }
    }
  }
  return days;
};

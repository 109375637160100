const Permissions = {
  fleet: {
    any: "fleet.*",
    administrator: "fleet.administrator",
    zendesk: {
      edit: "fleet.zendesk.edit",
      any: "fleet.zendesk.*",
    },
    myReposit: {
      users: "fleet.my_reposit.users",
      modules: "fleet.my_reposit.modules",
      billing: "fleet.my_reposit.billing",
      any: "fleet.my_reposit.*",
    },
    status: {
      view: "fleet.status.view",
      any: "fleet.status.*",
      customerData: {
        view: "fleet.status.customer_data.view",
        debug: "fleet.status.customer_data.debug",
        any: "fleet.status.customer_data.*",
      },
    },
    node: {
      any: "fleet.node.*",
      view: "fleet.node.view",
      data: {
        any: "fleet.node.data.*",
        view: "fleet.node.data.view",
      },
      sga_data: {
        view: "fleet.node.sga_data.view",
      },
      inverter: {
        portal: "fleet.node.inverter.portal",
        any: "fleet.node.inverter.*",
      },
    },
    powerstations: {
      view: "fleet.powerstations.view",
      edit: "fleet.powerstations.edit",
      any: "fleet.powerstations.*",
      data: {
        view: "fleet.powerstations.data.view",
      },
      gridOutages: {
        edit: "fleet.powerstations.grid_outages.edit",
        view: "fleet.powerstations.grid_outages.view",
        any: "fleet.powerstations.grid_outages.*",
      },
    },
    rert: {
      any: "fleet.rert.*",
      dispatches: {
        view: "fleet.rert.dispatches.view",
        edit: "fleet.rert.dispatches.edit",
        any: "fleet.rert.dispatches.*",
      },
    },
    dispatches: {
      view: "fleet.dispatches.view",
      edit: "fleet.dispatches.edit",
      powerFactor: "fleet.dispatches.power_factor",
      marketTrader: "fleet.dispatches.market_trader",
      strategy: "fleet.dispatches.strategy",
      any: "fleet.dispatches.*",
    },
    curtailments: {
      view: "fleet.curtailments.view",
      edit: "fleet.curtailments.edit",
      any: "fleet.curtailments.*",
    },
    billing: {
      gridcredits: {
        view: "fleet.billing.gridcredits.view",
      },
    },
    analysis: {
      export: {
        edit: "fleet.analysis.export.edit",
      },
    },
    customerInformation: {
      view: "fleet.customer_information.view",
      edit: "fleet.customer_information.edit",
      any: "fleet.customer_information.*",
      contracts: {
        view: "fleet.customer_information.contracts.view",
        edit: "fleet.customer_information.contracts.edit",
        any: "fleet.customer_information.contracts.*",
      },
      plans: {
        view: "fleet.customer_information.plans.view",
        edit: "fleet.customer_information.plans.edit",
        any: "fleet.customer_information.plans.*",
      },
    },
    kitDesigner: "fleet.kit_designer",
  },
};

export default Permissions;

const matchEqual = (permOne, permTwo, checkType) => {
  return permOne === permTwo;
};

const matchStartsWith = (permOne, permTwo, checkType) => {
  const perm = permOne.substring(0, permOne.length - 1);
  return permTwo.startsWith(perm);
};

const getMatchFunction = perm => {
  return perm.endsWith("*") ? matchStartsWith : matchEqual;
};

export function hasPermission(userPermissions, permission) {
  let permissionAuthorized;
  // If the `permission` props is used then were checking that the user has a single prop.
  if (permission) {
    for (let userPerm in userPermissions) {
      const isMatch = getMatchFunction(permission);
      if (isMatch(permission, userPermissions[userPerm])) {
        permissionAuthorized = true;
        break;
      }
    }
  } else {
    permissionAuthorized = true;
  }

  return permissionAuthorized;
}

export function anyPermissions(userPermissions, any) {
  let anyAuthorized;

  // If the any prop is specified then we need to check if the user has at least one of those props.
  if (any) {
    // eslint-disable-next-line
    outerloop: for (let anyPerm in any) {
      const isMatch = getMatchFunction(any[anyPerm]);
      for (let i in userPermissions) {
        if (isMatch(any[anyPerm], userPermissions[i])) {
          anyAuthorized = true;
          // eslint-disable-next-line
          break outerloop;
        }
      }
    }
  } else {
    anyAuthorized = true;
  }

  return anyAuthorized;
}

export function allPermissions(userPermissions, all) {
  let allAuthorized;

  // If the all prop is specified then we need to check if the user has all of the specified props.
  if (all) {
    let allFound = true;
    for (let allPerm in all) {
      const isMatch = getMatchFunction(all[allPerm]);
      let permFound = false;
      for (let i in userPermissions) {
        if (isMatch(all[allPerm], userPermissions[i])) {
          permFound = true;
          break;
        }
      }
      if (!permFound) {
        allFound = false;
        break;
      }
    }

    if (allFound) {
      allAuthorized = true;
    }
  } else {
    allAuthorized = true;
  }

  return allAuthorized;
}

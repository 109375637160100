import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Modal } from "../../components/layout/Modal";
import { Button, Loading } from "../../components/widgets";
import NewCurtailment from "../../components/curtailment/NewCurtailment";
import NewCurtailmentGraph from "../../components/curtailment/NewCurtailmentGraph";
import * as feedInManagementActions from "../../redux/actions/feedinmanagement";
import moment from "moment-timezone";
import NewCurtailmentExecuteConfirmation from "../../components/curtailment/NewCurtailmentExecuteConfirmation";

const DEFAULT_CURTAILMENT_START_OFFSET = 14400; // Use 4 hours from now
const DEFAULT_CURTAILMENT_DURATION = 14400; // 4 hours

const mapStateToProps = state => ({
  curtailments: state.feedInManagement.curtailments,
  powerstations: state.feedInManagement.powerstations,
  user: state.user,
  newCurtailment: state.feedInManagement.newCurtailment,
  constraintsLoading: state.feedInManagement.constraintsLoading,
  predictionsLoading: state.feedInManagement.predictionsLoading,
  executeCurtailmentLoading: state.feedInManagement.executeCurtailmentLoading,
});

const mapDispatchToProps = dispatch => {
  return {
    feedInManagementActions: bindActionCreators(feedInManagementActions, dispatch),
  };
};

class NewCurtailmentModal extends Component {
  constructor(props) {
    super(props);

    let curtailableVPP = true;
    for (let i in this.props.powerstations) {
      if (this.props.powerstations[i].id === props.powerstationId) {
        curtailableVPP = this.props.powerstations[i].nodes.length > 0;
      }
    }
    const now = new Date();
    const nowTimestamp = Math.round(now.getTime() / 1000); // Get now as seconds (JS uses ms).
    let startTime = nowTimestamp + DEFAULT_CURTAILMENT_START_OFFSET;
    startTime = startTime - (startTime % 3600); // Round down to nearest hour.
    const timezone = this.props.user.account.timezone;
    let startDate = moment(new Date(startTime * 1000));

    const momentNow = moment().tz(this.props.user.account.timezone);
    momentNow.minutes(0).seconds(0);

    this.state = {
      powerstationId: props.powerstationId ? props.powerstationId : null,
      allowPowerstationEdit: !props.powerstationId,
      startDateTime: startDate.tz(timezone),
      graphStartDateTime: momentNow,
      duration: DEFAULT_CURTAILMENT_DURATION,
      realPowerP: 0,
      component: "grid",
      curtailableVPP,
    };
  }

  componentDidMount = () => {
    if (this.state.powerstationId) {
      const { powerstationId, duration, graphStartDateTime } = this.state;
      this.fetchCurtailmentData(powerstationId, duration, graphStartDateTime);
    }
  };

  componentWillUnmount = () => {
    this.props.feedInManagementActions.clearNewCurtailmentGraphData();
  };

  componentWillReceiveProps = nextProps => {
    // only allow a curtailment if the powerstation has nodes, and there is enough system_min_p to do so
    if (nextProps.newCurtailment) {
      let constraints = nextProps.newCurtailment.constraints;
      for (let i in nextProps.powerstations) {
        if (nextProps.powerstations[i].id === this.state.powerstationId) {
          this.setState({
            curtailableVPP: this.props.powerstations[i].nodes.length > 0 && constraints !== 0,
          });
        }
      }
    }
  };

  fetchCurtailmentData = (powerstationId, duration, startTime) => {
    this.props.feedInManagementActions.fetchNewCurtailmentConstraints(powerstationId);
    this.props.feedInManagementActions.fetchNewCurtailmentPredictions(
      powerstationId,
      duration,
      startTime
    );
  };

  handlePowerstationChange = value => {
    this.props.feedInManagementActions.clearNewCurtailmentGraphData();
    const { duration, startDateTime } = this.state;

    this.setState(
      {
        powerstationId: value,
        realPowerP: 0,
      },
      () => {
        this.fetchCurtailmentData(value, duration, startDateTime.unix());
      }
    );
  };

  handleStartDateTimeChange = (startDateTime, isBrushChange) => {
    let callback = () => {};
    let graphStartDateTime;
    if (!this.state.startDateTime.isSame(startDateTime, "day") && !isBrushChange) {
      this.props.feedInManagementActions.clearNewCurtailmentGraphData();
      const { powerstationId, duration } = this.state;
      callback = () => {
        this.fetchCurtailmentData(powerstationId, duration, this.getGraphStartTime(startDateTime));
      };
      graphStartDateTime = this.getGraphStartTime(startDateTime);
    } else {
      callback = this.updateConstraints();
      graphStartDateTime = this.state.graphStartDateTime;
    }

    if (startDateTime.diff(graphStartDateTime) < 0) {
      startDateTime = graphStartDateTime.clone();
    }

    this.setState(
      {
        startDateTime: startDateTime,
        graphStartDateTime: graphStartDateTime,
      },
      callback
    );
  };

  handleDurationChange = duration => {
    this.setState(
      {
        duration: duration,
      },
      this.updateConstraints()
    );
  };

  handleRealPowerPOnChange = realPowerP => {
    const constraints = this.props.newCurtailment.constraints;
    const range = constraints.max - constraints.min;
    const snapPercent = 0.04;

    let nextRealPowerP = realPowerP;
    if (range <= 0) {
      nextRealPowerP = realPowerP;
    } else if (realPowerP + range * snapPercent > constraints.max) {
      nextRealPowerP = constraints.max;
    } else if (realPowerP - range * snapPercent < constraints.min) {
      nextRealPowerP = constraints.min;
    } else if (Math.abs(realPowerP) < range * snapPercent) {
      nextRealPowerP = 0;
    }

    this.setState(
      {
        realPowerP: nextRealPowerP,
      },
      this.updateConstraints
    );
  };

  updateConstraints = () => {
    if (this.updateConstraintsTimeout) {
      clearTimeout(this.updateConstraintsTimeout);
    }

    this.updateConstraintsTimeout = setTimeout(() => {
      this.props.feedInManagementActions.fetchNewCurtailmentConstraints(
        this.state.powerstationId,
        this.state.duration
      );
    }, 300);
  };

  getGraphStartTime = startDateTime => {
    const now = moment().tz(this.props.user.account.timezone);
    if (startDateTime.isSame(now, "day")) {
      return now.minutes(0).seconds(0);
    } else {
      return startDateTime.clone().hours(0).minutes(0).seconds(0);
    }
  };

  executeCurtailment = () => {
    this.props.feedInManagementActions.executeCurtailment(
      this.state.powerstationId,
      this.state.startDateTime.unix(),
      this.state.duration,
      this.state.realPowerP,
      this.state.component
    );
  };

  showExecuteConfirmation = () => {
    this.setState({
      showExecuteConfirmation: true,
    });
  };

  hideExecuteConfirmation = () => {
    this.setState({
      showExecuteConfirmation: false,
    });
  };

  renderFooter(isLoading) {
    if (this.state.showExecuteConfirmation) {
      return (
        <>
          <Button
            id="back-to-curtailment-edit-modal"
            type="secondary"
            onClick={this.hideExecuteConfirmation}
            icon="chevron-left"
          >
            Back
          </Button>
          {(this.props.powerstations || !this.state.allowPowerstationEdit) &&
          this.state.powerstationId ? (
            <>
              <div>
                All times are in <Link to="/user/settings">{this.props.user.account.timezone}</Link>
              </div>
              <Button
                id="add-new-curtailment-modal"
                type="primary"
                onClick={this.executeCurtailment}
                icon="bolt"
                loading={isLoading}
              >
                Execute
              </Button>
            </>
          ) : null}
        </>
      );
    } else {
      return (
        <>
          <Button
            id="cancel-new-curtailment-modal"
            type="secondary"
            onClick={this.props.handleOnCancel}
            icon="times"
          >
            Cancel
          </Button>
          {(this.props.powerstations || !this.state.allowPowerstationEdit) &&
          this.state.powerstationId &&
          this.state.curtailableVPP ? (
            <>
              <div>
                All times are in <Link to="/user/settings">{this.props.user.account.timezone}</Link>
              </div>
              <Button
                id="confirm-curtailment-modal"
                type="primary"
                onClick={this.showExecuteConfirmation}
                icon="chevron-right"
                loading={isLoading}
              >
                Confirm
              </Button>
            </>
          ) : null}
        </>
      );
    }
  }

  handleComponentChange = newComponent => {
    this.setState({
      component: newComponent,
    });
  };

  render() {
    const newCurtailment = this.props.newCurtailment;
    const isLoading = this.props.constraintsLoading || this.props.executeCurtailmentLoading;
    return (
      <Modal>
        <Modal.Header title="New Curtailment" />
        <Modal.Content>
          <div
            style={{
              opacity: this.state.showExecuteConfirmation ? 1 : 0,
              position: this.state.showExecuteConfirmation ? "relative" : "absolute",
              top: 0,
              left: 0,
              width: "100%",
              transition: "all 0.5s ease-in-out",
              zIndex: this.state.showExecuteConfirmation ? 999999 : -999999,
            }}
          >
            <NewCurtailmentExecuteConfirmation
              powerstations={this.props.powerstations}
              powerstation={this.state.powerstationId}
              startDateTime={this.state.startDateTime}
              duration={this.state.duration}
              timezone={this.props.user.account.timezone}
              constraints={newCurtailment ? newCurtailment.constraints : null}
              realPowerP={this.state.realPowerP}
              component={this.state.component}
            />
          </div>
          <div
            style={{
              opacity: this.state.showExecuteConfirmation ? 0 : 1,
              position: this.state.showExecuteConfirmation ? "absolute" : "relative",
              top: 0,
              left: 0,
              width: "100%",
              transition: "all 0.5s ease-in-out",
              zIndex: this.state.showExecuteConfirmation ? -999999 : 999999,
            }}
          >
            <div style={{ position: "relative" }}>
              <NewCurtailment
                powerstations={this.props.powerstations}
                powerstation={this.state.powerstationId}
                allowPowerstationEdit={this.state.allowPowerstationEdit}
                curtailableVPP={this.state.curtailableVPP}
                startDateTime={this.state.startDateTime}
                duration={this.state.duration}
                onPowerstationChange={this.handlePowerstationChange}
                handleStartDateTimeChange={this.handleStartDateTimeChange}
                handleDurationChange={this.handleDurationChange}
                timezone={this.props.user.account.timezone}
                constraints={newCurtailment ? newCurtailment.constraints : null}
                onChangeRealPowerP={this.handleRealPowerPOnChange}
                realPowerP={this.state.realPowerP}
                component={this.state.component}
                onComponentChange={this.handleComponentChange}
                userPermissions={this.props.user.permissions}
              />

              {this.state.powerstationId && this.state.curtailableVPP ? (
                newCurtailment ? (
                  <div
                    style={{
                      height: "250px",
                      width: "calc(100% - 2px)",
                      marginBottom: "4rem",
                      paddingLeft: "1px",
                    }}
                  >
                    {isLoading ? <Loading horizontal /> : <div style={{ height: "4px" }} />}
                    <NewCurtailmentGraph
                      curtailmentStartTimestamp={this.state.startDateTime.unix()}
                      startTimestamp={this.state.graphStartDateTime.unix()}
                      duration={this.state.duration}
                      timezone={this.props.user.account.timezone}
                      requestedRealPowerP={this.state.realPowerP}
                      solarPrediction={
                        newCurtailment.predictions ? newCurtailment.predictions.solar : null
                      }
                      gridPrediction={
                        newCurtailment.predictions ? newCurtailment.predictions.grid : null
                      }
                      minRealPowerP={newCurtailment.constraints}
                      handleStartDateTimeChange={this.handleStartDateTimeChange}
                      handleDurationChange={this.handleDurationChange}
                    />
                  </div>
                ) : (
                  <div style={{ position: "relative", height: "8em" }}>
                    <Loading />
                  </div>
                )
              ) : null}
            </div>
          </div>
        </Modal.Content>
        <Modal.Footer>{this.renderFooter(isLoading)}</Modal.Footer>
      </Modal>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(NewCurtailmentModal);

import React, { Component } from "react";
import { MomentDate } from "../widgets";
import "./TariffSummary.css";

class ContractsSummary extends Component {
  render() {
    const { contracts, timezone } = this.props;

    if (contracts.length === 0) {
      return (
        <div className="tariff-summary">
          <span className="no-tariff">No capability information for this customer.</span>
        </div>
      );
    }

    return (
      <div className="tariff-summary">
        <table className="table-light tariff-summary-rate-table">
          <thead>
            <tr>
              <th>Start Date</th>
              <th>End Date</th>
              <th>Capability</th>
            </tr>
          </thead>
          <tbody>
            {contracts.map((e, i) => {
              return (
                <tr>
                  <td>
                    <MomentDate date={new Date(e.startDate)} timezone={timezone} />
                  </td>
                  <td>
                    {e.endDate ? (
                      <MomentDate date={new Date(e.endDate)} timezone={timezone} />
                    ) : (
                      <em>Ongoing</em>
                    )}
                  </td>
                  <td>{e.contractName}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  }
}

export default ContractsSummary;

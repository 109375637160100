import React from "react";
import _ from "lodash";
import StatsPanel from "./StatsPanel";
import { Loading } from "../widgets";
import MonitoringGraphControls from "./MonitoringGraphControls";
import MonitoringTimeControls from "./MonitoringTimeControls";
import MonitoringGraph from "./MonitoringGraph";
import Permissions, { hasPermission } from "../../permissions";
import { withRouter } from "react-router";
import queryString from "query-string";
import moment from "moment-timezone";
import "./Monitoring.css";
import MonitoringGraphTooltip from "./MonitoringGraphTooltip";
import MonitoringGraphLine from "./MonitoringGraphLine";
import { asCapacity } from "../../utils/formatting";
import { MONITORING_INTERNAL_DATE_FORMAT } from "../../constants";

function bool(str) {
  return str === "true";
}

function getAbsoluteOffset(elem) {
  let x = 0,
    y = 0;
  while (elem) {
    x += elem.offsetLeft;
    y += elem.offsetTop;
    elem = elem.offsetParent;
  }

  return { x, y };
}

const getSummaryStatPanelData = (dateInfo, summaryData, loading) => {
  const dateInfoMoment = moment(dateInfo.string);
  const now = moment();

  let title = "";
  if (dateInfo.period === "day")
    title = dateInfoMoment.isSame(now, "day") ? "So far today..." : "Day Summary";
  if (dateInfo.period === "week")
    title = dateInfoMoment.isSame(now, "week") ? "So far this week..." : "Week Summary";
  if (dateInfo.period === "month")
    title = dateInfoMoment.isSame(now, "month") ? "So far this month..." : "Month Summary";
  if (dateInfo.period === "year")
    title = dateInfoMoment.isSame(now, "year") ? "So far this year..." : "Year Summary";

  const meterImport = summaryData.meterImport ? asCapacity(summaryData.meterImport) : "-";
  const solarGeneration = summaryData.solarGeneration
    ? asCapacity(Math.abs(summaryData.solarGeneration))
    : "-";
  const gridExport = summaryData.meterExport ? asCapacity(Math.abs(summaryData.meterExport)) : "-";
  const houseConsumption = summaryData.houseConsumption
    ? asCapacity(summaryData.houseConsumption)
    : "-";

  const data = {
    title: title,
    loading,
    color: "white",
    display: "grid",
    stats: [
      {
        label: "Grid (Import)",
        value: meterImport,
      },
      {
        label: "Solar Generation",
        value: solarGeneration,
      },
      {
        label: "Grid (Export)",
        value: gridExport,
      },
      {
        label: "Consumption",
        value: houseConsumption,
      },
    ],
  };

  return <StatsPanel {...data} />;
};

class Monitoring extends React.Component {
  constructor(props) {
    super(props);

    // Here we populate the default state from URL query params if they are there or otherwise
    // we pick sensible defaults.
    // It will be useful to copy links to share with others.
    const search = queryString.parse(props.location.search);
    const hasSgaPermission = hasPermission(
      this.props.user.permissions,
      Permissions.fleet.node.sga_data.view
    );
    const currentDateStr = search.date
      ? search.date
      : moment().format(MONITORING_INTERNAL_DATE_FORMAT);
    this.state = {
      currentDateInfo: {
        period: search.period ? search.period : "day",
        string: currentDateStr,
        timezone: props.timezone,
      },
      componentState: {
        secondaryYAxis: search.secondaryYAxis ? search.secondaryYAxis : "state_of_charge",
        system: search.system ? search.system : null,
        house: search.house ? bool(search.house) : true,
        submeters: this.getInitialSubmeterComponentState(props.components.submeters, search),
        grid: search.grid ? bool(search.grid) : true,
        solar: search.solar ? bool(search.solar) : true,
        battery: search.battery ? bool(search.battery) : true,
        sga: search.sga ? bool(search.sga) && hasSgaPermission : hasSgaPermission,
        splitByPhase: search.splitByPhase ? bool(search.splitByPhase) : false,
      },
      tooltip: {
        visible: false,
        datestamp: null,
        x: 0,
        y: 0,
      },
      graphPadding: {
        top: 5,
        left: 30,
        right: 30,
        bottom: 50,
      },
    };
  }

  componentDidMount = () => {
    this.fetchPowerData(this.state.currentDateInfo);
    if (this.state.componentState.secondaryYAxis) {
      this.fetchSecondaryData(this.state.currentDateInfo, this.state.componentState.secondaryYAxis);
    }
    this.fetchSubmeterData(this.state.currentDateInfo);
    this.fetchSummaryData(this.state.currentDateInfo);
    window.addEventListener("resize", this.updateGraphContainerDimensions);
  };

  componentDidUpdate(prevProps, prevState, snapshot) {}

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateGraphContainerDimensions);
    this.props.clearActiveMonitoringData();
  }

  getInitialSubmeterComponentState = (submeters, search) => {
    let urlSubmeters = [];
    if (search.submeters) {
      urlSubmeters = search.submeters.split(",");
    }

    let submetersComponentState = null;
    if (submeters) {
      submetersComponentState = {};
      for (let i in submeters) {
        if (submeters.hasOwnProperty(i)) {
          const submeter = submeters[i];
          submetersComponentState[submeter.id] = urlSubmeters.indexOf(submeter.id) > -1;
        }
      }
    }

    return submetersComponentState;
  };

  getPeriodTimestamps = dateInfo => {
    const start = moment(dateInfo.string).tz(dateInfo.timezone);
    const end = start.clone().add(1, dateInfo.period);
    return [start.unix(), end.unix()];
  };

  fetchPowerData = dateInfo => {
    const [startTimestamp, endTimestamp] = this.getPeriodTimestamps(dateInfo);
    this.props.fetchPowerData(startTimestamp, endTimestamp, dateInfo);
  };

  fetchSecondaryData = (dateInfo, dataType) => {
    const [startTimestamp, endTimestamp] = this.getPeriodTimestamps(dateInfo);
    this.props.fetchSecondaryData(
      startTimestamp,
      endTimestamp,
      dateInfo.period,
      dataType,
      dateInfo.string
    );
  };

  fetchSubmeterData = dateInfo => {
    const [startTimestamp, endTimestamp] = this.getPeriodTimestamps(dateInfo);
    this.props.fetchSubmeterData(startTimestamp, endTimestamp, dateInfo);
  };

  fetchSummaryData = dateInfo => {
    const start = moment.utc(dateInfo.string);
    this.props.fetchSummaryData(start.unix(), dateInfo);
  };

  setDate = dateInfo => {
    const newDateInfo = { ...this.state.currentDateInfo, ...dateInfo };
    this.setState({
      currentDateInfo: newDateInfo,
    });
    this.updateUrl(newDateInfo, this.state.componentState);
    this.fetchPowerData(newDateInfo);
    if (this.state.componentState.secondaryYAxis) {
      this.fetchSecondaryData(newDateInfo, this.state.componentState.secondaryYAxis);
    }
    this.fetchSubmeterData(newDateInfo);
    this.fetchSummaryData(newDateInfo);
  };

  setComponentState = componentState => {
    this.updateUrl(this.state.currentDateInfo, componentState);

    if (
      componentState.secondaryYAxis &&
      componentState.secondaryYAxis !== this.state.componentState.secondaryYAxis
    ) {
      this.fetchSecondaryData(this.state.currentDateInfo, componentState.secondaryYAxis);
    }

    this.setState({
      componentState: componentState,
    });
  };

  updateUrl = (dateInfo, componentState) => {
    // We want the URLs for this screen to be copy-able so that you can find a system,
    // look for data and share the URL with someone so they can see the same as you.
    const { location, history } = this.props;
    let search = queryString.parse(location.search);
    search.date = dateInfo.string;
    search.period = dateInfo.period;
    search.house = componentState.house;
    search.grid = componentState.grid;
    search.solar = componentState.solar;
    search.sga = componentState.sga;
    search.battery = componentState.battery;
    search.system = componentState.system;
    search.secondaryYAxis = componentState.secondaryYAxis;
    search.splitByPhase = componentState.splitByPhase;

    if (componentState.submeters) {
      let submeters = undefined;
      for (let submeter in componentState.submeters) {
        if (componentState.submeters[submeter]) {
          if (submeters) {
            submeters += `,${submeter}`;
          } else {
            submeters = submeter;
          }
        }
      }
      search.submeters = submeters;
    }

    const stringifiedSearch = queryString.stringify(search);
    history.replace(`${location.pathname}?${stringifiedSearch}`);
  };

  updateGraphContainerDimensions = () => {
    if (this.graphContainer) {
      this.setState({
        graphWidth: this.graphContainer.clientWidth,
        graphHeight: this.graphContainer.clientHeight,
        graphOffset: getAbsoluteOffset(this.graphContainer),
      });
    }
  };

  registerGraphContainer = graphContainer => {
    this.graphContainer = graphContainer;
    this.updateGraphContainerDimensions();
  };

  getGraphData = () => {
    const start = this.state.currentDateInfo.string;
    const period = this.state.currentDateInfo.period;

    if (this.props.data) {
      if (this.props.data[period]) {
        if (this.props.data[period][start]) {
          return this.props.data[period][start];
        }
      }
    }

    return {};
  };

  getSummaryData = () => {
    const start = this.state.currentDateInfo.string;
    const period = this.state.currentDateInfo.period;

    if (this.props.data) {
      if (this.props.data[period]) {
        if (this.props.data[period][start]) {
          if (this.props.data[period][start].summary) {
            return this.props.data[period][start].summary;
          }
        }
      }
    }

    return {};
  };

  getXDomain = dateInfo => {
    const [startTimestamp, endTimestamp] = this.getPeriodTimestamps(dateInfo);

    return [new Date(startTimestamp * 1000), new Date((endTimestamp + 1) * 1000)];
  };

  graphPositionToRatio = (x, y, clamp = false) => {
    let relativeX =
      (x - this.state.graphPadding.left) /
      (this.state.graphWidth - this.state.graphPadding.left - this.state.graphPadding.right);
    let relativeY =
      (y - this.state.graphPadding.top) /
      (this.state.graphHeight - this.state.graphPadding.top - this.state.graphPadding.bottom);
    if (clamp) {
      relativeX = Math.min(Math.max(relativeX, 0.0), 1.0);
      relativeY = Math.min(Math.max(relativeY, 0.0), 1.0);
    }

    return [relativeX, relativeY];
  };

  updateTooltipPosition = (x, y) => {
    let [xRatio, yRatio] = this.graphPositionToRatio(x, y);
    let graphData = this.getGraphData();
    let [start, end] = this.getXDomain(this.state.currentDateInfo);

    let timestamp = (start.getTime() + (end.getTime() - start.getTime()) * xRatio) / 1000;
    if (graphData.hasOwnProperty("meterPower")) {
      let allTimestamps = Object.keys(graphData.meterPower);
      // Try to find the closest timestamp in the data as possible
      // so we can look up graphData values with that key
      timestamp = allTimestamps.find(x => x >= timestamp) || timestamp;
    }
    // Hide the tooltip if it is out of the bounds of the graphing area (it might be over the axes)
    let visible = xRatio >= 0 && xRatio < 1 && yRatio >= 0 && yRatio <= 1;

    let centreYPos =
      (this.state.graphHeight + this.state.graphPadding.top - this.state.graphPadding.bottom) / 2;

    this.setState({
      tooltip: {
        timestamp: timestamp,
        visible: visible,
        x: x,
        y: centreYPos,
      },
    });
  };

  hideTooltip = () => {
    this.setState({
      tooltip: {
        ...this.state.tooltip,
        visible: false,
      },
    });
  };

  graphHasNoData = graphData => {
    return (
      _.isEmpty(graphData) ||
      (_.isEmpty(graphData.batteryPower) &&
        _.isEmpty(graphData.housePower) &&
        _.isEmpty(graphData.meterPower) &&
        _.isEmpty(graphData.solarPower) &&
        (_.isEmpty(graphData.meterSGA) ||
          !hasPermission(this.props.user.permissions, Permissions.fleet.node.sga_data.view)))
    );
  };

  render() {
    const graphData = this.getGraphData();
    return (
      <div className="monitoring">
        <div className="graph-container">
          <MonitoringTimeControls
            dateInfo={this.state.currentDateInfo}
            allowedPeriods={this.props.allowedPeriods}
            minDate={this.props.minDate}
            onDateChange={this.setDate}
            openDataExport={this.props.openDataExport}
            canExportData={this.props.canExportData}
          />
          <div className="graph" ref={this.registerGraphContainer}>
            {this.props.loading ? <Loading horizontal /> : <div style={{ height: "4px" }} />}
            {this.graphHasNoData(graphData) && !this.props.loading && (
              <div className="monitoring-graph-notification-container">
                <div className="monitoring-graph-no-data">No data to display</div>
              </div>
            )}
            <MonitoringGraph
              width={this.state.graphWidth}
              height={this.state.graphHeight}
              offset={this.state.graphOffset}
              dateInfo={this.state.currentDateInfo}
              data={graphData}
              componentState={this.state.componentState}
              updateTooltipPosition={this.updateTooltipPosition}
              hideTooltip={this.hideTooltip}
              padding={this.state.graphPadding}
              getXDomain={this.getXDomain}
              user={this.props.user}
            />
            {this.state.tooltip.visible && (
              <>
                <MonitoringGraphTooltip
                  x={this.state.tooltip.x}
                  y={this.state.tooltip.y}
                  componentState={this.state.componentState}
                  components={this.props.components}
                  timezone={this.props.timezone}
                  timestamp={this.state.tooltip.timestamp}
                  data={graphData}
                />
                <MonitoringGraphLine
                  x={this.state.tooltip.x}
                  height={this.state.graphHeight}
                  width={this.state.graphWidth}
                  padding={this.state.graphPadding}
                />
              </>
            )}
          </div>
          <MonitoringGraphControls
            components={this.props.components}
            componentState={this.state.componentState}
            onChange={this.setComponentState}
          />
        </div>
        <div className="stats-container">
          {this.props.statsPanels.map(statsPanel => (
            <StatsPanel
              title={statsPanel.title}
              color={statsPanel.color}
              stats={statsPanel.stats}
              display={statsPanel.display}
            />
          ))}
          {getSummaryStatPanelData(
            this.state.currentDateInfo,
            this.getSummaryData(),
            this.props.loadingSummary
          )}
        </div>
      </div>
    );
  }
}

export default withRouter(Monitoring);

import React, { Component } from "react";
import { FormattedNumber } from "react-intl";
import Slider from "rc-slider";
import Tooltip from "rc-tooltip";
import Permissions from "../../permissions";
import AuthRequired from "../../containers/AuthRequired";
import "./DispatchControlDropDown.css";

const Handle = Slider.Handle;

const formatTooltip = (val, positiveLabel, negativeLabel, zeroLabel) => {
  const roundedVal = Number(val.toFixed(2));
  if (roundedVal > 0) {
    return roundedVal + (positiveLabel ? positiveLabel : "");
  } else if (roundedVal < 0) {
    return -roundedVal + (negativeLabel ? negativeLabel : "");
  } else {
    return zeroLabel ? zeroLabel : 0;
  }
};

const handle = (hover, value, positiveLabel, negativeLabel, zeroLabel) => {
  return props => {
    const { index, dragging, ...restProps } = props;
    return (
      <Tooltip
        overlay={formatTooltip(value, positiveLabel, negativeLabel, zeroLabel)}
        visible={hover || dragging}
        placement="right"
        key={index}
        style={{ zIndex: 999999 }}
      >
        <Handle {...restProps} />
      </Tooltip>
    );
  };
};

class DispatchControl extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hoverRealPowerP: false,
      hoverPowerFactor: false,
      powerFactorMin: props.powerFactorMin || 0.7,
      powerFactorMax: props.powerFactorMax || 1.0,
    };
  }

  isHovering = (key, state) => this.setState({ [key]: state });

  handlePowerFactorLeadLagOnChange = (leadLag, e) => {
    if (e.target.checked) {
      this.props.onChangePowerFactorLeadLag(leadLag);
    }
  };

  componentWillReceiveProps = nextProps => {
    const { powerFactorMin: pfMin, powerFactorMax: pfMax } = nextProps;

    const changeRequired =
      (typeof pfMin === "number" && pfMin !== this.state.powerFactorMin) ||
      (typeof newPowerFactorMax === "number" && pfMax !== this.state.powerFactorMax);

    if (changeRequired) {
      this.setState({
        powerFactorMin: pfMin,
        powerFactorMax: pfMax,
      });
    }
  };

  render() {
    const {
      realPowerPMin,
      realPowerPMax,
      realPowerP,
      realPowerAvailable,
      onChangeRealPowerP,
      powerFactor,
      onChangePowerFactor,
      powerFactorLeadLag,
      powerFactorConstraintMessage,
      powerFactorAllowed,
      leadLagAllowed,
    } = this.props;
    return (
      <div className="dispatch-controls-wrapper">
        <div className="dispatch-controls">
          <div className="content">
            {realPowerAvailable ? (
              <>
                <div className="headers">
                  <div>Discharge</div>
                  <div>Charge</div>
                </div>
                {/*<SliderHeader val={} label="Charge" unit="kW" />*/}

                <div
                  style={{ margin: "0 23px" }}
                  onMouseEnter={() => this.isHovering("hoverRealPowerP", true)}
                  onMouseLeave={() => this.isHovering("hoverRealPowerP", false)}
                >
                  <Slider
                    key="real-power-slider"
                    vertical={false}
                    onChange={onChangeRealPowerP}
                    min={realPowerPMin}
                    max={realPowerPMax}
                    handle={handle(
                      this.state.hoverRealPowerP,
                      realPowerP,
                      "kW Charge",
                      "kW Discharge",
                      "Lockout"
                    )}
                    value={realPowerP}
                    step={0.1}
                    included={true}
                    style={{ width: "100%" }}
                    handleStyle={{
                      border: "1px solid #E5E5E5",
                      width: "39px",
                      height: "39px",
                      borderRadius: "20px",
                    }}
                    railStyle={{
                      width: "100%",
                      height: "8px",
                      borderRadius: "2px",
                    }}
                    maximumTrackStyle={{ backgroundColor: "rgb(230,236,247)" }}
                  />
                </div>

                <div className="footers">
                  <div>
                    <FormattedNumber value={realPowerPMin} maximumFractionDigits={2} /> kW
                  </div>
                  <div>0 kW</div>
                  <div>
                    <FormattedNumber value={Math.abs(realPowerPMax)} maximumFractionDigits={2} /> kW
                  </div>
                </div>
              </>
            ) : null}
          </div>
          <AuthRequired permission={Permissions.fleet.dispatches.powerFactor}>
            <div
              className={"content power-factor " + (powerFactorAllowed ? " disabled" : "")}
              title={powerFactorConstraintMessage}
            >
              <div className="headers">
                <div>
                  Power Factor
                  <span style={{ margin: "0 23px" }} className="power-factor-constraint-message">
                    {powerFactorConstraintMessage}
                  </span>
                </div>
              </div>
              <div
                style={{ margin: "0 23px" }}
                onMouseEnter={() =>
                  powerFactorAllowed ? null : this.isHovering("hoverPowerFactor", true)
                }
                onMouseLeave={() => this.isHovering("hoverPowerFactor", false)}
              >
                <Slider
                  key="power-factor-slider"
                  vertical={false}
                  onChange={onChangePowerFactor}
                  min={this.state.powerFactorMin}
                  disabled={!powerFactorAllowed}
                  max={this.state.powerFactorMax}
                  handle={handle(this.state.hoverPowerFactor, powerFactor, null, null, null)}
                  value={powerFactor}
                  step={0.05}
                  included={true}
                  style={{ width: "100%" }}
                  handleStyle={{
                    border: "1px solid #E5E5E5",
                    width: "39px",
                    height: "39px",
                    borderRadius: "20px",
                  }}
                  railStyle={{
                    width: "100%",
                    height: "8px",
                    borderRadius: "2px",
                  }}
                  maximumTrackStyle={{ backgroundColor: "rgb(230,236,247)" }}
                />
              </div>
              <div className="footers">
                <div>
                  <FormattedNumber value={this.state.powerFactorMin} maximumFractionDigits={2} />
                </div>
                <div>
                  <FormattedNumber value={this.state.powerFactorMax} maximumFractionDigits={2} />
                </div>
              </div>
              <div
                className={"lag-lead-container" + (leadLagAllowed ? "" : " disabled")}
                title={
                  powerFactorAllowed
                    ? leadLagAllowed
                      ? ""
                      : "Leading/lagging can only be set when power factor is less than 1."
                    : powerFactorConstraintMessage
                }
              >
                <label htmlFor="pf-leading" className="pf-label">
                  <input
                    id="pf-leading"
                    name="power-factor-lead-lag"
                    type="radio"
                    value="LEADING"
                    checked={powerFactorLeadLag === "LEADING"}
                    onChange={e => this.handlePowerFactorLeadLagOnChange("LEADING", e)}
                    disabled={!leadLagAllowed}
                  />
                  Leading
                </label>
                <label htmlFor="pf-lagging" className="pf-label">
                  <input
                    id="pf-lagging"
                    name="power-factor-lead-lag"
                    type="radio"
                    value="LAGGING"
                    checked={powerFactorLeadLag === "LAGGING"}
                    onChange={e => this.handlePowerFactorLeadLagOnChange("LAGGING", e)}
                    disabled={!leadLagAllowed}
                  />
                  Lagging
                </label>
              </div>
            </div>
          </AuthRequired>
        </div>
      </div>
    );
  }
}

class DispatchControlDropDown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showDispatchControls: false,
    };
  }

  handleOnSelectedClick = () => {
    this.setState(prevState => ({
      showDispatchControls: !prevState.showDispatchControls,
    }));
  };

  hideDispatchControls = e => {
    // only change state if focus is moving outside dropdown
    if (!this.dropdownNode.contains(e.relatedTarget)) {
      this.setState({
        showDispatchControls: false,
      });
    }
  };

  registerCurrentValue = currentValueElement => {
    this.currentValueElement = currentValueElement;
  };

  getCurrentPowerDisplay = () => {
    const { realPowerP, powerFactor, powerFactorLeadLag, realPowerAvailable } = this.props;

    let display;

    if (realPowerP > 0) {
      display = `charge at ${realPowerP} kW`;
    } else if (realPowerP < 0) {
      display = `discharge at ${Math.abs(realPowerP)} kW`;
    } else if (realPowerAvailable) {
      display = `lockout`;
    } else {
      display = `adjust power factor `;
    }

    if ((realPowerP !== 0 || !realPowerAvailable) && powerFactor) {
      let pfString = `pf ${powerFactor.toFixed(2)}`;
      if (powerFactor !== 1 && powerFactorLeadLag) {
        pfString += ` ${powerFactorLeadLag === "LEADING" ? "leading" : "lagging"}`;
      }
      display += ` (${pfString})`;
    }

    return display;
  };

  render() {
    if (this.props.readOnly) {
      return (
        <div className="dispatch-controls-drop-down ro">
          <span className="current-value" ref={this.registerCurrentValue}>
            {this.getCurrentPowerDisplay()}
          </span>
        </div>
      );
    } else {
      return (
        <div
          className={"dispatch-controls-drop-down"}
          tabIndex="0"
          onBlur={this.hideDispatchControls}
          ref={ref => (this.dropdownNode = ref)}
        >
          <span
            className="current-value"
            ref={this.registerCurrentValue}
            onClick={this.props.readOnly ? null : this.handleOnSelectedClick}
          >
            {this.getCurrentPowerDisplay()}
            <i className="fa fa-angle-down" />
          </span>
          {this.state.showDispatchControls ? (
            <div className="dispatch-controls-container">
              <div className="tip" />
              <DispatchControl {...this.props} />
            </div>
          ) : null}
        </div>
      );
    }
  }
}

export default DispatchControlDropDown;

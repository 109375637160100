import React, { Component } from "react";
import { Link } from "react-router-dom";

class UsersListTable extends Component {
  handleOnRowClick = user => {
    this.props.history.push(`/my-reposit/users/${user.id}`);
  };

  render() {
    const users = this.props.users;

    if (!users || users.length === 0) {
      return <div className="fleet-table-no-results">No users.</div>;
    }

    return (
      <table>
        <thead>
          <tr>
            <th>Name</th>
            <th>Email</th>
          </tr>
        </thead>
        <tbody>
          {users.map(user => {
            return (
              <tr role="button" onClick={() => this.handleOnRowClick(user)}>
                <td>
                  <Link to={`/my-reposit/users/${user.id}`}>
                    {user.givenName} {user.surname}
                  </Link>
                </td>
                <td>{user.email}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  }
}

export default UsersListTable;

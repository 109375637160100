import React, { Component } from "react";
import { Link } from "react-router-dom";
import { FormattedNumber } from "react-intl";
import { MomentDate, Duration } from "../widgets";
import { getDateAsString } from "../widgets/Moment";

class RertDispatchListTable extends Component {
  constructor(props) {
    super(props);

    let powerstationMap = {};
    for (let i in props.powerstations) {
      const powerstation = props.powerstations[i];
      powerstationMap[powerstation.id] = powerstation;
    }

    this.state = {
      powerstationMap: powerstationMap,
    };
  }

  handleOnRowClick = dispatch => {
    this.props.history.push(`/virtual-power-plants/rert-dispatches/${dispatch.id}`);
  };

  getPowerstationName = powerstationId => {
    const powerstation = this.state.powerstationMap[powerstationId];
    if (powerstation) {
      return powerstation.name;
    } else {
      return "";
    }
  };

  getStatus = state => {
    switch (state) {
      case "COMPLETED":
        return "Completed";
      case "UPCOMING":
        return "Upcoming";
      case "INPROGRESS":
        return "In Progress";
      default:
        return "";
    }
  };

  isThisYear = date => {
    const dateYear = getDateAsString(date, this.props.timezone, "YYYY");
    const nowYear = getDateAsString(new Date(), this.props.timezone, "YYYY");
    return dateYear === nowYear;
  };

  render() {
    const dispatches = this.props.dispatches;

    if (!dispatches || dispatches.length === 0) {
      return (
        <div className="fleet-table-no-results">
          No dispatches have been found for this search criteria.
        </div>
      );
    }

    return (
      <table>
        <thead>
          <tr>
            <th>Event</th>
            <th>Start</th>
            <th>Duration</th>
            <th>Request</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {dispatches.map(dispatch => {
            return (
              <tr role="button" onClick={() => this.handleOnRowClick(dispatch)}>
                <td>
                  <Link to={`/virtual-power-plants/rert-dispatches/${dispatch.id}`}>
                    {dispatch.reason}
                    <br />
                  </Link>
                  <p>{this.getPowerstationName(dispatch.powerstation)}</p>
                </td>
                <td>
                  {this.isThisYear(new Date(dispatch.request.startTime * 1000)) ? (
                    <MomentDate
                      date={new Date(dispatch.request.startTime * 1000)}
                      timezone={this.props.timezone}
                      format="D MMMM, h:mm a"
                    />
                  ) : (
                    <MomentDate
                      date={new Date(dispatch.request.startTime * 1000)}
                      timezone={this.props.timezone}
                      format="D MMM YY, h:mm a"
                    />
                  )}
                </td>
                <td>
                  <Duration seconds={dispatch.request.duration} />
                </td>
                <td>
                  {dispatch.request.realPowerP === 0 ? (
                    "Lockout (0 kW)"
                  ) : dispatch.request.realPowerP > 0 ? (
                    <span>
                      Charge at{" "}
                      <FormattedNumber
                        value={dispatch.request.realPowerP}
                        maximumFractionDigits={2}
                      />{" "}
                      kW
                    </span>
                  ) : (
                    <span>
                      Discharge at{" "}
                      <FormattedNumber
                        value={Math.abs(dispatch.request.realPowerP)}
                        maximumFractionDigits={2}
                      />{" "}
                      kW
                    </span>
                  )}
                </td>
                <td>{this.getStatus(dispatch.state)}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  }
}

export default RertDispatchListTable;

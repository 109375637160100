import React from "react";
import { VictoryArea, VictoryLine, VictoryAxis, VictoryLabel } from "victory";
import Permissions, { hasPermission } from "../../permissions";

const PERIOD_TO_ZEBRA_STRIPE_COUNT = {
  day: 48, // 1 per 30 mins
  week: 7, // 1 per day
  month: 30, // 1 week
  year: 12, // 1 day
};

class MonitoringGraph extends React.Component {
  formatData = data => {
    if (data) {
      return Object.keys(data).map(ts => ({
        x: new Date(parseInt(ts) * 1000),
        y: data[ts],
      }));
    }
  };

  getPowerDomain = () => {
    const data = this.props.data;
    const dateInfo = this.props.dateInfo;
    if (data) {
      let minY = Infinity;
      let maxY = -Infinity;

      const findMinMax = metricData => {
        // When we are finding the min and the max values we want to have both the top half
        // and the bottom half of the graphs display the same magnitude. As such we multiply
        // both the max and min by -1 to get the extremes in the opposite direction. This ensures
        // that the x axis has a positive and negative value that match.
        Object.keys(metricData).forEach(ts => {
          minY = metricData[ts] < minY ? metricData[ts] : minY;
          minY = metricData[ts] * -1 < minY ? metricData[ts] * -1 : minY;
          maxY = metricData[ts] > maxY ? metricData[ts] : maxY;
          maxY = metricData[ts] * -1 > maxY ? metricData[ts] * -1 : maxY;
        });
      };

      data.meterPower && findMinMax(data.meterPower);
      data.solarPower && findMinMax(data.solarPower);
      data.batteryPower && findMinMax(data.batteryPower);
      data.housePower && findMinMax(data.housePower);
      data.meterSGA &&
        hasPermission(this.props.user.permissions, Permissions.fleet.node.sga_data.view) &&
        findMinMax(data.meterSGA);

      // If all the data series are empty, then the min/max will be unchanged
      let yDomain;
      if (minY !== Infinity && maxY !== -Infinity) {
        yDomain = [minY ? parseInt(minY) - 1 : 0, maxY ? parseInt(maxY) + 1 : 0];
      } else {
        yDomain = [0, 1];
      }

      return {
        x: this.props.getXDomain(dateInfo),
        y: yDomain,
      };
    }

    return { x: [new Date(), new Date()], y: [0, 1] };
  };

  getSecondaryDomain = () => {
    if (this.props.data) {
      let minY = Infinity;
      let maxY = -Infinity;
      switch (this.props.componentState.secondaryYAxis) {
        case "voltage":
          let dataVoltage = this.props.data.meterVoltage;
          if (dataVoltage) {
            Object.keys(dataVoltage).forEach(ts => {
              minY = dataVoltage[ts] < minY ? dataVoltage[ts] : minY;
              maxY = dataVoltage[ts] > maxY ? dataVoltage[ts] : maxY;
            });
          } else {
            minY = 0;
            maxY = 0;
          }
          break;
        case "frequency":
          let dataFrequency = this.props.data.meterFrequency;
          if (dataFrequency) {
            Object.keys(dataFrequency).forEach(ts => {
              minY = dataFrequency[ts] < minY ? dataFrequency[ts] : minY;
              maxY = dataFrequency[ts] > maxY ? dataFrequency[ts] : maxY;
            });
          } else {
            minY = 0;
            maxY = 0;
          }
          break;
        case "state_of_charge": // State of charge
          maxY = 100;
          minY = -100;
          break;
        case "reactive_power": {
          const dataMeterReactive = this.props.data.meterReactivePower;
          if (dataMeterReactive) {
            Object.keys(dataMeterReactive).forEach(ts => {
              minY = dataMeterReactive[ts] < minY ? dataMeterReactive[ts] : minY;
              minY = dataMeterReactive[ts] * -1 < minY ? dataMeterReactive[ts] * -1 : minY;
              maxY = dataMeterReactive[ts] > maxY ? dataMeterReactive[ts] : maxY;
              maxY = dataMeterReactive[ts] * -1 > maxY ? dataMeterReactive[ts] * -1 : maxY;
            });
          }
          const solarMeterReactive = this.props.data.solarReactivePower;
          if (solarMeterReactive) {
            Object.keys(solarMeterReactive).forEach(ts => {
              minY = solarMeterReactive[ts] < minY ? solarMeterReactive[ts] : minY;
              minY = solarMeterReactive[ts] * -1 < minY ? solarMeterReactive[ts] * -1 : minY;
              maxY = solarMeterReactive[ts] > maxY ? solarMeterReactive[ts] : maxY;
              maxY = solarMeterReactive[ts] * -1 > maxY ? solarMeterReactive[ts] * -1 : maxY;
            });
          }
          break;
        }
        default:
          return { x: [new Date(), new Date()], y: [0, 1] };
      }

      return {
        x: this.props.getXDomain(this.props.dateInfo),
        y: [parseInt(minY - 1), parseInt(maxY) + 1],
      };
    }

    return { x: [new Date(), new Date()], y: [0, 1] };
  };

  getSecondaryUnits = () => {
    switch (this.props.componentState.secondaryYAxis) {
      case "voltage":
        return "V";
      case "frequency":
        return "Hz";
      case "state_of_charge":
        return "%";
      case "reactive_power":
        return "kvar";
      default:
        return { x: [0, 1], y: [0, 1] };
    }
  };

  getSecondaryTickValue = t => {
    let secondaryUnit = this.getSecondaryUnits();
    if (secondaryUnit === "%" && t < 0) {
      return null;
    }
    return `${t}${secondaryUnit}`;
  };

  renderZebraStripes = (width, height, padding) => {
    const stripeCount = PERIOD_TO_ZEBRA_STRIPE_COUNT[this.props.dateInfo.period];

    let evenColor = "rgba(230, 236, 247, 0.4)";
    let oddColor = "transparent";

    const graphWidth = width - padding.left - padding.right;
    const graphHeight = height - padding.top - padding.bottom;
    const stripeWidth = graphWidth / stripeCount;

    let stripes = [];

    for (let i = 0; i < stripeCount; i++) {
      stripes.push(
        <rect
          x={padding.left + i * stripeWidth}
          y={padding.top}
          fill={i % 2 === 0 ? evenColor : oddColor}
          style={{
            width: stripeWidth,
            height: graphHeight,
          }}
        />
      );
    }

    return stripes;
  };

  render() {
    const { width, height, data, componentState, padding } = this.props;
    const domain = this.getPowerDomain();
    const secondaryDomain = this.getSecondaryDomain();

    return (
      <>
        <svg
          width={width}
          height={height}
          role="img"
          aria-labelledby="undefined-title undefined-desc"
          viewBox={`0 0 ${width} ${height}`}
          overflow="visible"
          style={{
            width: `${width}px`,
            height: `${height}px`,
            padding: "0",
            userSelect: "none",
            touchAction: "none",
          }}
          onMouseLeave={this.props.hideTooltip}
          onMouseMove={e => {
            this.props.updateTooltipPosition(
              e.pageX - this.props.offset.x,
              e.pageY - this.props.offset.y
            );
          }}
        >
          <g style={{ position: "relative" }}>
            {this.renderZebraStripes(width, height, padding)}
            {/* x-axis thick grid lines/zebra striping */}
            <VictoryAxis
              animate={{
                duration: 500,
                onLoad: { duration: 0 },
              }}
              width={width}
              height={height}
              padding={padding}
              domain={domain}
              style={{
                axis: { stroke: "none" },
                tickLabels: {
                  fontFamily: '"Work Sans", "Helvetica Neue", Helvetica, Arial, sans-serif',
                  fontSize: 15,
                  fontWeight: 300,
                  fill: "rgb(83, 86, 90)",
                },
              }}
              orientation="bottom"
              standalone={false}
              offsetY={48}
              scale={{ x: "time", y: "linear" }}
            />

            {/* y-axis grid */}
            {(data.meterPower ||
              data.solarPower ||
              data.batteryPower ||
              data.housePower ||
              (data.meterSGA &&
                hasPermission(
                  this.props.user.permissions,
                  Permissions.fleet.node.sga_data.view
                ))) && (
              <VictoryAxis
                width={width}
                height={height}
                padding={padding}
                domain={domain}
                dependentAxis
                crossAxis={false}
                style={{
                  axis: {
                    stroke: "#979797",
                    strokeWidth: 0,
                  },
                  grid: {
                    stroke: "#979797",
                    strokeWidth: 0.2,
                  },
                  tickLabels: {
                    fontFamily: '"Work Sans", "Helvetica Neue", Helvetica, Arial, sans-serif',
                    fontSize: 15,
                    fontWeight: 300,
                    fill: "rgb(83, 86, 90)",
                  },
                }}
                orientation="left"
                tickFormat={t => `${t} kW`}
                tickLabelComponent={
                  <VictoryLabel textAnchor="middle" verticalAnchor="middle" angle={270} />
                }
                standalone={false}
                scale={{ x: "time", y: "linear" }}
              />
            )}

            {data.meterPower && componentState.grid && (
              <VictoryArea
                width={width}
                height={height}
                padding={padding}
                domain={domain}
                data={this.formatData(data.meterPower)}
                style={{
                  data: {
                    stroke: "rgb(70, 152, 203)",
                    strokeWidth: 1.5,
                    fill: "rgb(70, 152, 203)",
                    fillOpacity: 0.05,
                  },
                }}
                standalone={false}
                scale={{ x: "time", y: "linear" }}
              />
            )}

            {data.solarPower && componentState.solar && (
              <VictoryArea
                width={width}
                height={height}
                padding={padding}
                domain={domain}
                data={this.formatData(data.solarPower)}
                style={{
                  data: {
                    stroke: "rgb(246, 141, 38)",
                    strokeWidth: 1.5,
                    fill: "rgb(246, 141, 38)",
                    fillOpacity: 0.05,
                  },
                }}
                standalone={false}
                scale={{ x: "time", y: "linear" }}
              />
            )}

            {data.meterSGA &&
              hasPermission(this.props.user.permissions, Permissions.fleet.node.sga_data.view) &&
              componentState.sga && (
                <VictoryArea
                  width={width}
                  height={height}
                  padding={padding}
                  domain={domain}
                  data={this.formatData(data.meterSGA)}
                  style={{
                    data: {
                      stroke: "rgb(31, 42, 68)",
                      strokeWidth: 1.5,
                      fill: "rgb(31, 42, 68)",
                      fillOpacity: 0.05,
                    },
                  }}
                  standalone={false}
                  scale={{ x: "time", y: "linear" }}
                />
              )}

            {data.batteryPower && componentState.battery && (
              <VictoryArea
                width={width}
                height={height}
                padding={padding}
                domain={domain}
                data={this.formatData(data.batteryPower)}
                style={{
                  data: {
                    stroke: "rgb(224, 0, 77)",
                    strokeWidth: 1.5,
                    fill: "rgb(224, 0, 77)",
                    fillOpacity: 0.05,
                  },
                }}
                standalone={false}
                scale={{ x: "time", y: "linear" }}
              />
            )}

            {data.housePower && componentState.house && (
              <VictoryArea
                width={width}
                height={height}
                padding={padding}
                domain={domain}
                data={this.formatData(data.housePower)}
                style={{
                  data: {
                    stroke: "rgb(81, 199, 109)",
                    strokeWidth: 1.5,
                    fill: "rgb(81, 199, 109)",
                    fillOpacity: 0.05,
                  },
                }}
                standalone={false}
                scale={{ x: "time", y: "linear" }}
              />
            )}

            {data.submeters &&
              Object.keys(componentState.submeters)
                .filter(
                  submeterId => componentState.submeters[submeterId] && data.submeters[submeterId]
                )
                .map(submeterId => (
                  <VictoryArea
                    key={submeterId}
                    width={width}
                    height={height}
                    padding={padding}
                    domain={domain}
                    data={this.formatData(data.submeters[submeterId])}
                    style={{
                      data: {
                        stroke: "rgb(87, 196, 199)",
                        strokeWidth: 1.5,
                        fill: "rgb(87, 196, 199)",
                        fillOpacity: 0.05,
                      },
                    }}
                    standalone={false}
                    scale={{ x: "time", y: "linear" }}
                  />
                ))}

            {componentState.secondaryYAxis === "voltage" && data.meterVoltage && (
              <VictoryLine
                width={width}
                height={height}
                padding={padding}
                domain={secondaryDomain}
                data={this.formatData(data.meterVoltage)}
                style={{
                  data: {
                    stroke: "rgb(70, 152, 203)",
                    strokeWidth: 1.5,
                    strokeDasharray: "3, 2",
                  },
                }}
                standalone={false}
                scale={{ x: "time", y: "linear" }}
              />
            )}

            {componentState.secondaryYAxis === "frequency" && data.meterFrequency && (
              <VictoryLine
                width={width}
                height={height}
                padding={padding}
                domain={secondaryDomain}
                data={this.formatData(data.meterFrequency)}
                style={{
                  data: {
                    stroke: "rgb(70, 152, 203)",
                    strokeWidth: 1.5,
                    strokeDasharray: "3, 3",
                  },
                }}
                standalone={false}
                scale={{ x: "time", y: "linear" }}
              />
            )}

            {componentState.secondaryYAxis === "reactive_power" && data.meterReactivePower && (
              <VictoryLine
                width={width}
                height={height}
                padding={padding}
                domain={secondaryDomain}
                data={this.formatData(data.meterReactivePower)}
                style={{
                  data: {
                    stroke: "rgb(70, 152, 203)",
                    strokeWidth: 1.5,
                    strokeDasharray: "3, 3",
                  },
                }}
                standalone={false}
                scale={{ x: "time", y: "linear" }}
              />
            )}

            {componentState.secondaryYAxis === "reactive_power" && data.solarReactivePower && (
              <VictoryLine
                width={width}
                height={height}
                padding={padding}
                domain={secondaryDomain}
                data={this.formatData(data.solarReactivePower)}
                style={{
                  data: {
                    stroke: "rgb(246, 141, 38)",
                    strokeWidth: 1.5,
                    strokeDasharray: "3, 3",
                  },
                }}
                standalone={false}
                scale={{ x: "time", y: "linear" }}
              />
            )}

            {componentState.secondaryYAxis === "state_of_charge" && data.soc && (
              <VictoryLine
                width={width}
                height={height}
                padding={padding}
                domain={secondaryDomain}
                data={this.formatData(data.soc)}
                style={{
                  data: {
                    stroke: "rgb(70, 152, 203)",
                    strokeWidth: 1.5,
                    strokeDasharray: "3, 3",
                  },
                }}
                standalone={false}
                scale={{ x: "time", y: "linear" }}
              />
            )}

            {/*y-axis secondary labels*/}
            {componentState.secondaryYAxis &&
              (data.meterVoltage ||
                data.meterFrequency ||
                data.meterReactivePower ||
                data.solarReactivePower ||
                data.soc) && (
                <VictoryAxis
                  width={width}
                  height={height}
                  padding={padding}
                  domain={secondaryDomain}
                  dependentAxis
                  crossAxis={false}
                  orientation="right"
                  tickFormat={this.getSecondaryTickValue}
                  style={{
                    axis: {
                      stroke: "#979797",
                      strokeWidth: 0,
                    },
                    grid: {
                      strokeWidth: 0,
                    },
                    tickLabels: {
                      fontFamily: '"Work Sans", "Helvetica Neue", Helvetica, Arial, sans-serif',
                      fontSize: 15,
                      fontWeight: 300,
                      fill: "rgb(83, 86, 90)",
                    },
                  }}
                  tickLabelComponent={
                    <VictoryLabel textAnchor="middle" verticalAnchor="middle" angle={90} />
                  }
                  standalone={false}
                  offsetX={32}
                  scale={{ x: "time", y: "linear" }}
                />
              )}
          </g>
        </svg>
      </>
    );
  }
}

export default MonitoringGraph;

import { history } from "../../containers/Root";

export const UPDATE_GRID_OUTAGES = "UPDATE_GRID_OUTAGES";
export const FETCH_GRID_OUTAGES = "FETCH_GRID_OUTAGES";
export const FETCH_ACTIVE_GRID_OUTAGE = "FETCH_ACTIVE_GRID_OUTAGE";
export const UPDATE_ACTIVE_GRID_OUTAGE = "UPDATE_ACTIVE_GRID_OUTAGE";
export const EXECUTE_GRID_OUTAGE = "EXECUTE_GRID_OUTAGE";
export const UPDATE_EXECUTE_GRID_OUTAGE = "UPDATE_EXECUTE_GRID_OUTAGE";
export const CANCEL_GRID_OUTAGE = "CANCEL_GRID_OUTAGE";
export const UPDATE_CANCEL_GRID_OUTAGE = "UPDATE_CANCEL_GRID_OUTAGE";
export const GRID_OUTAGE_FINISHED_UPDATING = "GRID_OUTAGE_FINISHED_UPDATING";
export const FETCH_GRID_OUTAGE_RESPONSE = "FETCH_GRID_OUTAGE_RESPONSE";
export const UPDATE_GRID_OUTAGE_RESPONSE = "UPDATE_GRID_OUTAGE_RESPONSE";
export const FETCH_GRID_OUTAGE_POWERSTATIONS = "FETCH_GRID_OUTAGE_POWERSTATIONS";
export const UPDATE_GRID_OUTAGE_POWERSTATIONS = "UPDATE_GRID_OUTAGE_POWERSTATIONS";
export const CLEAR_ACTIVE_GRID_OUTAGE = "CLEAR_ACTIVE_GRID_OUTAGE";

export function fetchGridOutages() {
  return {
    type: FETCH_GRID_OUTAGES,
    meta: {
      endpoint: "/grid-outages?contract_type=GRID_OUTAGE",
      success: upgradeGridOutages,
      errorMessage: "Failed to load grid outages.",
    },
  };
}

function upgradeGridOutages(response) {
  return {
    type: UPDATE_GRID_OUTAGES,
    payload: {
      outages: response.data,
    },
  };
}

export function fetchActiveGridOutage(eventId) {
  return {
    type: FETCH_ACTIVE_GRID_OUTAGE,
    meta: {
      endpoint: `/grid-outage/${eventId}`,
      success: updateActiveGridOutage,
      errorMessage: "Failed to load grid outage.",
    },
  };
}

export function fetchGridOutageResponse(eventId) {
  return {
    type: FETCH_GRID_OUTAGE_RESPONSE,
    meta: {
      endpoint: "/grid-outage/" + eventId + "/response",
      success: updateGridOutageResponse,
      errorMessage: "Failed to load grid outage response.",
    },
  };
}

function updateGridOutageResponse(response) {
  return {
    type: UPDATE_GRID_OUTAGE_RESPONSE,
    payload: {
      response: response.data,
    },
  };
}

function updateActiveGridOutage(response) {
  return {
    type: UPDATE_ACTIVE_GRID_OUTAGE,
    payload: {
      outage: response.data,
    },
  };
}

export function executeGridOutage(stationId, startTime, duration, outageReason, customerMessage) {
  return {
    type: EXECUTE_GRID_OUTAGE,
    meta: {
      endpoint: "/grid-outage",
      method: "POST",
      body: {
        powerstation: stationId,
        startTime: startTime,
        duration: duration,
        outageReason: outageReason,
        customerMessage: customerMessage,
      },
      success: updateExecuteGridOutage,
      error: finshedUpdatingGridOutage,
      successMessage: "Grid Outage Request successfully executed",
      errorMessage: "Failed to execute Grid Outage Request.",
    },
  };
}

function updateExecuteGridOutage(response) {
  history.push(`/virtual-power-plants/grid-outages/${response.data.id}`);
  return {
    type: UPDATE_EXECUTE_GRID_OUTAGE,
  };
}

export function cancelGridOutage(dispatchId) {
  return {
    type: CANCEL_GRID_OUTAGE,
    meta: {
      endpoint: `/dispatch/${dispatchId}/cancel`,
      method: "POST",
      success: updateCancelGridOutage,
      successMessage: "Grid Outage Request successfully cancelled.",
      errorMessage: "Failed to cancel Grid Outage Request.",
    },
  };
}

function updateCancelGridOutage() {
  history.push("/virtual-power-plants/grid-outages");
  return {
    type: UPDATE_CANCEL_GRID_OUTAGE,
  };
}

export function clearActiveGridOutage() {
  return {
    type: CLEAR_ACTIVE_GRID_OUTAGE,
  };
}

function finshedUpdatingGridOutage() {
  return {
    type: GRID_OUTAGE_FINISHED_UPDATING,
  };
}

export function fetchOutagePowerstations(activeStationId) {
  return {
    type: FETCH_GRID_OUTAGE_POWERSTATIONS,
    meta: {
      endpoint: "/api/powerstations",
      success: updateOutagePowerstations,
      errorMessage: "Failed to load Virtual Power Plant.",
    },
  };
}

function updateOutagePowerstations(powerstations) {
  return {
    type: UPDATE_GRID_OUTAGE_POWERSTATIONS,
    payload: {
      powerstations: powerstations.data,
    },
  };
}

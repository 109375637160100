export const FETCH_GRID_CREDITS_EVENTS = "FETCH_GRID_CREDITS_EVENTS";
export const UPDATE_GRID_CREDITS_EVENTS = "UPDATE_GRID_CREDITS_EVENTS";
export const FETCH_CUSTOMER_COUNT_HISTORY = "FETCH_CUSTOMER_COUNT_HISTORY";
export const UPDATE_CUSTOMER_COUNT_HISTORY = "UPDATE_CUSTOMER_COUNT_HISTORY";
export const FETCH_GRID_CREDITS_SUMMARY = "FETCH_GRID_CREDITS_SUMMARY";
export const UPDATE_GRID_CREDITS_SUMMARY = "UPDATE_GRID_CREDITS_SUMMARY";

export function fetchGridCredits(start, end) {
  let queryString = "?";
  if (start) {
    queryString += "start=" + start + "&";
  }
  if (end) {
    queryString += "end=" + end;
  }
  return {
    type: FETCH_GRID_CREDITS_EVENTS,
    meta: {
      endpoint: "/billing/items" + queryString,
      success: updateGridCredits,
    },
  };
}

export function updateGridCredits(gridCredits) {
  return {
    type: UPDATE_GRID_CREDITS_EVENTS,
    payload: {
      events: gridCredits.data.events,
    },
  };
}

export function fetchCustomerCountHistory(interval) {
  return {
    type: FETCH_CUSTOMER_COUNT_HISTORY,
    meta: {
      endpoint: "/billing/customers?interval=" + (interval ? interval : "month"),
      success: updateCustomerCountHistory,
    },
  };
}

export function updateCustomerCountHistory(customerCount) {
  return {
    type: UPDATE_CUSTOMER_COUNT_HISTORY,
    payload: {
      customerCount: customerCount.data.customerCount,
    },
  };
}

export function fetchGridCreditsSummary() {
  return {
    type: FETCH_GRID_CREDITS_SUMMARY,
    meta: {
      endpoint: "/billing/monthly",
      success: updateGridCreditsSummary,
    },
  };
}

export function updateGridCreditsSummary(billingSummary) {
  return {
    type: UPDATE_GRID_CREDITS_SUMMARY,
    payload: {
      billingSummary: billingSummary.data.summary,
    },
  };
}

import React, { Component } from "react";
import Tooltip from "./Tooltip";
import "./SelectDropdown.css";

class SelectDropdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showOptions: false,
    };
  }

  handleOnOptionClick = value => {
    if (this.props.value !== value && this.props.onChange) {
      this.props.onChange(value);
    }
    this.setState({
      showOptions: false,
    });
  };

  handleOnSelectedClick = () => {
    this.setState({
      showOptions: true,
    });
  };

  hideDropdown = () => {
    this.setState({
      showOptions: false,
    });
  };

  registerCurrentValue = currentValueElement => {
    this.currentValueElement = currentValueElement;
  };

  buildLabel = (option, isSelector) => {
    if (option && option.labelComponent) {
      let labelComponent = option.labelComponent;
      let labelComponentWithProps;
      if (labelComponent) {
        labelComponentWithProps = React.cloneElement(labelComponent, {
          ...this.props,
          isSelector,
          ...labelComponent.props,
        });
      }

      return labelComponentWithProps;
    } else {
      return option ? option.label : null;
    }
  };

  render() {
    const { options, value, orientation } = this.props;

    if (this.props.readOnly) {
      const currentOption = options ? options.find(o => o.value === value) : null;
      return (
        <div className="rp-widget select-dd ro">
          <span className="current-value">{this.buildLabel(currentOption, true)}</span>
          {this.props.tooltipContent || this.props.tooltipId ? (
            <Tooltip
              id={this.props.tooltipId}
              title={this.props.tooltipTitle}
              content={this.props.tooltipContent}
              icon={this.props.tooltipIcon}
            />
          ) : null}
        </div>
      );
    } else {
      const currentOption = options ? options.find(o => o.value === value) : null;
      return (
        <div className="rp-widget select-dd" tabIndex="0" onBlur={this.hideDropdown}>
          <span
            className={"current-value" + (this.props.error ? " error" : "")}
            ref={this.registerCurrentValue}
            onClick={this.handleOnSelectedClick}
          >
            {this.props.icon ? <span className={"fa fa-" + this.props.icon} /> : null}
            {currentOption ? this.buildLabel(currentOption, true) : this.props.placeholder}
          </span>
          {this.props.tooltipContent || this.props.tooltipId ? (
            <Tooltip
              id={this.props.tooltipId}
              title={this.props.tooltipTitle}
              content={this.props.tooltipContent}
              icon={this.props.tooltipIcon}
            />
          ) : null}
          {this.state.showOptions ? (
            <div className={"options-container " + (orientation === "up" ? "up" : "down")}>
              <div className="tip" />
              <div className="options-scroll-container">
                {options.map(o => {
                  return (
                    <div
                      key={o.value}
                      className={"option" + (o.value === value ? " selected" : "")}
                      onClick={() => this.handleOnOptionClick(o.value)}
                    >
                      {o.icon && <i className={`fa fa-${o.icon}`} style={{ color: o.iconColor }} />}
                      {this.buildLabel(o)}
                    </div>
                  );
                })}
              </div>
            </div>
          ) : null}
        </div>
      );
    }
  }
}

export default SelectDropdown;

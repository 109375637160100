import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Modal } from "../../components/layout/Modal";
import { Button } from "../../components/widgets";
import NewGridOutage from "../../components/gridoutage/NewGridOutage";
import CustomerAppMessageEdit from "../../components/trading/CustomerAppMessageEdit";
import * as gridOutageActions from "../../redux/actions/gridoutage";
import moment from "moment-timezone";

const DEFAULT_GRID_OUTAGE_START_OFFSET = 14400; // Use 4 hours from now
const DEFAULT_GRID_OUTAGE_DURATION = 7200; // 4 hours

const mapStateToProps = state => ({
  gridOutages: state.gridOutages.outages,
  powerstations: state.gridOutages.powerstations,
  user: state.user,
  newGridOutage: state.gridOutages.newGridOutage,
  executeGridOutageLoading: state.gridOutages.loading,
});

const mapDispatchToProps = dispatch => {
  return {
    gridOutageActions: bindActionCreators(gridOutageActions, dispatch),
  };
};

const STORM_MESSAGE =
  "It looks like a storm is coming at 11:00 am for 1 hour and 30 minutes. In preparation for a potential blackout your battery has been requested to charge by {{SUBORG_NAME}}.";
const OUTAGE_MESSAGE =
  "{{SUBORG_NAME}} is aware of a planned network outage in your area at 11:00 am for 1 hour and 30 minutes. In preparation for this event your battery have been requested to charge.";
const STORM_TITLE = "Storm Approaching";
const OUTAGE_TITLE = "Planned Network Outage";

class NewGridOutageModal extends Component {
  constructor(props) {
    super(props);

    const now = new Date();
    const nowTimestamp = Math.round(now.getTime() / 1000); // Get now as seconds (JS uses ms).
    let startTime = nowTimestamp + DEFAULT_GRID_OUTAGE_START_OFFSET;
    startTime = startTime - (startTime % 3600); // Round down to nearest hour.
    const timezone = this.props.user.account.timezone;
    let startDate = moment(new Date(startTime * 1000));

    const momentNow = moment().tz(this.props.user.account.timezone);
    momentNow.minutes(0).seconds(0);

    this.state = {
      powerstationId: props.powerstationId ? props.powerstationId : null,
      allowPowerstationEdit: !props.powerstationId,
      startDateTime: startDate.tz(timezone),
      duration: DEFAULT_GRID_OUTAGE_DURATION,
      outageReason: "STORM",
      customerAppMessage: "",
      defaultMessage: this.formatDefaultMessage(STORM_MESSAGE),
      messageTitle: STORM_TITLE,
    };
  }

  handlePowerstationChange = value => {
    this.setState({
      powerstationId: value,
    });
  };

  formatDefaultMessage = message => {
    return message.replace("{{SUBORG_NAME}}", this.props.user.account.suborg_name);
  };

  handleDurationChange = duration => {
    this.setState({
      duration: duration,
    });
  };

  handleReasonChange = value => {
    let message, title;
    if (value === "STORM") {
      message = this.formatDefaultMessage(STORM_MESSAGE);
      title = STORM_TITLE;
    } else {
      message = this.formatDefaultMessage(OUTAGE_MESSAGE);
      title = OUTAGE_TITLE;
    }

    this.setState({
      outageReason: value,
      defaultMessage: message,
      messageTitle: title,
    });
  };

  executeGridOutage = () => {
    this.props.gridOutageActions.executeGridOutage(
      this.state.powerstationId,
      this.state.startDateTime.unix(),
      this.state.duration,
      this.state.outageReason,
      this.state.customerAppMessage
    );
  };

  showCustomerAppMessageEdit = () => {
    this.setState({
      editCustomerAppMessage: true,
      currentCustomerAppMessage: this.state.customerAppMessage,
    });
  };

  hideCustomerAppMessageEdit = () => {
    this.setState({
      editCustomerAppMessage: false,
    });
  };

  handleOnCustomerAppMessageChange = value => {
    this.setState({
      customerAppMessage: value,
    });
  };

  cancelCustomerAppMessageChange = () => {
    this.setState({
      editCustomerAppMessage: false,
      customerAppMessage: this.state.currentCustomerAppMessage,
      currentCustomerAppMessage: null,
    });
  };

  handleStartDateTimeChange = startDateTime => {
    this.setState({
      startDateTime: startDateTime,
    });
  };

  render() {
    const isLoading = this.props.executeGridOutageLoading;

    return (
      <Modal>
        <Modal.Header title="New Grid Outage" />
        <Modal.Content>
          <div
            style={{
              opacity: this.state.editCustomerAppMessage ? 1 : 0,
              position: this.state.editCustomerAppMessage ? "relative" : "absolute",
              top: 0,
              left: 0,
              width: "100%",
              transition: "all 0.5s ease-in-out",
              zIndex: this.state.editCustomerAppMessage ? 999999 : -999999,
            }}
          >
            <CustomerAppMessageEdit
              hideCustomerAppMessageEdit={this.hideCustomerAppMessageEdit}
              customerAppMessage={this.state.customerAppMessage}
              onChange={this.handleOnCustomerAppMessageChange}
              previousCustomerAppMessages={this.props.previousDispatchMessages}
              standardMessage={this.state.defaultMessage}
              messageTitle={this.state.messageTitle}
            />
          </div>
          <div
            style={{
              opacity: this.state.editCustomerAppMessage ? 0 : 1,
              position: this.state.editCustomerAppMessage ? "absolute" : "relative",
              top: 0,
              left: 0,
              width: "100%",
              transition: "all 0.5s ease-in-out",
              zIndex: this.state.editCustomerAppMessage ? -999999 : 999999,
            }}
          >
            <div style={{ position: "relative" }}>
              <NewGridOutage
                powerstations={this.props.powerstations}
                powerstation={this.state.powerstationId}
                allowPowerstationEdit={this.state.allowPowerstationEdit}
                startDateTime={this.state.startDateTime}
                outageReason={this.state.outageReason}
                duration={this.state.duration}
                onPowerstationChange={this.handlePowerstationChange}
                onReasonChange={this.handleReasonChange}
                handleStartDateTimeChange={this.handleStartDateTimeChange}
                handleDurationChange={this.handleDurationChange}
                timezone={this.props.user.account.timezone}
                showCustomerAppMessageEdit={this.showCustomerAppMessageEdit}
                customerAppMessage={this.state.customerAppMessage}
                previousCustomerAppMessages={this.props.previousDispatchMessages}
              />
            </div>
          </div>
        </Modal.Content>
        <Modal.Footer>
          {this.state.editCustomerAppMessage ? (
            <>
              <Button
                id="back-from-capp-msg-edit-to-dispatch-cancel"
                type="secondary"
                onClick={this.cancelCustomerAppMessageChange}
                icon="chevron-left"
              >
                Cancel
              </Button>
              <Button
                id="back-from-capp-msg-edit-to-dispatch-save"
                type="primary"
                onClick={this.hideCustomerAppMessageEdit}
                icon="check"
              >
                Save Message
              </Button>
            </>
          ) : (
            <>
              <Button
                id="cancel-new-dispatch-modal"
                type="secondary"
                onClick={this.props.handleOnCancel}
                icon="times"
              >
                Cancel
              </Button>
              {(this.props.powerstations || !this.state.allowPowerstationEdit) &&
              this.state.powerstationId ? (
                <Button
                  id="add-new-dispatch-modal"
                  type="primary"
                  onClick={this.executeGridOutage}
                  icon="bolt"
                  loading={isLoading}
                >
                  Execute
                </Button>
              ) : null}
            </>
          )}
        </Modal.Footer>
      </Modal>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(NewGridOutageModal);

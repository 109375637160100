import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import queryString from "query-string";
import { Loading, InfoBox } from "../../components/widgets";
import * as powerstationActions from "../../redux/actions/powerstations";
import PowerstationsListTable from "../../components/powerstations/PowerstationsListTable";
import PowerstationUnassignedDeploymentsModal from "../../components/powerstations/PowerstationUnassignedDeploymentsModal";
import PagingControl from "../../components/widgets/PagingControl";
import CreatePowerstationModal from "../../components/powerstations/CreatePowerstationModal";

export const PAGE_SIZE = 10;

const mapStateToProps = state => ({
  powerstations: state.powerstations.powerstations,
  deployments: state.powerstations.deployments,
  location: state.router.location,
  powerstationCount: state.powerstations.powerstationCount,
  loading: state.powerstations.powerstationsLoading,
  savePowerstationLoading: state.powerstations.savePowerstationLoading,
  deploymentsNotInPowerstation: state.powerstations.deploymentsNotInPowerstation,
  deploymentsNotInPowerstationCount: state.powerstations.deploymentsNotInPowerstationCount,
  loadingUnusedDeployments: state.powerstations.loadingUnusedDeployments,
});

const mapDispatchToProps = dispatch => {
  return {
    powerstationActions: bindActionCreators(powerstationActions, dispatch),
  };
};

export class PowerstationList extends Component {
  constructor(props) {
    super(props);
    const search = queryString.parse(props.location.search);

    let limit = PAGE_SIZE;
    if (search.limit !== undefined) {
      limit = parseInt(search.limit, 10);
    }

    let offset = 0;
    if (search.offset !== undefined) {
      offset = parseInt(search.offset, 10);
    }

    this.state = {
      hideUnassignedDeploymentsModal: false,
      limit,
      offset,
      unusedNodeLimit: PAGE_SIZE,
      unusedNodeOffset: 0,
    };
    this.updateLimitPerPage = this.updateLimitPerPage.bind(this);
    this.updateUnusedDeploymentLimitPerPage = this.updateUnusedDeploymentLimitPerPage.bind(this);
  }

  componentDidMount = () => {
    this.props.powerstationActions.fetchPowerstations(null, this.state.limit, this.state.offset);
    this.props.powerstationActions.fetchUnusedDeployments(
      this.state.unusedNodeLimit,
      this.state.unusedNodeOffset
    );
  };

  componentWillReceiveProps = nextProps => {
    const nextSearch = queryString.parse(nextProps.location.search);
    if (
      this.props.location.search &&
      nextProps.location.search &&
      this.props.location.search !== nextProps.location.search
    ) {
      this.props.powerstationActions.fetchPowerstations(null, nextSearch.limit, nextSearch.offset);
      this.setState({
        limit: parseInt(nextSearch.limit, 10),
        offset: parseInt(nextSearch.offset, 10),
      });
    }
  };

  getPage = (limit, offset) => {
    this.updateUrl(limit, offset);
  };

  updateUrl = (limit, offset) => {
    const url = `${this.props.location.pathname}?limit=${limit}&offset=${offset}`;
    this.props.history.push(url);
  };

  updateLimitPerPage = limit => {
    this.setState({ limit });
  };

  getUnusedDeploymentPage = (limit, offset) => {
    this.setState({ unusedNodeLimit: limit, unusedNodeOffset: offset });
    this.props.powerstationActions.fetchUnusedDeployments(limit, offset);
  };

  updateUnusedDeploymentLimitPerPage = limit => {
    this.setState({ unusedNodeLimit: limit });
  };

  showUnassignedDeploymentsModal = () => {
    this.setState({
      showUnassignedDeploymentsModal: true,
    });
  };

  hideUnassignedDeploymentsModal = () => {
    this.setState({
      showUnassignedDeploymentsModal: false,
    });
  };

  render() {
    if (!this.props.powerstations || this.props.savePowerstationLoading || this.props.loading) {
      return <Loading />;
    }
    const {
      deploymentsNotInPowerstation,
      deploymentsNotInPowerstationCount,
      loadingUnusedDeployments,
    } = this.props;

    return (
      <span>
        {deploymentsNotInPowerstationCount > 0 && this.props.powerstations.length > 0 ? (
          <InfoBox type="warning" style={{ marginTop: "1em" }}>
            {deploymentsNotInPowerstationCount}{" "}
            {deploymentsNotInPowerstationCount === 1 ? "node" : "nodes"} are not currently assigned
            to a Virtual Power Plant.{" "}
            <span
              className="link"
              style={{ fontSize: "0.9em" }}
              onClick={this.showUnassignedDeploymentsModal}
            >
              View unassigned nodes
            </span>
          </InfoBox>
        ) : null}
        <PowerstationsListTable
          powerstations={this.props.powerstations.filter(
            powerstation => powerstation.type !== "FCAS"
          )}
          history={this.props.history}
        />
        <PagingControl
          key="powerstationPaging"
          limit={this.state.limit}
          offset={this.state.offset}
          totalItems={this.props.powerstationCount}
          itemName="virtual power plant"
          onPageChange={(limit, offset) => this.getPage(limit, offset)}
          onChangeResultsPerPage={this.updateLimitPerPage}
          orientation="up"
        />
        <div>
          {this.props.powerstations.filter(powerstation => powerstation.type === "FCAS").length !==
          0 ? (
            <div>
              <div className="page-header">
                <h1>FCAS Virtual Power Plants</h1>
              </div>
              <PowerstationsListTable
                powerstations={this.props.powerstations.filter(
                  powerstation => powerstation.type === "FCAS"
                )}
                history={this.props.history}
              />
            </div>
          ) : null}
          {this.state.showUnassignedDeploymentsModal ? (
            <PowerstationUnassignedDeploymentsModal
              deployments={deploymentsNotInPowerstation}
              loading={loadingUnusedDeployments}
              handleOnCancel={this.hideUnassignedDeploymentsModal}
            >
              <PagingControl
                key="unusedDeploymentsPaging"
                limit={this.state.unusedNodeLimit}
                offset={this.state.unusedNodeOffset}
                totalItems={deploymentsNotInPowerstationCount}
                itemName="deployment"
                onPageChange={(limit, offset) => this.getUnusedDeploymentPage(limit, offset)}
                onChangeResultsPerPage={this.updateUnusedDeploymentLimitPerPage}
                orientation="up"
              />
            </PowerstationUnassignedDeploymentsModal>
          ) : null}
          {this.props.createNewVpp ? (
            <CreatePowerstationModal
              closeModal={this.props.hideNewVppModal}
              fetchNewPowerstation={this.props.powerstationActions.fetchNewPowerstation}
            />
          ) : null}
        </div>
      </span>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PowerstationList);

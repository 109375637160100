export const FETCH_INVOICES = "FETCH_INVOICES";
export const UPDATE_INVOICES = "UPDATE_INVOICES";

export function fetchInvoices() {
  return {
    type: FETCH_INVOICES,
    meta: {
      endpoint: "/billing/invoices",
      success: updateInvoices,
    },
  };
}

export function updateInvoices(invoices) {
  return {
    type: UPDATE_INVOICES,
    payload: {
      invoices: invoices.data,
    },
  };
}

import React, { Component } from "react";
import { VictoryArea, VictoryAxis, VictoryLabel } from "victory";
import { getDateAsString, getTimeAsString } from "../widgets/Moment";
import { durationToString } from "../../utils/formatting";
import "./NewCurtailmentGraph.css";

const PADDING_PCT = 0.22;
const INTERVAL = 1800;
const DEFAULT_GRAPH_MIN = -5;
const DEFAULT_GRAPH_MAX = 1;

class NewCurtailmentGraph extends Component {
  constructor(props) {
    super(props);

    this.state = {
      graphWidth: null,
      graphHeight: null,
    };
  }

  getGraphData = (
    startTime,
    curtailmentStartTime,
    duration,
    timezone,
    requestedRealPowerP,
    minRealPowerP,
    solarPrediction,
    gridPrediction
  ) => {
    let timestampTicks = {};
    let zebraTicks = {};
    let counter = 0;
    for (let i = 0; i < 48; i++) {
      const timestamp = startTime + INTERVAL * i;
      timestampTicks[timestamp] = { timestamp: timestamp, display: "" };

      // Zebra striped lines occur every 4th line i.e. every second hour.
      if (counter % 4 === 1) {
        zebraTicks[timestamp * 1000] = { timestamp: timestamp * 1000, display: "" };
      }

      counter++;
    }

    // set the limit to be the system_min_p for this vpp
    let lowerLimitData;
    if (minRealPowerP) {
      lowerLimitData = [];
      Object.keys(timestampTicks)
        .sort()
        .forEach(function (k) {
          const timestamp = parseInt(k, 10);
          lowerLimitData.push({
            x: timestamp * 1000,
            y: minRealPowerP,
            timestamp: timestamp * 1000,
          });
        });
    }

    // show the line we want to curtail to
    let realPowerPData;
    if (requestedRealPowerP) {
      realPowerPData = [];
      Object.keys(timestampTicks)
        .sort()
        .forEach(function (k) {
          const timestamp = parseInt(k, 10);

          realPowerPData.push({
            x: timestamp * 1000,
            y: requestedRealPowerP,
            timestamp: timestamp * 1000,
          });
        });
    }

    // show the static solar predictions
    let solarPredictionData;
    if (solarPrediction) {
      solarPredictionData = [];
      Object.keys(timestampTicks)
        .sort()
        .forEach(function (k) {
          const timestamp = parseInt(k, 10);

          solarPredictionData.push({
            x: timestamp * 1000,
            y: solarPrediction[k],
            timestamp: timestamp * 1000,
          });
        });
    }

    // show the static grid predictions
    let gridPredictionData;
    if (gridPrediction) {
      gridPredictionData = [];
      Object.keys(timestampTicks)
        .sort()
        .forEach(function (k) {
          const timestamp = parseInt(k, 10);

          gridPredictionData.push({
            x: timestamp * 1000,
            y: gridPrediction[k],
            timestamp: timestamp * 1000,
          });
        });
    }

    let highlightedTimestampTicks = {};
    // Set up a highlighted vertical line for the start time of the dispatch.
    const jsStartDate = new Date(curtailmentStartTime * 1000);
    const startDateStr = getDateAsString(jsStartDate, timezone);
    const startTimeStr = getTimeAsString(jsStartDate, timezone, "HH:mm");
    highlightedTimestampTicks[curtailmentStartTime * 1000] = {
      timestamp: curtailmentStartTime * 1000,
      display: startDateStr + "\n" + startTimeStr,
    };

    // Set up a highlighted vertical line for the end time of the dispatch.
    const dispatchEndTime = curtailmentStartTime + duration;
    const jsEndDate = new Date(dispatchEndTime * 1000);
    const endTimeStr = getTimeAsString(jsEndDate, timezone, "HH:mm");
    highlightedTimestampTicks[dispatchEndTime * 1000] = {
      timestamp: dispatchEndTime * 1000,
      display: durationToString(duration) + "\n" + endTimeStr,
    };

    return {
      lowerLimitData: lowerLimitData,
      realPowerPData: realPowerPData,
      solarPredictionData: solarPredictionData,
      gridPredictionData: gridPredictionData,
      tickDisplay: timestampTicks,
      zebraTickDisplay: zebraTicks,
      highlightedTickDisplay: highlightedTimestampTicks,
    };
  };

  registerGraphContainer = graphContainer => {
    if (graphContainer) {
      this.setState({
        graphWidth: graphContainer.clientWidth,
        graphHeight: graphContainer.clientHeight,
      });
    }
  };

  getGraphDomain = (lowerLimit, solarPrediction, gridPrediction, startTimestamp, endTimestamp) => {
    let min = DEFAULT_GRAPH_MIN;
    let max = DEFAULT_GRAPH_MAX;

    if (solarPrediction) {
      Object.keys(solarPrediction)
        .sort()
        .forEach(function (k) {
          min = solarPrediction[k] < min ? solarPrediction[k] : min;
          max = solarPrediction[k] > max ? solarPrediction[k] : max;
        });
    }

    if (gridPrediction) {
      Object.keys(gridPrediction)
        .sort()
        .forEach(function (k) {
          min = gridPrediction[k] < min ? gridPrediction[k] : min;
          max = gridPrediction[k] > max ? gridPrediction[k] : max;
        });
    }

    // if the max export is more than the lowest prediction
    if (lowerLimit) {
      if (lowerLimit < min) {
        min = lowerLimit;
      }
    }
    max = max + Math.abs(max * PADDING_PCT);
    min = min - Math.abs(min * PADDING_PCT);
    return {
      x: [startTimestamp * 1000, endTimestamp * 1000],
      y: [min, max],
    };
  };

  render() {
    const {
      startTimestamp,
      curtailmentStartTimestamp,
      duration,
      timezone,
      requestedRealPowerP,
      minRealPowerP,
      solarPrediction,
      gridPrediction,
    } = this.props;
    const {
      lowerLimitData,
      realPowerPData,
      tickDisplay,
      zebraTickDisplay,
      highlightedTickDisplay,
      solarPredictionData,
      gridPredictionData,
    } = this.getGraphData(
      startTimestamp,
      curtailmentStartTimestamp,
      duration,
      timezone,
      requestedRealPowerP,
      minRealPowerP,
      solarPrediction,
      gridPrediction
    );
    const endTimestamp = startTimestamp + INTERVAL * 48;
    const width = this.state.graphWidth;
    const height = this.state.graphHeight;
    const bottomPadding = 18;
    const padding = { top: 0, right: 0, bottom: 0, left: 0 };
    const graphDomain = this.getGraphDomain(
      minRealPowerP,
      solarPrediction,
      gridPrediction,
      startTimestamp,
      endTimestamp
    );
    return (
      <div className="new-curtailment-response-graph">
        <div className="legend-container">
          <div className="legend">
            <span className="key-style solar-prediction" />
            <span className="key-label">Solar Prediction</span>
          </div>
          <div className="legend">
            <span className="key-style grid-prediction" />
            <span className="key-label">Grid Prediction</span>
          </div>
        </div>
        <div className="graph-container" ref={this.registerGraphContainer}>
          {this.state.graphWidth && (
            <svg
              width={width}
              height={height + bottomPadding}
              role="img"
              aria-labelledby="undefined-title undefined-desc"
              viewBox={`0 0 ${width} ${height + bottomPadding}`}
              overflow="visible"
              style={{
                width: `${width}px`,
                height: `${height + bottomPadding}px`,
                userSelect: "none",
                touchAction: "none",
                fill: "#ffffff",
              }}
            >
              <rect
                x="0"
                y="0"
                fill="#fff"
                style={{
                  width: "calc(100%)",
                  height: `calc(100% - ${bottomPadding}px)`,
                  stroke: "#E8E8E9",
                  strokeWidth: 1,
                }}
              />

              <g transform="translate(0, 0)">
                {/* x-axis thick grid lines/zebra striping */}
                <VictoryAxis
                  width={width}
                  height={height}
                  padding={padding}
                  domain={graphDomain}
                  style={{
                    axis: { stroke: "none" },
                    grid: {
                      stroke: "rgba(219, 226, 233, 0.3)",
                      strokeWidth: width / ((Object.keys(tickDisplay).length - 1) / 2),
                    },
                  }}
                  orientation="bottom"
                  tickValues={Object.keys(zebraTickDisplay).map(Number)}
                  tickFormat={tick => ""}
                  standalone={false}
                />

                {/* x-axis graph start label */}
                <VictoryAxis
                  width={width}
                  height={height + bottomPadding * 2}
                  padding={padding}
                  domain={graphDomain}
                  style={{
                    axis: { stroke: "none" },
                    grid: { stroke: "none" },
                    tickLabels: {
                      fontFamily: '"Work Sans", "Helvetica Neue", Helvetica, Arial, sans-serif',
                      fill: "#DBE2E9",
                      fontSize: "12",
                    },
                  }}
                  offsetY={42}
                  orientation="bottom"
                  tickValues={[Number(Object.keys(tickDisplay)[0])]}
                  tickFormat={tick => {
                    const jsDate = new Date(tick);
                    const dateStr = getDateAsString(jsDate, timezone);
                    const timeStr = getTimeAsString(jsDate, timezone, "HH:mm");
                    return dateStr + "\n" + timeStr;
                  }}
                  tickLabelComponent={<VictoryLabel textAnchor="start" />}
                  standalone={false}
                />

                {/* x-axis graph end label */}
                <VictoryAxis
                  width={width}
                  height={height + bottomPadding * 2}
                  padding={padding}
                  domain={graphDomain}
                  style={{
                    axis: { stroke: "none" },
                    grid: { stroke: "none" },
                    tickLabels: {
                      fontFamily: '"Work Sans", "Helvetica Neue", Helvetica, Arial, sans-serif',
                      fill: "#DBE2E9",
                      fontSize: "12",
                    },
                  }}
                  offsetY={42}
                  orientation="bottom"
                  tickValues={[
                    Number(Object.keys(tickDisplay)[Object.keys(tickDisplay).length - 1]) + 1800000,
                  ]}
                  tickFormat={tick => {
                    const jsDate = new Date(tick);
                    const dateStr = getDateAsString(jsDate, timezone);
                    const timeStr = getTimeAsString(jsDate, timezone, "HH:mm");
                    return dateStr + "\n" + timeStr;
                  }}
                  tickLabelComponent={<VictoryLabel textAnchor="end" />}
                  standalone={false}
                />

                {/* x-axis dispatch start/end labels */}
                <VictoryAxis
                  width={width}
                  height={height + bottomPadding * 2}
                  padding={padding}
                  domain={graphDomain}
                  style={{
                    axis: { stroke: "none" },
                    grid: { stroke: "none" },
                    tickLabels: {
                      fontFamily: '"Work Sans", "Helvetica Neue", Helvetica, Arial, sans-serif',
                      fill: "#4698cb",
                      fontSize: "12",
                    },
                  }}
                  offsetY={42}
                  orientation="bottom"
                  tickValues={Object.keys(highlightedTickDisplay).map(Number)}
                  tickFormat={tick => highlightedTickDisplay[tick].display}
                  standalone={false}
                />

                {/* y-axis grid */}
                <VictoryAxis
                  width={width}
                  height={height}
                  padding={0}
                  dependentAxis
                  domain={graphDomain}
                  crossAxis={false}
                  style={{
                    axis: { stroke: "#E8E8E9" },
                    grid: { stroke: "#E8E8E9" },
                  }}
                  orientation="left"
                  tickLabelComponent={<g />}
                  standalone={false}
                />

                {/* y-axis grid 0 line */}
                <VictoryAxis
                  width={width}
                  height={height}
                  padding={0}
                  dependentAxis
                  domain={graphDomain}
                  crossAxis={false}
                  style={{
                    axis: { stroke: "#E8E8E9", strokeWidth: 2 },
                    grid: { stroke: "#E8E8E9", strokeWidth: 2 },
                  }}
                  orientation="left"
                  tickValues={[0]}
                  tickFormat={tick => ""}
                  standalone={false}
                />

                {/* x-axis blue lines */}
                <VictoryAxis
                  width={width}
                  height={height}
                  padding={padding}
                  domain={graphDomain}
                  style={{
                    axis: { stroke: "none" },
                    grid: { stroke: "#4698cb" },
                  }}
                  orientation="bottom"
                  tickValues={Object.keys(highlightedTickDisplay).map(Number)}
                  tickFormat={tick => ""}
                  standalone={false}
                />

                {realPowerPData && (
                  <VictoryArea
                    width={width}
                    height={height}
                    padding={padding}
                    data={realPowerPData}
                    labels={[
                      "",
                      "",
                      "",
                      "",
                      "",
                      "",
                      `Requested Export Limit ${requestedRealPowerP} kW`,
                    ]}
                    domain={graphDomain}
                    interpolation="stepAfter"
                    labelComponent={<VictoryLabel dy={-30} />}
                    animate={{
                      duration: 200,
                      easing: "linear",
                      onLoad: { duration: 500 },
                    }}
                    style={{
                      data: {
                        stroke: "#26874B",
                        strokeWidth: 2,
                        strokeOpacity: 1,
                        fill: "#26874B",
                        fillOpacity: 0.0,
                      },
                      labels: {
                        fontSize: 12,
                        fill: "#26874B",
                      },
                    }}
                    standalone={false}
                  />
                )}

                {solarPredictionData && (
                  <VictoryArea
                    width={width}
                    height={height}
                    padding={padding}
                    data={solarPredictionData}
                    domain={graphDomain}
                    interpolation="stepAfter"
                    style={{
                      data: {
                        stroke: "#F68D26",
                        strokeWidth: 2,
                        strokeOpacity: 0.6,
                        strokeDasharray: "3, 2",
                        fill: "#F68D26",
                        fillOpacity: 0.0,
                      },
                    }}
                    standalone={false}
                  />
                )}

                {gridPredictionData && (
                  <VictoryArea
                    width={width}
                    height={height}
                    padding={padding}
                    data={gridPredictionData}
                    domain={graphDomain}
                    interpolation="stepAfter"
                    style={{
                      data: {
                        stroke: "#4698cb",
                        strokeWidth: 2,
                        strokeOpacity: 0.6,
                        strokeDasharray: "3, 2",
                        fill: "#4698cb",
                        fillOpacity: 0.0,
                      },
                    }}
                    standalone={false}
                  />
                )}

                {/* y-axis kW labels */}
                <VictoryAxis
                  width={width}
                  height={height}
                  padding={padding}
                  dependentAxis
                  domain={graphDomain}
                  crossAxis={false}
                  style={{
                    axis: { stroke: "none" },
                  }}
                  orientation="right"
                  offsetX={18}
                  tickLabelComponent={
                    <VictoryLabel
                      style={{
                        fontFamily: '"Work Sans", "Helvetica Neue", Helvetica, Arial, sans-serif',
                        fontSize: "14",
                        fill: "#616161",
                        fontWeight: 500,
                      }}
                      textAnchor="end"
                    />
                  }
                  tickFormat={t => (t ? t : 0) + "kW"}
                  standalone={false}
                />

                {lowerLimitData && (
                  <VictoryArea
                    width={width}
                    height={height}
                    padding={padding}
                    data={lowerLimitData}
                    // offset the label by 5 points
                    labels={[
                      "",
                      "",
                      "",
                      "",
                      "",
                      `VPP Max. Export ${Math.round(minRealPowerP * 100) / 100} kW`,
                    ]}
                    domain={graphDomain}
                    interpolation="stepAfter"
                    animate={{
                      duration: 200,
                      easing: "linear",
                      onLoad: { duration: 500 },
                    }}
                    style={{
                      data: {
                        stroke: "#26D07C",
                        strokeWidth: 2,
                        strokeOpacity: 0.6,
                        strokeDasharray: "10",
                        fill: "#26D07C",
                        fillOpacity: 0.0,
                      },
                      labels: {
                        fontSize: 12,
                        fill: "#26D07C",
                      },
                    }}
                    standalone={false}
                  />
                )}
              </g>
            </svg>
          )}
        </div>
      </div>
    );
  }
}
export default NewCurtailmentGraph;

const OFFLINE_DURATION_MINUTES = 15;

export const processMonitoringState = depl => {
  const working = depl.status.operationalStatus.lastValue > 0;

  const now = Math.round(Date.now() / 1000);
  let connectionOffline = false;
  let connectionOfflineOver24Hours = false;

  if (!depl.status.ping.lastTimestamp) {
    connectionOffline = true;
    connectionOfflineOver24Hours = true;
  } else if (now - 60 * OFFLINE_DURATION_MINUTES > depl.status.ping.lastTimestamp) {
    connectionOffline = true;
    if (now - 60 * 60 * 24 > depl.status.ping.lastTimestamp) {
      connectionOfflineOver24Hours = true;
    }
  }

  depl.status.operationalStatus.working = working;
  depl.status.ping.connectionOffline = connectionOffline;
  depl.status.ping.connectionOfflineOver24Hours = connectionOfflineOver24Hours;

  return depl;
};

import * as actions from "../actions/monitoring";

const monitoringInitialState = {};

const monitoringReducer = (state = monitoringInitialState, action) => {
  switch (action.type) {
    case "RESET_STORE":
      return {
        ...monitoringInitialState,
      };

    case actions.FETCH_POWER_DATA:
      return {
        ...state,
        loadingPowerData: true,
      };

    case actions.FETCH_SECONDARY_DATA:
      return {
        ...state,
        loadingSecondaryData: true,
      };

    case action.FETCH_CUSTOMER_DATA_SUBMETER:
      return {
        ...state,
        loadingSubmeterData: true,
      };

    case actions.FETCH_SUMMARY_DATA:
      return {
        ...state,
        loadingSummary: true,
      };

    case actions.UPDATE_DATA:
      return {
        ...state,
        loadingPowerData: false,
        [action.payload.period]: {
          ...state[action.payload.period],
          [action.payload.dateStr]: {
            ...(state[action.payload.period]
              ? state[action.payload.period][action.payload.dateStr]
              : null),
            ...action.payload.data,
          },
        },
      };

    case actions.UPDATE_SECONDARY_DATA:
      return {
        ...state,
        loadingSecondaryData: false,
        [action.payload.period]: {
          ...state[action.payload.period],
          [action.payload.dateStr]: {
            ...(state[action.payload.period]
              ? state[action.payload.period][action.payload.dateStr]
              : null),
            ...action.payload.data,
          },
        },
      };

    case actions.UPDATE_SUBMETER_DATA:
      // Submeters need to be done separately because they are stored in a sub-object of the data
      return {
        ...state,
        loadingSubmeterData: false,
        [action.payload.period]: {
          ...state[action.payload.period],
          [action.payload.dateStr]: {
            ...(state[action.payload.period]
              ? state[action.payload.period][action.payload.dateStr]
              : null),
            submeters: {
              ...(state[action.payload.period] &&
              state[action.payload.period][action.payload.dateStr]
                ? state[action.payload.period][action.payload.dateStr].submeters
                : null),
              ...action.payload.data,
            },
          },
        },
      };

    case actions.UPDATE_SUMMARY_DATA:
      return {
        ...state,
        loadingSummary: false,
        [action.payload.interval]: {
          ...state[action.payload.interval],
          [action.payload.dateStr]: {
            ...(state[action.payload.interval]
              ? state[action.payload.interval][action.payload.dateStr]
              : null),
            summary: {
              ...action.payload.data,
            },
          },
        },
      };
    case actions.CLEAR_MONITORING_DATA:
      return { ...monitoringInitialState };

    default:
      return state;
  }
};

export default monitoringReducer;

import React, { Component } from "react";
import { Textarea, SelectDropdown } from "../widgets";
import CustomerAppMessagePreview from "./CustomerAppMessagePreview";
import "./CustomerAppMessageEdit.css";

const STANDARD_MESSAGE =
  "Your battery has been requested to charge for 1 hour and 30 minutes starting at 11:30 am. You will receive GridCredits for this service.";
const STANDARD_TITLE = "GridCredits Event";

class CustomerAppMessageEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      standardMessage: STANDARD_MESSAGE,
      messageTitle: STANDARD_TITLE,
    };
  }

  componentDidMount() {
    let message, title;
    if (this.props.standardMessage) {
      message = this.props.standardMessage;
    } else {
      message = STANDARD_MESSAGE;
    }

    if (this.props.messageTitle) {
      title = this.props.messageTitle;
    } else {
      title = STANDARD_TITLE;
    }

    this.setState({
      standardMessage: message,
      messageTitle: title,
    });
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.standardMessage !== nextProps.standardMessage) {
      this.setState({
        standardMessage: nextProps.standardMessage,
      });
    }

    if (this.props.messageTitle !== nextProps.messageTitle) {
      this.setState({
        messageTitle: nextProps.messageTitle,
      });
    }
  }

  getPreviousMessageList = () => {
    let messagesFound = {};
    let messages = [];

    // Ensure we sort before filtering out duplicates. That way if a old message was used again
    // more recently it will appear at the top of the list.
    messages.sort((a, b) => {
      if (a.createdAt < b.createdAt) return 1;
      if (a.createdAt > b.createdAt) return -1;
      return 0;
    });

    for (let i in this.props.previousCustomerAppMessages) {
      const message = this.props.previousCustomerAppMessages[i];
      if (!messagesFound[message.customerMessage]) {
        messagesFound[message.customerMessage] = true;
        messages.push(message);
      }
    }

    messages = messages.map(m => {
      return {
        value: m.customerMessage,
        label: m.customerMessage,
      };
    });

    return messages;
  };

  render() {
    return (
      <div className="customer-app-message-edit-container">
        <div className="message-container">
          <p>
            Each customer that participates in this dispatch will receive a message via the Reposit
            Power app once the dispatch is scheduled.
          </p>
          <p>
            {this.props.previousCustomerAppMessages &&
            this.props.previousCustomerAppMessages.length > 0
              ? "You can customise this message below or select a previous one."
              : "You can customise this message below."}
          </p>
          {this.props.previousCustomerAppMessages &&
          this.props.previousCustomerAppMessages.length > 0 ? (
            <div className="history-container link">
              <span className="fa fa-history history-icon" />
              <SelectDropdown
                placeholder="Previous Messages"
                options={this.getPreviousMessageList()}
                onChange={this.props.onChange}
              />
            </div>
          ) : null}
          <Textarea
            value={this.props.customerAppMessage}
            onChange={e => this.props.onChange(e.target.value)}
            style={{ fontSize: "0.9rem", backgroundColor: "rgba(247, 249, 253, 0.5)" }}
          />
        </div>
        <div className="preview-container">
          <CustomerAppMessagePreview
            title={this.state.messageTitle}
            message={
              this.props.customerAppMessage
                ? `${this.state.standardMessage}

${this.props.customerAppMessage}`
                : this.state.standardMessage
            }
          />
        </div>
      </div>
    );
  }
}

export default CustomerAppMessageEdit;

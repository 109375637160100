import React, { Component } from "react";
import { SelectDropdown, Input, Tooltip } from "../../components/widgets";
import "./PowerstationType.css";

const FilterTypeDropdownLabel = ({ label, enrolmentType, isSelector }) =>
  isSelector ? (
    <>{label}</>
  ) : (
    <span className="filter-type-dd-label">
      <span>{label}</span>
      {isSelector ? null : <div className="enrolment-type">{enrolmentType}</div>}
    </span>
  );

const POWERSTATION_FILTER_TYPES = [
  {
    value: "INDIVIDUAL_NODES",
    label: "individual nodes",
    labelComponent: <FilterTypeDropdownLabel label="individual nodes" enrolmentType="Manual" />,
  },
  {
    value: "POSTCODE",
    label: "nodes within a postcode",
    labelComponent: (
      <FilterTypeDropdownLabel label="nodes within a postcode" enrolmentType="Automatic" />
    ),
  },
  {
    value: "STATE",
    label: "nodes within a state",
    labelComponent: (
      <FilterTypeDropdownLabel label="nodes within a state" enrolmentType="Automatic" />
    ),
  },
  {
    value: "FCAS",
    label: "nodes enabled for this FCAS DUID",
    labelComponent: (
      <FilterTypeDropdownLabel label="nodes enabled for this FCAS DUID" enrolmentType="Automatic" />
    ),
  },
];

const STATES = [
  { value: "ACT", label: "Australian Capital Territory" },
  { value: "NSW", label: "New South Wales" },
  { value: "NT", label: "Northern Territory" },
  { value: "QLD", label: "Queensland" },
  { value: "SA", label: "South Australia" },
  { value: "TAS", label: "Tasmania" },
  { value: "VIC", label: "Victoria" },
  { value: "WA", label: "Western Australia" },
];

class PowerstationType extends Component {
  registerPostcode = postcodeField => {
    postcodeField.focus();
  };

  getTypeSettings = () => {
    if (this.props.type === "POSTCODE") {
      return this.props.filters.postcodes ? `- ${this.props.filters.postcodes.join()}` : "";
    } else if (this.props.type === "STATE") {
      const state = STATES.find(s => s.value === this.props.filters.state);
      return state ? `- ${state.label}` : "";
    }
    return "";
  };

  render() {
    if (this.props.simple) {
      return (
        <p>
          {POWERSTATION_FILTER_TYPES.find(f => f.value === this.props.type).label}{" "}
          {this.getTypeSettings()}
        </p>
      );
    }
    return (
      <div className="powerstation-type">
        <span>This Virtual Power Plant is made up of </span>
        <SelectDropdown
          value={this.props.type}
          onChange={this.props.handleTypeOnChange}
          options={
            this.props.readOnly
              ? POWERSTATION_FILTER_TYPES
              : POWERSTATION_FILTER_TYPES.filter(p => p.value !== "FCAS")
          }
          readOnly={this.props.readOnly || this.props.filterTypeReadOnly}
        />

        {this.props.inModal ? (
          <Tooltip large id="powerstations.detail.powerstationTypeSmall" />
        ) : (
          <Tooltip large id="powerstations.detail.powerstationType" />
        )}

        {this.props.type === "STATE" || this.props.type === "POSTCODE" ? (
          <span className="spacer">-</span>
        ) : null}

        {this.props.type === "POSTCODE" ? (
          <div className="filter-item">
            <Input
              autoFocus
              readOnly={this.props.readOnly}
              placeholder="Postcodes - separated with a comma"
              onChange={e => this.props.handleFilterOnChange(e.target.value)}
              value={this.props.filters.postcodes ? this.props.filters.postcodes.join() : ""}
              error={this.props.error}
            />
          </div>
        ) : null}

        {this.props.type === "STATE" ? (
          <SelectDropdown
            options={STATES}
            value={this.props.filters.state ? this.props.filters.state : null}
            onChange={this.props.handleFilterOnChange}
            placeholder="Select a state..."
            readOnly={this.props.readOnly}
            error={this.props.error}
          />
        ) : null}
      </div>
    );
  }
}

export default PowerstationType;

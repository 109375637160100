import * as actions from "../actions/apikeys";

const apiKeysInitialState = {
  apiKeys: null,
  updatePending: false,
  newKey: null,
};

const apiKeysReducer = (state = apiKeysInitialState, action) => {
  switch (action.type) {
    case actions.FETCH_API_KEYS:
      return {
        ...state,
        apiKeys: null,
      };
    case actions.UPDATE_API_KEYS:
      return {
        ...state,
        apiKeys: action.payload.keys.map(key => {
          return {
            ...key,
            status: "VALID",
          };
        }),
      };
    case actions.ADD_API_KEY:
    case actions.REVOKE_API_KEY:
      return {
        ...state,
        updatePending: true,
      };
    case actions.UPDATE_API_KEY_ADDED:
      let newListEntry = {
        ...action.payload.key,
        status: "VALID",
      };
      delete newListEntry.key;
      return {
        updatePending: false,
        apiKeys: [...state.apiKeys, newListEntry],
        newKey: action.payload.key,
      };
    case actions.UPDATE_API_KEY_WITNESSED:
      return {
        ...state,
        newKey: null,
      };
    case actions.UPDATE_API_KEY_REVOKED:
      return {
        updatePending: false,
        apiKeys: state.apiKeys.map(apiKey => {
          return apiKey.keyId === action.payload.keyId
            ? {
                ...apiKey,
                status: "REVOKED",
              }
            : apiKey;
        }),
      };
    case actions.UPDATE_API_KEY_ACTION_FAILED:
      return {
        ...state,
        updatePending: false,
      };
    case actions.CLEAR_API_KEYS:
      return {
        apiKeys: null,
        updatePending: false,
        newKey: null,
      };
    default:
      return state;
  }
};

export default apiKeysReducer;

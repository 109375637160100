import * as actions from "../actions/ui";

const uiInitialState = {
  confirm: null,
};

const uiReducer = (state = uiInitialState, action) => {
  switch (action.type) {
    case "RESET_STORE":
      return {
        ...uiInitialState,
      };
    case actions.OPEN_CONFIRM:
      return {
        ...state,
        confirm: {
          message: action.payload.message,
          id: action.payload.id,
        },
      };
    case actions.CLOSE_CONFIRM:
      return {
        ...state,
        confirm: null,
      };
    default:
      return state;
  }
};

export default uiReducer;

import React, { Component } from "react";
import DeckGL, { IconLayer } from "deck.gl";

const ICON_SIZE = 150;

export default class DeploymentMapEditLayer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      x: 0,
      y: 0,
      hoveredItems: null,
      expanded: false,
    };
  }

  render() {
    const { viewport, unselectedData, selectedData, iconAtlas, iconMapping } = this.props;

    if (!(unselectedData || selectedData) || !iconMapping) {
      return null;
    }

    const unselectedLayer = new IconLayer({
      id: "unselected",
      data: unselectedData,
      pickable: !!this.props.onSelect,
      iconAtlas,
      iconMapping,
      // sizeScale: ICON_SIZE * size * window.devicePixelRatio,
      getPosition: d => d.coordinates,
      getIcon: d => `white${d.iconNameSuffix}`,
      getSize: d => (this.props.iconSize ? this.props.iconSize : ICON_SIZE),
      // onHover: this.props.onHover,
      onClick: this.props.onSelect,
    });

    const selectedLayer = new IconLayer({
      id: "selected",
      data: selectedData,
      pickable: !!this.props.onUnselect,
      iconAtlas,
      iconMapping,
      // sizeScale: ICON_SIZE * size * window.devicePixelRatio,
      getPosition: d => d.coordinates,
      getIcon: d => `dark${d.iconNameSuffix}`,
      getSize: d => (this.props.iconSize ? this.props.iconSize : ICON_SIZE),
      // onHover: this.props.onHover,
      onClick: this.props.onUnselect,
    });

    return <DeckGL {...viewport} layers={[unselectedLayer, selectedLayer]} />;
  }
}

import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import AuthRequired from "../AuthRequired";
import * as usermanagementActions from "../../redux/actions/usermanagement";
import Permissions, { hasPermission } from "../../permissions";
import "./User.css";

const mapStateToProps = state => ({
  users: state.userManagement.users,
  user: state.user,
});

const mapDispatchToProps = dispatch => {
  return {
    usermanagementActions: bindActionCreators(usermanagementActions, dispatch),
  };
};

class User extends Component {
  componentDidMount = () => {
    if (!this.props.users) {
      this.props.usermanagementActions.fetchAllUsers();
    }
  };

  getUsersName = () => {
    const user = this.props.users.find(u => u.id === this.props.userId);
    if (user) {
      return (
        <span className="mp-user">
          {hasPermission(this.props.user.permissions, Permissions.fleet.myReposit.users) ? (
            <Link to={`/my-reposit/users/${user.id}`}>{`${user.givenName} ${user.surname}`}</Link>
          ) : (
            `${user.givenName} ${user.surname}`
          )}
        </span>
      );
    } else {
      return <span className="mp-user unknown">Unknown</span>;
    }
  };

  render() {
    return (
      <AuthRequired
        any={[
          Permissions.fleet.myReposit.users,
          Permissions.fleet.dispatches.any,
          Permissions.fleet.rert.any,
          Permissions.fleet.curtailments.any,
          Permissions.fleet.powerstations.gridOutages.any,
        ]}
      >
        {this.props.users ? (
          this.getUsersName()
        ) : (
          <span className="mp-user loading">Loading...</span>
        )}
      </AuthRequired>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(User);

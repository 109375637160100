import React, { Component } from "react";
import { Input, Button } from "../widgets";
import "./TariffRatesTable.css";

class TariffRatesTable extends Component {
  shouldShowAddBlockLink = (rate, blockIdx) => {
    if (rate.isBaseRate && !this.props.enableMultipleBaseRateBlocks) {
      return false;
    }

    if (blockIdx === rate.blocks.length - 1 && this.props.addBlock) {
      return true;
    }

    return false;
  };

  render() {
    const rates = this.props.rates;
    const isReadOnly = this.props.readOnly;
    const units = this.props.units ? this.props.units : "kWh";

    return (
      <table
        cellSpacing={0}
        cellPadding={0}
        className={"table-light rates-table" + (isReadOnly ? " ro" : "")}
      >
        <thead>
          <tr>
            <th>Label</th>
            {this.props.addBlock ? <th>{units} Range</th> : null}
            <th>$/{units} (ex. GST)</th>
            <th />
          </tr>
        </thead>
        <tbody>
          {rates.map((r, i) => {
            return r.blocks.map((b, j) => {
              return (
                <tr className={r.isBaseRate ? "base-rate" : null}>
                  {j === 0 ? (
                    <td className="label" rowSpan={r.blocks.length}>
                      <Input
                        readOnly={isReadOnly}
                        value={r.label}
                        onChange={e => this.props.rateChange(i, "label", e.target.value)}
                        error={this.props.errors[i] ? this.props.errors[i].label : null}
                      />
                      {r.isBaseRate ? (
                        <span className="base-rate-text">This is the base rate</span>
                      ) : null}
                    </td>
                  ) : null}
                  {this.props.addBlock ? (
                    <td className="block-onset">
                      {b.onset === 0 ? (
                        "From 0 kWh"
                      ) : (
                        <span>
                          {"From"}
                          <Input
                            readOnly={isReadOnly}
                            value={b.onset}
                            onChange={e => this.props.blockChange(i, j, "onset", e.target.value)}
                            error={
                              this.props.errors[i] && this.props.errors[i].blocks[j]
                                ? this.props.errors[i].blocks[j].onset
                                : null
                            }
                          />
                          {units}
                        </span>
                      )}
                      {!isReadOnly && this.shouldShowAddBlockLink(r, j) ? (
                        <span className="block-add">
                          <Button
                            id="tariff-add-block"
                            type="link"
                            onClick={() => this.props.addBlock(i)}
                          >
                            <i className="fa fa-plus-circle" />
                            Add Range
                          </Button>
                        </span>
                      ) : null}
                    </td>
                  ) : null}
                  <td className="price">
                    <Input
                      readOnly={isReadOnly}
                      value={b.price}
                      onChange={e => this.props.blockChange(i, j, "price", e.target.value)}
                      error={
                        this.props.errors[i] && this.props.errors[i].blocks[j]
                          ? this.props.errors[i].blocks[j].price
                          : null
                      }
                    />
                  </td>
                  <td className="actions">
                    {isReadOnly || (i === 0 && j === 0) ? null : (
                      <Button
                        id="tariff-remove-block"
                        type="cancel"
                        onClick={() => this.props.removeBlock(i, j)}
                      >
                        <i className="fa fa-times-circle" />
                      </Button>
                    )}
                  </td>
                </tr>
              );
            });
          })}
        </tbody>
        {!isReadOnly && this.props.addRate ? (
          <tfoot>
            <tr>
              <td className="rate-add" colSpan={4}>
                <Button id="tariff-add-rate" type="link" onClick={this.props.addRate}>
                  <i className="fa fa-plus-circle" />
                  Add Rate
                </Button>
              </td>
            </tr>
          </tfoot>
        ) : null}
      </table>
    );
  }
}

export default TariffRatesTable;

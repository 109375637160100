import React, { Component } from "react";
import Tooltip from "./Tooltip";
import "./Checkbox.css";

class Checkbox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: props.error,
    };
  }

  componentWillReceiveProps = nextProps => {
    // Remove the existing error if we receive a new value but the error has not yet changed.
    // This probably indicates that the user is correcting the error and in most cases they will
    // do this successfully - we can wait until new validation to re-display the error if they
    // still haven't fixed it.
    if (this.props.checked !== nextProps.checked && this.props.error === nextProps.error) {
      this.setState({
        error: null,
      });
    }

    if (this.props.error !== nextProps.error) {
      this.setState({
        error: nextProps.error,
      });
    }
  };

  render() {
    const id =
      this.props && this.props.id ? this.props.id : `cb_${Math.round(Math.random() * 1e10)}`;

    if (this.props.readOnly) {
      return (
        <span className="rp-widget checkbox ro">
          {this.props.checked ? <span className="checked" /> : <span className="not-checked" />}
        </span>
      );
    } else if (!this.props.label) {
      return (
        <span className={"rp-widget checkbox" + (this.props.error ? " error" : "")}>
          <input id={id} type="checkbox" {...this.props} />
          {this.props.checkboxComponent ? (
            this.props.checkboxComponent(id, "control")
          ) : (
            <label htmlFor={id} className="control" />
          )}
        </span>
      );
    } else {
      return (
        <span>
          <label className="checkbox-label">
            <span
              className={
                "rp-widget checkbox" +
                (this.state.error ? " error" : "") +
                (this.props.className ? ` ${this.props.className}` : "")
              }
            >
              <input id={id} type="checkbox" {...this.props} />
              {this.props.checkboxComponent ? (
                this.props.checkboxComponent(id, "control")
              ) : (
                <label htmlFor={id} className="control" />
              )}
            </span>
            {this.props.label ? <span className="label-text">{this.props.label}</span> : null}
            {this.props.tooltipContent || this.props.tooltipId ? (
              <Tooltip
                id={this.props.tooltipId}
                title={this.props.tooltipTitle}
                content={this.props.tooltipContent}
              />
            ) : null}
          </label>
          {this.props.error ? <span className="error-message">{this.state.error}</span> : null}
        </span>
      );
    }
  }
}

export default Checkbox;

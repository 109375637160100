import { history } from "../../containers/Root";
import { Notify } from "../../utils/notificationManager";

export const FETCH_RERT_DISPATCHES = "FETCH_RERT_DISPATCHES";
export const UPDATE_RERT_DISPATCHES = "UPDATE_RERT_DISPATCHES";
export const FETCH_RERT_POWERSTATIONS = "FETCH_RERT_POWERSTATIONS";
export const UPDATE_RERT_POWERSTATIONS = "UPDATE_RERT_POWERSTATIONS";
export const FETCH_PREVIOUS_RERT_DISPATCH_MESSAGES = "FETCH_PREVIOUS_RERT_DISPATCH_MESSAGES";
export const UPDATE_PREVIOUS_RERT_DISPATCH_MESSAGES = "UPDATE_PREVIOUS_RERT_DISPATCH_MESSAGES";
export const FETCH_NEW_RERT_DISPATCH_CONSTRAINTS = "FETCH_NEW_RERT_DISPATCH_CONSTRAINTS";
export const UPDATE_NEW_RERT_DISPATCH_CONSTRAINTS = "UPDATE_NEW_RERT_DISPATCH_CONSTRAINTS";
export const FETCH_NEW_RERT_DISPATCH_PREDICTIONS = "FETCH_NEW_RERT_DISPATCH_PREDICTIONS";
export const UPDATE_NEW_RERT_DISPATCH_PREDICTIONS = "UPDATE_NEW_RERT_DISPATCH_PREDICTIONS";
export const CLEAR_NEW_RERT_DISPATCH_GRAPH_DATA = "CLEAR_NEW_RERT_DISPATCH_GRAPH_DATA";
export const CLEAR_PREVIOUS_RERT_DISPATCH_MESSAGES = "CLEAR_PREVIOUS_RERT_DISPATCH_MESSAGES";
export const EXECUTE_RERT_DISPATCH = "EXECUTE_RERT_DISPATCH";
export const UPDATE_EXECUTE_RERT_DISPATCH = "UPDATE_EXECUTE_RERT_DISPATCH";
export const FETCH_ACTIVE_RERT_DISPATCH = "FETCH_RERT_DISPATCH";
export const UPDATE_ACTIVE_RERT_DISPATCH = "UPDATE_ACTIVE_RERT_DISPATCH";
export const CLEAR_ACTIVE_RERT_DISPATCH = "CLEAR_ACTIVE_RERT_DISPATCH";
export const FETCH_RERT_DISPATCH_RESPONSE = "FETCH_RERT_DISPATCH_RESPONSE";
export const UPDATE_RERT_DISPATCH_RESPONSE = "UPDATE_RERT_DISPATCH_RESPONSE";
export const FETCH_RERT_DISPATCH_PREDICTION = "FETCH_RERT_DISPATCH_PREDICTION";
export const UPDATE_RERT_DISPATCH_PREDICTION = "UPDATE_RERT_DISPATCH_PREDICTION";
export const FETCH_RERT_DISPATCH_REMAINING_CHARGE = "FETCH_RERT_DISPATCH_REMAINING_CHARGE";
export const UPDATE_RERT_DISPATCH_REMAINING_CHARGE = "UPDATE_RERT_DISPATCH_REMAINING_CHARGE";
export const CANCEL_RERT_DISPATCH = "CANCEL_RERT_DISPATCH";
export const UPDATE_CANCEL_RERT_DISPATCH = "UPDATE_CANCEL_RERT_DISPATCH";
export const RESCHEDULE_RERT_DISPATCH = "RESCHEDULE_RERT_DISPATCH";
export const UPDATE_RESCHEDULE_RERT_DISPATCH = "UPDATE_RESCHEDULE_RERT_DISPATCH";

export function fetchRertDispatches() {
  return {
    type: FETCH_RERT_DISPATCHES,
    meta: {
      endpoint: "/api/rert-dispatches",
      success: updateRertDispatches,
      errorMessage: "Failed to load RERT dispatch requests.",
    },
  };
}

function updateRertDispatches(response) {
  return {
    type: UPDATE_RERT_DISPATCHES,
    payload: {
      rertDispatches: response.data,
    },
  };
}

export function fetchPowerstations() {
  return {
    type: FETCH_RERT_POWERSTATIONS,
    meta: {
      endpoint: "/api/powerstations",
      success: updatePowerstations,
      errorMessage: "Failed to load Virtual Power Plant.",
    },
  };
}

function updatePowerstations(powerstations) {
  return {
    type: UPDATE_RERT_POWERSTATIONS,
    payload: {
      powerstations: powerstations.data,
    },
  };
}

export function fetchPreviousRertDispatchMessges() {
  return {
    type: FETCH_PREVIOUS_RERT_DISPATCH_MESSAGES,
    meta: {
      endpoint: "/api/rert-dispatches/messages",
      success: updatePreviousRertDispatchMessages,
      errorMessage: "Failed to load previous RERT dispatch messages.",
    },
  };
}

function updatePreviousRertDispatchMessages(response) {
  return {
    type: UPDATE_PREVIOUS_RERT_DISPATCH_MESSAGES,
    payload: {
      messages: response.data,
    },
  };
}

export function fetchNewRertDispatchConstraints(stationId, duration, realPowerP) {
  let endpoint = `/api/powerstations/${stationId}/constraints?duration=${duration}`;
  endpoint += realPowerP !== undefined && realPowerP !== null ? "&realPowerP=" + realPowerP : "";

  return {
    type: FETCH_NEW_RERT_DISPATCH_CONSTRAINTS,
    meta: {
      endpoint: endpoint,
      success: updateNewRertDispatchConstraints,
      errorMessage: "Failed to load RERT dispatch constraints.",
    },
  };
}

function updateNewRertDispatchConstraints(constraints) {
  return {
    type: UPDATE_NEW_RERT_DISPATCH_CONSTRAINTS,
    payload: {
      constraints: constraints.data,
    },
  };
}

export function fetchNewRertDispatchPrediction(
  stationId,
  startTime,
  dispatchStartTime,
  duration,
  realPowerP
) {
  return {
    type: FETCH_NEW_RERT_DISPATCH_PREDICTIONS,
    meta: {
      endpoint: `/api/powerstations/${stationId}/predictions?start=${startTime}&dispatchStart=${dispatchStartTime}&dispatchDuration=${duration}&dispatchRealPowerP=${realPowerP}`,
      success: updateNewRertDispatchPrediction,
      errorMessage: "Failed to load RERT dispatch prediction.",
    },
  };
}

function updateNewRertDispatchPrediction(predictions) {
  return {
    type: UPDATE_NEW_RERT_DISPATCH_PREDICTIONS,
    payload: {
      predictions: predictions.data,
    },
  };
}

export function clearNewRertDispatchGraphData() {
  return {
    type: CLEAR_NEW_RERT_DISPATCH_GRAPH_DATA,
  };
}

export function clearPreviousRertDispatchMessages() {
  return {
    type: CLEAR_PREVIOUS_RERT_DISPATCH_MESSAGES,
  };
}

export function executeRertDispatch(
  stationId,
  startTime,
  duration,
  realPowerP,
  customerAppMessage,
  strategy
) {
  return {
    type: EXECUTE_RERT_DISPATCH,
    meta: {
      endpoint: "/api/rert-dispatches",
      method: "POST",
      body: {
        powerstation: stationId,
        startTime: startTime,
        duration: duration,
        realPowerP: realPowerP,
        customerMessage: customerAppMessage,
        strategy: strategy,
      },
      success: updateExecuteRertDispatch,
      errorMessage: "Failed to execute RERT Dispatch.",
    },
  };
}

function updateExecuteRertDispatch(response) {
  if (response) {
    if (response.status === "ERROR") {
      // deals with 400s (treated as a success by fetchMiddleware)
      const message = (response.data && response.data.message) || "";
      Notify.error("Failed to execute RERT dispatch.", message);
    } else {
      Notify.success("RERT Dispatch successfully executed");

      if (response.data && response.data.id) {
        history.push(`/virtual-power-plants/rert-dispatches/${response.data.id}`);
      }
    }
  }

  return {
    type: UPDATE_EXECUTE_RERT_DISPATCH,
  };
}

// the flushExisting bool should be set to true if you want to remove the currently loaded active dispatch from the store.
// In cases when you are auto-refreshing a dispatch you will want to set this to false.
export function fetchActiveRertDispatch(dispatchId) {
  return {
    type: FETCH_ACTIVE_RERT_DISPATCH,
    meta: {
      endpoint: "/api/rert-dispatches/" + dispatchId,
      success: updateActiveRertDispatch,
      errorMessage: "Failed to load RERT dispatch.",
    },
  };
}

function updateActiveRertDispatch(response) {
  return {
    type: UPDATE_ACTIVE_RERT_DISPATCH,
    payload: {
      dispatch: response.data,
    },
  };
}

export function clearActiveRertDispatch() {
  return {
    type: CLEAR_ACTIVE_RERT_DISPATCH,
  };
}

export function fetchRertDispatchPrediction(dispatchId) {
  return {
    type: FETCH_RERT_DISPATCH_PREDICTION,
    meta: {
      endpoint: "/api/rert-dispatches/" + dispatchId + "/prediction",
      success: updateRertDispatchPrediction,
      errorMessage: "Failed to load RERT dispatch prediction.",
    },
  };
}

function updateRertDispatchPrediction(response) {
  return {
    type: UPDATE_RERT_DISPATCH_PREDICTION,
    payload: {
      prediction: response.data,
    },
  };
}

export function fetchRertDispatchRemainingCharge(dispatchId) {
  return {
    type: FETCH_RERT_DISPATCH_REMAINING_CHARGE,
    meta: {
      endpoint: `/api/rert-dispatches/${dispatchId}/remainingcharge`,
      success: updateRertDispatchRemainingCharge,
      errorMessage: "Failed to load remaining charge.",
    },
  };
}

function updateRertDispatchRemainingCharge(response) {
  return {
    type: UPDATE_RERT_DISPATCH_REMAINING_CHARGE,
    payload: {
      remainingCharge: response.data,
    },
  };
}

export function fetchRertDispatchResponse(dispatchId) {
  return {
    type: FETCH_RERT_DISPATCH_RESPONSE,
    meta: {
      endpoint: "/api/rert-dispatches/" + dispatchId + "/response",
      success: updateRertDispatchResponse,
      errorMessage: "Failed to load RERT dispatch response.",
    },
  };
}

function updateRertDispatchResponse(response) {
  return {
    type: UPDATE_RERT_DISPATCH_RESPONSE,
    payload: {
      response: response.data,
    },
  };
}

export function cancelRertDispatch(dispatchId) {
  return {
    type: CANCEL_RERT_DISPATCH,
    meta: {
      endpoint: "/api/rert-dispatches/" + dispatchId + "/cancel",
      method: "POST",
      success: updateCancelRertDispatch,
      errorMessage: "Failed to cancel RERT dispatch.",
    },
  };
}

function updateCancelRertDispatch(response) {
  if (response) {
    if (response.status === "ERROR") {
      // deals with 400s (treated as a success by fetchMiddleware)
      const message = (response.data && response.data.message) || "";
      Notify.error("Failed to cancel RERT dispatch.", message);
    } else {
      Notify.success("RERT Dispatch successfully cancelled.");
      history.push("/virtual-power-plants/rert-dispatches");
    }
  }
  return {
    type: UPDATE_CANCEL_RERT_DISPATCH,
  };
}

export function rescheduleRertDispatch(dispatchId, startTime, duration, customerAppMessage) {
  return {
    type: RESCHEDULE_RERT_DISPATCH,
    meta: {
      endpoint: "/api/rert-dispatches/" + dispatchId + "/reschedule",
      method: "POST",
      body: {
        startTime: startTime,
        duration: duration,
        customerAppMessage: customerAppMessage,
      },
      success: updateRescheduleRertDispatch,
      errorMessage: "Failed to reschedule RERT dispatch.",
    },
  };
}

function updateRescheduleRertDispatch(response) {
  if (response) {
    if (response.status === "ERROR") {
      // deals with 400s (treated as a success by fetchMiddleware)
      const message = (response.data && response.data.message) || "";
      Notify.error("Failed to reschedule RERT dispatch.", message);
    } else {
      Notify.success("RERT Dispatch successfully rescheduled");

      if (response.data && response.data.id) {
        history.replace(`/virtual-power-plants/rert-dispatches/${response.data.id}`);
      }
    }
  }

  return {
    type: UPDATE_RESCHEDULE_RERT_DISPATCH,
  };
}

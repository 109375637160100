import React from "react";
import "./MonitoringGraphTooltip.css";
import { getTimeAsString } from "../widgets/Moment";

class PrimaryLabel extends React.Component {
  render() {
    return (
      <div className="metric">
        <div className="color-container">
          <div className="color" style={{ borderColor: this.props.color }} />
        </div>
        <div className="name">{this.props.name}</div>
        {this.props.value !== null && <div className="value">{this.props.value}</div>}
        {this.props.value !== null && this.props.unit && (
          <div className="unit">&nbsp;{this.props.unit}</div>
        )}
        {this.props.value !== null && this.props.direction && (
          <div className="direction">{this.props.direction}</div>
        )}
      </div>
    );
  }
}

class MonitoringGraphTooltip extends React.Component {
  getGraphDataPoint(graphData, metric, datestamp) {
    return graphData[metric] && graphData[metric][datestamp] !== undefined
      ? graphData[metric][datestamp]
      : null;
  }

  render() {
    const { timestamp, timezone, data, components, componentState } = this.props;
    const dateLabel = getTimeAsString(
      new Date(timestamp * 1000),
      timezone,
      "dddd DD MMMM YYYY, h:mma"
    );

    const houseValue = this.getGraphDataPoint(data, "housePower", timestamp);
    const batteryValue = this.getGraphDataPoint(data, "batteryPower", timestamp);
    const solarValue = this.getGraphDataPoint(data, "solarPower", timestamp);
    const sgaValue = this.getGraphDataPoint(data, "meterSGA", timestamp);
    const gridValue = this.getGraphDataPoint(data, "meterPower", timestamp);

    const dataPrecision = 2;
    let secondaryAxisLabel = null;
    let secondaryAxes = [];

    if (componentState.secondaryYAxis !== null) {
      switch (componentState.secondaryYAxis) {
        case "reactive_power":
          secondaryAxisLabel = "Reactive Power";
          secondaryAxes = [
            {
              label: "Solar Reactive Power",
              unit: "kvar",
              color: "rgb(246, 141, 38)",
              value: this.getGraphDataPoint(data, "solarReactivePower", timestamp),
            },
            {
              label: "Meter Reactive Power",
              unit: "kvar",
              value: this.getGraphDataPoint(data, "meterReactivePower", timestamp),
            },
          ];
          break;
        case "frequency":
          secondaryAxisLabel = "Frequency";
          secondaryAxes = [
            {
              label: secondaryAxisLabel,
              unit: "Hz",
              value: this.getGraphDataPoint(data, "meterFrequency", timestamp),
            },
          ];
          break;
        case "voltage":
          secondaryAxisLabel = "Voltage";
          secondaryAxes = [
            {
              label: secondaryAxisLabel,
              unit: "V",
              value: this.getGraphDataPoint(data, "meterVoltage", timestamp),
            },
          ];
          break;
        case "state_of_charge":
          secondaryAxisLabel = "State of Charge";
          secondaryAxes = [
            {
              label: secondaryAxisLabel,
              unit: "%",
              value: this.getGraphDataPoint(data, "soc", timestamp),
            },
          ];
          break;
        default:
          console.warn("Unknown secondary axis type", componentState.secondaryYAxis);
          break;
      }
    }

    const isWide =
      componentState.house ||
      (components.submeters &&
        components.submeters.find(submeter => componentState.submeters[submeter.id]));

    return (
      <div
        className={`monitoring-tooltip ${isWide ? "wide" : ""}`}
        style={{
          transform: `translate3d(${this.props.x + 15}px, ${this.props.y}px, 0)`,
        }}
      >
        <header>{dateLabel}</header>
        <div className="primary-container">
          {isWide && (
            <div className="column">
              {componentState.house && (
                <PrimaryLabel
                  name="Total Consumption"
                  color="rgb(81, 199, 109)"
                  unit="kW"
                  value={houseValue !== null ? houseValue.toFixed(dataPrecision) : null}
                />
              )}
              {components.submeters &&
                components.submeters.map(submeter => {
                  let submeterValue =
                    data.submeters &&
                    data.submeters[submeter.id] &&
                    data.submeters[submeter.id][timestamp]
                      ? data.submeters[submeter.id][timestamp]
                      : null;

                  return (
                    componentState.submeters[submeter.id] && (
                      <PrimaryLabel
                        name={components.submeters.find(sm => sm.id === submeter.id).name}
                        color="rgb(87, 196, 199)"
                        unit="kW"
                        value={submeterValue !== null ? submeterValue.toFixed(dataPrecision) : null}
                      />
                    )
                  );
                })}
            </div>
          )}
          <div className="column">
            {componentState.grid && (
              <PrimaryLabel
                name="Grid"
                color="rgb(70, 152, 203)"
                unit="kW"
                value={gridValue !== null ? gridValue.toFixed(dataPrecision) : null}
                direction={gridValue > 0 ? "import" : gridValue < 0 ? "export" : ""}
              />
            )}
            {componentState.solar && (
              <PrimaryLabel
                name="Solar"
                color="rgb(246, 141, 38)"
                unit="kW"
                value={solarValue !== null ? Math.abs(solarValue).toFixed(dataPrecision) : null}
              />
            )}
            {componentState.sga && (
              <PrimaryLabel
                name="SGA"
                color="rgb(31, 42, 68)"
                unit="kW"
                value={sgaValue !== null ? Math.abs(sgaValue).toFixed(dataPrecision) : null}
              />
            )}
            {components.battery && componentState.battery && (
              <PrimaryLabel
                name="Battery"
                color="rgb(224, 0, 77)"
                unit="kW"
                value={batteryValue ? Math.abs(batteryValue).toFixed(dataPrecision) : null}
                direction={batteryValue > 0 ? "charge" : batteryValue < 0 ? "discharge" : ""}
              />
            )}
          </div>
        </div>
        {componentState.secondaryYAxis && (
          <>
            <header>Secondary Y-Axis ({secondaryAxisLabel || "undefined"})</header>
            <div className="secondary-container">
              <div className="column">
                {secondaryAxes.map(axis => {
                  return (
                    <PrimaryLabel
                      key={axis.label}
                      name={axis.label}
                      color={axis.color || "rgb(70, 152, 203)"}
                      unit={axis.unit}
                      value={axis.value !== null ? axis.value.toFixed(dataPrecision) : null}
                    />
                  );
                })}
              </div>
            </div>
          </>
        )}
      </div>
    );
  }
}

export default MonitoringGraphTooltip;

import React, { Component } from "react";
import ReactMapGL from "react-map-gl";
import { PerspectiveMercatorViewport } from "viewport-mercator-project";
import MapIconSprite from "./map_icon_sprite.png";
import DeploymentMapClusteringLayer from "./DeploymentMapClusteringLayer";
import DeploymentMapEditLayer from "./DeploymentMapEditLayer";
import DeploymentMapSingleLayer from "./DeploymentMapSingleLayer";
import "./DeploymentMap.css";

const ICON_MAPPING = {
  whiteBattery100: { x: 0, y: 0, width: 66, height: 90, mask: false },
  paleBattery100: { x: 66, y: 0, width: 66, height: 90, mask: false },
  darkBattery100: { x: 132, y: 0, width: 66, height: 90, mask: false },
  whiteBattery75: { x: 0, y: 90, width: 66, height: 90, mask: false },
  paleBattery75: { x: 66, y: 90, width: 66, height: 90, mask: false },
  darkBattery75: { x: 132, y: 90, width: 66, height: 90, mask: false },
  whiteBattery50: { x: 0, y: 180, width: 66, height: 90, mask: false },
  paleBattery50: { x: 66, y: 180, width: 66, height: 90, mask: false },
  darkBattery50: { x: 132, y: 180, width: 66, height: 90, mask: false },
  whiteBattery25: { x: 0, y: 270, width: 66, height: 90, mask: false },
  paleBattery25: { x: 66, y: 270, width: 66, height: 90, mask: false },
  darkBattery25: { x: 132, y: 270, width: 66, height: 90, mask: false },
  whiteBattery0: { x: 0, y: 360, width: 66, height: 90, mask: false },
  paleBattery0: { x: 66, y: 360, width: 66, height: 90, mask: false },
  darkBattery0: { x: 132, y: 360, width: 66, height: 90, mask: false },
  whiteBatteryOffline: { x: 0, y: 450, width: 66, height: 90, mask: false },
  paleBatteryOffline: { x: 66, y: 450, width: 66, height: 90, mask: false },
  darkBatteryOffline: { x: 132, y: 450, width: 66, height: 90, mask: false },

  whiteSolar: { x: 0, y: 540, width: 66, height: 90, mask: false },
  paleSolar: { x: 66, y: 540, width: 66, height: 90, mask: false },
  darkSolar: { x: 132, y: 540, width: 66, height: 90, mask: false },
  whiteSolarOffline: { x: 0, y: 630, width: 66, height: 90, mask: false },
  paleSolarOffline: { x: 66, y: 630, width: 66, height: 90, mask: false },
  darkSolarOffline: { x: 132, y: 630, width: 66, height: 90, mask: false },

  cluster1: { x: 198, y: 0, width: 102, height: 102, mask: false },
  cluster2: { x: 300, y: 0, width: 102, height: 102, mask: false },
  cluster3: { x: 402, y: 0, width: 102, height: 102, mask: false },
  cluster4: { x: 504, y: 0, width: 102, height: 102, mask: false },
  cluster5: { x: 606, y: 0, width: 102, height: 102, mask: false },

  cluster6: { x: 198, y: 102, width: 102, height: 102, mask: false },
  cluster7: { x: 300, y: 102, width: 102, height: 102, mask: false },
  cluster8: { x: 402, y: 102, width: 102, height: 102, mask: false },
  cluster9: { x: 504, y: 102, width: 102, height: 102, mask: false },
  cluster10: { x: 606, y: 102, width: 102, height: 102, mask: false },

  cluster20: { x: 198, y: 204, width: 102, height: 102, mask: false },
  cluster30: { x: 300, y: 204, width: 102, height: 102, mask: false },
  cluster40: { x: 402, y: 204, width: 102, height: 102, mask: false },
  cluster50: { x: 504, y: 204, width: 102, height: 102, mask: false },
  cluster60: { x: 606, y: 204, width: 102, height: 102, mask: false },

  cluster70: { x: 198, y: 306, width: 102, height: 102, mask: false },
  cluster80: { x: 300, y: 306, width: 102, height: 102, mask: false },
  cluster90: { x: 402, y: 306, width: 102, height: 102, mask: false },
  cluster100: { x: 504, y: 306, width: 102, height: 102, mask: false },
  cluster150: { x: 606, y: 306, width: 102, height: 102, mask: false },

  cluster200: { x: 198, y: 408, width: 102, height: 102, mask: false },
  cluster300: { x: 300, y: 408, width: 102, height: 102, mask: false },
  cluster400: { x: 402, y: 408, width: 102, height: 102, mask: false },
  cluster500: { x: 504, y: 408, width: 102, height: 102, mask: false },
  clusterPlus: { x: 606, y: 408, width: 102, height: 102, mask: false },
};

class DeploymentMap extends Component {
  constructor(props) {
    super(props);

    const bounds = this.getBounds(props);

    this.state = {
      map: {
        latitude: bounds.latitude,
        longitude: bounds.longitude,
        zoom: this.props.zoom ? this.props.zoom : bounds.zoom,
        pitch: 0,
        bearing: 0,
      },
      deployments: this.formatDeploymentsList(this.props.deployments),
      unselectedDeployments: this.formatDeploymentsList(this.props.unselectedDeployments),
      selectedDeployments: this.formatDeploymentsList(this.props.selectedDeployments),
    };
  }

  componentWillReceiveProps = nextProps => {
    if (
      this.props.deployments !== nextProps.deployments ||
      this.props.selectedDeployments !== nextProps.selectedDeployments ||
      this.props.unselectedDeployments !== nextProps.unselectedDeployments
    ) {
      if (this.props.recomputeBoundsOnUpdate) {
        const bounds = this.getBounds(nextProps);

        this.setState({
          map: {
            ...this.state.map,
            latitude: bounds.latitude,
            longitude: bounds.longitude,
            zoom: this.props.zoom ? this.props.zoom : bounds.zoom,
          },
          deployments: this.formatDeploymentsList(nextProps.deployments),
          unselectedDeployments: this.formatDeploymentsList(nextProps.unselectedDeployments),
          selectedDeployments: this.formatDeploymentsList(nextProps.selectedDeployments),
        });
      } else {
        this.setState({
          deployments: this.formatDeploymentsList(nextProps.deployments),
          unselectedDeployments: this.formatDeploymentsList(nextProps.unselectedDeployments),
          selectedDeployments: this.formatDeploymentsList(nextProps.selectedDeployments),
        });
      }
    }
  };

  getBounds = props => {
    const viewport = new PerspectiveMercatorViewport({
      width: this.props.width,
      height: this.props.height,
    });

    let minLat = null,
      maxLat = null,
      minLng = null,
      maxLng = null;

    if (props.latitude && props.longitude) {
      minLat = this.props.latitude;
      maxLat = this.props.latitude;
      minLng = this.props.longitude;
      maxLng = this.props.longitude;
    } else {
      if (props.selectedDeployments) {
        for (let i in props.selectedDeployments) {
          const deployment = props.selectedDeployments[i];
          minLat =
            deployment.address.lat < minLat || minLat === null ? deployment.address.lat : minLat;
          maxLat =
            deployment.address.lat > maxLat || maxLat === null ? deployment.address.lat : maxLat;
          minLng =
            deployment.address.lng < minLng || minLng === null ? deployment.address.lng : minLng;
          maxLng =
            deployment.address.lng > maxLng || maxLng === null ? deployment.address.lng : maxLng;
        }
      }

      if (props.unselectedDeployments) {
        for (let i in props.unselectedDeployments) {
          const deployment = props.unselectedDeployments[i];
          minLat =
            deployment.address.lat < minLat || minLat === null ? deployment.address.lat : minLat;
          maxLat =
            deployment.address.lat > maxLat || maxLat === null ? deployment.address.lat : maxLat;
          minLng =
            deployment.address.lng < minLng || minLng === null ? deployment.address.lng : minLng;
          maxLng =
            deployment.address.lng > maxLng || maxLng === null ? deployment.address.lng : maxLng;
        }
      }

      if (props.deployments) {
        for (let i in props.deployments) {
          const deployment = props.deployments[i];
          minLat =
            deployment.address.lat < minLat || minLat === null ? deployment.address.lat : minLat;
          maxLat =
            deployment.address.lat > maxLat || maxLat === null ? deployment.address.lat : maxLat;
          minLng =
            deployment.address.lng < minLng || minLng === null ? deployment.address.lng : minLng;
          maxLng =
            deployment.address.lng > maxLng || maxLng === null ? deployment.address.lng : maxLng;
        }
      }
    }

    // If we didn't manage to find any lat/lngs set some defaults (Reposit office).
    if (!minLat || !maxLat) {
      minLat = -35.333502;
      maxLat = -35.333502;
    }

    if (!minLng || !maxLng) {
      minLng = 149.170508;
      maxLng = 149.170508;
    }

    return viewport.fitBounds(
      [
        [minLng, minLat],
        [maxLng + 0.001, maxLat + 0.001],
      ],
      { padding: 50, offset: [0, 0], zoom: props.zoom }
    );
  };

  // Converts a standard API returned deployment list to one suitable for rendering on the
  // IconLayer.
  formatDeploymentsList = deployments => {
    if (deployments) {
      // The map clustering and z-indexes of icons are deterministic based on the order of
      // deployments so make sure we order them in some deterministic way to avoid jiggle on
      // refresh or non-deterministic clustering.
      const sortedDeployments = deployments.sort((a, b) => {
        if (a.id < b.id) return -1;
        if (a.id > b.id) return 1;
        return 0;
      });

      let processedDeployments = [];
      for (let i in sortedDeployments) {
        const deployment = sortedDeployments[i];
        processedDeployments.push({
          coordinates: [deployment.address.lng, deployment.address.lat],
          deployment: deployment,
          iconNameSuffix: this.getIconNameSuffix(deployment),
        });
      }
      return processedDeployments;
    } else {
      return null;
    }
  };

  deploymentIsOffline = depl => {
    return (
      (depl.status && depl.status.ping && depl.status.ping.connectionOffline) || !depl.isOnline
    );
  };

  getIconNameSuffix = deployment => {
    if (deployment) {
      // The deployment data is different between the node-page and the dashboard, so we need to handle both here
      // New style: deployment.hasBattery
      // Old style: deployment.system.battery.length
      if (
        deployment.hasBattery ||
        (deployment.system && deployment.system.battery && deployment.system.battery.length > 0)
      ) {
        return this.deploymentIsOffline(deployment) ? "BatteryOffline" : "Battery100";
      } else {
        return this.deploymentIsOffline(deployment) ? "SolarOffline" : "Solar";
      }
    } else {
      return "Battery100";
    }
  };

  // For some reason when the map first loads none of the icons render. If the render method is
  // called again all the icons suddenly appear. This puts s 200ms delay after the component has map
  // component has mounted (this is called as a ref callback) and changes state which then causes a
  // render fixing the issue.
  renderHack = () => {
    setTimeout(() => {
      if (!this.state.renderHack) {
        this.setState({
          renderHack: true,
        });
      }
    }, 200);
  };

  render() {
    const viewport = {
      width: this.props.width,
      height: this.props.height,
      latitude: this.state.map.latitude,
      longitude: this.state.map.longitude,
      pitch: this.state.map.pitch,
      bearing: this.state.map.bearing,
      zoom: this.state.map.zoom,
      onViewportChange: viewport => {
        const { width, height, latitude, longitude, zoom, pitch, bearing } = viewport;
        this.setState({
          map: {
            width: width,
            height: height,
            latitude: latitude,
            longitude: longitude,
            zoom: zoom,
            pitch: pitch,
            bearing: bearing,
          },
        });
      },
      mapStyle: process.env.REACT_APP_MAPBOX_STYLE,
    };

    return (
      <>
        <ReactMapGL
          key="map"
          ref={this.renderHack}
          {...viewport}
          mapboxApiAccessToken={process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}
        >
          {this.props.deployments ? (
            <DeploymentMapClusteringLayer
              viewport={viewport}
              deployments={this.state.deployments}
              iconAtlas={MapIconSprite}
              iconMapping={ICON_MAPPING}
              showCluster={this.props.showCluster !== undefined ? this.props.showCluster : true}
              iconSize={this.props.iconSize}
              onMarkerClick={this.props.onMarkerClick}
            />
          ) : null}

          {this.state.selectedDeployments || this.state.unselectedDeployments ? (
            <DeploymentMapEditLayer
              viewport={viewport}
              unselectedData={this.state.unselectedDeployments}
              selectedData={this.state.selectedDeployments}
              iconAtlas={MapIconSprite}
              iconMapping={ICON_MAPPING}
              onSelect={this.props.onSelect}
              onUnselect={this.props.onUnselect}
              showCluster={false}
              iconSize={this.props.iconSize}
            />
          ) : null}

          {this.props.latitude && this.props.longitude ? (
            <DeploymentMapSingleLayer
              viewport={viewport}
              latitude={this.props.latitude}
              longitude={this.props.longitude}
              iconAtlas={MapIconSprite}
              iconMapping={ICON_MAPPING}
              iconNameSuffix={this.getIconNameSuffix(this.props.deployment)}
              iconSize={this.props.iconSize}
            />
          ) : null}
        </ReactMapGL>
        <div
          key="map-loading-overlay"
          className={"map-loading-overlay" + (this.props.loading ? " visible" : "")}
        />
        <div key="map-help-message-container" className="map-help-message-container">
          {this.props.mapMessage ? (
            <div className="map-help-message">{this.props.mapMessage}</div>
          ) : null}
        </div>
      </>
    );
  }
}

export default DeploymentMap;

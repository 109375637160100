import React, { Component } from "react";
import { getTimeAsString } from "../../components/widgets/Moment";
import { FormattedNumber } from "react-intl";
import "./GridCreditsSearchTable.css";

class GridCreditsSearchTable extends Component {
  render() {
    const DEFAULT_TYPE = "Support Request";

    const lineItems = this.props.lineItems;

    if (!lineItems || lineItems.length === 0) {
      return (
        <div className="fleet-table-no-results">No GridCredits® transactions have been found.</div>
      );
    }

    return (
      <table>
        <thead>
          <tr>
            <th>NMI</th>
            <th>Date</th>
            <th>Type</th>
            <th>Amount</th>
            <th>Rate</th>
            <th>Cost</th>
          </tr>
        </thead>
        <tbody>
          {lineItems.map(lineItem => {
            return (
              <tr>
                <td>{lineItem.nmi}</td>
                <td>
                  {lineItem.type === "Spot Price"
                    ? getTimeAsString(new Date(lineItem.day), this.props.timezone, "D MMMM")
                    : getTimeAsString(
                        new Date(lineItem.ts * 1000),
                        this.props.timezone,
                        "D MMMM h:mm a"
                      )}
                </td>
                <td>{lineItem.type ? lineItem.type : DEFAULT_TYPE}</td>
                <td>
                  {lineItem.amount !== null ? (
                    <FormattedNumber
                      style="decimal" // eslint-disable-line
                      maximumFractionDigits={2}
                      value={lineItem.amount}
                    />
                  ) : null}
                </td>
                <td>
                  <FormattedNumber
                    style="decimal" // eslint-disable-line
                    maximumFractionDigits={4}
                    value={lineItem.rate}
                  />
                </td>
                <td>
                  {lineItem.cost !== null ? (
                    <FormattedNumber
                      style="currency" // eslint-disable-line
                      currency="AUD"
                      maximumFractionDigits={2}
                      value={lineItem.cost}
                    />
                  ) : null}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  }
}

export default GridCreditsSearchTable;

import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Page from "../../components/layout/Page";
import { Loading, Button } from "../../components/widgets";
import RertDispatchListTable from "../../components/rert/RertDispatchListTable";
import NewRertDispatchModal from "../rert/NewRertDispatchModal";
import * as rertActions from "../../redux/actions/rert";
import AuthRequired from "../AuthRequired";
import Permissions from "../../permissions";

const mapStateToProps = state => ({
  rertDispatches: state.rert.rertDispatches,
  powerstations: state.rert.powerstations,
  user: state.user,
});

const mapDispatchToProps = dispatch => {
  return {
    rertActions: bindActionCreators(rertActions, dispatch),
  };
};

class RertDispatchList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showNewRertDispatch: false,
    };
  }

  componentDidMount = () => {
    this.fetchRertDispatchData();
    this.refreshInterval = setInterval(this.fetchRertDispatchData, 300000); // 5 minutes;
  };

  componentWillUnmount = () => {
    if (this.refreshInterval) {
      clearInterval(this.refreshInterval);
    }
  };

  fetchRertDispatchData = () => {
    this.props.rertActions.fetchRertDispatches();
    this.props.rertActions.fetchPowerstations();
  };

  showNewRertDispatch = () => {
    this.setState({
      showNewRertDispatch: true,
    });
  };

  hideNewRertDispatch = () => {
    this.setState({
      showNewRertDispatch: false,
    });
  };

  render() {
    if (!this.props.rertDispatches || !this.props.powerstations) {
      return <Loading />;
    }

    let rertDispatches = this.props.rertDispatches;
    rertDispatches.sort((a, b) => {
      const aRequest = a.request;
      const bRequest = b.request;
      if (aRequest.startTime > bRequest.startTime) return -1;
      if (aRequest.startTime < bRequest.startTime) return 1;
      if (aRequest.startTime === bRequest.startTime) {
        if (aRequest.duration > bRequest.duration) return -1;
        if (aRequest.duration < bRequest.duration) return 1;
        return 0;
      }
      return 0;
    });

    return (
      <Page key="rert-dispatch-list" permission={Permissions.fleet.rert.dispatches.any}>
        <Page.Header title="RERT Dispatches">
          <AuthRequired permission={Permissions.fleet.rert.dispatches.edit}>
            <Page.Header.Actions>
              <Button
                id="new-rert-dispatch-from-rert-dispatch-list"
                type="primary"
                icon="plus"
                onClick={this.showNewRertDispatch}
              >
                New RERT Dispatch
              </Button>
            </Page.Header.Actions>
          </AuthRequired>
        </Page.Header>
        <Page.Content>
          <RertDispatchListTable
            dispatches={rertDispatches}
            powerstations={this.props.powerstations}
            timezone={this.props.user.account.timezone}
            history={this.props.history}
          />
          {this.state.showNewRertDispatch ? (
            <NewRertDispatchModal handleOnCancel={this.hideNewRertDispatch} />
          ) : null}
        </Page.Content>
      </Page>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RertDispatchList);

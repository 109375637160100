import React, { Component } from "react";
import { Modal } from "../../components/layout/Modal";
import { Button } from "../../components/widgets";
import { Input, Textarea } from "../widgets";
import "./AddAPIKeyModal.css";

class AddAPIKeyModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      ipWhitelist: "",
      password: "",
      nameError: null,
    };
  }

  handleNameChange = event => {
    this.setState({
      name: event.target.value,
    });
  };

  handleWhitelistChange = event => {
    this.setState({
      ipWhitelist: event.target.value,
    });
  };

  handlePasswordChange = event => {
    this.setState({
      password: event.target.value,
    });
  };

  handleAdd = () => {
    const name = this.state.name;

    if (!name) {
      this.setState({
        nameError: "You must provide a name.",
      });
    } else {
      const ipWhitelist = this.state.ipWhitelist.trim();
      this.props.onSubmit(
        this.state.password,
        this.state.name,
        ipWhitelist === "" ? null : ipWhitelist.split(/\s+/g)
      );
    }
  };

  handleCancel = () => {
    this.props.onCancel();
  };

  render() {
    const { confirming, ipParseError, passwordRejected } = this.props;

    return (
      <div className="add-api-key-container">
        <Modal>
          <Modal.Header title={"Add API Key"} />
          <Modal.Content>
            <div className="add-api-key-modal-wrapper">
              <div className="add-api-key-field">
                <h6>Key Name</h6>
                <Input
                  type="text"
                  autoComplete="off"
                  value={this.state.name}
                  onChange={this.handleNameChange}
                  error={this.state.nameError}
                />
              </div>
              <div className="add-api-key-field">
                <h6>IP Address Whitelist</h6>
                <div className="add-api-key-ip-whitelist">
                  <Textarea
                    autoComplete="off"
                    value={this.state.ipWhitelist}
                    onChange={this.handleWhitelistChange}
                    error={ipParseError}
                  />
                  <div className="ip-whitelist-instructions">
                    <p>
                      IP entries can be:
                      <ul>
                        <li>
                          <span>—</span> a single IP address
                          <br />
                          (eg. '192.0.2.65' or '2001:db8::1');
                        </li>
                        <li>
                          <span>—</span> a CIDR IP range
                          <br />
                          (eg. '192.0.2.0/24' or '2001:db8::1/96');
                        </li>
                        <li>
                          <span>—</span> an IPv4 subnet
                          <br />
                          (eg. '192.0.2.0/255.255.255.0').
                        </li>
                      </ul>
                    </p>
                    <p>Each entry should be on a new line.</p>
                    <p>Leave this field empty to allow all IP addresses.</p>
                  </div>
                </div>
              </div>
              <div className="add-api-key-field">
                <h6>Current Password</h6>
                <Input
                  type="password"
                  autoComplete="off"
                  value={this.state.password}
                  onChange={this.handlePasswordChange}
                  error={passwordRejected ? "Incorrect password." : null}
                />
              </div>
            </div>
          </Modal.Content>
          <Modal.Footer>
            <Button
              id="api-key-modal-cancel"
              type="secondary"
              onClick={this.handleCancel}
              disabled={confirming}
              icon="times"
            >
              Cancel
            </Button>
            <Button
              id="api-key-modal-submit"
              type="primary"
              onClick={this.handleAdd}
              disabled={confirming}
              loading={confirming}
              icon="check"
            >
              Add
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

export default AddAPIKeyModal;

import React, { Component } from "react";
import "./FleetDeploymentLocation.css";
import { LabelledContent, DeploymentMap } from "../widgets";
import { getAddressLine1, getAddressLine2 } from "../../utils/formatting";

class FleetDeploymentLocation extends Component {
  registerMapContainer = mapContainer => {
    if (mapContainer) {
      this.setState({
        map: {
          width: mapContainer.clientWidth,
          height: mapContainer.clientHeight,
        },
      });
    }
  };

  render() {
    return (
      <div className="fleet-deployment-location">
        <div className="location-info">
          {this.props.network && this.props.network.nmi && (
            <LabelledContent vertical label="NMI">
              {this.props.network.nmi}
            </LabelledContent>
          )}
          {this.props.network && this.props.network.network && (
            <LabelledContent vertical label="Network">
              {this.props.network.network}
            </LabelledContent>
          )}
          <LabelledContent vertical label="Install Address">
            {getAddressLine1(this.props.deployment.address)}
            <br />
            {getAddressLine2(this.props.deployment.address)}
          </LabelledContent>
        </div>
        <div className="map-container" ref={this.registerMapContainer}>
          {this.state && this.state.map && (
            <DeploymentMap
              width={this.state.map.width}
              height={this.state.map.height}
              latitude={this.props.deployment.address.lat}
              longitude={this.props.deployment.address.lng}
              deployment={this.props.deployment}
              zoom={13}
            />
          )}
        </div>
      </div>
    );
  }
}

export default FleetDeploymentLocation;

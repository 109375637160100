import React, { Component } from "react";
import { Link } from "react-router-dom";
import PowerstationType from "./PowerstationType";
import "./PowerstationsListTable.css";
import { Tooltip } from "../widgets";

class PowerstationsListTable extends Component {
  handleOnRowClick = powerstation => {
    this.props.history.push(`/virtual-power-plants/manage/${powerstation.id}?tab=MAP`);
  };

  getCapacity = rawCapacity => {
    let capacityUnit = "",
      capacity = 0;
    if (rawCapacity) {
      capacity = rawCapacity > 1000 ? +(rawCapacity / 1000).toFixed(2) : rawCapacity;
      capacityUnit = rawCapacity > 1000 ? "MWh" : "kWh";
    }

    return `${capacity} ${capacityUnit}`;
  };

  getPower = rawPower => {
    let powerUnit = "",
      power = 0;
    if (rawPower) {
      power = rawPower > 1000 ? +(rawPower / 1000).toFixed(2) : rawPower;
      powerUnit = rawPower > 1000 ? "MW" : "kW";
    }

    return `${power} ${powerUnit}`;
  };

  getPowerstationType = powerstation => {
    if (powerstation.type === "DYNAMIC") {
      return powerstation.filters.state ? "STATE" : "POSTCODE";
    } else if (powerstation.type === "FCAS") {
      return "FCAS";
    } else {
      return "INDIVIDUAL_NODES";
    }
  };

  render() {
    const powerstations = this.props.powerstations;

    return (
      <table className="powerstation-table">
        <thead>
          <tr>
            <th className="powerstation-table-column-long">Name</th>
            <th>
              Enrolment <Tooltip id={"powerstations.list.enrolment"} />
            </th>
            <th>Nodes</th>
            <th>
              Capacity <Tooltip id={"powerstations.list.capacity"} />
            </th>
            <th>
              Inverter Power <Tooltip id={"powerstations.list.inverterPower"} />
            </th>
            <th>
              Battery Power <Tooltip id={"powerstations.list.batteryPower"} />
            </th>
          </tr>
        </thead>
        <tbody>
          {powerstations.map(powerstation => {
            return (
              <tr
                key={powerstation.id}
                role="button"
                onClick={() => this.handleOnRowClick(powerstation)}
              >
                <td>
                  <Link to={`/virtual-power-plants/manage/${powerstation.id}?tab=MAP`}>
                    {powerstation.name}
                  </Link>
                  <PowerstationType
                    type={this.getPowerstationType(powerstation)}
                    filters={powerstation.filters}
                    readOnly
                    simple
                  />
                </td>
                <td>{powerstation.type === "STATIC" ? "Manual" : "Automatic"}</td>
                <td>{powerstation.nodes.total}</td>
                <td>{this.getCapacity(powerstation.namePlate.batteryCapacity)}</td>
                <td>{this.getPower(powerstation.namePlate.inverterPower)}</td>
                <td>{this.getPower(powerstation.namePlate.batteryPower)}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  }
}

export default PowerstationsListTable;

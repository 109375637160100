import React from "react";
import { Link } from "react-router-dom";
import { ProgressBar, ProgressText, Loading, Tooltip } from "../widgets";
import { asPowerSigned, componentToType } from "../../utils/formatting";
import CurtailmentResponseGraph from "./CurtailmentResponseGraph";
import "./CurtailmentOverview.css";

const CurtailmentOverview = ({ curtailment, prediction, response, timezone }) => {
  return (
    <>
      <div className="curtailment-overview">
        <div className="response-container">
          {(curtailment.state === "INPROGRESS" && response && prediction) ||
          (curtailment.state === "UPCOMING" && prediction) ||
          (curtailment.state === "COMPLETED" && response) ? (
            <div className="graph-wrapper">
              <CurtailmentResponseGraph
                prediction={prediction}
                response={response}
                startTime={curtailment.request.startTime}
                duration={curtailment.request.duration}
                solarPower={curtailment.currentResponse.solarPower}
                meterPower={curtailment.currentResponse.meterPower}
                yAxisLocation="left"
                timezone={timezone}
                request={curtailment.request}
                component={curtailment.component}
              />
            </div>
          ) : (
            <div className="graph-loading-container">
              <Loading />
            </div>
          )}
          <div className="node-count-container">
            <ProgressText
              progress={curtailment.currentResponse.deploymentsAccepted}
              final={curtailment.request.deploymentsRequested}
            />
            <ProgressBar
              progress={curtailment.currentResponse.deploymentsAccepted}
              final={curtailment.request.deploymentsRequested}
            />
            <div style={{ padding: "5px" }}>
              <strong>Nodes responded</strong>
              <Tooltip medium id="curtailments.detail.nodesResponded" />
            </div>
          </div>
        </div>

        <div className="details-container">
          <div className="request power">
            <div className="value">
              {asPowerSigned(curtailment.request.realPowerP)}{" "}
              {componentToType(curtailment.component)}
              <Tooltip medium id="curtailments.component" />
            </div>
            <div className="label">accepted</div>
          </div>
          <div className="curtailment-overview-footer">
            {curtailment.state === "INPROGRESS" || curtailment.state === "UPCOMING" ? (
              <span className="refresh-interval">Data will update automatically</span>
            ) : null}
            <span className="">
              All times are in <Link to="/user/settings">{timezone}</Link>
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default CurtailmentOverview;

import React, { Component } from "react";
import PropTypes from "prop-types";
import { Modal } from "../layout/Modal";
import { Button } from "../widgets";

import "./ChooseExportModal.css";
import AuthRequired from "../../containers/AuthRequired";
import Permissions from "../../permissions";

class ChooseExportModal extends Component {
  static get propTypes() {
    return {
      openDeploymentOperationalModal: PropTypes.func,
      openPowerstationOperationalModal: PropTypes.func,
      openCustomerModal: PropTypes.func,
      closeModal: PropTypes.func,
    };
  }

  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Modal>
        <Modal.Header title="New Data Export" />
        <Modal.Content>
          <div className="choose-export-modal">
            <AuthRequired permission={Permissions.fleet.analysis.export.edit}>
              <div
                className="choose-export-tile"
                onClick={this.props.openPowerstationOperationalModal}
              >
                <h3>Operational Data - VPP</h3>
                <div className="description">Operational data for a Virtual Power Plant.</div>
              </div>
            </AuthRequired>
            <div className="choose-export-tile" onClick={this.props.openDeploymentOperationalModal}>
              <h3>Operational Data - Individual Nodes</h3>
              <div className="description">Operational data for one or more nodes.</div>
            </div>
            <div
              className="choose-export-tile"
              id="new-customer-export"
              onClick={this.props.openCustomerModal}
            >
              <h3>Customer Data</h3>
              <div className="description">
                Static data for a set of customers, their nodes and their capabilities
              </div>
            </div>
          </div>
        </Modal.Content>
        <Modal.Footer>
          <Button
            id="new-export-modal-cancel"
            type="secondary"
            onClick={this.props.closeModal}
            icon="times"
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default ChooseExportModal;

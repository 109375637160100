import React, { Component } from "react";
import { Input, Button } from "../../components/widgets";
import { Modal } from "../../components/layout/Modal";
import "./MFAConfirmPhoneNumberModal.css";

export default class MFAConfirmPhoneNumberModal extends Component {
  render() {
    return (
      <Modal>
        <Modal.Header title="Confirm Phone Number" />
        <div className="mfa-modal-confirm-phone-container">
          <p>
            An SMS (text message) has been sent to {this.props.phoneNumber}. Please enter the
            8-digit code from this message below to confirm this number for MFA.
          </p>
          <p>
            Please note that the code being sent is not the same as used for logging in and can not
            be obtained using an authenticator app.
          </p>
          <div className="code-wrapper">
            <Input
              autoFocus
              placeholder="Enter the 8-digit verification code"
              value={this.props.verificationCode}
              onChange={this.props.onVerificationCodeChange}
            />
          </div>
        </div>
        <Modal.Footer>
          <Button
            id="verify-sms-phone-number-cancel"
            type="secondary"
            icon="check"
            onClick={this.props.hideConfirmPhoneNumberModal}
          >
            Cancel
          </Button>
          <Button
            id="verify-sms-phone-number"
            type="primary"
            icon="check"
            onClick={this.props.verifyCode}
            loading={this.props.loading}
          >
            Verify
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

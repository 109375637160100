import React, { Component } from "react";
import _ from "lodash";
import SelectDropdown from "./SelectDropdown";

import "./PagingControl.css";

const RESULTS_PER_PAGE = [
  { value: 10, label: "10" },
  { value: 50, label: "50" },
  { value: 75, label: "75" },
  { value: 100, label: "100" },
  { value: 150, label: "150" },
  { value: 200, label: "200" },
];

class PagingControl extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: props.offset === 0 ? 1 : Math.floor(props.offset / props.limit) + 1,
      maxPages: this.calculateMaxPages(this.props.size),
      resultsPerPage: [10, 50, 75, 100, 150, 200],
    };
    this.previousPage = this.previousPage.bind(this);
    this.nextPage = this.nextPage.bind(this);
    this.setPage = this.setPage.bind(this);
    this.getDisplayText = this.getDisplayText.bind(this);
    this.onChangeResultsDropdown = this.onChangeResultsDropdown.bind(this);
  }

  calculateMaxPages(size) {
    if (size === "small") {
      return 3;
    }
    if (size === "medium") {
      return 5;
    }
    if (size === "large") {
      return 7;
    }
    return 5;
  }

  calculatePageNumbers(totalItems) {
    const { limit } = this.props;
    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(totalItems / limit); i++) {
      pageNumbers.push(i);
    }
    return pageNumbers;
  }

  shouldIncludePage(page, lastPage) {
    const pageThreshold = Math.floor(this.state.maxPages / 2);
    if (this.state.currentPage - pageThreshold - 1 <= 1) {
      return page <= this.state.maxPages;
    }
    if (this.state.currentPage + pageThreshold + 1 >= lastPage) {
      return page > lastPage - this.state.maxPages;
    }
    return (
      page >= this.state.currentPage - pageThreshold &&
      page <= this.state.currentPage + pageThreshold
    );
  }

  getPage(page) {
    return (
      <div
        id={page}
        key={page}
        onClick={e => this.setPage(parseInt(e.target.id))}
        className={`${this.state.currentPage === page ? "current-page" : "clickable"} `}
      >
        {page}
      </div>
    );
  }

  getPageNumbers(pageNumbers) {
    const lastPage = _.last(pageNumbers);
    return pageNumbers
      .filter(page => this.shouldIncludePage(page, lastPage))
      .map(page => this.getPage(page));
  }

  setPage(page) {
    if (page === this.state.currentPage) {
      return;
    }
    const { onPageChange, limit } = this.props;
    this.setState({ currentPage: page });
    onPageChange(limit, page * limit - limit);
  }

  previousPage() {
    this.setPage(this.state.currentPage - 1);
  }

  nextPage() {
    this.setPage(this.state.currentPage + 1);
  }

  getDisplayText() {
    const { totalItems, itemName, limit } = this.props;
    const endRecord = this.state.currentPage * limit;
    const startRecord = endRecord - limit + 1;
    const itemLabel = totalItems === 1 ? itemName : itemName + "s";

    return `${startRecord}-${
      endRecord > totalItems ? totalItems : endRecord
    } of ${totalItems} ${itemLabel}`;
  }

  onChangeResultsDropdown(val) {
    const { onPageChange, onChangeResultsPerPage } = this.props;
    this.setState({ currentPage: 1 });
    onChangeResultsPerPage(val);
    onPageChange(val, 0);
  }

  render() {
    const { totalItems, itemName, limit, orientation } = this.props;
    const pageNumbers = this.calculatePageNumbers(totalItems);
    const renderLowerBound =
      this.state.currentPage > this.state.maxPages - Math.floor(this.state.maxPages / 2) + 1;
    const renderUpperBound =
      this.state.currentPage < _.last(pageNumbers) - Math.floor(this.state.maxPages / 2) - 1;

    if (!totalItems || totalItems === 0) {
      return <div />;
    }

    return (
      <div className="pagination-control-container">
        <span className="pagination-container">
          {totalItems && totalItems > limit ? (
            <>
              <div className="pagination-controls">
                {this.state.currentPage > 1 ? (
                  <div onClick={this.previousPage} className="previous-page clickable">{`<`}</div>
                ) : null}
                {renderLowerBound ? this.getPage(pageNumbers[0]) : null}
                {renderLowerBound ? <div>...</div> : null}
                {this.getPageNumbers(pageNumbers)}
                {renderUpperBound ? <div>...</div> : null}
                {renderUpperBound ? this.getPage(_.last(pageNumbers)) : null}
                {_.last(pageNumbers) > this.state.currentPage ? (
                  <div onClick={this.nextPage} className="next-page clickable">{`>`}</div>
                ) : null}
              </div>
              <div className="pagination-display-text">{this.getDisplayText()}</div>
            </>
          ) : (
            `${totalItems || 0} ${totalItems === 1 ? itemName : itemName + "s"}`
          )}
          {this.props.onChangeResultsPerPage && (
            <div className="results-per-page">
              Results per page:{" "}
              <SelectDropdown
                value={limit}
                options={RESULTS_PER_PAGE}
                onChange={this.onChangeResultsDropdown}
                orientation={orientation}
              />
            </div>
          )}
        </span>
      </div>
    );
  }
}

export default PagingControl;

import React, { Component } from "react";
import Select from "react-select";
import "./Select.css";

class Input extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: props.error,
    };
  }

  autoFocus = input => {
    if (input) {
      input.focus();
    }
  };

  componentWillReceiveProps = nextProps => {
    // Remove the existing error if we receive a new value but the error has not yet changed.
    // This probably indicates that the user is correcting the error and in most cases they will
    // do this successfully - we can wait until new validation to re-display the error if they
    // still haven't fixed it.
    if (this.props.value !== nextProps.value && this.props.error === nextProps.error) {
      this.setState({
        error: null,
      });
    }

    if (this.props.error !== nextProps.error) {
      this.setState({
        error: nextProps.error,
      });
    }
  };

  render() {
    if (this.props.readOnly) {
      // Initially assign label to be value as its better to show something that nothing at all.
      // We then attempt to find the nice display label.
      let label = this.props.value;
      if (this.props.options) {
        const option = this.props.options.find(o => o.value === this.props.value);
        if (option) {
          label = option.label;
        }
      }
      return (
        <span className="rp-widget select ro" style={this.props.containerStyle}>
          {label}
        </span>
      );
    } else {
      return (
        <span
          className={"rp-widget select" + (this.state.error ? " error" : "")}
          style={this.props.containerStyle}
        >
          <Select {...this.props} />
          {this.props.error ? <span className="error-message">{this.state.error}</span> : null}
        </span>
      );
    }
  }
}

export default Input;

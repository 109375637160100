import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Button } from "../widgets/index";
import "./APIKeysTable.css";

class APIKeysTable extends Component {
  render() {
    const { apiKeys, onDelete, onAdd } = this.props;

    if (apiKeys.length <= 0) {
      return (
        <>
          <p>No API keys.</p>
          <Button id="add-api-key" type="link" onClick={onAdd}>
            <i className="fa fa-plus-circle" />
            Add API Key
          </Button>
        </>
      );
    }

    return (
      <>
        <table className="table-light api-keys-table">
          <thead>
            <tr>
              <th style={{ minWidth: "6rem" }}>Name</th>
              <th>Allowed IP's</th>
              <th style={{ width: "6rem" }} />
            </tr>
          </thead>
          <tbody>
            {apiKeys.map(key => (
              <tr className={key.status !== "VALID" ? "revoked-api-key" : null}>
                <td>{key.keyName}</td>
                <td>{key.ipWhitelist && key.ipWhitelist.join(", ")}</td>
                <td className="actions">
                  {key.status === "VALID" || key.status === "REVOKING" ? (
                    <Button
                      id="remove-api-key"
                      type="cancel"
                      onClick={() => onDelete(key)}
                      loading={key.status === "REVOKING"}
                      disabled={key.status !== "VALID"}
                    >
                      {key.status === "VALID" ? (
                        <span>
                          <i className="fa fa-times-circle" /> Remove
                        </span>
                      ) : (
                        key.status
                      )}
                    </Button>
                  ) : (
                    "Removed"
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className="add-api-key-button with-table">
          <Button id="tadd-api-key" type="link" onClick={onAdd}>
            <i className="fa fa-plus-circle" />
            Add API Key
          </Button>
        </div>
      </>
    );
  }
}

export default withRouter(APIKeysTable);

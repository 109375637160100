import { Notify } from "../../utils/notificationManager";
import queryString from "query-string";

export const BEGIN_CSV_EXPORT = "BEGIN_CSV_EXPORT";
export const UPDATE_BEGIN_CSV_EXPORT = "UPDATE_BEGIN_CSV_EXPORT";
export const BEGIN_CSV_EXPORT_FAILURE = "BEGIN_CSV_EXPORT_FAILURE";
export const FETCH_CSV_EXPORTS = "FETCH_CSV_EXPORTS";
export const UPDATE_CSV_EXPORTS = "UPDATE_CSV_EXPORTS";
export const FETCH_CSV_EXPORT_DETAILS = "FETCH_CSV_EXPORT_DETAILS";
export const UPDATE_CSV_EXPORT_DETAILS = "UPDATE_CSV_EXPORT_DETAILS";
export const UPDATE_CSV_EXPORT_DETAILS_FAILURE = "UPDATE_CSV_EXPORT_DETAILS_FAILURE";
export const UPDATE_CSV_EXPORT_DETAILS_EMPTY = "UPDATE_CSV_EXPORT_DETAILS_EMPTY";
export const UPDATE_CSV_EXPORTS_EMPTY = "UPDATE_CSV_EXPORTS_EMPTY";
export const FETCH_CSV_EXPORT_DEPLOYMENT_SUGGESTIONS = "FETCH_CSV_EXPORT_DEPLOYMENT_SUGGESTIONS";
export const UPDATE_CSV_EXPORT_DEPLOYMENT_SUGGESTIONS = "UPDATE_CSV_EXPORT_DEPLOYMENT_SUGGESTIONS";
export const CLEAR_CSV_EXPORT_DEPLOYMENT_SUGGESTIONS = "CLEAR_CSV_EXPORT_DEPLOYMENT_SUGGESTION";
export const SELECT_CSV_EXPORT_DEPLOYMENT = "SELECT_CSV_EXPORT_DEPLOYMENT";
export const DESELECT_CSV_EXPORT_DEPLOYMENT = "DESELECT_CSV_EXPORT_DEPLOYMENT";
export const DESELECT_ALL_CSV_EXPORT_DEPLOYMENT = "DESELECT_ALL_CSV_EXPORT_DEPLOYMENT";
export const FETCH_CSV_EXPORT_SINGLE_DEPLOYMENT = "FETCH_CSV_EXPORT_SINGLE_DEPLOYMENT";

export function beginCSVDataExport(data) {
  return {
    type: BEGIN_CSV_EXPORT,
    meta: {
      endpoint: "/analysis/export-jobs",
      method: "POST",
      body: data,
      success: updateBeginCSVDataExport,
      error: beginCSVDataExportFailure,
    },
  };
}

export function updateBeginCSVDataExport(response) {
  if (response.error) {
    Notify.error("Data Export Failure", "Unable to submit data export job.");
  } else {
    Notify.success(
      "Data Export Submitted",
      "Successfully submitted a CSV data export for processing."
    );
    return {
      type: UPDATE_BEGIN_CSV_EXPORT,
      meta: {
        endpoint: `/analysis/export-jobs`,
        success: updateCSVDataExports,
      },
    };
  }
}

export function beginCSVDataExportFailure(response) {
  Notify.error(
    "Data Export Failed",
    "There was an unexpected error submitting a CSV data export for processing."
  );
  return {
    type: BEGIN_CSV_EXPORT_FAILURE,
  };
}

export function fetchCSVDataExports(limit, offset, showLoading) {
  return {
    type: FETCH_CSV_EXPORTS,
    meta: {
      endpoint: `/analysis/export-jobs?limit=${limit}&offset=${offset}`,
      success: updateCSVDataExports,
      errorMessage: "Unable to fetch export history.",
    },
    payload: {
      showLoading: showLoading,
    },
  };
}

export function updateCSVDataExports(exports) {
  if (!exports || exports.data === null || exports.data.length === 0) {
    return {
      type: UPDATE_CSV_EXPORTS_EMPTY,
    };
  }
  return {
    type: UPDATE_CSV_EXPORTS,
    payload: {
      exports,
    },
  };
}

export function fetchCSVDataExportDetails(taskId) {
  return {
    type: FETCH_CSV_EXPORT_DETAILS,
    meta: {
      endpoint: `/analysis/export-jobs/${taskId}`,
      success: updateCSVDataExportDetails,
      error: updateCSVDataExportDetailsFailure,
    },
    payload: {
      taskId,
    },
  };
}

export function updateCSVDataExportDetails(exportDetails, payload) {
  if (!exportDetails) {
    return {
      type: UPDATE_CSV_EXPORT_DETAILS_EMPTY,
      payload: {
        taskId: payload.taskId,
      },
    };
  } else {
    return {
      type: UPDATE_CSV_EXPORT_DETAILS,
      payload: {
        exportDetails,
        taskId: payload.taskId,
      },
    };
  }
}

export function updateCSVDataExportDetailsFailure(response) {
  return {
    type: UPDATE_CSV_EXPORT_DETAILS_FAILURE,
  };
}

export function fetchDeploymentSuggestions(filter, limit) {
  let query_parts = { limit: limit };
  if (filter) {
    query_parts.f = encodeURIComponent(filter);
  }
  return {
    type: FETCH_CSV_EXPORT_DEPLOYMENT_SUGGESTIONS,
    meta: {
      endpoint: "/v2/nodes?" + queryString.stringify(query_parts),
      success: updateCSVDataExportDeploymentSuggestions,
      errorMessage: "Failed to load node suggestions.",
    },
  };
}

function updateCSVDataExportDeploymentSuggestions(fleet) {
  return {
    type: UPDATE_CSV_EXPORT_DEPLOYMENT_SUGGESTIONS,
    payload: {
      deployments: fleet.deployments,
    },
  };
}

/**
 * Fetches a single deployment and adds it to the list of selected deployments
 * This is used to set the initial deployment when a user goes from the node detail page
 * to the data export page
 * @param deploymentId
 */
export function fetchSingleExportDeployment(deploymentId) {
  // Do a query against robin for the node UID and hope it is in the first 10 results
  let query_parts = { f: encodeURIComponent(deploymentId) };
  return {
    type: FETCH_CSV_EXPORT_SINGLE_DEPLOYMENT,
    meta: {
      endpoint: "/v2/nodes?" + queryString.stringify(query_parts),
      success: updateSingleExportDeployment,
      errorMessage: `Failed to load details for node ${deploymentId}.`,
    },
    payload: { deploymentId: deploymentId },
  };
}

function updateSingleExportDeployment(response, payload) {
  const deploymentId = payload.deploymentId;
  // The deployment should be found in the first page of results
  const deployment = response.deployments.find(x => x.id === deploymentId);
  if (deployment) {
    return {
      type: SELECT_CSV_EXPORT_DEPLOYMENT,
      payload: {
        deployment: deployment,
      },
    };
  }
}

export function clearDeploymentSuggestions() {
  return {
    type: CLEAR_CSV_EXPORT_DEPLOYMENT_SUGGESTIONS,
  };
}

export function selectExportDeployment(deployment) {
  return {
    type: SELECT_CSV_EXPORT_DEPLOYMENT,
    payload: {
      deployment: deployment,
    },
  };
}

export function deselectExportDeployment(deployment) {
  return {
    type: DESELECT_CSV_EXPORT_DEPLOYMENT,
    payload: {
      deployment: deployment,
    },
  };
}

export function deselectAllExportDeployments() {
  return {
    type: DESELECT_ALL_CSV_EXPORT_DEPLOYMENT,
  };
}

import React, { Component } from "react";
import { withRouter } from "react-router";

class DisavowPasswordReset extends Component {
  componentDidMount = () => {
    const currentLocation = this.props.history.getCurrentLocation();
    const token = currentLocation.query.token;
    this.props.disavow(token);
  };

  render() {
    return (
      <span>
        <div className="message-container">
          <div className="message">
            Your password reset link has been deactivated. An email has been sent to support.
          </div>
        </div>
      </span>
    );
  }
}

export default withRouter(DisavowPasswordReset);

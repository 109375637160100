import React, { Component } from "react";
import Tooltip from "./Tooltip";
import "./Textarea.css";

class Input extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: props.error,
    };
  }

  autoFocus = input => {
    if (input) {
      input.focus();
    }
  };

  componentWillReceiveProps = nextProps => {
    // Remove the existing error if we receive a new value but the error has not yet changed.
    // This probably indicates that the user is correcting the error and in most cases they will
    // do this successfully - we can wait until new validation to re-display the error if they
    // still haven't fixed it.
    if (this.props.value !== nextProps.value && this.props.error === nextProps.error) {
      this.setState({
        error: null,
      });
    }

    if (this.props.error !== nextProps.error) {
      this.setState({
        error: nextProps.error,
      });
    }
  };

  render() {
    if (this.props.readOnly) {
      return (
        <span className="rp-widget textarea ro" style={this.props.containerStyle}>
          {this.props.value}
        </span>
      );
    } else {
      return (
        <span
          className={"rp-widget textarea" + (this.state.error ? " error" : "")}
          style={this.props.containerStyle}
        >
          <textarea {...this.props} ref={this.props.autoFocus ? this.autoFocus : null} />
          {this.props.tooltipId ? <Tooltip id={this.props.tooltipId} /> : null}
          {this.props.error ? <span className="error-message">{this.state.error}</span> : null}
        </span>
      );
    }
  }
}

export default Input;

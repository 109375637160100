import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import * as feedInManagementActions from "../../redux/actions/feedinmanagement";
import Permissions, { hasPermission } from "../../permissions";
import { getAddressLine1, getAddressLine2 } from "../../utils/formatting";
import "./DeploymentList.css";

const mapStateToProps = state => ({
  deployments: state.feedInManagement.curtailmentDeployments,
  user: state.user,
});

const mapDispatchToProps = dispatch => {
  return {
    feedInManagementActions: bindActionCreators(feedInManagementActions, dispatch),
  };
};

class DeploymentList extends Component {
  componentDidMount = () => {
    if (!this.props.deployments) {
      if (this.props.type === "CURTAILMENT") {
        this.fetchCurtailmentData();
        this.refreshInterval = setInterval(this.fetchCurtailmentData, 15000);
      }
    }
  };

  fetchCurtailmentData = () => {
    this.props.feedInManagementActions.fetchCurtailmentDeployments(this.props.eventId);
  };

  componentWillUnmount = () => {
    if (this.refreshInterval) {
      clearInterval(this.refreshInterval);
    }
    this.props.feedInManagementActions.clearCurtailmentDeployments();
  };

  getDeploymentDetails = deployment => {
    return (
      <span>
        {hasPermission(this.props.user.permissions, Permissions.fleet.myReposit.users) ? (
          <Link to={`/fleet-monitoring/nodes/${deployment.id}`}>{deployment.nmi}</Link>
        ) : (
          `${deployment.nmi}`
        )}
      </span>
    );
  };

  getResponded = deployment => {
    if (deployment.responded) {
      return <span className="fa fa-check" style={{ color: "#4698cb" }} />;
    } else {
      return "-";
    }
  };

  handleOnRowClick = deployment => {
    this.props.history.push(`/fleet-monitoring/nodes/${deployment.id}`);
  };

  render() {
    if (!this.props.deployments) {
      return <span style={{ marginLeft: "0.5em" }}>Loading...</span>;
    } else {
      let deployments = this.props.deployments;
      return (
        <table>
          <thead>
            <tr>
              <th>NMI / Serial Number</th>
              <th>Install Address</th>
              <th>Responded</th>
            </tr>
          </thead>
          <tbody>
            {deployments.map(d => {
              return (
                <tr onClick={() => this.handleOnRowClick(d)}>
                  <td>
                    <div>{this.getDeploymentDetails(d)}</div>
                    <div>{d.serialNumber}</div>
                  </td>
                  <td>
                    {getAddressLine1(d.address)}
                    <br />
                    {getAddressLine2(d.address)}
                  </td>
                  <td>
                    <div>{this.getResponded(d)}</div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      );
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DeploymentList);

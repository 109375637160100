import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Page from "../../components/layout/Page";
import AuthRequired from "../AuthRequired";
import { confirm } from "../../utils/dialogs";
import RertDispatchOverview from "../../components/rert/RertDispatchOverview";
import RescheduleRertDispatchModal from "./RescheduleRertDispatchModal";
import { Loading, Button } from "../../components/widgets";
import * as rertActions from "../../redux/actions/rert";
import Permissions, { hasPermission } from "../../permissions";
import { User } from "../smartWidgets";

const mapStateToProps = state => ({
  dispatch: state.rert.activeRertDispatch,
  prediction: state.rert.activeRertDispatchPrediction,
  response: state.rert.activeRertDispatchResponse,
  remainingCharge: state.rert.activeRertDispatchRemainingCharge,
  powerstations: state.rert.powerstations,
  user: state.user,
});

const mapDispatchToProps = dispatch => {
  return {
    rertActions: bindActionCreators(rertActions, dispatch),
  };
};

class RertDispatch extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dispatchId: this.props.match.params.id,
      dispatchCreatedAt: 0,
      dispatchStartTime: 0,
      dispatchEndTime: 0,
      showRescheduleRertDispatch: false,
    };
  }

  refreshTimeouts = {};

  componentDidMount = () => {
    this.fetchDispatch();
    if (!this.props.powerstations) {
      this.props.rertActions.fetchPowerstations();
    }

    if (this.props.dispatch) {
      this.fetchPrediction();
      this.fetchResponse();
      this.fetchRemainingCharge();
    }
  };

  componentWillReceiveProps = nextProps => {
    if (
      nextProps.match &&
      nextProps.match.params &&
      nextProps.match.params.id &&
      nextProps.match.params.id !== this.state.dispatchId
    ) {
      this.setState(
        {
          dispatchId: nextProps.match.params.id,
        },
        () => {
          this.fetchPrediction();
          this.fetchResponse();
          this.fetchRemainingCharge();
          this.fetchDispatch();
        }
      );
    }

    if (!this.props.dispatch && nextProps.dispatch) {
      this.setState(
        {
          dispatchCreatedAt: nextProps.dispatch.createdAt,
          dispatchStartTime: nextProps.dispatch.request.startTime,
          dispatchEndTime:
            nextProps.dispatch.request.startTime + nextProps.dispatch.request.duration,
        },
        () => {
          this.fetchPrediction();
          this.fetchResponse();
          this.fetchRemainingCharge();
          this.fetchDispatch();
        }
      );
    } else if (
      this.props.dispatch &&
      nextProps.dispatch &&
      this.props.dispatch !== nextProps.dispatch
    ) {
      this.setState({
        dispatchCreatedAt: nextProps.dispatch.createdAt,
        dispatchStartTime: nextProps.dispatch.request.startTime,
        dispatchEndTime: nextProps.dispatch.request.startTime + nextProps.dispatch.request.duration,
      });
    } else if (nextProps.dispatch) {
      if (this.props.prediction !== nextProps.prediction) {
        this.setupRefreshTimeout("prediction", this.fetchPrediction);
      }

      if (this.props.response !== nextProps.response) {
        this.setupRefreshTimeout("response", this.fetchResponse);
      }

      if (this.props.remainingCharge !== nextProps.remainingCharge) {
        this.setupRefreshTimeout("remainingCharge", this.fetchRemainingCharge);
      }
    }

    if (this.props.dispatch !== nextProps.dispatch) {
      this.setupRefreshTimeout("dispatch", this.fetchDispatch);
    }
  };

  fetchDispatch = () => {
    this.props.rertActions.fetchActiveRertDispatch(this.state.dispatchId);
  };

  fetchPrediction = () => {
    this.props.rertActions.fetchRertDispatchPrediction(this.state.dispatchId);
  };

  fetchRemainingCharge = () => {
    this.props.rertActions.fetchRertDispatchRemainingCharge(this.state.dispatchId);
  };

  fetchResponse = () => {
    this.props.rertActions.fetchRertDispatchResponse(this.state.dispatchId);
  };

  componentWillUnmount = () => {
    for (let id in this.refreshTimeouts) {
      clearTimeout(this.refreshTimeouts[id]);
    }
    this.props.rertActions.clearActiveRertDispatch();
  };

  setupRefreshTimeout = (timeoutId, callback) => {
    if (this.refreshTimeouts[timeoutId]) {
      clearTimeout(this.refreshTimeouts[timeoutId]);
    }

    const nowTs = Math.round(new Date().valueOf() / 1000);
    const secondsSinceCreated = nowTs - this.state.dispatchCreatedAt;

    let dispatchState = "NOT INPROGRESS";
    if (this.state.dispatchStartTime <= nowTs && nowTs <= this.state.dispatchEndTime) {
      dispatchState = "INPROGRESS";
    }

    // If we are still on this page once a dispatch is complete, default to every 5 minutes
    let refreshInterval = 300000;
    if (secondsSinceCreated < 60) {
      // If we are within 1 minute of creating the dispatch then poll every 2 seconds to see the
      // nodes responded number clock up.
      // Most systems respond well within 60 seconds.
      refreshInterval = 2000; // 2 seconds
    } else if (dispatchState === "INPROGRESS") {
      // 8 seconds - gives a bit of time for requests and should ensure that we get an update for
      // each value at least every 10 seconds during a dispatch. The timer starts once a network
      // request has finished.
      refreshInterval = 8000;
    }

    this.refreshTimeouts[timeoutId] = setTimeout(callback, refreshInterval);
  };

  getPowerstationName = powerstationId => {
    const powerstation = this.props.powerstations.find(p => p.id === powerstationId);
    if (powerstation) {
      return powerstation.name;
    } else {
      return "Unknown";
    }
  };

  showRescheduleRertDispatch = () => {
    this.setState({
      showRescheduleRertDispatch: true,
    });
  };

  hideRescheduleRertDispatch = () => {
    this.setState({
      showRescheduleRertDispatch: false,
    });
  };

  cancelRertDispatch = () => {
    confirm("cancel-rert-dispatch", "Are you sure want to cancel this dispatch?")
      .then(() => {
        this.props.rertActions.cancelRertDispatch(this.state.dispatchId);
      })
      .catch(() => null);
  };

  render() {
    if (!this.props.dispatch || !this.props.powerstations) {
      return <Loading />;
    }

    const dispatch = this.props.dispatch;

    return (
      <Page key="dispatch" permission={Permissions.fleet.rert.dispatches.any}>
        <Page.Header
          title={
            dispatch.triggered ? dispatch.reason : this.getPowerstationName(dispatch.powerstation)
          }
        >
          {this.props.dispatch.state === "UPCOMING" ? (
            <AuthRequired permission={Permissions.fleet.rert.dispatches.edit}>
              <Page.Header.Actions>
                <Button
                  id="reschedule-rert-dispatch"
                  type="primary"
                  icon="clock-o"
                  onClick={this.showRescheduleRertDispatch}
                >
                  Reschedule
                </Button>
                <Button
                  id="cancel-rert-dispatch"
                  type="danger"
                  icon="times"
                  onClick={this.cancelRertDispatch}
                >
                  Cancel
                </Button>
              </Page.Header.Actions>
            </AuthRequired>
          ) : null}
          <Page.SubHeading>
            Created by:
            <User userId={dispatch.createdBy} />
          </Page.SubHeading>
        </Page.Header>
        <Page.Content>
          <RertDispatchOverview
            dispatch={this.props.dispatch}
            prediction={this.props.prediction}
            response={this.props.response}
            remainingCharge={this.props.remainingCharge}
            timezone={this.props.user.account.timezone}
            showMarketData={
              this.props.user.account.suborg_type === "RETAILER" ||
              hasPermission(this.props.user.permissions, Permissions.fleet.dispatches.marketTrader)
            }
          />
          {this.state.showRescheduleRertDispatch ? (
            <RescheduleRertDispatchModal
              dispatchId={this.state.dispatchId}
              dispatch={this.props.dispatch}
              returnToDispatchView={this.hideRescheduleRertDispatch}
            />
          ) : null}
        </Page.Content>
      </Page>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RertDispatch);

import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Page from "../../components/layout/Page";
import { Button, Loading } from "../../components/widgets";
import * as notificationActions from "../../redux/actions/notifications";
import { getDateString } from "../../utils/userNotifications";
import Markdown from "react-markdown";
import "./UserNotification.css";

const mapStateToProps = state => ({
  activeNotification: state.notifications.activeNotification,
  account: state.user.account,
});

const mapDispatchToProps = dispatch => {
  return {
    notificationActions: bindActionCreators(notificationActions, dispatch),
  };
};

class UserNotification extends Component {
  constructor(props) {
    super(props);

    this.state = {
      notificationId: this.props.match.params.id,
    };
  }

  componentDidMount = () => {
    this.props.notificationActions.fetchNotification(this.state.notificationId);
    // Slight delay to give the notification a chance to load without being altered - doesn't matter much but leads to
    // better UX.
    if (this.props.activeNotification && !this.props.activeNotification.read) {
      setTimeout(
        () => this.props.notificationActions.fetchMarkReadNotification(this.state.notificationId),
        1000
      );
    }
  };

  componentWillReceiveProps = nextProps => {
    if (
      !this.props.activeNotification &&
      nextProps.activeNotification &&
      !nextProps.activeNotification.read
    ) {
      this.props.notificationActions.fetchMarkReadNotification(this.state.notificationId);
    }
  };

  componentWillUnmount = () => {
    this.props.notificationActions.clearActiveNotification();
  };

  markUnread = () => {
    this.props.notificationActions.fetchMarkUnreadNotification(this.state.notificationId);
  };

  renderLink = props => {
    // Is its an absolute link then just render a normal anchor tag.
    if (props.href.startsWith("http")) {
      return (
        <a {...props} target="_blank" rel="noopener noreferrer" className="external-link">
          {props.children}
        </a>
      );
    } else {
      // Otherwise we're linking within fleet so render a react-router Link.
      return <Link to={props.href}>{props.children}</Link>;
    }
  };

  render() {
    const notification = this.props.activeNotification;

    if (!notification) {
      return <Loading />;
    }

    return (
      <Page>
        <Page.Header
          title={`Notification - ${getDateString(
            notification.createdAt,
            this.props.account.timezone
          )}`}
        >
          <Page.Header.Actions>
            {notification.read ? (
              <Button
                id="user-notification-mark-unread"
                type="secondary"
                onClick={this.markUnread}
                icon="times"
              >
                Mark as Unread
              </Button>
            ) : null}
          </Page.Header.Actions>
        </Page.Header>
        <Page.Content>
          <div className="notification-summary">{notification.summary}</div>
          <div className="notification-markdown-container">
            <Markdown
              source={notification.body}
              renderers={{
                link: this.renderLink,
              }}
            />
          </div>
        </Page.Content>
      </Page>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserNotification);

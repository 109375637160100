import React, { Component } from "react";
import { Modal } from "../../components/layout/Modal";
import { Button } from "../../components/widgets";
import Input from "../widgets/Input";
import "./ConfirmPasswordModal.css";

class ConfirmPasswordModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      password: "",
    };
  }

  handlePasswordChange = event => {
    this.setState({
      password: event.target.value,
    });
  };

  handleSubmit = event => {
    this.props.onSubmit(this.state.password);
  };

  handleCancel = () => {
    this.props.onCancel();
  };

  render() {
    return (
      <form className="confirm-password-form" onSubmit={this.handleSubmit}>
        <Modal>
          <Modal.Header title={this.props.title || "Password Confirmation"} />
          <Modal.Content>
            <div className="password-confirmation-details">
              {this.props.children || "Confirm your password to complete this action."}
            </div>
            <Input
              type="password"
              placeholder="Current Password"
              autoComplete="off"
              value={this.state.password}
              onChange={this.handlePasswordChange}
              error={this.props.passwordRejected ? "Incorrect password." : null}
            />
          </Modal.Content>
          <Modal.Footer>
            <Button
              id="confirm-password-modal-cancel"
              type="secondary"
              onClick={this.handleCancel}
              disabled={this.props.confirming}
              icon="times"
            >
              Cancel
            </Button>
            <Button
              id="confirm-password-modal-confirm"
              type="primary"
              onClick={this.handleSubmit}
              disabled={this.props.confirming}
              loading={this.props.confirming}
              icon="check"
            >
              {this.props.confirmText || "Confirm"}
            </Button>
          </Modal.Footer>
        </Modal>
      </form>
    );
  }
}

export default ConfirmPasswordModal;

import * as actions from "../actions/usermanagement";

const userManagementInitialState = {
  confirm: null,
};

const userManagementReducer = (state = userManagementInitialState, action) => {
  switch (action.type) {
    case "RESET_STORE":
      return {
        ...userManagementInitialState,
      };
    case actions.UPDATE_ALL_USERS:
      return {
        ...state,
        users: action.payload.users,
      };
    case actions.UPDATE_USER:
      return {
        ...state,
        activeUser: action.payload.user,
      };
    case actions.FETCH_CREATE_USER:
      let nextState = { ...state };
      delete nextState.newUserErrors;
      return {
        ...nextState,
        savingUser: true,
      };
    case actions.UPDATE_CREATE_USER:
    case actions.UPDATE_SAVE_USER:
      if (action.payload.user) {
        let nextState = { ...state };
        delete nextState.newUserErrors;
        return {
          ...nextState,
          activeUser: action.payload.user,
          savingUser: false,
        };
      } else {
        return {
          ...state,
          newUserErrors: action.payload.error,
          savingUser: false,
        };
      }
    case actions.FETCH_SAVE_USER:
      return {
        ...state,
        savingUser: true,
      };
    case actions.CLEAR_ACTIVE_USER:
      return {
        ...state,
        activeUser: null,
      };
    case actions.UPDATE_AVAILABLE_PERMISSIONS_FOR_ORG:
      return {
        ...state,
        availablePermissions: action.payload.permissions,
      };
    default:
      return state;
  }
};

export default userManagementReducer;

import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Page from "../../../components/layout/Page";
import { Loading, Button, InfoBox } from "../../../components/widgets";
import UserForm from "../../../components/myreposit/users/UserForm";
import * as userManagementActions from "../../../redux/actions/usermanagement";
import Permissions from "../../../permissions";
import { confirm } from "../../../utils/dialogs";

const mapStateToProps = state => ({
  loggedInUserAccount: state.user.account,
  user: state.userManagement.activeUser,
  availablePermissions: state.userManagement.availablePermissions,
  newUserErrors: state.userManagement.newUserErrors,
  savingUser: state.userManagement.savingUser,
});

const mapDispatchToProps = dispatch => {
  return {
    userManagementActions: bindActionCreators(userManagementActions, dispatch),
  };
};

class UserEdit extends Component {
  constructor(props) {
    super(props);

    if (props.user) {
      this.state = {
        user: {
          id: props.user.id,
          givenName: props.user.givenName,
          surname: props.user.surname,
          email: props.user.email,
          permissions: props.user.permissions,
        },
        errors: {},
      };
    } else {
      this.state = {
        user: {
          givenName: "",
          surname: "",
          email: "",
          permissions: [],
        },
        errors: {},
      };
    }
  }

  componentDidMount = () => {
    const userId = this.props.match.params.id;
    if (userId) {
      this.props.userManagementActions.fetchUser(userId);
    }
    this.props.userManagementActions.fetchAvailablePermissionsForOrg();
  };

  componentWillUnmount = () => {
    this.props.userManagementActions.clearActiveUser();
  };

  componentWillReceiveProps = nextProps => {
    if (this.props.user !== nextProps.user && nextProps.user) {
      this.setState({
        user: {
          id: nextProps.user.id,
          givenName: nextProps.user.givenName,
          surname: nextProps.user.surname,
          email: nextProps.user.email,
          permissions: nextProps.user.permissions,
        },
      });
    }
  };

  handleFieldOnChange = (fieldName, value) => {
    this.setState({
      user: {
        ...this.state.user,
        [fieldName]: value,
      },
    });
  };

  handlePermissionToggle = (permissionName, checked) => {
    if (checked) {
      let nextPermissions = this.state.user.permissions;
      nextPermissions.push(permissionName);
      this.setState({
        user: {
          ...this.state.user,
          permissions: nextPermissions,
        },
      });
    } else {
      let nextPermissions = this.state.user.permissions;
      nextPermissions.splice(nextPermissions.indexOf(permissionName), 1);

      this.setState({
        user: {
          ...this.state.user,
          permissions: nextPermissions,
        },
      });
    }
  };

  validateUser = () => {
    let errors = {};
    if (!this.state.user.givenName) {
      errors.givenName = "You must enter a given name";
    }

    if (!this.state.user.surname) {
      errors.surname = "You must enter a surname";
    }

    if (!this.state.user.email) {
      errors.email = "You must enter an email address.";
    }

    return errors;
  };

  hasErrors = errors => {
    if (errors.givenName || errors.surname || errors.email) {
      return true;
    }

    return false;
  };

  getNonFieldErrors = () => {
    const errors = this.props.newUserErrors;
    if (errors instanceof Array) {
      return errors[0];
    }
  };

  saveUser = () => {
    const errors = this.validateUser();
    const hasErrors = this.hasErrors(errors);

    if (hasErrors) {
      this.setState({
        errors: errors,
      });
    } else {
      this.setState({
        errors: {},
      });
      if (this.state.user.id) {
        this.props.userManagementActions.saveUser(
          this.props.user.id,
          this.state.user,
          this.props.loggedInUserAccount.id
        );
      } else {
        this.createUser();
      }
    }
  };

  createUser = () => {
    confirm(
      "new-user",
      "An email will be sent to " +
        this.state.user.email +
        " with log in details and instructions to set their password for the first time. Are you sure you want to create this user? "
    )
      .then(() => {
        this.props.userManagementActions.createUser(this.state.user);
      })
      .catch(() => null);
  };

  render() {
    const userId = this.props.match.params.id;

    if ((userId && !this.props.user) || !this.props.availablePermissions) {
      return <Loading />;
    }

    const user = this.props.user;

    return (
      <Page permission={Permissions.fleet.myReposit.users}>
        <Page.Header title={userId ? `${user.givenName} ${user.surname}` : "Add User"}>
          <Page.Header.Actions>
            <Button
              id="my-reposit-cancel-edit-user"
              type="secondary"
              icon="times"
              to={userId ? `/my-reposit/users/${userId}` : "/my-reposit/users"}
            >
              Cancel
            </Button>
            <Button
              id="my-reposit-save-user"
              type="primary"
              icon="check"
              onClick={this.saveUser}
              loading={this.props.savingUser}
            >
              Save
            </Button>
          </Page.Header.Actions>
        </Page.Header>
        <Page.Content>
          {!this.state.user.id ? (
            <InfoBox type="info" style={{ marginTop: "1em" }}>
              When you create this user they will be sent an email with log in details and
              instructions to set their password for the first time.
            </InfoBox>
          ) : null}
          {this.getNonFieldErrors() ? (
            <span style={{ color: "#E0004D", fontSize: "0.9em", paddingTop: "1em" }}>
              {this.getNonFieldErrors()}
            </span>
          ) : null}
          <UserForm
            userId={this.state.user.id}
            givenName={this.state.user.givenName}
            surname={this.state.user.surname}
            email={this.state.user.email}
            availablePermissions={this.props.availablePermissions.sort((a, b) => {
              const aName = a.displayName.toLowerCase();
              const bName = b.displayName.toLowerCase();
              if (aName < bName) return -1;
              if (aName > bName) return 1;
              return 0;
            })}
            userPermissions={this.state.user.permissions}
            handleFieldOnChange={this.handleFieldOnChange}
            handlePermissionToggle={this.handlePermissionToggle}
            errors={this.state.errors}
            loggedInUserId={this.props.loggedInUserAccount.id}
          />
        </Page.Content>
      </Page>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserEdit);

import { combineReducers } from "redux";
import { routerReducer } from "react-router-redux";
import powerstationReducer from "./powerstations";
import userReducer from "./user";
import fleetReducer from "./fleet";
import customersReducer from "./customers";
import billingReducer from "./billing";
import ui from "./ui";
import dashboardReducer from "./dashboard";
import tradingReducer from "./trading";
import invoicesReducer from "./invoices";
import userManagementReducer from "./usermanagement";
import apiKeysReducer from "./apikeys";
import analysisReducer from "./analysis";
import gridOutageReducer from "./gridoutage";
import notificationsReducer from "./notifications";
import rertReducer from "./rert";
import ordersReducer from "./orders";
import feedInManagementReducer from "./feedinmanagement";
import monitoringReducer from "./monitoring";

export default combineReducers({
  router: routerReducer,
  powerstations: powerstationReducer,
  user: userReducer,
  fleet: fleetReducer,
  customers: customersReducer,
  billing: billingReducer,
  ui: ui,
  dashboard: dashboardReducer,
  trading: tradingReducer,
  invoices: invoicesReducer,
  userManagement: userManagementReducer,
  apiKeys: apiKeysReducer,
  analysis: analysisReducer,
  gridOutages: gridOutageReducer,
  notifications: notificationsReducer,
  rert: rertReducer,
  orders: ordersReducer,
  feedInManagement: feedInManagementReducer,
  monitoring: monitoringReducer,
});

import * as actions from "../actions/dashboard";

const dashboardInitialState = {};

const dashboardReducer = (state = dashboardInitialState, action) => {
  switch (action.type) {
    case "RESET_STORE":
      return {
        ...dashboardInitialState,
      };
    case actions.UPDATE_DASHBOARD:
      return {
        ...state,
        dashboard: action.payload.dashboard,
        saving: false,
      };
    case actions.SAVE_DASHBOARD:
      return {
        ...state,
        dashboard: action.payload.dashboard,
        saving: true,
      };
    case actions.UPDATE_SAVE_DASHBOARD:
      return {
        ...state,
        saving: false,
      };
    case actions.UPDATE_DASHBOARD_DEPLOYMENTS:
      return {
        ...state,
        deployments: action.payload.deployments,
      };
    case actions.UPDATE_DASHBOARD_DEPLOYMENT_COUNTS:
      return {
        ...state,
        deploymentCounts: action.payload.deploymentCounts,
      };
    case actions.UPDATE_DASHBOARD_POWERSTATIONS:
      return {
        ...state,
        powerstations: action.payload.powerstations,
      };
    case actions.UPDATE_LATEST_POWERSTATION_VOLTAGE:
      const currentData = state.powerstationData
        ? state.powerstationData[action.payload.powerstationUid]
        : {};
      return {
        ...state,
        powerstationData: {
          ...state.powerstationData,
          [action.payload.powerstationUid]: {
            ...(currentData ? currentData : {}),
            latestMeterVoltage: action.payload.voltage,
          },
        },
      };
    case actions.UPDATE_POWERSTATION_SOLAR_POWER:
      const currentData2 = state.powerstationData
        ? state.powerstationData[action.payload.powerstationUid]
        : {};
      return {
        ...state,
        powerstationData: {
          ...state.powerstationData,
          [action.payload.powerstationUid]: {
            ...(currentData2 ? currentData2 : {}),
            solarPower: action.payload.solarPower,
          },
        },
      };
    case actions.UPDATE_POWERSTATION_METER_POWER:
      const currentData3 = state.powerstationData
        ? state.powerstationData[action.payload.powerstationUid]
        : {};
      return {
        ...state,
        powerstationData: {
          ...state.powerstationData,
          [action.payload.powerstationUid]: {
            ...(currentData3 ? currentData3 : {}),
            meterPower: action.payload.meterPower,
          },
        },
      };
    case actions.UPDATE_POWERSTATION_REMAINING_CHARGE:
      const currentData4 = state.powerstationData
        ? state.powerstationData[action.payload.powerstationUid]
        : {};
      return {
        ...state,
        powerstationData: {
          ...state.powerstationData,
          [action.payload.powerstationUid]: {
            ...(currentData4 ? currentData4 : {}),
            remainingCharge: action.payload.remainingCharge,
          },
        },
      };
    case actions.UPDATE_TICKER_EVENTS:
      return {
        ...state,
        tickerEvents: action.payload.tickerEvents,
      };
    default:
      return state;
  }
};

export default dashboardReducer;

import _ from "lodash";
import * as actions from "../actions/powerstations";

export const powerstationsInitialState = {
  powerstations: null,
  activePowerstation: null,
  deployments: null,
  incompleteEventsForPowerstation: null,
  events: null,
};

const powerstationReducer = (state = powerstationsInitialState, action) => {
  switch (action.type) {
    case "RESET_STORE":
      return {
        ...powerstationsInitialState,
      };
    case actions.FETCH_POWERSTATIONS:
      return {
        ...state,
        powerstationsLoading: true,
      };
    case actions.UPDATE_POWERSTATIONS:
      return {
        ...state,
        powerstations: action.payload.powerstations,
        powerstationCount: action.payload.total,
        powerstationsLoading: false,
      };
    case actions.UPDATE_ACTIVE_POWERSTATION:
      return {
        ...state,
        activePowerstation: {
          ...action.payload.powerstation,
          namePlate: {
            ...action.payload.powerstation.namePlate,
            totalDeployments: action.payload.powerstation.nodes.total,
          },
        },
      };
    case actions.CLEAR_ACTIVE_POWERSTATION:
      return {
        ...state,
        activePowerstation: null,
      };
    case actions.DELETE_POWERSTATION:
      return {
        ...state,
        savePowerstationLoading: true,
      };
    case actions.REMOVE_POWERSTATION:
      let nextPowerstations = [];
      for (let i in state.powerstations) {
        const powerstation = state.powerstations[i];
        if (powerstation.id !== action.payload.powerstationId) {
          nextPowerstations.push(powerstation);
        }
      }

      return {
        ...state,
        powerstations: nextPowerstations,
        savePowerstationLoading: false,
      };
    case actions.UPDATE_INCOMPLETE_POWERSTATION_EVENTS:
      return {
        ...state,
        incompleteEventsForPowerstation: action.payload.events,
      };
    case actions.UPDATE_UPCOMING_EVENTS:
      return {
        ...state,
        events: action.payload.events,
      };
    case actions.UPDATE_POWERSTATION_DEPLOYMENTS:
      return {
        ...state,
        deployments: action.payload.deployments,
        deploymentCount: action.payload.total,
        loadingDeployments: false,
      };
    case actions.UPDATE_POWERSTATION_NAMEPLATE:
      return {
        ...state,
        activePowerstation: {
          ...state.activePowerstation,
          namePlate: {
            ...action.payload.namePlate,
            totalDeployments: action.payload.total,
          },
        },
      };
    case actions.PATCH_POWERSTATION:
      return _.omit(
        {
          ...state,
          bulkEditActionLoading: _.isEmpty(action.payload.deploymentIds),
          deployments: _.map(state.deployments, d =>
            _.includes(action.payload.deploymentIds, d.id)
              ? { ...d, loading: true, included: action.payload.method === "add" ? true : false }
              : { ...d }
          ),
        },
        ["patchError"]
      );
    case actions.UPDATE_PATCHED_POWERSTATION:
      if (action.payload.exitScreen) {
        return { ...state, bulkEditActionLoading: false };
      }
      if (action.payload.response.status === "ERROR") {
        return {
          ...state,
          bulkEditActionLoading: false,
          deployments: _.map(state.deployments, d => _.omit(d, ["loading"])),
          patchError: action.payload.response.data,
        };
      }
      const deployments = _.map(state.deployments, d => {
        if (
          _.isEmpty(action.payload.deploymentIds) ||
          _.includes(action.payload.deploymentIds, d.id)
        ) {
          return {
            ..._.omit(d, ["loading"]),
            included: action.payload.method === "add" ? true : false,
          };
        }
        return { ...d };
      });
      const totalDeployments = action.payload.response.nodes
        ? action.payload.response.nodes.total
        : 0;
      return {
        ...state,
        activePowerstation: {
          ...state.activePowerstation,
          namePlate: {
            ...action.payload.response.namePlate,
            totalDeployments,
          },
        },
        deployments,
        bulkEditActionLoading: false,
      };
    case actions.PATCH_POWERSTATION_ERROR:
      return {
        ...state,
        deployments: _.map(state.deployments, d =>
          _.includes(action.payload.response.deploymentIds, d.id)
            ? _.omit({ ...d, included: !d.included }, ["loading"])
            : { ...d }
        ),
        bulkEditActionLoading: false,
      };
    case actions.FETCH_POWERSTATION_MAP:
      return {
        ...state,
        loadingMap: true,
      };
    case actions.UPDATE_POWERSTATION_MAP:
      return {
        ...state,
        map: action.payload.map,
        loadingMap: false,
      };
    case actions.FETCH_UNUSED_DEPLOYMENTS:
      return {
        ...state,
        loadingUnusedDeployments: true,
      };
    case actions.UPDATE_UNUSED_DEPLOYMENTS:
      return {
        ...state,
        deploymentsNotInPowerstation: action.payload.deployments,
        deploymentsNotInPowerstationCount: action.payload.total,
        loadingUnusedDeployments: false,
      };
    case actions.SET_LOADING_POWERSTATION:
      return {
        ...state,
        loadingDeployments: true,
      };
    case actions.FETCH_EDIT_POWERSTATION:
      return {
        ...state,
        savePowerstationLoading: true,
      };
    case actions.FETCH_NEW_POWERSTATION_ERROR:
      return {
        ...state,
        savePowerstationLoading: false,
      };
    case actions.APPEND_POWERSTATION:
      if (!state.powerstations) {
        return {
          ...state,
          savePowerstationLoading: false,
        };
      } else {
        let appendedPowerstations = state.powerstations.slice();
        appendedPowerstations.push(action.payload.powerstation);
        return {
          ...state,
          powerstations: appendedPowerstations,
          savePowerstationLoading: false,
        };
      }
    case actions.UPDATE_EDIT_POWERSTATION:
      return {
        ...state,
        savePowerstationLoading: false,
      };
    default:
      return state;
  }
};

export default powerstationReducer;

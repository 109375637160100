import React, { Component } from "react";
import { Select, Input, Button } from "../widgets";
import "./MFASettingsForm.css";

export default class MFASettingsForm extends Component {
  render() {
    return (
      <div className="mfa-settings-form-container">
        <p>Configure how you wish to manage MFA logins.</p>
        <div className="mfa-settings-form">
          <div className="field-box">
            <h6>How would you prefer to obtian the MFA code?</h6>
            <Select
              containerStyle={{ width: "300px", display: "inline-block" }}
              options={[
                { value: "APP", label: "Google Authenticator (or similar)" },
                { value: "SMS", label: "SMS (text message)" },
              ]}
              value={this.props.codePreference}
              onChange={this.props.onCodePreferenceChange}
              clearable={false}
            />

            {this.props.codePreference === "APP" ? (
              <p className="google-auth-help">
                Google Authenticator is available as a free download via the{" "}
                <a
                  href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Google Play
                </a>{" "}
                and{" "}
                <a
                  href="https://itunes.apple.com/au/app/google-authenticator/id388497605?mt=8"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  App Store
                </a>
                .
              </p>
            ) : null}

            {this.props.codePreference === "SMS" ? (
              <div>
                {this.props.phoneNumberConfirmed ? (
                  <div className="field-box">
                    <h6>Phone Number</h6>
                    <Input readOnly value={this.props.phoneNumberMasked} />
                    <p>
                      Your phone number has already been set and confirmed. Please contact Reposit
                      Power support if this requires changing.
                    </p>
                  </div>
                ) : (
                  <div className="field-box">
                    <h6>Phone Number</h6>
                    <Input
                      placeholder="Phone Number"
                      value={this.props.phoneNumber}
                      onChange={this.props.onPhoneNumberChange}
                      error={this.props.phoneNumberError}
                    />
                  </div>
                )}
              </div>
            ) : null}

            {this.props.saveEnabled ? (
              <div>
                {!this.props.phoneNumberConfirmed ? (
                  <div className="field-box">
                    <h6>Confirm Password</h6>
                    <Input
                      type="password"
                      placeholder="Confirm Password"
                      autoComplete="off"
                      onChange={this.props.onConfirmPasswordChange}
                      value={this.props.password}
                      error={this.props.passwordError}
                    />
                  </div>
                ) : null}
                <Button
                  id="mfa-settings-save"
                  type="primary"
                  icon="check"
                  onClick={this.props.onSave}
                  loading={this.props.loading}
                >
                  Update MFA Settings
                </Button>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}

import React, { Component } from "react";
import { MomentRelative } from "../../components/widgets";
import "./FleetDeploymentsTableStatus.css";

class FleetDeploymentsTableStatus extends Component {
  render() {
    const { lastTimestamp, connectionOffline } = this.props.deployment.status.ping;
    const state = this.props.deployment.state;

    if (state !== "COMMISSIONED") {
      return (
        <div className="fleet-deployments-table-status-wrapper">
          <div className="status-label">
            <div className="circle awaiting-contact" />
            <span className="title">Not Commissioned</span>
          </div>
        </div>
      );
    }
    return (
      <div
        className={
          "fleet-deployments-table-status-wrapper " +
          (connectionOffline ? (lastTimestamp ? "not-working" : "no-contact") : "working")
        }
      >
        <div className="status-label">
          <div
            className={
              connectionOffline
                ? lastTimestamp
                  ? "circle offline"
                  : "circle awaiting-contact"
                : "circle online"
            }
          />
          <span className="title">
            {connectionOffline ? (lastTimestamp ? "Offline" : "Awaiting Contact") : "Online"}
          </span>
        </div>
        {connectionOffline && lastTimestamp ? (
          <div className="last-contact">
            last contact <MomentRelative date={new Date(lastTimestamp * 1000)} />
          </div>
        ) : null}
      </div>
    );
  }
}

export default FleetDeploymentsTableStatus;

import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Permissions, { hasPermission } from "../../permissions";
import AuthRequired from "../AuthRequired";
import IconDashboard from "./icon_dashboard.svg";
import IconVPP from "./icon_vpp.svg";
import IconGridCredits from "./icon_gridcredits.svg";
import IconFleetStatus from "./icon_fleetstatus.svg";
import IconSupport from "./icon_support.svg";
import IconCustomers from "./icon_customers.svg";
import IconMyReposit from "./icon_myreposit.svg";
import IconAnalysis from "./icon_analysis.svg";
import IconOrdering from "./icon_ordering.svg";
import { PAGE_SIZE as DISPATCH_PAGE_SIZE } from "../trading/DispatchList";
import { PAGE_SIZE as CURTAILMENT_PAGE_SIZE } from "../curtailment/CurtailmentList";
import { PAGE_SIZE as DEPLOYMENTS_PAGE_SIZE } from "../fleet/FleetDeployments";
import { PAGE_SIZE as POWERSTATION_LIST_PAGE_SIZE } from "../powerstations/PowerstationsList";
import "./Sidebar.css";

const mapStateToProps = state => ({
  location: state.router.location,
  permissions: state.user.permissions,
});

class UnconnectedSidebarItem extends Component {
  componentDidMount() {
    this.fetchBadgeData();
  }

  isActive = () => {
    const location = this.props.location.pathname;
    return (
      (location !== "/" &&
        location.startsWith(this.props.basepath) &&
        this.props.basepath !== "/") ||
      (location === "/" && this.props.basepath === "/")
    );
  };

  getSubmenuPermissions = () => {
    const submenu = this.props.submenu ? this.props.submenu : [];

    let submenuPermissions = [];
    if (this.props.permission) {
      submenuPermissions.push(this.props.permission);
    } else {
      for (let k in submenu) {
        const submenuItem = submenu[k];
        submenuPermissions.push(submenuItem.permission);
      }
    }

    return submenuPermissions;
  };

  fetchBadgeData = () => {
    if (this.isActive()) {
      // If the tab is active anyway it most likely means were going to be fetching the data we need anyway so leave
      // it to the component to fetch the data required for the sidebar.
      return;
    }

    const submenu = this.props.submenu ? this.props.submenu : [];
    for (let k in submenu) {
      const submenuItem = submenu[k];
      // If there is a data fetcher and the user has the permission require to access the menu item then we will
      // fetch the data required.
      if (
        submenuItem.badgeDataFetcher &&
        hasPermission(this.props.permissions, submenuItem.permission)
      ) {
        submenuItem.badgeDataFetcher();
      }
    }
  };

  render() {
    const location = this.props.location.pathname;
    const submenu = this.props.submenu ? this.props.submenu : [];

    const submenuPermissions = this.getSubmenuPermissions();

    if (submenu && this.isActive()) {
      return (
        <AuthRequired any={submenuPermissions}>
          <li className="active">
            <div className="active-container">
              <div className="icon-container">
                <img src={this.props.icon} className="icon" alt={`Icon for ${this.props.title}`} />
              </div>
              <div className="submenu">
                <Link key={this.props.to} to={this.props.to}>
                  {this.props.title}
                </Link>
                {Object.keys(submenu).map(k => {
                  const submenuItem = submenu[k];
                  return (
                    <AuthRequired key={submenuItem.to} permission={submenuItem.permission}>
                      <Link
                        key={submenuItem.to}
                        to={submenuItem.to}
                        className={
                          location.startsWith(submenuItem.to.split("?")[0]) ? "active" : ""
                        }
                      >
                        {k}
                        {submenuItem.badge ? (
                          <div className="badge">{submenuItem.badge}</div>
                        ) : null}
                      </Link>
                    </AuthRequired>
                  );
                })}
              </div>
            </div>
          </li>
        </AuthRequired>
      );
    } else {
      return (
        <AuthRequired any={submenuPermissions}>
          <li className={this.isActive() ? "active" : ""}>
            <Link to={this.props.to}>
              <div className="icon-container">
                <img src={this.props.icon} className="icon" alt={`Icon for ${this.props.icon}`} />
              </div>
              {this.props.title}
              {this.props.badge ? <div className="badge">{this.props.badge}</div> : null}
            </Link>
          </li>
        </AuthRequired>
      );
    }
  }
}

const SidebarItem = connect(mapStateToProps)(UnconnectedSidebarItem);

class Sidebar extends Component {
  render() {
    return (
      <nav className="sidebar">
        <ul>
          <SidebarItem
            title="Dashboard"
            icon={IconDashboard}
            basepath="/"
            to="/"
            permission={Permissions.fleet.any}
          />
          <SidebarItem
            title="Virtual Power Plants"
            icon={IconVPP}
            basepath="/virtual-power-plants"
            to={`/virtual-power-plants/manage?limit=${POWERSTATION_LIST_PAGE_SIZE}&offset=0`}
            submenu={{
              "Build and Edit": {
                to: `/virtual-power-plants/manage?limit=${POWERSTATION_LIST_PAGE_SIZE}&offset=0`,
                permission: Permissions.fleet.powerstations.any,
              },
              Dispatches: {
                to: `/virtual-power-plants/dispatches?limit=${DISPATCH_PAGE_SIZE}&offset=0`,
                permission: Permissions.fleet.dispatches.any,
              },
              RERT: {
                to: "/virtual-power-plants/rert-dispatches",
                permission: Permissions.fleet.rert.dispatches.any,
              },
              // "Upcoming Events": {to: "/virtual-power-plants/upcoming-events", permission: Permissions.fleet.powerstations.any},
              "Grid Outages": {
                to: "/virtual-power-plants/grid-outages",
                permission: Permissions.fleet.powerstations.gridOutages.any,
              },
              "Feed-In Management": {
                to: `/virtual-power-plants/feed-in-management?tab=CURTAILMENT_LIST&limit=${CURTAILMENT_PAGE_SIZE}&offset=0`,
                permission: Permissions.fleet.curtailments.any,
              },
            }}
          />
          <SidebarItem
            title="GridCredits®"
            icon={IconGridCredits}
            basepath="/gridcredits"
            to="/gridcredits/billing"
            submenu={{
              Billing: {
                to: "/gridcredits/billing",
                permission: Permissions.fleet.billing.gridcredits.view,
              },
            }}
          />
          <SidebarItem
            title="Analysis"
            icon={IconAnalysis}
            basepath="/analysis"
            to="/analysis/export"
            submenu={{
              "Data Export (csv)": {
                to: "/analysis/export",
                permission: Permissions.fleet.analysis.export.edit,
              },
            }}
          />
          <SidebarItem
            title="Fleet Monitoring"
            icon={IconFleetStatus}
            basepath="/fleet-monitoring"
            to={`/fleet-monitoring/nodes?tab=LIST&limit=${DEPLOYMENTS_PAGE_SIZE}&offset=0`}
            submenu={{
              "Fleet Monitoring": {
                to: `/fleet-monitoring/nodes?tab=LIST&limit=${DEPLOYMENTS_PAGE_SIZE}&offset=0`,
                permission: Permissions.fleet.node.any,
              },
            }}
          />
          <SidebarItem
            title="Ordering"
            icon={IconOrdering}
            basepath="/ordering"
            to="/ordering/kit-designer"
            submenu={{
              "Kit Builder": {
                to: "/ordering/kit-designer",
                permission: Permissions.fleet.kitDesigner,
              },
            }}
          />
          <SidebarItem
            title="Support"
            icon={IconSupport}
            basepath="/support-issues"
            to="/support-issues"
            submenu={{
              "Support Issues": {
                to: "/support-issues",
                permission: Permissions.fleet.zendesk.edit,
              },
            }}
          />
          <SidebarItem
            title="Customers"
            icon={IconCustomers}
            basepath="/customers"
            to="/customers/manage"
            submenu={{
              Customers: {
                to: "/customers/manage",
                permission: Permissions.fleet.customerInformation.any,
              },
              Plans: {
                to: "/customers/plans",
                permission: Permissions.fleet.customerInformation.plans.any,
              },
              // "Additional Contracts": {to: "/customers/contracts", permission: Permissions.fleet.customerInformation.any},
            }}
          />
          <SidebarItem
            title="My Reposit"
            icon={IconMyReposit}
            basepath="/my-reposit"
            to="/my-reposit/users"
            submenu={{
              Users: { to: "/my-reposit/users", permission: Permissions.fleet.myReposit.users },
              // "Modules": {to: "/my-reposit/modules", permission: Permissions.fleet.myReposit.modules},
              Billing: {
                to: "/my-reposit/billing",
                permission: Permissions.fleet.myReposit.billing,
              },
              // "System Log": {to: "/my-reposit/system-log", permission: Permissions.fleet.myReposit.any}
            }}
          />
        </ul>
      </nav>
    );
  }
}

export default Sidebar;

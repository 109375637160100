import React, { Component } from "react";
import { Link } from "react-router-dom";
import AuthRequired from "../../containers/AuthRequired";
import "./Page.css";

class Page extends Component {
  static Breadcrumb = class extends Component {
    render() {
      return (
        <div className="breadcrumb">
          <Link to={this.props.to}>
            <span className="fa fa-chevron-left back-icon" />
            <span className="breadcrumb-label">{this.props.title}</span>
          </Link>
        </div>
      );
    }
  };

  static Header = class extends Component {
    static Title = class extends Component {
      render() {
        return <h1>{this.props.children}</h1>;
      }
    };

    static Actions = class extends Component {
      render() {
        return <div className="header-actions">{this.props.children}</div>;
      }
    };

    setDocumentTitle = title => {
      if (title) {
        document.title = title + " | " + window.appTitle;
      } else {
        document.title = window.appTitle;
      }
    };

    componentDidMount = () => {
      // Get the document title as found in the original page (i.e. as defined
      // in index.html) and cache it as a window property.
      // We cache against the window so that even if this component is destroyed
      // we can still use the original title.
      if (!window.appTitle) {
        window.appTitle = document.title;
      }
      this.setDocumentTitle(this.props.title);
    };

    componentWillReceiveProps = nextProps => {
      if (this.props.title !== nextProps.title) {
        this.setDocumentTitle(nextProps.title);
      }
    };

    componentWillUnmount = () => {
      this.setDocumentTitle();
    };

    render() {
      return (
        <div className="page-header">
          {this.props.title ? <h1>{this.props.title}</h1> : null}
          {this.props.children}
        </div>
      );
    }
  };

  static SubHeading = class extends Component {
    render() {
      return <div className="subheading">{this.props.children}</div>;
    }
  };

  static SearchFields = class extends Component {
    render() {
      if (this.props.visible) {
        return <div className="page-search-container">{this.props.children}</div>;
      } else {
        return null;
      }
    }
  };

  static Content = class extends Component {
    render() {
      return (
        <div className={"page-content" + (this.props.className ? ` ${this.props.className}` : "")}>
          {this.props.children}
        </div>
      );
    }
  };

  render() {
    if (this.props.permission) {
      return (
        <AuthRequired permission={this.props.permission}>
          <div className="page" style={this.props.style}>
            {this.props.title ? <h1>{this.props.title}</h1> : null}
            {this.props.children}
          </div>
        </AuthRequired>
      );
    } else {
      return (
        <div className="page" style={this.props.style}>
          {this.props.title ? <h1>{this.props.title}</h1> : null}
          {this.props.children}
        </div>
      );
    }
  }
}

export default Page;

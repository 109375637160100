import * as actions from "../actions/feedinmanagement";

const fimInitialState = {
  activeCurtailment: null,
  newCurtailment: null,
};

const feedInManagementReducer = (state = fimInitialState, action) => {
  switch (action.type) {
    case "RESET_STORE":
      return {
        ...fimInitialState,
      };
    case actions.FETCH_CURTAILMENTS:
      return {
        ...state,
        curtailmentsLoading: true,
      };
    case actions.UPDATE_CURTAILMENTS:
      return {
        ...state,
        curtailments: action.payload.curtailments,
        curtailmentsPageMeta: action.payload.curtailmentsPageMeta,
        curtailmentsLoading: false,
      };
    case actions.FETCH_SETPOINTS:
      return {
        ...state,
        setpointsLoading: true,
      };
    case actions.UPDATE_SETPOINTS:
      return {
        ...state,
        setpoints: action.payload.setpoints,
        setpointsPageMeta: action.payload.setpointsPageMeta,
        setpointsLoading: false,
      };
    case actions.FETCH_VPP_SETPOINTS:
      return {
        ...state,
        vppSetpointsLoading: true,
      };
    case actions.UPDATE_VPP_SETPOINTS:
      return {
        ...state,
        vppSetpoints: action.payload.setpoints,
        vppSetpointsPageMeta: action.payload.vppSetpointsPageMeta,
        vppSetpointsLoading: false,
      };
    case actions.CLEAR_VPP_SETPOINTS:
      return {
        ...state,
        setpoints: null,
        setpointsPageMeta: null,
      };
    case actions.UPDATE_FIM_POWERSTATIONS:
      return {
        ...state,
        powerstations: action.payload.powerstations,
      };
    case actions.UPDATE_ACTIVE_CURTAILMENT:
      return {
        ...state,
        activeCurtailment: action.payload.curtailment,
      };
    case actions.CLEAR_ACTIVE_CURTAILMENT:
      return {
        ...state,
        activeCurtailment: null,
        activeCurtailmentResponse: null,
        activeCurtailmentRemainingCharge: null,
      };
    case actions.UPDATE_CURTAILMENT_RESPONSE:
      return {
        ...state,
        activeCurtailmentResponse: action.payload.response,
      };
    case actions.UPDATE_CURTAILMENT_PREDICTION:
      return {
        ...state,
        activeCurtailmentPrediction: action.payload.prediction,
      };
    case actions.CLEAR_NEW_CURTAILMENT_GRAPH_DATA:
      return {
        ...state,
        newCurtailment: null,
      };
    case actions.EXECUTE_CURTAILMENT:
      return {
        ...state,
        executeCurtailmentLoading: true,
      };
    case actions.UPDATE_EXECUTE_CURTAILMENT:
      return {
        ...state,
        executeCurtailmentLoading: false,
      };
    case actions.CREATE_SETPOINT:
      return {
        ...state,
        executeCurtailmentSetpointLoading: true,
      };
    case actions.UPDATE_CREATE_SETPOINT:
      return {
        ...state,
        executeCurtailmentSetpointLoading: false,
      };
    case actions.FETCH_NEW_CURTAILMENT_CONSTRAINTS:
      return {
        ...state,
        constraintsLoading: true,
      };
    case actions.UPDATE_NEW_CURTAILMENT_CONSTRAINTS:
      return {
        ...state,
        newCurtailment: {
          ...state.newCurtailment,
          constraints: action.payload.realPowerP,
        },
        constraintsLoading: false,
      };
    case actions.FETCH_NEW_CURTAILMENT_PREDICTIONS:
      return {
        ...state,
        predictionsLoading: true,
      };
    case actions.UPDATE_NEW_CURTAILMENT_PREDICTIONS:
      return {
        ...state,
        newCurtailment: {
          ...state.newCurtailment,
          predictions: action.payload.predictions,
        },
        predictionsLoading: false,
      };
    case actions.FAILED_NEW_CURTAILMENT_PREDICTIONS:
      return {
        ...state,
        predictionsLoading: false,
      };
    case actions.UPDATE_CURTAILMENT_DEPLOYMENTS:
      return {
        ...state,
        curtailmentDeployments: action.payload.deployments,
      };
    case actions.CLEAR_CURTAILMENT_DEPLOYMENTS:
      return {
        ...state,
        curtailmentDeployments: null,
      };
    default:
      return state;
  }
};

export default feedInManagementReducer;

import React, { Component } from "react";
import { Link } from "react-router-dom";
import { FormattedNumber } from "react-intl";
import { MomentDate, Duration } from "../widgets";
import { getDateAsString } from "../widgets/Moment";
import Loading from "../widgets/Loading";

class CurtailmentListTable extends Component {
  constructor(props) {
    super(props);

    let powerstationMap = {};
    for (let i in props.powerstations) {
      const powerstation = props.powerstations[i];
      powerstationMap[powerstation.id] = powerstation;
    }

    this.state = {
      powerstationMap: powerstationMap,
    };
  }

  handleOnRowClick = curtailment => {
    this.props.history.push(this.getUserUrl(curtailment));
  };

  getUserUrl = curtailment => {
    return `/virtual-power-plants/feed-in-management/${curtailment.id}`;
  };

  getPowerstationName = powerstationId => {
    const powerstation = this.state.powerstationMap[powerstationId];
    if (powerstation) {
      return powerstation.name;
    } else {
      return "";
    }
  };

  getStatus = state => {
    switch (state) {
      case "COMPLETED":
        return "Completed";
      case "UPCOMING":
        return "Upcoming";
      case "INPROGRESS":
        return "In Progress";
      default:
        return "";
    }
  };

  getType = component => {
    switch (component) {
      case "grid":
        return "Net";
      case "solar":
        return "Gross";
      default:
        return "";
    }
  };

  isThisYear = date => {
    const dateYear = getDateAsString(date, this.props.timezone, "YYYY");
    const nowYear = getDateAsString(new Date(), this.props.timezone, "YYYY");
    return dateYear === nowYear;
  };

  render() {
    const curtailments = this.props.curtailments;
    if (!curtailments) {
      return <Loading />;
    }

    if (curtailments.length === 0) {
      return <div className="fleet-table-no-results">No curtailments found.</div>;
    }

    return (
      <table>
        <thead>
          <tr>
            <th>Event</th>
            <th>Start</th>
            <th>Duration</th>
            <th>Request</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {curtailments.map(c => {
            return (
              <tr role="button" onClick={() => this.handleOnRowClick(c)}>
                <td>
                  <Link to={this.getUserUrl(c)}>
                    Curtailment
                    <br />
                  </Link>
                  <p>{this.getPowerstationName(c.powerstation)}</p>
                </td>
                <td>
                  {this.isThisYear(new Date(c.request.startTime * 1000)) ? (
                    <MomentDate
                      date={new Date(c.request.startTime * 1000)}
                      timezone={this.props.timezone}
                      format="D MMMM, h:mm a"
                    />
                  ) : (
                    <MomentDate
                      date={new Date(c.request.startTime * 1000)}
                      timezone={this.props.timezone}
                      format="D MMM YY, h:mm a"
                    />
                  )}
                </td>
                <td>
                  <Duration seconds={c.request.duration} />
                </td>
                <td>
                  <span>
                    <FormattedNumber value={c.request.realPowerP} maximumFractionDigits={2} /> kW{" "}
                    {this.getType(c.component)}
                  </span>
                </td>
                <td>{this.getStatus(c.state)}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  }
}

export default CurtailmentListTable;

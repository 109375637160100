import React, { Component } from "react";
import Loading from "./Loading";
import "./EditableInput.css";
import "./Input.css";

class EditableInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editing: false,
      value: props.value,
    };
    this.handleChange = this.handleChange.bind(this);
    this.toggleEditing = this.toggleEditing.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (this.state.editing) {
      this.editableInput.focus();
    }
    if (this.props.value !== prevProps.value) {
      this.setState({ value: this.props.value });
    }
  }

  toggleEditing() {
    this.setState({ editing: !this.state.editing });
  }

  handleChange(event) {
    this.setState({ value: event.target.value });
  }

  handleCancel() {
    this.setState({ value: this.props.value, editing: !this.state.editing });
  }

  handleSave() {
    const { onSaveChange } = this.props;
    onSaveChange(this.state.value);
    this.toggleEditing();
  }

  renderEditMode() {
    const { maxLength } = this.props;
    return (
      <div className="rp-widget input editable-input editable-input-container">
        <input
          ref={input => {
            this.editableInput = input;
          }}
          type="text"
          onChange={this.handleChange}
          value={this.state.value}
          maxLength={maxLength}
        />
        <button className="mp-button link" onClick={this.handleSave}>
          <i className="fa fa-check-square editable-input-icon" />
        </button>
        <button className="mp-button link" onClick={this.handleCancel}>
          <i className="fa fa-times-circle editable-input-icon editable-input-cancel" />
        </button>
      </div>
    );
  }

  renderReadOnly() {
    const { loading } = this.props;
    return (
      <div className="editable-input-container">
        <span className="editable-input-read-only">
          {this.state.value ? (
            this.state.value
          ) : (
            <span className="editable-input-placeholder">This site has no label</span>
          )}
        </span>
        {loading ? (
          <Loading width="20" height="20" className="loading-inline-vertical" />
        ) : (
          <button className="mp-button link" onClick={() => this.toggleEditing()}>
            <i className="fa fa-pencil editable-input-icon" />
          </button>
        )}
      </div>
    );
  }

  render() {
    return <span>{this.state.editing ? this.renderEditMode() : this.renderReadOnly()}</span>;
  }
}

export default EditableInput;

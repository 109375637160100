import React, { Component } from "react";
import { getDateAsString } from "../widgets/Moment";
import { TARIFF_TYPES } from "./Tariff";
import "./TariffSummary.css";

const RESET_PERIOD_TO_INTERVAL = {
  DAILY: "day",
  WEEKLY: "week",
  MONTHLY: "month",
  QUARTERLY: "quarter",
};

class TariffSummary extends Component {
  periodsToString = (periods, tariffType) => {
    if (periods.length === 0 && (tariffType === "FLAT_RATE" || tariffType === "BLOCK")) {
      return "all times";
    } else if (periods.length === 0) {
      return "all other times";
    }

    const isApplicableFor = (applicableDays, include, exclude) => {
      const includeDays = include.split(",");
      const excludeDays = exclude.split(",");

      for (let i in includeDays) {
        if (!applicableDays.includes(includeDays[i])) {
          return false;
        }
      }

      for (let i in excludeDays) {
        if (applicableDays.includes(excludeDays[i])) {
          return false;
        }
      }

      return true;
    };

    const formatTime = time => {
      const timeParts = time.split(":");

      if (timeParts[1] === "00") {
        const hour = parseInt(timeParts[0], 10);
        if (hour > 12) {
          return `${hour - 12}pm`;
        } else if (hour === 12) {
          return `${hour}pm`;
        } else {
          return `${hour}am`;
        }
      } else {
        const hour = parseInt(timeParts[0], 10);
        const minute = timeParts[1];
        if (hour > 12) {
          return `${hour - 12}:${minute}pm`;
        } else if (hour === 12) {
          return `${hour}:${minute}pm`;
        } else {
          return `${hour}:${minute}am`;
        }
      }
    };

    let periodStrings = [];
    for (let i in periods) {
      const period = periods[i];
      const days = period.applicableDays;

      if (isApplicableFor(days, "MON,TUE,WED,THU,FRI", "SAT,SUN,PUBLIC_HOLIDAYS")) {
        periodStrings.push(
          `${formatTime(period.startTime)}-${formatTime(period.endTime)} weekdays`
        );
      }

      if (isApplicableFor(days, "SAT,SUN", "MON,TUE,WED,THU,FRI,PUBLIC_HOLIDAYS")) {
        periodStrings.push(
          `${formatTime(period.startTime)}-${formatTime(period.endTime)} weekends`
        );
      }

      if (isApplicableFor(days, "MON,TUE,WED,THU,FRI,PUBLIC_HOLIDAYS", "SAT,SUN")) {
        periodStrings.push(
          `${formatTime(period.startTime)}-${formatTime(period.endTime)} weekdays/public holidays`
        );
      }

      if (isApplicableFor(days, "MON,TUE,WED,THU,FRI,PUBLIC_HOLIDAYS", "SAT,SUN")) {
        periodStrings.push(
          `${formatTime(period.startTime)}-${formatTime(period.endTime)} weekends/public holidays`
        );
      }

      if (isApplicableFor(days, "PUBLIC_HOLIDAYS", "MON,TUE,WED,THU,FRI,SAT,SUN")) {
        periodStrings.push(
          `${formatTime(period.startTime)}-${formatTime(period.endTime)} public holidays`
        );
      }

      if (isApplicableFor(days, "MON,TUE,WED,THU,FRI,SAT,SUN,PUBLIC_HOLIDAYS", "")) {
        periodStrings.push(
          `${formatTime(period.startTime)}-${formatTime(period.endTime)} all times`
        );
      }

      if (periodStrings.length === 0) {
        // Default just list the days as passed in included so we at least display something even if
        // it's not that great.
        periodStrings.push(days.join(","));
      }
    }

    return periodStrings.join(", ");
  };

  render() {
    const tariff = this.props.tariff;
    if (!tariff || !tariff.planCode) {
      return (
        <div className="tariff-summary">
          <span className="no-tariff">No plan information for this customer.</span>
        </div>
      );
    }
    return (
      <div className="tariff-summary">
        <table className="table-light tariff-summary-table">
          <thead>
            <tr>
              <th>Plan Type</th>
              <th>Network</th>
              <th>Solar FiT</th>
              <th>Plan Started</th>
              {tariff.discount && tariff.discount !== 0 ? <th>Discount</th> : null}
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{TARIFF_TYPES[tariff.tariffType]}</td>
              <td>{tariff.operationalNetwork}</td>
              <td>${parseFloat(tariff.feedInTariff)}</td>
              <td>
                {getDateAsString(new Date(tariff.startDate), this.props.timezone, "D MMMM YYYY")}
              </td>
              {tariff.discount && tariff.discount !== 0 ? (
                <td>{parseFloat(tariff.discount)}%</td>
              ) : null}
            </tr>
          </tbody>
        </table>
        <table className="table-light tariff-summary-rate-table">
          <thead>
            <tr>
              <th>Rate</th>
              <th />
              <th>
                $/kWh (ex. GST)
                {tariff.discount ? "*" : null}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td />
              <td>Daily supply charge</td>
              <td>${parseFloat(tariff.dailyCharge)}</td>
            </tr>
            {tariff.rates
              ? tariff.rates.map((r, i) => {
                  return r.blocks.map((b, j) => {
                    return (
                      <tr>
                        {j === 0 ? <td rowSpan={r.blocks.length}>{r.label}</td> : null}
                        <td>
                          {this.periodsToString(r.periods, tariff.type)}
                          {r.blocks.length > 1 ? ` - From ${b.onset} kWh` : null}
                          {tariff.resetPeriod && r.blocks.length > 1
                            ? " per " + RESET_PERIOD_TO_INTERVAL[tariff.resetPeriod]
                            : null}
                        </td>
                        <td>${parseFloat(b.price)}</td>
                      </tr>
                    );
                  });
                })
              : null}
          </tbody>
          {tariff.discount ? (
            <tfoot>
              <tr>
                <td />
                <td />
                <td>
                  <span className="discount-help-text">*Prices exclusive of discount</span>
                </td>
              </tr>
            </tfoot>
          ) : null}
        </table>
      </div>
    );
  }
}

export default TariffSummary;

import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import DashboardWidgetComponent from "./DashboardWidgetComponent";
import { Loading } from "../../../components/widgets";
import * as dashboardActions from "../../../redux/actions/dashboard";
import ReactMapGL from "react-map-gl";
import DeckGL, { HexagonLayer } from "deck.gl";
import { PerspectiveMercatorViewport } from "viewport-mercator-project";
import Permissions from "../../../permissions";
import Unconfigured from "../../../components/dashboard/Unconfigured";
import "./VoltageHeatMap.css";

const LIGHT_SETTINGS = {
  lightsPosition: [-0.144528, 49.739968, 8000, -3.807751, 54.104682, 8000],
  ambientRatio: 0.4,
  diffuseRatio: 0.6,
  specularRatio: 0.2,
  lightsStrength: [0.8, 0.0, 0.8, 0.0],
  numberOfLights: 2,
};

const mapStateToProps = state => ({
  deployments: state.dashboard.deployments,
  powerstations: state.dashboard.powerstations,
  powerstationData: state.dashboard.powerstationData,
});

const mapDispatchToProps = dispatch => {
  return {
    dashboardActions: bindActionCreators(dashboardActions, dispatch),
  };
};

class VoltageHeatMap extends DashboardWidgetComponent {
  static widgetId = "reposit.VoltageHeatMap";
  static widgetName = "Voltage Heat Map";
  static description =
    "Display heat map of voltage across on a map for all nodes in a selected Power Station.";
  static settingsConfig = [
    {
      name: "powerstation",
      displayName: "Virtual Power Plant",
      type: "powerstation-select",
    },
  ];
  static validPermissions = [Permissions.fleet.powerstations.data.view];

  constructor(props) {
    super(props);

    this.state = {
      map: {
        latitude: -35.333502,
        longitude: 149.170508,
        zoom: 9,
        pitch: 0,
        bearing: 0,
      },
      voltageDeployments: null,
    };
  }

  componentDidMount = () => {
    const powerstationId = this.props.settings.powerstation;
    if (powerstationId) {
      if (!this.props.deployments) {
        this.props.dashboardActions.fetchDeployments();
      }

      if (!this.props.powerstations) {
        this.props.dashboardActions.fetchPowerstations();
      } else {
        this.setPowerstationTitle(this.props);
      }

      // So long as we've got a powerstation ID always refresh the data everytime the component mounts.
      this.props.dashboardActions.fetchLatestPowerstationVoltage(powerstationId);

      if (this.props.powerstations) {
        const powerstation = this.props.powerstations.find(p => p.id === powerstationId);
        if (
          powerstation &&
          this.props.deployments &&
          this.props.powerstationData &&
          this.props.powerstationData[powerstationId] &&
          this.props.powerstationData[powerstationId].latestMeterVoltage
        ) {
          this.updateVoltageHeatmap(
            this.props.deployments,
            this.props.powerstationData[powerstationId].latestMeterVoltage
          );
        }
      }
    } else {
      this.setTitle("Voltage Heat Map"); // not yet configured
    }
  };

  setPowerstationTitle = props => {
    if (props.powerstations) {
      const powerstationId = props.settings.powerstation;
      const powerstation = props.powerstations.find(p => p.id === powerstationId);
      if (powerstation) {
        this.setTitle("Voltage Heat Map - " + powerstation.name);
      }
    }
  };

  componentWillReceiveProps = nextProps => {
    const powerstationId = nextProps.settings.powerstation;

    if (
      this.props.settings.powerstation !== powerstationId ||
      this.props.powerstations !== nextProps.powerstations
    ) {
      this.setPowerstationTitle(nextProps);
    }

    if (powerstationId) {
      if (
        (this.props.powerstationData !== nextProps.powerstationData ||
          this.props.deployments !== nextProps.deployments ||
          this.props.width !== nextProps.width ||
          this.props.height !== nextProps.height) &&
        nextProps.powerstationData &&
        nextProps.deployments
      ) {
        const powerstationData = nextProps.powerstationData;
        if (
          powerstationData[powerstationId] &&
          powerstationData[powerstationId].latestMeterVoltage
        ) {
          this.updateVoltageHeatmap(
            nextProps.deployments,
            powerstationData[powerstationId].latestMeterVoltage
          );
        }
      }
    }
  };

  updateVoltageHeatmap = (deployments, voltages) => {
    let deploymentUidMap = {};
    for (let i in deployments) {
      const deployment = deployments[i];
      deploymentUidMap[deployment.id] = deployment;
    }

    let voltageDeployments = [];
    let allVoltages = [];
    for (let i in voltages) {
      const deployment = deploymentUidMap[i];
      const voltage = voltages[i];

      if (deployment && voltage) {
        voltageDeployments.push({
          position: [deployment.address.lng, deployment.address.lat],
          voltage: voltage,
          size: 100,
        });
        allVoltages.push(voltage);
      }
    }

    if (allVoltages.length === 0) {
      this.setState({
        voltageDeployments: [],
        minVoltage: 240,
        maxVoltage: 240,
      });
      return;
    }

    const minVoltage = Math.min(...allVoltages);
    const maxVoltage = Math.max(...allVoltages);

    const bounds = this.getBounds(voltageDeployments);

    this.setState({
      voltageDeployments: voltageDeployments,
      minVoltage: minVoltage,
      maxVoltage: maxVoltage,
      map: {
        ...this.state.map,
        latitude: bounds.latitude,
        longitude: bounds.longitude,
        zoom: bounds.zoom,
      },
    });
  };

  getBounds = voltageDeployments => {
    const viewport = new PerspectiveMercatorViewport({
      width: this.props.width,
      height: this.props.height,
    });

    let minLat = null,
      maxLat = null,
      minLng = null,
      maxLng = null;

    for (let i in voltageDeployments) {
      const deployment = voltageDeployments[i];
      minLat = deployment.position[1] < minLat || minLat === null ? deployment.position[1] : minLat;
      maxLat = deployment.position[1] > maxLat || maxLat === null ? deployment.position[1] : maxLat;
      minLng = deployment.position[0] < minLng || minLng === null ? deployment.position[0] : minLng;
      maxLng = deployment.position[0] > maxLng || maxLng === null ? deployment.position[0] : maxLng;
    }

    // If we didn't manage to find any lat/lngs set some defaults (Reposit office).
    if (!minLat || !maxLat) {
      minLat = -35.333502;
      maxLat = -35.333502;
    }

    if (!minLng || !maxLng) {
      minLng = 149.170508;
      maxLng = 149.170508;
    }

    return viewport.fitBounds(
      [
        [minLng, minLat],
        [maxLng + 0.001, maxLat + 0.001],
      ],
      { padding: 50, offset: [0, 0] }
    );
  };

  calculateAverageVoltage = points => {
    delete points.x;
    delete points.y;

    let averageVoltage = points[0].voltage;
    if (points.length > 1) {
      let totalVoltage = 0;
      for (let i in points) {
        totalVoltage += points[i].voltage;
      }
      averageVoltage = totalVoltage / points.length;
    }

    return averageVoltage;
  };

  // For some reason when the map first loads none of the icons render. If the render method is
  // called again all the icons suddenly appear. This puts s 200ms delay after the component has map
  // component has mounted (this is called as a ref callback) and changes state which then causes a
  // render fixing the issue.
  renderHack = () => {
    setTimeout(() => {
      if (!this.state.renderHack) {
        this.setState({
          renderHack: true,
        });
      }
    }, 200);
  };

  getColorValue = points => {
    const averageVoltage = this.calculateAverageVoltage(points);
    if (averageVoltage < 230) return -3;
    if (averageVoltage < 233) return -2;
    if (averageVoltage < 235) return -1;
    if (averageVoltage > 250) return 3;
    if (averageVoltage > 247) return 2;
    if (averageVoltage > 245) return 1;
    return 0;
  };

  render() {
    if (!this.props.settings.powerstation) {
      return <Unconfigured />;
    }

    if (!this.state.voltageDeployments) {
      return <Loading />;
    }

    let radius;
    switch (true) {
      case this.state.map.zoom <= 12:
        radius = 1000;
        break;
      case this.state.map.zoom <= 14:
        radius = 500;
        break;
      case this.state.map.zoom <= 15:
        radius = 300;
        break;
      default:
        radius = 100;
        break;
    }

    const layer = new HexagonLayer({
      id: "hexagon-layer",
      data: this.state.voltageDeployments,
      radius: radius,
      pickable: true,
      opacity: 0.5,
      extruded: false,
      elevationDomain: null,
      onHover: info => {
        if (info.object) {
          const averageVoltage = this.calculateAverageVoltage(info.object.points);
          if (averageVoltage !== this.state.tileVoltage) {
            this.setState({
              tileVoltage: averageVoltage,
            });
          }
          this.setState({
            tooltipX: info.x,
            tooltipY: info.y,
          });
        } else {
          if (this.state.tileVoltage !== null) {
            this.setState({
              tileVoltage: null,
            });
          }
        }
      },
      colorDomain: [-3, 3],
      colorRange: [
        [179, 222, 221, 255],
        [100, 204, 201, 255],
        [67, 143, 141, 255],
        [38, 208, 124, 255],
        [235, 175, 115, 255],
        [246, 141, 38, 255],
        [178, 105, 33, 255],
      ],
      getColorValue: this.getColorValue,
      elevationScale: 10,
      lightSettings: LIGHT_SETTINGS,
    });

    const viewport = {
      width: this.props.width,
      height: this.props.height,
      latitude: this.state.map.latitude,
      longitude: this.state.map.longitude,
      pitch: this.state.map.pitch,
      bearing: this.state.map.bearing,
      zoom: this.state.map.zoom,
      onViewportChange: viewport => {
        const { width, height, latitude, longitude, zoom, pitch, bearing } = viewport;
        this.setState({
          map: {
            width: width,
            height: height,
            latitude: latitude,
            longitude: longitude,
            zoom: zoom,
            pitch: pitch,
            bearing: bearing,
          },
        });
      },
      mapStyle: process.env.REACT_APP_MAPBOX_STYLE,
    };

    return (
      <div className="voltage-heat-map-widget">
        <ReactMapGL
          ref={this.renderHack}
          {...viewport}
          mapboxApiAccessToken={process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}
        >
          <DeckGL {...viewport} layers={[layer]} />
          {this.state.tileVoltage ? (
            <div
              style={{
                left: this.state.tooltipX,
                top: this.state.tooltipY,
                position: "absolute",
                backgroundColor: "rgba(0, 0, 0, 0.8)",
                color: "white",
                fontSize: "12px",
                padding: "4px",
                opacity: "0.8",
                fontWeight: "bold",
                pointerEvents: "none",
              }}
            >
              Voltage: {+this.state.tileVoltage.toFixed(2)}
            </div>
          ) : null}
        </ReactMapGL>
        <div className="key-container">
          <div>
            <div>{"< 235v"}</div>
            <div className="key-block under" />
          </div>
          <div>
            <div>240v</div>
            <div className="key-block ok" />
          </div>
          <div>
            <div>{"> 245v"}</div>
            <div className="key-block over" />
          </div>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(VoltageHeatMap);

import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import * as customerActions from "../../redux/actions/customers";
import Page from "../../components/layout/Page";
import { Loading, Button } from "../../components/widgets";
import TariffListTable from "../../components/customers/TariffListTable";
import AuthRequired from "../AuthRequired";
import Permissions from "../../permissions";

const mapStateToProps = state => ({
  customers: state.customers,
});

const mapDispatchToProps = dispatch => {
  return {
    customerActions: bindActionCreators(customerActions, dispatch),
  };
};

class TariffList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchOpen:
        props.customers.tariffListFilter.tariffNameOrPlanCode ||
        props.customers.tariffListFilter.network ||
        props.customers.tariffListFilter.tariffType,
      filterNetworks: [],
    };
  }

  componentDidMount = () => {
    this.props.customerActions.fetchAllTariffs();
    this.setFilterOptions(this.props);
  };

  componentWillReceiveProps = nextProps => {
    if (this.props.customers.tariff.tariffs !== nextProps.customers.tariff.tariffs) {
      this.setFilterOptions(nextProps);
    }
  };

  setFilterOptions = props => {
    if (props.customers.tariff.tariffs) {
      let networks = {};
      let uniqueNetworks = [];

      for (let i in props.customers.tariff.tariffs) {
        const tariff = props.customers.tariff.tariffs[i];

        if (!networks[tariff.operationalNetworkUid]) {
          networks[tariff.operationalNetworkUid] = true;
          uniqueNetworks.push({
            uid: tariff.operationalNetworkUid,
            name: tariff.operationalNetwork,
          });
        }
      }

      this.setState({
        filterNetworks: uniqueNetworks,
      });
    } else {
      this.setState({
        filterNetworks: [],
      });
    }
  };

  closeSearchBar = () => {
    this.setState({ searchOpen: false });
    this.props.customerActions.clearTariffListFilter();
  };

  openSearchBar = () => {
    this.setState({ searchOpen: true });
  };

  onFilterChange = (filterKey, filterValue) => {
    if (filterValue) {
      this.props.customerActions.updateTariffListFilter(filterKey, filterValue);
    } else {
      this.props.customerActions.updateTariffListFilter(filterKey, null);
    }
  };

  onSortChange = sortField => {
    const currentSortField = this.props.customers.tariffListFilter.sortField;
    let direction = "";
    if (currentSortField && currentSortField.field === sortField) {
      if (currentSortField.direction === "ASC") {
        direction = "DESC";
      } else {
        direction = "ASC";
      }
    } else {
      direction = "ASC";
    }

    this.props.customerActions.updateTariffListFilter("sortField", {
      field: sortField,
      direction: direction,
    });
  };

  filterTariffs = (filter, tariffs) => {
    if (filter.network) {
      tariffs = tariffs.filter(t => t.operationalNetworkUid === filter.network);
    }

    if (filter.tariffType) {
      tariffs = tariffs.filter(t => t.type === filter.tariffType);
    }

    const tariffName = filter.tariffNameOrPlanCode;
    if (tariffName && tariffName.trim() !== "") {
      let tariffNameLower = tariffName.toLowerCase();
      tariffs = tariffs.filter(t => {
        return (
          t.name.toLowerCase().indexOf(tariffNameLower) >= 0 ||
          t.planCode.toLowerCase().indexOf(tariffNameLower) >= 0
        );
      });
    }

    const sortAlphaNumeric = (fieldName, direction) => {
      tariffs.sort((a, b) => {
        const aVal = a[fieldName].toLowerCase();
        const bVal = b[fieldName].toLowerCase();

        if (direction === "ASC") {
          if (aVal < bVal) return -1;
          if (aVal > bVal) return 1;
        } else if (direction === "DESC") {
          if (aVal > bVal) return -1;
          if (aVal < bVal) return 1;
        }

        return 0;
      });
    };

    const sortNumeric = (fieldName, direction) => {
      tariffs.sort((a, b) => {
        const aVal = a[fieldName];
        const bVal = b[fieldName];

        if (direction === "ASC") {
          return aVal - bVal;
        } else if (direction === "DESC") {
          return bVal - aVal;
        }
        return 0;
      });
    };

    const sortField = filter.sortField;
    if (sortField) {
      switch (sortField.field) {
        case "tariffName":
          sortAlphaNumeric("name", sortField.direction);
          break;
        case "tariffType":
          sortAlphaNumeric("type", sortField.direction);
          break;
        case "network":
          sortAlphaNumeric("operationalNetwork", sortField.direction);
          break;
        case "customers":
          sortNumeric("customersCount", sortField.direction);
          break;
        default:
          sortAlphaNumeric("name", "ASC");
          break;
      }
    } else {
      sortAlphaNumeric("name", "ASC");
    }

    return tariffs;
  };

  render() {
    if (this.props.customers.tariff.tariffs === null) {
      return <Loading />;
    }

    const tariffs = this.filterTariffs(
      this.props.customers.tariffListFilter,
      this.props.customers.tariff.tariffs
    );

    return (
      <Page>
        <Page.Header title="Plans">
          <Page.Header.Actions>
            <AuthRequired permission={Permissions.fleet.customerInformation.plans.edit}>
              <Button id="create-new-plan" type="primary" to="/customers/plans/new" icon="plus">
                New Plan
              </Button>
            </AuthRequired>
          </Page.Header.Actions>
        </Page.Header>
        <Page.Content>
          <TariffListTable
            tariffs={tariffs}
            history={this.props.history}
            onSortChange={this.onSortChange}
          />
        </Page.Content>
      </Page>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(TariffList));

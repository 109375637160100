import React, { Component } from "react";
import _ from "lodash";
import { Link } from "react-router-dom";
import FleetDeploymentsTableStatus from "./FleetDeploymentsTableStatus";
import "./FleetDeploymentsTable.css";
import { processMonitoringState } from "../../redux/reducers/utils";
import { getAddressLine1, getAddressLine2 } from "../../utils/formatting";
import { Tooltip } from "../widgets";

class FleetDeploymentsTable extends Component {
  handleOnRowClick = id => {
    this.props.history.push(this.getUserUrl(id));
  };

  getUserUrl = id => {
    return `/fleet-monitoring/nodes/${id}`;
  };

  render() {
    const deployments = this.props.deployments;

    if (!deployments || deployments.length === 0) {
      return (
        <div className="fleet-table-no-results">
          No nodes have been found for this search criteria
        </div>
      );
    }

    return (
      <table className="fleet-table">
        <thead>
          <tr>
            <th>Serial Number / Label</th>
            <th>Install Address</th>
            <th>NMI</th>
            <th>Inverter Type</th>
            <th>Battery Type</th>
            <th>
              Status <Tooltip id={"fleetStatus.list.status"} />
            </th>
          </tr>
        </thead>
        <tbody>
          {deployments.map(deployment => {
            return (
              <tr
                key={deployment.id}
                role="button"
                className="fleet-table-row"
                onClick={() => this.handleOnRowClick(deployment.id)}
              >
                <td>
                  <div>{deployment.system.reposit.serialNumber}</div>
                  <div className="site-label-cell">{deployment.siteLabel}</div>
                </td>
                <td>
                  {getAddressLine1(deployment.address)}
                  <br />
                  {getAddressLine2(deployment.address)}
                </td>
                <td>
                  <Link to={this.getUserUrl(deployment.id)}>{deployment.network.nmi}</Link>
                </td>
                <td>
                  {!_.isEmpty(deployment.system.inverter)
                    ? deployment.system.inverter[0].brand
                    : null}
                </td>
                <td>
                  {!_.isEmpty(deployment.system.battery)
                    ? deployment.system.battery[0].brand
                    : null}
                </td>
                <td>
                  <FleetDeploymentsTableStatus deployment={processMonitoringState(deployment)} />
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  }
}

export default FleetDeploymentsTable;

import React, { Component } from "react";
import WidgetContainer from "./WidgetContainer";
import Ticker from "./Ticker";
import "./DashboardFullScreen.css";

class DashboardFullScreen extends Component {
  render() {
    return (
      <div style={{ height: "100vh", width: "100vw" }}>
        <WidgetContainer
          key={this.props.widget.id}
          widgetId={this.props.widget.id}
          data-grid={this.props.widget.layout}
          widgetComponent={this.props.widget.component}
          settings={this.props.widget.settings}
          registerResizeCallback={this.props.registerResizeCallback}
          className="react-grid-item"
          fullscreen
          exitFullScreen={this.props.exitFullScreen}
          widgetCount={this.props.widgetCount}
          fullScreenWidgetIndex={this.props.fullScreenWidgetIndex}
          pauseFullScreen={this.props.pauseFullScreen}
          resumeFullScreen={this.props.resumeFullScreen}
          isFullScreenRunning={this.props.isFullScreenRunning}
          gotoFullScreenWidget={this.props.gotoFullScreenWidget}
        />
        <Ticker events={this.props.tickerEvents} fetchTickerEvents={this.props.fetchTickerEvents} />
      </div>
    );
  }
}

export default DashboardFullScreen;

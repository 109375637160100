import React, { Component } from "react";
import { Button, Select, Checkbox, Input } from "../widgets";
import "./TariffPeriodsTable.css";

class TariffPeriodsTable extends Component {
  getFormattedInputTime = val => {
    // This happens if we delete everything in the textbox.
    if (val === "") {
      return "";
    }

    // If the value does not start with a 0, 1 or 2 then it must be an AM time.
    if (val !== "" && !(val.startsWith("0") || val.startsWith("1") || val.startsWith("2"))) {
      val = "0" + val;
    }

    // Check if the string matches a very loose regex for time, if it does, proceed, otherwise ignore the input.
    if (/^(([0|1][0-9])|(2[0-3]))?:?[0-5]?[0-9]?$/.test(val)) {
      // Check if a colon has been entered, if it has remove it and then put it back in in the right place.
      if (val.indexOf(":") > 0 && val.length > 2) {
        val = val.replace(/:/g, "");
        val = val.substring(0, 2) + ":" + val.substring(2, 4);
      } else if (val.indexOf(":") < 0 && val.length > 2) {
        // If a colon hasn't been entered on add it once we have complete the hour.
        val = val.substring(0, 2) + ":" + val.substring(2, 4);
      }

      return val;
    }

    return null;
  };

  handleTimeOnChange = (property, i, e) => {
    const time = this.getFormattedInputTime(e.target.value);

    // Explicit null check here as getFormattedInputTime sometimes returns an empty string.
    if (time !== null) {
      this.props.periodChange(i, property, time);
    }
  };

  handleDayChange = (i, e) => {
    this.props.periodChange(i, "applicableDays", {
      day: e.target.value,
      checked: e.target.checked,
    });
  };

  render() {
    const { periods, rates, errors } = this.props;
    const isReadOnly = this.props.readOnly;

    return (
      <table
        cellSpacing={0}
        cellPadding={0}
        className={"table-light periods-table" + (isReadOnly ? " ro" : "")}
      >
        <thead>
          <tr>
            <th>Time Range</th>
            <th>Days</th>
            <th>Rate</th>
            <th />
          </tr>
        </thead>
        <tbody>
          {periods.map((p, i) => {
            return (
              <tr>
                <td className="time-range">
                  <Input
                    readOnly={isReadOnly}
                    placeholder="00:00"
                    onChange={e => this.handleTimeOnChange("startTime", i, e)}
                    onKeyPress={e => this.handleTimeOnChange("startTime", i, e)}
                    value={p.startTime}
                    error={
                      errors[i]
                        ? errors[i].startTime || errors[i].overlap || errors[i].timeAlignment
                        : null
                    }
                  />
                  <Input
                    readOnly={isReadOnly}
                    placeholder="00:00"
                    onChange={e => this.handleTimeOnChange("endTime", i, e)}
                    onKeyPress={e => this.handleTimeOnChange("endTime", i, e)}
                    value={p.endTime}
                    error={
                      errors[i]
                        ? errors[i].endTime || errors[i].overlap || errors[i].timeAlignment
                        : null
                    }
                  />
                  {errors[i] && errors[i].timeAlignment ? (
                    <span className="error-message">Start time must come before end time.</span>
                  ) : null}
                </td>
                <td className="days">
                  <label>
                    <span className="text">Mo</span>
                    <Checkbox
                      readOnly={isReadOnly}
                      checked={p.applicableDays.mon}
                      value="mon"
                      onChange={e => this.handleDayChange(i, e)}
                      error={errors[i] ? errors[i].applicableDays : null}
                    />
                  </label>
                  <label>
                    <span className="text">Tu</span>
                    <Checkbox
                      readOnly={isReadOnly}
                      checked={p.applicableDays.tue}
                      value="tue"
                      onChange={e => this.handleDayChange(i, e)}
                      error={errors[i] ? errors[i].applicableDays : null}
                    />
                  </label>
                  <label>
                    <span className="text">We</span>
                    <Checkbox
                      readOnly={isReadOnly}
                      checked={p.applicableDays.wed}
                      value="wed"
                      onChange={e => this.handleDayChange(i, e)}
                      error={errors[i] ? errors[i].applicableDays : null}
                    />
                  </label>
                  <label>
                    <span className="text">Th</span>
                    <Checkbox
                      readOnly={isReadOnly}
                      checked={p.applicableDays.thu}
                      value="thu"
                      onChange={e => this.handleDayChange(i, e)}
                      error={errors[i] ? errors[i].applicableDays : null}
                    />
                  </label>
                  <label>
                    <span className="text">Fr</span>
                    <Checkbox
                      readOnly={isReadOnly}
                      checked={p.applicableDays.fri}
                      value="fri"
                      onChange={e => this.handleDayChange(i, e)}
                      error={errors[i] ? errors[i].applicableDays : null}
                    />
                  </label>
                  <label>
                    <span className="text">Sa</span>
                    <Checkbox
                      readOnly={isReadOnly}
                      checked={p.applicableDays.sat}
                      value="sat"
                      onChange={e => this.handleDayChange(i, e)}
                      error={errors[i] ? errors[i].applicableDays : null}
                    />
                  </label>
                  <label>
                    <span className="text">Su</span>
                    <Checkbox
                      readOnly={isReadOnly}
                      checked={p.applicableDays.sun}
                      value="sun"
                      onChange={e => this.handleDayChange(i, e)}
                      error={errors[i] ? errors[i].applicableDays : null}
                    />
                  </label>
                  <label>
                    <span className="text">Public Holiday</span>
                    <Checkbox
                      readOnly={isReadOnly}
                      checked={p.applicableDays.publicHolidays}
                      value="publicHolidays"
                      onChange={e => this.handleDayChange(i, e)}
                      error={errors[i] ? errors[i].applicableDays : null}
                    />
                  </label>
                  {errors[i] && errors[i].applicableDays ? (
                    <span className="error-message">{errors[i].applicableDays}</span>
                  ) : null}
                </td>
                <td className="rate">
                  <Select
                    readOnly={isReadOnly}
                    value={p.rate}
                    options={rates
                      .filter(r => r.label)
                      .map(r => ({ value: r.label, label: r.label }))}
                    onChange={option =>
                      this.props.periodChange(i, "rate", option ? option.value : null)
                    }
                    error={errors[i] ? errors[i].rate : null}
                  />
                </td>
                <td className="actions">
                  {!isReadOnly && i !== 0 ? (
                    <Button
                      id="tariff-remove-period"
                      type="cancel"
                      onClick={() => this.props.removePeriod(i)}
                    >
                      <i className="fa fa-times-circle" />
                    </Button>
                  ) : null}
                </td>
              </tr>
            );
          })}
        </tbody>
        {!isReadOnly && this.props.addPeriod ? (
          <tfoot>
            <tr>
              <td className="period-add" colSpan={4}>
                {errors.find(e => e.overlap) ? (
                  <span className="error-message">
                    There are periods that are currently overlapping, please correct these periods
                    before saving.
                  </span>
                ) : null}
                <Button id="tariff-add-period" type="link" onClick={this.props.addPeriod}>
                  <i className="fa fa-plus-circle" />
                  Add Period
                </Button>
              </td>
            </tr>
          </tfoot>
        ) : null}
      </table>
    );
  }
}

export default TariffPeriodsTable;

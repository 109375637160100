import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import queryString from "query-string";
import Page from "../../components/layout/Page";
import { Loading, Button } from "../../components/widgets";
import CurtailmentListTable from "../../components/curtailment/CurtailmentListTable";
import NewCurtailmentModal from "./NewCurtailmentModal";
import * as feedInManagementActions from "../../redux/actions/feedinmanagement";
import AuthRequired from "../AuthRequired";
import Permissions from "../../permissions";
import PagingControl from "../../components/widgets/PagingControl";
import TabBar from "../../components/layout/TabBar";
import SetpointListTable from "../../components/curtailment/SetpointListTable";
import NewCurtailmentSetpointModal from "./NewCurtailmentSetpointModal";

export const PAGE_SIZE = 10;

const mapStateToProps = state => ({
  curtailments: state.feedInManagement.curtailments,
  setpoints: state.feedInManagement.setpoints,
  powerstations: state.feedInManagement.powerstations,
  user: state.user,
  curtailmentsPageMeta: state.feedInManagement.curtailmentsPageMeta,
  setpointsPageMeta: state.feedInManagement.setpointsPageMeta,
  executeCurtailmentSetpointLoading: state.feedInManagement.executeCurtailmentSetpointLoading,
  location: state.router.location,
  setpointsLoading: state.feedInManagement.setpointsLoading,
  curtailmentsLoading: state.feedInManagement.curtailmentsLoading,
});

const mapDispatchToProps = dispatch => {
  return {
    feedInManagementActions: bindActionCreators(feedInManagementActions, dispatch),
  };
};

class CurtailmentList extends Component {
  constructor(props) {
    super(props);

    let powerstationMap = {};
    for (let i in props.powerstations) {
      const powerstation = props.powerstations[i];
      powerstationMap[powerstation.id] = powerstation;
    }

    const search = queryString.parse(props.location.search);

    let limit = PAGE_SIZE;
    if (search.limit !== undefined) {
      limit = parseInt(search.limit, 10);
    }

    let offset = 0;
    if (search.offset !== undefined) {
      offset = parseInt(search.offset, 10);
    }

    this.state = {
      pageTab: this.getPageTab(search),
      showNewCurtailment: false,
      limit,
      offset,
      powerstationMap: powerstationMap,
    };

    this.updateLimitPerPage = this.updateLimitPerPage.bind(this);
  }

  componentDidMount = () => {
    this.fetchData(this.state.limit, this.state.offset, this.state.pageTab);
  };

  getPageTab(search) {
    let pageTab = "CURTAILMENT_LIST";
    if (search.tab !== undefined) {
      if (search.tab === "SETPOINT_LIST") {
        pageTab = "SETPOINT_LIST";
      }
    }
    return pageTab;
  }

  fetchData = (limit, offset, pageTab) => {
    if (pageTab === "CURTAILMENT_LIST") {
      this.fetchCurtailmentData(limit, offset);
    }
    if (pageTab === "SETPOINT_LIST") {
      this.fetchSetpointData(limit, offset);
    }
  };

  fetchCurtailmentData = (limit, offset) => {
    this.props.feedInManagementActions.fetchCurtailments(limit, offset);
    this.props.feedInManagementActions.fetchPowerstations();
  };

  fetchSetpointData = (limit, offset) => {
    this.props.feedInManagementActions.fetchSetpoints(limit, offset);
    if (!this.props.powerstations) {
      this.props.feedInManagementActions.fetchPowerstations();
    }
  };

  getPage = (limit, offset, pageTab) => {
    this.updateUrl(pageTab, limit, offset);
  };

  updateUrl = (tab, limit, offset) => {
    const url = `${this.props.location.pathname}?tab=${tab}&limit=${limit}&offset=${offset}`;
    this.props.history.push(url);
  };

  showNewCurtailment = () => {
    this.setState({
      showNewCurtailment: true,
    });
  };

  hideNewCurtailment = () => {
    this.setState({
      showNewCurtailment: false,
    });
  };

  showNewCurtailmentSetpoint = () => {
    this.setState({
      showNewCurtailmentSetpoint: true,
    });
  };

  hideNewCurtailmentSetpoint = () => {
    this.setState({
      showNewCurtailmentSetpoint: false,
    });
  };

  componentWillReceiveProps = nextProps => {
    // if we've got word the setpoint has executed
    if (
      this.props.executeCurtailmentSetpointLoading &&
      !nextProps.executeCurtailmentSetpointLoading
    ) {
      this.hideNewCurtailmentSetpoint();
      this.props.feedInManagementActions.fetchSetpoints(this.state.limit, this.state.offset);
    }
    const nextSearch = queryString.parse(nextProps.location.search);
    if (
      this.props.location.search &&
      nextProps.location.search &&
      this.props.location.search !== nextProps.location.search
    ) {
      this.fetchData(nextSearch.limit, nextSearch.offset, nextSearch.tab);
      this.setState({
        limit: parseInt(nextSearch.limit, 10),
        offset: parseInt(nextSearch.offset, 10),
        pageTab: this.getPageTab(nextSearch),
      });
    }
  };

  updateLimitPerPage = limit => {
    this.setState({ limit });
  };

  handlePageTabOnChange = nextTab => {
    this.setState({
      pageTab: nextTab,
    });
    this.updateUrl(nextTab, this.state.limit, 0);
  };

  renderContent() {
    let curtailments = this.props.curtailments;
    let setpoints = this.props.setpoints;

    if (this.state.pageTab === "CURTAILMENT_LIST") {
      if (this.props.curtailmentsLoading || !this.props.curtailments || !this.props.powerstations) {
        return <Loading />;
      }

      curtailments.sort((a, b) => {
        const aRequest = a.request;
        const bRequest = b.request;
        if (aRequest.startTime > bRequest.startTime) return -1;
        if (aRequest.startTime < bRequest.startTime) return 1;
        if (aRequest.startTime === bRequest.startTime) {
          if (aRequest.duration > bRequest.duration) return -1;
          if (aRequest.duration < bRequest.duration) return 1;
          return 0;
        }
        return 0;
      });
    } else if (this.state.pageTab === "SETPOINT_LIST") {
      if (this.props.setpointsLoading || !this.props.setpoints || !this.props.powerstations) {
        return <Loading />;
      }
    }
    return (
      <div>
        {this.state.pageTab === "CURTAILMENT_LIST" ? (
          <>
            <CurtailmentListTable
              curtailments={curtailments}
              powerstations={this.props.powerstations}
              timezone={this.props.user.account.timezone}
              history={this.props.history}
            />
            <PagingControl
              key="curtailmentPaging"
              limit={this.state.limit}
              offset={this.state.offset}
              totalItems={this.props.curtailmentsPageMeta.count}
              itemName="curtailment"
              onPageChange={(limit, offset) => this.getPage(limit, offset, this.state.pageTab)}
              onChangeResultsPerPage={this.updateLimitPerPage}
              orientation="up"
            />
            <>
              {this.state.showNewCurtailment ? (
                <NewCurtailmentModal handleOnCancel={this.hideNewCurtailment} />
              ) : null}
            </>
          </>
        ) : (
          <>
            <SetpointListTable
              setpoints={setpoints}
              powerstations={this.props.powerstations}
              timezone={this.props.user.account.timezone}
              history={this.props.history}
            />
            <PagingControl
              key="setpointPaging"
              limit={this.state.limit}
              offset={this.state.offset}
              totalItems={this.props.setpointsPageMeta.count}
              itemName="setpoint"
              onPageChange={(limit, offset) => this.getPage(limit, offset, this.state.pageTab)}
              onChangeResultsPerPage={this.updateLimitPerPage}
              orientation="up"
            />
            <>
              {this.state.showNewCurtailmentSetpoint ? (
                <NewCurtailmentSetpointModal handleOnCancel={this.hideNewCurtailmentSetpoint} />
              ) : null}
            </>
          </>
        )}
      </div>
    );
  }

  render() {
    return (
      <Page key="curtailment-list" permission={Permissions.fleet.curtailments.any}>
        <Page.Header title="Feed-In Management">
          <AuthRequired permission={Permissions.fleet.curtailments.edit}>
            <Page.Header.Actions>
              {this.state.pageTab === "CURTAILMENT_LIST" ? (
                <Button
                  id="new-curtailment-from-curtailment-list"
                  type="primary"
                  icon="plus"
                  onClick={this.showNewCurtailment}
                >
                  New Curtailment
                </Button>
              ) : (
                <Button
                  id="new-curtailment-setpoint-from-fim"
                  type="primary"
                  icon="plus"
                  onClick={this.showNewCurtailmentSetpoint}
                >
                  New Default Setpoint
                </Button>
              )}
            </Page.Header.Actions>
          </AuthRequired>
        </Page.Header>
        <Page.Content>
          <TabBar
            tabs={[
              { label: "Curtailments", value: "CURTAILMENT_LIST" },
              { label: "Default Setpoints", value: "SETPOINT_LIST" },
            ]}
            onChange={this.handlePageTabOnChange}
            activeTab={this.state.pageTab}
          />
          {this.renderContent()}
        </Page.Content>
      </Page>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CurtailmentList);

import React, { Component } from "react";
import "./NoSidebarLayout.css";

export class NoSidebarLayout extends Component {
  render() {
    return (
      <div className="content-wrapper">
        <div className="content-container no-sidebar">
          <div className="content">{this.props.children}</div>
        </div>
      </div>
    );
  }
}

export default NoSidebarLayout;

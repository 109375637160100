import { Notify } from "../../utils/notificationManager";

export const FETCH_CUSTOMER_CONTRACTS = "FETCH_CUSTOMER_CONTRACTS";
export const CLEAR_CUSTOMER_CONTRACTS = "CLEAR_CUSTOMER_CONTRACTS";
export const UPDATE_CUSTOMER_CONTRACTS = "UPDATE_CUSTOMER_CONTRACTS";
export const UPDATE_CUSTOMER_CONTRACTS_UPLOAD_CHANGES = "UPDATE_CUSTOMER_CONTRACTS_UPLOAD_CHANGES";
export const UPDATE_CUSTOMER_LIST_FILTER = "UPDATE_CUSTOMER_LIST_FILTER";
export const CLEAR_CUSTOMER_LIST_FILTER = "CLEAR_CUSTOMER_LIST_FILTER";
export const UPDATE_TARIFF_LIST_FILTER = "UPDATE_TARIFF_LIST_FILTER";
export const CLEAR_TARIFF_LIST_FILTER = "CLEAR_TARIFF_LIST_FILTER";
export const FETCH_SAVE_NEW_TARIFF = "FETCH_SAVE_NEW_TARIFF";
export const UPDATE_SAVE_NEW_TARIFF = "UPDATE_SAVE_NEW_TARIFF";
export const ERROR_SAVE_NEW_TARIFF = "ERROR_SAVE_NEW_TARIFF";
export const FETCH_OPERATIONAL_NETWORKS = "FETCH_OPERATIONAL_NETWORKS";
export const UPDATE_OPERATIONAL_NETWORKS = "UPDATE_OPERATIONAL_NETWORKS";
export const FETCH_CUSTOMER_DETAILS = "FETCH_CUSTOMER_DETAILS";
export const UPDATE_CUSTOMER_DETAILS = "UPDATE_CUSTOMER_DETAILS";
export const CLEAR_CUSTOMER_DETAILS = "CLEAR_CUSTOMER_DETAILS";
export const FETCH_CURRENT_CUSTOMER_TARIFF = "FETCH_CURRENT_CUSTOMER_TARIFF";
export const UPDATE_CURRENT_CUSTOMER_TARIFF = "UPDATE_CURRENT_CUSTOMER_TARIFF";
export const FETCH_ALL_TARIFFS = "FETCH_ALL_TARIFFS";
export const UPDATE_ALL_TARIFFS = "UPDATE_ALL_TARIFFS";
export const FETCH_TARIFF = "FETCH_TARIFF";
export const UPDATE_TARIFF = "UPDATE_TARIFF";
export const CLEAR_ACTIVE_TARIFF = "CLEAR_ACTIVE_TARIFF";
export const FETCH_SAVE_EXISTING_TARIFF = "FETCH_SAVE_EXISTING_TARIFF";
export const UPDATE_SAVE_EXISTING_TARIFF = "UPDATE_SAVE_EXISTING_TARIFF";
export const ERROR_SAVE_EXISTING_TARIFF = "ERROR_SAVE_EXISTING_TARIFF";

export function fetchCustomersContracts() {
  return {
    type: FETCH_CUSTOMER_CONTRACTS,
    meta: {
      endpoint: "/contracts/customer",
      success: updateCustomersContracts,
    },
  };
}

export function clearCustomersContracts() {
  return {
    type: CLEAR_CUSTOMER_CONTRACTS,
  };
}

export function updateCustomersContracts(customersContracts) {
  return {
    type: UPDATE_CUSTOMER_CONTRACTS,
    payload: {
      customers: customersContracts.data.customers,
      contracts: customersContracts.data.contracts,
    },
  };
}

export function updateCustomerListFilter(filterKey, filterValue) {
  return {
    type: UPDATE_CUSTOMER_LIST_FILTER,
    payload: {
      filterKey: filterKey,
      filterValue: filterValue,
    },
  };
}

export function clearCustomerListFilter() {
  return {
    type: CLEAR_CUSTOMER_LIST_FILTER,
  };
}

export function updateTariffListFilter(filterKey, filterValue) {
  return {
    type: UPDATE_TARIFF_LIST_FILTER,
    payload: {
      filterKey: filterKey,
      filterValue: filterValue,
    },
  };
}

export function clearTariffListFilter() {
  return {
    type: CLEAR_TARIFF_LIST_FILTER,
  };
}

export function fetchSaveNewTariff(tariff) {
  return {
    type: FETCH_SAVE_NEW_TARIFF,
    meta: {
      method: "POST",
      endpoint: "/contracts/plan",
      success: updateSaveNewTariff,
      error: errorSaveNewTariff,
      body: tariff,
      errorMessage: "Unable to save your plan.",
    },
  };
}

export function updateSaveNewTariff(tariffResp) {
  if (tariffResp.status === "ERROR") {
    Notify.warning("New Plan Errors", "Please correct your errors before continuing.");
    return {
      type: UPDATE_SAVE_NEW_TARIFF,
      payload: {
        tariffErrors: tariffResp.errors,
      },
    };
  } else if (tariffResp.status === "OK") {
    Notify.success("New Plan Created", "Your new plan has been successfully saved.");

    return {
      type: UPDATE_SAVE_NEW_TARIFF,
      payload: {
        tariff: tariffResp.data,
      },
    };
  }
}

export function errorSaveNewTariff() {
  return {
    type: ERROR_SAVE_NEW_TARIFF,
  };
}

export function fetchSaveExistingTariff(tariff) {
  return {
    type: FETCH_SAVE_EXISTING_TARIFF,
    meta: {
      method: "PUT",
      endpoint: "/contracts/plan/" + tariff.uid,
      success: updateSaveExistingTariff,
      error: errorSaveExistingTariff,
      body: tariff,
      errorMessage: "Unable to save your plan.",
    },
  };
}

export function updateSaveExistingTariff(tariffResp) {
  if (tariffResp.status === "ERROR") {
    Notify.warning("Plan Errors", "Please correct your errors before continuing.");

    return {
      type: UPDATE_SAVE_EXISTING_TARIFF,
      payload: {
        tariffErrors: tariffResp.errors,
      },
    };
  } else if (tariffResp.status === "OK") {
    Notify.success("Plan Saved", "Your plan has been successfully saved.");

    return {
      type: UPDATE_SAVE_EXISTING_TARIFF,
      payload: {
        tariff: tariffResp.data,
      },
    };
  }
}

export function errorSaveExistingTariff() {
  return {
    type: ERROR_SAVE_EXISTING_TARIFF,
  };
}

export function fetchOperationalNetworks() {
  return {
    type: FETCH_OPERATIONAL_NETWORKS,
    meta: {
      endpoint: "/contracts/plan/operational-networks",
      success: updateOperationalNetworks,
    },
  };
}

export function updateOperationalNetworks(networksResp) {
  return {
    type: UPDATE_OPERATIONAL_NETWORKS,
    payload: {
      networks: networksResp.data.operationalNetworks,
    },
  };
}

export function fetchCustomerDetails(nmi) {
  return {
    type: FETCH_CUSTOMER_DETAILS,
    meta: {
      endpoint: "/contracts/customer/" + nmi,
      success: updateCustomerDetails,
      errorMessage: "Unable to fetch customer details.",
    },
  };
}

export function updateCustomerDetails(customerResp) {
  return {
    type: UPDATE_CUSTOMER_DETAILS,
    payload: {
      customerDetails: customerResp.data,
    },
  };
}

export function clearCustomerDetails() {
  return {
    type: CLEAR_CUSTOMER_DETAILS,
  };
}

export function fetchCurrentCustomerTariff(nmi) {
  return {
    type: FETCH_CURRENT_CUSTOMER_TARIFF,
    meta: {
      endpoint: "/contracts/customer/" + nmi + "/plan",
      success: updateCurrentCustomerTariff,
      errorMessage: "Unable to fetch customer plan.",
    },
  };
}

export function updateCurrentCustomerTariff(customerTariffResp) {
  return {
    type: UPDATE_CURRENT_CUSTOMER_TARIFF,
    payload: {
      tariff: customerTariffResp.data,
    },
  };
}

export function fetchAllTariffs() {
  return {
    type: FETCH_ALL_TARIFFS,
    meta: {
      endpoint: "/contracts/plan",
      success: updateAllTariffs,
      errorMessage: "Unable to fetch plans.",
    },
  };
}

export function updateAllTariffs(tariffsResp) {
  return {
    type: UPDATE_ALL_TARIFFS,
    payload: {
      tariffs: tariffsResp.data.plans,
    },
  };
}

export function fetchTariff(uid) {
  return {
    type: FETCH_TARIFF,
    meta: {
      // IMPORTANT! Encode the planCode as it could contain slashes and the like.
      endpoint: "/contracts/plan/" + uid,
      success: updateTariff,
      errorMessage: "Unable to fetch plan.",
    },
  };
}

export function updateTariff(tariffResp) {
  return {
    type: UPDATE_TARIFF,
    payload: {
      tariff: tariffResp.data,
    },
  };
}

export function clearActiveTariff() {
  return {
    type: CLEAR_ACTIVE_TARIFF,
  };
}

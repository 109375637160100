export const FETCH_API_KEYS = "FETCH_API_KEYS";
export const UPDATE_API_KEYS = "UPDATE_API_KEYS";
export const ADD_API_KEY = "ADD_API_KEY";
export const UPDATE_API_KEY_ADDED = "UPDATE_API_KEY_ADDED";
export const UPDATE_API_KEY_WITNESSED = "UPDATE_API_KEY_WITNESSED";
export const REVOKE_API_KEY = "REVOKE_API_KEY";
export const UPDATE_API_KEY_REVOKED = "UPDATE_API_KEY_REVOKED";
export const UPDATE_API_KEY_ACTION_FAILED = "UPDATE_API_KEY_ACTION_FAILED";
export const CLEAR_API_KEYS = "CLEAR_API_KEYS";

export function fetchApiKeys() {
  return {
    type: FETCH_API_KEYS,
    meta: {
      endpoint: "/account/api-keys",
      success: updateApiKeys,
    },
  };
}

export function updateApiKeys(keys) {
  return {
    type: UPDATE_API_KEYS,
    payload: {
      keys: keys.data.keys,
    },
  };
}

export function addApiKey(password, name, ipWhitelist, onSuccess, onFailure) {
  return {
    type: ADD_API_KEY,
    meta: {
      endpoint: "/account/api-keys?password=" + btoa(password),
      method: "POST",
      body: {
        keyName: name,
        ipWhitelist: ipWhitelist,
      },
      success: updateApiKeyAdded,
      error: updateApiKeyActionFailed,
    },
    payload: {
      onSuccess: onSuccess,
      onFailure: onFailure,
    },
  };
}

export function updateApiKeyAdded(response, payload) {
  if (response.error) {
    payload.onFailure(response.error, response.message);
    // deals with 400s (treated as a success by fetchMiddleware)
    return updateApiKeyActionFailed();
  }

  payload.onSuccess();

  return {
    type: UPDATE_API_KEY_ADDED,
    payload: {
      key: response.data,
    },
  };
}

export function updateApiKeyWitnessed() {
  return {
    type: UPDATE_API_KEY_WITNESSED,
  };
}

export function revokeApiKey(password, keyId, onSuccess, onFailure) {
  return {
    type: REVOKE_API_KEY,
    meta: {
      endpoint: "/account/api-keys/" + keyId + "?password=" + btoa(password),
      method: "DELETE",
      success: updateApiKeyRevoked,
      error: null,
    },
    payload: {
      keyId: keyId,
      onSuccess: onSuccess,
      onFailure: onFailure,
    },
  };
}

export function updateApiKeyRevoked(response, payload) {
  if (response && response.error) {
    payload.onFailure(response.error, response.message);
    // deals with 400s (treated as a success by fetchMiddleware)
    return updateApiKeyActionFailed();
  }

  payload.onSuccess();

  return {
    type: UPDATE_API_KEY_REVOKED,
    payload: {
      keyId: payload.keyId,
    },
  };
}

export function updateApiKeyActionFailed() {
  return {
    type: UPDATE_API_KEY_ACTION_FAILED,
  };
}

export function clearApiKeys() {
  return {
    type: CLEAR_API_KEYS,
  };
}

import React, { Component } from "react";
import "./CustomerLocation.css";
import { LabelledContent, DeploymentMap } from "../widgets";
import { getDateAsString } from "../widgets/Moment";
import { toTitleCase } from "../../utils/formatting";

class CustomerLocation extends Component {
  registerMapContainer = mapContainer => {
    if (mapContainer) {
      this.setState({
        map: {
          width: mapContainer.clientWidth,
          height: mapContainer.clientHeight,
        },
      });
    }
  };

  render() {
    return (
      <div className="customer-location">
        <div className="location-info">
          <LabelledContent vertical label="NMI">
            {this.props.nmi ? this.props.nmi : "-"}
          </LabelledContent>
          <LabelledContent vertical label="Install Address">
            {this.props.addressLine1}
            <br />
            {this.props.addressLine2}
          </LabelledContent>
          {this.props.status ? (
            <LabelledContent vertical label="Installation Status">
              {toTitleCase(this.props.status)}
            </LabelledContent>
          ) : null}
          {this.props.agreementDate ? (
            <LabelledContent vertical label="Reposit T&Cs">
              <LabelledContent horizontal label="Version">
                {this.props.agreementVersion}
              </LabelledContent>
              <LabelledContent horizontal label="Agreed Date">
                {getDateAsString(
                  new Date(this.props.agreementDate),
                  this.props.timezone,
                  "D MMMM YYYY"
                )}
              </LabelledContent>
            </LabelledContent>
          ) : null}
        </div>
        <div className="map-container" ref={this.registerMapContainer}>
          {this.state && this.state.map ? (
            <DeploymentMap
              width={this.state.map.width}
              height={this.state.map.height}
              latitude={this.props.latitude}
              longitude={this.props.longitude}
              zoom={13}
            />
          ) : null}
        </div>
      </div>
    );
  }
}

export default CustomerLocation;

import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import DashboardWidgetComponent from "./DashboardWidgetComponent";
import Permissions, { hasPermission } from "../../../permissions";
import * as dashboardActions from "../../../redux/actions/dashboard";
import { Loading } from "../../../components/widgets";
import { Line, VictoryArea, VictoryAxis, VictoryTooltip } from "victory";
import { asCapacity, asPower } from "../../../utils/formatting";
import moment from "moment-timezone";
import { ALL_POWERSTATIONS_OPTION } from "../WidgetSettings";
import "./GraphWidget.css";
import Unconfigured from "../../../components/dashboard/Unconfigured";

const PADDING_PCT = 0.22;

const THREE_HOURS = 10800;
const SIX_HOURS = 21600;
const NINE_HOURS = 32400;
const TWELVE_HOURS = 43200;
const FIFTEEN_HOURS = 54000;
const EIGHTEEN_HOURS = 64800;
const TWENTYONE_HOURS = 75600;

const mapStateToProps = state => ({
  powerstations: state.dashboard.powerstations,
  powerstationData: state.dashboard.powerstationData,
  user: state.user,
});

const mapDispatchToProps = dispatch => {
  return {
    dashboardActions: bindActionCreators(dashboardActions, dispatch),
  };
};

class SolarStats extends Component {
  getToday = () => {
    let total = 0;
    for (let i in this.props.data) {
      const data = this.props.data[i];
      total += data * (1 / 12); // multiple by 1/12th as the data is in 5 min intervals to get kWh from kW
    }

    return total;
  };

  getLatest = () => {
    let latestValue = 0;
    let latestTs = 0;
    for (let i in this.props.data) {
      if (i > latestTs) {
        latestTs = i;
        latestValue = this.props.data[i];
      }
    }

    return latestValue;
  };

  render() {
    return (
      <>
        <div className="stat">
          <div className="value">{asCapacity(Math.abs(this.getToday()))}</div>
          <div className="label">So far today</div>
        </div>
        <div className="stat">
          <div className="value">{asPower(Math.abs(this.getLatest()))}</div>
          <div className="label">Right now</div>
        </div>
      </>
    );
  }
}

class MeterStats extends Component {
  getExported = () => {
    let total = 0;
    for (let i in this.props.data) {
      const data = this.props.data[i];
      if (data < 0) {
        total += data * (1 / 12); // multiple by 1/12th as the data is in 5 min intervals to get kWh from kW
      }
    }

    return total;
  };

  getImported = () => {
    let total = 0;
    for (let i in this.props.data) {
      const data = this.props.data[i];
      if (data > 0) {
        total += data * (1 / 12); // multiple by 1/12th as the data is in 5 min intervals to get kWh from kW
      }
    }

    return total;
  };

  render() {
    return (
      <>
        <div className="stat">
          <div className="value">{asCapacity(Math.abs(this.getExported()))}</div>
          <div className="label">Exported</div>
        </div>
        <div className="stat">
          <div className="value">{asCapacity(Math.abs(this.getImported()))}</div>
          <div className="label">Imported</div>
        </div>
      </>
    );
  }
}

class RemainingChargeStats extends Component {
  getMax = () => {
    let max = 0;
    for (let i in this.props.data) {
      const data = this.props.data[i];
      max = max < data ? data : max;
    }

    return max / 1000;
  };

  getLatest = () => {
    let latestValue = 0;
    let latestTs = 0;
    for (let i in this.props.data) {
      if (i > latestTs) {
        latestTs = i;
        latestValue = this.props.data[i] / 1000;
      }
    }

    return latestValue;
  };

  render() {
    return (
      <>
        <div className="stat">
          <div className="value">{asCapacity(Math.abs(this.getMax()))}</div>
          <div className="label">Max. stored today</div>
        </div>
        <div className="stat">
          <div className="value">{asCapacity(Math.abs(this.getLatest()))}</div>
          <div className="label">Stored now</div>
        </div>
      </>
    );
  }
}

class Graph extends Component {
  constructor(props) {
    super(props);

    this.state = {
      startTimestamp: moment().tz(this.props.timezone).startOf("day").unix(),
      endTimestamp: moment().tz(this.props.timezone).add(1, "day").startOf("day").unix(),
    };
  }

  componentWillReceiveProps = nextProps => {
    if (
      this.props.widgetHeight !== nextProps.widgetHeight ||
      this.props.widgetWidth !== nextProps.widgetWidth
    ) {
      this.resetGraphContainerSize();
    }
  };

  getGraphData = () => {
    const tickSpacing = 1800;
    const startTimestamp = this.state.startTimestamp;
    const endTimestamp = this.state.endTimestamp;
    const absolute = this.props.absolute;

    let timestamp = startTimestamp;
    let timestampTicks = {};
    while (timestamp <= endTimestamp) {
      // A a vertical line.
      timestampTicks[timestamp] = { timestamp: timestamp, display: "" };
      timestamp += tickSpacing;
    }

    const sixAm = startTimestamp + SIX_HOURS;
    const noon = startTimestamp + TWELVE_HOURS;
    const sixPm = startTimestamp + EIGHTEEN_HOURS;
    timestampTicks[sixAm] = { timestamp: timestamp, display: "6 AM" };
    timestampTicks[noon] = { timestamp: timestamp, display: "12 PM" };
    timestampTicks[sixPm] = { timestamp: timestamp, display: "6 PM" };

    const data = this.props.data;
    let graphData = [];
    if (data) {
      Object.keys(data)
        .sort()
        .forEach(function (k) {
          const timestamp = parseInt(k, 10);
          graphData.push({
            x: timestamp,
            y: absolute ? Math.abs(data[k]) : data[k],
            timestamp: timestamp,
          });
        });
    }

    return {
      tickDisplay: timestampTicks,
      graphData: graphData,
    };
  };

  getDomain = () => {
    const startTimestamp = this.state.startTimestamp;
    const endTimestamp = this.state.endTimestamp;
    const data = this.props.data;
    const absolute = this.props.absolute;

    let min, max;

    if (data) {
      Object.keys(data)
        .sort()
        .forEach(function (k) {
          const dataPoint = absolute ? Math.abs(data[k]) : data[k];
          min = dataPoint < min || !min ? dataPoint : min;
          max = dataPoint > max || !max ? dataPoint : max;
        });
    }

    min = min === 0 ? -3 : min - Math.abs(min * PADDING_PCT);
    max = max === 0 ? 3 : max + Math.abs(max * PADDING_PCT);

    return {
      x: [startTimestamp, endTimestamp],
      y: [min, max],
    };
  };

  resetGraphContainerSize = () => {
    if (this.graphContainer) {
      this.setState({
        graphWidth: this.graphContainer.clientWidth,
        graphHeight: this.graphContainer.clientHeight,
      });
    }
  };

  registerGraphContainer = graphContainer => {
    this.graphContainer = graphContainer;
    this.resetGraphContainerSize();
  };

  render() {
    const { tickDisplay, graphData } = this.getGraphData();
    const domain = this.getDomain();

    const width = this.state.graphWidth;
    const height = this.state.graphHeight;

    const bottomPadding = 18;
    const padding = { top: 0, right: 0, bottom: 0, left: 0 };
    const startTimestamp = this.state.startTimestamp;

    return (
      <div className="graph-container" ref={this.registerGraphContainer}>
        {this.state.graphWidth && (
          <svg
            width={width}
            height={height + bottomPadding}
            role="img"
            viewBox={`0 0 ${width} ${height + bottomPadding}`}
            overflow="visible"
            style={{
              width: `${width}px`,
              height: `${height + bottomPadding}px`,
              userSelect: "none",
              touchAction: "none",
            }}
          >
            <rect
              x="0"
              y="0"
              fill="rgba(230, 236, 247, 0.5)"
              style={{
                width: width,
                height: height,
                stroke: "none",
              }}
            />
            <g transform="translate(0, 0)">
              {/* x-axis white lines */}
              <VictoryAxis
                width={width}
                height={height}
                padding={padding}
                domain={domain}
                style={{
                  axis: { stroke: "none" },
                  grid: { stroke: "#FFFFFF" },
                }}
                orientation="bottom"
                tickValues={Object.keys(tickDisplay).map(Number)}
                tickFormat={() => ""}
                standalone={false}
              />

              {/* x-axis light grey ticks */}
              <VictoryAxis
                width={width}
                height={height + bottomPadding}
                padding={padding}
                domain={domain}
                style={{
                  axis: { stroke: "none" },
                  grid: { stroke: "rgba(151, 151, 151, 0.5)" },
                }}
                orientation="top"
                tickValues={Object.keys(tickDisplay).map(Number)}
                tickFormat={() => ""}
                gridComponent={<Line type={"grid"} y1={height} y2={height + 8} />}
                standalone={false}
              />

              {/* x-axis minor dark grey ticks */}
              <VictoryAxis
                width={width}
                height={height + bottomPadding}
                padding={padding}
                domain={domain}
                style={{
                  axis: { stroke: "none" },
                  grid: { stroke: "rgb(151, 151, 151)" },
                }}
                orientation="top"
                tickValues={[
                  startTimestamp + THREE_HOURS,
                  startTimestamp + NINE_HOURS,
                  startTimestamp + FIFTEEN_HOURS,
                  startTimestamp + TWENTYONE_HOURS,
                ]}
                tickFormat={() => ""}
                gridComponent={<Line type={"grid"} y1={height} y2={height + 8} />}
                standalone={false}
              />

              {/* x-axis major dark grey (long) ticks */}
              <VictoryAxis
                width={width}
                height={height + bottomPadding}
                padding={padding}
                domain={domain}
                style={{
                  axis: { stroke: "none" },
                  grid: { stroke: "rgb(151, 151, 151)" },
                }}
                orientation="top"
                tickValues={[
                  startTimestamp + SIX_HOURS,
                  startTimestamp + TWELVE_HOURS,
                  startTimestamp + EIGHTEEN_HOURS,
                ]}
                tickFormat={() => ""}
                gridComponent={<Line type={"grid"} y1={height} y2={height + 12} />}
                standalone={false}
              />

              {/* x-axis labels */}
              <VictoryAxis
                width={width}
                height={height + bottomPadding * 2}
                padding={padding}
                domain={domain}
                style={{
                  axis: { stroke: "none" },
                  grid: { stroke: "none" },
                  tickLabels: {
                    fontFamily: '"Work Sans", "Helvetica Neue", Helvetica, Arial, sans-serif',
                    fill: "#929292",
                    fontSize: this.props.fullscreen ? "24" : "12",
                  },
                }}
                offsetY={32}
                orientation="bottom"
                tickValues={Object.keys(tickDisplay).map(Number)}
                tickFormat={tick => tickDisplay[tick + ""].display}
                standalone={false}
              />
              <VictoryArea
                width={width}
                height={height}
                padding={padding}
                data={graphData}
                domain={domain}
                interpolation="natural"
                labelComponent={<VictoryTooltip />}
                style={{
                  data: {
                    stroke: this.props.lineColour,
                    strokeWidth: this.props.fullscreen ? 3 : 1,
                    fill: this.props.lineColour,
                    fillOpacity: 0.2,
                  },
                }}
                standalone={false}
              />
            </g>
          </svg>
        )}
      </div>
    );
  }
}

class GraphWidget extends DashboardWidgetComponent {
  static widgetId = "reposit.GraphWidget";
  static widgetName = "Data Graph";
  static description =
    "Display today and right now values for a specific metric of data collected.";
  static settingsConfig = user => {
    const metricConfig = {
      name: "metric",
      displayName: "What metric do you want to display?",
      type: "select",
      options: [
        { value: "SOLAR_POWER", label: "Solar Generation" },
        { value: "METER_POWER", label: "Grid Import/Export" },
        { value: "REMAINING_CHARGE", label: "Stored Energy" },
      ],
    };

    if (hasPermission(user.permissions, Permissions.fleet.powerstations.data.view)) {
      return [
        {
          name: "powerstation",
          displayName: "Virtual Power Plant",
          tooltipId: "dashboard.graphWidget.powerstation",
          type: "powerstation-select-with-all-nodes-option",
        },
        metricConfig,
      ];
    } else {
      return [metricConfig];
    }
  };

  static validPermissions = [Permissions.fleet.powerstations.data.view, Permissions.fleet.node.any];

  componentDidMount = () => {
    if (!this.props.powersations) {
      this.setTitle("Data Graph"); // unconfigured
    }

    const powerstationId = this.getPowerstationId(this.props);
    if (powerstationId) {
      if (hasPermission(this.props.user.permissions, Permissions.fleet.powerstations.data.view)) {
        if (!this.props.powerstations) {
          this.props.dashboardActions.fetchPowerstations();
        } else {
          this.setGraphWidgetTitle(this.props);
        }
      } else {
        this.setGraphWidgetTitle(this.props);
      }

      this.fetchData(this.props);
    }
  };

  componentWillUnmount = () => {
    if (this.loadDataInterval) {
      clearInterval(this.loadDataInterval);
    }
  };

  fetchData = props => {
    const powerstationId = this.getPowerstationId(this.props);
    if (powerstationId) {
      if (props.settings.metric === "SOLAR_POWER") {
        this.props.dashboardActions.fetchPowerstationSolarPower(
          powerstationId,
          this.props.user.account.timezone
        );
      } else if (props.settings.metric === "REMAINING_CHARGE") {
        this.props.dashboardActions.fetchPowerstationRemainingCharge(
          powerstationId,
          this.props.user.account.timezone
        );
      } else {
        this.props.dashboardActions.fetchPowerstationMeterPower(
          powerstationId,
          this.props.user.account.timezone
        );
      }
    }
    if (this.loadDataInterval) {
      clearInterval(this.loadDataInterval);
    }
    this.loadDataInterval = setInterval(
      this.refreshData,
      1000 * 60 * 5 // 5 minutes
    );
  };

  refreshData = () => {
    this.fetchData(this.props);
  };

  componentWillReceiveProps = nextProps => {
    if (
      this.props.settings.metric !== nextProps.settings.metric ||
      this.props.settings.powerstation !== nextProps.settings.powerstation
    ) {
      this.fetchData(nextProps);
    }

    if (
      this.props.settings.metric !== nextProps.settings.metric ||
      this.props.settings.powerstation !== nextProps.settings.powerstation ||
      this.props.powerstations !== nextProps.powerstations
    ) {
      this.setGraphWidgetTitle(nextProps);
    }
  };

  setGraphWidgetTitle = props => {
    const powerstationId = this.getPowerstationId(props);
    if (props.powerstations || powerstationId === ALL_POWERSTATIONS_OPTION) {
      let powerstation;
      if (powerstationId === ALL_POWERSTATIONS_OPTION) {
        powerstation = true;
      } else {
        powerstation = props.powerstations.find(p => p.id === powerstationId);
      }

      if (powerstation) {
        const powerstationName =
          powerstationId === ALL_POWERSTATIONS_OPTION ? "All Nodes" : powerstation.name;
        if (props.settings.metric === "SOLAR_POWER") {
          this.setTitle("Solar Generation - " + powerstationName);
        } else if (props.settings.metric === "REMAINING_CHARGE") {
          this.setTitle("Stored Energy - " + powerstationName);
        } else {
          this.setTitle("Grid Import/Export - " + powerstationName);
        }
      }
    }
  };

  getPowerstationId = props => {
    // People without any VPP privs cant use VPPs so this will let them see all nodes.
    let powerstationId;
    if (hasPermission(props.user.permissions, Permissions.fleet.powerstations.data.view)) {
      powerstationId = props.settings.powerstation;
    } else {
      powerstationId = ALL_POWERSTATIONS_OPTION;
    }

    return powerstationId;
  };

  render() {
    const powerstationId = this.getPowerstationId(this.props);

    let powerstation;
    // If we using the "All Nodes in my Fleet" option then just set the powerstation var to true as
    // we don't use it and this will make the loading state disappear.
    if (powerstationId === ALL_POWERSTATIONS_OPTION) {
      powerstation = true;
    } else {
      if (!this.props.powerstations) {
        return <Loading />;
      }
      powerstation = this.props.powerstations.find(p => p.id === powerstationId);
    }

    if (!powerstation) {
      return <Unconfigured />;
    } else if (!this.props.powerstationData || !this.props.powerstationData[powerstationId]) {
      return <Loading />;
    }

    let data, className, lineColour, absolute;
    if (this.props.settings.metric === "SOLAR_POWER") {
      data = this.props.powerstationData[powerstationId].solarPower;
      className = "solar";
      lineColour = "rgb(255, 157, 66)";
      absolute = true;
    } else if (this.props.settings.metric === "REMAINING_CHARGE") {
      data = this.props.powerstationData[powerstationId].remainingCharge;
      className = "charge";
      lineColour = "rgb(87, 196, 199)";
      absolute = false;
    } else {
      data = this.props.powerstationData[powerstationId].meterPower;
      className = "meter";
      lineColour = "rgb(70, 152, 203)";
      absolute = false;
    }

    if (!data) {
      return <Loading />;
    }

    return (
      <div className={`graph-widget ${className}`}>
        <div className="stats-container">
          {this.props.settings.metric === "SOLAR_POWER" ? (
            <SolarStats data={data} />
          ) : this.props.settings.metric === "REMAINING_CHARGE" ? (
            <RemainingChargeStats data={data} />
          ) : (
            <MeterStats data={data} />
          )}
        </div>
        <div className="graph-container">
          <Graph
            absolute={absolute}
            data={data}
            timezone={this.props.user.account.timezone}
            lineColour={lineColour}
            widgetHeight={this.props.height}
            widgetWidth={this.props.width}
            fullscreen={this.props.fullscreen}
          />
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(GraphWidget);

import * as actions from "../actions/user";

const userInitialState = {
  account: null,
  loginStatus: "FETCHING",
  passwordResetStatus: null,
  passwordReset: null,
  permissions: [],
  sessionExpired: false,
  timezoneMismatch: false,
};

// NOTE: We deliberately don't ...spread over state here to more tightly
// manage the user part of the store. Bugs here could lead to unauthenticated
// access.
const dispatchReducer = (state = userInitialState, action) => {
  switch (action.type) {
    case "RESET_STORE":
      return {
        ...userInitialState,
        loginStatus: "BAD_AUTH",
      };
    case actions.LOG_IN:
    case actions.FETCH_ACCOUNT:
      let newState = {
        account: null,
        permissions: [],
        loginStatus: "FETCHING",
        passwordReset: null,
        sessionExpired: false,
      };
      delete newState.totp;
      delete newState.totpConfig;
      return newState;
    case actions.UPDATE_ACCOUNT:
      return {
        account: action.payload.browserTimezone
          ? { ...action.payload.account, timezone: action.payload.browserTimezone }
          : action.payload.account,
        permissions: action.payload.permissions,
        loginStatus: "LOGGED_IN",
        passwordReset: null,
        sessionExpired: false,
        timezoneMismatch: action.payload.timezoneMismatch,
      };
    case actions.LOGIN_ERROR:
      return {
        account: null,
        permissions: [],
        loginStatus: "ERROR",
        passwordReset: null,
        sessionExpired: false,
        savingAccount: false,
      };
    case actions.CLEAR_ACCOUNT:
      return {
        account: null,
        permissions: [],
        loginStatus: "LOGGED_OUT",
        passwordReset: null,
        sessionExpired: false,
      };
    case actions.SET_TIMEZONE:
      return {
        account: {
          ...state.account,
        },
        permissions: state.permissions,
        loginStatus: state.loginStatus,
        passwordReset: null,
        sessionExpired: false,
        savingTimezone: true,
        timezoneMismatch: state.timezoneMismatch,
      };
    case actions.UPDATE_TIMEZONE:
      return {
        account: {
          ...state.account,
          timezone: action.payload.timezone,
        },
        permissions: state.permissions,
        loginStatus: state.loginStatus,
        passwordReset: null,
        sessionExpired: false,
        savingTimezone: false,
        timezoneMismatch:
          action.payload.timezone !== Intl.DateTimeFormat().resolvedOptions().timeZone,
      };
    case actions.FETCH_REQUEST_PASSWORD_RESET:
      return {
        account: {
          ...state.account,
        },
        permissions: state.permissions,
        loginStatus: state.loginStatus,
        passwordReset: {
          status: "FETCHING",
          message: null,
        },
        sessionExpired: false,
      };
    case actions.UPDATE_REQUEST_PASSWORD_RESET:
      return {
        account: {
          ...state.account,
        },
        permissions: state.permissions,
        loginStatus: state.loginStatus,
        passwordReset: {
          status: action.payload.status,
          message: action.payload.message,
        },
        sessionExpired: false,
      };
    case actions.CLEAR_PASSWORD_RESET:
      return {
        account: {
          ...state.account,
        },
        permissions: state.permissions,
        loginStatus: state.loginStatus,
        passwordReset: null,
        sessionExpired: false,
      };
    case actions.CLEAR_TIMEZONE_MISMATCH:
      return {
        ...state,
        timezoneMismatch: !state.timezoneMismatch,
      };
    case actions.FETCH_VERIFY_PASSWORD_RESET_TOKEN:
      return {
        account: {
          ...state.account,
        },
        permissions: state.permissions,
        loginStatus: state.loginStatus,
        passwordReset: {
          status: "FETCHING",
          message: null,
        },
        sessionExpired: false,
      };
    case actions.UPDATE_VERIFY_PASSWORD_RESET_TOKEN:
      return {
        account: {
          ...state.account,
        },
        permissions: state.permissions,
        loginStatus: state.loginStatus,
        passwordReset: {
          status: action.payload.status,
          message: null,
          tokenValid: action.payload.tokenValid,
        },
        sessionExpired: false,
      };
    case actions.FETCH_CHANGE_PASSWORD:
      return {
        account: {
          ...state.account,
        },
        permissions: state.permissions,
        loginStatus: state.loginStatus,
        passwordReset: {
          status: "FETCHING",
          message: null,
          tokenValid: state.passwordReset.tokenValid,
        },
        sessionExpired: false,
      };
    case actions.UPDATE_CHANGE_PASSWORD:
      return {
        account: {
          ...state.account,
        },
        permissions: state.permissions,
        loginStatus: state.loginStatus,
        passwordReset: {
          status: action.payload.status === "error" ? "ERROR" : "PASSWORD_RESET",
          message: action.payload.message,
          tokenValid: state.passwordReset.tokenValid,
        },
        sessionExpired: false,
      };
    case actions.CLEAR_CHANGE_PASSWORD:
      return {
        account: {
          ...state.account,
        },
        permissions: state.permissions,
        loginStatus: state.loginStatus,
        passwordReset: {
          status: "OK",
          message: null,
          tokenValid: true,
        },
        sessionExpired: false,
      };
    case actions.SESSION_EXPIRED:
      return {
        account: {
          ...state.account,
        },
        permissions: state.permissions,
        loginStatus: state.loginStatus,
        passwordReset: {
          status: "OK",
          message: null,
          tokenValid: true,
        },
        sessionExpired: true,
      };
    case actions.CHANGE_PASSWORD_INLINE:
      return {
        ...state,
        passwordReset: {
          ...state.passwordReset,
          status: "CHANGING",
        },
      };
    case actions.UPDATE_PASSWORD_CHANGED_INLINE:
      let nextPasswordReset = {};
      if (!action.payload.success) {
        nextPasswordReset = {
          status: "FAILED",
          message: action.payload.message,
          tokenValid: true,
        };
      }
      return {
        ...state,
        passwordReset: nextPasswordReset,
      };
    case actions.CLEAR_PASSWORD_CHANGED_INLINE:
      return {
        ...state,
        passwordReset: null,
      };
    case actions.INITIATE_MULTI_FACTOR:
      return {
        ...state,
        loginStatus: "TOTP_REQUIRED",
        totpConfig: action.payload,
      };
    case actions.SUBMIT_TOTP:
      return {
        ...state,
        totp: {
          ...state.totp,
          errorMessage: null,
        },
      };
    case actions.UPDATE_TOTP_REJECTED:
      return {
        ...state,
        totp: {
          ...state.totp,
          errorMessage: "Invalid 6-digit code.",
        },
      };
    case actions.INITIATE_MULTI_FACTOR_CONFIGURATION:
      return {
        ...state,
        loginStatus: "TOTP_UNCONFIGURED",
        totpConfig: {
          totpUri: action.payload.totpUri,
          confirmed: action.payload.confirmed,
        },
      };
    case actions.UPDATE_MFA_SETTINGS:
      return {
        ...state,
        mfaSettings: action.payload,
        verifySMS: null,
        codePreference: null,
      };
    case actions.FETCH_MFA_SMS_VERIFICATION_REQUEST:
      return {
        ...state,
        verifySMS: {
          fetching: true,
        },
      };
    case actions.UPDATE_MFA_SMS_VERIFICATION_REQUEST:
      return {
        ...state,
        verifySMS: {
          fetching: false,
          ...action.payload,
        },
      };
    case actions.FETCH_MFA_SMS_VERIFICATION_CONFIRM:
      return {
        ...state,
        verifySMS: {
          ...state.verifySMS,
          fetching: true,
        },
      };
    case actions.FETCH_SAVE_CODE_PREFERENCE:
      return {
        ...state,
        codePreference: {
          fetching: true,
        },
      };
    default:
      return state;
  }
};

export default dispatchReducer;

import React, { Component } from "react";
import _ from "lodash";
import { Modal } from "../../components/layout/Modal";
import { Button, Input } from "../../components/widgets";
import { Notify } from "../../utils/notificationManager";
import PowerstationType from "../../components/powerstations/PowerstationType";
import "./CreatePowerstationModal.css";

class CreatePowerstationModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      type: "INDIVIDUAL_NODES",
      filters: null,
    };
  }

  savePowerstation = () => {
    let error = false;
    if (this.state.name === null || this.state.name.trim() === "") {
      this.setState({
        validationMessage: "You must enter a Virtual Power Plant name.",
      });
      error = true;
    }
    if (
      (this.state.type === "POSTCODE" && _.isEmpty(this.state.filters.postcodes)) ||
      (this.state.type === "STATE" && !this.state.filters.state)
    ) {
      this.setState({
        powerstationTypeError: `You must enter a value for ${
          this.state.type === "STATE" ? "state" : "postcode"
        }`,
      });
      error = true;
    }

    if (error) {
      Notify.error("Virtual Power Plant invalid", "Please all required fields are filled in");
      return;
    }

    if (this.state.type === "INDIVIDUAL_NODES") {
      this.props.fetchNewPowerstation("STATIC", this.state.name, [], null, null);
    } else {
      this.props.fetchNewPowerstation("DYNAMIC", this.state.name, [], null, this.state.filters);
    }
    this.props.closeModal();
  };

  handleNameOnChange = event => {
    const nextName = event.target.value;
    this.setState({ name: nextName });
    if (nextName !== null && nextName.trim() !== "") {
      this.setState({ validationMessage: null });
    }
  };

  handleTypeOnChange = value => {
    if (this.state.value !== value) {
      this.setState({
        type: value,
        filters: {
          postcodes: [],
          state: null,
        },
        powerstationTypeError: null,
      });
    }
  };

  handleFilterOnChange = value => {
    let filters;
    if (this.state.type === "POSTCODE") {
      filters = {
        postcodes: value.split(","),
        state: null,
      };
    }
    if (this.state.type === "STATE") {
      if (value) {
        filters = {
          postcodes: [],
          state: value,
        };
      } else {
        filters = {
          postcodes: [],
          state: null,
        };
      }
    }
    this.setState({
      filters: filters,
      powerstationTypeError: null,
    });
  };

  render() {
    return (
      <Modal style={{ minWidth: "900px" }}>
        <Modal.Header title="Create New Virtual Power Plant" />
        <Modal.Content>
          <div className="vpp-name-container">
            <Input
              style={{ fontSize: "1em", fontWeight: 300, width: "32rem" }}
              value={this.state.name}
              onChange={this.handleNameOnChange}
              error={this.state.validationMessage}
              placeholder="Virtual Power Plant name"
            />
            <span
              className={`vpp-type-container ${
                this.state.powerstationTypeError ? "powerstation-type-error" : ""
              }`}
            >
              <PowerstationType
                type={this.state.type}
                handleTypeOnChange={this.handleTypeOnChange}
                handleFilterOnChange={this.handleFilterOnChange}
                filters={this.state.filters}
                inModal={true}
                error={this.state.powerstationTypeError}
              />
            </span>
            {this.state.powerstationTypeError && this.state.type === "STATE" ? (
              <span className="error-message">{this.state.powerstationTypeError}</span>
            ) : null}
          </div>
        </Modal.Content>
        <Modal.Footer>
          <Button
            id="cancel-edit-powerstation"
            type="secondary"
            icon="times"
            onClick={this.props.closeModal}
          >
            Cancel
          </Button>
          <Button
            id="save-powerstation"
            type="primary"
            icon="check"
            onClick={this.savePowerstation}
          >
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default CreatePowerstationModal;

import React, { Component } from "react";
import _ from "lodash";
import { Link } from "react-router-dom";
import { Button, InfoBox, Tooltip, Toggle } from "../widgets";
import "./PowerstationDeploymentsList.css";
import FleetDeploymentsTableStatus from "../fleet/FleetDeploymentsTableStatus";
import { hasPermission } from "../../permissions";
import Permissions from "../../permissions";
import { processMonitoringState } from "../../redux/reducers/utils";
import { getAddressLine1, getAddressLine2 } from "../../utils/formatting";

class PowerstationDeploymentsList extends Component {
  render() {
    const deployments = this.props.deployments;

    return (
      <>
        {this.props.selectable ? (
          <div className="powerstation-deployments-list-selector-container">
            <div className="powerstation-deployments-list-selector">
              <div className="powerstation-deployments-list-widgets">
                <Button
                  id="powerstation-deployments-add-all"
                  type="link"
                  onClick={this.props.addAll}
                  disabled={this.props.bulkEditActionLoading}
                >
                  Add All
                </Button>{" "}
                /{" "}
                <Button
                  id="powerstation-deployments-add-current-page"
                  type="link"
                  onClick={this.props.addCurrentPage}
                  disabled={this.props.bulkEditActionLoading}
                >
                  Add Current Page
                </Button>{" "}
                /{" "}
                <Button
                  id="powerstation-deployments-remove-all"
                  type="link"
                  onClick={this.props.removeAll}
                  disabled={this.props.bulkEditActionLoading}
                >
                  Remove All
                </Button>{" "}
                /{" "}
                <Button
                  id="powerstation-deployments-remove-current-page"
                  type="link"
                  onClick={this.props.removeCurrentPage}
                  disabled={this.props.bulkEditActionLoading}
                >
                  Remove Current Page
                </Button>{" "}
                {this.props.bulkEditActionLoading ? (
                  <i className="fa fa-spinner fa-spin reposit-loading" ariaHidden="true" />
                ) : null}{" "}
              </div>
              <Tooltip medium id="powerstations.detail.includeControls" />
            </div>
            {this.props.buldEditError ? (
              <InfoBox type="error">
                <div className="error-message">{this.props.buldEditError}</div>
              </InfoBox>
            ) : null}
          </div>
        ) : null}
        {!deployments || deployments.length === 0 ? (
          <div className="fleet-table-no-results">
            {this.props.includedDeploymentCount
              ? "No nodes found for the current filter."
              : "No nodes have been added to this virtual power plant."}
          </div>
        ) : (
          <table className="fleet-table">
            <thead>
              <tr>
                {this.props.selectable ? <th>Included in VPP?</th> : null}
                <th>NMI / Serial Number</th>
                <th>Install Address</th>
                <th>Inverter Type</th>
                <th>Battery Type</th>
                <th>
                  Status <Tooltip id={"fleetStatus.list.status"} />
                </th>
              </tr>
            </thead>
            <tbody>
              {deployments.map(deployment => {
                return (
                  <tr>
                    {this.props.selectable ? (
                      <td>
                        <Toggle
                          readOnly={deployment.loading}
                          checked={deployment.included}
                          loading={deployment.loading}
                          textBefore="No"
                          textAfter="Yes"
                          onChange={() =>
                            this.props.onToggleDeploymentIncluded(
                              deployment.id,
                              deployment.included
                            )
                          }
                          size="small"
                        />
                      </td>
                    ) : null}
                    <td>
                      {hasPermission(this.props.userPermissions, Permissions.fleet.node.view) ? (
                        <Link to={`/fleet-monitoring/nodes/${deployment.id}`}>
                          {deployment.network.nmi}
                        </Link>
                      ) : (
                        <div>{deployment.network.nmi}</div>
                      )}
                      <div>{deployment.system.reposit.serialNumber}</div>
                    </td>
                    <td>
                      {getAddressLine1(deployment.address)}
                      <br />
                      {getAddressLine2(deployment.address)}
                    </td>
                    <td>
                      {_.isEmpty(deployment.system.inverter)
                        ? null
                        : deployment.system.inverter[0].brand}
                    </td>
                    <td>
                      {_.isEmpty(deployment.system.battery)
                        ? null
                        : deployment.system.battery[0].brand}
                    </td>
                    <td>
                      <FleetDeploymentsTableStatus
                        deployment={processMonitoringState(deployment)}
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
      </>
    );
  }
}

export default PowerstationDeploymentsList;

import React, { Component } from "react";
import "./TabbedContainer.css";
import AuthRequired from "../../containers/AuthRequired";
import ReactGA from "react-ga";

class TabbedContainer extends Component {
  constructor(props) {
    super(props);
    if (this.props.children) {
      const tab = this.getCurrentTab();
      this.state = {
        currentTab: tab.props.label,
      };
    }
  }

  static Tab = class extends Component {
    render() {
      return (
        <div className="tabbed-container-content" style={this.props.style}>
          {this.props.children}
        </div>
      );
    }
  };

  getCurrentTab = () => {
    // If only one tab is used then the children one be an array so we just grab the first and
    // only thing in the list
    if (Array.isArray(this.props.children)) {
      const tabNumber = this.props.initialTabNumber || 0;
      return this.props.children[tabNumber];
    } else {
      return this.props.children;
    }
  };

  findTabByLabel = label => {
    if (Array.isArray(this.props.children)) {
      return this.props.children.find(c => c.props.label === label);
    } else {
      return this.props.children;
    }
  };

  handleTabOnClick = tabLabel => {
    const currentTab = this.findTabByLabel(this.state.currentTab);
    if (currentTab.props.onDeactivate) {
      currentTab.props.onDeactivate();
    }

    this.setState({
      currentTab: tabLabel,
    });

    const nextTab = this.findTabByLabel(tabLabel);
    if (nextTab.props.onActivate) {
      nextTab.props.onActivate();
    }

    if (this.props.id) {
      const eventLabel = `tab-${this.props.id}:${tabLabel}`.toLowerCase();
      ReactGA.event({
        category: "Button",
        action: "Clicked Button",
        label: eventLabel,
      });
    }
  };

  render() {
    const currentTabContent = this.findTabByLabel(this.state.currentTab);

    // Ensure that even just one defined tab gets treated as an list of tabs so we can
    // keep the rendering code below consistent.
    let tabs = this.props.children;
    if (!Array.isArray(tabs)) {
      tabs = [tabs];
    }

    return (
      <div className="tabbed-container" style={this.props.style}>
        <div className="tabbed-container-tabs">
          {tabs.map(c => {
            if (c.props.permission) {
              return (
                <AuthRequired permission={c.props.permission}>
                  <div
                    key={c.props.label}
                    className={
                      "tabbed-container-tab" +
                      (c.props.label === this.state.currentTab ? " active" : "")
                    }
                    onClick={() => this.handleTabOnClick(c.props.label)}
                  >
                    {c.props.label}
                  </div>
                </AuthRequired>
              );
            } else {
              return (
                <div
                  key={c.props.label}
                  className={
                    "tabbed-container-tab" +
                    (c.props.label === this.state.currentTab ? " active" : "")
                  }
                  onClick={() => this.handleTabOnClick(c.props.label)}
                >
                  {c.props.label}
                </div>
              );
            }
          })}
        </div>
        {currentTabContent}
      </div>
    );
  }
}

export default TabbedContainer;

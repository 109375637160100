import React, { Component } from "react";
import moment from "moment-timezone";
import "./TimePicker.css";

class SelectDropdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showTimePicker: false,
    };
  }

  handleOnSelectedClick = () => {
    this.setState({
      showTimePicker: true,
    });
  };

  hideTimePicker = () => {
    this.setState({
      showTimePicker: false,
    });
  };

  registerCurrentValue = currentValueElement => {
    this.currentValueElement = currentValueElement;
  };

  handleTimeChange = adjustment => {
    const nextDateTime = adjustment();

    this.props.onChange(nextDateTime);
  };

  increaseHour = () => {
    let nextDateTime = this.props.dateTime.clone();
    nextDateTime.seconds(0);
    nextDateTime.add(1, "hour");
    return nextDateTime;
  };

  increaseMinute = () => {
    let nextDateTime = this.props.dateTime.clone();
    nextDateTime.seconds(0);
    nextDateTime.add(this.props.minuteStep, "minute");
    return nextDateTime;
  };

  decreaseHour = () => {
    let nextDateTime = this.props.dateTime.clone();
    nextDateTime.seconds(0);
    nextDateTime.subtract(1, "hour");
    return nextDateTime;
  };

  decreaseMinute = () => {
    let nextDateTime = this.props.dateTime.clone();
    nextDateTime.seconds(0);
    nextDateTime.subtract(this.props.minuteStep, "minute");
    return nextDateTime;
  };

  setNow = () => {
    const now = moment().tz(this.props.timezone);
    const nextMinutes = now.minutes() - (now.minutes() % this.props.minuteStep);
    let nextDateTime = now.clone();
    nextDateTime.seconds(0);
    nextDateTime.minutes(nextMinutes);
    this.props.onChange(nextDateTime);
  };

  formatTime = () => {
    let hours = this.props.dateTime.hours();
    hours = hours < 10 ? "0" + hours : hours;

    let minutes = this.props.dateTime.minutes();
    minutes = minutes < 10 ? "0" + minutes : minutes;

    return `${hours}:${minutes}`;
  };

  getOffset = () => {
    const duration = this.props.dateTime.diff(this.props.offsetFromDateTime) / 1000;

    let hours = Math.floor(duration / 3600);
    let minutes = Math.floor((duration - hours * 3600) / 60);

    if (hours && minutes) {
      return `+${hours}h${minutes}m`;
    } else if (hours) {
      return `+${hours}h`;
    } else if (minutes) {
      return `+${minutes}m`;
    } else {
      return "";
    }
  };

  render() {
    const { dateTime, placeholder, offsetFromDateTime, showNow } = this.props;

    const hours = dateTime.hours();
    const minutes = dateTime.minutes();

    return (
      <div
        className={"rp-widget time-picker" + (this.props.readOnly ? " ro" : "")}
        tabIndex="0"
        onBlur={this.hideTimePicker}
      >
        <span
          className="current-value"
          ref={this.registerCurrentValue}
          onClick={this.props.readOnly ? null : this.handleOnSelectedClick}
        >
          {hours !== null && hours !== undefined && minutes !== null && minutes !== undefined
            ? this.formatTime()
            : placeholder}
          {offsetFromDateTime !== null && offsetFromDateTime !== undefined ? (
            <span className="offset">({this.getOffset()})</span>
          ) : null}
        </span>
        {this.state.showTimePicker ? (
          <div className="time-picker-container">
            <div className="tip" />
            <div className="time-components-container">
              <div className="component controls-container">
                <span
                  className="fa fa-caret-up"
                  onClick={() => this.handleTimeChange(this.increaseHour)}
                />
                <span
                  className="fa fa-caret-up"
                  onClick={() => this.handleTimeChange(this.increaseMinute)}
                />
              </div>
              <div className="component current-value-edit">{this.formatTime()}</div>
              <div className="component controls-container">
                <span
                  className="fa fa-caret-down"
                  onClick={() => this.handleTimeChange(this.decreaseHour)}
                />
                <span
                  className="fa fa-caret-down"
                  onClick={() => this.handleTimeChange(this.decreaseMinute)}
                />
              </div>
              {offsetFromDateTime !== null && offsetFromDateTime !== undefined ? (
                <div className="component offset">{this.getOffset()}</div>
              ) : null}
              {showNow ? (
                <div className="component now link" onClick={this.setNow}>
                  now
                </div>
              ) : null}
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}

export default SelectDropdown;

import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Loading, Button } from "../../components/widgets";
import * as apiKeyActions from "../../redux/actions/apikeys";
import APIKeysTable from "../../components/user/APIKeysTable";
import ConfirmPasswordModal from "../../components/user/ConfirmPasswordModal";
import AddAPIKeyModal from "../../components/user/AddAPIKeyModal";
import { Modal } from "../../components/layout/Modal";
import "./APIKeysList.css";
import { Notify } from "../../utils/notificationManager";

const mapStateToProps = state => ({
  apiKeys: state.apiKeys,
});

const mapDispatchToProps = dispatch => {
  return {
    apiKeyActions: bindActionCreators(apiKeyActions, dispatch),
  };
};

class APIKeysList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showAddModal: false,
      keyToDelete: null,
      ipParseError: null,
      passwordRejected: false,
    };
  }

  componentDidMount = () => {
    this.props.apiKeyActions.fetchApiKeys();
  };

  componentWillUnmount = () => {
    this.props.apiKeyActions.clearApiKeys();
  };

  clearState = () => {
    this.setState({
      showAddModal: false,
      keyToDelete: null,
      ipParseError: null,
      passwordRejected: false,
    });
  };

  showError = (error, message) => {
    switch (error) {
      case "password_rejected":
        this.setState({
          passwordRejected: true,
        });
        break;
      case "ip_parse_failure":
        this.setState({
          ipParseError: message,
        });
        break;
      default:
        break;
    }
  };

  clearPasswordRejected = () => {
    this.setState({
      passwordRejected: false,
    });
  };

  clearErrors = () => {
    this.setState({
      ipParseError: null,
      passwordRejected: false,
    });
  };

  handleDeleteButtonClick = key => {
    this.setState({
      keyToDelete: key,
    });
  };

  handleDeleteConfirmationSubmitted = password => {
    this.props.apiKeyActions.revokeApiKey(
      password,
      this.state.keyToDelete.keyId,
      this.clearState,
      this.showError
    );
  };

  handleAddButtonClick = () => {
    this.setState({
      showAddModal: true,
    });
  };

  handleAddSubmitted = (password, name, ipWhitelist) => {
    this.props.apiKeyActions.addApiKey(
      password,
      name,
      ipWhitelist,
      this.clearState,
      this.showError
    );
  };

  handleWitnessed = () => {
    this.props.apiKeyActions.updateApiKeyWitnessed();
  };

  handleCopy = () => {
    Notify.success("Key Copied", "Your new API key has been copied to your clipboard.");
  };

  render() {
    const { apiKeys, updatePending, newKey } = this.props.apiKeys;

    const keyToDelete = this.state.keyToDelete;

    if (!apiKeys) {
      return (
        <div className="user-setting api-keys">
          <h4>API Keys</h4>
          <div className="api-keys-content">
            <Loading />
          </div>
        </div>
      );
    }

    const newKeyModal = newKey ? (
      <div className="new-api-key-container">
        <Modal>
          <Modal.Header title={newKey.keyName || newKey.keyId} />
          <Modal.Content>
            <div className="new-api-key-block">
              <span className="rp-widget textarea">
                <textarea readOnly={true}>{newKey.key}</textarea>
              </span>
              <CopyToClipboard text={newKey.key} onCopy={this.handleCopy}>
                <Button
                  type="secondary"
                  icon="clipboard"
                  className="copy-button"
                  data-clipboard-target="#new-api-key-value"
                  title="Copy key"
                />
              </CopyToClipboard>
            </div>
            <p>Make sure you store this somewhere safe – you won't be able to see it again!</p>
          </Modal.Content>
          <Modal.Footer>
            <Button
              id="api-key-modal-ok"
              type="primary"
              onClick={this.handleWitnessed}
              icon="check"
            >
              OK
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    ) : null;

    return (
      <div className="user-setting api-keys">
        <h4>API Keys</h4>
        <div className="api-keys-content">
          <APIKeysTable
            apiKeys={apiKeys}
            onDelete={this.handleDeleteButtonClick}
            onAdd={this.handleAddButtonClick}
          />
          {keyToDelete ? (
            <ConfirmPasswordModal
              onSubmit={this.handleDeleteConfirmationSubmitted}
              onCancel={this.clearState}
              confirming={updatePending}
              passwordRejected={this.state.passwordRejected}
              title={"Confirm Removal"}
              confirmText={"Remove"}
            >
              <p>
                Confirm your password to remove '{keyToDelete.keyName || keyToDelete.keyId}'. This
                action cannot be undone.
              </p>
            </ConfirmPasswordModal>
          ) : null}
          {this.state.showAddModal ? (
            <AddAPIKeyModal
              onSubmit={this.handleAddSubmitted}
              onCancel={this.clearState}
              onPasswordCancel={this.clearPasswordRejected}
              confirming={updatePending}
              ipParseError={this.state.ipParseError}
              passwordRejected={this.state.passwordRejected}
            />
          ) : null}
          {newKeyModal}
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(APIKeysList);

import React, { Component } from "react";
import loading from "./loading.gif";
import loadingAlt from "./loading_alt.gif";
import "./Loading.css";

class Loading extends Component {
  render() {
    if (this.props.horizontal) {
      return (
        <div className="loading-horizontal">
          <div className="indeterminate" />
        </div>
      );
    } else {
      return (
        <div className={`${this.props.className ? this.props.className : "loading-spinner"}`}>
          <img
            src={this.props.inverse ? loadingAlt : loading}
            width={this.props.width}
            height={this.props.height}
            alt="loading"
            title="loading"
          />
        </div>
      );
    }
  }
}

Loading.defaultProps = {
  width: "54",
  height: "54",
};

export default Loading;

import React, { Component } from "react";
import Button from "../widgets/Button";
import { Modal } from "../layout/Modal";
import InverterSystemForm from "./InverterSystemForm";
import InverterSystemIllustration from "./InverterSystemIllustration";
import "./ConfigureInvertersModalContent.css";
import { Loading } from "../widgets";

class ConfigureInvertersModalContent extends Component {
  constructor(props) {
    super(props);
    const uids = Object.keys(props.inverterSystems);
    this.state = {
      inverterSubsystemUid: uids.length > 0 ? uids[0] : null,
    };
  }

  static getDerivedStateFromProps = (props, state) => {
    if (!props.inverterSystems.hasOwnProperty(state.inverterSubsystemUid)) {
      const uids = Object.keys(props.inverterSystems);
      return { inverterSubsystemUid: uids.length > 0 ? uids[uids.length - 1] : null };
    } else {
      return null;
    }
  };

  handleInverterSubsystemSelected = uid => {
    this.setState({ inverterSubsystemUid: uid });
  };

  handleInverterSystemAdded = () => {
    this.setState({ inverterSubsystemUid: null });
    this.props.onInverterSystemAdded();
  };

  handleInverterChanged = (uid, inv) => {
    this.props.onInverterChanged(uid, inv);
  };

  handlePvChanged = (uid, pv) => {
    this.props.onPvChanged(uid, pv);
  };

  handlePvPhasesChanged = (uid, phases) => {
    this.props.onPvPhasesChanged(uid, phases);
  };

  handleBatteryChanged = (uid, battery) => {
    this.props.onBatteryChanged(uid, battery);
  };

  handleInverterSystemRemoved = uid => {
    this.props.onInverterSystemRemoved(uid);
  };

  handleBack = () => {
    this.props.onBack();
  };

  handleNext = () => {
    this.props.onNext();
  };

  render() {
    const { inverterSubsystemUid } = this.state;

    const {
      constraintsLoading,
      kitLoading: loading,
      inverterSystems,
      inverterTypes,
      batteryTypes,
    } = this.props;

    if (constraintsLoading) {
      return (
        <>
          <Modal.Header
            className="kit-design-header no-border"
            title="Configure a Reposit Kit &mdash; Configure Inverters"
          />
          <Modal.Content>
            <div className="configure-inverters-loading">
              <Loading />
              <div>Loading inverter constraints...</div>
            </div>
          </Modal.Content>
          <Modal.Footer>
            <Button type="secondary" onClick={this.handleBack} icon="chevron-left">
              Back
            </Button>
            <Button type="primary" onClick={this.handleNext} icon="chevron-right" loading={true}>
              Next
            </Button>
          </Modal.Footer>
        </>
      );
    }

    const subsystem = inverterSubsystemUid && inverterSystems[inverterSubsystemUid];

    const subsystemForm = subsystem ? (
      <InverterSystemForm
        loading={loading}
        index={Object.keys(inverterSystems).indexOf(inverterSubsystemUid) + 1}
        inverterSystem={subsystem}
        batteryTypes={batteryTypes}
        inverterTypes={inverterTypes}
        onInverterChange={selected => this.handleInverterChanged(inverterSubsystemUid, selected)}
        onPvChanged={selected => this.handlePvChanged(inverterSubsystemUid, selected)}
        onPvPhasesChanged={selected => this.handlePvPhasesChanged(inverterSubsystemUid, selected)}
        onBatteryChange={selected => this.handleBatteryChanged(inverterSubsystemUid, selected)}
      />
    ) : (
      <div className="inverter-system-empty">
        <h4>Reposit First</h4>
        <div className="reposit-first-explanation">
          This configuration has no additional options.
        </div>
      </div>
    );

    const inverterSystemEntries = Object.entries(inverterSystems);

    const installerIllustrations =
      inverterSystemEntries.length > 0 ? (
        inverterSystemEntries.map(entry => {
          const [uid, system] = entry;
          return (
            <InverterSystemIllustration
              key={uid}
              loading={loading}
              inverterSystem={system}
              selected={uid === inverterSubsystemUid}
              inverterTypes={inverterTypes}
              batteryTypes={batteryTypes}
              onSelected={() => this.handleInverterSubsystemSelected(uid)}
              onRemoved={() => this.handleInverterSystemRemoved(uid)}
            />
          );
        })
      ) : (
        <span className="no-inverter-indicator">No Inverter</span>
      );

    return (
      <>
        <Modal.Header
          className="kit-design-header no-border"
          title="Configure a Reposit Kit &mdash; Configure Inverters"
        />
        <Modal.Content>
          <div className="kit-design-form">
            <div className="illustration-bar">
              <div className="illustrations">{installerIllustrations}</div>
              <div className="inverter-system-add">
                <Button
                  type="link"
                  onClick={this.handleInverterSystemAdded}
                  icon="plus-circle"
                  disabled={loading}
                />
              </div>
            </div>
            <div className="kit-design-form-section">
              <div className="inverter-system-container">{subsystemForm}</div>
            </div>
          </div>
        </Modal.Content>
        <Modal.Footer>
          <Button type="secondary" onClick={this.handleBack} icon="chevron-left" disabled={loading}>
            Back
          </Button>
          <Button type="primary" onClick={this.handleNext} icon="chevron-right" loading={loading}>
            Next
          </Button>
        </Modal.Footer>
      </>
    );
  }
}

export default ConfigureInvertersModalContent;

import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Header from "../containers/layout/Header";
import * as userActions from "../redux/actions/user";
import { ModalConfirm } from "../components/layout/Modal";
import "./NavLayout.css";

const mapStateToProps = state => ({
  account: state.user.account,
  ui: state.ui,
});

const mapDispatchToProps = dispatch => {
  return {
    userActions: bindActionCreators(userActions, dispatch),
  };
};

export class NavLayout extends Component {
  logout = () => {
    this.props.userActions.logout();
  };

  render() {
    return (
      <>
        <div className={"root" + (this.props.ui.scrollEnabled ? " scroll-enabled" : "")}>
          <Header
            userFullName={this.props.account.name}
            handleLogout={this.logout}
            orgName={this.props.account.suborg_name}
          />
          {this.props.ui.confirm ? (
            <ModalConfirm
              id={this.props.ui.confirm.id}
              message={this.props.ui.confirm.message}
              resolve={window.fleetConfirm.resolve}
              reject={window.fleetConfirm.reject}
              // route={this.findLeafRoute(this.props)}
            />
          ) : null}
          {this.props.children}
        </div>
        <div id="fullscreen-dashboard" className="fullscreen-dashboard" />
        <div id="modal-container" />
      </>
    );
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NavLayout));

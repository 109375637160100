export const FETCH_FLEET_DEPLOYMENTS = "FETCH_FLEET_DEPLOYMENTS";
export const UPDATE_FLEET_DEPLOYMENTS = "UPDATE_FLEET_DEPLOYMENTS";
export const FETCH_ACTIVE_FLEET_DEPLOYMENT = "FETCH_ACTIVE_FLEET_DEPLOYMENT";
export const UPDATE_ACTIVE_FLEET_DEPLOYMENT = "UPDATE_ACTIVE_FLEET_DEPLOYMENT";
export const CLEAR_ACTIVE_FLEET_DEPLOYMENT = "CLEAR_ACTIVE_FLEET_DEPLOYMENT";

export const SAVE_DEPLOYMENT_SITE_LABEL = "SAVE_DEPLOYMENT_SITE_LABEL";
export const UPDATE_DEPLOYMENT_SITE_LABEL = "UPDATE_DEPLOYMENT_SITE_LABEL";

export const FETCH_ACTIVE_DEPLOYMENT_HISTORY = "FETCH_ACTIVE_DEPLOYMENT_HISTORY";
export const UPDATE_ACTIVE_DEPLOYMENT_HISTORY = "UPDATE_ACTIVE_DEPLOYMENT_HISTORY";
export const CLEAR_ACTIVE_DEPLOYMENT_HISTORY = "CLEAR_ACTIVE_DEPLOYMENT_HISTORY";

export const UPDATE_FLEET_FILTER = "UPDATE_FLEET_FILTER";
export const CLEAR_FLEET_FILTER = "CLEAR_FLEET_FILTER";
export const UPDATE_DAILY_DATA = "UPDATE_DAILY_DATA";
export const UPDATE_CUSTOMER_DATA_SUBMETER = "UPDATE_CUSTOMER_DATA_SUBMETER";

export const GET_PORTAL_CREDENTIALS = "GET_PORTAL_CREDENTIALS";
export const UPDATE_PORTAL_CREDENTIALS = "UPDATE_PORTAL_CREDENTIALS";
export const PORTAL_CREDENTIALS_ERROR = "PORTAL_CREDENTIALS_ERROR";
export const SET_LOADING_FLEET = "SET_LOADING_FLEET";
export const FETCH_MAP_DEPLOYMENTS = "FETCH_MAP_DEPLOYMENTS";
export const UPDATE_MAP_DEPLOYMENTS = "UPDATE_MAP_DEPLOYMENTS";

export function fetchFleetDeployments(limit, offset, filter) {
  let endpoint = `/v2/nodes?`;
  if (filter) {
    endpoint += `f=${encodeURIComponent(filter)}&limit=${limit}&offset=${offset}`;
  } else {
    endpoint += `limit=${limit}&offset=${offset}`;
  }
  return {
    type: FETCH_FLEET_DEPLOYMENTS,
    meta: {
      endpoint,
      success: updateFleetDeployments,
      errorMessage: "Failed to load fleet management data.",
    },
  };
}

function updateFleetDeployments(fleet) {
  return {
    type: UPDATE_FLEET_DEPLOYMENTS,
    payload: {
      deployments: fleet.deployments,
      count: fleet.total,
    },
  };
}

export function setLoading(loadingMap) {
  return {
    type: SET_LOADING_FLEET,
    payload: {
      loadingMap,
    },
  };
}

export function fetchMapDeployments(filter) {
  let endpoint = "/dashboard/map";
  if (filter) {
    endpoint += `?f=${encodeURIComponent(filter)}`;
  }
  return {
    type: FETCH_MAP_DEPLOYMENTS,
    meta: {
      endpoint,
      success: updateMapDeployments,
      errorMessage: "Failed to load fleet map nodes.",
    },
  };
}

function updateMapDeployments(response) {
  return {
    type: UPDATE_MAP_DEPLOYMENTS,
    payload: {
      deployments: response.deployments,
    },
  };
}

export function fetchActiveFleetDeployment(deploymentUid) {
  return {
    type: FETCH_ACTIVE_FLEET_DEPLOYMENT,
    meta: {
      // change the postpend of this endpoint to installId
      endpoint: `/api/nodes/${deploymentUid}/detail`,
      success: updateActiveFleetDeployment,
      errorMessage: "Failed to load data for this node.",
    },
    payload: {
      deploymentUid,
    },
  };
}

function updateActiveFleetDeployment(deployment, payload) {
  return {
    type: UPDATE_ACTIVE_FLEET_DEPLOYMENT,
    payload: {
      deployment: deployment.data,
      deploymentUid: payload.deploymentUid,
    },
  };
}

export const saveDeploymentSiteLabel = (deploymentUid, siteLabel) => ({
  type: SAVE_DEPLOYMENT_SITE_LABEL,
  meta: {
    endpoint: `/api/nodes/${deploymentUid}/sitelabel`,
    method: "POST",
    body: {
      siteLabel,
    },
    success: updateDeploymentSiteLabel,
    errorMessage: "Failed to save site label.",
  },
});

export const updateDeploymentSiteLabel = siteLabel => ({
  type: UPDATE_DEPLOYMENT_SITE_LABEL,
  payload: siteLabel.data,
});

export function clearActiveFleetDeployment() {
  return {
    type: CLEAR_ACTIVE_FLEET_DEPLOYMENT,
  };
}

export function fetchActiveDeploymentHistory(deploymentUid, limit, offset) {
  return {
    type: FETCH_ACTIVE_DEPLOYMENT_HISTORY,
    meta: {
      endpoint: `/api/nodes/${deploymentUid}/events?limit=${limit}&offset=${offset}`,
      success: updateActiveDeploymentHistory,
      errorMessage: "Failed to load node event log.",
    },
  };
}

function updateActiveDeploymentHistory(response) {
  return {
    type: UPDATE_ACTIVE_DEPLOYMENT_HISTORY,
    payload: {
      deploymentHistory: response.data,
      eventLogPageMeta: response.meta,
    },
  };
}

export function clearActiveDeploymentHistory() {
  return {
    type: CLEAR_ACTIVE_DEPLOYMENT_HISTORY,
  };
}

export function getInverterPortalCredentials(deploymentId, inverterId) {
  return {
    type: GET_PORTAL_CREDENTIALS,
    meta: {
      endpoint: "/auth/portal_token?deployment=" + deploymentId + "&inverter=" + inverterId,
      success: updateInverterPortalCredentials,
      errorMessage: "Failed to access inverter portal.",
    },
  };
}

function updateInverterPortalCredentials(response) {
  if (response.status === "ERROR") {
    return {
      type: PORTAL_CREDENTIALS_ERROR,
      payload: {
        portalErrorMessage: response.message,
      },
    };
  }

  let jwtPayload = JSON.parse(window.atob(response.inverterPortalToken.split(".")[1]));
  return {
    type: UPDATE_PORTAL_CREDENTIALS,
    payload: {
      portalAccessToken: response.inverterPortalToken,
      portalInfo: jwtPayload,
    },
  };
}

import React, { Component } from "react";
import Loading from "../../components/widgets/Loading";
import { getDateAsString } from "../../components/widgets/Moment";
import { Accordion, AccordionItem } from "react-sanfona";
import { fetchCSVDataExportDetails } from "../../redux/actions/analysis";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router";
import DataExportSummaryItem from "../../components/analysis/DataExportSummaryItem";
import "./AnalysisDataExportsList.css";
import { ExportItemHeader } from "../../components/analysis/ExportItemHeader";
import PagingControl from "../../components/widgets/PagingControl";

const mapStateToProps = state => ({
  exportDetails: state.analysis.exportDetails,
  user: state.user,
});

const mapDispatchToProps = dispatch => {
  return {
    fetchExportDetails: bindActionCreators(fetchCSVDataExportDetails, dispatch),
  };
};

class AnalysisDataExportsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pendingUpdates: [],
    };
  }

  getDataExportDetails = task => {
    this.props.fetchExportDetails(task.task_id);
  };

  componentWillReceiveProps(nextProps) {
    if (this.props.exports && nextProps.exports && nextProps.exports.data) {
      for (let pendingUpdateUid of this.state.pendingUpdates) {
        let nextExport = nextProps.exports.data.find(e => e.task_id === pendingUpdateUid);
        if (nextExport && nextExport.status === "SUCCESS") {
          this.props.fetchExportDetails(pendingUpdateUid);
          this.setState({
            pendingUpdates: this.state.pendingUpdates.filter(x => x !== pendingUpdateUid),
          });
        }
      }

      // mark any in progress tasks for update i.e. fetch their respective files as soon as they're done
      // rather than wait for the user to refresh the list component.
      if (nextProps.exports && this.props.exports) {
        if (nextProps.exports.data !== this.props.exports.data) {
          for (let nextExportTask of nextProps.exports.data) {
            // only tasks that are in progress or pending and not already marked for update
            if (
              ["PROGRESS", "PENDING"].includes(nextExportTask.status) &&
              !this.state.pendingUpdates.includes(nextExportTask.task_id)
            ) {
              this.setState({
                pendingUpdates: [...this.state.pendingUpdates, nextExportTask.task_id],
              });
            }
          }
        }
      }
    }
  }

  updateUrl = (limit, offset) => {
    const url = `${this.props.location.pathname}?limit=${limit}&offset=${offset}`;
    this.props.history.push(url);
  };

  render() {
    if (this.props.noExports) {
      return <div className="fleet-table-no-results">No Data Exports have been found.</div>;
    }

    if (!this.props.exports) {
      return <Loading />;
    }
    const { exports, timezone } = this.props;
    return (
      <div className="analysis-exports-container">
        <div className="analysis-exports-header">
          <span className="date">Date</span>
          <span className="type">Type</span>
          <span className="details">Details</span>
          <span className="status">Status</span>
        </div>
        <Accordion allowMultiple={true}>
          {(exports.data || []).map(item => {
            const date = new Date(parseInt(item.ts * 1000, 10));
            const dateString = getDateAsString(date, timezone, "DD MMMM YYYY, HH:mm");
            const taskInfo = item.task_info;
            return (
              <AccordionItem
                title={
                  <ExportItemHeader
                    date={dateString}
                    status={item.status}
                    metrics={item.metrics}
                    taskType={item.task_type}
                    powerstation={taskInfo ? taskInfo.powerstation : null}
                    start={taskInfo ? taskInfo.start : null}
                    end={taskInfo ? taskInfo.end : null}
                    taskInfo={taskInfo}
                    timezone={this.props.timezone}
                    suborgType={this.props.user.account.suborg_type}
                  />
                }
                slug={item.reference_id ? item.reference_id : item.task_id}
                key={item.reference_id ? item.reference_id : item.task_id}
                className="export-item"
                expandedClassName="export-item-expanded"
                onExpand={() => this.getDataExportDetails(item)}
              >
                <DataExportSummaryItem
                  details={this.props.exportDetails}
                  metrics={item.metrics}
                  taskType={item.task_type}
                  taskId={item.task_id}
                  submeters={item.task_info.submeters}
                  percentageComplete={Math.round(item.progress * 1000) / 1000}
                  status={item.status}
                  timeRemaining={item.time_remaining}
                  suborgType={this.props.user.account.suborg_type}
                />
              </AccordionItem>
            );
          })}
        </Accordion>
        <PagingControl
          limit={this.props.limit}
          offset={this.props.offset}
          totalItems={exports.count}
          itemName="task"
          onPageChange={(limit, offset) => this.updateUrl(limit, offset)}
          orientation="up"
        />
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AnalysisDataExportsList));

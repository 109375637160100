import React, { Component } from "react";
import "./InfoBox.css";

class InfoBox extends Component {
  render() {
    let title;
    switch (this.props.type) {
      case "warning":
        title = "Warning";
        break;
      case "error":
        title = "Error";
        break;
      default:
        title = "Information";
        break;
    }

    return (
      <div
        className={"rp-widget infobox " + (this.props.type ? this.props.type : "info")}
        style={this.props.style}
      >
        <span className="title">{title}: </span>
        {this.props.children ? this.props.children : this.props.message}
      </div>
    );
  }
}

export default InfoBox;

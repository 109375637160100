import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import Page from "../../components/layout/Page";
import * as customerActions from "../../redux/actions/customers";
import Tariff from "../../components/customers/Tariff";
import { Loading } from "../../components/widgets";
import { applicableDaysToList } from "../../utils/tariff";
import Permissions from "../../permissions";

const mapStateToProps = state => ({
  user: state.user,
  customers: state.customers,
});

const mapDispatchToProps = dispatch => {
  return {
    customerActions: bindActionCreators(customerActions, dispatch),
  };
};

class TariffEdit extends Component {
  componentDidMount = () => {
    // Refresh the tariff list for duplicate name/plan code validation.
    this.props.customerActions.fetchAllTariffs();
  };

  componentWillUnmount = () => {
    this.props.customerActions.clearActiveTariff();
  };

  componentWillReceiveProps = nextProps => {
    if (
      this.props.customers.tariff.activeTariff !== nextProps.customers.tariff.activeTariff &&
      nextProps.customers.tariff.activeTariff
    ) {
      const planCode = nextProps.customers.tariff.activeTariff.planCode;
      this.props.history.push("/customers/plans/" + encodeURIComponent(planCode));
    }

    const planCode = decodeURIComponent(nextProps.match.params.planCode);
    if (
      nextProps.match.params.planCode &&
      nextProps.customers.tariff.tariffs &&
      !this.props.customers.tariff.activeTariff
    ) {
      const tariff = nextProps.customers.tariff.tariffs.find(t => t.planCode === planCode);
      this.props.customerActions.fetchTariff(tariff.uid);
    }
  };

  translateTariffForView = tariff => {
    const version = tariff.versions.find(v => v.latest);

    let rates = [],
      periods = [];
    for (let i in version.rates) {
      let rate = version.rates[i];
      if (rate.periods.length === 0) {
        rate.isBaseRate = true;
      }
      rates.push(rate);
      for (let j in rate.periods) {
        const period = rate.periods[j];
        periods.push({
          ...rate.periods[j],
          rate: rate.label,
          applicableDays: {
            mon: period.applicableDays.includes("MON"),
            tue: period.applicableDays.includes("TUE"),
            wed: period.applicableDays.includes("WED"),
            thu: period.applicableDays.includes("THU"),
            fri: period.applicableDays.includes("FRI"),
            sat: period.applicableDays.includes("SAT"),
            sun: period.applicableDays.includes("SUN"),
            publicHolidays: period.applicableDays.includes("PUBLIC_HOLIDAYS"),
          },
        });
      }
    }

    rates.sort((a, b) => {
      if (a.isBaseRate && !b.isBaseRate) return -1;
      if (a.isBaseRate && b.isBaseRate) return 0;
      return 1;
    });

    let demandRates = [],
      demandPeriods = [];
    for (let i in version.demandRates) {
      let demandRate = version.demandRates[i];
      demandRates.push(demandRate);
      for (let j in demandRate.periods) {
        const period = demandRate.periods[j];
        demandPeriods.push({
          ...demandRate.periods[j],
          rate: demandRate.label,
          applicableDays: {
            mon: period.applicableDays.includes("MON"),
            tue: period.applicableDays.includes("TUE"),
            wed: period.applicableDays.includes("WED"),
            thu: period.applicableDays.includes("THU"),
            fri: period.applicableDays.includes("FRI"),
            sat: period.applicableDays.includes("SAT"),
            sun: period.applicableDays.includes("SUN"),
            publicHolidays: period.applicableDays.includes("PUBLIC_HOLIDAYS"),
          },
        });
      }
    }

    return {
      ...tariff,
      dailyCharge: version.dailyCharge,
      feedInTariff: version.feedInTariff,
      rates: rates,
      periods: periods.length > 0 ? periods : null,
      demandRates: demandRates,
      demandPeriods: demandPeriods,
    };
  };

  handleSave = tariff => {
    const new_tariff = {
      uid: tariff.uid,
      name: tariff.name,
      planCode: tariff.planCode,
      description: tariff.description,
      tariffType: tariff.tariffType,
      operationalNetworkUid: tariff.operationalNetworkUid,
      startDate: tariff.startDate.format("YYYY-MM-DD"),
      resetPeriod: tariff.resetPeriod,
      dailyCharge: tariff.dailyCharge,
      feedInTariff: tariff.feedInTariff,
      rates: tariff.rates.map(r => ({
        ...r,
        periods: tariff.periods
          ? tariff.periods
              .filter(p => p.rate === r.label)
              .map(p => ({
                ...p,
                applicableDays: applicableDaysToList(p.applicableDays),
              }))
          : [],
      })),
      demandRates: tariff.demandRates
        ? tariff.demandRates.map(r => ({
            ...r,
            periods: tariff.demandPeriods
              ? tariff.demandPeriods
                  .filter(p => p.rate === r.label)
                  .map(p => ({
                    ...p,
                    applicableDays: applicableDaysToList(p.applicableDays),
                  }))
              : [],
          }))
        : [],
    };

    this.props.customerActions.fetchSaveExistingTariff(new_tariff);
  };

  handleOnCancel = () => {
    const planCode = this.props.customers.tariff.activeTariff.planCode;
    this.props.customerActions.clearActiveTariff();
    const tariff = this.props.customers.tariff.tariffs.find(t => t.planCode === planCode);
    this.props.customerActions.fetchTariff(tariff.uid);

    this.props.history.push("/customers/plans/" + encodeURIComponent(planCode));
  };

  render() {
    if (this.props.customers.tariff.activeTariff === null) {
      return <Loading />;
    }

    const tariff = this.translateTariffForView(this.props.customers.tariff.activeTariff);

    return (
      <Page permission={Permissions.fleet.customerInformation.plans.any}>
        <Tariff
          title={this.props.customers.tariff.activeTariff.name}
          onSave={this.handleSave}
          onCancel={this.handleOnCancel}
          isSaving={this.props.customers.tariff.savingTariff}
          readOnly={!this.props.tariffEditable}
          fetchOperationalNetworks={this.props.customerActions.fetchOperationalNetworks}
          operationalNetworks={this.props.customers.tariff.operationalNetworks}
          tariff={tariff}
          tariffErrors={this.props.customers.tariff.serverErrors}
          allTariffs={this.props.customers.tariff.tariffs}
          timezone={this.props.user.account.timezone}
        />
      </Page>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(TariffEdit));

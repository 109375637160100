import React, { Component } from "react";
import "./Toggle.css";

class Toggle extends Component {
  constructor(props) {
    super(props);

    this.state = {
      checkboxId: `t_${Math.round(Math.random() * 1e10)}`,
    };
  }

  render() {
    return (
      <div
        className={`rp-widget toggle${this.props.readOnly ? " ro" : ""}${
          this.props.size === "small" ? " small" : ""
        }`}
      >
        {this.props.textBefore ? (
          <div className="toggle-before-text">{this.props.textBefore}</div>
        ) : null}
        <input
          {...this.props}
          id={this.state.checkboxId}
          type="checkbox"
          disabled={this.props.readOnly ? this.props.readOnly : false}
        />
        <label htmlFor={this.state.checkboxId}>
          <div className="switch" />
        </label>
        {this.props.textAfter ? (
          <div className="toggle-after-text">{this.props.textAfter}</div>
        ) : null}
        {this.props.loading ? (
          <div className="loading-icon">
            <i className="fa fa-spinner fa-spin reposit-loading" ariaHidden="true" />
          </div>
        ) : (
          <div className="not-loading" />
        )}
      </div>
    );
  }
}

export default Toggle;

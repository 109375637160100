import React, { Component } from "react";
import { Provider } from "react-redux";
import createHistory from "history/createBrowserHistory";
import NotificationSystem from "react-notification-system";
import { IntlProvider } from "react-intl";
import AuthRouter from "../routes";
import { notificationManagerInstance, notificationStyles } from "../utils/notificationManager";
import configureStore from "../redux/configureStore";
import ReactGA from "react-ga";
import CacheBuster from "../components/cachebuster/CacheBuster";
import packageJson from "../../package.json";
global.appVersion = packageJson.version;

export const store = configureStore({});

const intlFormats = {
  date: {
    short: {
      day: "numeric",
      month: "long",
    },
  },
  time: {
    short: {
      hour: "2-digit",
      minute: "2-digit",
    },
  },
};

ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_KEY);

export const history = createHistory();

history.listen((location, action) => {
  ReactGA.set({ page: location.pathname });
  ReactGA.pageview(location.pathname);
});

export default class Root extends Component {
  notificationSystem = React.createRef();
  componentDidMount() {
    notificationManagerInstance.setNotificationSystem(this.notificationSystem);
  }

  render() {
    return (
      <CacheBuster>
        {({ loading, isLatestVersion, refreshCacheAndReload }) => {
          if (loading) return null;
          if (!loading && !isLatestVersion) {
            refreshCacheAndReload();
          }
          return (
            <Provider store={store}>
              <IntlProvider locale="en-AU" formats={intlFormats}>
                <span>
                  <NotificationSystem ref={this.notificationSystem} style={notificationStyles} />
                  <AuthRouter history={history} />
                </span>
              </IntlProvider>
            </Provider>
          );
        }}
      </CacheBuster>
    );
  }
}

import { history } from "../../containers/Root";
import { Notify } from "../../utils/notificationManager";

export const FETCH_DISPATCHES = "FETCH_DISPATCHES";
export const UPDATE_DISPATCHES = "UPDATE_DISPATCHES";
export const FETCH_TRADING_POWERSTATIONS = "FETCH_TRADING_POWERSTATIONS";
export const UPDATE_TRADING_POWERSTATIONS = "UPDATE_TRADING_POWERSTATIONS";
export const FETCH_ACTIVE_DISPATCH = "FETCH_DISPATCH";
export const UPDATE_ACTIVE_DISPATCH = "UPDATE_ACTIVE_DISPATCH";
export const CLEAR_ACTIVE_DISPATCH = "CLEAR_ACTIVE_DISPATCH";
export const UPDATE_DISPATCH_RESPONSE = "UPDATE_DISPATCH_RESPONSE";
export const FETCH_DISPATCH_RESPONSE = "FETCH_DISPATCH_RESPONSE";
export const FETCH_DISPATCH_PREDICTION = "FETCH_DISPATCH_PREDICTION";
export const UPDATE_DISPATCH_PREDICTION = "UPDATE_DISPATCH_PREDICTION";
export const FETCH_NEW_DISPATCH_CONSTRAINTS = "FETCH_NEW_DISPATCH_CONSTRAINTS";
export const UPDATE_NEW_DISPATCH_CONSTRAINTS = "UPDATE_NEW_DISPATCH_CONSTRAINTS";
export const FETCH_NEW_DISPATCH_PREDICTIONS = "FETCH_NEW_DISPATCH_PREDICTIONS";
export const UPDATE_NEW_DISPATCH_PREDICTIONS = "UPDATE_NEW_DISPATCH_PREDICTIONS";
export const FETCH_NEW_DISPATCH_PRICE = "FETCH_NEW_DISPATCH_PRICE";
export const UPDATE_DISPATCH_PRICE = "UPDATE_DISPATCH_PRICE";
export const ERROR_DISPATCH_PRICE = "ERROR_DISPATCH_PRICE";
export const FETCH_TRADING_PRICES = "FETCH_TRADING_PRICES";
export const UPDATE_TRADING_PRICES = "UPDATE_TRADING_PRICES";
export const ERROR_TRADING_PRICES = "ERROR_TRADING_PRICES";
export const CLEAR_NEW_DISPATCH_GRAPH_DATA = "CLEAR_NEW_DISPATCH_GRAPH_DATA";
export const EXECUTE_DISPATCH = "EXECUTE_DISPATCH";
export const UPDATE_EXECUTE_DISPATCH = "UPDATE_EXECUTE_DISPATCH";
export const FETCH_DISPATCH_REMAINING_CHARGE = "FETCH_DISPATCH_REMAINING_CHARGE";
export const UPDATE_DISPATCH_REMAINING_CHARGE = "UPDATE_DISPATCH_REMAINING_CHARGE";
export const FETCH_DISPATCH_COST = "FETCH_DISPATCH_COST";
export const UPDATE_DISPATCH_COST = "UPDATE_DISPATCH_COST";
export const FETCH_PREVIOUS_DISPATCH_MESSAGES = "FETCH_PREVIOUS_DISPATCH_MESSAGES";
export const UPDATE_PREVIOUS_DISPATCH_MESSAGES = "UPDATE_PREVIOUS_DISPATCH_MESSAGES";
export const CLEAR_PREVIOUS_DISPATCH_MESSAGES = "CLEAR_PREVIOUS_DISPATCH_MESSAGES";

export function fetchDispatches(limit, offset) {
  return {
    type: FETCH_DISPATCHES,
    meta: {
      endpoint: `/api/dispatches?limit=${limit}&offset=${offset}`,
      success: updateDispatches,
      errorMessage: "Failed to load trade requests.",
    },
  };
}

function updateDispatches(response) {
  return {
    type: UPDATE_DISPATCHES,
    payload: {
      dispatches: response.data,
      dispatchesPageMeta: response.meta,
    },
  };
}

export function fetchPowerstations(activeStationId) {
  return {
    type: FETCH_TRADING_POWERSTATIONS,
    meta: {
      endpoint: "/api/powerstations",
      success: updatePowerstations,
      errorMessage: "Failed to load Virtual Power Plant.",
    },
  };
}

function updatePowerstations(powerstations) {
  return {
    type: UPDATE_TRADING_POWERSTATIONS,
    payload: {
      powerstations: powerstations.data,
    },
  };
}

// the flushExisting bool should be set to true if you want to remove the currently loaded active dispatch from the store.
// In cases when you are auto-refreshing a dispatch you will want to set this to false.
export function fetchActiveDispatch(dispatchId) {
  return {
    type: FETCH_ACTIVE_DISPATCH,
    meta: {
      endpoint: "/api/dispatches/" + dispatchId,
      success: updateActiveDispatch,
      errorMessage: "Failed to load dispatch.",
    },
  };
}

function updateActiveDispatch(response) {
  return {
    type: UPDATE_ACTIVE_DISPATCH,
    payload: {
      dispatch: response.data,
    },
  };
}

export function clearActiveDispatch() {
  return {
    type: CLEAR_ACTIVE_DISPATCH,
  };
}

export function fetchDispatchPrediction(dispatchId) {
  return {
    type: FETCH_DISPATCH_PREDICTION,
    meta: {
      endpoint: "/api/dispatches/" + dispatchId + "/prediction",
      success: updateDispatchPrediction,
      errorMessage: "Failed to load dispatch prediction.",
    },
  };
}

function updateDispatchPrediction(response) {
  return {
    type: UPDATE_DISPATCH_PREDICTION,
    payload: {
      prediction: response.data,
    },
  };
}

export function fetchDispatchRemainingCharge(dispatchId) {
  return {
    type: FETCH_DISPATCH_REMAINING_CHARGE,
    meta: {
      endpoint: `/api/dispatches/${dispatchId}/remainingcharge`,
      success: updateDispatchRemainingCharge,
      errorMessage: "Failed to load remaining charge.",
    },
  };
}

function updateDispatchRemainingCharge(response) {
  return {
    type: UPDATE_DISPATCH_REMAINING_CHARGE,
    payload: {
      remainingCharge: response.data,
    },
  };
}

export function fetchDispatchCost(dispatchId) {
  return {
    type: FETCH_DISPATCH_COST,
    meta: {
      endpoint: `/api/dispatches/${dispatchId}/cost`,
      success: updateDispatchCost,
      errorMessage: "Failed to load dispatch cost.",
    },
  };
}

function updateDispatchCost(response) {
  return {
    type: UPDATE_DISPATCH_COST,
    payload: {
      cost: response.data,
    },
  };
}

export function fetchDispatchResponse(dispatchId) {
  return {
    type: FETCH_DISPATCH_RESPONSE,
    meta: {
      endpoint: "/api/dispatches/" + dispatchId + "/response",
      success: updateDispatchResponse,
      errorMessage: "Failed to load dispatch response.",
    },
  };
}

function updateDispatchResponse(response) {
  return {
    type: UPDATE_DISPATCH_RESPONSE,
    payload: {
      response: response.data,
    },
  };
}

export function fetchNewDispatchConstraints(stationId, duration, realPowerP, powerFactor) {
  let endpoint = `/api/powerstations/${stationId}/constraints?duration=${duration}`;
  endpoint += realPowerP !== undefined && realPowerP !== null ? "&realPowerP=" + realPowerP : "";
  endpoint +=
    powerFactor !== undefined && powerFactor !== null ? "&powerFactor=" + powerFactor : "";

  return {
    type: FETCH_NEW_DISPATCH_CONSTRAINTS,
    meta: {
      endpoint: endpoint,
      success: updateNewDispatchConstraints,
      errorMessage: "Failed to load dispatch constraints.",
    },
  };
}

function updateNewDispatchConstraints(constraints) {
  return {
    type: UPDATE_NEW_DISPATCH_CONSTRAINTS,
    payload: {
      constraints: constraints.data,
    },
  };
}

export function fetchNewDispatchPrediction(
  stationId,
  startTime,
  dispatchStartTime,
  duration,
  realPowerP
) {
  return {
    type: FETCH_NEW_DISPATCH_PREDICTIONS,
    meta: {
      endpoint: `/api/powerstations/${stationId}/predictions?start=${startTime}&dispatchStart=${dispatchStartTime}&dispatchDuration=${duration}&dispatchRealPowerP=${realPowerP}`,
      success: updateNewDispatchPrediction,
      errorMessage: "Failed to load dispatch prediction.",
    },
  };
}

function updateNewDispatchPrediction(predictions) {
  return {
    type: UPDATE_NEW_DISPATCH_PREDICTIONS,
    payload: {
      predictions: predictions.data,
    },
  };
}

export function fetchNewDispatchPrice(stationId, startTime, duration, realPowerP, powerFactor) {
  let endpoint = `/dispatch/price?duration=${duration}&powerstation=${stationId}&startTime=${startTime}`;
  endpoint += realPowerP !== undefined && realPowerP !== null ? "&realPowerP=" + realPowerP : "";
  endpoint +=
    powerFactor !== undefined && powerFactor !== null ? "&powerFactor=" + powerFactor : "";
  return {
    type: FETCH_NEW_DISPATCH_PRICE,
    meta: {
      endpoint: endpoint,
      success: updateNewDispatchPrice,
    },
  };
}

function updateNewDispatchPrice(prices) {
  if (!prices) {
    Notify.warning("Failed to load dispatch prices.");
    return {
      type: ERROR_DISPATCH_PRICE,
    };
  }
  return {
    type: UPDATE_DISPATCH_PRICE,
    payload: {
      prices: prices.data,
    },
  };
}

export function fetchTradingPrice(stationId) {
  return {
    type: FETCH_TRADING_PRICES,
    meta: {
      endpoint: `/powerstation/${stationId}/prices`,
      success: updateTradingPrice,
    },
  };
}

function updateTradingPrice(prices) {
  if (!prices) {
    Notify.warning("Failed to load trading prices");
    return {
      type: ERROR_TRADING_PRICES,
    };
  }
  return {
    type: UPDATE_TRADING_PRICES,
    payload: {
      prices: prices.data,
    },
  };
}

export function clearNewDispatchGraphData() {
  return {
    type: CLEAR_NEW_DISPATCH_GRAPH_DATA,
  };
}

export function executeDispatch(
  stationId,
  startTime,
  duration,
  realPowerP,
  powerFactor,
  powerFactorLeadLag,
  customerAppMessage,
  strategy
) {
  return {
    type: EXECUTE_DISPATCH,
    meta: {
      endpoint: "/dispatch",
      method: "POST",
      body: {
        powerstation: stationId,
        startTime: startTime,
        duration: duration,
        realPowerP: realPowerP,
        powerFactor: powerFactor,
        powerFactorLeadLag: powerFactorLeadLag,
        customerMessage: customerAppMessage,
        strategy: strategy,
      },
      success: updateExecuteDispatch,
      errorMessage: "Failed to execute Dispatch.",
    },
  };
}

function updateExecuteDispatch(response) {
  if (response) {
    if (response.status === "ERROR") {
      // deals with 400s (treated as a success by fetchMiddleware)
      const message = (response.data && response.data.message) || "";
      Notify.error("Failed to execute dispatch.", message);
    } else {
      Notify.success("Dispatch successfully executed");

      if (response.data && response.data.id) {
        history.push(`/virtual-power-plants/dispatches/${response.data.id}`);
      }
    }
  }

  return {
    type: UPDATE_EXECUTE_DISPATCH,
  };
}

export function fetchPreviousDispatchMessges() {
  return {
    type: FETCH_PREVIOUS_DISPATCH_MESSAGES,
    meta: {
      endpoint: "/dispatch/messages",
      success: updatePreviousDispatchMessages,
      errorMessage: "Failed to load previous dispatch messages.",
    },
  };
}

function updatePreviousDispatchMessages(response) {
  return {
    type: UPDATE_PREVIOUS_DISPATCH_MESSAGES,
    payload: {
      messages: response.data,
    },
  };
}

export function clearPreviousDispatchMessages() {
  return {
    type: CLEAR_PREVIOUS_DISPATCH_MESSAGES,
  };
}

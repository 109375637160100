import { store } from "../../containers/Root";
import { Notify } from "../../utils/notificationManager";

export const LOG_IN = "LOG_IN";
export const FETCH_ACCOUNT = "FETCH_ACCOUNT";
export const REFETCH_ACCOUNT = "REFETCH_ACCOUNT";
export const UPDATE_ACCOUNT = "UPDATE_ACCOUNT";
export const REFRESH_ACCESS_TOKEN = "REFRESH_ACCESS_TOKEN";
export const BACKGROUND_REFRESH_ACCESS_TOKEN = "BACKGROUND_REFRESH_ACCESS_TOKEN";
export const LOGIN_ERROR = "LOGIN_ERROR";
export const LOGIN_BAD_AUTH = "LOGIN_BAD_AUTH";
export const CLEAR_ACCOUNT = "CLEAR_ACCOUNT";
export const SET_TIMEZONE = "SET_TIMEZONE";
export const UPDATE_TIMEZONE = "UPDATE_TIMEZONE";
export const SET_ALERT_EMAIL_FREQUENCY = "SET_ALERT_EMAIL_FREQUENCY";
export const UPDATE_ALERT_EMAIL_FREQUENCY = "UPDATE_ALERT_EMAIL_FREQUENCY";
export const FETCH_REQUEST_PASSWORD_RESET = "FETCH_REQUEST_PASSWORD_RESET";
export const UPDATE_REQUEST_PASSWORD_RESET = "UPDATE_REQUEST_PASSWORD_RESET";
export const CLEAR_PASSWORD_RESET = "CLEAR_PASSWORD_RESET";
export const CLEAR_TIMEZONE_MISMATCH = "CLEAR_TIMEZONE_MISMATCH";
export const FETCH_DISAVOW_PASSWORD_RESET = "FETCH_DISAVOW_PASSWORD_RESET";
export const FETCH_VERIFY_PASSWORD_RESET_TOKEN = "FETCH_VERIFY_PASSWORD_RESET_TOKEN";
export const UPDATE_VERIFY_PASSWORD_RESET_TOKEN = "UPDATE_VERIFY_PASSWORD_RESET_TOKEN";
export const FETCH_CHANGE_PASSWORD = "FETCH_CHANGE_PASSWORD";
export const UPDATE_CHANGE_PASSWORD = "UPDATE_CHANGE_PASSWORD";
export const CLEAR_CHANGE_PASSWORD = "CLEAR_CHANGE_PASSWORD";
export const SESSION_EXPIRED = "SESSION_EXPIRED";
export const CHANGE_PASSWORD_INLINE = "CHANGE_PASSWORD_INLINE";
export const UPDATE_PASSWORD_CHANGED_INLINE = "UPDATE_PASSWORD_CHANGED_INLINE";
export const CLEAR_PASSWORD_CHANGED_INLINE = "CLEAR_PASSWORD_CHANGED_INLINE";
export const INITIATE_MULTI_FACTOR = "INITIATE_MULTI_FACTOR";
export const SUBMIT_TOTP = "SUBMIT_TOTP";
export const UPDATE_TOTP_REJECTED = "UPDATE_TOTP_REJECTED";
export const INITIATE_MULTI_FACTOR_CONFIGURATION = "INITIATE_MULTI_FACTOR_CONFIGURATION";
export const CONFIRM_TOTP_CODE = "CONFIRM_TOTP_CODE";
export const FETCH_MFA_SETTINGS = "FETCH_MFA_SETTINGS";
export const UPDATE_MFA_SETTINGS = "UPDATE_MFA_SETTINGS";
export const FETCH_MFA_SMS_VERIFICATION_REQUEST = "FETCH_MFA_SMS_VERIFICATION_REQUEST";
export const UPDATE_MFA_SMS_VERIFICATION_REQUEST = "UPDATE_MFA_SMS_VERIFICATION_REQUEST";
export const FETCH_MFA_SMS_VERIFICATION_CONFIRM = "FETCH_MFA_SMS_VERIFICATION_CONFIRM";
export const FETCH_SAVE_CODE_PREFERENCE = "FETCH_SAVE_CODE_PREFERENCE";
export const REQUEST_SMS_CODE = "REQUEST_SMS_CODE";

export const accessTokenItemName = "session.accessToken";

export function refreshLogin() {
  const accessToken = localStorage.getItem(accessTokenItemName);
  if (accessToken) {
    try {
      // Always attempt to refresh the access token when re-opening or refreshing the browser tab.
      return refreshAccessToken();
    } catch (err) {
      return logout();
    }
  } else {
    // Force a logout if there is no token just to make sure all the state
    // is in the appropriate format.
    return logout();
  }
}

function getAccessTokenPayload(accessToken) {
  const accessTokenParts = accessToken.split(".");
  return JSON.parse(atob(accessTokenParts[1]));
}

export function login(username, password, captchaToken) {
  // We will get a new access token if we successfully authorize so just
  // remove this one.
  localStorage.removeItem(accessTokenItemName);
  return {
    type: LOG_IN,
    meta: {
      endpoint: "/auth/login/",
      noAuthorizationHeader: true,
      method: "POST",
      body: {
        username: username,
        password: password,
        captchaToken: captchaToken,
      },
      success: credentialsAccepted,
      authError: loginError,
    },
  };
}

let accessTokenRefreshTimeout = null;

function getNowUnix() {
  const nowDate = new Date();
  return Math.ceil(nowDate.getTime() / 1000); // convert from ms to s
}

function setAccessTokenRefreshTimeout(accessTokenPayload) {
  const now = getNowUnix();
  const expiresIn = accessTokenPayload.exp - now;
  const refreshIn = expiresIn - 300; // Refresh the access token 10 mins before it actually expires.
  // We do not have to worry about the timeout going negative, a negative timeout execute
  // immediately.
  setTimeout(() => store.dispatch(backgroundRefreshAccessToken()), refreshIn * 1000); // ms to s
}

function updateAccessToken(accessToken) {
  localStorage.setItem(accessTokenItemName, accessToken);
  const accessTokenPayload = getAccessTokenPayload(accessToken);
  setAccessTokenRefreshTimeout(accessTokenPayload);
}

function credentialsAccepted(loginResponse) {
  if (loginResponse.mfaRequired) {
    updateAccessToken(loginResponse.access_token);
    return mfaRequired(loginResponse);
  } else {
    return loginSuccess(loginResponse);
  }
}

export function mfaRequired(loginResponse) {
  if (loginResponse.mfa.confirmed) {
    return initiateMultiFactor(loginResponse.mfa);
  } else {
    return initiateMultiFactorConfiguration(loginResponse.mfa);
  }
}

function initiateMultiFactor(mfaSettings) {
  return {
    type: INITIATE_MULTI_FACTOR,
    payload: {
      confirmed: mfaSettings.confirmed,
      codePreference: mfaSettings.codePreference,
      phoneNumberMasked: mfaSettings.phoneNumberMasked,
      phoneNumberConfirmed: mfaSettings.phoneNumberConfirmed,
    },
  };
}

function initiateMultiFactorConfiguration(mfaSettings) {
  return {
    type: INITIATE_MULTI_FACTOR_CONFIGURATION,
    payload: {
      totpUri: mfaSettings.totpUri,
      confirmed: mfaSettings.confirmed,
    },
  };
}

export function submitTotpCode(totp, rememberDevice) {
  return {
    type: SUBMIT_TOTP,
    meta: {
      endpoint: "/auth/mfa/verify-code",
      method: "POST",
      body: {
        totpCode: totp,
        rememberDevice: rememberDevice,
      },
      success: fetchAccount,
      authError: updateTotpRejected,
      error: updateTotpRejected,
      authErrorMessage: "6-digit code rejected. Please try again.",
    },
  };
}

function updateTotpRejected() {
  return {
    type: UPDATE_TOTP_REJECTED,
  };
}

export function logout() {
  const accessToken = localStorage.getItem(accessTokenItemName);
  if (accessToken) {
    localStorage.removeItem(accessTokenItemName);
    clearTimeout(accessTokenRefreshTimeout);
    accessTokenRefreshTimeout = null;
    // We have to explicitly pass the rpToken to this action otherwise when the async fetch happens the localStorage
    // item will have already been deleted.
    return clearAccount(accessToken);
  } else {
    // Just reset the store without the logout API call as we dont have a token to log out from anyway.
    return resetStore();
  }
}

function backgroundRefreshAccessToken() {
  clearTimeout(accessTokenRefreshTimeout);
  return {
    type: BACKGROUND_REFRESH_ACCESS_TOKEN,
    meta: {
      endpoint: "/auth/refresh/",
      method: "POST",
      success: updateAccessToken,
    },
  };
}

function refreshAccessToken() {
  clearTimeout(accessTokenRefreshTimeout);
  return {
    type: REFRESH_ACCESS_TOKEN,
    meta: {
      endpoint: "/auth/refresh/",
      method: "POST",
      success: loginSuccess,
    },
  };
}

function loginSuccess(loginResponse) {
  updateAccessToken(loginResponse.access_token);
  return fetchAccount();
}

export function fetchAccount() {
  return {
    type: FETCH_ACCOUNT,
    meta: {
      endpoint: "/account",
      success: updateAccount,
      error: loginError,
      errorMessage: "Failed to load user account details",
    },
  };
}

// This gets used to refresh account data after editing (i.e. after updating the current users
// account preferences and permissions)
export function refetchAccount() {
  return {
    type: REFETCH_ACCOUNT,
    meta: {
      endpoint: "/account",
      success: updateAccount,
      error: loginError,
      errorMessage: "Failed to load user account details",
    },
  };
}

export function updateAccount(account) {
  const permissions = account.data.permissions;
  let account_no_perms = account.data;
  delete account_no_perms.permissions;
  let browserTimezone;
  let timezoneMismatch = false;

  if (!account_no_perms.timezone) {
    browserTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    store.dispatch(setTimezone(browserTimezone));
  } else if (Intl.DateTimeFormat().resolvedOptions().timeZone !== account_no_perms.timezone) {
    timezoneMismatch = true;
  }

  return {
    type: UPDATE_ACCOUNT,
    payload: {
      account: account_no_perms,
      permissions: permissions,
      browserTimezone,
      timezoneMismatch,
    },
  };
}

export function loginError(payload, response) {
  // If we've used a token and gotten a 401 then the token is no longer valid
  // so ensure we remove it.
  if (response && response.status === 401) {
    localStorage.removeItem(accessTokenItemName);
  }
  return {
    type: LOGIN_ERROR,
  };
}

function clearAccount(accessToken) {
  return {
    type: "RESET_STORE",
    meta: {
      endpoint: "/auth/logout/",
      accessToken: accessToken,
      authErrorOverride: () => {},
      // No callbacks here as we mark them as logged out immediately anyway.
    },
  };
}

function resetStore() {
  return {
    type: "RESET_STORE",
  };
}

export function setTimezone(timezone) {
  return {
    type: SET_TIMEZONE,
    meta: {
      endpoint: "/account/timezone?timezone=" + timezone,
      method: "POST",
      success: updateTimezone,
      successMessage: "Settings successfully updated.",
      errorMessage: "Failed to update settings.",
    },
  };
}

function updateTimezone(timezone) {
  return {
    type: UPDATE_TIMEZONE,
    payload: {
      timezone: timezone.timezone,
    },
  };
}

export function setAlertEmailFrequency(frequency) {
  return {
    type: SET_ALERT_EMAIL_FREQUENCY,
    meta: {
      endpoint: "/account/alertEmail?frequency=" + frequency,
      method: "POST",
      success: updateAlertEmailFrequency,
      successMessage: "Settings successfully updated.",
      errorMessage: "Failed to update settings.",
    },
  };
}

function updateAlertEmailFrequency(response) {
  return {
    type: UPDATE_ALERT_EMAIL_FREQUENCY,
    payload: {
      frequency: response.frequency,
    },
  };
}

export function requestPasswordReset(emailAddress) {
  return {
    type: FETCH_REQUEST_PASSWORD_RESET,
    meta: {
      endpoint: "/password/reset",
      method: "POST",
      body: {
        email: emailAddress,
      },
      success: updateRequestPasswordReset,
    },
  };
}

function updateRequestPasswordReset(resetResp) {
  return {
    type: UPDATE_REQUEST_PASSWORD_RESET,
    payload: {
      message: resetResp.message,
      status: resetResp.status,
    },
  };
}

export function clearPasswordReset() {
  return {
    type: CLEAR_PASSWORD_RESET,
  };
}

export const clearTimezoneMismatch = () => ({ type: CLEAR_TIMEZONE_MISMATCH });

export function disavowPasswordReset(token) {
  return {
    type: FETCH_DISAVOW_PASSWORD_RESET,
    meta: {
      endpoint: "/password/reset/disavow?token=" + token,
      method: "POST",
    },
  };
}

export function verifyPasswordResetToken(token) {
  return {
    type: FETCH_VERIFY_PASSWORD_RESET_TOKEN,
    meta: {
      endpoint: "/password/change/verify_token?token=" + token,
      success: updateVerifyPasswordResetToken,
    },
  };
}

export function updateVerifyPasswordResetToken(token) {
  return {
    type: UPDATE_VERIFY_PASSWORD_RESET_TOKEN,
    payload: {
      status: token.status,
      tokenValid: token.valid,
    },
  };
}

export function changePassword(password, confirmPassword, token) {
  return {
    type: FETCH_CHANGE_PASSWORD,
    meta: {
      endpoint: "/password/change?token=" + token,
      method: "POST",
      body: {
        password: password,
        confirmPassword: confirmPassword,
      },
      success: updateChangePassword,
      error: failedChangePassword,
    },
  };
}

function updateChangePassword(resp) {
  return {
    type: UPDATE_CHANGE_PASSWORD,
    payload: {
      status: resp.status,
      message: resp.message,
    },
  };
}

function failedChangePassword(_, resp) {
  const respJson = resp.json();
  return {
    type: UPDATE_CHANGE_PASSWORD,
    payload: {
      status: respJson.status,
      message: respJson.message,
    },
  };
}

export function clearChangePassword() {
  return {
    type: CLEAR_CHANGE_PASSWORD,
  };
}

export function changePasswordInline(oldPassword, newPassword, passwordConfirmation) {
  return {
    type: CHANGE_PASSWORD_INLINE,
    meta: {
      endpoint: "/password/inline-change",
      method: "POST",
      body: {
        oldPassword: oldPassword,
        newPassword: newPassword,
        passwordConfirmation: passwordConfirmation,
      },
      success: updatePasswordChangedInline,
      error: failedInlinePasswordChange,
      authErrorOverride: updatePasswordChangedInlineAuthError,
    },
  };
}

export function updatePasswordChangedInline(json) {
  const success = json.status === "OK";
  if (success) {
    Notify.success("Changed password", "Successfully changed your password.");
  }

  return {
    type: UPDATE_PASSWORD_CHANGED_INLINE,
    payload: {
      success: success,
      message: json.message,
    },
  };
}

function updatePasswordChangedInlineAuthError(error, json) {
  return updatePasswordChangedInline({
    status: "ERROR",
    message: "Incorrect current password",
  });
}

export function failedInlinePasswordChange(_, resp) {
  Notify.error("Failed to change password", "An unknown error occurred, please try again later.");
  return {
    type: UPDATE_PASSWORD_CHANGED_INLINE,
    payload: {
      success: false,
    },
  };
}

export function clearPasswordChangeInline() {
  return {
    type: CLEAR_PASSWORD_CHANGED_INLINE,
  };
}

export function fetchMFASettings() {
  return {
    type: FETCH_MFA_SETTINGS,
    meta: {
      endpoint: "/account/mfa/settings",
      success: updateMFASettings,
      errorMessage: "Failed to load existing MFA settings.",
    },
  };
}

function updateMFASettings(response) {
  return {
    type: UPDATE_MFA_SETTINGS,
    payload: {
      codePreference: response.data.codePreference,
      phoneNumberMasked: response.data.phoneNumberMasked,
      phoneNumberConfirmed: response.data.phoneNumberConfirmed,
    },
  };
}

export function fetchMFASMSVerificationRequest(phoneNumber, password) {
  return {
    type: FETCH_MFA_SMS_VERIFICATION_REQUEST,
    meta: {
      endpoint: "/auth/mfa/sms/verification/request",
      method: "POST",
      body: {
        phoneNumber: phoneNumber,
        password: password,
      },
      success: updateMFASMSVerificationRequest,
      errorMessage: "Unable to send verification SMS.",
    },
  };
}

function updateMFASMSVerificationRequest(response) {
  return {
    type: UPDATE_MFA_SMS_VERIFICATION_REQUEST,
    payload: {
      formattedPhoneNumber: response.status === "ERROR" ? null : response.data.formattedPhoneNumber,
      phoneNumberError:
        response.status === "ERROR" && response.field === "PHONE" ? response.message : null,
      passwordError:
        response.status === "ERROR" && response.field === "PASSWORD" ? response.message : null,
      messageSent: response.status === "OK",
    },
  };
}

export function fetchMFASMSVerificationConfirm(verificationCode) {
  return {
    type: FETCH_MFA_SMS_VERIFICATION_CONFIRM,
    meta: {
      endpoint: "/auth/mfa/sms/verification/confirm",
      method: "POST",
      body: {
        verificationCode: verificationCode,
      },
      success: fetchMFASettings,
      errorMessage: "Unable to confirm phone number via SMS.",
      successMessage: "Phone number successfully confirmed and MFA settings saved.",
    },
  };
}

export function fetchSaveCodePreference(codePreference) {
  return {
    type: FETCH_SAVE_CODE_PREFERENCE,
    meta: {
      endpoint: "/auth/mfa/code-preference",
      method: "POST",
      body: {
        codePreference: codePreference,
      },
      success: fetchMFASettings,
      errorMessage: "Unable to update your MFA settings.",
      successMessage: "MFA settings successfully updated.",
    },
  };
}

export function requestSMSCode() {
  return {
    type: REQUEST_SMS_CODE,
    meta: {
      endpoint: "/auth/mfa/request-sms",
      method: "POST",
      errorMessage: "Unable to send MFA SMS.",
      successMessage: "You MFA code is now being sent via SMS.",
    },
  };
}

import React, { Component } from "react";
import _ from "lodash";
import { Link } from "react-router-dom";
import { getAddressLine1, getAddressLine2 } from "../../utils/formatting";
import "./CustomersListTable.css";

class CustomersListTable extends Component {
  handleOnRowClick = customer => {
    this.props.history.push(`/customers/manage/${customer.nmi}`);
  };

  getCustomerAddress = customer => {
    const addr = _.pick(customer, ["streetNumber", "streetName", "city", "state", "postcode"]);
    return { ...addr, street: addr.streetName };
  };

  render() {
    const { customers, suborgType } = this.props;

    if (!customers || customers.length === 0) {
      return (
        <div className="fleet-table-no-results">
          No customers have been found for this search criteria
        </div>
      );
    }

    return (
      <table>
        <thead>
          <tr>
            <th>NMI</th>
            <th>Install Address</th>
            {suborgType === "RETAILER" ? <th>Retail Plan</th> : null}
            <th>Capabilities</th>
            {suborgType === "NETWORK" ? <th>Information</th> : null}
          </tr>
        </thead>
        <tbody>
          {customers.map(customer => {
            return (
              <tr role="button" onClick={() => this.handleOnRowClick(customer)}>
                <td>
                  <Link to={`/customers/manage/${customer.nmi}`}>{customer.nmi}</Link>
                </td>
                <td>
                  {getAddressLine1(this.getCustomerAddress(customer))}
                  <br />
                  {getAddressLine2(this.getCustomerAddress(customer))}
                </td>
                {suborgType === "RETAILER" ? (
                  <td>
                    {customer.tariff ? customer.tariff.name : "-"}
                    {customer.tariff && customer.tariff.discount ? (
                      <div className="discount-container">
                        <label>Discount:</label>
                        <span>{parseFloat(customer.tariff.discount)}%</span>
                      </div>
                    ) : null}
                  </td>
                ) : null}
                <td>
                  {customer.contracts.map(c => (
                    <div key={c.contractName}>{c.contractName}</div>
                  ))}
                </td>
                {suborgType === "NETWORK" ? (
                  <td>
                    {customer.contracts.length === 0 ? (
                      <div className="customer-info-info">
                        <i className="fa fa-circle icon" />
                        <span>No Capabilities</span>
                      </div>
                    ) : null}
                  </td>
                ) : null}
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  }
}

export default CustomersListTable;

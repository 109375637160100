import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as userActions from "../../redux/actions/user";
import InlinePasswordChangeForm from "../../components/user/InlinePasswordChangeForm";

const mapStateToProps = state => ({
  passwordReset: state.user.passwordReset,
  inlinePasswordChange: state.user.inlinePasswordChange,
});

const mapDispatchToProps = dispatch => {
  return {
    userActions: bindActionCreators(userActions, dispatch),
  };
};

class InlinePasswordChanger extends Component {
  constructor(props) {
    super(props);
    this.state = {
      oldPasswordError: "",
      newPasswordError: "",
      passwordConfirmationError: "",
    };
  }

  componentWillUnmount = () => {
    this.props.userActions.clearPasswordChangeInline();
  };

  submitPasswordChange = event => {
    event.preventDefault();

    const { oldPassword, newPassword, passwordConfirmation } = this.state;

    const passwordConfirmationError =
      newPassword !== passwordConfirmation ? "Passwords don't match." : null;
    const oldPasswordError = !oldPassword ? "Enter your current password." : null;
    const newPasswordError = !newPassword ? "Enter your new password." : null;
    const passwordConfirmationEmptyError = !passwordConfirmation
      ? "Confirm your new password."
      : null;

    this.setState({
      oldPasswordError: oldPasswordError,
      newPasswordError: newPasswordError,
      passwordConfirmationError: passwordConfirmationError,
      passwordConfirmationEmptyError: passwordConfirmationEmptyError,
    });

    if (
      !oldPasswordError &&
      !newPasswordError &&
      !passwordConfirmationError &&
      !passwordConfirmationEmptyError
    ) {
      this.props.userActions.changePasswordInline(
        this.state.oldPassword,
        this.state.newPassword,
        this.state.passwordConfirmation
      );
    }
  };

  onPasswordFieldChange = (fieldName, event) => {
    this.setState({
      [fieldName]: event.target.value,
    });
  };

  render() {
    const serverError =
      this.props.passwordReset && this.props.passwordReset.status === "FAILED"
        ? this.props.passwordReset.message
        : null;

    return (
      <div className="user-setting password">
        <h4>Password</h4>
        <InlinePasswordChangeForm
          status={
            this.props.passwordReset && this.props.passwordReset.status
              ? this.props.passwordReset.status
              : "UNCHANGED"
          }
          onSubmit={this.submitPasswordChange}
          oldPassword={this.state.oldPassword || ""}
          newPassword={this.state.newPassword || ""}
          passwordConfirmation={this.state.passwordConfirmation || ""}
          newPasswordError={this.state.newPasswordError}
          oldPasswordError={this.state.oldPasswordError}
          passwordConfirmationError={
            this.state.passwordConfirmationError ||
            this.state.passwordConfirmationEmptyError ||
            serverError
          }
          onOldPasswordChange={event => this.onPasswordFieldChange("oldPassword", event)}
          onNewPasswordChange={event => this.onPasswordFieldChange("newPassword", event)}
          onPasswordConfirmationChange={event =>
            this.onPasswordFieldChange("passwordConfirmation", event)
          }
        />
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(InlinePasswordChanger);

import React, { Component } from "react";
import { connect } from "react-redux";
import LoginLayout from "../layouts/LoginLayout";
import LoginForm from "./login/LoginForm";

const mapStateToProps = state => ({
  user: state.user,
});

class LoginRequired extends Component {
  render() {
    if (this.props.user.loginStatus === "LOGGED_IN") {
      return this.props.children;
    } else {
      return (
        <LoginLayout>
          <LoginForm />
        </LoginLayout>
      );
    }
  }
}

export default connect(mapStateToProps)(LoginRequired);

import React, { Component } from "react";
import { Modal } from "../../components/layout/Modal";
import { Button, Loading } from "../../components/widgets";
import PowerstationDeploymentsList from "./PowerstationDeploymentsList";
import "./PowerstationUnassignedDeploymentsModal.css";

class PowerstationUnassignedDeploymentsModal extends Component {
  render() {
    return (
      <Modal style={{ minWidth: "1100px", minHeight: "750px" }}>
        <Modal.Header title="Unassigned Nodes" />
        <Modal.Content>
          <div className="unassigned-deployments-table-container">
            {this.props.loading ? (
              <Loading />
            ) : (
              <span>
                <PowerstationDeploymentsList deployments={this.props.deployments} />
              </span>
            )}
          </div>
          <div className="unassigned-deployment-children">
            {!this.props.loading && this.props.children}
          </div>
        </Modal.Content>
        <Modal.Footer>
          <Button
            id="close-unassigned-deployments-modal"
            type="secondary"
            onClick={this.props.handleOnCancel}
            icon="times"
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default PowerstationUnassignedDeploymentsModal;

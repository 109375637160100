import React, { Component } from "react";
import { getAbsoluteOffset } from "../../utils/dom";
import ReactDOM from "react-dom";
import "./Tooltip.css";
import { getTooltipById } from "../../utils/tooltips";
import Markdown from "react-markdown";

const WIDTH_DEFAULT = 240;
const WIDTH_MEDIUM = 300;
const WIDTH_LARGE = 420;

class Tooltip extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showContent: false,
    };
  }

  handleHover = nextState => {
    this.setState({
      showContent: nextState,
    });
  };

  registerIcon = icon => {
    this.icon = icon;
  };

  tooltipBodyContainer = document.getElementById("tooltip-body-container");

  getTitleAndContent = () => {
    let title;
    let content;

    if (this.props.id) {
      const tooltip = getTooltipById(this.props.id);
      title = tooltip.title;
      if (tooltip.content) {
        content = <Markdown source={tooltip.content} />;
      }
    } else if (this.props.children) {
      title = null;
      content = this.props.children;
    } else if (this.props.title || this.props.content) {
      title = this.props.title;
      if (this.props.content) {
        content = <p>{this.props.content}</p>;
      }
    }

    return {
      title,
      content,
    };
  };

  render() {
    const { left, top } = getAbsoluteOffset(this.icon);
    const windowWidth = window.innerWidth;
    const tooltipWidth = this.props.large
      ? WIDTH_LARGE
      : this.props.medium
      ? WIDTH_MEDIUM
      : WIDTH_DEFAULT;
    const isDebug = process.env.NODE_ENV !== "production";

    const icon = this.props.icon || "info-circle";

    // If there not enough room for it, swing it left.
    let anchor = "tl"; // top-left
    if (left + tooltipWidth >= windowWidth) {
      anchor = "tr"; // top-right
    }

    const { title, content } = this.getTitleAndContent();

    if (!title && !content) {
      if (isDebug) {
        return (
          <span className="tooltip-container debug-error">
            <span className="info-icon">
              <i className={"fa fa-" + icon} title={isDebug ? this.props.id : null} />
            </span>{" "}
            !Error
          </span>
        );
      }
      return null;
    }

    return (
      <span
        className="tooltip-container"
        onMouseEnter={() => this.handleHover(true)}
        onMouseLeave={() => this.handleHover(false)}
      >
        <span className="info-icon" ref={this.registerIcon}>
          <i className={"fa fa-" + icon} title={isDebug ? this.props.id : null} />
        </span>
        {this.state.showContent
          ? ReactDOM.createPortal(
              <div
                className="tooltip-body"
                style={{
                  left: left,
                  top: top,
                }}
              >
                <div className="tip" />
                <div
                  className="tooltip-content"
                  style={{
                    left: anchor === "tl" ? null : "calc(-" + tooltipWidth + "px + 0.7em)",
                    width: `${tooltipWidth}px`,
                  }}
                >
                  {title ? <h1>{title}</h1> : null}
                  <div className="tooltip-content-wrapper">{content}</div>
                </div>
              </div>,
              this.tooltipBodyContainer
            )
          : null}
      </span>
    );
  }
}

export default Tooltip;

import React, { Component } from "react";
import { Link } from "react-router-dom";
import { LabelledContent, Loading } from "../widgets";
import { getDateAsString } from "../widgets/Moment";
import { Accordion, AccordionItem } from "react-sanfona";
import { FormattedNumber } from "react-intl";
import "./BillingGridCreditsSummary.css";

const BillingItemHeader = props => (
  <div className="billing-transaction-heading" {...props}>
    <span className="date">{props.date}</span>

    <span className="expand-icon">
      <i className="fa fa-angle-double-down" />
    </span>

    <span className="total">{props.total}</span>
  </div>
);

class BillingGridCreditsSummary extends Component {
  render() {
    if (!this.props.billingSummary) {
      return <Loading />;
    }

    const { billingSummary, timezone, accessToken } = this.props;

    return (
      <div className="billing-gc-container">
        <Accordion allowMultiple={true}>
          {(billingSummary || []).map(item => {
            const date = new Date(item.date);
            const dateString = getDateAsString(date, timezone, "MMMM YYYY");
            const lastDayOfMonth = getDateAsString(
              new Date(date.getFullYear(), date.getMonth() + 1, 0),
              timezone,
              "YYYY-MM-DD"
            );
            return (
              <AccordionItem
                title={
                  <BillingItemHeader
                    date={dateString}
                    total={
                      <FormattedNumber
                        style="currency" // eslint-disable-line
                        currency="AUD"
                        maximumFractionDigits={2}
                        value={item.value}
                      />
                    }
                  />
                }
                slug={item.date}
                key={item.date}
                className="billing-transaction-item"
                expandedClassName="billing-transaction-expanded"
              >
                <div className="billing-transaction-body">
                  <div className="summary">
                    <LabelledContent label="Customers">{item.count}</LabelledContent>
                    <LabelledContent label="Support Events">{item.eventCount}</LabelledContent>
                    <LabelledContent label="Total Value">
                      <FormattedNumber
                        style="currency" // eslint-disable-line
                        currency="AUD"
                        maximumFractionDigits={2}
                        value={item.value}
                      />
                    </LabelledContent>
                  </div>
                  <div className="controls">
                    <ul className="link-list">
                      <li>
                        <Link
                          to={
                            "/gridcredits/billing/search?start=" +
                            item.date +
                            "&end=" +
                            lastDayOfMonth
                          }
                        >
                          View breakdown
                        </Link>
                      </li>
                      <li>
                        <a
                          href={
                            process.env.REACT_APP_MARKETPLACE_API_URL +
                            "/billing/summary/csv?auth=Bearer " +
                            accessToken +
                            "&start=" +
                            item.date +
                            "&end=" +
                            lastDayOfMonth
                          }
                        >
                          Download summary as CSV (Excel)
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </AccordionItem>
            );
          })}
        </Accordion>
      </div>
    );
  }
}

export default BillingGridCreditsSummary;

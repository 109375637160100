import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as billingActions from "../../redux/actions/billing";
import { Loading, Button } from "../../components/widgets";
import Page from "../../components/layout/Page";
import GridCreditsSearchTable from "../../components/billing/GridCreditsSearchTable";
import { accessTokenItemName } from "../../redux/actions/user";
import queryString from "query-string";
import BillingGridCreditsSearchFields from "../../components/billing/BillingGridCreditsSearchFields";
import moment from "moment-timezone";

const mapStateToProps = state => ({
  events: state.billing.events,
  user: state.user,
});

const mapDispatchToProps = dispatch => {
  return {
    billingActions: bindActionCreators(billingActions, dispatch),
  };
};

class BillingGridCreditsSearch extends Component {
  constructor(props) {
    super(props);

    const { start, end } = this.parseQueryString(props);

    const startMoment = start ? moment(start, "YYYY-MM-DD") : null;
    const endMoment = start ? moment(end, "YYYY-MM-DD") : null;

    this.state = {
      searchDates: {
        from: startMoment,
        to: endMoment,
      },
    };
  }

  parseQueryString = props => {
    return queryString.parse(props.location.search);
  };

  componentDidMount = () => {
    const { start, end } = this.parseQueryString(this.props);
    if (start && end) {
      this.props.billingActions.fetchGridCredits(start, end);
    }
  };

  componentWillReceiveProps = nextProps => {
    if (this.props.location.search !== nextProps.location.search) {
      const { start, end } = this.parseQueryString(nextProps);

      const startMoment = start ? moment(start, "YYYY-MM-DD") : null;
      const endMoment = start ? moment(end, "YYYY-MM-DD") : null;

      this.setState = {
        searchDates: {
          from: startMoment,
          to: endMoment,
        },
      };
    }
  };

  handleDateOnChange = (dateFieldName, nextDate) => {
    this.setState({
      searchDates: {
        ...this.state.searchDates,
        [dateFieldName]: nextDate,
      },
    });
  };

  handleOnSearch = () => {
    const start = this.state.searchDates.from.format("YYYY-MM-DD");
    const end = this.state.searchDates.to.format("YYYY-MM-DD");
    this.props.billingActions.fetchGridCredits(start, end);
  };

  getUrlParams = () => {
    const rpToken = localStorage.getItem(accessTokenItemName);
    if (this.props.location.search) {
      return `${this.props.location.search}&auth=Bearer ${rpToken}`;
    } else {
      const start = this.state.searchDates.from.format("YYYY-MM-DD");
      const end = this.state.searchDates.to.format("YYYY-MM-DD");
      return `?start=${start}&end=${end}&auth=Bearer ${rpToken}`;
    }
  };

  render() {
    return (
      <Page>
        <Page.Header title="GridCredits® - Billing Search">
          <Page.Header.Actions>
            {this.props.events && this.props.events.length > 0 ? (
              <>
                <Button
                  id="gc-billing-download-events-csv"
                  type="secondary"
                  icon="cloud-download"
                  to={
                    process.env.REACT_APP_MARKETPLACE_API_URL +
                    "/billing/events/csv" +
                    this.getUrlParams()
                  }
                >
                  Download Events CSV
                </Button>
                <Button
                  id="gc-billing-download-summary-csv"
                  type="secondary"
                  icon="cloud-download"
                  to={
                    process.env.REACT_APP_MARKETPLACE_API_URL +
                    "/billing/month/csv" +
                    this.getUrlParams()
                  }
                >
                  Download Summary CSV
                </Button>
              </>
            ) : null}
          </Page.Header.Actions>
        </Page.Header>
        <Page.SearchFields visible>
          <BillingGridCreditsSearchFields
            timezone={this.props.user.account.timezone}
            fromDate={this.state.searchDates.from}
            toDate={this.state.searchDates.to}
            handleDateOnChange={this.handleDateOnChange}
            onSearch={this.handleOnSearch}
          />
        </Page.SearchFields>
        <Page.Content>
          {this.props.events || !this.state.searchDates.start || !this.state.searchDates.end ? (
            <GridCreditsSearchTable
              lineItems={this.props.events}
              timezone={this.props.user.account.timezone}
            />
          ) : (
            <Loading />
          )}
        </Page.Content>
      </Page>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BillingGridCreditsSearch);

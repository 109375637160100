import React, { Component } from "react";
import NewDispatch from "./NewDispatch";
import { FormattedNumber } from "react-intl";
import { LabelledContent } from "../widgets";
import "./NewDispatchExecuteConfirmation.css";

class NewDispatchExecuteConfirmation extends Component {
  render() {
    return (
      <div className="new-dispatch-confirmation">
        <NewDispatch
          readOnly
          powerstations={this.props.powerstations}
          powerstation={this.props.powerstation}
          startDateTime={this.props.startDateTime}
          duration={this.props.duration}
          timezone={this.props.timezone}
          constraints={this.props.constraints}
          realPowerP={this.props.realPowerP}
          powerFactor={this.props.powerFactor}
          powerFactorLeadLag={this.props.powerFactorLeadLag}
          customerAppMessage={this.props.customerAppMessage}
          strategy={this.props.strategy}
        />
        {this.props.buyPrice !== undefined ? (
          <div className="pricing-summary">
            <LabelledContent label="Cost" tooltipId="dispatches.new.buyPrice">
              <FormattedNumber
                style="currency" // eslint-disable-line
                currency="AUD"
                maximumFractionDigits={2}
                value={Math.abs(this.props.buyPrice)}
              />
            </LabelledContent>
            {this.props.showMarketData ? (
              <LabelledContent label="Sell Price" tooltipId="dispatches.new.sellPrice">
                {this.props.sellPrice ? (
                  <FormattedNumber
                    style="currency" // eslint-disable-line
                    currency="AUD"
                    maximumFractionDigits={2}
                    value={Math.abs(this.props.sellPrice)}
                  />
                ) : (
                  "-"
                )}
              </LabelledContent>
            ) : null}
          </div>
        ) : null}
        <div className="confirm-message">Are you sure you want to execute this dispatch?</div>
      </div>
    );
  }
}

export default NewDispatchExecuteConfirmation;

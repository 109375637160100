import React, { Component } from "react";

import { bindActionCreators } from "redux";
import { beginCSVDataExport } from "../../redux/actions/analysis";
import * as dashboardActions from "../../redux/actions/dashboard";

import { connect } from "react-redux";
import { Modal } from "../../components/layout/Modal";
import { Button, Select, InfoBox } from "../../components/widgets";
import { CUSTOMER_TASK } from "../../utils/analysis";
import "./AnalysisDataCustomerExport.css";
import Tooltip from "../../components/widgets/Tooltip";

const mapStateToProps = state => ({
  user: state.user,
  analysis: state.analysis,
  powerstations: state.dashboard.powerstations,
});

const mapDispatchToProps = dispatch => {
  return {
    beginCSVDataExport: bindActionCreators(beginCSVDataExport, dispatch),
    dashboardActions: bindActionCreators(dashboardActions, dispatch),
  };
};

class NewCustomerExport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataExport: {
        powerstationId: "",
        allCustomers: "fleetCustomers",
      },
    };
  }

  componentDidMount = () => {
    if (this.props.suborgType !== "INSTALLER") {
      if (!this.props.powerstations) {
        this.props.dashboardActions.fetchPowerstations();
      }
    }
  };

  saveChanges = () => {
    let allCustomerOptions = { allCustomers: false };
    if (this.state.dataExport.allCustomers === "allCustomers") {
      allCustomerOptions.allCustomers = true;
    }

    let jsonBody = {
      type: CUSTOMER_TASK,
      params: {
        powerstation: this.state.dataExport.powerstationId,
        user: this.props.user.account.id,
        timezone: this.props.user.account.timezone,
        ...allCustomerOptions,
      },
    };
    this.props.beginCSVDataExport(jsonBody, this.props.user.account.id);
    this.props.closeModal(true);
  };

  infoBoxText = suborgType => {
    let variableText;
    if (suborgType === "NETWORK") {
      variableText = "the number of customers in a Virtual Power Plant or on your network";
    } else if (suborgType === "INSTALLER") {
      variableText = "the number of customers in Fleet";
    } else {
      variableText = "the number of customers in a Virtual Power Plant or in Fleet";
    }
    return ` Depending on ${variableText} this may take several minutes to complete.`;
  };

  handleDropdownfieldChange = (fieldName, field) => {
    this.setState({
      dataExport: {
        ...this.state.dataExport,
        [fieldName]: field ? field.value : null,
      },
    });
  };

  render() {
    let powerstationOptions = [];
    for (let i in this.props.powerstations) {
      powerstationOptions.push({
        value: this.props.powerstations[i].id,
        label: this.props.powerstations[i].name,
      });
    }

    let customerSelectionOptions = [
      {
        value: "allCustomers",
        label: "All customers on my network",
      },
      {
        value: "fleetCustomers",
        label: "All customers in Fleet",
      },
    ];

    return (
      <Modal>
        <Modal.Header title="New Customer Data Export" />
        <Modal.Content>
          <InfoBox>
            Creating this data export will submit a job to download your specified data as a CSV.
            {this.infoBoxText(this.props.suborgType)}
          </InfoBox>
          {this.props.suborgType !== "INSTALLER" && (
            <div className="new-export-modal-contents">
              <div className="export-modal-dropdown">
                <label>
                  <span className="label-text">Virtual Power Plant</span>
                </label>
                <Select
                  options={powerstationOptions}
                  onChange={p => this.handleDropdownfieldChange("powerstationId", p)}
                  value={this.state.dataExport.powerstationId}
                />
              </div>
              {this.props.suborgType === "NETWORK" && (
                <div className="export-modal-dropdown">
                  <label>
                    <span className="label-text">Customer Set</span>
                    <Tooltip id="analysis.new.customer.allCustomers" />
                  </label>
                  <Select
                    options={customerSelectionOptions}
                    onChange={p => this.handleDropdownfieldChange("allCustomers", p)}
                    value={this.state.dataExport.allCustomers}
                  />
                </div>
              )}
            </div>
          )}
          {this.props.suborgType === "RETAILER" && !this.state.dataExport.powerstationId && (
            <InfoBox>
              Not selecting a Virtual Power Plant will export data for all customers in Fleet.
            </InfoBox>
          )}
        </Modal.Content>
        <Modal.Footer>
          <Button
            id="new-export-modal-cancel"
            type="secondary"
            onClick={this.props.closeModal}
            icon="times"
          >
            Cancel
          </Button>
          <Button
            id="new-export-modal-begin"
            type="primary"
            icon="check"
            onClick={this.saveChanges}
          >
            Begin Export
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(NewCustomerExport);

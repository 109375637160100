import { tooltips } from "../tooltip-generated-DONOTEDIT";

export function getTooltipById(id) {
  const idParts = id.split(".");
  let obj = tooltips;
  for (let i in idParts) {
    if (!obj) {
      break;
    }
    const idPart = idParts[i];
    obj = obj[idPart];
  }

  if (obj) {
    return {
      title: obj.title,
      content: obj.content,
    };
  } else {
    if (process.env.NODE_ENV !== "production") {
      console.warn(`No tooltip found for ID: ${id}`);
    }
    return {};
  }
}

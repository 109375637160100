import React, { Component } from "react";
import "./TabBar.css";

class TabBar extends Component {
  render() {
    return (
      <div className={"fleet-tab-bar" + (this.props.className ? ` ${this.props.className}` : "")}>
        {this.props.tabs.map(t => {
          return (
            <div
              className={"fleet-tab" + (this.props.activeTab === t.value ? " active" : "")}
              onClick={() => this.props.onChange(t.value)}
            >
              {t.label}
              {t.badge ? <div className="badge">{t.badge}</div> : null}
            </div>
          );
        })}
      </div>
    );
  }
}

export default TabBar;

export const FETCH_POWER_DATA = "FETCH_POWER_DATA";
export const FETCH_SECONDARY_DATA = "FETCH_SECONDARY_DATA";
export const FETCH_CUSTOMER_DATA_SUBMETER = "FETCH_CUSTOMER_DATA_SUBMETER";
export const UPDATE_DATA = "UPDATE_DATA";
export const UPDATE_SECONDARY_DATA = "UPDATE_SECONDARY_DATA";
export const UPDATE_SUBMETER_DATA = "UPDATE_CUSTOMER_DATA_SUBMETER";
export const FETCH_SUMMARY_DATA = "FETCH_SUMMARY_DATA";
export const UPDATE_SUMMARY_DATA = "UPDATE_SUMMARY_DATA";
export const CLEAR_MONITORING_DATA = "CLEAR_MONITORING_DATA";

export function fetchNodePowerData(
  deploymentUid,
  start,
  end,
  intervalSeconds,
  period,
  dateStr,
  includeSga
) {
  return fetchPowerData(
    "nodes",
    deploymentUid,
    start,
    end,
    intervalSeconds,
    period,
    dateStr,
    includeSga
  );
}

export function fetchNodeSecondaryData(
  deploymentUid,
  start,
  end,
  interval,
  period,
  metric,
  dateStr
) {
  return fetchSecondaryData("nodes", deploymentUid, start, end, interval, period, metric, dateStr);
}

export function fetchNodeSubmeterData(
  installerKey,
  start,
  end,
  intervalSeconds,
  period,
  submeterId,
  dateStr
) {
  return fetchSubmeterData(installerKey, start, end, intervalSeconds, period, submeterId, dateStr);
}

export function clearActiveMonitoringData() {
  return clearMonitoringData();
}

function fetchPowerData(type, uid, start, end, intervalSeconds, period, dateStr, includeSga) {
  let endpoint = `/api/${type}/${uid}/data?metrics=meterPower,solarPower,batteryPower,housePower&start=${start}&end=${end}&interval=${intervalSeconds}&fill=null`;
  if (includeSga) {
    endpoint = `/api/${type}/${uid}/data?metrics=meterPower,solarPower,batteryPower,housePower,meterSGA&start=${start}&end=${end}&interval=${intervalSeconds}&fill=null`;
  }
  return {
    type: FETCH_POWER_DATA,
    meta: {
      endpoint,
      success: updateData,
    },
    payload: {
      dateStr: dateStr,
      period: period,
    },
  };
}

function fetchSecondaryData(type, uid, start, end, interval, period, metric, dateStr) {
  return {
    type: FETCH_SECONDARY_DATA,
    meta: {
      endpoint: `/api/${type}/${uid}/data?metrics=${metric}&start=${start}&end=${end}&interval=${interval}`,
      success: updateSecondaryData,
    },
    payload: {
      dateStr: dateStr,
      period: period,
    },
  };
}

export function fetchSubmeterData(installerKey, start, end, interval, period, submeterId, dateStr) {
  return {
    type: FETCH_CUSTOMER_DATA_SUBMETER,
    payload: {
      submeterId: submeterId,
      dateStr: dateStr,
      period: period,
    },
    meta: {
      endpoint: `/fleet/deployment/${installerKey}/customerdata/submeter/historical/p?aggregation_base=client&delta_t=${interval}&start=${start}&end=${end}&id=${submeterId}`,
      success: updateSubmeterData,
      errorMessage: "Failed to load customer sub meter data.",
    },
  };
}

export function fetchNodeSummaryData(uid, start, interval, dateStr) {
  return {
    type: FETCH_SUMMARY_DATA,
    meta: {
      endpoint: `/api/nodes/${uid}/data/summary?start=${start}&interval=${interval}`,
      success: updateNodeSummaryData,
    },
    payload: {
      dateStr: dateStr,
      interval: interval,
    },
  };
}

function updateData(response, payload) {
  return {
    type: UPDATE_DATA,
    payload: {
      dateStr: payload.dateStr,
      period: payload.period,
      data: response.data,
    },
  };
}

function updateSecondaryData(response, payload) {
  return {
    type: UPDATE_SECONDARY_DATA,
    payload: {
      dateStr: payload.dateStr,
      period: payload.period,
      data: response.data,
    },
  };
}

function updateSubmeterData(response, payload) {
  let resultData = {};
  let timestamp = Date.now() / 1000;
  // Change the result data from a list of tuples (timestamp, data) to an object (timestamp: data)
  // Also dates in the future are still included, so we need to remove them here
  for (let i = 0; i < response.submeterP.length; ++i) {
    let dataPoint = response.submeterP[i];
    if (dataPoint[0] > timestamp) {
      break;
    }
    resultData[dataPoint[0]] = dataPoint[1];
  }
  return {
    type: UPDATE_SUBMETER_DATA,
    payload: {
      dateStr: payload.dateStr,
      period: payload.period,
      data: {
        [payload.submeterId]: resultData,
      },
    },
  };
}

function updateNodeSummaryData(response, payload) {
  return {
    type: UPDATE_SUMMARY_DATA,
    payload: {
      dateStr: payload.dateStr,
      interval: payload.interval,
      data: response.data,
    },
  };
}

function clearMonitoringData() {
  return {
    type: CLEAR_MONITORING_DATA,
  };
}

import React, { Component } from "react";
import DispatchIcon from "./ticker_icon_dispatch.svg";
import CloudIcon from "./ticker_icon_cloud.svg";
import TempIcon from "./ticker_icon_temp.svg";
import HomeIcon from "./ticker_icon_home.svg";
import CustomerIcon from "./ticker_icon_customer.svg";
import "./Ticker.css";

class DispatchTicker extends Component {
  getAction = () => {
    if (this.props.state === "UPCOMING") {
      if (this.props.realPowerP > 0) return "charge";
      if (this.props.realPowerP < 0) return "discharge";
      if (this.props.realPowerP === 0) return "lockout";
    }

    if (this.props.state === "INPROGRESS") {
      if (this.props.realPowerP > 0) return "charging";
      if (this.props.realPowerP < 0) return "discharging";
      if (this.props.realPowerP === 0) return "locked out";
    }
  };

  getPower = () => {
    const rawPower = this.props.realPowerP;
    let powerUnit = "",
      power = 0;
    if (rawPower) {
      power = (rawPower > 1000 ? +(rawPower / 1000) : rawPower).toFixed(2);
      powerUnit = rawPower > 1000 ? "MW" : "kW";
    }

    return `${power} ${powerUnit}`;
  };

  render() {
    if (this.props.state === "UPCOMING") {
      return (
        <li className="dispatch-ticker">
          <img src={DispatchIcon} alt="dispatch icon" />
          <div>
            <span className="powerstation-name">{this.props.powerstation}</span> is due to{" "}
            {this.getAction()}
            {this.props.realPowerP !== 0 ? (
              <span>
                {" "}
                at <span className="power">{this.getPower()}</span>
              </span>
            ) : null}
          </div>
        </li>
      );
    }

    if (this.props.state === "INPROGRESS") {
      return (
        <li className="dispatch-ticker">
          <img src={DispatchIcon} alt="dispatch icon" />
          <div>
            <span className="powerstation-name">{this.props.powerstation}</span> is{" "}
            {this.getAction()}
            {this.props.realPowerP !== 0 ? (
              <span>
                {" "}
                at <span className="power">{this.getPower()}</span>
              </span>
            ) : null}
          </div>
        </li>
      );
    }

    return null;
  }
}

class CloudCoverTicker extends Component {
  render() {
    return (
      <li className="weather-ticker">
        <img src={CloudIcon} alt="weather icon" />
        <div>
          Forecast cloud cover across fleet is{" "}
          <span className="cloud">{Math.round(this.props.value * 100)}%</span>
        </div>
      </li>
    );
  }
}

class TemperatureTicker extends Component {
  render() {
    return (
      <li className="weather-ticker">
        <img src={TempIcon} alt="thermometer icon" />
        <div>
          High temperature forecast of{" "}
          <span className="temp">{Math.round(this.props.value)}&#8451;</span> in postcode{" "}
          <span className="temp">{this.props.postcode}</span>
        </div>
      </li>
    );
  }
}

class AwaitingCommissioningTicker extends Component {
  render() {
    return (
      <li className="installer-ticker">
        <img src={HomeIcon} alt="home icon" />
        <div>
          <span className="install">{this.props.value}</span> installations awaiting commissioning
        </div>
      </li>
    );
  }
}

class RecentCommissioningTicker extends Component {
  render() {
    return (
      <li className="installer-ticker">
        <img src={HomeIcon} alt="home icon" />
        <div>
          <span className="install">{this.props.value}</span> installations commissioned last month
        </div>
      </li>
    );
  }
}

class NewCustomersTicker extends Component {
  render() {
    return (
      <li className="customer-ticker">
        <img src={CustomerIcon} alt="customer icon" />
        <div>
          <span className="customer">{this.props.value}</span> new customers last month
        </div>
      </li>
    );
  }
}

class Ticker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tickerWidth: 1000000,
      tickerContentWidth: 0,
      x: 1000000,
    };
  }

  componentDidMount = () => {
    if (this.props.events) {
      this.setState({
        events: this.props.events,
      });
    }
  };

  componentWillUnmount = () => {
    if (this.tickerAnimationFrame) {
      cancelAnimationFrame(this.tickerAnimationFrame);
      this.tickerAnimationFrame = null;
    }
  };

  componentWillReceiveProps = nextProps => {
    if (this.props.events !== nextProps.events && nextProps.events) {
      if (!this.tickerAnimationFrame) {
        this.tickerAnimationFrame = requestAnimationFrame(this.animate);
      }

      if (this.state.events) {
        this.setState({
          nextEvents: nextProps.events,
        });
      } else {
        this.setState({
          events: nextProps.events,
        });
      }
    }
  };

  registerDashboardTicker = dashboardTicker => {
    if (dashboardTicker) {
      this.setState({
        tickerWidth: dashboardTicker.clientWidth,
        x: dashboardTicker.clientWidth,
      });
    }
  };

  registerTickerContent = tickerContent => {
    if (tickerContent) {
      this.tickerContent = tickerContent;
    }
  };

  animate = timestamp => {
    if (!this.lastAnimationTimestamp) {
      this.lastAnimationTimestamp = timestamp;
    }
    // transition at ~60 ~pixels per second
    let nextX = this.state.x - (timestamp - this.lastAnimationTimestamp) / 16.67;
    this.lastAnimationTimestamp = timestamp;

    // Got all the way off the screen to the left so reset it to the right to scroll again and
    // updates its contents.
    const contentWidth = this.tickerContent ? this.tickerContent.clientWidth : 1000000;

    if (nextX < -contentWidth) {
      if (!this.state.nextEvents) {
        this.props.fetchTickerEvents();
      }

      nextX = this.state.tickerWidth;

      if (this.state.nextEvents) {
        this.setState(
          {
            events: this.state.nextEvents,
            nextEvents: null,
            x: nextX,
          }
          // () => this.reanimateTicker()
        );
        this.tickerAnimationFrame = requestAnimationFrame(this.animate);
        return;
      }
    }

    this.setState(
      {
        x: nextX,
      }
      // () => this.reanimateTicker()
    );
    this.tickerAnimationFrame = requestAnimationFrame(this.animate);
  };

  render() {
    return (
      <div className="dashboard-ticker" ref={this.registerDashboardTicker}>
        {this.state.events ? (
          <ul
            className="ticker-content"
            style={{ left: this.state.x }}
            ref={this.registerTickerContent}
          >
            {this.state.events.map(e => {
              switch (e.type) {
                case "DISPATCH":
                  return <DispatchTicker {...e} />;
                case "CLOUD_COVER":
                  return <CloudCoverTicker {...e} />;
                case "HIGH_TEMP":
                  return <TemperatureTicker {...e} />;
                case "NEWLY_COMMISSIONED":
                  return <RecentCommissioningTicker {...e} />;
                case "AWAITING_COMMISSION":
                  return <AwaitingCommissioningTicker {...e} />;
                case "NEW_CUSTOMERS":
                  return <NewCustomersTicker {...e} />;
                default:
                  return null;
              }
            })}
          </ul>
        ) : null}
        <div className="end-cap left" />
        <div className="end-cap right" />
      </div>
    );
  }
}

export default Ticker;

import React, { Component } from "react";
import { MomentDate } from "../widgets";
import { getDateAsString } from "../widgets/Moment";
import Loading from "../widgets/Loading";

class SetpointListTable extends Component {
  constructor(props) {
    super(props);

    let powerstationMap = {};
    if (props.powerstations) {
      for (let i in props.powerstations) {
        const powerstation = props.powerstations[i];
        powerstationMap[powerstation.id] = powerstation;
      }
    }

    this.state = {
      powerstationMap: powerstationMap,
    };
  }

  getPowerstationName = powerstationId => {
    const powerstation = this.state.powerstationMap[powerstationId];
    if (powerstation) {
      return powerstation.name;
    } else {
      return "";
    }
  };

  getStatus = state => {
    switch (state) {
      case "ACTIVE":
        return "Active";
      case "INACTIVE":
        return "Inactive";
      default:
        return "";
    }
  };

  isThisYear = date => {
    const dateYear = getDateAsString(date, this.props.timezone, "YYYY");
    const nowYear = getDateAsString(new Date(), this.props.timezone, "YYYY");
    return dateYear === nowYear;
  };

  render() {
    const setpoints = this.props.setpoints;
    if (!setpoints) {
      return <Loading />;
    }

    if (setpoints.length === 0) {
      return <div className="fleet-table-no-results">No curtailment setpoints found.</div>;
    }

    setpoints.sort((a, b) => {
      if (a.created_at > b.created_at) return -1;
      if (a.created_at < b.created_at) return 1;
      if (a.created_at === b.created_at) {
        if (a.status === "ACTIVE") return 1;
        if (a.status === "INACTIVE") return -1;
        return 0;
      }
      return 0;
    });

    return (
      <table>
        <thead>
          <tr>
            <th>Setpoint</th>
            <th>Created At</th>
            <th>Status</th>
            <th>Ramp Duration</th>
            <th>Gross VPP Limit (kW)</th>
            <th>Net VPP Limit (kW)</th>
          </tr>
        </thead>
        <tbody>
          {setpoints.map(s => {
            return (
              <tr key={s.id} className="no-click">
                <td>
                  Setpoint Change
                  <br />
                  <p>{this.getPowerstationName(s.powerstation_uid)}</p>
                </td>
                <td>
                  {this.isThisYear(new Date(s.created_at * 1000)) ? (
                    <MomentDate
                      date={new Date(s.created_at * 1000)}
                      timezone={this.props.timezone}
                      format="D MMMM, h:mm a"
                    />
                  ) : (
                    <MomentDate
                      date={new Date(s.created_at * 1000)}
                      timezone={this.props.timezone}
                      format="D MMM YY, h:mm a"
                    />
                  )}
                </td>
                <td>{this.getStatus(s.status)}</td>
                <td>{s.ramp_duration ? `${s.ramp_duration} seconds` : "Unknown"} </td>
                <td>{s.solar_limit}</td>
                <td>{s.grid_limit}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  }
}

export default SetpointListTable;

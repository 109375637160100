import React, { Component } from "react";
import DeckGL, { IconLayer, WebMercatorViewport } from "deck.gl";
import rbush from "rbush";

const ICON_SIZE = 150;

export default class DeploymentMapClusteringLayer extends Component {
  constructor(props) {
    super(props);

    // build spatial index
    this._tree = rbush(9, [".x", ".y", ".x", ".y"]);
    this.state = {
      x: 0,
      y: 0,
      hoveredItems: null,
      expanded: false,
    };

    this._updateCluster(props.deployments, props.viewport);
  }

  componentWillReceiveProps(nextProps) {
    const { viewport } = nextProps;
    const oldViewport = this.props.viewport;

    if (
      nextProps.deployments !== this.props.deployments ||
      viewport.width !== oldViewport.width ||
      viewport.height !== oldViewport.height
    ) {
      this._updateCluster(nextProps.deployments, nextProps.viewport);
    }
  }

  getIconName = (size, iconNameSuffix) => {
    if (size === 0) {
      return "";
    }
    if (size === 1) {
      return `dark${iconNameSuffix}`;
    }
    if (size < 10) {
      return `cluster${size}`;
    }
    if (size < 100) {
      return `cluster${Math.floor(size / 10) * 10}`;
    }
    if (size < 200) {
      return `cluster${Math.floor(size / 50) * 50}`;
    }
    if (size < 500) {
      return `cluster${Math.floor(size / 100) * 100}`;
    }
    return "cluster500";
  };

  getIconSize = size => {
    if (size === 1) {
      return ICON_SIZE;
    } else {
      return 200;
    }
  };

  _sortNodes(data) {
    // The clustering of nodes works by finding the first item in the list and clustering nearby
    // items to it, then it moves on to find the next item in the list that is not clustered.
    // As such when zooming out further and further nodes are essentially clustered to the first
    // item in the list. To make this clustering more representative of the geographical locations
    // we can order the list of deployments geographically by the most popular to the least popular.
    // The geographical "popularity" is simply a count of nodes in each state and then city.

    // Ignore deployments that are missing a state or city
    data = data.filter(
      d => d.deployment.address.state !== null && d.deployment.address.city !== null
    );
    // Get a count of nodes in each state/city tuple.
    const stateCount = {};
    const cityCount = {};
    data.forEach(d => {
      const state = d.deployment.address.state.toLowerCase();
      const city = d.deployment.address.city.toLowerCase();
      const cityKey = `${state}--${city}`;
      stateCount[state] = stateCount[state] ? (stateCount[state] += 1) : 1;
      cityCount[cityKey] = cityCount[cityKey] ? (cityCount[cityKey] += 1) : 1;
    });

    // Sort by popularity - state first then city.
    data.sort(function (a, b) {
      const aState = a.deployment.address.state.toLowerCase();
      const bState = b.deployment.address.state.toLowerCase();

      if (aState === bState) {
        const aCity = a.deployment.address.city.toLowerCase();
        const bCity = b.deployment.address.city.toLowerCase();
        const aCityKey = `${aState}--${aCity}`;
        const bCityKey = `${bState}--${bCity}`;
        return cityCount[bCityKey] - cityCount[aCityKey];
      } else {
        return stateCount[bState] - stateCount[aState];
      }
    });

    return data;
  }

  // Compute icon clusters
  // We use the projected positions instead of longitude and latitude to build
  // the spatial index, because this particular dataset is distributed all over
  // the world, we can't use some fixed deltaLon and deltaLat
  _updateCluster(data, viewport) {
    if (!data) {
      return;
    }

    const sortedData = this._sortNodes(data);

    const tree = this._tree;

    const transform = new WebMercatorViewport({
      ...viewport,
      zoom: 0,
    });

    sortedData.forEach(p => {
      const screenCoords = transform.project(p.coordinates);
      p.x = screenCoords[0];
      p.y = screenCoords[1];
      p.zoomLevels = [];
    });

    tree.clear();
    tree.load(sortedData);

    for (let z = 0; z <= 20; z++) {
      const radius = ICON_SIZE / 2 / Math.pow(2, z);

      sortedData.forEach(p => {
        if (p.zoomLevels[z] === undefined) {
          // this point does not belong to a cluster
          const { x, y } = p;

          // find all points within radius that do not belong to a cluster
          const neighbors = tree
            .search({
              minX: x - radius,
              minY: y - radius,
              maxX: x + radius,
              maxY: y + radius,
            })
            .filter(neighbor => neighbor.zoomLevels[z] === undefined);

          // only show the center point at this zoom level
          neighbors.forEach(neighbor => {
            if (neighbor === p) {
              p.zoomLevels[z] = {
                icon: this.getIconName(neighbors.length, p.iconNameSuffix),
                points: neighbors,
              };
            } else {
              neighbor.zoomLevels[z] = null;
            }
          });
        }
      });
    }
  }

  render() {
    const { viewport, deployments, iconAtlas, iconMapping, showCluster } = this.props;

    if (!deployments || !iconMapping) {
      return null;
    }

    const z = Math.floor(viewport.zoom);
    const updateTrigger = z * showCluster;

    const deploymentsLayer = new IconLayer({
      id: "deployments",
      data: deployments,
      iconAtlas,
      iconMapping,
      getPosition: d => d.coordinates,
      getIcon: d =>
        showCluster
          ? d.zoomLevels && d.zoomLevels[z] && d.zoomLevels[z].icon
          : this.getIconName(1, d.iconNameSuffix),
      getSize: d => (this.props.iconSize ? this.props.iconSize : ICON_SIZE),
      updateTriggers: {
        getIcon: updateTrigger,
        getSize: updateTrigger,
      },
      pickable: !!this.props.onMarkerClick,
      onClick: marker => this.props.onMarkerClick(marker.object.deployment),
    });

    return <DeckGL {...viewport} layers={[deploymentsLayer]} />;
  }
}

import React, { Component } from "react";
import { Link } from "react-router-dom";
import { SelectDropdown, MomentDate, Duration } from "../widgets";
import AuthRequired from "../../containers/AuthRequired";
import Permissions, { hasPermission } from "../../permissions";
import "./PowerstationIncompleteEvents.css";

class PowerstationIncompleteEvents extends Component {
  getEventOptions = () => {
    let options = [];
    if (hasPermission(this.props.userPermissions, Permissions.fleet.dispatches.edit)) {
      options.push({
        label: "Dispatch",
        value: "DISPATCH",
      });
    }

    if (hasPermission(this.props.userPermissions, Permissions.fleet.curtailments.edit)) {
      options.push({
        label: "Curtailment",
        value: "CURTAILMENT",
      });
    }

    return options;
  };

  handleNewEventOnChange = eventType => {
    if (eventType === "DISPATCH") {
      this.props.onNewDispatchClick();
    }
  };

  toTitleCase = str => {
    if (str) {
      return str.replace(/\w\S*/g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      });
    }

    return "";
  };

  getStatus = state => {
    switch (state) {
      case "COMPLETED":
        return "Completed";
      case "UPCOMING":
        return "Upcoming";
      case "INPROGRESS":
        return "In Progress";
      default:
        return "";
    }
  };

  render() {
    return (
      <AuthRequired any={[Permissions.fleet.dispatches.any, Permissions.fleet.curtailments.any]}>
        <div className="powerstation-incomplete">
          <div className="event-table-container">
            <label htmlFor="">Upcoming/In Progress Events</label>
            {!this.props.incompleteEvents || this.props.incompleteEvents.length === 0 ? (
              <div className="value">
                There are currently no events on this Virtual Power Plant.
              </div>
            ) : (
              <table className="table-light event-table">
                <thead>
                  <tr>
                    <th>Type</th>
                    <th>Start</th>
                    <th>Duration</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {this.props.incompleteEvents.map(n => (
                    <tr>
                      <td>
                        <Link to={`/virtual-power-plants/dispatches/${n.id}`}>
                          {this.toTitleCase(n.type)}
                        </Link>
                      </td>
                      <td>
                        <MomentDate
                          date={new Date(n.startTime) * 1000}
                          timezone={this.props.timezone}
                          format="D MMMM, h:mm a"
                        />
                      </td>
                      <td>
                        <Duration seconds={n.duration} />
                      </td>
                      <td>{this.getStatus(n.state)}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>
          <div>
            <SelectDropdown
              placeholder="Add New"
              options={this.getEventOptions()}
              onChange={this.handleNewEventOnChange}
              icon="plus-circle"
            />
          </div>
        </div>
      </AuthRequired>
    );
  }
}

export default PowerstationIncompleteEvents;

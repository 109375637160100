import React, { Component } from "react";
import GenericPhoneShell from "./generic_phone_shell.png";
import "./CustomerAppMessagePreview.css";

class CustomerAppMessagePreview extends Component {
  render() {
    return (
      <div
        className="customer-app-message-preview"
        style={{
          backgroundImage: `url(${GenericPhoneShell})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "100% auto",
        }}
      >
        <div className="title">{this.props.title}</div>
        <div className="message">{this.props.message}</div>
      </div>
    );
  }
}

export default CustomerAppMessagePreview;

import React, { Component } from "react";
import ReactDOM from "react-dom";
import { store } from "../../containers/Root";
import { withRouter } from "react-router";
import { closeConfirm } from "../../redux/actions/ui";
import { Button } from "../widgets";
import "./Modal.css";

export const ModalContext = React.createContext({});

export class Modal extends Component {
  static Header = class extends Component {
    render() {
      return (
        <header className={this.props.className}>
          <div>{this.props.title}</div>
        </header>
      );
    }
  };

  static Footer = class extends Component {
    render() {
      return <footer>{this.props.children}</footer>;
    }
  };

  static Content = class extends Component {
    render() {
      return (
        <div className={"content" + (this.props.title ? " with-header" : "")}>
          {this.props.children}
        </div>
      );
    }
  };

  modalContainer = document.getElementById("modal-container");

  render() {
    const { className, children } = this.props;
    return ReactDOM.createPortal(
      <ModalContext.Provider value={{ isVisible: true, type: "Modal" }}>
        <div className="fleet-modal-overlay" />
        <div className={`fleet-modal-wrapper${className ? " " + className : ""}`}>
          <div className="fleet-modal" style={this.props.style}>
            {children}
          </div>
        </div>
      </ModalContext.Provider>,
      this.modalContainer
    );
  }
}

class ModalConfirmComponent extends Component {
  handleChoice(confirmed) {
    if (confirmed) {
      this.props.resolve();
    } else {
      this.props.reject();
    }

    store.dispatch(closeConfirm());
  }

  componentDidMount = () => {
    // Set a hook that if the back button is pressed the modal confirm will be closed rather
    // than actually doing a back navigation.
    // this.removeSetRouteLeaveHook = this.props.router.setRouteLeaveHook(this.props.route, this.routerWillLeave);
  };

  routerWillLeave = () => {
    store.dispatch(closeConfirm());
    return false;
  };

  componentWillUnmount = () => {
    // this.removeSetRouteLeaveHook();
  };

  render() {
    return (
      <ModalContext.Provider value={{ isVisible: true, type: "ModalConfirm" }}>
        <div className="fleet-modal-overlay" />
        <div className="fleet-modal-wrapper">
          <div className="fleet-modal confirm">
            <div className="content">{this.props.message}</div>
            <footer>
              <Button
                id={`modal-confirm-no-${
                  this.props.id ? this.props.id.replace(/ /g, "-") : "undefined"
                }`}
                type="secondary"
                onClick={() => this.handleChoice(false)}
                icon="times"
              >
                No
              </Button>
              <Button
                id={`modal-confirm-yes-${
                  this.props.id ? this.props.id.replace(/ /g, "-") : "undefined"
                }`}
                type="primary"
                onClick={() => this.handleChoice(true)}
                icon="check"
              >
                Yes
              </Button>
            </footer>
          </div>
        </div>
      </ModalContext.Provider>
    );
  }
}

export const ModalConfirm = withRouter(ModalConfirmComponent);

import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Page from "../../components/layout/Page";
import * as customerActions from "../../redux/actions/customers";
import CustomerLocation from "../../components/customers/CustomerLocation";
import TariffSummary from "../../components/customers/TariffSummary";
import { Loading, Button, Tooltip } from "../../components/widgets";
import ContractsSummary from "../../components/customers/ContractsSummary";
import AuthRequired from "../AuthRequired";
import Permissions from "../../permissions";

const mapStateToProps = state => ({
  customers: state.customers,
  user: state.user,
});

const mapDispatchToProps = dispatch => {
  return {
    customerActions: bindActionCreators(customerActions, dispatch),
  };
};

class CustomerDetails extends Component {
  componentDidMount = () => {
    this.props.customerActions.fetchCustomerDetails(this.props.match.params.nmi);
    this.props.customerActions.fetchCurrentCustomerTariff(this.props.match.params.nmi);
  };

  componentWillUnmount = () => {
    this.props.customerActions.clearCustomerDetails();
  };

  render() {
    const customer = this.props.customers.activeCustomer;

    if (!(customer && customer.nmi && customer.contracts && customer.currentTariff)) {
      return <Loading />;
    }

    return (
      <Page permission={Permissions.fleet.customerInformation.any}>
        <Page.Header title={"Customer " + this.props.match.params.nmi} />
        <Page.Content>
          <CustomerLocation
            nmi={customer.nmi}
            addressLine1={customer.addressLine1}
            addressLine2={customer.addressLine2}
            agreementDate={customer.agreementDate}
            agreementVersion={customer.agreementVersion}
            latitude={customer.lat}
            longitude={customer.long}
            timezone={this.props.user.account.timezone}
          />

          {this.props.user.account.suborg_type === "RETAILER" ? (
            <AuthRequired permission={Permissions.fleet.customerInformation.plans.any}>
              <Page.Header>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <h1>
                    {"Current Plan " +
                      (customer.currentTariff && customer.currentTariff.planCode
                        ? ` - ${customer.currentTariff.name} (${customer.currentTariff.planCode})`
                        : "")}
                  </h1>

                  <Tooltip id="customerInformation.detail.currentPlan" />
                </div>

                <Page.Header.Actions>
                  {customer.currentTariff && customer.currentTariff.planCode ? (
                    <Button
                      id="goto-view-plan-details"
                      type="secondary"
                      to={"/customers/plans/" + encodeURIComponent(customer.currentTariff.planCode)}
                      icon="file-text-o"
                    >
                      View Plan Details
                    </Button>
                  ) : null}
                </Page.Header.Actions>
              </Page.Header>
              {customer.currentTariff ? (
                <TariffSummary
                  tariff={customer.currentTariff}
                  timezone={this.props.user.account.timezone}
                />
              ) : (
                <div style={{ position: "relative", height: "6em" }}>
                  <Loading />
                </div>
              )}
            </AuthRequired>
          ) : null}

          <AuthRequired permission={Permissions.fleet.customerInformation.contracts.any}>
            <Page.Header>
              <div style={{ display: "flex", alignItems: "center" }}>
                <h1>Capabilities</h1>

                <Tooltip id="customerInformation.detail.capabilities" />
              </div>
            </Page.Header>
            {customer.contracts ? (
              <ContractsSummary
                contracts={customer.contracts}
                timezone={this.props.user.account.timezone}
              />
            ) : (
              <div style={{ position: "relative", height: "6em" }}>
                <Loading />
              </div>
            )}
          </AuthRequired>
        </Page.Content>
      </Page>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerDetails);
